/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner, Callout } from '@blueprintjs/core';

import { getRawAuditClip } from 'actions/audit';

class WatchClip extends Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  componentDidMount() {
    const { dispatch, clipId } = this.props;
    dispatch(getRawAuditClip(clipId));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, clipId } = this.props;
    if (prevProps.clipId !== clipId) {
      dispatch(getRawAuditClip(clipId));
    }
  }

  render() {
    const { clip } = this.props;
    if (clip.error && !clip.pending) {
      return <Callout intent="danger" title="Error fetching clip" />;
    }
    if (!(clip.data && clip.data.id)) {
      return <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>;
    }
    return <video width="100%" controls src={clip.data.s3_path} ref={this.video} crossOrigin="anonymous" />;
  }
}

WatchClip.propTypes = {
  dispatch: PropTypes.func,
  clipId: PropTypes.number,
  clip: PropTypes.object,
};

export default connect(state => ({
  clip: state.rawClip,
}))(WatchClip);
