import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, Icon, FormGroup, InputGroup, Spinner, Tooltip, Checkbox, Button,
  MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { getAudits, patchAudit } from 'actions/audit';
import { AppToaster } from 'components/Toaster';
import ReactPaginate from 'react-paginate-bp3';


const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

class Audits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      path: '',
      model: '',
      checked: [],
      page: 0,
    };
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAudits(null, 0, 100, null, null));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleInputPath(item) {
    this.setState({ path: item.path, checked: [] });
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const {
      page, query,
    } = this.state;
    // serializing json into url
    dispatch(replace(`/audits?${qs.stringify(_.pickBy({
      p: page,
    }))}`));
    dispatch(getAudits(
      query,
      page * 100,
      100,
      null,
      null,
    ));
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({ page: selected }, this.doRefresh);
  }

  @autobind
  handleModel(item) {
    this.setState({ model: item.model, checked: [] });
  }

  @autobind
  handleModelPredicate(query, item) {
    const q = query.toLowerCase();
    return item.model.toLowerCase().includes(q);
  }

  @autobind
  handlePathPredicate(query, item) {
    const q = query.toLowerCase();
    return item.path.toLowerCase().includes(q);
  }

  @autobind
  handleCompare() {
    const { dispatch } = this.props;
    const { checked } = this.state;
    return dispatch(push(`/audits/compare?${qs.stringify({ id: checked })}`));
  }

  @autobind
  clearModel() {
    this.setState({ model: '' });
  }

  @autobind
  clearPath() {
    this.setState({ path: '' });
  }

  @autobind
  pauseAudit(id, name) {
    const { dispatch } = this.props;
    return dispatch(patchAudit(id, { status: 4 }))
      .then(() => dispatch(getAudits())
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`${name} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeAudit(id, name) {
    const { dispatch } = this.props;
    return dispatch(patchAudit(id, { status: 0 }))
      .then(() => dispatch(getAudits())
      && AppToaster.show({
        icon: 'play',
        message: <span>{`${name} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  clearCompare() {
    this.setState({ checked: [] });
  }

  @autobind
  selectAudit(e, id) {
    const { audits } = this.props;
    const { checked } = this.state;
    if (!checked.length) {
      return this.setState({ checked: [id] });
    }
    if (!e.target.checked) {
      return this.setState({ checked: [...checked].filter(x => x !== id) });
    }
    const { s3_input_path, total } = (audits.data.find(x => x.audit_id === checked[0]) || {});
    const selectedAudit = audits.data.find(x => x.audit_id === id);
    if (selectedAudit.s3_input_path === s3_input_path && selectedAudit.total === total) {
      const newChecked = [...checked, id];
      return this.setState({ checked: newChecked });
    }
    return AppToaster.show({
      intent: 'danger',
      message: <span>S3 input path and total clip length must match to compare</span>,
      icon: 'cross',
    });
  }

  @autobind
  applyFilters(a) {
    const {
      s3_input_path, model_id, audit_name, audit_id,
    } = a;
    const { query, path, model } = this.state;
    const pathCheck = s3_input_path === path;
    const modelCheck = model_id === model;
    const queryCheck = (audit_name.toLowerCase().includes(query.toLowerCase())
      || audit_id.toString().includes(query));
    if (path && model) {
      return pathCheck && modelCheck && queryCheck;
    }
    if (path) {
      return queryCheck && pathCheck;
    }
    if (model) {
      return queryCheck && modelCheck;
    }
    return queryCheck;
  }

  @autobind
  renderModelItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent={undefined}
        onClick={handleClick}
        text={item.model}
      />
    );
  }

  @autobind
  renderPathItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent={undefined}
        onClick={handleClick}
        text={item.path}
      />
    );
  }

  @autobind
  renderAudit(audit) {
    const {
      completed, total, audit_id, audit_name, StartTime, model_id, status, EndTime,
    } = audit;
    const { checked } = this.state;
    const failed = status === 0 && EndTime !== null;
    return (
      <tr key={audit_id}>
        <td style={{ textAlign: 'center' }}>
          <Checkbox
            checked={checked.includes(audit_id)}
            onChange={e => this.selectAudit(e, audit_id)}
          />
        </td>
        <td><Link to={`/audits/${audit_id}`}>{audit_id}</Link></td>
        <td><Link to={`/audits/${audit_id}`}>{audit_name}</Link></td>
        <td>{model_id}</td>
        <td>{failed ? 'Failed' : STATUS_MAP[status]}</td>
        <td>{`${completed}/${total}`}</td>
        <td>{DateTime.fromISO(StartTime).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td style={{ textAlign: 'center' }}>
          {status !== 1 && !failed && (
            <Tooltip content={status === 4 ? 'Resume Audit' : 'Pause Audit'}>
              <Icon
                iconSize={20}
                className="pause-audit"
                icon={status === 4 ? 'play' : 'pause'}
                onClick={() => (status === 4
                  ? this.resumeAudit(audit_id, audit_name)
                  : this.pauseAudit(audit_id, audit_name))}
              />
            </Tooltip>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const { audits } = this.props;
    const {
      query, checked, path, model, page,
    } = this.state;
    const spinner = audits.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Audits
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/audits/create"
                  >
                    <Icon icon="add" />
                  </Link>
                  <Button
                    className="is-pulled-right"
                    style={{ height: 40, marginRight: 10 }}
                    intent="primary"
                    icon="comparison"
                    disabled={checked.length < 2}
                    onClick={this.handleCompare}
                  >
                    {checked.length > 1 ? `Compare (${checked.length})` : 'Compare'}
                  </Button>
                </H1>
              </div>
            </div>
          </div>
        </div>
        <FormGroup>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
        </FormGroup>
        <div className="columns">
          <div className="column">
            <FormGroup className="inline-flex m-r-sm" label="Models">
              <div style={{ display: 'flex' }}>
                <Select
                  resetOnSelect
                  filterable
                  itemPredicate={this.handleModelPredicate}
                  itemRenderer={this.renderModelItem}
                  popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                  items={_.uniqBy((audits.data || []), 'model_id')
                    .map((y, i) => ({ model: y.model_id, id: i + 1 })) || []}
                  onItemSelect={this.handleModel}
                  activeItem={model}
                >
                  <Button text={model || 'All Models'} rightIcon="caret-down" />
                </Select>
                {!!model && <Button intent="danger" outlined onClick={this.clearModel} style={{ marginLeft: 5 }} icon="cross" />}
              </div>
            </FormGroup>
            <FormGroup className="inline-flex m-r-sm" label="S3 Input Paths">
              <div style={{ display: 'flex' }}>
                <Select
                  resetOnSelect
                  filterable
                  itemPredicate={this.handlePathPredicate}
                  itemRenderer={this.renderPathItem}
                  popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                  items={_.uniqBy((audits.data || []), 's3_input_path')
                    .map((x, i) => ({ path: x.s3_input_path, id: i + 1 })) || []}
                  onItemSelect={this.handleInputPath}
                  activeItem={path}
                >
                  <Button text={path || 'All Input Paths'} rightIcon="caret-down" />
                </Select>
                {!!path && <Button intent="danger" outlined onClick={this.clearPath} style={{ marginLeft: 5 }} icon="cross" />}
              </div>
            </FormGroup>
          </div>
        </div>
        <div>
          <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
            <ReactPaginate
              previousLabel={<Icon icon="chevron-left" />}
              nextLabel={<Icon icon="chevron-right" />}
              breakLabel="..."
              pageCount={Math.ceil(((audits.data.meta || {}).total || 0) / 100)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              forcePage={page}
              onPageChange={this.handlePageClick}
              containerClassName="bp3-button-group"
              pageClassName="bp3-button"
              previousClassName="bp3-button"
              nextClassName="bp3-button"
              disabledClassName="bp3-disabled"
              breakClassName="bp3-button bp3-disabled"
              activeClassName="bp3-active"
            />
          </div>
          <div>
            {!!((audits.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>
                      {!!checked.length && (
                        <Button outlined small onClick={this.clearCompare} icon="cross" intent="danger" />
                      )}
                    </th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Model</th>
                    <th>Status</th>
                    <th>Done/Total</th>
                    <th>Start Time</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {([...audits.data] || [])
                    .filter(this.applyFilters)
                    .map(this.renderAudit)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Audits.propTypes = {
  dispatch: PropTypes.func,
  audits: PropTypes.object,
};

export default connect(state => ({
  audits: state.audits,
}))(Audits);
