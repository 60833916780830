import React from 'react';
import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
  createRequestInstance,
  watchRequests,
} from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import { LOGIN, GET_USER_PROFILE, TWO_FACTOR_LOGIN } from '../constants';
import { logout, getCurrentUser } from '../actions/user';
import { AppToaster } from '../components/Toaster';


function* onResponseSaga(response, action) {
  if (action.type === LOGIN || action.type === TWO_FACTOR_LOGIN) {
    if (response.data.expires_in > 600) {
      yield put(getCurrentUser(response.data.access_token));
    }
  }
  if (action.type === GET_USER_PROFILE) {
    if (![1, 443].includes(response.data.content.organization_id)) {
      yield put(logout());
    }
  }
  return response;
}

function* onErrorSaga(error) {
  if (error.response && error.response.status === 401) {
    yield put(logout());
  }
  if (error.response && error.response.data) {
    if (error.response.data.result) {
      AppToaster.show({
        icon: 'error',
        message: (
          <span>
            <strong>
              Error:&nbsp;
              {error.response.data.result.errorCode}
            </strong>
            <br />
            {error.response.data.result.errorMessage || ''}
          </span>
        ),
        intent: 'danger',
      });
    }
  }

  return { error };
}

function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axios),
    onSuccess: onResponseSaga,
    onError: onErrorSaga,
  });
  yield watchRequests({
    getLastActionKey: action => (action.meta || {}).actionKey || action.type,
  });
}

export default rootSaga;
