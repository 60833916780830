import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Card, Elevation, Icon,
} from '@blueprintjs/core';

import { TextInput } from './inputs';

const MFALogin = ({
  handleSubmit, submitting, resend, resendCodeLoading, isTotp,
}) => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container has-text-left">
        <div className="columns">
          <div className="column is-one-third is-offset-one-third">
            <form onSubmit={handleSubmit}>
              <Card elevation={Elevation.FOUR}>
                <h5 className="bp3-heading"><Icon icon="briefcase" /></h5>
                <Field
                  component={TextInput}
                  placeholder=""
                  name="code"
                  label="Two-Factor Authentication Code"
                />
                {!isTotp && (
                  <Button loading={resendCodeLoading} style={{ marginBottom: 10, width: '100%' }} intent="primary" onClick={resend}>
                    Resend Code
                  </Button>
                )}
                <Button intent="success" fill loading={submitting} disabled={submitting} type="submit">
                  <Icon icon="log-in" />
                </Button>
              </Card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
);

MFALogin.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  resend: PropTypes.func,
  resendCodeLoading: PropTypes.bool,
  isTotp: PropTypes.bool,
};

export default reduxForm({
  form: 'MFALogin',
})(MFALogin);
