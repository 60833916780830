import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dash from './dash';
import Tasks from './Tasks';
import TaskPage from './Tasks/taskPage';

export default () => (
  <Switch>
    <Route path="/model_training/:id/tasks/:task_id" component={TaskPage} />
    <Route path="/model_training/:id/tasks/new" component={TaskPage} />
    <Route path="/model_training/:id/tasks" component={Tasks} />
    <Route path="/model_training/tasks/:task_id" component={TaskPage} />
    <Route component={Dash} />
  </Switch>
);
