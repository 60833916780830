import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, FormGroup, InputGroup, Icon, Spinner, NonIdealState,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getOrganizations } from 'actions/organization';

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      status: '',
    };
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOrganizations());
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const { query, status } = this.state;
    dispatch(getOrganizations(query, status));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value }, this.doRefresh);
  }

  @autobind
  handleStatus(e) {
    this.setState({ status: e.target.value }, this.doRefresh);
  }

  renderOrg(org, i) {
    return (
      <tr key={i}>
        <td><Link to={`/organizations/${org.id}`}>{org.name || org.id}</Link></td>
        <td>{org.slug}</td>
        <td className="has-text-centered"><Icon icon="symbol-circle" intent={org.active ? 'success' : 'danger'} /></td>
      </tr>
    );
  }

  render() {
    const { query, status } = this.state;
    const { organizations } = this.props;
    const spinner = organizations.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Organizations
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/organizations/create"
                  >
                    <Icon icon="add" />
                  </Link>
                </H1>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-right">
                    <FormGroup
                      label="Status"
                      labelFor="org-status"
                    >
                      <div className="bp3-select">
                        <select id="org-status" value={status} onChange={this.handleStatus}>
                          <option value="">--</option>
                          <option value="true">Active</option>
                          <option value="false">Disabled</option>
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((organizations.data || []).length) && <NonIdealState icon="search" title="No results." />}
            {!!((organizations.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Slug</th>
                    <th className="has-text-centered">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {(organizations.data || []).map(this.renderOrg)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Organizations.propTypes = {
  dispatch: PropTypes.func,
  organizations: PropTypes.object,
};

export default connect(state => ({
  organizations: state.organizations,
}))(Organizations);
