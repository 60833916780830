import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';
import { getZone, saveZone } from 'actions/site';

import { TextInput, DayInput } from 'components/inputs';
import { QUERY_DATE_FMT } from '../../constants';

class ZoneName extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const zoneId = parseInt(match.params.id, 10);
    const data = {
      name: values.name,
      valid_from: moment(values.valid_from, QUERY_DATE_FMT).utc().startOf('day').format(),
    };
    return dispatch(saveZone(zoneId, data))
      .then(() => dispatch(getZone(zoneId)));
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
        <div className="columns" style={{ border: '.5px solid #394B59', marginLeft: '0px' }}>
          <div className="column">
            <Field
              component={TextInput}
              placeholder=""
              name="name"
              label="Zone Name"
              type="text"
            />
            <Field
              component={DayInput}
              name="valid_from"
              label="Data Start Date"
              hideTime
              dateFormat="LL"
            />
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

ZoneName.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default reduxForm({
  form: 'zonename',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.valid_from) {
      errors.valid_from = 'Required';
    }
    return errors;
  },
})(ZoneName);
