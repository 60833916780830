import { requestsReducer } from 'redux-saga-requests';
import {
  GET_LOCATIONS,
  GET_LOCATION,
  GET_LOCATION_WHITELIST,
  GET_AUDIT_LOCATIONS,
} from '../constants';

export const locationsReducer = requestsReducer({
  actionType: GET_LOCATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const auditlocationsReducer = requestsReducer({
  actionType: GET_AUDIT_LOCATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const locationReducer = requestsReducer({
  actionType: GET_LOCATION,
  getData: (state, action) => action.payload.data.content,
});

export const locationWhitelistReducer = requestsReducer({
  actionType: GET_LOCATION_WHITELIST,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});
