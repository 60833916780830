import { requestsReducer } from 'redux-saga-requests';
import {
  GET_TRAINING_DATASETS, GET_ALL_TRAINING_TASKS, GET_TRAINING_STATUS, TERMINATE_TRAINING,
} from '../constants';

export const getTrainingDatasetsReducer = requestsReducer({
  actionType: GET_TRAINING_DATASETS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getAllTrainingTasksReducer = requestsReducer({
  actionType: GET_ALL_TRAINING_TASKS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getTrainingTaskStatusReducer = requestsReducer({
  actionType: GET_TRAINING_STATUS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const terminateTrainingReducer = requestsReducer({
  actionType: TERMINATE_TRAINING,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});
