import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';

const asTrue = () => true;
const nameCache = {};
const defaultRenderer = o => (o ? (DateTime.fromISO(o.start_time).toFormat('yyyy-MM-dd HH:mm:ss') || o) : o);
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;

class ClipSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filtered_clips: [],
    };
  }

  componentDidMount() {
    const { cam_clips } = this.props;
    this.setState({ filtered_clips: cam_clips });
  }

  @autobind
  onItemSelect(o) {
    const { onChange } = this.props;
    onChange(o.id);
  }

  @autobind
  handleOpen() {
    const {
      cam_clips, get_filter_dates,
    } = this.props;
    const [start_date, end_date] = get_filter_dates();
    // console.log("start_date", start_date);
    // //for each clip print clip.start_time
    // //for each clip print clip.end_time
    // cam_clips.forEach(clip => {
    //   console.log(clip.start_time);
    //   console.log(DateTime.fromISO(clip.start_time).toFormat("yyyy-MM-dd HH:mm:ss"));
    //   });

    let filtered_clips = cam_clips;

    filtered_clips.forEach(() => {
    });

    if (start_date) {
      // filter out all cam_clips that have start_time before start_date
      // eslint-disable-next-line
      filtered_clips = cam_clips.filter(x => DateTime.fromISO(x.start_time) >= start_date.getTime());
    }

    if (end_date) {
      // filter out all cam_clips that have start_time after end_date
      // eslint-disable-next-line
      filtered_clips = filtered_clips.filter(x => DateTime.fromISO(x.start_time) <= end_date.getTime());
    }

    this.setState({ filtered_clips });
    // this.setState({ loading: true });
  }


  @autobind
  renderItem(item, { handleClick, modifiers }) {
    const { value } = this.props;
    if (item === '--') {
      return (
        <MenuItem
          active={false}
          disabled={modifiers.disabled}
          key={item}
          icon={item === value ? 'tick' : 'blank'}
          onClick={handleClick}
          text={item}
        />
      );
    }
    if (!modifiers.matchesPredicate) {
      return null;
    }
    if (!item) {
      return null;
    }
    const itemRenderer = defaultRenderer;
    nameCache[item.id] = item.start_time;

    return (

      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        icon={item.id === value && value ? 'tick' : 'blank'}
        onClick={() => {
          handleClick();
        }
      }
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const {
      className,
    } = this.props;
    const { get_clip_id } = this.props;

    const clip_id = get_clip_id();


    // const { id } =  this.props.;
    const { loading, filtered_clips } = this.state;
    const items = filtered_clips || [];

    const itemRenderer = defaultRenderer;
    const site = items.find(x => x.id === clip_id); // || (value || null);


    // if (!test_site) {
    //   //add site to items
    //   items.push(site);
    // }

    // if (includeReset) {
    //   items = ['--'].concat(items);
    // }

    return (
      <Select
        noResults={<MenuItem disabled key="nr" text="No results." />}
        resetOnSelect
        itemRenderer={this.renderItem}
        itemPredicate={loading ? asTrue : defaultFilter}
        popoverProps={{
          minimal: true, fill: true, popoverClassName: 'popover-sz', onOpening: this.handleOpen,
        }}
        itemDisabled={loading ? asTrue : undefined}
        items={items} // {loading ? [{ id: 'loading', name: 'Loading...' }] : items}
        onItemSelect={this.onItemSelect}
        loading={loading}
      >
        <Button
          text={itemRenderer(site) || 'Select a clip'}
          fill
          rightIcon="caret-down"
          className={className}
        />
      </Select>
    );
  }
}

ClipSelection.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  className: PropTypes.string,
  cam_clips: PropTypes.array,
  get_filter_dates: PropTypes.func,
  get_clip_id: PropTypes.func,
};

export default connect(state => (

  {
    siteDevices: state.siteDevices,
  }))(ClipSelection);
