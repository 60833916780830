import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import _ from 'lodash';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import {
  Menu, MenuItem, Card, Elevation, H3, Radio, RadioGroup,
  FormGroup, InputGroup, ProgressBar, Intent,
} from '@blueprintjs/core';
import OccupancyProvider from 'components/Providers/allZonesOccupancyProvider';

const fmtr = x => numbro(x).format('0,0');

const getYesterdayEntrances = (timezone, rows) => {
  if (rows.length) {
    const entries = (rows[rows.length - 2] || [0, 0, 0])[1];
    const exits = (rows[rows.length - 2] || [0, 0, 0])[2];
    return [entries, exits];
  }
  return [0, 0];
};

const getTodayEntrances = (timezone, rows) => {
  if (rows.length) {
    const entries = (rows[rows.length - 1] || [0, 0, 0])[1];
    const exits = (rows[rows.length - 1] || [0, 0, 0])[2];
    return [entries, exits];
  }
  return [0, 0];
};

class OccupancyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      day: 'today',
      query: '',
    };
    this.tableRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    const { data: currentData, selectedZones } = this.props;
    if (!_.isEqual(currentData, nextProps.data) && nextProps.data.resolved) {
      const datasets = (nextProps.data.rows).map((record) => {
        const { id, name, timezone } = selectedZones.find(x => x.id === record[0]) || {};
        const rows = record[1].sort((a, b) => a[0].localeCompare(b[0]));
        return {
          id,
          name,
          yesterday: getYesterdayEntrances(timezone, rows),
          today: getTodayEntrances(timezone, rows),
        };
      });
      this.setState({
        datasets: { data: datasets, fetching: false, progress: nextProps.data.progress },
      });
    }
    if (!nextProps.data.resolved) {
      this.setState({ datasets: { fetching: true, progress: nextProps.data.progress } });
    }
  }

  @autobind
  cellRenderer(index, title) {
    const { datasets, day, query } = this.state;
    const filteredData = datasets.data ? datasets.data
      .filter(x => x.name.toLowerCase().includes(query)) : [];
    const row = filteredData[index];

    if (row) {
      if (title === 'zone_name') {
        return <Cell wrapText>{row.name || ''}</Cell>;
      }
      if (title === 'entries') {
        return <Cell wrapText>{row[day] ? fmtr(row[day][0]) : ''}</Cell>;
      }
      if (title === 'exits') {
        return <Cell wrapText>{row[day] ? fmtr(row[day][1]) : ''}</Cell>;
      }
      if (title === 'occupancy') {
        return <Cell wrapText>{row[day] ? fmtr(row[day][0] - row[day][1]) : ''}</Cell>;
      }
    }
    return <Cell />;
  }

  @autobind
  sortAsc(column) {
    const { datasets, day } = this.state;
    let sortedData;
    if (column === 0) {
      sortedData = [...datasets.data]
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    if (column === 1) {
      sortedData = [...datasets.data]
        .sort((a, b) => a[day][0] - b[day][0]);
    }
    if (column === 2) {
      sortedData = [...datasets.data]
        .sort((a, b) => a[day][1] - b[day][1]);
    }
    if (column === 3) {
      sortedData = [...datasets.data]
        .sort((a, b) => (a[day][0] - a[day][1]) - (b[day][0] - b[day][1]));
    }
    datasets.data = sortedData;
    this.setState({ datasets });
  }

  @autobind
  sortDesc(column) {
    const { datasets, day } = this.state;
    let sortedData;
    if (column === 0) {
      sortedData = [...datasets.data]
        .sort((a, b) => b.name.localeCompare(a.name));
    }
    if (column === 1) {
      sortedData = [...datasets.data]
        .sort((a, b) => b[day][0] - a[day][0]);
    }
    if (column === 2) {
      sortedData = [...datasets.data]
        .sort((a, b) => b[day][1] - a[day][1]);
    }
    if (column === 3) {
      sortedData = [...datasets.data]
        .sort((a, b) => (b[day][0] - b[day][1]) - (a[day][0] - a[day][1]));
    }
    datasets.data = sortedData;
    this.setState({ datasets });
  }

  @autobind
  handleDayChange(e) {
    this.setState({ day: e.target.value });
  }

  @autobind
  handleQuery(e) {
    const q = e.target.value.toLowerCase();
    this.setState({ query: q });
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  render() {
    const { day, query, datasets } = this.state;
    const columnWidth = this.tableRef.current ? this.tableRef.current.clientWidth / 4.11 : 0;
    const filteredData = datasets.data ? datasets.data
      .filter(x => x.name.toLowerCase().includes(query)) : [];
    return (
      <Card elevation={Elevation.FOUR} style={{ marginBottom: 20 }}>
        <div className="flex-space-between-container" ref={this.tableRef}>
          <div>
            <H3>
              Daily Occupancy Report
            </H3>
          </div>
          <div className="flex-space-between-container">
            <div style={{ fontWeight: 'bold', marginRight: 20 }}>
              <RadioGroup inline size="small" selectedValue={day} onChange={this.handleDayChange}>
                <Radio value="yesterday">Yesterday</Radio>
                <Radio value="today">Today</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        {datasets.progress === 100 && (
          <Fragment>
            <FormGroup style={{ margin: '10px 0px' }}>
              <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Zones" />
            </FormGroup>
            {/** blueprintjs table needs fixed height container or only renders 200 rows */}
            <div style={{ height: 600 }}>
              <Table
                columnWidths={Array(4).fill(columnWidth)}
                numFrozenColumns={1}
                numRows={filteredData.length}
                class="bp3-html-table bp3-table-resize-horizontal"
                enableFocusedCell={false}
              >
                <Column
                  columnHeaderCellRenderer={() => <ColumnHeaderCell name="Zone" menuRenderer={this.renderMenu} />}
                  cellRenderer={i => this.cellRenderer(i, 'zone_name')}
                />
                <Column
                  columnHeaderCellRenderer={() => <ColumnHeaderCell name="Entries" menuRenderer={this.renderMenu} />}
                  cellRenderer={i => this.cellRenderer(i, 'entries')}
                />
                <Column
                  columnHeaderCellRenderer={() => <ColumnHeaderCell name="Exits" menuRenderer={this.renderMenu} />}
                  cellRenderer={i => this.cellRenderer(i, 'exits')}
                />
                <Column
                  columnHeaderCellRenderer={() => <ColumnHeaderCell name="Occupancy" menuRenderer={this.renderMenu} />}
                  cellRenderer={i => this.cellRenderer(i, 'occupancy')}
                />
              </Table>
            </div>
          </Fragment>
        )}
        {(datasets.progress == null || datasets.progress < 100) && (
          <div style={{ margin: '1rem' }}>
            <ProgressBar
              intent={Intent.SUCCESS}
              value={datasets.progress ? (datasets.progress / 100) : 0}
            />
          </div>
        )}
      </Card>
    );
  }
}

OccupancyReport.propTypes = {
  selectedZones: PropTypes.array,
  data: PropTypes.object,
};

export default OccupancyProvider(({
  startDate, endDate, selectedZones,
}) => ({
  name: 'report',
  selectedZones,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
}))(OccupancyReport);
