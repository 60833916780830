import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  H3, NonIdealState, Button, Card, Elevation, FormGroup, InputGroup,
} from '@blueprintjs/core';

import { getClipsByTime } from 'actions/device';

import WatchClip from './watchClip';

class UploadedClips extends Component {
  constructor(props) {
    super(props);
    this.state = { watching: null, query: '' };
  }

  componentDidMount() {
    const { dispatch, upload } = this.props;
    const { camera_serial, start_time, end_time } = upload;
    dispatch(getClipsByTime(camera_serial, start_time, end_time));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, upload } = this.props;
    const {
      camera_serial, start_time, end_time, upload_id,
    } = upload;
    if (prevProps.upload.upload_id !== upload_id) {
      dispatch(getClipsByTime(camera_serial, start_time, end_time));
    }
  }

  @autobind
  setVideo(id) {
    const { watching } = this.state;
    if (watching === id) return this.setState({ watching: null });
    return this.setState({ watching: id });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderClip(clip) {
    const {
      id, start_time, end_time, file_name,
    } = clip;
    const { watching } = this.state;
    return (
      <Fragment key={id}>
        <tr>
          <td>{id}</td>
          <td>{DateTime.fromISO(start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{DateTime.fromISO(end_time).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{file_name}</td>
          <td>
            <Button intent={watching === id ? 'warning' : 'primary'} outlined minimal icon="video" onClick={() => this.setVideo(id)} />
          </td>
        </tr>
        {watching === id && (
          <tr>
            <td colSpan="3"><WatchClip clipId={id} /></td>
          </tr>
        )}
      </Fragment>
    );
  }

  render() {
    const { upload, clips } = this.props;
    const { query } = this.state;
    const { start_time, end_time, camera_serial } = upload;
    return (
      <Card elevation={Elevation.TWO}>
        <H3>
          <div className="flex-space-between-container">
            <div>{`${camera_serial} Clips`}</div>
            <div>
              {`${DateTime.fromISO(start_time).toLocaleString(DateTime.DATETIME_MED)}
              -
              ${DateTime.fromISO(end_time).toLocaleString(DateTime.DATETIME_MED)}
              `}
            </div>
          </div>
        </H3>
        <FormGroup>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Files" />
        </FormGroup>
        <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          {!!(clips.data || []).length && (
            <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
              <thead>
                <tr>
                  <td>Clip ID</td>
                  <td>Start Time</td>
                  <td>End Time</td>
                  <td>File</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {(clips.data || [])
                  .filter(x => x.file_name.indexOf(query.toUpperCase()) > -1)
                  .sort((a, b) => (b.start_time).localeCompare(a.start_time))
                  .map(this.renderClip)}
              </tbody>
            </table>
          )}
        </div>
        {!(clips.data || []).length && !clips.pending && <NonIdealState icon="search" title="No Clips Found" />}
      </Card>
    );
  }
}

UploadedClips.propTypes = {
  dispatch: PropTypes.func,
  upload: PropTypes.object,
  clips: PropTypes.object,
};

export default connect(state => ({
  clips: state.clipsByTime,
}))(UploadedClips);
