import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  Button, Spinner, Classes, Overlay, Tag, NonIdealState,
  Popover, H5,
} from '@blueprintjs/core';
import { getDash, deleteDash, getDashes } from 'actions/dash';
import AddCard from '../addCard';

import CardItem from '../Card';

const classes = classNames(
  Classes.CARD,
  Classes.ELEVATION_4,
  'card-dash-overlay',
);

class Dash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardOverlay: false,
      interval: null,
      deleting: false,
    };
  }

  componentDidMount() {
    this.getData();
    const id = setInterval(this.getData, 30000);
    this.setState({ interval: id });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  @autobind
  getData() {
    const { dispatch, match } = this.props;
    dispatch(getDash(match.params.id));
  }

  @autobind
  handleDelete() {
    const { match, dispatch } = this.props;
    const { interval } = this.state;
    this.setState({ deleting: true });
    clearInterval(interval);
    dispatch(deleteDash(match.params.id))
      .then(() => dispatch(getDashes()))
      .then(() => dispatch(push('/dashboard')))
      .finally(() => this.setState({ deleting: false }));
  }

  @autobind
  toggleCardOverlay() {
    const { cardOverlay } = this.state;
    this.setState({ cardOverlay: !cardOverlay });
  }

  @autobind
  deleteDashContent() {
    return (
      <div style={{ padding: 10 }}>
        <H5>Confirm Deletion</H5>
        <p>Are you sure you want to delete this dashboard?</p>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
              Cancel
          </Button>
          <Button onClick={this.handleDelete} intent="danger" className={Classes.POPOVER_DISMISS}>
              Delete
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { dash, match, dispatch } = this.props;
    const { cardOverlay, deleting } = this.state;
    if (!dash.data && dash.pending) {
      return <Spinner size={100} />;
    }
    if (dash.data.id !== parseInt(match.params.id, 10)) {
      return <Spinner size={100} />;
    }
    const hasCards = dash.data && !!dash.data.cards;
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 20 }}>
          <div>
            <Tag minimal large>Card Type:</Tag>
            &nbsp;
            <Tag minimal intent="primary" large>
              Organization
            </Tag>
            &nbsp;
            <Tag minimal intent="success" large>
              Location
            </Tag>
            &nbsp;
            <Tag minimal intent="warning" large>
              Zone
            </Tag>
          </div>
          <div>
            {hasCards && (
              <Button
                onClick={() => dispatch(push(`/dashboard/${match.params.id}/explore`))}
                icon="geosearch"
                intent="success"
              >
                Explore Dashboard
              </Button>
            )}
            &nbsp;&nbsp;
            <Button intent="primary" icon="add" text="Add Cards" onClick={this.toggleCardOverlay} />
            &nbsp;&nbsp;
            <Popover
              content={this.deleteDashContent()}
              target={(
                <Button intent="danger" icon="trash" loading={deleting}>Dashboard</Button>
              )}
            />
          </div>
        </div>
        {hasCards && dash.data.cards.map(x => <CardItem card={x} key={x.id} dash={dash.data} />)}
        {!hasCards && <NonIdealState icon="add" title="Add Cards Above" />}
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          hasBackdrop
          usePortal
          isOpen={cardOverlay}
          onClose={this.toggleCardOverlay}
          transitionDuration={0}
          canOutsideClickClose={false}
          enforceFocus={false}
          autoFocus={false}
        >
          <div className={classes}>
            <AddCard toggleCardOverlay={this.toggleCardOverlay} dash={dash.data} />
          </div>
        </Overlay>
      </Fragment>
    );
  }
}

Dash.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  dash: PropTypes.object,
};

export default connect(state => ({
  dash: state.dash,
}))(Dash);
