import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './list';
import Zone from './zone';

export default () => (
  <Switch>
    <Route path="/zones/:id" component={Zone} />
    <Route component={List} />
  </Switch>
);
