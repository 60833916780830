/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';

import { auditClips } from 'actions/audit';

const asTrue = () => true;
const defaultRenderer = o => o.file_name;

class AssignedSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  @autobind
  onItemSelect(item) {
    const { dispatch } = this.props;
    if (item.audit.line_id && item.audit.line_id !== undefined) {
      dispatch(push(`/audits/groundtruth/${item.id}?audit=${item.audit.audit_id}&line_id=${item.audit.line_id}`));
    } else {
      dispatch(push(`/audits/groundtruth/${item.id}?audit=${item.audit.audit_id}`));
    }
  }

  @autobind
  handleOpen() {
    const { dispatch, person, audit } = this.props;
    if (person && audit) {
      this.setState({ loading: true });
      dispatch(auditClips(true, false, audit, undefined, undefined, person))
        .then(() => this.setState({ loading: false }));
    }
  }

  @autobind
  renderItem(item, { modifiers, handleClick }) {
    const { match } = this.props;
    const id = parseInt(match.params.id, 10);
    if (!item.id) {
      return null;
    }
    const itemRenderer = defaultRenderer;
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={itemRenderer(item)}
        icon={id === item.id && 'tick'}
        intent={id === item.id ? 'success' : undefined}
      />
    );
  }

  render() {
    const { clips } = this.props;
    const { loading } = this.state;
    const validClips = clips.data.filter(x => x.ground_truth_line_id === x.audit.line_id || x.ground_truth_line_id === 0);
    return (
      <Select
        noResults={!loading && <MenuItem icon="glass" intent="success" disabled text="Congrats you finished your clips" />}
        resetOnSelect
        itemRenderer={this.renderItem}
        popoverProps={{
          minimal: true, fill: true, popoverClassName: 'popover-sz', onOpening: this.handleOpen,
        }}
        filterable={false}
        itemDisabled={loading ? asTrue : undefined}
        items={loading ? [] : validClips || []}
        onItemSelect={this.onItemSelect}
      >
        <Button
          text="Remaining Assigned Clips"
          fill
          rightIcon="caret-down"
          loading={loading}
        />
      </Select>
    );
  }
}

AssignedSelect.propTypes = {
  dispatch: PropTypes.func,
  clips: PropTypes.object,
  person: PropTypes.string,
  audit: PropTypes.string,
  match: PropTypes.object,
};

export default connect(state => ({
  clips: state.auditClips,
}))(AssignedSelect);
