import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import {
  FormGroup, NonIdealState, Card, FileInput, Button,
} from '@blueprintjs/core';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

class LogoInput extends PureComponent {
  @autobind
  handleInputChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.target.files[0]);
  }

  @autobind
  handleClearLogo() {
    const { input: { onChange } } = this.props;
    onChange('');
  }

  renderWithLogo() {
    const { logo, input: { value } } = this.props;
    const fileName = _.isObject(value) ? value.name : 'Choose file...';
    return (
      <div className="with-logo">
        <img src={logo} alt="" />
        <div>
          <FileInput text={fileName} onInputChange={this.handleInputChange} />
        </div>
      </div>
    );
  }

  renderNoLogo() {
    const { input: { value } } = this.props;
    const fileName = _.isObject(value) ? value.name : 'Choose file...';
    return (
      <NonIdealState
        icon="ban-circle"
        title="No custom logo"
        description="This organization will use the default LRM logo"
        action={<FileInput text={fileName} onInputChange={this.handleInputChange} />}
      />
    );
  }

  render() {
    const {
      input,
      label,
      help,
      logo,
      meta: { touched, error },
    } = this.props;
    const xlogo = (input.value === '') ? undefined : logo;
    return (
      <FormGroup
        intent={(touched && error) ? 'danger' : undefined}
        helperText={HelperText(touched, help, error)}
        label={label || undefined}
        labelFor="text-input"
      >
        <Card className="logo-selector" style={{ textAlign: xlogo ? 'right' : undefined }}>
          {xlogo ? <Button intent="danger" icon="cross" onClick={this.handleClearLogo} /> : null}
          {xlogo ? this.renderWithLogo() : this.renderNoLogo()}
        </Card>
      </FormGroup>
    );
  }
}

LogoInput.propTypes = {
  logo: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default LogoInput;
