import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './list';
import Device from './device';
import IAP from './IAP';
import AddCamera from './addCamera';
import Models from './Models';
import AddPeplink from './addPeplink';

export default () => (
  <Switch>
    <Route path="/devices/models" component={Models} />
    <Route path="/devices/add_camera" component={AddCamera} />
    <Route path="/devices/add_peplink" component={AddPeplink} />
    <Route path="/devices/iap" component={IAP} />
    <Route path="/devices/:id" component={Device} />
    <Route component={List} />
  </Switch>
);
