import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormGroup, ContextMenu,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';

class ReidTruth extends Component {
  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  @autobind
  handleEdit() {
    const { dispatch, selectedRow } = this.props;
    ContextMenu.hide();
    dispatch(push(`/reid/${selectedRow.task_id}/groundtruth/${selectedRow.clip_name}/${selectedRow.reference_track_id}`));
  }

  render() {
    const { selectedRow } = this.props;

    const link = `/reid/${selectedRow.task_id}/groundtruth/${selectedRow.clip_name}/${selectedRow.reference_track_id}`;
    return (
      <div className="audit-context-menu">
        <div className="clip-name">
          <strong>
Clip Name:
            {' '}
            <a href={link}>{selectedRow.clip_name}</a>
          </strong>
        </div>
        <div className="clip-name">
          <strong>
Track ID:
            {' '}
            <a href={link}>{selectedRow.reference_track_id}</a>
          </strong>
        </div>
        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
          <div>
            <FormGroup label="Accepted">
              <div>{selectedRow.reid_ground_truth_aggreg.total_accepted}</div>
            </FormGroup>
          </div>
          <div>
            <FormGroup label="Rejected">
              <div>{selectedRow.reid_ground_truth_aggreg.total_rejected}</div>
            </FormGroup>
          </div>
          <div className="has-text-right">
            <Button onClick={this.handleEdit} intent="primary" type="submit" icon="tick">
              Edit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ReidTruth.propTypes = {
  dispatch: PropTypes.func,
  selectedRow: PropTypes.object,
};


export default ReidTruth;
