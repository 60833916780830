import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { H3, Button } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';
import { TextInput, FormSelectInput } from 'components/inputs';
import { getZone, patchZoneConfigs } from 'actions/site';
import { TextAreaInput } from '../inputs';

class ZoneConfig extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const {
      // eslint-disable-next-line max-len
      min_appearance_length, max_appearance_length, ci_type, grid_height, grid_width, piecewise_model,
    } = values;
    const { id } = match.params;
    return dispatch(patchZoneConfigs(id, {
      min_appearance_length: parseInt(min_appearance_length, 10),
      max_appearance_length: parseInt(max_appearance_length, 10),
      grid_height: parseInt(grid_height, 10),
      grid_width: parseInt(grid_width, 10),
      ci_type,
      piecewise_model,
    })).then(() => dispatch(getZone(id)));
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="columns" style={{ margin: '0px 0px 10px 0px' }}>
        <div className="column is-one-third">
          <div className="columns" style={{ border: '.5px solid #394B59', marginTop: 5 }}>
            <div className="column">
              <form onSubmit={handleSubmit(this.handleSave)}>
                <H3>Zone Configs</H3>
                <div className="columns">
                  <div className="column">
                    <Field
                      component={TextInput}
                      type="number"
                      name="min_appearance_length"
                      label="Min Appearance Length"
                    />
                  </div>
                  <div className="column">
                    <Field
                      component={TextInput}
                      type="number"
                      name="max_appearance_length"
                      label="Blacklist Length"
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Field
                      name="grid_width"
                      component={TextInput}
                      type="number"
                      label="Grid Width (ft)"
                    />
                  </div>
                  <div className="column">
                    <Field
                      name="grid_height"
                      component={TextInput}
                      type="number"
                      label="Grid Height (ft)"
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Field
                      component={FormSelectInput}
                      name="ci_type"
                      label="Confidence Interval Type"
                    >
                      <option value="statistical">Statistical</option>
                      <option value="max">Max</option>
                    </Field>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Field
                      name="piecewise_model"
                      component={TextAreaInput}
                      type="text"
                      rows="20"
                      label="Piecewise Model Config"
                    />
                  </div>
                </div>
                <div className="has-text-right" style={{ marginTop: '10px' }}>
                  <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ZoneConfig.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func,
  match: PropTypes.object,
};

export default reduxForm({
  form: 'zone_configs',
  enableReinitialize: true,
  validate: (values) => {
    const { piecewise_model } = values;
    const errors = {};
    try {
      JSON.parse(piecewise_model);
    } catch (e) {
      errors.piecewise_model = 'Not Valid JSON';
    }
    return errors;
  },
})(ZoneConfig);
