import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import Immutable from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import { randomColor } from 'randomcolor';

import { QUERY_DATE_FMT } from 'constants';
import { dashQuery } from 'actions/query';
import Line from './line';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const fmtTime = (x) => {
  const t = numbro(x * 60).format('00:00:00').split(':');
  let hour = parseInt(t[0], 10);
  let minute = parseInt(t[1], 10);
  let second = parseInt(t[2], 10);
  if (second === 60) {
    minute += 1;
    second = 0;
  }
  if (minute === 24) {
    hour += 1;
    minute = 0;
  }
  const time = moment({ hour, minute, second }).format('HH:mm:ss').split(':');
  if (time[0] === '00') {
    return `${time[1]}m ${time[2]}s`;
  }
  return `${time[0]}h ${time[1]}m ${time[2]}s`;
};

class WaitChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: this.getParameters(props),
      datasets: {},
      colors: props.selectedZones.map(({ id }) => ({ id, color: randomColor({ format: 'hsl', hue: 'random' }) })),
    };
  }

  componentDidMount() {
    const { parameters } = this.state;
    this.fetchData(parameters);
  }

  componentWillReceiveProps(nextProps) {
    const { parameters, colors } = this.state;
    const { data: currentData, selectedZones } = this.props;
    const newParameters = parameters.merge(this.getParameters(nextProps));
    if (newParameters !== parameters) {
      this.setState({ parameters: newParameters });
      this.fetchData(newParameters);
    }
    if (!_.isEqual(currentData, nextProps.data) && nextProps.data) {
      const datasets = _.flatten(Object.values(
        _.mapValues(nextProps.data, (a, b) => [
          {
            label: (selectedZones.find(x => x.id === parseInt(b.split('-')[1], 10)) || {}).name,
            data: a && a.response && a.response.content && a.response.content.rows
              ? [...a.response.content.rows]
                .sort((c, d) => c[0].localeCompare(d[0])).map(x => x[1]) : [],
            fill: false,
            pointRadius: 0,
            lineTension: 0,
            borderWidth: 1.5,
            borderColor: (colors.find(x => x.id === parseInt(b.split('-')[1], 10)) || {}).color,
          },
        ]),
      ));
      const labelSample = ((((Object.values(nextProps.data) || [])[0] || {}).response || {})
        .content || {}).rows || [];
      const xfmt3 = ['minute', 'hour'].includes(nextProps.dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
      const labels = labelSample.length
        ? [...labelSample].sort((a, b) => a[0].localeCompare(b[0])).map(xfmt3)
        : [];
      const chartDataset = {
        datasets,
        labels,
      };
      this.setState({ datasets: chartDataset });
    }
  }

  getParameters(props) {
    const {
      startDate, endDate, dimension,
    } = props;
    const start = dimension === 'day' ? moment(startDate).clone().subtract(1, 'd') : startDate;
    return Immutable.Map(_.pickBy({
      startTime: fmtTimeParam(start),
      endTime: fmtTimeParam(endDate),
      dimension,
      metrics: 'waitTime',
    }, p => !_.isUndefined(p)));
  }

  @autobind
  fetchData(parameters) {
    const { selectedZones, dispatch } = this.props;
    return Promise.all(selectedZones.map(({ id }) => dispatch(dashQuery(
      id,
      'query',
      parameters.get('startTime'),
      parameters.get('endTime'),
      parameters.get('dimension'),
      parameters.get('metrics'),
    ))));
  }

  render() {
    const { datasets } = this.state;
    const { dimension } = this.props;

    return (
      <div style={{ minHeight: 500 }}>
        <Line
          data={() => datasets}
          legend
          position="top"
          yLabel=""
          yFmtr={fmtTime}
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

WaitChart.propTypes = {
  dispatch: PropTypes.func,
  selectedZones: PropTypes.array,
  data: PropTypes.object,
  dimension: PropTypes.string,
};

export default connect((state, { selectedZones }) => ({
  data: _.pickBy(state.dashQuery, (v, k) => selectedZones.map(x => `query-${x.id}`).includes(k)),
}))(WaitChart);
