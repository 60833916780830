import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H3, NonIdealState } from '@blueprintjs/core';

const MetricsChart = ({ chart }) => {
  if (!chart) {
    return <NonIdealState icon="warning-sign" title="No Data Found" className="non-ideal-device-card" />;
  }
  return (
    <Fragment>
      <H3>Training Metrics</H3>
      <br />
      <div className="row" style={{ textAlign: 'left' }}>
        <img src={chart} alt="metrics_chart" />
      </div>
      <br />
    </Fragment>
  );
};

MetricsChart.propTypes = {
  chart: PropTypes.string,
};

export default MetricsChart;
