import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, ButtonGroup, TextArea,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AppToaster } from 'components/Toaster';
import qs from 'query-string';

const NONE = 'NONE';
const SUBMIT = 'submit';
const DELETE = 'delete';
const CURRENT = 'current';
const VERTICAL_16X9 = 'vertical-16x9';
const HORIZONTAL_16X9 = 'horizontal-16x9';
const CUSTOM = 'custom';

class Mosaic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button: NONE,
      loading: false,
      config: JSON.stringify({
        dim_x: -1,
        dim_y: -1,
        pieces: [{
          x: -1, y: -1, len_x: -1, len_y: -1, content_url: '',
        }],
      }, null, 4),
    };
  }

  @autobind
  handleCurrent() {
    const {
      accessToken, device,
    } = this.props;
    const { button, loading } = this.state;
    const vars = { access_token: accessToken };
    if (button !== CURRENT && loading) {
      return;
    }
    this.setState({ button: CURRENT, loading: true });
    axios.get(`v1/devices/${device.id}/mosaic?${qs.stringify(vars)}`)
      .then((resp) => {
        this.setState({ config: JSON.stringify(resp.data.content, null, 4) });
      })
      .catch((error) => {
        if (error == null) {
          return;
        }
        if ((error.response || {}).status === 404) {
          AppToaster.show({
            icon: 'warning-sign',
            message: (
              <span>No config</span>
            ),
            intent: 'warning',
          });
          return;
        }
        AppToaster.show({
          icon: 'error',
          message: (
            <span>{error.message || 'unknown'}</span>
          ),
          intent: 'error',
        });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  @autobind
  handleVertical() {
    const { button, loading } = this.state;
    if (button !== CURRENT && loading) {
      return;
    }
    this.setState({
      button: CUSTOM,
      loading: false,
      config: JSON.stringify({
        dim_x: 9,
        dim_y: 16,
        pieces: [{
          x: 0, y: 0, len_x: 9, len_y: 8, content_url: '',
        },
        {
          x: 0, y: 8, len_x: 9, len_y: 8, content_url: '',
        },
        ],
      }, null, 4),
    });
  }

  @autobind
  handleHorizontal() {
    const { button, loading } = this.state;
    if (button !== CURRENT && loading) {
      return;
    }
    this.setState({
      button: CUSTOM,
      loading: false,
      config: JSON.stringify({
        dim_x: 16,
        dim_y: 9,
        pieces: [{
          x: 0, y: 0, len_x: 8, len_y: 9, content_url: '',
        },
        {
          x: 8, y: 0, len_x: 8, len_y: 9, content_url: '',
        },
        ],
      }, null, 4),
    });
  }

  @autobind
  handleCustom() {
    const { button, loading } = this.state;
    if (button !== CURRENT && loading) {
      return;
    }
    this.setState({
      button: CUSTOM,
      loading: false,
      config: JSON.stringify({
        dim_x: -1,
        dim_y: -1,
        pieces: [{
          x: -1, y: -1, len_x: -1, len_y: -1, content_url: '',
        }],
      }, null, 4),
    });
  }

  @autobind
  handleOnChange(event) {
    this.setState({ config: event.target.value });
  }

  @autobind
  handleSubmit() {
    const {
      accessToken, device,
    } = this.props;
    const { button, config, loading } = this.state;
    const vars = { access_token: accessToken, device_id: device.id };
    if (button !== SUBMIT && loading) {
      return;
    }
    let x;
    try {
      x = JSON.parse(config);
    } catch (e) {
      AppToaster.show({
        icon: 'error',
        message: (
          <span>{e.message || 'unknown'}</span>
        ),
        intent: 'error',
      });
      return;
    }

    this.setState({ button: SUBMIT, loading: true });
    axios.get(`v1/devices/${device.id}/mosaic?${qs.stringify(vars)}`)
      .then((resp) => {
        const { id } = resp.data.content;
        x.id = id;
        axios.put(`v1/cms/mosaics?${qs.stringify(vars)}`, x)
          .then(() => {
            AppToaster.show({
              icon: 'tick',
              message: (
                <span>
                  <strong>
                    Updated mosaic
                  </strong>
                </span>
              ),
              intent: 'success',
            });
            axios.get(`v1/devices/${device.id}/mosaic?${qs.stringify(vars)}`)
              .then((r) => {
                this.setState({ config: JSON.stringify(r.data.content, null, 4) });
              })
              .catch((error) => {
                if (error == null) {
                  return;
                }
                if ((error.response || {}).status === 404) {
                  AppToaster.show({
                    icon: 'warning-sign',
                    message: (
                      <span>Failed to get updated config</span>
                    ),
                    intent: 'warning',
                  });
                  return;
                }
                AppToaster.show({
                  icon: 'error',
                  message: (
                    <span>{error.message || 'unknown'}</span>
                  ),
                  intent: 'error',
                });
              });
          })
          .catch((error) => {
            AppToaster.show({
              icon: 'error',
              message: (
                <span>{error.message || 'unknown'}</span>
              ),
              intent: 'error',
            });
            return -1;
          })
          .then(() => { this.setState({ loading: false }); });
      })
      .catch((error) => {
        if ((error.response || {}).status === 404) {
          delete x.id;
          axios.post(`v1/cms/mosaics?${qs.stringify(vars)}`, x)
            .then(() => {
              AppToaster.show({
                icon: 'tick',
                message: (
                  <span>
                    <strong>
                    Created new mosaic
                    </strong>
                  </span>
                ),
                intent: 'success',
              });
              axios.get(`v1/devices/${device.id}/mosaic?${qs.stringify(vars)}`)
                .then((r) => {
                  this.setState({ config: JSON.stringify(r.data.content, null, 4) });
                })
                .catch((e) => {
                  if (e == null) {
                    return;
                  }
                  if ((error.response || {}).status === 404) {
                    AppToaster.show({
                      icon: 'warning-sign',
                      message: (
                        <span>Failed to get updated config</span>
                      ),
                      intent: 'warning',
                    });
                    return;
                  }
                  AppToaster.show({
                    icon: 'error',
                    message: (
                      <span>{error.message || 'unknown'}</span>
                    ),
                    intent: 'error',
                  });
                });
            })
            .catch((e) => {
              AppToaster.show({
                icon: 'error',
                message: (
                  <span>{e.message || 'unknown'}</span>
                ),
                intent: 'error',
              });
            })
            .then(() => { this.setState({ loading: false }); });
          return;
        }
        AppToaster.show({
          icon: 'error',
          message: (
            <span>{error.message || 'unknown'}</span>
          ),
          intent: 'error',
        });
      })
      .then(() => this.setState({ loading: false }));
  }

  @autobind
  handleDelete() {
    const {
      accessToken, device,
    } = this.props;
    const { button, loading } = this.state;
    const vars = { access_token: accessToken, device_id: device.id };
    if (button !== DELETE && loading) {
      return;
    }
    this.setState({ button: DELETE, loading: true });


    axios.get(`v1/devices/${device.id}/mosaic?${qs.stringify(vars)}`)
      .then((resp) => {
        const { id } = resp.data.content;
        switch (id) {
          case undefined:
            AppToaster.show({
              icon: 'warning-sign',
              message: (
                <span>Nothing to delete</span>
              ),
              intent: 'warning',
            });
            break;
          case -1:
            break;
          case 0:
            break;
          default:
            axios.delete(`v1/cms/mosaics/${id}?${qs.stringify(vars)}`)
              .then(() => {
                AppToaster.show({
                  icon: 'tick',
                  message: (
                    <span>
                      <strong>
                    Deleted mosaic
                      </strong>
                    </span>
                  ),
                  intent: 'success',
                });
                this.setState({
                  config: JSON.stringify({
                    dim_x: -1,
                    dim_y: -1,
                    pieces: [{
                      x: -1, y: -1, len_x: -1, len_y: -1, content_url: '',
                    }],
                  }, null, 4),
                });
              })
              .catch((error) => {
                if (error == null) {
                  return;
                }
                if ((error.response || {}).status === 404) {
                  AppToaster.show({
                    icon: 'warning-sign',
                    message: (
                      <span>No config</span>
                    ),
                    intent: 'warning',
                  });
                  return;
                }
                AppToaster.show({
                  icon: 'error',
                  message: (
                    <span>{error.message || 'unknown'}</span>
                  ),
                  intent: 'error',
                });
              })
              .then(() => {
                this.setState({ loading: false });
              });
        }
      })
      .catch((error) => {
        if ((error.response || {}).status === 404) {
          return 0;
        }
        AppToaster.show({
          icon: 'error',
          message: (
            <span>{error.message || 'unknown'}</span>
          ),
          intent: 'error',
        });
        return -1;
      })
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const {
      button, config, loading,
    } = this.state;
    return (
      <div>
        <ButtonGroup className="is-pulled-left" fill>
          <Button
            large
            icon="download"
            loading={button === CURRENT && loading}
            disabled={button !== CURRENT && loading}
            onClick={this.handleCurrent}
          >
            Current
          </Button>
          <Button
            large
            icon="arrows-vertical"
            disabled={button !== VERTICAL_16X9 && loading}
            onClick={this.handleVertical}
          >
            Vertical (16x9)
          </Button>
          <Button
            large
            icon="arrows-horizontal"
            disabled={button !== HORIZONTAL_16X9 && loading}
            onClick={this.handleHorizontal}
          >
           Horizontal (16x9)
          </Button>
          <Button
            large
            icon="build"
            disabled={button !== CUSTOM && loading}
            onClick={this.handleCustom}
          >
            Custom
          </Button>
        </ButtonGroup>
        <TextArea
          growVertically
          large
          fill
          value={config}
          disabled={loading}
          onChange={this.handleOnChange}
        />
        <Button
          large
          loading={button === SUBMIT && loading}
          disabled={button !== SUBMIT && loading}
          onClick={this.handleSubmit}
        >
           Submit
        </Button>
        <Button
          large
          loading={button === DELETE && loading}
          disabled={button !== DELETE && loading}
          onClick={this.handleDelete}
        >
      Delete
        </Button>
      </div>
    );
  }
}

Mosaic.propTypes = {
  accessToken: PropTypes.string,
  device: PropTypes.object,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
}))(Mosaic);
