import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { autobind } from 'core-decorators';
import {
  H1, Button, Callout,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { TextInput, TextAreaInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';
import { push } from 'connected-react-router';

import {
  updateModel, createModel, getModel, getModels,
} from 'actions/audit';

class CreateModel extends Component {
  @autobind
  handleSave(values) {
    const { modelId, dispatch, isEdit } = this.props;
    if (isEdit) {
      return dispatch(updateModel(modelId, values))
        .then(() => {
          dispatch(getModel(modelId));
          dispatch(getModels());
        })
        .then(() => AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                Model Updated
              </strong>
            </span>
          ),
          intent: 'success',
        }));
    }
    return dispatch(createModel(values))
      .then(() => dispatch(getModels())
        .then(() => dispatch(push('/devices/models')))
        || AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                Model Created
              </strong>
            </span>
          ),
          intent: 'success',
        }));
  }

  render() {
    const {
      submitting, handleSubmit, title, isEdit, error,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSave)} className="container" autoComplete="off">
        <div className="columns">
          <div className="column">
            <H1>
              { title || 'Create Model' }
            </H1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              component={TextInput}
              placeholder=""
              name="id"
              label="Model ID"
              type="text"
              disabled={isEdit}
            />
            <Field
              component={TextInput}
              placeholder=""
              name="model_alias"
              label="Model Alias"
              type="text"
            />
            <Field
              component={TextInput}
              placeholder=""
              name="location"
              label="Location"
              type="text"
            />
            <Field
              component={TextInput}
              placeholder=""
              name="model_type"
              label="Model Type"
              type="text"
              disabled={isEdit}
            />
            <Field
              component={TextInput}
              placeholder=""
              name="config"
              label="Config"
              type="text"
            />
          </div>
          <div className="column">
            <Field
              component={TextAreaInput}
              rows={10}
              placeholder=""
              name="description"
              label="Description"
              type="text"
              id="create-model-text-area"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {error && !submitting && <Callout icon="error" intent="danger" title="Error" className="m-b-md">{error}</Callout>}
            <div className="has-text-right">
              <Link to="/devices/models" className="bp3-button m-r-md">Cancel</Link>
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CreateModel.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  modelId: PropTypes.string,
};

export default reduxForm({
  form: 'create-model',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.id) {
      errors.id = 'Required';
    }
    if (!values.location) {
      errors.location = 'Required';
    }
    return errors;
  },
})(CreateModel);
