import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { RangePicker } from 'antd/lib/date-picker';
import { DateTime } from 'luxon';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import {
  Menu, MenuItem, Card, Elevation, H3, Button,
  H6, FormGroup, InputGroup, Spinner, NonIdealState,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { getClipsSummary } from 'actions/audit';
import { getOrganizations } from 'actions/organization';

const fmtr = x => numbro(x).format('0,0');
const defaultRenderer = o => o.name;
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;

class AuditSummary extends Component {
  constructor(props) {
    super(props);
    const userProfile = props.currentUser.profile;
    this.state = {
      startDate: DateTime.utc().minus({ days: 7 }),
      endDate: DateTime.utc(),
      orgId: userProfile.organization_id,
      query: '',
      rows: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { startDate, endDate, orgId } = this.state;
    const start = startDate.startOf('second').toISO({ suppressMilliseconds: true });
    const end = endDate.startOf('second').toISO({ suppressMilliseconds: true });
    dispatch(getClipsSummary(start, end, orgId));
    if (orgId === 1) dispatch(getOrganizations());
  }

  componentWillReceiveProps(nextProps) {
    const { clipsSummary } = this.props;
    if (!_.isEqual(clipsSummary, nextProps.clipsSummary) && !nextProps.clipsSummary.pending) {
      const rows = [];
      Object.entries(nextProps.clipsSummary.data).forEach(([key, value]) => {
        if (value.complete > 0 || value.incomplete > 0) {
          rows.push({ assignee: key, complete: value.complete, incomplete: value.incomplete });
        }
      });
      this.setState({ rows });
    }
  }

  @autobind
  onItemSelect(item) {
    this.setState({ orgId: item.id }, this.updateData);
  }

  @autobind
  handleQuery(e) {
    const q = e.target.value.toLowerCase();
    this.setState({ query: q });
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    });
  }

  @autobind
  handleDateOk() {
    this.updateData();
  }

  @autobind
  updateData() {
    const { dispatch } = this.props;
    const { startDate, endDate, orgId } = this.state;
    const start = startDate.startOf('second').toISO({ suppressMilliseconds: true });
    const end = endDate.startOf('second').toISO({ suppressMilliseconds: true });
    dispatch(getClipsSummary(start, end, orgId));
  }

  @autobind
  cellRenderer(index, title) {
    const { query, rows } = this.state;
    const filteredData = rows.filter(x => x.assignee.toLowerCase().includes(query));
    const row = filteredData[index];
    if (row) {
      if (title === 'assignee') {
        return <Cell wrapText>{row.assignee || ''}</Cell>;
      }
      if (title === 'complete') {
        return <Cell wrapText>{fmtr(row.complete) || ''}</Cell>;
      }
      if (title === 'incomplete') {
        return <Cell wrapText>{fmtr(row.incomplete) || ''}</Cell>;
      }
    }
    return <Cell />;
  }

  @autobind
  sortAsc(column) {
    const { rows } = this.state;
    let sortedData;
    if (column === 0) {
      sortedData = [...rows]
        .sort((a, b) => a.assignee.localeCompare(b.assignee));
    }
    if (column === 1) {
      sortedData = [...rows]
        .sort((a, b) => a.complete - b.complete);
    }
    if (column === 2) {
      sortedData = [...rows]
        .sort((a, b) => a.incomplete - b.incomplete);
    }
    this.setState({ rows: sortedData });
  }

  @autobind
  sortDesc(column) {
    const { rows } = this.state;
    let sortedData;
    if (column === 0) {
      sortedData = [...rows]
        .sort((a, b) => b.assignee.localeCompare(a.assignee));
    }
    if (column === 1) {
      sortedData = [...rows]
        .sort((a, b) => b.complete - a.complete);
    }
    if (column === 2) {
      sortedData = [...rows]
        .sort((a, b) => b.incomplete - a.incomplete);
    }
    this.setState({ rows: sortedData });
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderItem(item, { modifiers, handleClick }) {
    if (!item.id) {
      return null;
    }
    const itemRenderer = defaultRenderer;
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const { clipsSummary, organizations } = this.props;
    const {
      query, rows, orgId, startDate, endDate,
    } = this.state;
    if (organizations.pending) {
      return <Spinner size="100" />;
    }
    const filteredData = rows.filter(x => x.assignee.toLowerCase().includes(query));
    const currentOrg = organizations.data.find(x => x.id === orgId) || {};
    let dataRender;
    if (clipsSummary.pending || !clipsSummary.data) {
      dataRender = <Spinner size="100" />;
    } else if (rows.length === 0) {
      dataRender = <NonIdealState icon="clean" title="No data for selected parameters" className="non-ideal-device-card" />;
    } else {
      dataRender = (
        <div style={{ marginTop: 20 }}>
          <Table
            numRows={filteredData.length}
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Assignee" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'assignee')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Completed Clips" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'complete')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Incomplete Clips" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'incomplete')}
            />
          </Table>
        </div>
      );
    }
    return (
      <div className="container">
        <Card elevation={Elevation.FOUR} style={{ marginBottom: 20 }}>
          <H3>Individual performance for assigned clips</H3>
          <div className="columns flex-space-evenly-container align-center-container">
            {orgId === 1 ? (
              <div className="column is-half">
                <H6>Organization</H6>
                <Select
                  items={organizations.data || []}
                  itemRenderer={this.renderItem}
                  popoverProps={{
                    minimal: true, fill: true, popoverClassName: 'popover-sz',
                  }}
                  onItemSelect={this.onItemSelect}
                  filterable
                  resetOnSelect
                  itemPredicate={defaultFilter}
                >
                  <Button
                    text={currentOrg.name}
                    rightIcon="caret-down"
                    fill
                    style={{ height: '32px' }}
                  />
                </Select>
              </div>
            ) : null}
            <div className="column is-half align-center-container">
              <div className="rows">
                <div className="row">
                  <H6>Date Range</H6>
                </div>
                <div className="row">
                  <RangePicker
                    value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                    format="lll"
                    style={{ width: '500px' }}
                    showTime
                    onChange={this.handleDateChange}
                    onOk={this.handleDateOk}
                    allowClear={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <FormGroup style={{ margin: '10px 0px' }}>
            <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search ..." />
          </FormGroup>
          {dataRender}
        </Card>
      </div>
    );
  }
}

AuditSummary.propTypes = {
  dispatch: PropTypes.func,
  clipsSummary: PropTypes.object,
  organizations: PropTypes.object,
  currentUser: PropTypes.object,
};

export default connect(state => ({
  clipsSummary: state.clipsSummary,
  organizations: state.organizations,
  currentUser: state.currentUser,
}))(AuditSummary);
