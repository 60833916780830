import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import {
  Spinner, H3, H5,
} from '@blueprintjs/core';
import _ from 'lodash';

import { compareAuditSummary } from 'actions/audit';

class Summarize extends Component {
  async componentDidMount() {
    const { dispatch, compareIds } = this.props;
    if (compareIds.length) {
      return Promise.all(compareIds.map(x => dispatch(compareAuditSummary(`s-${x}`, x))));
    }
    return null;
  }

  @autobind
  renderSummaryRow(item) {
    const {
      audit_id, in_residual_total, mae_total, model_ins_total, model_occupancy,
      model_outs_total, net_movement_error, out_residual_total, true_ins_total,
      true_occupancy, true_outs_total,
    } = item;
    return (
      <tr key={audit_id}>
        <td>{audit_id}</td>
        <td>{model_ins_total}</td>
        <td>{model_outs_total}</td>
        <td>{true_ins_total}</td>
        <td>{true_outs_total}</td>
        <td>{in_residual_total}</td>
        <td>{out_residual_total}</td>
        <td>{mae_total}</td>
        <td>{net_movement_error}</td>
        <td>{model_occupancy}</td>
        <td>{true_occupancy}</td>
      </tr>
    );
  }

  @autobind
  renderSummaryReport() {
    const { summaryData } = this.props;
    const auditSummaries = Object.values(summaryData).map(x => x.response);
    return (
      <table className={classNames('bp3-html-table bp3-html-table-striped bp3-html-table-bordered', { 'bp3-skeleton': false })}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Model Ins</th>
            <th>Model Outs</th>
            <th>True Ins</th>
            <th>True Outs</th>
            <th>In Residual</th>
            <th>Out Residual</th>
            <th>MAE Residual</th>
            <th>Net Movement Error</th>
            <th>Model Ending Occ.</th>
            <th>True Ending Occ.</th>
          </tr>
        </thead>
        <tbody>
          {(auditSummaries || []).map(this.renderSummaryRow)}
        </tbody>
      </table>
    );
  }

  render() {
    const { summaryData } = this.props;
    const loaded = _.every(summaryData, { resolved: true });
    if (!loaded) {
      return (
        <div className="center-text summary">
          <H5>Loading Audit Summary...</H5>
          <Spinner size={100} />
        </div>
      );
    }
    return (
      <div style={{ margin: '20px 0px 40px 0px' }}>
        <H3>Summary</H3>
        {this.renderSummaryReport()}
      </div>
    );
  }
}

Summarize.propTypes = {
  dispatch: PropTypes.func,
  summaryData: PropTypes.object,
};

export default connect((state, { compareIds }) => ({
  summaryData: _.pickBy(state.compareAuditSummary, (v, k) => compareIds.includes(k.split('-')[1])),
}))(Summarize);
