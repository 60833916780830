import {
  GET_ALERTS, PATCH_ALERT, DELETE_ALERT, CREATE_ALERT,
  GET_ALERT, GET_ACTIVE_ALERTS, PATCH_ALERT_STATUS,
} from '../constants';

export const getAlerts = () => ({
  type: GET_ALERTS,
  payload: {
    request: {
      method: 'get',
      url: 'https://overwatch.livereachmedia.com/api/v1/alerts',
    },
  },
});

export const patchAlert = (id, data) => ({
  type: PATCH_ALERT,
  payload: {
    request: {
      method: 'patch',
      url: `https://overwatch.livereachmedia.com/api/v1/alerts/${id}`,
      data,
    },
  },
});

export const deleteAlert = id => ({
  type: DELETE_ALERT,
  payload: {
    request: {
      method: 'delete',
      url: `https://overwatch.livereachmedia.com/api/v1/alerts/${id}`,
    },
  },
});

export const createAlert = data => ({
  type: CREATE_ALERT,
  payload: {
    request: {
      method: 'put',
      url: 'https://overwatch.livereachmedia.com/api/v1/alerts',
      data,
    },
  },
});

export const getAlert = id => ({
  type: GET_ALERT,
  payload: {
    request: {
      method: 'get',
      url: `https://overwatch.livereachmedia.com/api/v1/alerts/${id}`,
    },
  },
});

export const getActiveAlerts = () => ({
  type: GET_ACTIVE_ALERTS,
  payload: {
    request: {
      method: 'get',
      url: 'https://overwatch.livereachmedia.com/api/v1/alerts/active',
    },
  },
});

export const patchAlertStatus = (id, objectId, data) => ({
  type: PATCH_ALERT_STATUS,
  payload: {
    request: {
      method: 'patch',
      url: `https://overwatch.livereachmedia.com/api/v1/alerts/${id}/${objectId}`,
      data,
    },
  },
});
