
import { DateTime } from 'luxon';
import {
  UPDATE_DATERANGE,
} from '../constants';

const startDate = DateTime.utc().minus(3600000);
const endDate = DateTime.utc();

const initialState = { startDate, endDate };

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATERANGE: {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }
    default:
      return state;
  }
}
