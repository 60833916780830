import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  H1, Tag, Spinner, Icon, H3, Tooltip, Menu, MenuItem, FormGroup, InputGroup,
  Button, Switch,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import _ from 'lodash';
import numbro from 'numbro';
import ReactPaginate from 'react-paginate-bp3';
import { AppToaster } from 'components/Toaster';
import ROCChart from './rocChart';
import {
  getReidAuditTask, getReidAuditTasks,
  getReidAuditTaskSummary, getReidAuditTaskTracks, patchReidAuditTask,
  extractReidAuditTask, deleteReidAuditTaskOutput,
} from '../../../actions/reid';
import ReidMatrix from './matrix';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

const fmtr = x => numbro(x).format('0,0');

const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

const INDEX_MAP = {
  0: 'value',
  1: 'key',
  2: 'row.tp',
  3: 'row.fp',
  4: 'row.fn',
  5: 'row.gt_id',
};

class ReidAuditSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: '',
      operator: '',
      condition: '',
      distance: true,
      thresh: 0.5,
      tableLoading: true,
      startIndex: 0,
      endIndex: 500,
      page: 0,
      extractLoading: false,
      clearLoading: false,
    };
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    this.getData();
  }

  @autobind
  getData() {
    const { dispatch, match, reidAuditTasks } = this.props;
    const { id } = match.params;
    const {
      thresh, startIndex, endIndex, distance,
    } = this.state;
    const metric = distance ? 'eucledian' : 'cosine';
    // eslint-disable-next-line max-len
    dispatch(getReidAuditTaskTracks(id, thresh, metric, startIndex, endIndex)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content, tableLoading: false });
      }
    });
    dispatch(getReidAuditTaskSummary(id));
    dispatch(getReidAuditTask(id));
    if (!(reidAuditTasks) || !(reidAuditTasks.data)) {
      dispatch(getReidAuditTasks());
    }
  }

  @autobind
  refresh() {
    const { dispatch, match } = this.props;
    const {
      startIndex, endIndex, thresh, distance,
    } = this.state;
    this.setState({ tableLoading: true });
    const metric = distance ? 'eucledian' : 'cosine';
    // eslint-disable-next-line max-len
    dispatch(getReidAuditTaskTracks(match.params.id, thresh, metric, startIndex, endIndex)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content, tableLoading: false }, this.refreshTable());
      }
    });
  }

  @autobind
  refreshTable() {
    const {
      filter, operator, condition, data,
    } = this.state;
    // const { reidAuditTracks } = this.props;
    let auditData = [...data];
    const filteredData = (() => {
      const intCondition = parseInt(condition, 10);
      if (intCondition != null && filter) {
        auditData = data.filter((x) => {
          const column = x.row[filter];
          switch (operator) {
            case '>':
              return column > intCondition;
            case '<':
              return column < intCondition;
            case '=':
              return column === intCondition;
            case '!=':
              return column !== intCondition;
            default:
              break;
          }
          return x;
        });
      }
      return auditData;
    })();
    return this.setState({ data: filteredData });
  }

  @autobind
  async extractProtobuf() {
    const { dispatch, match, reidAuditTask } = this.props;
    this.setState({ extractLoading: true });
    if (reidAuditTask.data.task_status !== 1) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Reid Job Not Complete</span>,
        intent: 'danger',
      });
    }
    return dispatch(extractReidAuditTask(match.params.id))
      .then(() => (this.delayGet(5000).then(() => this.getData())))
      .finally(() => this.setState({ extractLoading: false }));
  }

  @autobind
  async clearOutput() {
    const { dispatch, match } = this.props;
    this.setState({ clearLoading: true });
    return dispatch(deleteReidAuditTaskOutput(match.params.id))
      .then(() => this.getData())
      .finally(() => this.setState({ clearLoading: false }));
  }

  @autobind
  handleFilter(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({ filter: value, operator: '', condition: '' }, this.refreshTable);
    }
    this.setState({ filter: value });
  }

  @autobind
  handleOperator(e) {
    this.setState({ operator: e.target.value });
  }

  @autobind
  handleCondition(e) {
    this.setState({ condition: e.target.value });
  }

  @autobind
  applyFilter() {
    const {
      data, filter, operator, condition,
    } = this.state;
    const intCondition = parseInt(condition, 10);
    if (intCondition != null) {
      const filteredData = data.filter((x) => {
        const column = x[filter];
        switch (operator) {
          case '>':
            return column > intCondition;
          case '<':
            return column < intCondition;
          case '=':
            return column === intCondition;
          case '!=':
            return column !== intCondition;
          default:
            break;
        }
        return x;
      });
      this.setState({ data: filteredData });
    }
  }

  @autobind
  sortAsc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 1].includes(column)) {
        return a[INDEX_MAP[column]].localeCompare(b[INDEX_MAP[column]]);
      }
      return a[INDEX_MAP[column]] - b[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  sortDesc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 1].includes(column)) {
        return b[INDEX_MAP[column]].localeCompare(a[INDEX_MAP[column]]);
      }
      return b[INDEX_MAP[column]] - a[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  pauseAudit() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchReidAuditTask(id, { status: 4 }))
      .then(() => dispatch(getReidAuditTask(id))
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`task ${id} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeAudit() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchReidAuditTask(id, { status: 0 }))
      .then(() => dispatch(getReidAuditTask(id))
      && AppToaster.show({
        icon: 'play',
        message: <span>{`task ${id} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  cellRenderer(index, title, child) {
    const { data } = this.state;
    const r = data[index];
    if (r && !child) {
      return <Cell tooltip={r[title]}>{r[title]}</Cell>;
    } if (r) {
      return <Cell tooltip={r[title]}>{r.row[title]}</Cell>;
    }
    return <Cell />;
  }

  @autobind
  handleThreshold(e) {
    this.setState({ thresh: e.target.value });
  }

  @autobind
  handleThresholdSave() {
    const { dispatch, match } = this.props;
    const {
      thresh, startIndex, endIndex, distance,
    } = this.state;
    const { id } = match.params;
    this.setState({ tableLoading: true });
    const metric = distance === 0 ? 'eucledian' : 'cosine';
    // eslint-disable-next-line max-len
    dispatch(getReidAuditTaskTracks(id, thresh, metric, startIndex, endIndex)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content, tableLoading: false }, this.doRefresh);
      }
    });
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({
      page: selected, startIndex: (selected * 500), endIndex: ((selected + 1) * 500),
    }, this.doRefresh);
  }

  @autobind
  handleDistanceSwitch() {
    const { distance } = this.state;
    this.setState({ distance: !distance }, this.doRefresh);
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderContextMenu(a) {
    const { data, thresh, distance } = this.state;
    const { dispatch, reidAuditTask, match } = this.props;
    if (a.target && a.target.rows) {
      const selectedRow = data.find((x, i) => i === a.target.rows[0]);
      if (selectedRow) {
        return (
          <ReidMatrix
            selectedRow={selectedRow}
            region={reidAuditTask.data.region}
            task_id={match.params.id}
            thresh={thresh}
            distance={distance}
            dispatch={dispatch}
          />
        );
      }
    }
    return undefined;
  }

  @autobind
  renderFilters() {
    const {
      filter, operator, condition,
    } = this.state;
    return (
      <div className="flex-space-between-container" style={{ alignItems: 'center' }}>
        <div className="audit-filter-container">
          <FormGroup label="Metric Filter" className="filter-form-group">
            <div className="bp3-select">
              <select name="filter" value={filter} onChange={this.handleFilter}>
                <option value="">--</option>
                <option value="fp">False Positives</option>
                <option value="fn">False Negatives</option>
                <option value="tp">True Positives</option>
              </select>
            </div>
          </FormGroup>
          {!!filter && (
            <FormGroup label="Operator" className="filter-form-group">
              <div className="bp3-select">
                <select name="operator" className="bp3-select" onChange={this.handleOperator}>
                  <option value="">--</option>
                  <option value="<">{'<'}</option>
                  <option value=">">{'>'}</option>
                  <option value="=">=</option>
                  <option value="!=">!=</option>
                </select>
              </div>
            </FormGroup>
          )}
          {!!operator && !!filter && (
            <div className="submit-group">
              <FormGroup label="Condition" className="filter-form-group">
                <InputGroup type="number" name="condition" value={condition} onChange={this.handleCondition} />
              </FormGroup>
              <Button onClick={this.refreshTable} style={{ margin: '5px 0px 0px 10px' }} icon="tick" intent="primary" />
            </div>
          )}
        </div>
      </div>
    );
  }

  @autobind
  renderTracksTable() {
    const { data, page } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <div className="flex-space-between-container">
          <H3>Tracks</H3>
        </div>
        {this.renderFilters()}
        <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
          <ReactPaginate
            previousLabel={<Icon icon="chevron-left" />}
            nextLabel={<Icon icon="chevron-right" />}
            breakLabel="..."
            pageCount={8}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={page}
            onPageChange={this.handlePageClick}
            containerClassName="bp3-button-group"
            pageClassName="bp3-button"
            previousClassName="bp3-button"
            nextClassName="bp3-button"
            disabledClassName="bp3-disabled"
            breakClassName="bp3-button bp3-disabled"
            activeClassName="bp3-active"
          />
        </div>
        <div className="columns" style={{ marginTop: 20, height: 400 }}>
          <Table
            columnWidths={[350, 105, 180, 205, 210, 105]}
            numFrozenColumns={1}
            numRows={data.length}
            class="bp3-html-table bp3-table-resize-horizontal"
            getCellClipboardData={(row, col) => data[row][INDEX_MAP[col]]}
            bodyContextMenuRenderer={a => this.renderContextMenu(a)}
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Track ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'value', false)}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Type" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'key', false)}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="True Positive" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'tp', true)}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="False Positive" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'fp', true)}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="False Negative" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'fn', true)}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Ground Truth ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'gt_id', true)}
            />
          </Table>
        </div>
      </div>
    );
  }

  @autobind
  renderChart() {
    const { reidAuditSummary } = this.props;
    const { distance } = this.state;
    const recall = [];
    const cost = [];
    ([...reidAuditSummary.data] || []).sort((a, b) => a.id < b.id)
      .filter(d => d.dist_type === (distance === true ? 'euc' : 'cos'))
      .forEach((x) => {
        recall.push(parseFloat((x.tp / (x.tp + x.fn)).toFixed(4)));
        cost.push(x.thresh);
      });
    const datasets = [
      {
        label: 'Recall',
        data: recall,
        borderColor: '#CFF3D2',
        borderWidth: 1,
        fill: false,
        pointBackgroundColor: '#CFF3D2',
        pointRadius: 0.5,
        lineTension: 0,
      },
    ];
    const chartData = {
      datasets,
      labels: cost,
    };
    return (
      <div style={{ marginTop: 30 }}>
        <H3 style={{ marginTop: 10 }}>Recall Curve</H3>
        <Switch
          checked={distance}
          label="Distance Type"
          innerLabelChecked="euc"
          innerLabel="cos"
          large
          onChange={this.handleDistanceSwitch}
        />
        <div style={{ height: 400, paddingTop: 10 }}>
          <ROCChart
            yFmtr={fmtr}
            data={() => chartData}
            legend
            position="bottom"
            stepSize={reidAuditSummary.data.length % 5}
            yLabel="Recall"
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      reidAuditTask, reidAuditSummary, dispatch,
    } = this.props;
    if (
      (reidAuditTask.pending && !reidAuditTask.data)
      || (reidAuditSummary.pending && !reidAuditSummary.data)
    ) {
      return <Spinner size={100} />;
    }
    const {
      s3_pbuf_path, vision_rx_version, model_id, config, task_ids, title, task_status,
      task_id, EndTime, extracted,
    } = reidAuditTask.data || {};
    const {
      thresh, tableLoading, extractLoading, clearLoading,
    } = this.state;

    const taskStatus = task_status === 0 && EndTime !== null ? 'Failed' : STATUS_MAP[task_status];
    const refireValues = reidAuditTask.data
      ? ([reidAuditTask.data] || []).map(({ task_ids: ti, ...rest }) => ({
        ...rest,
        task_ids: ti ? ti.split(',').map(x => ({ id: x })) : [],
      })) : {};

    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="audit-header-container">
            <H1>
              <Icon
                className="audit-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                onClick={() => dispatch(push('/reid/audits'))}
              />
              {title}
            </H1>
            <div>
              {taskStatus !== 'Failed' && task_status !== 1 && (
                <Tooltip content={task_status === 4 ? 'Resume Audit' : 'Pause Audit'}>
                  <div className="pause-audit-container">
                    <Icon
                      iconSize={30}
                      className="pause-audit"
                      icon={task_status === 4 ? 'play' : 'pause'}
                      onClick={() => (task_status === 4
                        ? this.resumeAudit()
                        : this.pauseAudit())}
                    />
                  </div>
                </Tooltip>
              )}
              <Link
                to={{
                  pathname: '/reid/audit/create',
                  state: { initialValues: refireValues[0] },
                }}
              >
                <Button style={{ margin: '0px 0px 10px 10px' }} icon="repeat" intent="primary">Re-Fire</Button>
              </Link>
            </div>
          </div>
          <div className="bp3-text-large bp3-text-muted audit-header">
            {`ID: ${task_id}`}
            <Dot />
            {`Model: ${model_id}`}
            <Dot />
            {`Version: ${vision_rx_version}`}
            <Dot />
            {`Status: ${taskStatus}`}
            <Dot />
            {`Merge Task Ids: ${task_ids}`}
          </div>
          <H1>
            <div className="bp3-text-small">
              <div className="audit-summary">
                <div className="audit-summary-row">
                  <div className="audit-summary-row-item">
                    <div>S3 Output Path:</div>
                    <Tag multiline>{s3_pbuf_path}</Tag>
                  </div>
                  <div className="audit-summary-row">
                    <div className="audit-summary-row-item">
                      <div>Vision Params:</div>
                      <Tag multiline>{config || 'N/A'}</Tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button loading={extractLoading} disabled={extracted === true} onClick={this.extractProtobuf} intent="primary" type="submit" icon="play">
              Extract
            </Button>
            <Button style={{ margin: '0px 0px 0px 10px' }} disabled={(extracted === false)} onClick={this.clearOutput} loading={(clearLoading)} intent="primary" icon="trash">Clear Output</Button>
          </H1>
        </div>
        <div>
          {reidAuditSummary.data && this.renderChart()}
        </div>
        { reidAuditSummary.data && (
          <div>
            <FormGroup label="Distance Threshold" style={{ width: '25%', display: 'inline-block' }}>
              <InputGroup value={thresh} onChange={this.handleThreshold} type="number" />
            </FormGroup>
            <Button style={{ display: 'inline-block', marginLeft: 10 }} loading={tableLoading} onClick={this.handleThresholdSave} intent="primary" type="submit" icon="tick">
              Refresh Table
            </Button>
            { tableLoading && <Spinner size={30} /> }
            { !tableLoading && this.renderTracksTable()}
          </div>
        )}
      </div>
    );
  }
}

ReidAuditSummary.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  reidAuditSummary: PropTypes.object,
  reidAuditTask: PropTypes.object,
  reidAuditTasks: PropTypes.object,
};

export default connect(state => ({
  reidAuditTask: state.reidAuditTask,
  reidAuditSummary: state.reidAuditSummary,
  token: state.currentUser.token.access_token,
  audits: state.audits,
  users: state.users,
}))(ReidAuditSummary);
