/* eslint-disable jsx-a11y/media-has-caption */

import { getClipsByCamera } from 'actions/device';
import React, { Component } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Spinner, H2, H5, Card, Button, Icon,
} from '@blueprintjs/core';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import SiteSelect from './site_select';
import ClipSelect from './clip_select';


const baseUrl = (u) => {
  const url = new URL(u);
  url.search = '';
  return url.toString();
};

const zpad = (stra, num = 2, pad = '0') => {
  let str = `${stra}`;
  while (str.length < num) {
    str = pad + str;
  }
  return str;
};


const tc = (seconds) => {
  const MINUTE = 60;
  const HOUR = 60 * 60;

  // eslint-disable-next-line no-bitwise
  const ss = ~~seconds % MINUTE;
  // eslint-disable-next-line no-bitwise
  const ms = ~~((seconds - ~~seconds) * 1000);
  // eslint-disable-next-line no-bitwise
  const mm = ~~((seconds % HOUR) / MINUTE);
  // eslint-disable-next-line no-bitwise
  const hh = ~~(seconds / HOUR);

  return `${zpad(hh)}:${zpad(mm)}:${zpad(ss)}:${zpad(ms, 3)}`;
};

const Desc = ({
  icon, title, children, intent, noPad,
}) => (
  <H5 className={classNames({ 'bp3-intent-danger': intent === 'danger' })}>
    <Icon icon={icon} intent={intent} />
    &nbsp;
    {title}
    &nbsp;&nbsp;
    <div className="bp3-ui-text bp3-text-muted">
      { !noPad && <Icon icon="blank" /> }
      { !noPad && <span>&nbsp;</span> }
      {children}
    </div>
  </H5>
);

Desc.propTypes = {
  intent: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  noPad: PropTypes.bool,
};

class Clip extends Component {
  constructor(props) {
    super(props);
    // const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    this.video = React.createRef();
    this.state = {
      fps: 0,
      ins: 0,
      outs: 0,
      speed: '1.0',
      vc: null,

      loadedUrl: null,
    };
  }

  componentDidMount() {
    const { u_id } = this.props;
    window.MediaInfo({ chunkSize: 256 * 1024, coverData: false, format: 'object' }, (mediainfo) => {
      window.mediainfo[u_id] = mediainfo;
    }, () => { });

    this.setData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { speed, clip_id } = this.state;
    const { id } = this.props;
    if (this.video.current && prevState.speed !== speed) {
      this.video.current.playbackRate = parseFloat(speed);
    }
    if (id !== prevProps.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ins: 0, outs: 0, speed: '1.0' }, () => this.reloadClip(clip_id));
    }
    this.ismounted = false;
  }

  componentWillUnmount() {
    const { vc } = this.state;
    if (this.video.current) {
      this.video.current.cancelVideoFrameCallback(vc);
    }
    document.addEventListener('keydown', this.handleKeyPress);
  }


  @autobind
  async setData() {
    const {
      id, u_id, cam_id, clip_id, site_cameras, cam_clips,
    } = this.props;
    this.setState({
      site_id: id,
      u_id,
      cam_id,
      clip_id,
      site_cameras,
      cam_clips,
    },
    function () {
      this.reloadClip(clip_id);
      if (this.video.current) {
        this.setState({
          vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback),
        });
      }
      document.addEventListener('keydown', this.handleKeyPress);
      this.ismounted = true;
    });
  }

  @autobind
  get_clip_id() {
    const { clip_id } = this.state;
    return clip_id;
  }

  @autobind
  handleScreenshot() {
    const video = this.video.current;
    const canvas = document.createElement('canvas');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const link = document.createElement('a');
    link.download = 'ss.png';
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  @autobind
  handlePlayback(e) {
    const speed = _.isString(e) ? e : e.target.value;
    this.setState({ speed });
  }

  @autobind
  handleVideoCallback() {
    this.setState({
      vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback),
    });
  }


  reloadClip(clipID) {
    const { p_funcs } = this.props;
    const { cam_clips } = this.state;
    const curr_clip_index = cam_clips.findIndex(x => x.id === clipID);
    this.setState({ curr_clip_index, clip_id: clipID });
    p_funcs.dispatchGetRawAuditClip(clipID).then((action) => {
      const { loadedUrl } = this.state;
      const clip = action.payload.data.content;
      this.setState({ clip });
      const videoPath = clip.s3_path;
      if (loadedUrl !== videoPath) {
        return fetch(videoPath);
      }
      return false;
    })
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ fps: 25, loadedUrl: baseUrl(response.url) });
        }
      });
  }

  @autobind
  handleKeyPress(e) {
    const { p_funcs } = this.props;
    const { u_id } = this.state;
    if (p_funcs.get_active_id() === u_id) {
      if (e.target.tagName === 'INPUT' || document.activeElement.tagName === 'SELECT') {
        return;
      }

      const video = this.video.current;
      const {
        ins, outs, speed, site_id, clip_id, cam_clips,
      } = this.state;
      const mod = e.ctrlKey ? -1 : 1;
      if (e.key === '.') {
        this.handleForward();
      } else if (e.key === ',') {
        this.handleBackward();
      } else if (e.keyCode === 8) {
        this.handlePlayback('1.0');
      } else if (e.key === '[') {
        let s = parseFloat(speed);
        if (s > 1) {
          s = parseFloat(speed) - 1;
          this.setState({ speed: s.toFixed(1) });
        } else if (s === 1) {
          this.setState({ speed: '0.5' });
        } else if (s === 0.5) {
          this.setState({ speed: '0.25' });
        }
      } else if (e.key === ']') {
        let s = parseFloat(speed);
        if (s === 0.25) {
          this.setState({ speed: '0.5' });
        } else if (s === 0.5) {
          this.setState({ speed: '1.0' });
        } else if (s < 6) {
          s += 1;
          this.setState({ speed: s.toFixed(1) });
        }
      } else if (e.key === 'o') {
        this.setState({ ins: ins + mod });
      } else if (e.key === 'p') {
        this.setState({ outs: outs + mod });
      } else if (e.key === 'r') {
        this.setState({ ins: 0, outs: 0 });
      } else if (e.key === 's') {
        this.handleScreenshot();
      } else if (e.key === 'n') {
        this.handleNextClip();
      } else if (e.key === 'b') {
        this.handlePrevClip();
      } else if (e.key === 'm' && video) {
      // get clip with clip_id f
        const clip = cam_clips.find(x => x.id === clip_id);


        const newEntry = {
          track_id: null,
          clip_start_time: clip.start_time,
          time: video.currentTime,
          clip: clip_id,
          site: site_id,
          id: '',
          start: !e.ctrlKey,
          order: null,
          u_id,
        };
        p_funcs.add_tag(newEntry);
      }
    }
  }

  @autobind
  handleForward() {
    let { fps } = this.state;
    const video = this.video.current;
    if (this.isPlaying()) {
      video.pause();
    }
    if (fps === 0) fps = 25;
    video.currentTime += 1 / fps;
  }

  @autobind
  handleBackward() {
    let { fps } = this.state;
    const video = this.video.current;
    if (this.isPlaying()) {
      video.pause();
    }
    if (fps === 0) fps = 25;
    video.currentTime -= 1 / fps;
  }


  @autobind
  handlePlay() {
    const video = this.video.current;
    const playing = this.isPlaying();
    if (playing) {
      video.pause();
    } else {
      video.play();
    }
    this.forceUpdate();
  }

  @autobind
  handleNextClip() {
    const {
      cam_clips, curr_clip_index,
    } = this.state;

    // check if not last clip
    if (curr_clip_index < cam_clips.length - 1) {
      // get next clip
      const next_clip_id = cam_clips[curr_clip_index + 1].id;

      this.setState({ clip_id: next_clip_id });


      this.reloadClip(next_clip_id);
      if (this.video.current) {
        this.setState({
          vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback),
          speed: '1.0',
        });
      }
    }
  }

  @autobind
  handlePrevClip() {
    const {
      clip_id, cam_clips,
    } = this.state;
    const curr_clip_index = cam_clips.findIndex(x => x.id === clip_id);
    // check if not last clip
    if (curr_clip_index > 0) {
      // get next clip
      const next_clip_id = cam_clips[curr_clip_index - 1].id;


      this.setState({ clip_id: next_clip_id });
      this.reloadClip(next_clip_id);
      if (this.video.current) {
        this.setState({
          vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback),
          speed: '1.0',
        });
      }
    }
  }


  @autobind
  handleClip(e) {
    this.reloadClip(e);
  }

  @autobind
  jumpToTime(t) {
    const video = this.video.current;
    if (video) {
      video.currentTime = t;
    }
  }

  @autobind
  async handleSiteChange(cam_id) {
    const { dispatch } = this.props;
    const cam_clips_payload = await dispatch(getClipsByCamera(cam_id));
    const cam_clips_unsorted = cam_clips_payload.payload.data.content;
    const cam_clips = [...cam_clips_unsorted].sort(
      (a, b) => DateTime.fromISO(a.start_time) - DateTime.fromISO(b.start_time),
    );

    const clip_id = cam_clips[0].id;


    this.setState({
      cam_id,
      clip_id,
      cam_clips,
      speed: '1.0',
    });
    this.reloadClip(clip_id);
    if (this.video.current) {
      this.setState({ vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback) });
    }
  }

  isPlaying() {
    const video = this.video.current;
    const playing = video && !!(
      video.currentTime > 0
      && !video.paused
      && !video.ended
      && video.readyState > 2);
    return playing;
  }

  @autobind
  renderNoClip() {
    return (
      <div style={{ textAlign: 'center', paddingTop: 40 }}>
        <Icon iconSize={40} icon="error" style={{ marginBottom: 10 }} />
        <H2>Error Fetching Clip</H2>
        <Button
          style={{ marginTop: 10 }}
          large
          intent="primary"
          icon="properties"
        >
          Groundtruth Clips
        </Button>
      </div>
    );
  }

  renderVideoPlayer(player, videoPath, timecode) {
    const {
      speed, clip, curr_clip_index, cam_clips, cam_id, site_cameras, clip_id,
    } = this.state;
    const { p_funcs } = this.props;
    const playing = this.isPlaying();
    return (

      <div className="columns">
        <div className="column">
          <Card style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left',

          }}
          >
            <table className="bp3-html-table bp3-interactive bp3-html-table-condensed">
              <thead>
                <tr>
                  <th>Speed </th>
                  <th>Camera Selecter</th>
                  <th>Clip Selecter</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div className="bp3-select">
                      <select name="filter" value={speed} onChange={this.handlePlayback}>
                        <option value="0.25">0.25x</option>
                        <option value="0.5">0.5x</option>
                        <option value="1.0">1x</option>
                        <option value="1.5">1.5x</option>
                        <option value="2.0">2x</option>
                        <option value="3.0">3x</option>
                        <option value="4.0">4x</option>
                        <option value="5.0">5x</option>
                        <option value="6.0">6x</option>
                      </select>
                    </div>
                  </th>
                  <th>
                    <SiteSelect
                      value={cam_id}
                      site_cameras={site_cameras}
                      onChange={this.handleSiteChange}
                      className="m-b-sm"
                      clip={clip.file_name}
                      includeReset
                    />
                  </th>
                  <th>
                    <ClipSelect
                      value={clip_id}
                      get_clip_id={this.get_clip_id}
                      get_filter_dates={p_funcs.get_filter_dates}
                      cam_clips={cam_clips}
                      onChange={this.handleClip}
                      className="m-b-sm"
                      clip={clip.file_name}
                      includeReset
                    />
                  </th>
                </tr>

              </tbody>
            </table>

            <div />

            <video width="100%" controls src={videoPath} ref={this.video} crossOrigin="anonymous" />
            <div className="has-text-centered p-t-md">
              {tc(timecode)}
            </div>
            <div className="has-text-centered p-t-md">
              <Button disabled={!(curr_clip_index > 0)} icon="step-backward" onClick={this.handlePrevClip} />
              <Button icon="fast-backward" onClick={this.handleBackward} />
              <Button icon={playing ? 'pause' : 'play'} onClick={this.handlePlay} />
              <Button icon="fast-forward" onClick={this.handleForward} />
              <Button disabled={!(curr_clip_index < cam_clips.length - 1)} icon="step-forward" onClick={this.handleNextClip} />
            </div>

          </Card>
        </div>
      </div>

    );
  }

  render() {
    const {
      vc, clip,
    } = this.state;
    const video = this.video.current;
    if (!video) {
      setTimeout(() => this.forceUpdate(), 0);
    }
    if (!(clip)) {
      return <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>;
    }
    if (!vc && video) {
      this.setState({ vc: video.requestVideoFrameCallback(this.handleVideoCallback) });
    }
    const videoPath = clip.s3_path;
    const timecode = video && video.currentTime;

    return (
      <div>
        {this.renderVideoPlayer(1, videoPath, timecode)}
      </div>


    );
  }
}

Clip.propTypes = {
  dispatch: PropTypes.func,
  site_cameras: PropTypes.array,
  p_funcs: PropTypes.object,
  id: PropTypes.string,
  u_id: PropTypes.number,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
  clipTags: state.clipTags,
  modelAudit: state.audit,
  clips: state.cam_clips,
  siteDevices: state.siteDevices,
  cameraClips: state.cameraClips,
}))(Clip);
