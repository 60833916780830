import rootSaga from './root';

import { reducer as appReducer } from './app';
import { reducer as adminReducer } from './admin';
import {
  organizationReducer,
  organizationsReducer,
  organizationDevicesReducer,
  auditOrganizationsReducer,
} from './organization';
import { roleReducer, rolesReducer, permissionsReducer } from './role';
import { userReducer, usersReducer } from './user';
import {
  auditsReducer, auditReducer, auditSummaryReducer, auditOverviewReducer, auditSignedUrlReducer,
  getModelsReducer, auditClipsReducer, auditClipReducer, auditClipTagReducer, getModelReducer,
  getAssignedClipsReducer, getAssignedClipsByOrgReducer, getClipsSummaryReducer,
  getRawAuditClipReducer, addWaitTimeReducer, getWaitTimesReducer,
} from './audit';
import {
  reidTasksReducer, reidTaskReducer,
  reidOutputReducer, reidTrackReducer,
  reidExtrackReducer, reidTrackGroundTruthReducer, reidMergeOutputReducer,
  reidClipPatchesReducer, reidTaskClipsReducer, reidTaskGroupsReducer,
  reidAuditTaskReducer, reidAuditTasksReducer, reidAuditSummaryReducer,
  reidAuditMatrixReducer, reidAuditTracksReducer, reidAuditCompareReducer,
} from './reid';
import {
  locationReducer,
  locationsReducer,
  locationWhitelistReducer,
  auditlocationsReducer,
} from './location';
import {
  deviceReducer, devicesReducer, metricsReducer, beaconsReducer,
  deviceInterfaceReducer, versionReducer, devicesAllReducer, iapMetricsReducer,
  getVisionParametersReducer, getFetchedImage, getInsAndOutReducer,
  getFpsReducer, getCameraResolutionReducer, getHomographyReducer,
  getCameraRecordingsReducer, viewExistingCameraUploadsReducer, getAllPeplinkDevicesReducer,
  getPeplinkSimPoolsReducer, getPeplinkReducer, getPeplinkBandwidthUsageReducer,
  getPeplinkClientsReducer, getPeplinkLogsReducer, getClipsByTimeReducer,
  getCountingLinesByCameraReducer,
  getInsAndOutByLineReducer, getClipsByCameraReducer,
} from './device';
import {
  nvrReducer, nvrsReducer, nvrMetricsReducer, nvrReleaseReducer,
} from './nvr';
import { getDashesReducer, getDashReducer } from './dash';
import {
  getSiteReducer,
  getSiteDevicesReducer,
  getSitesReducer,
  getZonesReducer,
  getZoneReducer,
  getSiteOpReducer,
  getGroundTruthReducer,
  getZoneDevicesReducer,
  getAuditSitesReducer,
  getAuditSiteDevicesReducer,
} from './site';
import { pipelinesReducer } from './pipelines';
import iapReducer from './iap';
import dateRangeReducer from './daterange';
import {
  getTrainingDatasetsReducer, getAllTrainingTasksReducer, getTrainingTaskStatusReducer,
  terminateTrainingReducer,
} from './training';
import { getDisplaysReducer, getDisplayHoursReducer } from './cms';
import { getAlertsReducer, getAlertReducer, getActiveAlertsReducer } from './alerts';

const reducers = {
  app: appReducer,
  admin: adminReducer,
  audits: auditsReducer,
  tasks: reidTasksReducer,
  task: reidTaskReducer,
  reidTasks: reidTasksReducer,
  reidTask: reidTaskReducer,
  reidAuditTask: reidAuditTaskReducer,
  reidAuditTasks: reidAuditTasksReducer,
  reidAuditSummary: reidAuditSummaryReducer,
  reidAuditMatrix: reidAuditMatrixReducer,
  reidAuditTracks: reidAuditTracksReducer,
  reidAuditCompareSummary: reidAuditCompareReducer,
  reidOutput: reidOutputReducer,
  reidMergeOutput: reidMergeOutputReducer,
  reidTrackGroundTruth: reidTrackGroundTruthReducer,
  reidClipsPatches: reidClipPatchesReducer,
  reidTaskClips: reidTaskClipsReducer,
  reidExtract: reidExtrackReducer,
  reidTrack: reidTrackReducer,
  reidTaskGroups: reidTaskGroupsReducer,
  audit: auditReducer,
  auditClips: auditClipsReducer,
  auditClip: auditClipReducer,
  auditS3Url: auditSignedUrlReducer,
  auditSummary: auditSummaryReducer,
  auditOverview: auditOverviewReducer,
  organizations: organizationsReducer,
  organization: organizationReducer,
  auditOrganizations: auditOrganizationsReducer,
  organizationDevices: organizationDevicesReducer,
  roles: rolesReducer,
  role: roleReducer,
  permissions: permissionsReducer,
  locations: locationsReducer,
  auditLocations: auditlocationsReducer,
  location: locationReducer,
  users: usersReducer,
  user: userReducer,
  devices: devicesReducer,
  device: deviceReducer,
  nvr: nvrReducer,
  nvrs: nvrsReducer,
  nvrMetrics: nvrMetricsReducer,
  nvrRelease: nvrReleaseReducer,
  interfaces: deviceInterfaceReducer,
  bosun: metricsReducer,
  iapmetrics: iapMetricsReducer,
  beacon: beaconsReducer,
  site: getSiteReducer,
  siteDevices: getSiteDevicesReducer,
  auditSiteDevices: getAuditSiteDevicesReducer,
  versions: versionReducer,
  sites: getSitesReducer,
  auditSites: getAuditSitesReducer,
  zones: getZonesReducer,
  zone: getZoneReducer,
  siteOp: getSiteOpReducer,
  groundtruth: getGroundTruthReducer,
  pipelines: pipelinesReducer,
  zoneDevices: getZoneDevicesReducer,
  allDevices: devicesAllReducer,
  iap: iapReducer,
  vision: getVisionParametersReducer,
  fetchImage: getFetchedImage,
  cameraStats: getInsAndOutReducer,
  cameraLineStats: getInsAndOutByLineReducer,
  fps: getFpsReducer,
  resolution: getCameraResolutionReducer,
  whitelist: locationWhitelistReducer,
  homography: getHomographyReducer,
  models: getModelsReducer,
  cameraRecordings: getCameraRecordingsReducer,
  cameraUploads: viewExistingCameraUploadsReducer,
  dateRange: dateRangeReducer,
  clipTags: auditClipTagReducer,
  model: getModelReducer,
  dashes: getDashesReducer,
  dash: getDashReducer,
  peplinks: getAllPeplinkDevicesReducer,
  simpools: getPeplinkSimPoolsReducer,
  peplink: getPeplinkReducer,
  peplinkBandwidth: getPeplinkBandwidthUsageReducer,
  peplinkClients: getPeplinkClientsReducer,
  peplinkLogs: getPeplinkLogsReducer,
  clipsByTime: getClipsByTimeReducer,
  trainingDatasets: getTrainingDatasetsReducer,
  trainingTasks: getAllTrainingTasksReducer,
  trainingTaskStatus: getTrainingTaskStatusReducer,
  assignedClips: getAssignedClipsReducer,
  orgAssignedClips: getAssignedClipsByOrgReducer,
  clipsSummary: getClipsSummaryReducer,
  terminateTraining: terminateTrainingReducer,
  displays: getDisplaysReducer,
  displayHours: getDisplayHoursReducer,
  alertsList: getAlertsReducer,
  alert: getAlertReducer,
  activeAlerts: getActiveAlertsReducer,
  cameraCountingLines: getCountingLinesByCameraReducer,
  rawClip: getRawAuditClipReducer,
  cameraClips: getClipsByCameraReducer,
  addWaitTime: addWaitTimeReducer,
  getWaitTimes: getWaitTimesReducer,
};

export { rootSaga as default, reducers };
