import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Spinner, H5, Card,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { getReidAuditTaskMatrix } from 'actions/reid';

const PatchItem = (props2) => {
  const {
    url, distance, gtid,
  } = props2;

  return (
    <div>
      <Card elevation="1" style={{ marginBottom: '1em' }}>
        <img
          src={url}
          alt=""
          className="camera-vision-image"
          style={{ width: 'auto', height: '15em' }}
          draggable={false}
          id="img-feed"
          title={`Ground Truth: ${gtid}`}
        />
        <div>
          Distance:
          {' '}
          {distance}
        </div>
      </Card>
    </div>
  );
};

class ReidMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      fpList: [],
      tpList: [],
      fnList: [],
      queryImg: {},
    };
    this.getData();
  }

  @autobind
  getData() {
    const {
      selectedRow, dispatch, thresh, distance, task_id,
      region,
    } = this.props;
    if (selectedRow) {
      const trackParam = selectedRow.key === 'track' ? `taskTrack=${selectedRow.value}` : `groupID=${selectedRow.value}`;
      dispatch(getReidAuditTaskMatrix(task_id, trackParam, selectedRow.row.gt_id, thresh, distance === true ? 'eucledian' : 'cosine', region)).then(({ payload }) => {
        if (payload && payload.data.content) {
          const { content } = payload.data;
          this.setState({
            tpList: content.filter(x => x.tp === 1 && x.gtid !== selectedRow.row.gt_id),
            fpList: content.filter(x => x.fp === 1),
            fnList: content.filter(x => x.fn === 1),
            queryImg: content.filter(x => x.gtid === selectedRow.row.gt_id)[0],
            loading: false,
          });
        }
      });
    }
  }

  render() {
    // const {  } = this.props;
    const {
      tpList, fpList, fnList, loading,
      queryImg,
    } = this.state;

    if (loading) {
      return <Spinner size={100} />;
    }

    return (
      <div className="reid-audit-context-menu">
        <div>
          <Card elevation="3" style={{ marginBottom: '1em', width: '100%' }}>
            <img
              src={queryImg.url ? queryImg.url : ''}
              alt=""
              className="camera-vision-image"
              style={{ width: 'auto', height: '15em' }}
              draggable={false}
              id="img-feed"
              title={`Ground Truth: ${queryImg.gtid}`}
            />
            <div>
              Query Image
            </div>
          </Card>
        </div>
        <div className="columns">
          <div className="column">
            <div className="has-text-centered">
              <H5>
                True Positives
                {' '}
                (
                {tpList.length}
                )
              </H5>
              <ul style={{ overflow: 'auto', maxHeight: '400px', margin: 5 }}>
                {tpList.map(patch => (
                  <li key={patch.gtid} style={{ display: 'inline-block', width: '33%' }}>
                    <PatchItem url={patch.url} distance={patch.distance} gtid={patch.gtid} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="has-text-centered">
              <H5>
                False Positives
                {' '}
                (
                {fpList.length}
                )
              </H5>
              <ul style={{ overflow: 'auto', maxHeight: '400px', margin: 5 }}>
                {fpList.map(patch => (
                  <li key={patch.gtid} style={{ display: 'inline-block', width: '33%' }}>
                    <PatchItem url={patch.url} distance={patch.distance} gtid={patch.gtid} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="has-text-centered">
              <H5>
                False Negatives
                {' '}
                (
                {fnList.length}
                )
              </H5>
              <ul style={{ overflow: 'auto', maxHeight: '400px', margin: 5 }}>
                {fnList.map(patch => (
                  <li key={patch.gtid} style={{ display: 'inline-block', width: '33%' }}>
                    <PatchItem url={patch.url} distance={patch.distance} gtid={patch.gtid} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReidMatrix.propTypes = {
  dispatch: PropTypes.func,
  selectedRow: PropTypes.object,
  thresh: PropTypes.number,
  distance: PropTypes.bool,
  task_id: PropTypes.string,
  region: PropTypes.string,
};


export default ReidMatrix;
