import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './list';
import Nvr from './nvr';
import NvrReleases from './releases';

export default () => (
  <Switch>
    <Route path="/nvrs/releases" component={NvrReleases} />
    <Route path="/nvrs/:id" component={Nvr} />
    <Route component={List} />
  </Switch>
);
