import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H4, Icon, Intent, Spinner, Tag,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';

import { getPeplinkClients } from 'actions/device';

class PeplinkClients extends Component {
  componentDidMount() {
    const { peplinkId, dispatch } = this.props;
    dispatch(getPeplinkClients(peplinkId));
  }

  @autobind
  getClientList() {
    const { peplinkClients } = this.props;
    const clients = [];
    if (peplinkClients.data) {
      peplinkClients.data.forEach((client) => {
        clients.push({
          name: this.formatClientName(client.name),
          device_id: client.device_id,
          ip: client.ip,
          status: client.status,
        });
      });
    }
    return clients;
  }

  formatClientName(name) {
    if (name.slice(0, 3) === 'AMC') {
      const suffix = name.split('_')[1];
      return `AMCREST-${suffix}`;
    }
    return name;
  }

  render() {
    const { peplinkId } = this.props;
    if (!peplinkId) {
      return (<Spinner />);
    }
    const clients = this.getClientList();
    return (
      clients.map(x => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '10px',
          }}
        >
          <Icon
            icon="symbol-circle"
            intent={x.status === 'active' ? Intent.SUCCESS : Intent.DANGER}
            style={{ marginRight: '1rem' }}
          />
          {x.device_id && (
            <Fragment>
              <a href={`/devices/${x.device_id}`}>
                <H4 style={{ marginBottom: 0 }}>{x.name}</H4>
              </a>
              &nbsp;
              <Tag intent="none">{x.ip || ''}</Tag>
            </Fragment>
          )}
          {!x.device_id && <H4 style={{ marginBottom: 0 }}>{x.name}</H4>}
        </div>
      ))
    );
  }
}

PeplinkClients.propTypes = {
  dispatch: PropTypes.func,
  peplinkId: PropTypes.number,
  peplinkClients: PropTypes.object,
};

export default connect(state => ({
  peplinkClients: state.peplinkClients,
}))(PeplinkClients);
