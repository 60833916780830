import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  Card, Elevation, H3, H4, H6, Tag, Button, Icon,
} from '@blueprintjs/core';

import EditAlert from './edit';

const ICON_MAP = {
  eq: 'equals',
  ne: 'not-equal-to',
  gt: 'greater-than',
  lt: 'less-than',
};

const cardTitle = (title, onEdit) => (
  <div className="flex-space-between-container">
    <p className="card-header-title">
      <Tag minimal large intent="default">{title}</Tag>
    </p>
    <div style={{ marginTop: 5 }}>
      <Button onClick={onEdit} icon="edit" minimal intent="none" />
    </div>
  </div>
);

const SlackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" viewBox="0 0 60 60" width="25" height="25" aria-label="Slack">
    <path d="m21.902.148c-3.299 0-5.973 2.68-5.973 5.985a5.979 5.979 0 0 0 5.973 5.985h5.974v-5.985a5.98 5.98 0 0 0 -5.974-5.985m0 15.96h-15.929c-3.299 0-5.973 2.68-5.973 5.986 0 3.305 2.674 5.985 5.973 5.985h15.93c3.298 0 5.973-2.68 5.973-5.985 0-3.306-2.675-5.986-5.974-5.986" fill="#36c5f0" />
    <path d="m59.734 22.094c0-3.306-2.675-5.986-5.974-5.986s-5.973 2.68-5.973 5.986v5.985h5.973a5.98 5.98 0 0 0 5.974-5.985m-15.929 0v-15.961a5.98 5.98 0 0 0 -5.974-5.985c-3.299 0-5.973 2.68-5.973 5.985v15.96c0 3.307 2.674 5.987 5.973 5.987a5.98 5.98 0 0 0 5.974-5.985" fill="#2eb67d" />
    <path d="m37.831 60a5.98 5.98 0 0 0 5.974-5.985 5.98 5.98 0 0 0 -5.974-5.985h-5.973v5.985c0 3.305 2.674 5.985 5.973 5.985m0-15.96h15.93c3.298 0 5.973-2.68 5.973-5.986a5.98 5.98 0 0 0 -5.974-5.985h-15.929c-3.299 0-5.973 2.68-5.973 5.985a5.979 5.979 0 0 0 5.973 5.985" fill="#ecb22e" />
    <path d="m0 38.054a5.979 5.979 0 0 0 5.973 5.985 5.98 5.98 0 0 0 5.974-5.985v-5.985h-5.974c-3.299 0-5.973 2.68-5.973 5.985m15.929 0v15.96c0 3.306 2.674 5.986 5.973 5.986a5.98 5.98 0 0 0 5.974-5.985v-15.961a5.979 5.979 0 0 0 -5.974-5.985c-3.299 0-5.973 2.68-5.973 5.985" fill="#e01e5a" />
  </svg>
);

const SLACK_CHANNELS = {
  lidl_device_alerts: 'https://app.slack.com/client/T74RQUDNF/C017F4N0Z2B',
  lidl_occupancy_alerts: 'https://app.slack.com/client/T74RQUDNF/C01DHNDMCLD',
  'alerts-d': 'https://app.slack.com/client/T74RQUDNF/C01S9UFD7T2',
  salvation_army_alerts: 'https://app.slack.com/client/T74RQUDNF/C01TH9F2LUB',
  peplink_alerts: 'https://app.slack.com/client/T74RQUDNF/C01C30Z2NSK',
  houston_alerts: 'https://app.slack.com/client/T74RQUDNF/C01SC4S6N6N',
};

const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);

class AlertCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  @autobind
  handleEdit() {
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  }

  @autobind
  handleSlackRedirect(channel) {
    window.open(SLACK_CHANNELS[channel] || '');
  }

  @autobind
  handleCardClick() {
    const { dispatch, alert } = this.props;
    dispatch(push(`/alerts/${alert.alert_id}`));
  }

  render() {
    const { alert } = this.props;
    const { editMode } = this.state;
    if (editMode) {
      return <EditAlert alert={alert} editToggle={this.handleEdit} />;
    }
    const slackChannels = alert.notification_channels ? alert.notification_channels.split(',') : [];
    return (
      <Fragment>
        {cardTitle(alert.alert_name, this.handleEdit)}
        <Card elevation={Elevation.THREE} style={{ marginBottom: 20 }}>
          <div>
            <H3>{alert.alert_description}</H3>
          </div>
          <br />
          <div style={{ paddingBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
              <H4>
                {alert.metric}
                &nbsp;&nbsp;
              </H4>
              <Icon icon={ICON_MAP[alert.type]} style={{ paddingTop: '3px' }} />
              &nbsp;&nbsp;
              <H4>{alert.threshold}</H4>
            </div>
            <H6>{`Aggregation: ${capitalizeFirstLetter(alert.aggregation)}`}</H6>
            <H6>{`Reminder: ${alert.reminder}${alert.reminder ? ' seconds' : ''}`}</H6>
            <H6>{`Duration: ${alert.duration} seconds`}</H6>
            <H6>{`Send Notification on No Data: ${alert.no_data ? 'Yes' : 'No'}`}</H6>
          </div>
          Filter:
          <div className="bp3-code-block">{alert.filter}</div>
          <div className="flex-space-between-container" style={{ marginTop: '30px' }}>
            <div style={{ display: 'flex' }}>
              <Icon icon={<SlackIcon />} />
              &nbsp;&nbsp;
              {slackChannels.map(x => (
                <Tag
                  intent="success"
                  onClick={() => this.handleSlackRedirect(x)}
                  interactive
                  minimal
                  large
                  style={{ marginLeft: '10px' }}
                >
                  {`#${x}`}
                </Tag>
              ))}
              {!slackChannels.length && (
                <Tag
                  intent="warning"
                  minimal
                  large
                  style={{ marginLeft: '10px' }}
                >
                  No Notifications
                </Tag>
              )}
            </div>
            <div>
              <Button onClick={this.handleCardClick} minimal intent="primary" text="View Current States" />
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

AlertCard.propTypes = {
  alert: PropTypes.object,
  dispatch: PropTypes.func,
};

export default AlertCard;
