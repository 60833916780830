import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAuditSiteDevices } from 'actions/site';
import { push } from 'connected-react-router';
import {
  H1, Spinner, Icon, H3, H5, Menu, MenuItem,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  getAudits, getAudit, patchAudit, patchAuditTags, getWaitTimes,
} from 'actions/audit';
import { Link } from 'react-router-dom';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import numbro from 'numbro';
import { DateTime } from 'luxon';
import axios from 'axios';

import { AppToaster } from 'components/Toaster';
import Line from '../LineChart';
import EditTrack from './edit_track';

const fmtr = x => numbro(x).format('0,0');


const INDEX_MAP = {
  0: 'Track ID',
  1: 'Entry',
  2: 'Id Check',
  3: 'Redress',
  4: 'Exit',
  5: 'Total',
  6: 'user_id',
  7: 'created',
};

class AuditSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: '',
      operator: '',
      condition: '',
      groundtruth: '',
      assigned: '',
      assign: false,
      clips: [],
      assignedPerson: '',
      track_dict: null,
      tag_dict: null,
      track_keys: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
  }


  @autobind
  async getData() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const site_id = id;
    const site_devices_payload = await dispatch(getAuditSiteDevices(site_id));
    const site_devices = site_devices_payload.payload.data.content;
    const site_cameras = site_devices.filter(x => x.device.type.toLowerCase().includes('camera'));
    this.setState({ site_cameras, site_id });

    const response = await dispatch(getWaitTimes(site_id));
    const data = response.payload.data.results;


    const track_dict = {};
    data.forEach((element) => {
      if (track_dict[element.track_id] === undefined) {
        track_dict[element.track_id] = [];
      }
      track_dict[element.track_id].push(element);
    });
    const track_keys = Object.keys(track_dict);
    const track_info_dict = {};
    track_keys.forEach((key) => {
      track_info_dict[key] = {};
    });
    this.setState({
      track_dict, data, track_keys, track_info_dict,
    });
  }

  extract_time(clip_id) {
    const time_parts = clip_id.split('_');
    const parsed_time = DateTime.strptime(`${time_parts[0]}-${time_parts[1]}`, '%Y-%m-%d-%H-%M-%S');
    return parsed_time;
  }

  @autobind
  refreshTable() {
    const {
      groundtruth, assigned: stateAssigned,
      filter, operator, condition, data,
    } = this.state;
    const { audit } = this.props;
    let auditData = [...audit.data];
    const filteredData = (() => {
      if (groundtruth === '1') {
        auditData = auditData.filter(this.hasGroundTruth);
      }
      if (groundtruth === '-1') {
        auditData = auditData.filter(this.noGroundtruth);
      }
      if (stateAssigned === '1') {
        auditData = auditData.filter(this.isAssigned);
      }
      if (stateAssigned === '-1') {
        auditData = auditData.filter(this.isNotAssigned);
      }
      const intCondition = parseInt(condition, 10);
      if (intCondition != null && filter) {
        auditData = data.filter((x) => {
          const column = x[filter];
          switch (operator) {
            case '>':
              return column > intCondition;
            case '<':
              return column < intCondition;
            case '=':
              return column === intCondition;
            case '!=':
              return column !== intCondition;
            default:
              break;
          }
          return x;
        });
      }
      return auditData;
    })();
    return this.setState({ data: filteredData });
  }

  @autobind
  hasGroundTruth({ true_ins, true_outs }) {
    return Number.isInteger(true_ins) && Number.isInteger(true_outs);
  }

  @autobind
  noGroundtruth({ true_ins, true_outs }) {
    return !Number.isInteger(true_ins) && !Number.isInteger(true_outs);
  }

  @autobind
  isAssigned({ assigned }) {
    return !!assigned;
  }

  @autobind
  isNotAssigned({ assigned }) {
    return !assigned;
  }

  @autobind
  toggleAssign() {
    const { assign } = this.state;
    this.setState({ assign: !assign });
  }

  @autobind
  downloadSummary() {
    const { token, match } = this.props;
    window.open(`${axios.defaults.baseURL}/v1/audit/tasks/${match.params.id}/summary_report.csv?access_token=${token}`);
  }

  @autobind
  downloadReport() {
    const { token, match } = this.props;
    window.open(`${axios.defaults.baseURL}/v1/audit/tasks/${match.params.id}/report.csv?access_token=${token}`);
  }

  @autobind
  handleFilter(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({ filter: value, operator: '', condition: '' }, this.refreshTable);
    }
    this.setState({ filter: value });
  }

  @autobind
  handleGroundTruth(e) {
    this.setState({ groundtruth: e.target.value }, this.refreshTable);
  }

  @autobind
  handleAssigned(e) {
    this.setState({ assigned: e.target.value }, this.refreshTable);
  }

  @autobind
  handleOperator(e) {
    this.setState({ operator: e.target.value });
  }

  @autobind
  handleCondition(e) {
    this.setState({ condition: e.target.value });
  }

  @autobind
  handleAssign(e) {
    this.setState({ assignedPerson: e.target.value });
  }

  @autobind
  clearClips() {
    this.setState({ clips: [] });
  }

  @autobind
  async isValidAssignment() {
    const { clips } = this.state;
    const { dispatch, match } = this.props;
    let takenClips = false;
    const isValid = await dispatch(getAudit(match.params.id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const currentAuditClips = [...payload.data.content];
        clips.forEach((c) => {
          // eslint-disable-next-line max-len
          const current = currentAuditClips.find(x => x.clip_name === c.clip_name && x.line_id === c.line_id) || {};
          if (current.assigned) {
            takenClips = true;
          }
        });
      }
      if (takenClips) return false;
      return true;
    });
    return isValid;
  }

  @autobind
  async handleClipAssignment() {
    const { clips, assignedPerson } = this.state;
    const { dispatch, match } = this.props;
    if (!assignedPerson) {
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>Must select a person</span>,
      });
    }
    this.setState({ loading: true });
    const isValid = await this.isValidAssignment();
    if (!isValid) {
      this.setState({ loading: false });
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>One or more clips you have selected are taken</span>,
      });
    }
    try {
      await Promise.all(clips.map(x => dispatch(patchAuditTags(
        match.params.id,
        x.clip_name,
        { assigned: assignedPerson, line_id: x.line_id },
      ))));
      return this.getData();
    } catch (e) {
      //
    } finally {
      this.setState({ loading: false, assignedPerson: '', clips: [] });
    }
    return null;
  }

  @autobind
  selectClips(row) {
    if (row && row[0].cols) {
      if (row[0].cols[0] > 0 || row[0].cols[1] > 0) {
        return null;
      }
    }
    const { data } = this.state;
    if (row && row[0] && row[0].rows) {
      const start = row[0].rows[0];
      const end = row[0].rows[1];
      const selectedClips = [...data].slice(start, end + 1);
      return this.setState({ clips: selectedClips });
    }
    return null;
  }

  @autobind
  applyFilter() {
    const {
      data, filter, operator, condition,
    } = this.state;
    const intCondition = parseInt(condition, 10);
    if (intCondition != null) {
      const filteredData = data.filter((x) => {
        const column = x[filter];
        switch (operator) {
          case '>':
            return column > intCondition;
          case '<':
            return column < intCondition;
          case '=':
            return column === intCondition;
          case '!=':
            return column !== intCondition;
          default:
            break;
        }
        return x;
      });
      this.setState({ data: filteredData });
    }
  }

  @autobind
  sortAsc(column) {
    const { track_keys, track_info_dict } = this.state;
    if (column === 0) {
      // track keys list of strings
      // sort track keys
      const sorted_keys = track_keys.sort();
      this.setState({ track_keys: sorted_keys });
    } else {
      const sorted_keys = [...track_keys].sort((a, b) => {
        const a_val = track_info_dict[a][INDEX_MAP[column]];
        const b_val = track_info_dict[b][INDEX_MAP[column]];
        if (!a_val && !b_val) {
          return 0;
        }
        if (!a_val) {
          return -1;
        }
        if (!b_val) {
          return 1;
        }
        return a_val - b_val;
      });
      this.setState({ track_keys: sorted_keys });
    }
  }

  @autobind
  sortDesc(column) {
    const { track_keys, track_info_dict } = this.state;
    if (column === 0) {
      // track keys list of strings
      // sort track keys
      const sorted_keys = track_keys.sort().reverse();
      this.setState({ track_keys: sorted_keys });
    } else {
      const sorted_keys = [...track_keys].sort((a, b) => {
        const a_val = track_info_dict[a][INDEX_MAP[column]];
        const b_val = track_info_dict[b][INDEX_MAP[column]];
        if (!a_val && !b_val) {
          return 0;
        }
        if (!a_val) {
          return 1;
        }
        if (!b_val) {
          return -1;
        }
        return b_val - a_val;
      });
      this.setState({ track_keys: sorted_keys });
    }
  }

  @autobind
  pauseAudit(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchAudit(id, { status: 4 }))
      .then(() => dispatch(getAudits(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`${name} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeAudit(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchAudit(id, { status: 0 }))
      .then(() => dispatch(getAudits(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'play',
        message: <span>{`${name} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  cellRenderer(index) {
    const { site_cameras } = this.state;
    if (site_cameras) {
      const camera = site_cameras[index];
      if (camera) {
        return <Cell tooltip={camera.device.org_device_name}>{camera.device.org_device_name}</Cell>;
      }
    }
    return <Cell error />;
  }

  @autobind
  tagRenderer(index, tag) {
    const { site_cameras, data } = this.state;
    if (site_cameras) {
      const camera = site_cameras[index];
      // eslint-disable-next-line max-len
      const count = data.filter(x => x.camera_serial === camera.device.device_identifier && x.tag === tag).length;
      if (camera) {
        return <Cell>{count > 0 ? count : null}</Cell>;
      }
    }
    return <Cell no site cameras />;
  }

  @autobind
  trackRenderer(track_list, tag) {
    const { track_info_dict } = this.state;
    // return <Cell null />;
    // return track wheere track.id == id;
    const track = track_list.find(x => x.tag === tag);
    if (track) {
      let { start_time } = track;
      const { epoch_time } = track;
      // start_tine is of form YYYY-MM-DD HH-MM-SS
      // epoch_time is of form seconds
      // calculate start_time in epoch time
      // reaplce space in start_time with T
      start_time = start_time.replace(' ', 'T');
      start_time = DateTime.fromISO(start_time);
      start_time = start_time.plus({ seconds: epoch_time });
      track_info_dict[track.track_id][tag] = start_time;
      // format time with month and day and time
      const time_string = start_time.toFormat('MMM dd HH:mm:ss');


      return <Cell>{time_string}</Cell>;
    }
    return <Cell null />;
  }

  @autobind
  render_track_creator(track_list) {
    const { track_info_dict } = this.state;
    const tag = 'Entry';
    const track = track_list.find(x => x.tag === tag);
    if (track) {
      const { user_id } = track;
      track_info_dict[track.track_id].user_id = user_id;
      return <Cell>{user_id}</Cell>;
    }
    return <Cell null />;
  }

  @autobind
  render_track_create_time(track_list) {
    const { track_info_dict } = this.state;
    const tag = 'Entry';
    const track = track_list.find(x => x.tag === tag);
    if (track) {
      const { created } = track;
      const time = DateTime.fromISO(created.replace(' ', 'T'));
      track_info_dict[track.track_id].created = time;
      return <Cell>{time.toFormat('MMM dd HH:mm:ss')}</Cell>;
    }
    return <Cell null />;
  }

  @autobind
  render_track_time(track_list) {
    const { track_info_dict } = this.state;
    const entry_track = track_list.find(x => x.tag === 'Entry');
    const exit_track = track_list.find(x => x.tag === 'Exit');
    if (entry_track && exit_track) {
      const { epoch_time } = entry_track;
      let { start_time } = entry_track;
      start_time = start_time.replace(' ', 'T');
      start_time = DateTime.fromISO(start_time);
      start_time = start_time.plus({ seconds: epoch_time });

      let end_time = exit_track.start_time;
      const epoch_time_end = exit_track.epoch_time;
      end_time = end_time.replace(' ', 'T');
      end_time = DateTime.fromISO(end_time);
      end_time = end_time.plus({ seconds: epoch_time_end });
      const total_time = end_time.diff(start_time, 'seconds');
      track_info_dict[entry_track.track_id].Total = total_time.seconds;
      const time_string = total_time.toFormat('hh:mm:ss');
      return <Cell>{time_string}</Cell>;
      // format time with month and day and time
    }
    return <Cell null />;
  }


  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderEditTrack(a) {
    if (a.target && a.target.rows && a.target.cols) {
      const { track_dict, track_keys } = this.state;
      const { rows, cols } = a.target;
      const row = rows[0];
      const col = cols[0];
      if (track_keys) {
        const track_list = track_dict[track_keys[row]];
        if (track_list) {
          const track = track_list[INDEX_MAP[col]];

          return (
            <EditTrack
              track={track}
              handleUpdateTag={this.handleUpdateTag}
              handleDeleteTag={this.handleDeleteTag}
              handleDeleteTrack={this.handleDeleteTrack}
            />
          );
        }
        return <h1>error no track</h1>;
      }
      return <h1>error no track keys</h1>;
    }
    return <h1>error no target</h1>;
  }

  @autobind
  renderSummaryReport() {
    const { track_dict, data, track_keys } = this.state;
    if (track_dict && data) {
      // get number of keys in track_dict
      // calc number of keys in track_dict

      return (
        <div className="tableContainer" style={{ borderTop: '1px solid #f5f8fa', height: '800px' }}>
          <div className="flex-space-between-container">
            <H3 style={{ marginTop: 10 }}>Wait Time Summary</H3>

          </div>

          <Table
            columnWidths={[200, 200, 200, 200, 200, 200, 200, 200]}
            // numFrozenColumns={2}
            numRows={Object.keys(track_dict).length}
            bodyContextMenuRenderer={a => this.renderEditTrack(a)}
            class="bp3-html-table bp3-table-resize-horizontal"
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Track ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => <Cell>{track_keys[i]}</Cell>}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Entry Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.trackRenderer(track_dict[track_keys[i]], 'Entry')}
            />

            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="ID Check Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.trackRenderer(track_dict[track_keys[i]], 'Id Check')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Redress Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.trackRenderer(track_dict[track_keys[i]], 'Redress')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Exit Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.trackRenderer(track_dict[track_keys[i]], 'Exit')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Total Wait Time" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.render_track_time(track_dict[track_keys[i]])}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Creator ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.render_track_creator(track_dict[track_keys[i]])}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Create time" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.render_track_create_time(track_dict[track_keys[i]])}
            />
          </Table>
        </div>
      );
    }
    return <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>;
  }

  @autobind
  renderNoSummaryReport() {
    return (
      <div style={{ borderTop: '1px solid #f5f8fa' }}>
        <H3 style={{ marginTop: 10 }}>Audit Summary</H3>
        <H5>Unavailable -- True Ins/Out Not Reported</H5>
      </div>
    );
  }


  @autobind
  renderAuditTable() {
    const { site_cameras } = this.state;
    if (!site_cameras) {
      return (
        <div className="flex-center-container">
          <H5>No Cameras Found</H5>
        </div>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <div className="flex-space-between-container">
          <H3>Audit Report</H3>
        </div>
        <div className="columns" style={{ marginTop: 20, height: 400 }}>
          <Table
            columnWidths={[200, 200, 200, 200, 200]}
            // numFrozenColumns={2}
            numRows={site_cameras.length}
            class="bp3-html-table bp3-table-resize-horizontal"
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Camera Name" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'clip_name')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Entry Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.tagRenderer(i, 'Entry')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="ID Check Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.tagRenderer(i, 'Id Check')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Redress Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.tagRenderer(i, 'Redress')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Exit Tags" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.tagRenderer(i, 'Exit')}
            />
          </Table>
        </div>
      </div>
    );
  }


  @autobind
  renderChart() {
    const { track_dict, tag_dict, loading } = this.state;
    const color_map = {
      Entry: 'green',
      Exit: 'red',
      'Id Check': 'silver',
      Redress: 'blue',
    };
    if (!track_dict || loading) {
      return (
        <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>
      );
    }

    // find min start time in tag_dict
    let min_start_time = Infinity;
    let max_start_time = -Infinity;

    Object.entries(tag_dict).forEach(([, v]) => {
      v.forEach((d) => {
        if (d.start_time < min_start_time) {
          min_start_time = d.start_time;
        }
        if (d.start_time > max_start_time) {
          max_start_time = d.start_time;
        }
      });
    });

    const time_length = Math.floor((max_start_time - min_start_time) / 1000) + 1;

    const datasets = Object.entries(tag_dict).map(([k, v]) => {
      const tracks = v;


      const time_list = [];
      for (let i = min_start_time; i <= max_start_time; i += 1000) {
        time_list.push(null);
      }

      // for each exit,
      // find the index of the time_list at the start_time
      // set the value to  end_time - start_time
      for (let i = 0; i < tracks.length; i += 1) {
        const exit = tracks[i];
        const { start_time, end_time } = exit;
        const index = Math.floor((start_time - min_start_time) / 1000);
        const waittime = (end_time - start_time) / 1000000;

        if (time_list[index] != null) {
          time_list[index] = (time_list[index] + waittime) / 2;
        } else {
          time_list[index] = waittime;
        }
      }


      return {
        label: k,
        data: time_list,
        borderColor: color_map[k],
        borderWidth: 2,
        fill: false,
        pointBackgroundColor: color_map[k],
        pointRadius: 5,
        lineTension: 0,
      };
    });
    const chartData = {
      datasets,
      labels: Array(time_length).fill().map((_x, i) => i + 1),
    };
    return (
      <div style={{ marginTop: 30 }}>
        <H3 style={{ marginTop: 10 }}>Tag Time After Entry</H3>
        <div style={{ height: 400, paddingTop: 10 }}>
          <Line
            yFmtr={fmtr}
            data={() => chartData}
            legend
            position="bottom"
            stepSize={time_length % 5}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderClipInput(clip, i) {
    return (
      <tr key={i}>
        <td>
          <Link to={`/devices/${clip.camera_serial}`}>
            {clip.camera_serial}
          </Link>
        </td>
        <td>{clip.line_id}</td>
        <td>{DateTime.fromISO(clip.start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td>{DateTime.fromISO(clip.end_time).toLocaleString(DateTime.DATETIME_MED)}</td>
      </tr>
    );
  }

  render() {
    const {
      audit, auditSummary, auditOverview, dispatch,
    } = this.props;
    const { site_id } = this.state;
    if (
      (audit.pending && !audit.data)
      || (auditSummary.pending && !auditSummary.data)
      || (auditOverview.pending && !auditOverview.data)
    ) {
      return <Spinner size={100} />;
    }
    const { true_ins_total, true_outs_total } = auditSummary.data || {};
    const showSummary = true_ins_total !== null && true_outs_total !== null;
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="audit-header-container">
            <H1>
              <Icon
                className="audit-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                onClick={() => dispatch(push('/audits'))}
              />
              Site:&nbsp;
              <Link to={`/audits/waittimes/site_audit/${site_id}`}>
                {site_id}
              </Link>
            </H1>
          </div>
        </div>
        <div>
          {showSummary && this.renderSummaryReport()}
          {!showSummary && this.renderNoSummaryReport()}
        </div>
        <div>
          {/* this.renderChart() */}
        </div>
        <div>
          {this.renderAuditTable()}
        </div>
      </div>
    );
  }
}

AuditSummary.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  auditOverview: PropTypes.object,
  auditSummary: PropTypes.object,
  audit: PropTypes.object,
  token: PropTypes.string,
};

export default connect(state => ({
  auditOverview: state.auditOverview,
  audit: state.audit,
  auditSummary: state.auditSummary,
  token: state.currentUser.token.access_token,
  audits: state.audits,
  users: state.users,
}))(AuditSummary);
