/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Icon, FormGroup, InputGroup,
} from '@blueprintjs/core';

import { networkStatus, beaconStatus } from '../Status';

const skipBeaconCheck = ['axis.camera', 'amcrest.camera', 'peplink'];
const skipNetworkCheck = ['cisco.meraki', 'aruba.iap'];

class LocationDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  handleQuery = e => this.setState({ query: e.target.value });

  renderDevice = (d) => {
    const { allDevices } = this.props;
    const allD = (allDevices || {}).data;
    const deviceLookup = allD
      .find(x => parseInt(x.id, 10) === parseInt(d.device.device_identifier, 10));
    const version = (deviceLookup || {}).iap_configuration
      && deviceLookup.iap_configuration.reported_version
      ? deviceLookup.iap_configuration.reported_version
      : '';
    const realVersion = (() => {
      if (version) {
        return version;
      }
      if (deviceLookup && deviceLookup.version && deviceLookup.version.name) {
        return deviceLookup.version.name;
      }
      return '';
    })();
    const skipNetwork = skipNetworkCheck.includes(d.device.type);
    const skipBeacon = skipBeaconCheck.includes(d.device.type);
    const isCMS = ((deviceLookup || {}).iap_configuration || { is_cms: false }).is_cms;
    return (
      <tr key={d.id}>
        <td><Link to={`/devices/${d.device.device_identifier}`}>{d.device.device_identifier}</Link></td>
        <td><Link to={`/devices/${d.device.device_identifier}`}>{d.device.name}</Link></td>
        <td className="has-text-centered"><Icon icon="cell-tower" intent={skipNetwork ? undefined : networkStatus(d.status) ? 'success' : 'danger'} /></td>
        <td className="has-text-centered"><Icon icon="satellite" intent={skipBeacon || isCMS ? undefined : beaconStatus(d.status) ? 'success' : 'danger'} /></td>
        <td className="has-text-centered"><Icon icon={d.monitored ? 'tick' : 'cross'} intent={d.monitored ? 'success' : ''} /></td>
        <td>{realVersion}</td>
      </tr>
    );
  }

  renderTable = (devices, location, match) => (
    <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive',
      { 'bp3-skeleton': location.id !== parseInt(match.params.id, 10) })}
    >
      <thead>
        <tr>
          <th>Device ID</th>
          <th>Name</th>
          <th className="has-text-centered">Network</th>
          <th className="has-text-centered">Beacon</th>
          <th className="has-text-centered">Monitored</th>
          <th>Version</th>
        </tr>
      </thead>
      <tbody>
        {(devices || []).map(this.renderDevice)}
      </tbody>
    </table>
  );

  renderNoDevices = () => <div>No Devices</div>


  render() {
    const { locationDevices, location, match } = this.props;
    const { query } = this.state;
    if ((locationDevices && !locationDevices.length) || locationDevices[0] === undefined) {
      return <div style={{ textAlign: 'center' }}>No Devices</div>;
    }
    const filteredData = (locationDevices || [])
      .filter(y => !!y)
      .filter(x => x.device.name.toLowerCase().includes(query.toLowerCase())
        || x.device.device_identifier.includes(query));
    return (
      <div style={{ marginBottom: 10, marginLeft: 10 }}>
        <FormGroup>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Names or ID's" />
        </FormGroup>
        {!!(filteredData || []).length && this.renderTable(filteredData, location, match)}
      </div>
    );
  }
}

LocationDevices.propTypes = {
  locationDevices: PropTypes.any,
  allDevices: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default LocationDevices;
