import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  FieldArray, Field, reduxForm, reset,
} from 'redux-form';
import {
  Button, NonIdealState, Spinner,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { SelectInput, FormSelectInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { saveResources, getRole } from 'actions/role';
import { getZones } from 'actions/site';
import { getOrganizationDevices } from 'actions/organization';

class Resources extends Component {
  componentDidMount() {
    const { dispatch, orgId } = this.props;
    if (orgId) {
      dispatch(getZones(orgId));
      dispatch(getOrganizationDevices(orgId));
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, orgId } = this.props;
    if (orgId !== prevProps.orgId) {
      dispatch(getZones(orgId));
      dispatch(getOrganizationDevices(orgId));
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, roleId } = this.props;
    return dispatch(saveResources(roleId, values.resources.map(
      ({ kind, resource }) => (kind === 'zone' ? ([{
        kind,
        // kind_id: parseInt(resource, 10),
        kind_id: resource.id,
      },
      {
        kind: 'site',
        kind_id: resource.site_id,
        // kind_id: zones.filter(x => x.id === parseInt(resource, 10))[0].site_id,
      },
      {
        kind: 'location',
        kind_id: resource.location_id,
        // kind_id: zones.filter(x => x.id === parseInt(resource, 10))[0].location_id,
      }]
      ) : ([{
        kind,
        kind_id: resource.id,
      }])),
    ).flat())).then(() => {
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            Resources saved
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getRole(roleId));
    }).then(() => {
      dispatch(reset('roles.resources'));
    });
  }

  @autobind
  renderResources({ fields }) {
    const { zones, cameraDevices } = this.props;

    const resources = fields.map((resource, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={i}>
        <td>
          <Field
            name={`${resource}.kind`}
            component={FormSelectInput}
            placeholder="Select Resource Type"
          >
            <option value="zone">Zone</option>
            <option value="camera">Camera</option>
          </Field>
        </td>
        <td>
          <Field
            name={`${resource}.resource`}
            component={SelectInput}
            items={[zones, cameraDevices].flat()}
            placeholder="Select Resource"
          />
          {/* {
              (fields && fields.get(i).kind !== 'camera') ? zones.map(x => (
                <option key={x.id} value={x.id}>
                  {`${x.name}`}
                </option>
              ))
                : cameraDevices.map(x => (
                  <option key={x.id} value={x.id}>
                    {`${x.name}`}
                  </option>
                ))}
                </Field> */}
        </td>
        <td className="has-text-right">
          <Button
            intent="danger"
            icon="trash"
            onClick={() => fields.remove(i)}
          />
        </td>
      </tr>
    ));

    return (
      <React.Fragment>
        <div className="columns">
          <div className="column has-text-right">
            <Button icon="plus" intent="primary" onClick={() => fields.push({ kind: 'zone' })}>
              Add
            </Button>
          </div>
        </div>
        { resources.length ? (
          <table className={classNames('bp3-html-table bp3-interactive', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th>Kind</th>
                <th>Resource</th>
              </tr>
            </thead>
            <tbody>
              { resources }
            </tbody>
          </table>
        ) : (
          <NonIdealState title="No Resources" />
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      submitting, handleSubmit, isAdmin, zonesPending,
      organizationDevicesPending,
    } = this.props;
    if (zonesPending || organizationDevicesPending) {
      return <Spinner size={100} />;
    }

    if (isAdmin) {
      return (
        <NonIdealState
          icon="crown"
          title="This is an admin role"
          description="Admin roles have access to all resources."
          className="non-ideal-device-card"
        />
      );
    }

    return (
      <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">

        <FieldArray name="resources" component={this.renderResources} />
        <div className="has-text-right">
          <Button
            style={{ marginTop: '16px' }}
            intent="primary"
            loading={submitting}
            disabled={submitting}
            type="submit"
            icon="tick"
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

Resources.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  roleId: PropTypes.number,
  orgId: PropTypes.number,
  isAdmin: PropTypes.bool,
  zones: PropTypes.array,
  cameraDevices: PropTypes.array,
  organizationDevicesPending: PropTypes.bool,
  zonesPending: PropTypes.bool,
};

const ALL_ZONES = {
  id: 0,
  organization_id: 0,
  location_id: 0,
  site_id: 0,
  name: 'All Zones',
};

const ALL_CAMERAS = {
  id: 0,
  name: 'All Cameras',
};

export default connect((state, props) => ({
  initialValues: props.resources ? {
    resources:
      props.resources.filter(x => x.kind === 'zone' || x.kind === 'camera').map(
        r => (r.kind === 'zone' ? ({
          kind: r.kind,
          resource: r.kind_id === 0
            ? ALL_ZONES
            : (state.zones.data || []).find(x => x.id === r.kind_id),
        }) : ({
          kind: r.kind,
          resource: r.kind_id === 0
            ? ALL_CAMERAS
            : (state.organizationDevices.data || []).find(x => x.id === r.kind_id),
        })),
      ),
  } : undefined,
  // validate: validatePermissions,
  zonesPending: state.zones.data === null || !!state.zones.pending,
  zones: [ALL_ZONES].concat(state.zones.data || []),
  organizationDevicesPending: state.organizationDevices.data === null
  || !!state.organizationDevices.pending,
  // organizationDevices: [ALL_CAMERAS].concat(state.organizationDevices.data || []),
  cameraDevices: [ALL_CAMERAS].concat((state.organizationDevices.data || []).filter(x => x.type === 'amcrest.camera'
  || x.type === 'axis.camera').map(x => ({ id: x.id, name: x.org_device_name }))),
}))(reduxForm({
  form: 'roles.resources',
  enableReinitialize: true,
})(Resources));
