import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import {
  H1, Spinner, Icon, Tag,
} from '@blueprintjs/core';
import qs from 'query-string';

import Visualize from './visualize';
import Summarize from './summarize';

class AuditCompare extends Component {
  @autobind
  goBack() {
    const { dispatch } = this.props;
    dispatch(push('/audits'));
  }

  render() {
    const {
      audits, location,
    } = this.props;
    const compareIds = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!compareIds.id || (audits.pending && !audits.data.length)) {
      return <Spinner size={100} />;
    }
    const { s3_input_path = '' } = (audits.data || [])
      .find(x => x.audit_id === parseInt(compareIds.id[0], 10)) || {};
    return (
      <div className="container">
        <H1>
          <Icon
            className="audit-back-icon"
            icon="circle-arrow-left"
            iconSize={30}
            onClick={this.goBack}
          />
          Compare Audits
          &nbsp;&nbsp;&nbsp;
          <Tag>S3 Input Path:</Tag>
          &nbsp;
          <Tag multiline>{s3_input_path}</Tag>
        </H1>
        <div>
          <Visualize audits={audits.data} compareIds={compareIds.id} />
        </div>
        <div>
          <Summarize compareIds={compareIds.id} />
        </div>
      </div>
    );
  }
}

AuditCompare.propTypes = {
  location: PropTypes.object,
  audits: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  audits: state.audits,
}))(AuditCompare);
