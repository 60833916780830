import { requestsReducer } from 'redux-saga-requests';
import {
  GET_REID_TASKS, GET_REID_TASK, TRACK_GROUND_TRUTH, REID_OUTPUT, TRACK_OUTPUT, GET_EXTRACT_TASK,
  REID_WAIT_TIME, REID_MERGE_OUTPUT, GET_REID_TASK_CLIPS,
  GET_REID_CLIP_PATCHES, GET_REID_TASK_GROUPS, GET_REID_AUDIT_TASKS, GET_REID_AUDIT_TASK,
  REID_AUDIT_SUMMARY, COMPARE_REID_AUDIT_TASKS,
  REID_AUDIT_MATRIX, REID_AUDIT_TRACKS,
} from '../constants';


export const reidTasksReducer = requestsReducer({
  actionType: GET_REID_TASKS,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    content.meta = action.payload.data.metadata || {};
    return content;
  },
});

export const reidTaskReducer = requestsReducer({
  actionType: GET_REID_TASK,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidOutputReducer = requestsReducer({
  actionType: REID_OUTPUT,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidWaitTimeReducer = requestsReducer({
  actionType: REID_WAIT_TIME,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidTrackReducer = requestsReducer({
  actionType: TRACK_OUTPUT,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidExtrackReducer = requestsReducer({
  actionType: GET_EXTRACT_TASK,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidTrackGroundTruthReducer = requestsReducer({
  actionType: TRACK_GROUND_TRUTH,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidMergeOutputReducer = requestsReducer({
  actionType: REID_MERGE_OUTPUT,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidTaskClipsReducer = requestsReducer({
  actionType: GET_REID_TASK_CLIPS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidClipPatchesReducer = requestsReducer({
  actionType: GET_REID_CLIP_PATCHES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidTaskGroupsReducer = requestsReducer({
  actionType: GET_REID_TASK_GROUPS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditTasksReducer = requestsReducer({
  actionType: GET_REID_AUDIT_TASKS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditTaskReducer = requestsReducer({
  actionType: GET_REID_AUDIT_TASK,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditSummaryReducer = requestsReducer({
  actionType: REID_AUDIT_SUMMARY,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditMatrixReducer = requestsReducer({
  actionType: REID_AUDIT_MATRIX,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditTracksReducer = requestsReducer({
  actionType: REID_AUDIT_TRACKS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const reidAuditCompareReducer = requestsReducer({
  actionType: COMPARE_REID_AUDIT_TASKS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});
