import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Spinner } from '@blueprintjs/core';
import _ from 'lodash';

import { getCameraModels } from 'actions/device';
import ModelForm from './modelForm';

class CameraModels extends Component {
  componentDidMount() {
    this.getCameraModels();
  }

  @autobind
  async getCameraModels() {
    const { dispatch, cameras } = this.props;
    if (cameras.length) {
      return Promise.all(cameras.map(x => dispatch(getCameraModels(x))));
    }
    return null;
  }

  render() {
    const {
      cameraModels, models, deviceCameras, device,
    } = this.props;
    const loaded = _.every(cameraModels, { resolved: true });
    if (!loaded || !(models.data || []).length) {
      return <Spinner size={100} />;
    }
    const initialValues = (deviceCameras || []).map(({ serial, vision_model }) => ({
      serial,
      vision_model,
      model_id: ((cameraModels || {})[serial] || { response: '' }).response,
    }));
    return (
      <ModelForm
        initialValues={{ camera_model_ids: initialValues }}
        models={models.data}
        refreshModels={this.getCameraModels}
        device={device.data}
      />
    );
  }
}

CameraModels.propTypes = {
  dispatch: PropTypes.func,
  cameraModels: PropTypes.object,
  models: PropTypes.object,
  deviceCameras: PropTypes.array,
  cameras: PropTypes.array,
  device: PropTypes.object,
};

export default connect((state, { cameras }) => ({
  cameraModels: _.pickBy(state.cameraModels, (v, k) => cameras.includes(k)),
  models: state.models,
  device: state.device,
}))(CameraModels);
