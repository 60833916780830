import { requestsReducer } from 'redux-saga-requests';
import { combineReducers } from 'redux';
import { LOGIN, GET_USER_PROFILE } from '../constants';

const authReducer = requestsReducer({
  actionType: LOGIN,
});

const userReducer = requestsReducer({
  actionType: GET_USER_PROFILE,
});

const baseReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
});

// eslint-disable-next-line import/prefer-default-export
export { baseReducer as reducer };
