import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';
import { requestsPromiseMiddleware } from 'redux-saga-requests';
// import logger from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
// import { routerMiddleware } from 'react-router-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';

export const history = createHistory();
function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    // Add other middleware on this line...
    requestsPromiseMiddleware({ auto: true }),
    sagaMiddleware,
    reactRouterMiddleware,
  ];

  const store = createStore(connectRouter(history)(rootReducer), initialState, compose(
    applyMiddleware(...middlewares),
  ));

  store.runSaga = sagaMiddleware.run;

  return store;
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate
    // your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant({
      ignore: ['form.organization.values.logo.lastModifiedDate', 'currentUser'],
    }),

    // logger,
    requestsPromiseMiddleware({ auto: true }),
    sagaMiddleware,
    reactRouterMiddleware,
  ];

  // add support for Redux dev tools
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(connectRouter(history)(rootReducer), initialState, composeEnhancers(
    applyMiddleware(...middlewares),
  ));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  store.runSaga = sagaMiddleware.run;

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
