import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { addCardstoDash, getDash } from 'actions/dash';

import Location from './location';
import Org from './org';
import Site from './site';

class CardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };
  }

  @autobind
  removeCard() {
    const { card, dispatch, dash } = this.props;
    const cards = dash.cards.filter(x => x.id !== card.id);
    this.setState({ deleting: true });
    dispatch(addCardstoDash({ cards }, dash.id))
      .then(() => dispatch(getDash(dash.id)))
      .finally(() => this.setState({ deleting: false }));
  }

  render() {
    const {
      organizations, locations, card, dispatch, zones,
    } = this.props;
    const { deleting } = this.state;
    const cardProps = {
      card,
      dispatch,
      onDelete: this.removeCard,
      deleting,
    };
    if (card.kind === 'site') {
      const { tab_permissions = [] } = (zones.data || []).find(x => x.id === card.kind_id) || {};
      const zoneObj = (zones.data || []).find(x => x.id === card.kind_id);
      const orgId = (zoneObj || {}).organization_id;
      return <Site {...cardProps} zones={zones} permissions={tab_permissions[0]} orgId={orgId} />;
    }
    if (card.kind === 'organization') return <Org {...cardProps} organizations={organizations} />;
    if (card.kind === 'location') return <Location {...cardProps} locations={locations} />;
    return null;
  }
}

CardItem.propTypes = {
  dispatch: PropTypes.func,
  organizations: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
  card: PropTypes.any,
};

export default connect(state => ({
  sites: state.sites,
  locations: state.locations,
  organizations: state.organizations,
  zones: state.zones,
}))(CardItem);
