import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';

import { getAudits } from 'actions/audit';

const asTrue = () => true;
const nameCache = {};
const defaultRenderer = o => (o ? (o.name || o.audit_name || nameCache[o] || o) : o);
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;


class AuditSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleSearch = _.debounce(this.doHandleSearch, 100);
  }

  componentDidMount() {
    const { value, dispatch, clip } = this.props;
    if (value) {
      this.setState({ loading: true });
      dispatch(getAudits(
        undefined, 20, value, clip,
      )).then(() => this.setState({ loading: false }));
    }
  }

  @autobind
  onItemSelect(o) {
    const { onChange } = this.props;
    onChange(o.audit_id);
  }

  @autobind
  handleOpen() {
    const { dispatch, clip } = this.props;
    this.setState({ loading: true });
    dispatch(getAudits(
      undefined, undefined, 20, undefined, clip,
    )).then(() => this.setState({ loading: false }));
  }

  @autobind
  doHandleSearch(query) {
    const { dispatch, clip } = this.props;
    this.setState({ loading: true });
    dispatch(getAudits(
      query, 20, undefined, clip,
    )).then(() => this.setState({ loading: false }));
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    const { value } = this.props;
    if (item === '--') {
      return (
        <MenuItem
          active={false}
          disabled={modifiers.disabled}
          key={item}
          icon={item === value ? 'tick' : 'blank'}
          onClick={handleClick}
          text={item}
        />
      );
    }
    if (!modifiers.matchesPredicate) {
      return null;
    }
    if (!item.audit_id) {
      return null;
    }
    const itemRenderer = defaultRenderer;
    nameCache[item.audit_id] = item.audit_name;

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.audit_id}
        icon={item.audit_id === value && value ? 'tick' : 'blank'}
        onClick={handleClick}
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const {
      audits, value, className, includeReset,
    } = this.props;
    const { loading } = this.state;
    let items = audits.data || [];
    const itemRenderer = defaultRenderer;
    const site = items.find(x => x.audit_id === value) || (value || null);
    if (includeReset) {
      items = ['--'].concat(items);
    }
    return (
      <Select
        noResults={<MenuItem disabled key="nr" text="No results." />}
        filterable
        resetOnSelect
        itemRenderer={this.renderItem}
        itemPredicate={loading ? asTrue : defaultFilter}
        popoverProps={{
          minimal: true, fill: true, popoverClassName: 'popover-sz', onOpening: this.handleOpen,
        }}
        itemDisabled={loading ? asTrue : undefined}
        items={loading ? [{ id: 'loading', name: 'Loading...' }] : items}
        onItemSelect={this.onItemSelect}
        onQueryChange={this.handleSearch}
        loading={loading}
      >
        <Button
          text={itemRenderer(site) || 'Select an audit'}
          fill
          rightIcon="caret-down"
          className={className}
        />
      </Select>
    );
  }
}

AuditSelection.propTypes = {
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  audits: PropTypes.object,
  value: PropTypes.number,
  className: PropTypes.string,
  clip: PropTypes.string,
  includeReset: PropTypes.bool,
};

export default connect(state => ({
  audits: state.audits,
}))(AuditSelection);
