import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './list';
import Create from './create';
import Organization from './organization';
import Role from './role';
import CreateRole from './create_role';

export default () => (
  <Switch>
    <Route path="/organizations/create" component={Create} />
    <Route path="/organizations/:id/roles/create" component={CreateRole} />
    <Route path="/organizations/:id/roles/:role_id" component={Role} />
    <Route path="/organizations/:id" component={Organization} />
    <Route component={List} />
  </Switch>
);
