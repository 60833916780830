import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H2, Spinner } from '@blueprintjs/core';

import { getAlert } from 'actions/alerts';
import DeviceAlert from './deviceAlertDetails';
import ZoneAlert from './zoneAlertDetails';

class AlertDetails extends Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    const alert_id = match.params.id;
    dispatch(getAlert(alert_id));
  }

  render() {
    const { alert, dispatch } = this.props;
    if (alert.pending) return <Spinner size="100" />;
    const zoneAlerts = ['occupancy', 'total_ins', 'total_outs', 'predicted_waittime', 'realtime_positions'];
    let details;
    if (zoneAlerts.includes(alert.data.metric)) {
      details = (
        <ZoneAlert status={alert.data.status} dispatch={dispatch} metric={alert.data.metric} />
      );
    } else {
      details = (
        <DeviceAlert status={alert.data.status} dispatch={dispatch} metric={alert.data.metric} />
      );
    }
    return (
      <div className="container">
        <H2>{alert.data.alert_name}</H2>
        {details}
      </div>
    );
  }
}

AlertDetails.propTypes = {
  dispatch: PropTypes.func,
  alert: PropTypes.object,
  match: PropTypes.object,
};

export default connect(state => ({
  alert: state.alert,
}))(AlertDetails);
