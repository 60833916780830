import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';


const nameCache = {};
function get_cam_name(cam) {
  try {
    return cam.device.name;
  } catch (e) {
    return nameCache[cam];
  }
}

const asTrue = () => true;
const defaultRenderer = o => (o ? (get_cam_name(o) || o) : o);
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;


class SiteSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleSearch = _.debounce(this.doHandleSearch, 100);
  }

  componentDidMount() {

  }

  @autobind
  onItemSelect(o) {
    const { onChange } = this.props;
    onChange(o.device.device_identifier);
  }

  @autobind
  handleOpen() {
  }

  @autobind
  doHandleSearch() {

  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    const { value } = this.props;
    if (item === '--') {
      return (
        <MenuItem
          active={false}
          disabled={modifiers.disabled}
          key={item}
          icon={item === value ? 'tick' : 'blank'}
          onClick={handleClick}
          text={item}
        />
      );
    }
    if (!modifiers.matchesPredicate) {
      return null;
    }
    if (!item) {
      return null;
    }
    const itemRenderer = defaultRenderer;
    nameCache[item.device_id] = item.device.name;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.device_id}
        icon={item.device.device_identifier === value && value ? 'tick' : 'blank'}
        onClick={handleClick}
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const {
      value, className, site_cameras,
    } = this.props;

    // const { id } =  this.props.;
    const { loading } = this.state;
    const items = site_cameras || [];
    // fine camera with site id equal to value
    // let camera = items.filter(item => item.device.device_identifier === value);
    // let cam_name = camera.device.name;

    const itemRenderer = defaultRenderer;
    const site = items.find(x => x.device.device_identifier === value) || (value || null);
    // if (includeReset) {
    //   items = ['--'].concat(items);
    // }

    return (
      <Select
        noResults={<MenuItem disabled key="nr" text="No results." />}
        filterable
        resetOnSelect
        itemPredicate={loading ? asTrue : defaultFilter}
        itemRenderer={this.renderItem}
        // itemPredicate={loading ? asTrue : defaultFilter}
        popoverProps={{
          minimal: true, fill: true, popoverClassName: 'popover-sz', onOpening: this.handleOpen,
        }}
        itemDisabled={loading ? asTrue : undefined}
        items={items} // {loading ? [{ id: 'loading', name: 'Loading...' }] : items}
        onItemSelect={this.onItemSelect}
        onQueryChange={this.handleSearch}
        loading={loading}
      >
        <Button
          text={itemRenderer(site) || 'Select a Camera'}
          fill
          rightIcon="caret-down"
          className={className}
        />
      </Select>
    );
  }
}

SiteSelection.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  site_cameras: PropTypes.array,
};

export default connect(state => (

  {
    siteDevices: state.siteDevices,
  }))(SiteSelection);
