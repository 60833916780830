/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Spinner, H3, H4, Card, FormGroup, Button, Drawer, Classes,
  Icon,
} from '@blueprintjs/core';
import { } from 'lodash';
import { autobind } from 'core-decorators';
import { replace, push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import moment from 'moment';

import {
  getReidTrackOutput, getReidTaskOutput, getReidTrackGroundTruth, postReidTrackGroundTruth, updateReidTrackGroundTruth,
  getReidTaskWaitTimes,
} from 'actions/reid';


const PatchesList = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    patches, type, handleAccept, handleReject, accepted, rejected, isEdit,
  } = props;

  const isAccepted = patch => (accepted && accepted.filter(x => x.patch_id === patch.patch_id).length > 0);

  const isRejected = patch => (rejected && rejected.filter(x => x.patch_id === patch.patch_id).length > 0);

  const PatchItem = (props2) => {
    const {
      patch,
    } = props2;
    const is_accepted = isAccepted(patch);
    const is_rejected = isRejected(patch);
    const colorborder = () => {
      if (is_accepted) {
        return '0.5em solid green';
      }
      if (is_rejected) {
        return '0.5em solid orange';
      }
      return '0.5em solid white';
    };

    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          <Button style={{ marginRight: '1em' }} intent="success" active={is_accepted} outlined minimal disabled={!(isEdit)} onClick={() => handleAccept(patch)}>Accept</Button>
          <Button intent="warning" outlined minimal disabled={!(isEdit)} active={is_rejected} onClick={() => handleReject(patch)}>Reject</Button>
        </div>
        <Card elevation="3" style={{ border: colorborder(), marginBottom: '1em' }}>
          <img
            src={patch.url}
            alt=""
            className="camera-vision-image"
            style={{ width: 'auto', height: '20em' }}
            draggable={false}
            id="img-feed"
          />
        </Card>
      </div>
    );
  };

  const currPatches = patches ? patches.filter(x => x.type === type) : [];
  return (
    <ul>
      {currPatches.map(patch => (
        <li key={patch.patch_id}>
          <PatchItem patch={patch} isEdit={isEdit} handleAccept={handleAccept} handleReject={handleReject} />
        </li>
      ))}
    </ul>

  );
};
class Patches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      patches: [],
      accepted: [],
      rejected: [],
      groundTruths: [],
      edited: [],
      isEdit: false,
      saveLoading: false,
      showHelp: false,
      waitTime: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
  }

  @autobind
  getGroundTruth() {
    const { match, dispatch } = this.props;
    const { id, clip_name, track_id } = match.params;
    const { patches, isEdit } = this.state;
    let currAccepted = [];
    let currRejected = [];
    dispatch(getReidTrackGroundTruth(id, clip_name, track_id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const editable = payload.data.content.length === 0 || isEdit;
        if (payload.data.content.length > 0 && patches) {
          currAccepted = patches.filter(x1 => payload.data.content.some(x2 => (x1.patch_id === x2.patch_id && x2.accepted)));
          currRejected = patches.filter(x1 => payload.data.content.some(x2 => (x1.patch_id === x2.patch_id && !x2.accepted)));
        }
        this.setState({
          groundTruths: payload.data.content, isEdit: editable, accepted: currAccepted, rejected: currRejected,
        });
      }
    });
  }

  @autobind
  getPatches() {
    const { match } = this.props;
    const { id, clip_name, track_id } = match.params;
    return dispatch => new Promise((resolve) => {
      dispatch(getReidTrackOutput(id, clip_name, track_id)).then(({ payload }) => {
        if (payload && payload.data.content) {
          this.setState({ data: payload.data.content, patches: payload.data.content[0].patches });
        }
      });
      resolve();
    });
  }

  @autobind
  getData() {
    const { dispatch, match } = this.props;
    const { id, clip_name, track_id } = match.params;
    dispatch(getReidTrackOutput(id, clip_name, track_id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content, patches: payload.data.content[0].patches });
      }
    }).then(() => this.getGroundTruth()).then(() => this.getWaitTimes());

    dispatch(getReidTaskOutput(id));
  }

  @autobind
  getWaitTimes() {
    const { match, dispatch } = this.props;
    const { id, clip_name, track_id } = match.params;
    let wait_time = '';
    dispatch(getReidTaskWaitTimes(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const waitTimes = payload.data.content;
        const gt = waitTimes.filter(o => (o.clip_name === clip_name && o.track_id === parseInt(track_id, 10)))[0];
        if (gt) {
          const start_epoch = moment.unix(gt.start_epoch);
          const end_epoch = moment.unix(gt.end_epoch);
          wait_time = moment.utc(end_epoch.diff(start_epoch)).format('mm:ss');
        }
        this.setState({ waitTime: wait_time });
      }
    });
  }

  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  @autobind
  handleHelp() {
    this.setState({ showHelp: true });
  }

  @autobind
  handleCloseHelp() {
    this.setState({ showHelp: false });
  }

  @autobind
  async handleSave() {
    const {
      accepted, rejected, data, edited,
    } = this.state;
    const {
      getData, dispatch, match,
    } = this.props;
    const isComplete = accepted.length + rejected.length === data[0].patches.length;
    if (!isComplete) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Not all patches have been marked</span>,
        intent: 'danger',
      });
    }
    this.setState({ saveLoading: true });
    if (edited.length !== 0) {
      let editedData = [];
      edited.forEach((patch) => {
        const gt = {
          accepted: accepted.filter(p => p.patch_id === patch.patch_id).length > 0,
          patch_id: patch.patch_id,
          track_id: parseInt(match.params.track_id, 10),
          clip_name: match.params.clip_name,
        };
        editedData = [...editedData, gt];
      });
      return dispatch(updateReidTrackGroundTruth(match.params.id, match.params.clip_name, match.params.track_id, editedData))
        .then(() => (getData ? this.delayGet(3000).then(() => this.getData()) : true))
        .then(() => this.setState({ isEdit: false, saveLoading: false }));
    }
    let groundtruths = [];
    accepted.forEach((patch) => {
      const gt = {
        accepted: true,
        patch_id: patch.patch_id,
        relative_path: patch.relative_path,
        type: patch.type,
        timestamp: patch.timestamp,

      };
      groundtruths = [...groundtruths, gt];
    });
    rejected.forEach((patch) => {
      const gt = {
        accepted: false,
        patch_id: patch.patch_id,
        relative_path: patch.relative_path,
        type: patch.type,
        timestamp: patch.timestamp,
      };
      groundtruths = [...groundtruths, gt];
    });
    const payload = {
      ground_truths: groundtruths,
    };
    return dispatch(postReidTrackGroundTruth(match.params.id, match.params.clip_name, match.params.track_id, payload))
      .then(() => (getData ? this.delayGet(3000).then(() => this.getData()) : true))
      .then(() => this.setState({ isEdit: false, saveLoading: false }));
  }

  @autobind
  handleEdit() {
    this.setState({ isEdit: true, edited: [] });
  }

  @autobind
  handleClear() {
    this.setState({ accepted: [], rejected: [] });
  }


  @autobind
  hasGroundTruth() {
    const { reidTrackGroundTruth } = this.props;

    if (reidTrackGroundTruth.pending || reidTrackGroundTruth.data.length === 0) {
      return false;
    }
    return true;
  }

  @autobind
  handleAccept(patch) {
    const { accepted, rejected, edited } = this.state;
    if (accepted.filter(e => e.patch_id === patch.patch_id).length > 0) {
      return;
    }
    const index = rejected.findIndex(x => x.patch_id === patch.patch_id);
    const tmpEdited = this.hasGroundTruth() && edited.indexOf(patch.patch_id) === -1 ? [...edited, patch] : edited;

    if (index > -1) {
      const tmp = [...rejected];
      tmp.splice(index, 1);
      this.setState({ accepted: [...accepted, patch], rejected: tmp, edited: tmpEdited });
    } else {
      this.setState({ accepted: [...accepted, patch], rejected, edited: tmpEdited });
    }
  }

  @autobind
  handleAcceptAll() {
    const { patches } = this.state;

    const tmpEdited = this.hasGroundTruth() ? patches : [];
    this.setState({ accepted: patches, rejected: [], edited: tmpEdited });
  }

  @autobind
  handleRejectAll() {
    const { patches } = this.state;

    const tmpEdited = this.hasGroundTruth() ? patches : [];
    this.setState({ rejected: patches, accepted: [], edited: tmpEdited });
  }

  @autobind
  handleReject(patch) {
    const { accepted, rejected, edited } = this.state;
    if (rejected.filter(e => e.patch_id === patch.patch_id).length > 0) {
      return;
    }
    const index = accepted.findIndex(x => x.patch_id === patch.patch_id);
    const tmpEdited = this.hasGroundTruth() && edited.indexOf(patch.patch_id) === -1 ? [...edited, patch] : edited;

    if (index > -1) {
      const tmp = [...accepted];
      tmp.splice(index, 1);
      this.setState({ rejected: [...rejected, patch], accepted: tmp, edited: tmpEdited });
    } else {
      this.setState({ rejected: [...rejected, patch], accepted, edited: tmpEdited });
    }
  }

  @autobind
  handlePreviousUserTrack() {
    const {
      reidOutput, match, dispatch, currentUser,
    } = this.props;
    // const { isEdit } = this.state;
    let prev_track_id = '';
    let prev_clip_name = '';

    let assignedTracks = reidOutput.data.filter(x => x.assignee === currentUser.profile.name).length === 0 ? reidOutput.data : reidOutput.data.filter(x => x.assignee === currentUser.profile.name);
    assignedTracks = assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0).length === 0 ? assignedTracks : assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0);
    if (assignedTracks.filter(x => x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name).length === 0) {
      prev_clip_name = assignedTracks[assignedTracks.length - 1].clip_name;
      prev_track_id = assignedTracks[assignedTracks.length - 1].reference_track_id;
    }
    assignedTracks.forEach((x, i, arr) => {
      if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
        if (i === 0) {
          prev_track_id = arr[arr.length - 1].reference_track_id;
          prev_clip_name = arr[arr.length - 1].clip_name;
        } else {
          prev_track_id = arr[i - 1].reference_track_id;
          prev_clip_name = arr[i - 1].clip_name;
        }
      }
    });


    // if (isEdit) {
    //   assignedTracks = assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0);
    //   assignedTracks.forEach((x, i, arr) => {
    //     if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
    //       if (i === 0) {
    //         prev_track_id = arr[arr.length - 1].reference_track_id;
    //         prev_clip_name = arr[arr.length - 1].clip_name;
    //       } else {
    //         prev_track_id = arr[i - 1].reference_track_id;
    //         prev_clip_name = arr[i - 1].clip_name;
    //       }
    //     }
    //   });
    // } else {
    //   assignedTracks.forEach((x, i, arr) => {
    //     if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
    //       if (i === 0) {
    //         prev_track_id = arr[arr.length - 1].reference_track_id;
    //         prev_clip_name = arr[arr.length - 1].clip_name;
    //       } else {
    //         prev_track_id = arr[i - 1].reference_track_id;
    //         prev_clip_name = arr[i - 1].clip_name;
    //       }
    //     }
    //   });
    // }

    if (prev_track_id !== '' && prev_clip_name.length > 0) {
      dispatch(replace(`/reid/${match.params.id}/groundtruth/${prev_clip_name}/${prev_track_id}`));
      this.setState({
        data: [], groundTruths: [], patches: [], accepted: [], rejected: [], edited: [],
      }, () => this.getData());
    }
  }

  @autobind
  handlePreviousTrack() {
    const { reidOutput, match, dispatch } = this.props;
    let prev_track_id = '';
    let prev_clip_name = '';
    reidOutput.data.forEach((x, i, arr) => {
      if (i > 0 && (x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
        prev_track_id = arr[i - 1].reference_track_id;
        prev_clip_name = arr[i - 1].clip_name;
      }
    });

    if (prev_track_id !== '' && prev_clip_name.length > 0) {
      dispatch(replace(`/reid/${match.params.id}/groundtruth/${prev_clip_name}/${prev_track_id}`));
      this.setState({
        data: [], groundTruths: [], patches: [], accepted: [], rejected: [], edited: [],
      }, () => this.getData());
    }
  }

  @autobind
  handleNextUserTrack() {
    const {
      reidOutput, match, dispatch, currentUser,
    } = this.props;
    // const { isEdit } = this.state;
    let next_clip_name = '';
    let next_track_id = '';

    let assignedTracks = reidOutput.data.filter(x => x.assignee === currentUser.profile.name).length === 0 ? reidOutput.data : reidOutput.data.filter(x => x.assignee === currentUser.profile.name);

    assignedTracks = assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0).length === 0 ? assignedTracks : assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0);
    if (assignedTracks.filter(x => x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name).length === 0) {
      next_clip_name = assignedTracks[0].clip_name;
      next_track_id = assignedTracks[0].reference_track_id;
    } else {
      assignedTracks.forEach((x, i, arr) => {
        if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
          if (i < arr.length - 1) {
            next_clip_name = arr[i + 1].clip_name;
            next_track_id = arr[i + 1].reference_track_id;
          } else {
            next_clip_name = arr[0].clip_name;
            next_track_id = arr[0].reference_track_id;
          }
        }
      });
    }

    // if (isEdit) {
    //   assignedTracks = assignedTracks.filter(x => x.reid_ground_truth_aggreg && x.reid_ground_truth_aggreg.total_accepted === 0 && x.reid_ground_truth_aggreg.total_rejected === 0);
    //   assignedTracks.forEach((x, i, arr) => {
    //     if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
    //       if (i >= arr.length) {
    //         next_clip_name = arr[0].clip_name;
    //         next_track_id = arr[0].reference_track_id;
    //       } else {
    //         next_clip_name = arr[i + 1].clip_name;
    //         next_track_id = arr[i + 1].reference_track_id;
    //       }
    //     }
    //   });
    // } else {
    //   assignedTracks.forEach((x, i, arr) => {
    //     if ((x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
    //       if (i < arr.length - 1) {
    //         next_clip_name = arr[i + 1].clip_name;
    //         next_track_id = arr[i + 1].reference_track_id;
    //       } else {
    //         next_clip_name = arr[0].clip_name;
    //         next_track_id = arr[0].reference_track_id;
    //       }
    //     }
    //   });
    // }

    if (next_clip_name.length > 0 && next_track_id !== 0) {
      dispatch(replace(`/reid/${match.params.id}/groundtruth/${next_clip_name}/${next_track_id}`));
      this.setState({
        data: [], patches: [], groundTruths: [], accepted: [], rejected: [], edited: [],
      }, () => this.getData());
    } else {
      return AppToaster.show({
        icon: 'tick',
        message: <span>No more tracks to mark</span>,
        intent: 'success',
      });
    }
    return null;
  }

  @autobind
  handleNextTrack() {
    const { reidOutput, match, dispatch } = this.props;
    let next_clip_name = '';
    let next_track_id = '';
    reidOutput.data.forEach((x, i, arr) => {
      if (i + 1 < arr.length && (x.reference_track_id === parseInt(match.params.track_id, 10) && x.clip_name === match.params.clip_name)) {
        next_clip_name = arr[i + 1].clip_name;
        next_track_id = arr[i + 1].reference_track_id;
      }
    });

    if (next_clip_name.length > 0 && next_track_id !== 0) {
      dispatch(replace(`/reid/${match.params.id}/groundtruth/${next_clip_name}/${next_track_id}`));
      this.setState({
        data: [], patches: [], groundTruths: [], accepted: [], rejected: [], edited: [],
      }, () => this.getData());
    } else {
      return AppToaster.show({
        icon: 'tick',
        message: <span>No more tracks to mark</span>,
        intent: 'success',
      });
    }
    return null;
  }

  render() {
    const {
      reidOutput, reidTrack, match, dispatch,
    } = this.props;
    const {
      showHelp, data, accepted, rejected, patches, isEdit, saveLoading, groundTruths, edited,
      waitTime,
    } = this.state;
    if (
      (reidOutput.pending || reidOutput.data.length === 0)
          || (reidTrack.pending || reidTrack.data.length === 0)
    ) {
      return <Spinner size={100} />;
    }

    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div className="container" style={{ marginBottom: 20 }}>
          <div>
            <div className="reid-header-container">
              <div className="columns">
                <div className="column">
                  <div style={{ margin: '1em' }}>
                    <Icon
                      title="Go Back to Task"
                      className="reid-back-icon"
                      icon="circle-arrow-left"
                      iconSize={20}
                      onClick={() => dispatch(push(`/reid/${match.params.id}`))}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <div className="bp3-align-left" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button title="Help" icon="help" style={{ marginRight: '1em', marginTop: '1em' }} className="m-l-sm" onClick={this.handleHelp} />
                      <Button
                        title="Previous Track"
                        className="back-arrow"
                        icon="circle-arrow-left"
                                  // disabled={(isFirst)}
                        style={{ marginRight: '1em', marginTop: '1em' }}
                        onClick={this.handlePreviousTrack}
                      />
                      <Button
                        title="Next Track"
                        className="forward-arrow"
                        icon="circle-arrow-right"
                        style={{ marginTop: '1em' }}
                                  // disabled={(isLast)}
                        onClick={this.handleNextTrack}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Card elevation="3" style={{ height: '100px' }}>
                    <div style={{ margin: '0.25em' }}>
Clip Name:
                      {' '}
                      {match.params.clip_name}
                    </div>
                    <div style={{ margin: '0.25em' }}>
                          Track ID:
                      {' '}
                      {match.params.track_id}
                    </div>
                    <div style={{ margin: '0.25em' }}>
                          Assigned:
                      {' '}
                      {data.length > 0 ? data[0].assignee : ''}
                    </div>
                  </Card>
                </div>
                <div className="column">
                  <Card elevation="3" style={{ height: '100px' }}>
                    <div style={{
                      width: '300px', display: 'flex', marginTop: 10, justifyContent: 'space-between',
                    }}
                    >
                      <div>
                        <FormGroup label="Accepted">
                          <div>{accepted.length}</div>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup label="Rejected">
                          <div>{rejected.length}</div>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup label="Total">
                          <div>{patches.length}</div>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup label="Wait Time">
                          <div>{waitTime}</div>
                        </FormGroup>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div style={{ marginTop: '1em' }}>
                {/* Toggle Tracks Assigned to: {currentUser.profile.name} */}
                <div className="bp3-align-left" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    title="Previous Track for User"
                    className="back-arrow"
                    icon="circle-arrow-left"
                              // disabled={(isFirst)}
                    style={{ marginRight: '1em', marginTop: '1em' }}
                    onClick={this.handlePreviousUserTrack}
                  >
Previous Track
                  </Button>
                  <Button
                    title="Next Track for User"
                    className="forward-arrow"
                    icon="circle-arrow-right"
                    style={{ marginTop: '1em' }}
                              // disabled={(isLast)}
                    onClick={this.handleNextUserTrack}
                  >
Next Track
                  </Button>
                  <Button style={{ marginRight: '1em', marginLeft: '1em' }} intent="success" active={accepted.length === patches.length} outlined minimal disabled={!(isEdit)} onClick={this.handleAcceptAll}>Accept All</Button>
                  <Button intent="warning" outlined minimal disabled={!(isEdit)} active={rejected.length === patches.length} onClick={this.handleRejectAll}>Reject All</Button>
                  <Button title="Save Ground Truth" loading={(saveLoading)} style={{ marginRight: '1em', marginLeft: '1em' }} onClick={this.handleSave} disabled={!(isEdit)} intent="primary" type="submit" icon="tick">
                    {(groundTruths.length > 0) ? 'Update' : 'Save'}
                  </Button>
                  <Button title="Edit Ground Truth" onClick={this.handleEdit} disabled={(isEdit)} intent="primary" type="reset" icon="play">
                              Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {
            (accepted.length > 0 && (
            <Card
              elevation="4"
              style={{
                display: 'inline-block', position: 'fixed', float: 'left', left: '0',
              }}
            >
              <div>
              Source Patch:
              </div>
              <img
                src={accepted[0] ? accepted[0].url : 'https://dummyimage.com/300x200/000/fff&text=^+image+not+found'}
                height="50px"
                width="auto"
                alt=""
                id="img-feed"
              />
            </Card>
            ))
          }
        </div>
        <div className="container" style={{ marginBottom: 20 }}>
          <div className="columns">
            <div className="column">
              <H4>Entry</H4>
              <PatchesList patches={patches} edited={edited} groundtruths={groundTruths} isEdit={isEdit} handleAccept={this.handleAccept} handleReject={this.handleReject} type="entry" accepted={accepted} rejected={rejected} />
            </div>
            <div className="column">
              <H4>Exit</H4>
              <PatchesList patches={patches} edited={edited} groundtruths={groundTruths} isEdit={isEdit} handleAccept={this.handleAccept} handleReject={this.handleReject} type="exit" accepted={accepted} rejected={rejected} />
            </div>
          </div>
        </div>
        <Drawer
          icon="info-sign"
          onClose={this.handleCloseHelp}
          title="Help"
          isOpen={showHelp}
          size={Drawer.SIZE_SMALL}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={`bp3-running-text ${Classes.DIALOG_BODY}`}>
              <H3>Instructions</H3>
              <div>
                <div>
                  Overview:
                  <ul>
                    <li>
                          Left-hand column: patches captured from the entry clip(s).
                    </li>
                    <li>
                          Right-hand column: patches captured from the exit clip(s).
                    </li>
                  </ul>
                </div>
                <div>
                  Procedure:
                  <ol>
                    <li>
                    Accept all patches that are of the same person.
                    </li>
                    <li>
                    Reject all patches that are not the same person as in the accepted patches.
                    </li>
                    <li>
                    Click save when finished.
                    </li>
                  </ol>
                </div>
                <div>
                  Considerations:
                  <ol>
                    <li>
                    Each patch should have only
                      <strong> one subject.</strong>
                      <ol type="a">
                        <li>
                        If there is more than one person in the patch, it must be clear which person is the subject; if it is not clear, reject that patch.
                        </li>
                        <li>
                        If there is a clear subject in the patch but another person or object in the foreground is blocking that person, then accept the patch only if at least approximately 50% of the subject person is visible.
                        </li>
                        <li>
                        If the patch seems to be centered more around the floor / background than the person, then that person cannot be considered the subject and the patch should be rejected
                        </li>
                      </ol>
                    </li>
                    <li>
                    If the same person cannot be accepted in both the entry and exit clips, then the all patches should be rejected (reject all).
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER} />
        </Drawer>
      </div>
    );
  }
}

Patches.propTypes = {
  dispatch: PropTypes.func,
  getData: PropTypes.func,
  reidOutput: PropTypes.object,
  reidTrack: PropTypes.object,
  reidTrackGroundTruth: PropTypes.object,
  match: PropTypes.object,
  currentUser: PropTypes.object,
};

export default connect(state => ({
  patches: state.patches,
  users: state.users,
  reidTask: state.reidTask,
  reidOutput: state.reidOutput,
  reidTrack: state.reidTrack,
  reidTrackGroundTruth: state.reidTrackGroundTruth,
  token: state.currentUser.token.access_token,
  isEdit: state.isEdit,
  currentUser: state.currentUser,
}))(Patches);
