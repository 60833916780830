import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import {
  Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { SelectInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getDevice, assignDevice } from 'actions/device';

class DeviceAssign extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, device, deviceName } = this.props;
    return dispatch(assignDevice(device.id, values.organization.id)).then(() => {
      dispatch(reset('device-assign'));
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {deviceName}
            </strong>
            : Device assigned
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getDevice(device.id));
    });
  }

  render() {
    const {
      submitting, handleSubmit, organizations,
    } = this.props;
    const orgs = organizations.map(x => ({ id: x.id, name: x.name }));

    return (
      <div className="columns">
        <form className="column" onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={SelectInput}
            name="organization"
            label="Assign Organization"
            items={orgs}
          />
          <div className="has-text-right">
            <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
              Assign
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

DeviceAssign.propTypes = {
  device: PropTypes.object,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organizations: PropTypes.object,
  deviceName: PropTypes.string,
};

export default reduxForm({
  form: 'device-assign',
})(DeviceAssign);
