import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Button, Callout, H4, Checkbox,
} from '@blueprintjs/core';
import { patchVisionParameters, getVisionParameters } from 'actions/device';
import _ from 'lodash';

class PolygonLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      points: [],
      anchorPoint: {},
      setAnchor: false,
      configureNew: false,
      viewCurrent: false,
      submitting: false,
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  // eslint-disable-next-line react/sort-comp
  handleKeyDown(e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  @autobind
  handleSave() {
    const { points, anchorPoint } = this.state;
    const { dispatch, device } = this.props;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    const counting_line = points.map(({ x, y }) => [
      (x / clientWidth) * naturalWidth,
      (y / clientHeight) * naturalHeight,
    ]);
    const counting_anchor = [
      (anchorPoint.x / clientWidth) * naturalWidth,
      (anchorPoint.y / clientHeight) * naturalHeight,
    ];
    this.setState({ submitting: true });
    dispatch(patchVisionParameters(device.id, { counting_line, counting_anchor }))
      .then(() => dispatch(getVisionParameters(device.id)))
      .then(() => this.configureRemove())
      .then(() => this.setState({ viewCurrent: true }))
      .finally(() => this.setState({ submitting: false }));
  }

  @autobind
  handleKeyPress(e) {
    const pointId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const { points } = this.state;
    const { clientHeight, clientWidth, naturalWidth } = this.getImageDimensions();
    if (clientHeight === 0 || clientWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentPoint = points.find(x => x.id === pointId);
    const filteredPoints = points.filter(y => y.id !== pointId);
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newMappedPoints = [...filteredPoints, newPoint].sort((a, b) => a.id - b.id);
    return this.setState({ points: newMappedPoints });
  }

  @autobind
  handleDrop(e) {
    if (!['polygon_image', 'polygon_svg', 'polgyon_anchor'].includes(e.target.id)) {
      return null;
    }
    e.preventDefault();
    e.stopPropagation();
    const { points } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const { x, y } = e.nativeEvent;
    const { clientHeight, clientWidth } = this.getImageDimensions();
    if (clientHeight === 0 || clientWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    if (this.imageRef.current) {
      const c = this.imageRef.current.getBoundingClientRect();
      const newPoint = {
        x: (x - c.x),
        y: (y - c.y),
        id: parseInt(id, 10),
      };
      const newPoints = [...points.filter(f => f.id !== parseInt(id, 10)), newPoint]
        .sort((a, b) => a.id - b.id);
      return this.setState({ points: newPoints });
    }
    return null;
  }

  @autobind
  handleCurrent() {
    const { viewCurrent } = this.state;
    this.setState({ viewCurrent: !viewCurrent });
  }

  @autobind
  setAnchor() {
    this.setState({ setAnchor: true });
  }

  @autobind
  hideAnchor() {
    this.setState({ setAnchor: false, anchorPoint: {} });
  }

  @autobind
  configureNew() {
    this.setState({ configureNew: true });
  }

  @autobind
  configureRemove() {
    this.setState({
      configureNew: false, points: [], anchorPoint: {}, setAnchor: false,
    });
  }

  @autobind
  getImageDimensions() {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    const naturalHeight = this.imageRef.current ? this.imageRef.current.naturalHeight : 0;
    return {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    };
  }

  @autobind
  getCentroid(currentPoints) {
    const { points } = this.state;
    const renderPoints = currentPoints || points;
    const midpoints = [];
    renderPoints.forEach((p, i) => {
      if (i !== renderPoints.length - 1) {
        const seg = [renderPoints[i], renderPoints[i + 1]];
        if (seg.length === 2) {
          const midpoint = { x: (seg[1].x + seg[0].x) / 2, y: (seg[1].y + seg[0].y) / 2 };
          midpoints.push(midpoint);
        }
      }
    });
    const x = _.mean(midpoints.map(xx => xx.x));
    const y = _.mean(midpoints.map(yy => yy.y));
    return { x, y };
  }

  @autobind
  preventDefault(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  fetchImage() {
    const { rand } = this.state;
    const { device } = this.props;
    const imgObject = new Image();
    imgObject.src = `https://admin.livereachmedia.com/api/v1/devices/${device.id}/camera/preview?r=${rand}`;
    imgObject.onload = this.checkImageLoaded(imgObject);
    imgObject.onerror = () => this.setState({ loading: false });
    this.setState({ loading: true, rand: Math.floor(Math.random() * 10000) });
  }

  @autobind
  checkImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ image: imgObject, loading: false });
      } else {
        setTimeout(() => {
          this.checkImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  undo() {
    const { points } = this.state;
    this.setState({ points: points.slice(0, -1) });
  }

  @autobind
  reset() {
    this.setState({ points: [], anchorPoint: {}, setAnchor: false });
  }

  @autobind
  addPoint(e) {
    if (!['polygon_image', 'polygon_svg', 'polgyon_anchor'].includes(e.target.id)) {
      return null;
    }
    const { points } = this.state;
    const { x, y } = e.nativeEvent;
    if (this.imageRef.current) {
      const c = this.imageRef.current.getBoundingClientRect();
      const nextId = points.length
        ? Math.max(...points.map(p => p.id)) + 1
        : 1;
      // points.push({ x, y, id: nextId });
      points.push({ x: (x - (c.x)), y: (y - (c.y)), id: nextId });
      this.setState({ points });
    }
    return null;
  }

  @autobind
  addAnchor(ev) {
    if (this.imageRef.current) {
      const { x, y } = ev.nativeEvent;
      const c = this.imageRef.current.getBoundingClientRect();
      this.setState({ anchorPoint: { x: (x - (c.x)), y: (y - (c.y)) } });
    }
  }

  @autobind
  renderInstructions() {
    const {
      setAnchor, points, anchorPoint, submitting,
    } = this.state;
    return (
      <Callout intent="primary">
        <H4>
          <div className="flex-space-between-container">
            <div>New Line</div>
            <Button intent="danger" onClick={this.configureRemove}>Exit</Button>
          </div>
        </H4>
        <div
          style={{
            margin: '20px 0px 10px 0px', opacity: setAnchor && 0.5, display: 'flex', flexDirection: 'column',
          }}
        >
          <div>
            Click continuous points on the image to create your counting line.
          </div>
          <div className="flex-space-between-container" style={{ margin: '10px 0px' }}>
            <div>
              <Button style={{ marginRight: 10 }} disabled={setAnchor} onClick={this.reset} intent="warning" icon="reset">Reset</Button>
              <Button disabled={setAnchor} onClick={this.undo} intent="success" icon="undo">Undo Point</Button>
            </div>
            <Button disabled={points.length < 2 || setAnchor} onClick={this.setAnchor} rightIcon="arrow-right" intent="primary">Next</Button>
          </div>
        </div>
        {setAnchor && (
          <Fragment>
            <div style={{ margin: '10px 0px', display: 'flex', flexDirection: 'column' }}>
              <div>
                Click a single point that represents the IN direction.
              </div>
              <div className="flex-space-between-container" style={{ margin: '10px 0px' }}>
                <Button style={{ marginRight: 10 }} icon="arrow-left" onClick={this.hideAnchor} intent="warning">Back</Button>
                <Button loading={submitting} onClick={this.handleSave} disabled={_.isEmpty(anchorPoint)} icon="tick" intent="primary">Done</Button>
              </div>
            </div>
          </Fragment>
        )}
      </Callout>
    );
  }

  @autobind
  drawAnchor(currentAnchor, currentPoints) {
    const { anchorPoint, points } = this.state;
    const renderAnchor = currentAnchor || anchorPoint;
    const renderPoints = currentPoints || points;
    const { clientHeight, clientWidth } = this.getImageDimensions();
    const { x, y } = this.getCentroid(renderPoints);
    return (
      <svg
        width={clientWidth}
        height={clientHeight}
        style={{
          position: 'absolute', top: 0, left: 0,
        }}
        id="polgyon_anchor"
      >
        <marker
          id={currentAnchor ? 'currentAnchor2' : 'anchor2'}
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
          fill={currentAnchor ? '#FF0000' : '#fff'}
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
        <line
          x1={x}
          y1={y}
          x2={renderAnchor.x}
          y2={renderAnchor.y}
          stroke={currentAnchor ? '#FF0000' : '#fff'}
          strokeWidth="3px"
          markerEnd={currentAnchor ? 'url(#currentAnchor2)' : 'url(#anchor2)'}
        />
      </svg>
    );
  }

  @autobind
  drawEdges(currentPoints) {
    const { clientHeight, clientWidth } = this.getImageDimensions();
    const { points } = this.state;
    const renderPoints = currentPoints || points;
    if (renderPoints.length > 1) {
      return renderPoints.map((p, i) => !(i === renderPoints.length - 1) && (
        <svg
          draggable={false}
          key={p.id}
          width={`${clientWidth}`}
          height={`${clientHeight}`}
          style={{
            position: 'absolute', top: 0, left: 0,
          }}
          id="polygon_svg"
        >
          <line
            x1={p.x - 2}
            y1={p.y - 2}
            x2={`${renderPoints[i + 1].x - 2}`}
            y2={`${renderPoints[i + 1].y - 2}`}
            stroke={currentPoints ? '#FF0000' : '#ffff00'}
            strokeWidth="4px"
          />
        </svg>
      ));
    }
    return null;
  }

  @autobind
  drawPoints(currentPoints) {
    const { points, setAnchor, configureNew } = this.state;
    const renderPoints = currentPoints || points;
    const draggable = (() => {
      if (currentPoints || setAnchor || !configureNew) {
        return false;
      }
      return true;
    })();

    return renderPoints.map(({ x, y, id }) => (
      <div
        key={id}
        id={id}
        role="presentation"
        style={{
          position: 'absolute',
          zIndex: 20,
          left: x - 4,
          top: y - 5,
          height: 6,
          width: 6,
          backgroundColor: currentPoints ? '#FF0000' : '#ffff00',
          borderRadius: '50%',
          textAlign: 'center',
          fontSize: 12,
          cursor: 'pointer',
        }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        draggable={draggable}
        onDragStart={e => this.handleDragStart(e, id)}
        onKeyDown={!setAnchor && !currentPoints ? this.handleKeyPress : null}
      />
    ));
  }

  @autobind
  viewCurrent() {
    const { vision } = this.props;
    const { counting_line, counting_anchor } = (vision || {}).data;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    const points = counting_line.map((c, i) => ({
      x: (c[0] / naturalWidth) * clientWidth,
      y: (c[1] / naturalHeight) * clientHeight,
      id: i + 1,
    }));
    const anchorPoint = {
      x: (counting_anchor[0] / naturalWidth) * clientWidth,
      y: (counting_anchor[1] / naturalHeight) * clientHeight,
    };
    return (
      <Fragment>
        {this.drawEdges(points)}
        {this.drawPoints(points)}
        {this.drawAnchor(anchorPoint, points)}
      </Fragment>
    );
  }

  @autobind
  renderConfig() {
    const { device, vision } = this.props;
    const {
      image, loading, setAnchor, configureNew, anchorPoint, points, viewCurrent,
    } = this.state;
    const v = (vision || {}).data;
    const dummyImage = 'https://dummyimage.com/300x200/000/fff&text=^+generate+image+above';
    const doRender = v.device_id === device.id;
    const onClick = (() => {
      if (configureNew) {
        if (setAnchor) return this.addAnchor;
        if (!setAnchor && configureNew) return this.addPoint;
        return null;
      }
      return null;
    })();
    const drawAnchor = !!anchorPoint.x && !!anchorPoint.y && setAnchor;
    return doRender && (
      <div style={{ marginBottom: 40 }}>
        <div style={{ marginBottom: 20 }} id="render-camera-img-polygon">
          <div style={{ fontSize: 16 }}>
            <Button onClick={this.fetchImage} loading={loading} icon="media">
              Generate Image
            </Button>
          </div>
        </div>
        <div className="flex-space-between-container">
          <div
            id="render-camera-img-polygon2"
            onDragOver={this.preventDefault}
            onDragEnter={this.preventDefault}
            onDrop={!setAnchor ? this.handleDrop : undefined}
            onClick={onClick}
            role="presentation"
          >
            <img
              src={image && image.complete ? image.src : dummyImage}
              alt="no feed"
              className="camera-vision-image"
              ref={this.imageRef}
              draggable={false}
              role="presentation"
              id="polygon_image"
            />
            {this.drawEdges()}
            {!!points.length && this.drawPoints()}
            {drawAnchor && this.drawAnchor()}
            {viewCurrent && this.viewCurrent()}
          </div>
          <div style={{ width: '50%', paddingLeft: 10 }}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              {!!v.counting_line && !!v.counting_anchor && (
                <Checkbox disabled={!image || !image.complete} label="View Current Direction" checked={viewCurrent} onChange={this.handleCurrent} />
              )}
              &nbsp;&nbsp;&nbsp;
              <Button disabled={!image || !image.complete} onClick={this.configureNew} icon="plus" intent="primary">
                Configure New Line
              </Button>
            </div>
            {configureNew && this.renderInstructions()}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderNoConfig() {
    return (
      <div style={{ fontSize: 16 }}>
        Polygon Line Configuration Unavailable
      </div>
    );
  }

  render() {
    const { device } = this.props;
    const { via, in_maintenance } = device || {};
    if (!!via && in_maintenance) {
      return this.renderConfig();
    }
    return this.renderNoConfig();
  }
}

PolygonLine.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  vision: PropTypes.object,
};

export default PolygonLine;
