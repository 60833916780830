import axios from 'axios';
import { success } from 'redux-saga-requests';
import {
  LOGIN, LOGOUT, GET_USER_PROFILE, TWO_FACTOR_LOGIN, TWO_FACTOR_RESEND,
} from '../constants';

const parseJSON = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
};

const userToken = parseJSON(window.localStorage.token)
  || parseJSON(window.sessionStorage.token)
  || null;

const userIntialState = {
  token: userToken,
  profile: null,
  organization: null,
};

if (userToken && userToken.access_token) {
  axios.defaults.headers.common.Authorization = `Bearer ${
    userToken.access_token
  }`;
}

export default function (state = userIntialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case success(LOGIN):
    case success(TWO_FACTOR_LOGIN):
    case success(TWO_FACTOR_RESEND): {
      const token = action.payload.data;
      token.expires = token.expires_in * 1000 + new Date().getTime();
      try {
        if (token.state && token.state.r) {
          window.localStorage.setItem('token', JSON.stringify(token));
        } else {
          window.sessionStorage.setItem('token', JSON.stringify(token));
        }
      } catch (error) {
        //
      }
      axios.defaults.headers.common.Authorization = `Bearer ${
        token.access_token
      }`;

      return {
        ...state,
        token,
      };
    }
    case success(GET_USER_PROFILE):
      return {
        ...state,
        profile: action.payload.data.content,
      };
    case LOGOUT: {
      delete axios.defaults.headers.common.Authorization;
      try {
        window.localStorage.removeItem('token');
        window.sessionStorage.removeItem('token');
      } catch (error) {
        //
      }
      return {
        ...state,
        token: null,
      };
    }
    default:
      return state;
  }
}
