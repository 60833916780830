import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormGroup,
} from '@blueprintjs/core';

import { DateInput } from '@blueprintjs/datetime';


class EditTrack extends Component {
  constructor(props) {
    super(props);
    const { track_id, tag } = props;
    this.state = {
      track_id,
      tag,
      new_tag_date: null,
    };
  }

  //   @autobind
  //   handleSave() {
  //     const { ins, outs } = this.state;
  //     const { selectedRow, getData, dispatch } = this.props;
  //     const { clip_name } = selectedRow || {};
  //     const serial = clip_name.split('_').pop().split('.')[0];
  //     const isDefined = ins !== undefined && outs !== undefined;
  //     if (!isDefined) {
  //       return AppToaster.show({
  //         icon: 'cross',
  //         message: <span>Ins and Outs Required</span>,
  //         intent: 'danger',
  //       });
  //     }
  //     const data = {
  //       ins: parseInt(ins, 10),
  //       outs: parseInt(outs, 10),
  //       line_id: selectedRow.line_id,
  //     };
  //     this.setState({ loading: true });
  //     return dispatch(updateGroundTruth(selectedRow.device.name, serial, data))
  //       .then(() => (getData ? this.delayGet(3000).then(() => getData()) : true))
  //       .then(() => ContextMenu.hide())
  //       .finally(() => this.setState({ loading: false }));
  //   }

  handleDateChange(date) {
    this.setState({ new_tag_date: date });
  }

  render() {
    const { new_tag_date, tag, track_id } = this.state;
    const { handleDeleteTag, handleUpdateTag } = this.props;
    return (
      <div className="audit-context-menu">
        <div>
          <FormGroup label="Update Track Time">
            <DateInput
              formatDate={date => date.toLocaleString()}
              onChange={date => this.handleDateChange(date)}
              parseDate={str => new Date(str)}
              timePickerProps={{ precision: 'minutes' }}
              placeholder="MM/DD/YYYY"
              value={new_tag_date}
            />
          </FormGroup>
        </div>
        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
          <div className="has-text-left">
            <Button onClick={() => handleDeleteTag(track_id, tag)} intent="danger" type="Delete" icon="cross">
            Delete Tag
            </Button>
          </div>
          <div className="has-text-right">
            <Button onClick={() => handleUpdateTag(track_id, tag, new_tag_date)} intent="primary" type="Close" icon="tick">
            Update Tag
            </Button>
          </div>
        </div>

      </div>
    );
  }
}

EditTrack.propTypes = {
  track_id: PropTypes.string,
  tag: PropTypes.string || null,
  handleUpdateTag: PropTypes.func,
  handleDeleteTag: PropTypes.func,
  // handleDeleteTrack: PropTypes.func,

};


export default EditTrack;
