import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import {
  H1, Tag, Spinner, Icon, H3, H5, Tooltip, Menu, MenuItem, FormGroup, InputGroup,
  Button, Popover,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  getAudits, getAudit, getAuditSummary, getAuditOverview, patchAudit, patchAuditTags,
} from 'actions/audit';
import { Link } from 'react-router-dom';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import _ from 'lodash';
import numbro from 'numbro';
import { DateTime } from 'luxon';
import axios from 'axios';
import moment from 'moment';

import { AppToaster } from 'components/Toaster';
import Line from './LineChart';
import AuditTruth from './truth';


const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

const fmtr = x => numbro(x).format('0,0');

const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

const INDEX_MAP = {
  0: 'clip_name',
  1: 'assigned',
  2: 'line_id',
  3: 'model_ins',
  4: 'model_outs',
  5: 'true_ins',
  6: 'true_outs',
  7: 'in_residual',
  8: 'out_residual',
  9: 'mae',
  10: 'movement_error',
  11: 'model_occupancy',
  12: 'true_occupancy',
};

class AuditSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: '',
      operator: '',
      condition: '',
      groundtruth: '',
      assigned: '',
      assign: false,
      clips: [],
      assignedPerson: '',
      loading: false,
      unassignLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { audit } = this.props;
    const { audit: oldAudit } = prevProps;
    if (audit.data.length && !oldAudit.data.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data: audit.data });
    }
  }

  @autobind
  getData() {
    const { dispatch, match, audits } = this.props;
    const { id } = match.params;
    dispatch(getAudit(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content });
      }
    });
    dispatch(getAuditSummary(id));
    dispatch(getAuditOverview(id));
    if (!(audits.data || []).length) {
      dispatch(getAudits(undefined, undefined, id, undefined));
    }
  }

  @autobind
  refreshTable() {
    const {
      groundtruth, assigned: stateAssigned,
      filter, operator, condition, data,
    } = this.state;
    const { audit } = this.props;
    let auditData = [...audit.data];
    const filteredData = (() => {
      if (groundtruth === '1') {
        auditData = auditData.filter(this.hasGroundTruth);
      }
      if (groundtruth === '-1') {
        auditData = auditData.filter(this.noGroundtruth);
      }
      if (stateAssigned === '1') {
        auditData = auditData.filter(this.isAssigned);
      }
      if (stateAssigned === '-1') {
        auditData = auditData.filter(this.isNotAssigned);
      }
      const intCondition = parseInt(condition, 10);
      if (intCondition != null && filter) {
        auditData = data.filter((x) => {
          const column = x[filter];
          switch (operator) {
            case '>':
              return column > intCondition;
            case '<':
              return column < intCondition;
            case '=':
              return column === intCondition;
            case '!=':
              return column !== intCondition;
            default:
              break;
          }
          return x;
        });
      }
      return auditData;
    })();
    return this.setState({ data: filteredData });
  }

  @autobind
  hasGroundTruth({ true_ins, true_outs }) {
    return Number.isInteger(true_ins) && Number.isInteger(true_outs);
  }

  @autobind
  noGroundtruth({ true_ins, true_outs }) {
    return !Number.isInteger(true_ins) && !Number.isInteger(true_outs);
  }

  @autobind
  isAssigned({ assigned }) {
    return !!assigned;
  }

  @autobind
  isNotAssigned({ assigned }) {
    return !assigned;
  }

  @autobind
  toggleAssign() {
    const { assign } = this.state;
    this.setState({ assign: !assign });
  }

  @autobind
  downloadSummary() {
    const { token, match } = this.props;
    window.open(`${axios.defaults.baseURL}/v1/audit/tasks/${match.params.id}/summary_report.csv?access_token=${token}`);
  }

  @autobind
  downloadReport() {
    const { token, match } = this.props;
    window.open(`${axios.defaults.baseURL}/v1/audit/tasks/${match.params.id}/report.csv?access_token=${token}`);
  }

  @autobind
  handleFilter(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({ filter: value, operator: '', condition: '' }, this.refreshTable);
    }
    this.setState({ filter: value });
  }

  @autobind
  handleGroundTruth(e) {
    this.setState({ groundtruth: e.target.value }, this.refreshTable);
  }

  @autobind
  handleAssigned(e) {
    this.setState({ assigned: e.target.value }, this.refreshTable);
  }

  @autobind
  handleOperator(e) {
    this.setState({ operator: e.target.value });
  }

  @autobind
  handleCondition(e) {
    this.setState({ condition: e.target.value });
  }

  @autobind
  handleAssign(e) {
    this.setState({ assignedPerson: e.target.value });
  }

  @autobind
  clearClips() {
    this.setState({ clips: [] });
  }

  @autobind
  async isValidAssignment() {
    const { clips } = this.state;
    const { dispatch, match } = this.props;
    let takenClips = false;
    const isValid = await dispatch(getAudit(match.params.id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const currentAuditClips = [...payload.data.content];
        clips.forEach((c) => {
          // eslint-disable-next-line max-len
          const current = currentAuditClips.find(x => x.clip_name === c.clip_name && x.line_id === c.line_id) || {};
          if (current.assigned) {
            takenClips = true;
          }
        });
      }
      if (takenClips) return false;
      return true;
    });
    return isValid;
  }

  @autobind
  async handleClipAssignment() {
    const { clips, assignedPerson } = this.state;
    const { dispatch, match } = this.props;
    if (!assignedPerson) {
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>Must select a person</span>,
      });
    }
    this.setState({ loading: true });
    const isValid = await this.isValidAssignment();
    if (!isValid) {
      this.setState({ loading: false });
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>One or more clips you have selected are taken</span>,
      });
    }
    try {
      await Promise.all(clips.map(x => dispatch(patchAuditTags(
        match.params.id,
        x.clip_name,
        { assigned: assignedPerson, line_id: x.line_id },
      ))));
      return this.getData();
    } catch (e) {
      //
    } finally {
      this.setState({ loading: false, assignedPerson: '', clips: [] });
    }
    return null;
  }

  @autobind
  async handleUnassign() {
    const { dispatch, match } = this.props;
    const { clips } = this.state;
    this.setState({ unassignLoading: true });
    try {
      await Promise.all(clips.map(x => dispatch(patchAuditTags(
        match.params.id,
        x.clip_name,
        { assigned: '', line_id: x.line_id },
      ))));
      return this.getData();
    } catch (e) {
      //
    } finally {
      this.setState({ unassignLoading: false, clips: [] });
    }
    return null;
  }

  @autobind
  selectClips(row) {
    if (row && row[0].cols) {
      if (row[0].cols[0] > 0 || row[0].cols[1] > 0) {
        return null;
      }
    }
    const { data } = this.state;
    if (row && row[0] && row[0].rows) {
      const start = row[0].rows[0];
      const end = row[0].rows[1];
      const selectedClips = [...data].slice(start, end + 1);
      return this.setState({ clips: selectedClips });
    }
    return null;
  }

  @autobind
  applyFilter() {
    const {
      data, filter, operator, condition,
    } = this.state;
    const intCondition = parseInt(condition, 10);
    if (intCondition != null) {
      const filteredData = data.filter((x) => {
        const column = x[filter];
        switch (operator) {
          case '>':
            return column > intCondition;
          case '<':
            return column < intCondition;
          case '=':
            return column === intCondition;
          case '!=':
            return column !== intCondition;
          default:
            break;
        }
        return x;
      });
      this.setState({ data: filteredData });
    }
  }

  @autobind
  sortAsc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 1].includes(column)) {
        return a[INDEX_MAP[column]].localeCompare(b[INDEX_MAP[column]]);
      }
      return a[INDEX_MAP[column]] - b[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  sortDesc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 1].includes(column)) {
        return b[INDEX_MAP[column]].localeCompare(a[INDEX_MAP[column]]);
      }
      return b[INDEX_MAP[column]] - a[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  pauseAudit(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchAudit(id, { status: 4 }))
      .then(() => dispatch(getAudits(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`${name} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeAudit(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchAudit(id, { status: 0 }))
      .then(() => dispatch(getAudits(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'play',
        message: <span>{`${name} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  cellRenderer(index, title) {
    const { data } = this.state;
    const row = data[index];
    if (row) {
      return <Cell tooltip={row[title]}>{row[title]}</Cell>;
    }
    return <Cell />;
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderContextMenu(a) {
    const { data } = this.state;
    const { dispatch } = this.props;
    if (a.target && a.target.rows) {
      const selectedRow = data.find((x, i) => i === a.target.rows[0]);
      if (selectedRow) {
        return <AuditTruth selectedRow={selectedRow} getData={this.getData} dispatch={dispatch} />;
      }
    }
    return undefined;
  }

  @autobind
  renderSummaryReport() {
    const { auditSummary } = this.props;
    const {
      model_ins_total, model_outs_total, true_ins_total, true_outs_total, in_residual_total,
      out_residual_total, mae_total, net_movement_error,
    } = auditSummary.data || {};
    const modelOccupancy = model_ins_total - model_outs_total;
    const trueOccupancy = true_ins_total - true_outs_total;
    return (
      <div style={{ borderTop: '1px solid #f5f8fa' }}>
        <div className="flex-space-between-container">
          <H3 style={{ marginTop: 10 }}>Audit Summary</H3>
          <div style={{ marginTop: 10 }}>
            <Tooltip content="Export Summary CSV">
              <Icon iconSize={20} icon="import" style={{ cursor: 'pointer' }} onClick={this.downloadSummary} />
            </Tooltip>
          </div>
        </div>
        <table className={classNames('bp3-html-table bp3-html-table-striped bp3-html-table-bordered', { 'bp3-skeleton': false })}>
          <thead>
            <tr>
              <th>Model Ins</th>
              <th>Model Outs</th>
              <th>True Ins</th>
              <th>True Outs</th>
              <th>In Residual</th>
              <th>Out Residual</th>
              <th>MAE Residual</th>
              <th>Net Movement Error</th>
              <th>Model Ending Occ.</th>
              <th>True Ending Occ.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{model_ins_total}</td>
              <td>{model_outs_total}</td>
              <td>{true_ins_total}</td>
              <td>{true_outs_total}</td>
              <td>{in_residual_total}</td>
              <td>{out_residual_total}</td>
              <td>{mae_total}</td>
              <td>{net_movement_error}</td>
              <td>{Number.isNaN(modelOccupancy) ? '' : modelOccupancy}</td>
              <td>{Number.isNaN(trueOccupancy) ? '' : trueOccupancy}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  @autobind
  renderNoSummaryReport() {
    return (
      <div style={{ borderTop: '1px solid #f5f8fa' }}>
        <H3 style={{ marginTop: 10 }}>Audit Summary</H3>
        <H5>Unavailable -- True Ins/Out Not Reported</H5>
      </div>
    );
  }

  @autobind
  renderFilters() {
    const {
      filter, operator, condition, clips, assignedPerson,
      loading, unassignLoading, groundtruth, assigned,
    } = this.state;
    const { users } = this.props;
    return (
      <div className="flex-space-between-container" style={{ alignItems: 'center' }}>
        <div className="audit-filter-container">
          <FormGroup
            label="Ground Truth"
            className="inline-flex m-r-md"
          >
            <div className="bp3-select">
              <select id="user-status" value={groundtruth || ''} onChange={this.handleGroundTruth}>
                <option value="">--</option>
                <option value="1">Completed</option>
                <option value="-1">Incomplete</option>
              </select>
            </div>
          </FormGroup>
          <FormGroup
            label="Assigned"
            className="inline-flex m-r-md"
          >
            <div className="bp3-select">
              <select id="user-status" value={assigned || ''} onChange={this.handleAssigned}>
                <option value="">--</option>
                <option value="1">Assigned</option>
                <option value="-1">Unnassigned</option>
              </select>
            </div>
          </FormGroup>
          <FormGroup label="Metric Filter" className="filter-form-group">
            <div className="bp3-select">
              <select name="filter" value={filter} onChange={this.handleFilter}>
                <option value="">--</option>
                <option value="model_ins">Model Ins</option>
                <option value="model_outs">Model Outs</option>
                <option value="true_ins">True Ins</option>
                <option value="true_outs">True Outs</option>
                <option value="in_residual">In Residual</option>
                <option value="out_residual">Out Residual</option>
                <option value="mae">MAE</option>
                <option value="model_occupancy">Model Occ.</option>
                <option value="true_occupancy">True Occ.</option>
                <option value="movement_error">Movement Error</option>
              </select>
            </div>
          </FormGroup>
          {!!filter && (
            <FormGroup label="Operator" className="filter-form-group">
              <div className="bp3-select">
                <select name="operator" className="bp3-select" onChange={this.handleOperator}>
                  <option value="">--</option>
                  <option value="<">{'<'}</option>
                  <option value=">">{'>'}</option>
                  <option value="=">=</option>
                  <option value="!=">!=</option>
                </select>
              </div>
            </FormGroup>
          )}
          {!!operator && !!filter && (
            <div className="submit-group">
              <FormGroup label="Condition" className="filter-form-group">
                <InputGroup type="number" name="condition" value={condition} onChange={this.handleCondition} />
              </FormGroup>
              <Button onClick={this.refreshTable} style={{ margin: '5px 0px 0px 10px' }} icon="tick" intent="primary" />
            </div>
          )}
        </div>
        <div>
          <Popover
            content={clips.length ? (
              <div style={{ padding: '10px', display: 'flex' }}>
                <div className="bp3-select">
                  <select value={assignedPerson} name="assign" className="bp3-select" onChange={this.handleAssign}>
                    <option value="">Select Person</option>
                    {([...users.data] || [])
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(x => <option value={x.name} key={x.id}>{x.name}</option>)}
                  </select>
                </div>
                <Button loading={loading} onClick={this.handleClipAssignment} style={{ marginLeft: 5 }} icon="tick" intent="primary" />
              </div>
            ) : null}
            target={(
              <Button disabled={!clips.length} icon="person" intent="primary">
                {clips.length ? `Assign Clips (${clips.length})` : 'Assign Clips'}
              </Button>
            )}
          />
          <Button
            icon="blocked-person"
            onClick={this.handleUnassign}
            style={{ marginLeft: 10 }}
            intent="danger"
            disabled={!clips.length}
            loading={unassignLoading}
          >
            {clips.length ? `Unassign Clips (${clips.length})` : 'Unassign Clips'}
          </Button>
          {!!clips.length && (
            <Tooltip content="Clear selected clips">
              <Button outlined minimal onClick={this.clearClips} style={{ marginLeft: 10 }} icon="cross" intent="danger" />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  @autobind
  renderAuditTable() {
    const { data } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <div className="flex-space-between-container">
          <H3>Audit Report</H3>
          <div>
            <Tooltip content="Export Report CSV">
              <Icon iconSize={20} icon="import" style={{ cursor: 'pointer' }} onClick={this.downloadReport} />
            </Tooltip>
          </div>
        </div>
        {this.renderFilters()}
        <div className="columns" style={{ marginTop: 20, height: 400 }}>
          <Table
            columnWidths={[350, 105, 80, 105, 110, 105, 110, 110, 110, 80, 80, 105, 105]}
            numFrozenColumns={1}
            numRows={data.length}
            class="bp3-html-table bp3-table-resize-horizontal"
            getCellClipboardData={(row, col) => data[row][INDEX_MAP[col]]}
            onSelection={this.selectClips}
            bodyContextMenuRenderer={a => this.renderContextMenu(a)}
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Clip Name" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'clip_name')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Assigned" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'assigned')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Line ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'line_id')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`M. Ins (${_.sumBy(data, a => a.model_ins)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'model_ins')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`M. Outs (${_.sumBy(data, a => a.model_outs)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'model_outs')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`T. Ins (${_.sumBy(data, a => a.true_ins)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'true_ins')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`T. Outs (${_.sumBy(data, a => a.true_outs)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'true_outs')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`In Res. (${_.sumBy(data, a => a.in_residual)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'in_residual')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name={`Out Res. (${_.sumBy(data, a => a.out_residual)})`} menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'out_residual')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="MAE" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'mae')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="ME" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'movement_error')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="∑ M. Occ" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'model_occupancy')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="∑ T. Occ" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'true_occupancy')}
            />
          </Table>
        </div>
      </div>
    );
  }

  @autobind
  renderChart() {
    const { audit } = this.props;
    const modelData = [];
    const trueData = [];
    [...audit.data].sort((a, b) => a.clip_name.localeCompare(b.clip_name))
      .forEach((x) => {
        modelData.push(x.model_occupancy);
        trueData.push(x.true_occupancy);
      });
    const datasets = [
      {
        label: 'Model',
        data: modelData,
        borderColor: '#CFF3D2',
        borderWidth: 1,
        fill: false,
        pointBackgroundColor: '#CFF3D2',
        pointRadius: 0.5,
        lineTension: 0,
      },
      {
        label: 'True',
        data: trueData,
        borderColor: '#DB8A3A',
        borderWidth: 2,
        fill: false,
        pointBackgroundColor: '#DB8A3A',
        pointRadius: 0.5,
        lineTension: 0,
      },
    ];
    const chartData = {
      datasets,
      labels: Array(audit.data.length).fill().map((x, i) => i + 1),
    };
    return (
      <div style={{ marginTop: 30 }}>
        <H3 style={{ marginTop: 10 }}>Model vs True Occupancy</H3>
        <div style={{ height: 400, paddingTop: 10 }}>
          <Line
            yFmtr={fmtr}
            data={() => chartData}
            legend
            position="bottom"
            stepSize={audit.data.length % 5}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderClipInput(clip, i) {
    return (
      <tr key={i}>
        <td>
          <Link to={`/devices/${clip.camera_serial}`}>
            {clip.camera_serial}
          </Link>
        </td>
        <td>{clip.line_id}</td>
        <td>{DateTime.fromISO(clip.start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td>{DateTime.fromISO(clip.end_time).toLocaleString(DateTime.DATETIME_MED)}</td>
      </tr>
    );
  }

  render() {
    const {
      audit, auditSummary, auditOverview, audits, match, dispatch,
    } = this.props;
    const { clips } = this.state;
    if (
      (audit.pending && !audit.data)
      || (auditSummary.pending && !auditSummary.data)
      || (auditOverview.pending && !auditOverview.data)
    ) {
      return <Spinner size={100} />;
    }
    const {
      s3_output_path, min_version, model_id, vision_params, include, exclude, clips_input,
    } = auditOverview.data || {};
    const { true_ins_total, true_outs_total } = auditSummary.data || {};
    const selectedAudit = (audits || {})
      .data.find(x => x.audit_id === parseInt(match.params.id, 10));
    const {
      audit_id, audit_name, status, EndTime,
    } = selectedAudit || {};
    const showSummary = true_ins_total !== null && true_outs_total !== null;
    const auditStatus = status === 0 && EndTime !== null ? 'Failed' : STATUS_MAP[status];
    const refireValues = auditOverview.data
      ? ([auditOverview.data] || []).map(({ clips_input: ci, ...rest }) => ({
        ...rest,
        clips_input: ci ? ci.map(x => ({
          camera_serial: x.camera_serial,
          start_time: moment(x.start_time).toDate(),
          end_time: moment(x.end_time).toDate(),
          line_id: x.line_id,
        })) : [],
        include: clips.length ? clips.map(x => ({ value: x.clip_name })) : null,
      })) : {};
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="audit-header-container">
            <H1>
              <Icon
                className="audit-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                onClick={() => dispatch(push('/audits'))}
              />
              {audit_name}
            </H1>
            <div>
              {auditStatus !== 'Failed' && status !== 1 && (
                <Tooltip content={status === 4 ? 'Resume Audit' : 'Pause Audit'}>
                  <div className="pause-audit-container">
                    <Icon
                      iconSize={30}
                      className="pause-audit"
                      icon={status === 4 ? 'play' : 'pause'}
                      onClick={() => (status === 4
                        ? this.resumeAudit(audit_name)
                        : this.pauseAudit(audit_name))}
                    />
                  </div>
                </Tooltip>
              )}
              <Link
                to={{
                  pathname: '/audits/create',
                  state: { initialValues: refireValues[0] },
                }}
              >
                <Button style={{ margin: '0px 0px 10px 10px' }} icon="repeat" intent="primary">Re-Fire</Button>
              </Link>
            </div>
          </div>
          <div className="bp3-text-large bp3-text-muted audit-header">
            {`ID: ${audit_id}`}
            <Dot />
            {`Model: ${model_id}`}
            <Dot />
            {`Version: ${min_version}`}
            <Dot />
            {`Status: ${auditStatus}`}
            <Dot />
            <Link to={`/audits/groundtruth?a=${match.params.id}`}>
              GroundTruth Clips
            </Link>
          </div>
          {!!clips_input && (
            <div style={{ marginTop: 10 }}>
              <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
                <thead>
                  <tr>
                    <th>Camera</th>
                    <th>Line ID</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {clips_input.map(this.renderClipInput)}
                </tbody>
              </table>
            </div>
          )}
          <H1>
            <div className="bp3-text-small">
              <div className="audit-summary">
                <div className="audit-summary-row">
                  <div className="audit-summary-row-item">
                    <div>S3 Output Path:</div>
                    <Tag multiline>{s3_output_path}</Tag>
                  </div>
                  <div className="audit-summary-row">
                    <div className="audit-summary-row-item">
                      <div>Vision Params:</div>
                      <Tag multiline>{vision_params || 'N/A'}</Tag>
                    </div>
                  </div>
                </div>
              </div>
              <div className="audit-summary">
                <div className="audit-summary-row">
                  <div className="audit-summary-row-item">
                    <div>Include:</div>
                    <Tag multiline>{include || 'N/A'}</Tag>
                  </div>
                </div>
              </div>
              <div className="audit-summary">
                <div className="audit-summary-row">
                  <div className="audit-summary-row-item">
                    <div>Exclude:</div>
                    <Tag multiline>{exclude || 'N/A'}</Tag>
                  </div>
                </div>
              </div>
            </div>
          </H1>
        </div>
        <div>
          {showSummary && this.renderSummaryReport()}
          {!showSummary && this.renderNoSummaryReport()}
        </div>
        <div>
          {this.renderChart()}
        </div>
        <div>
          {this.renderAuditTable()}
        </div>
      </div>
    );
  }
}

AuditSummary.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  auditOverview: PropTypes.object,
  auditSummary: PropTypes.object,
  audit: PropTypes.object,
  audits: PropTypes.object,
  token: PropTypes.string,
  users: PropTypes.object,
};

export default connect(state => ({
  auditOverview: state.auditOverview,
  audit: state.audit,
  auditSummary: state.auditSummary,
  token: state.currentUser.token.access_token,
  audits: state.audits,
  users: state.users,
}))(AuditSummary);
