import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, Elevation, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import moment from 'moment-timezone';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { RangePicker } from 'antd/lib/date-picker';

import ChartCard from '../../chartCard';
import WaitTimeChart from './waitTimeChart';
import DwellTimeChart from './dwellTimeChart';
import AnalyzeDevices from '../devices';
import OccupancyReport from '../occupancyReport';

class Visualize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(3, 'd').startOf('day'),
      endDate: moment().endOf('day'),
    };
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: moment(dates[0]).clone(), endDate: moment(dates[1]).clone(),
    });
  }

  @autobind
  backToZones() {
    const { match, dispatch } = this.props;
    if (match.params.card_id) {
      return dispatch(push(`/dashboard/${match.params.id}/card/${match.params.card_id}`));
    }
    return dispatch(push(`/dashboard/${match.params.id}/explore`));
  }

  render() {
    const { selectedZones, zones } = this.props;
    const { startDate, endDate } = this.state;
    const dateProps = { startDate, endDate };
    const occupancyZones = [];
    const waitZones = [];
    const dwellZones = [];
    const checkedZones = Array.isArray(selectedZones) ? selectedZones : [selectedZones];
    let timezone;
    (checkedZones || []).forEach((x) => {
      const z = (zones.data || []).find(y => y.id === parseInt(x, 10)) || {};
      if (timezone === null || timezone === undefined) {
        // eslint-disable-next-line prefer-destructuring
        timezone = z.timezone;
      }
      if (z.tab_permissions && z.tab_permissions[0]) {
        const { analytics_time, analytics_vision } = z.tab_permissions[0];
        if (analytics_time) {
          if (z.is_one_way_queuing) {
            waitZones.push(z);
          } else {
            dwellZones.push(z);
          }
        }
        if (analytics_vision && z.occupancy_enabled) occupancyZones.push(z);
      }
    });
    if (timezone === null || timezone === undefined) {
      // eslint-disable-next-line prefer-destructuring
      timezone = 'America/Los_Angeles';
    }
    const deviceZones = _.uniqBy([...occupancyZones, ...waitZones, ...dwellZones], x => x.id);
    const sDate = moment().tz(timezone).subtract(1, 'd').startOf('day');
    const eDate = moment().tz(timezone).endOf('day');
    const reportDateProps = { startDate: sDate, endDate: eDate };
    return (
      <div style={{ marginTop: 20 }}>
        <Card className="dash-date-card" elevation={Elevation.TWO}>
          <div className="flex-space-between-container" style={{ width: '100%', alignItems: 'center' }}>
            <div>
              <Button style={{ backgroundColor: '#212C35', fontWeight: 'bold' }} icon="circle-arrow-left" onClick={this.backToZones}>
                Change Zones
              </Button>
            </div>
            {(!!waitZones.length || !!dwellZones.length) && (
              <Fragment>
                <div>
                  <RangePicker
                    value={[moment(startDate), moment(endDate)]}
                    format="lll"
                    style={{ width: '500px' }}
                    showTime
                    onChange={this.handleDateChange}
                    allowClear={false}
                  />
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  {`Time Zone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}
                </div>
              </Fragment>
            )}
          </div>
        </Card>
        {!!waitZones.length && (
          <ChartCard title="Wait Time" hideWeek showMin initialDimension="day">
            <WaitTimeChart selectedZones={waitZones} {...dateProps} />
          </ChartCard>
        )}
        {!!dwellZones.length && (
          <ChartCard title="Dwell Time" hideWeek showMin initialDimension="day">
            <DwellTimeChart selectedZones={dwellZones} {...dateProps} />
          </ChartCard>
        )}
        {!!occupancyZones.length && (
          <OccupancyReport selectedZones={occupancyZones} {...reportDateProps} />
        )}
        {!!deviceZones.length && (
          <AnalyzeDevices selectedZones={deviceZones} />
        )}
      </div>
    );
  }
}

Visualize.propTypes = {
  selectedZones: PropTypes.any,
};

export default connect(state => ({
  zones: state.zones,
}))(Visualize);
