import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AlertsList from './AlertsList';
import AlertDetails from './AlertDetails';
import ActiveAlerts from './ActiveAlerts';

export default () => (
  <Switch>
    <Route path="/alerts/active" component={ActiveAlerts} />
    <Route path="/alerts/:id" component={AlertDetails} />
    <Route component={AlertsList} />
  </Switch>
);
