import {
  GET_IAP_RELEASES, GET_IAP_RELEASE, GET_IAP_SERVICES,
  CREATE_IAP_RELEASE, EDIT_IAP_RELEASE,
} from '../constants';

export const getReleases = () => ({
  type: GET_IAP_RELEASES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/iap/releases',
    },
  },
});

export const getRelease = releaseId => ({
  type: GET_IAP_RELEASE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/releases/${releaseId}`,
    },
  },
});

export const getServices = () => ({
  type: GET_IAP_SERVICES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/iap/services',
    },
  },
});

export const createRelease = data => ({
  type: CREATE_IAP_RELEASE,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/iap/releases',
    },
  },
});

export const editRelease = (releaseId, data) => ({
  type: EDIT_IAP_RELEASE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/iap/releases/${releaseId}`,
    },
  },
});
