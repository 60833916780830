import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Field,
  reduxForm,
  reset,
  FieldArray,
} from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, DateTimeInput } from 'components/inputs';
import _ from 'lodash';
import { postGroundTruth } from 'actions/site';
import { Button } from '@blueprintjs/core';
import moment from 'moment';
import { AppToaster } from 'components/Toaster';
import QueryGroundtruth from './querygroundtruth';

const validateNum = (value) => {
  if (value) {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(Number(value)) ? 'Must be a number' : undefined;
  }
  return undefined;
};

const required = value => (value ? undefined : 'Required');

const Line = () => (
  <hr
    style={{
      border: '1px solid',
      color: '#000',
      backgroundColor: '#000',
      width: '100%',
    }}
  />
);

class GroundTruth extends Component {
  renderGroundtruths = ({ fields }) => (
    <ul>
      <li>
        <Button icon="add" intent="success" onClick={() => fields.push()}>Add Groundtruth</Button>
        <div className="columns">
          <div className="column" />
          <div className="column">Date</div>
          <div className="column">Headcount</div>
          <div className="column">Dwell Time</div>
          <div className="column">Wait Time</div>
          <div className="column" />
        </div>
      </li>
      {fields.map((truth, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <div className="columns">
            <div className="column" style={{ marginTop: '10px', padding: '0.4em' }}>
              <div className="bp3-select">
                <Field
                  component="select"
                  name={`${truth}.scale`}
                  className="passerby-select"
                  validate={[required]}
                >
                  <option value="" defaultValue>Select Time Scale</option>
                  <option value="hour">Hourly</option>
                  <option value="minute">Minutely</option>
                </Field>
              </div>
            </div>
            <div className="column" style={{ marginTop: '5px', padding: '0.4em' }}>
              <Field
                component={DateTimeInput}
                name={`${truth}.date`}
                validate={[required]}
                props={{ secondsPrecision: true }}
              />
            </div>
            <div className="column" style={{ padding: '0.4em' }}>
              <Field
                component={TextInput}
                placeholder=""
                name={`${truth}.headcount`}
                type="text"
                validate={[validateNum]}
              />
            </div>
            <div className="column" style={{ padding: '0.4em' }}>
              <Field
                component={TextInput}
                placeholder=""
                name={`${truth}.dwell`}
                type="text"
                validate={[validateNum]}
              />
            </div>
            <div className="column" style={{ padding: '0.4em' }}>
              <Field
                component={TextInput}
                placeholder=""
                name={`${truth}.wait`}
                type="text"
                validate={[validateNum]}
              />
            </div>
            <div className="column" style={{ marginTop: '5px', padding: '0.4em' }}>
              <Button icon="cross" title="Remove Groundtruth" intent="danger" onClick={() => fields.remove(index)} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  @autobind
  handleSubmit(values) {
    const { dispatch, match } = this.props;
    const minute = { scale: 'minute', rows: [] };
    const hour = { scale: 'hour', rows: [] };
    values.groundtruths.forEach(x => (x.scale === 'hour'
      ? hour.rows.push([moment(x.date).minutes(0).seconds(0).milliseconds(0)
        .format(), x.headcount ? parseInt(x.headcount, 10) : null,
      x.dwell ? parseFloat(x.dwell) : null,
      x.wait ? parseFloat(x.wait) : null])
      : minute.rows.push([moment(x.date).milliseconds(0).format(),
        x.headcount ? parseInt(x.headcount, 10) : null,
        x.dwell ? parseFloat(x.dwell) : null,
        x.wait ? parseFloat(x.wait) : null])));
    if (minute.rows.length && hour.rows.length) {
      const data = _.values({ hour, minute });
      let counter = 0;
      // eslint-disable-next-line consistent-return
      data.forEach((x) => {
        dispatch(postGroundTruth(match.params.id, x));
        counter += 1;
        if (counter === data.length) {
          AppToaster.show({
            icon: 'tick',
            message: (
              <span>
                <strong>Hourly &amp; Minutely Groundtruth created</strong>
              </span>
            ),
          });
          return dispatch(reset('groundtruth'));
        }
      });
    } else if (minute.rows.length && !hour.rows.length) {
      const data = minute;
      return dispatch(postGroundTruth(match.params.id, data))
        .then(() => AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>Minutely Groundtruth created</strong>
            </span>
          ),
        }))
        .then(dispatch(reset('groundtruth')));
    } else if (!minute.rows.length && hour.rows.length) {
      const data = hour;
      return dispatch(postGroundTruth(match.params.id, data))
        .then(() => AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>Hourly Groundtruth created</strong>
            </span>
          ),
        }))
        .then(dispatch(reset('groundtruth')));
    }
    return '';
  }

  render() {
    const {
      submitting, handleSubmit, match,
    } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <FieldArray name="groundtruths" component={this.renderGroundtruths} />
          &nbsp;&nbsp;
          <div className="has-text-right">
            <Button intent="primary" type="submit" disabled={submitting} icon="tick">Submit</Button>
          </div>
        </form>
        <br />
        <Line />
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <div style={{ maxWidth: '70%', display: 'inline-block' }}>
            <QueryGroundtruth match={match} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

GroundTruth.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  match: PropTypes.object,
};

export default connect(state => ({
  groundtruth: state.groundtruth,
}))(reduxForm({
  form: 'groundtruth',
  enableReinitialize: true,
})(GroundTruth));
