import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  HTMLTable, Tag, Button, FormGroup, InputGroup,
} from '@blueprintjs/core';

const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);

class DeviceAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.status || [],
      deviceSort: 1,
      alertSort: 0,
      metricSort: 0,
      query: '',
      deviceNameSort: 1,
    };
  }

  @autobind
  handleDeviceSort() {
    const { deviceSort, rows } = this.state;

    const sort = deviceSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.object_id).localeCompare(b.object_id));
      this.setState({ rows: newRows, deviceSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.object_id).localeCompare(a.object_id));
      this.setState({ rows: newRows, deviceSort: sort });
    }
  }

  @autobind
  handleAlertSort() {
    const { alertSort, rows } = this.state;

    const sort = alertSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.state) - (b.state));
      this.setState({ rows: newRows, alertSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.state) - (a.state));
      this.setState({ rows: newRows, alertSort: sort });
    }
  }

  @autobind
  handleMetricSort() {
    const { metricSort, rows } = this.state;

    const sort = metricSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.value) - (b.value));
      this.setState({ rows: newRows, metricSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.value) - (a.value));
      this.setState({ rows: newRows, metricSort: sort });
    }
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleDeviceNameSort() {
    const { deviceNameSort, rows } = this.state;

    const sort = deviceNameSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.object_name).localeCompare(b.object_name));
      this.setState({ rows: newRows, deviceNameSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.object_name).localeCompare(a.object_name));
      this.setState({ rows: newRows, deviceNameSort: sort });
    }
  }

  @autobind
  rowRenderer(row) {
    const { dispatch } = this.props;
    const intent = row.state === 0 ? 'success' : 'danger';
    const alerting = row.state === 0 ? 'OK' : 'Alerting';
    return (
      <tr
        onClick={() => dispatch(push(`/devices/${row.object_id}`))}
      >
        <td style={{ color: '#46A4E0' }}>{row.object_id}</td>
        <td>{row.object_name}</td>
        <td><Tag minimal intent={intent} large>{alerting}</Tag></td>
        <td>{Math.round(row.value)}</td>
      </tr>
    );
  }

  render() {
    const { metric } = this.props;
    const { rows, query } = this.state;
    let filtered = rows;
    if (query !== '') {
      filtered = filtered.filter(x => x.object_id.toLowerCase().includes(query.toLowerCase()));
    }
    return (
      <div className="container">
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup
            value={query}
            onChange={this.handleQuery}
            leftIcon="search"
            large
            placeholder="Search states by device id"
          />
        </FormGroup>
        <HTMLTable striped interactive>
          <thead>
            <tr>
              <th>
                Device ID
                &nbsp;
                <Button icon="double-caret-vertical" minimal intent="none" onClick={this.handleDeviceSort} />
              </th>
              <th>
                Device Name
                &nbsp;
                <Button icon="double-caret-vertical" minimal intent="none" onClick={this.handleDeviceNameSort} />
              </th>
              <th>
                Alert Status
                &nbsp;
                <Button icon="double-caret-vertical" minimal intent="none" onClick={this.handleAlertSort} />
              </th>
              <th>
                {metric && capitalizeFirstLetter(metric)}
                &nbsp;
                <Button icon="double-caret-vertical" minimal intent="none" onClick={this.handleMetricSort} />
              </th>
            </tr>
          </thead>
          <tbody>
            {filtered.length && filtered.map(this.rowRenderer)}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}

DeviceAlert.propTypes = {
  dispatch: PropTypes.func,
  status: PropTypes.array,
  metric: PropTypes.string,
};

export default DeviceAlert;
