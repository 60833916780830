import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { getDevice, getAllDevices } from 'actions/device';
import { updateDateRange } from 'actions/daterange';

import Generic from './generic';
import Vision from './vision';
import IAP from './iap';
import Network from './network';
import Peplink from './peplink';

class Device extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getDevice(match.params.id));
    dispatch(getAllDevices());
  }

  componentDidUpdate(prevProps) {
    const { match, dispatch } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(getDevice(match.params.id));
      dispatch(getAllDevices());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      DateTime.utc().minus(3600000),
      DateTime.utc(),
    ));
  }

  render() {
    const {
      device, dispatch, location, deviceLocation, match, allDevices,
    } = this.props;
    if (!device.data || !allDevices.data) {
      return (<div className="container"><Spinner size={100} /></div>);
    }
    if (device.data.id !== match.params.id) {
      return (<div className="container"><Spinner size={100} /></div>);
    }
    const region = (deviceLocation.data || {}).region || 'EU';
    const Display = (() => {
      if (['livereachmedia.iap', 'Raspberry PI', 'Raspberry Pi'].includes(device.data.type)) {
        return IAP;
      }
      if (['cisco.meraki', 'aruba.iap'].includes(device.data.type)) {
        return Network;
      }
      if (device.data.type.includes('camera')) {
        return Vision;
      }
      if (device.data.type.includes('peplink')) {
        return Peplink;
      }
      return Generic;
    })();
    return (
      <div className="container">
        <Display
          device={device.data}
          dispatch={dispatch}
          location={location}
          region={region}
          match={match}
        />
      </div>
    );
  }
}

Device.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  deviceLocation: PropTypes.object,
  allDevices: PropTypes.object,
};

export default connect(state => ({
  device: state.device,
  deviceLocation: state.location,
  allDevices: state.allDevices,
}))(Device);
