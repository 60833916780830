import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { Field, reduxForm, reset } from 'redux-form';
import { Route, Switch, Link } from 'react-router-dom';
import {
  H1, Button, Navbar, Tabs, Tab,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getRole, saveRole, deleteRole } from 'actions/role';
import { getOrganization } from 'actions/organization';

import Permissions from './permissions';
import Resources from './resources';

class Role extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getRole(match.params.role_id));
  }

  componentDidUpdate(prevProps) {
    const { role, dispatch } = this.props;
    const prevOrgId = (prevProps.role.data || {}).organization_id;
    const orgId = (role.data || {}).organization_id;
    if (orgId !== prevOrgId) {
      dispatch(getOrganization(orgId));
    }
    const prevRoleId = (prevProps.role.data || {}).role_id;
    const roleId = (role.data || {}).role_id;
    if (roleId !== prevRoleId) {
      dispatch(getRole(roleId));
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    return dispatch(saveRole(match.params.role_id, {
      role_name: values.role_name,
    })).then(() => {
      dispatch(reset('role'));
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {values.role_name}
            </strong>
            : Role saved
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getRole(match.params.role_id));
    });
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch, location } = this.props;
    dispatch(push(tabId + (location.search || '')));
  }

  @autobind
  handleDelete() {
    const { dispatch, match } = this.props;
    return dispatch(deleteRole(match.params.role_id)).then(() => {
      dispatch(reset('role'));
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            Role deleted
          </span>
        ),
        intent: 'success',
      });
      return dispatch(push(`/organizations/${match.params.id}/roles`));
    });
  }

  render() {
    const {
      role, submitting, handleSubmit, match, organization, location,
    } = this.props;
    const roleId = parseInt(match.params.role_id, 10);
    const isAdmin = (role.data || {}).is_admin;
    const org = (organization.data
      && (role.data || {}).organization_id === (organization.data || {}).id)
      ? organization.data : {};
    const orgId = (role.data || {}).organization_id;
    return (
      <div className="container">
        <div className={classNames('columns', { 'bp3-skeleton': role.pending && (role.data || {}).role_id !== roleId })}>
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  {(role.data || {}).role_name}
                  <div className="bp3-text-small bp3-text-muted">
                    Organization:&nbsp;
                    <Link to={`/organizations/${orgId}`}>{org.name || (role.data || {}).organization_id}</Link>
                    &nbsp;|
                    Is Admin:&nbsp;
                    {JSON.stringify((role.data || {}).is_admin)}
                  </div>
                </H1>
              </div>
              <div className="column p-b-none" style={{ textAlign: 'right' }}>
                <Button
                  intent="danger"
                  loading={submitting}
                  disabled={submitting}
                  icon="trash"
                  onClick={this.handleDelete}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="columns">
              <div className="column is-one-quarter p-t-none">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="role_name"
                  label="Name"
                  type="text"
                  rightElement={(
                    <Button intent="primary" minimal loading={submitting} disabled={submitting} type="submit" icon="tick">
                      Save
                    </Button>
                  )}
                />
              </div>
            </div>
          </form>
        </div>

        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-role" selectedTabId={location.pathname} onChange={this.handleTabChange} large>
              <Tab id={`/organizations/${orgId}/roles/${roleId}`} title="Permissions" />
              <Tab id={`/organizations/${orgId}/roles/${roleId}/resources`} title="Resources" />
            </Tabs>
          </Navbar.Group>
        </Navbar>

        <Switch>
          <Route path="/organizations/:id/roles/:role_id/resources">
            <Resources
              style={{ margin: '16px 0' }}
              resources={(role.data || {}).resources || []}
              roleId={(role.data || {}).role_id}
              orgId={orgId}
              isAdmin={isAdmin}
            />
          </Route>
          <Route>
            <Permissions
              style={{ margin: '16px 0' }}
              roleId={(role.data || {}).role_id}
              isAdmin={isAdmin}
              orgId={orgId}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

Role.propTypes = {
  dispatch: PropTypes.func,
  role: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organization: PropTypes.object,
};

export default connect((state, props) => ({
  role: state.role,
  organization: state.organization,
  initialValues:
    state.role.data && state.role.data.role_id === parseInt(props.match.params.role_id, 10) ? {
      role_name: state.role.data.role_name,
    } : undefined,
}))(reduxForm({
  form: 'role',
  enableReinitialize: true,
})(Role));
