import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const PermissionedRedirect = ({
  to,
  permissions: Permissions,
  renderNoPermissions,
}) => (
  <Permissions renderNoPermissions={renderNoPermissions} renderWhilePending>
    <Redirect to={to} />
  </Permissions>
);

PermissionedRedirect.propTypes = {
  to: PropTypes.string,
  permissions: PropTypes.any,
  renderNoPermissions: PropTypes.func,
};

export default PermissionedRedirect;
