import qs from 'query-string';
import {
  GET_TRAINER_RESOURCES, GET_TRAINING_DATASETS, GET_ALL_TRAINING_TASKS, GET_TRAINING_STATUS,
  CREATE_TRAINING_TASK, TERMINATE_TRAINING,
} from '../constants';

export const getTrainerResources = (id, start, end) => ({
  type: GET_TRAINER_RESOURCES,
  meta: {
    name: id,
    actionKey: id,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/ml/models/training/resources/${id}?${qs.stringify({
        start,
        end,
      })}`,
    },
  },
});

export const getTrainingDatasets = () => ({
  type: GET_TRAINING_DATASETS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/ml/models/training/datasets',
    },
  },
});

export const getAllTrainingTasks = () => ({
  type: GET_ALL_TRAINING_TASKS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/ml/models/training/tasks',
    },
  },
});

export const getTrainingTaskStatus = id => ({
  type: GET_TRAINING_STATUS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/ml/models/training/tasks/${id}/status`,
    },
  },
});

export const createTrainingTask = data => ({
  type: CREATE_TRAINING_TASK,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/ml/models/training/tasks',
    },
  },
});

export const terminateTraining = (id, training_id) => ({
  type: TERMINATE_TRAINING,
  payload: {
    request: {
      method: 'post',
      url: `/v1/ml/models/training/resources/${id}/terminate?training_id=${training_id}`,
    },
  },
});
