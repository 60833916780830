import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Card, NonIdealState } from '@blueprintjs/core';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

export const options2 = (influx, metric, tag) => _(((influx || {}).data || {}).results || [])
  .filter(x => metric === (_.head(x.series) || {}).name)
  .map(x => x.series)
  .flatten(true)
  .map(x => x.tags[tag])
  .flatten(true)
  .uniq()
  .value();

const MetricLineGraph = ({
  labels, datasets, yFmtr,
}) => (
  <Line
    data={{
      labels: labels[0] || [],
      datasets,
    }}
    options={{
      legend: {
        labels: {
          fontColor: '#f5f8fa',
          generateLabels: (chart) => {
            const x = Chart.defaults.global.legend.labels.generateLabels(chart);
            x.forEach((yy) => {
              const y = yy;
              y.fillStyle = y.strokeStyle;
            });
            return x;
          },
        },
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          labelColor: (tooltipItem, chart) => {
            const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
            x.backgroundColor = x.borderColor;
            return x;
          },
          beforeLabel: (tooltipItem) => {
            // eslint-disable-next-line no-param-reassign
            tooltipItem.yLabel = yFmtr(tooltipItem.yLabel);
          },
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          ticks: {
            source: 'auto',
            fontColor: '#f5f8fa',
          },
          // color: '#f5f8fa',
        }],
        yAxes: [{
          // color: '#f5f8fa',
          scaleLabel: {
            display: true,
          },
          ticks: {
            fontColor: '#f5f8fa',
            callback: yFmtr,
          },
        }],
      },
    }}
  />
);

MetricLineGraph.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  yFmtr: PropTypes.func,
};

export const InfluxGraph = ({
  metrics, metric, yFmt, filter,
}) => {
  const yFmtr = yFmt || (x => x);
  const mf = filter || (() => true);
  if (metrics.pending || !metrics.data) {
    return (
      <div className="has-text-centered" style={{ padding: '100px 0' }}>
        <Spinner size={50} />
      </div>
    );
  }
  const labels = _(metrics.data.results)
    .filter(x => metric === (_.head(x.series) || {}).name)
    .map(x => x.series[0].values)
    .flatten(true)
    .map(x => x[0])
    .value();

  const datasets = _(metrics.data.results)
    .filter(x => metric === (_.head(x.series) || {}).name)
    .map(x => x.series)
    .flatten(true)
    .filter(mf)
    .map(x => _(x.columns)
      .tail()
      .map((z, i) => ({ m: z, d: x.values.map(y => ({ t: y[0], y: y[i + 1] })) }))
      .value())
    .flatten(true)
    .map((x, i) => ({
      label: x.m,
      data: x.d,
      type: 'line',
      pointRadius: 0,
      fill: true,
      lineTension: 0,
      borderWidth: 1.3,
      borderColor: ['#CFF3D2', '#8BCDBC', '#59A3AC', '#3878A1', '#1F4B99'][i % 5],
    }))
    .value();
  return (
    <MetricLineGraph labels={labels} datasets={datasets} yFmtr={yFmtr} />
  );
};

InfluxGraph.propTypes = {
  metrics: PropTypes.object,
  metric: PropTypes.string,
  yFmt: PropTypes.func,
  filter: PropTypes.func,
};

export const InfluxCard = ({ children, metrics }) => (
  <Card>
    {!!metrics.data && children}
    {!metrics.data
      && !metrics.pending
      && <NonIdealState icon="warning-sign" title="No data" className="non-ideal-device-card" />}
    {!metrics.data
      && !!metrics.pending
      && <div className="has-text-centered" style={{ padding: '160px 0' }}><Spinner /></div>}
  </Card>
);

InfluxCard.propTypes = {
  metrics: PropTypes.object,
  children: PropTypes.node,
};
