import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm, reset, FieldArray,
} from 'redux-form';
import {
  Card, Button, H4, NonIdealState, Spinner,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { AppToaster } from 'components/Toaster';
import { TextInput } from 'components/inputs';

import { getLocationWhitelist, postWhitelist, deleteWhitelist } from 'actions/location';

const MAC_REGEX = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;

class LocationWhitelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // shouldn't need to store these...api design is weird and should overwrite?
      deletedMacs: [],
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getLocationWhitelist(match.params.id));
  }

  @autobind
  addDeleteEntry(entry) {
    const { deletedMacs } = this.state;
    if (entry) {
      this.setState({ deletedMacs: [...deletedMacs, entry] });
    }
  }

  @autobind
  handleSave(values) {
    const { match, dispatch } = this.props;
    const { deletedMacs } = this.state;
    const { whitelists } = values;
    const shouldDelete = !!deletedMacs.length;
    const macIds = whitelists.map(x => x.mac);
    return dispatch(shouldDelete
      ? deleteWhitelist(match.params.id, {
        macids: deletedMacs,
      })
      : postWhitelist(match.params.id, {
        macids: macIds,
      }))
      .then(() => shouldDelete && dispatch(postWhitelist(match.params.id, {
        macids: macIds,
      }))).then(() => {
        AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              Whitelist saved
            </span>
          ),
          intent: 'success',
        });
        return dispatch(getLocationWhitelist(match.params.id));
      }).then(() => dispatch(reset('whitelists')));
  }

  @autobind
  renderWhitelists({ fields }) {
    const whitelists = fields.map((white, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={i}>
        <div className="mac-entry">
          <Field
            component={TextInput}
            name={`${white}.mac`}
            type="text"
          />
        </div>
        <div className="mac-delete">
          <Button
            icon="cross"
            title="Remove Mac Address"
            intent="danger"
            onClick={() => {
              this.addDeleteEntry(fields.get(i) ? fields.get(i).mac : '');
              fields.remove(i);
            }}
          />
        </div>
      </li>
    ));
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column is-one-quarter">
            <H4>MAC Address Whitelist</H4>
          </div>
          <div className="column has-text-right">
            <Button icon="plus" onClick={() => fields.push()}>
              MAC
            </Button>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            {whitelists.length ? (
              <div className="whitelist-container">{ whitelists }</div>
            ) : (<NonIdealState title="" />)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      submitting, handleSubmit, whitelist, match,
    } = this.props;
    const currentWhitelist = (whitelist || {}).data;

    if (!whitelist.data && whitelist.pending) {
      return <div><Spinner /></div>;
    }

    if (currentWhitelist.id !== parseInt(match.params.id, 10)) {
      return <div><Spinner /></div>;
    }

    return (
      <Card>
        <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
          <FieldArray name="whitelists" component={this.renderWhitelists} />
          <div className="columns">
            <div className="column has-text-right">
              <Button
                intent="primary"
                loading={submitting}
                disabled={submitting}
                type="submit"
                icon="tick"
                style={{ marginTop: 15 }}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Card>
    );
  }
}

LocationWhitelist.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  whitelist: PropTypes.object,
};

export default connect(state => ({
  whitelist: state.whitelist,
  initialValues: state.whitelist.data && !!state.whitelist.data.whitelist ? {
    whitelists: state.whitelist.data.whitelist.map(x => ({ mac: x })),
  } : undefined,
}))(reduxForm({
  form: 'whitelists',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    const whitelistsErrors = [];
    if (values.whitelists) {
      values.whitelists.forEach((val, i) => {
        const memberErrors = {};
        if (val && val.mac && !MAC_REGEX.test(val.mac)) {
          memberErrors.mac = 'Invalid mac address';
          whitelistsErrors[i] = memberErrors;
        }
      });
    }
    if (whitelistsErrors.length > 0) {
      errors.whitelists = whitelistsErrors;
    }

    return errors;
  },
})(LocationWhitelist));
