import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Tag, H3, Switch,
} from '@blueprintjs/core';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import { randomColor } from 'randomcolor';

import ROCChart from './rocChart';

const fmtr = x => numbro(x).format('0,0');

class VisualizeReid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: true,
    };
  }

  @autobind
  handleDistanceSwitch() {
    const { distance } = this.state;
    this.setState({ distance: !distance });
  }

  @autobind
  renderChartRow(item) {
    const {
      label, name, model, borderColor,
    } = item;
    return (
      <tr key={label}>
        <td><Tag style={{ backgroundColor: borderColor }} /></td>
        <td>{label}</td>
        <td>{name}</td>
        <td>{model}</td>
      </tr>
    );
  }

  @autobind
  renderCharts() {
    const { compareData, tasks } = this.props;
    const { distance } = this.state;
    let filteredData = [];
    if (distance) {
      filteredData = compareData.filter(x => x.dist_type === 'euc');
    } else {
      filteredData = compareData.filter(x => x.dist_type === 'cos');
    }
    const groupedData = _.groupBy(filteredData, 'task_id');
    const datasets = _.flatten(Object.values(
      _.mapValues(groupedData, (a, b) => {
        const {
          title = '',
          model_id = '',
        } = (tasks || []).find(o => o.task_id === parseInt(b, 10));
        return [
          {
            label: b,
            data: [...a].sort((c, d) => c.thresh < d.thresh)
              .map(x => parseFloat((x.tp / (x.tp + x.fn)).toFixed(4))),
            fill: false,
            pointRadius: 0.5,
            lineTension: 0,
            borderWidth: 1,
            borderColor: randomColor({ format: 'hsl', hue: 'random' }),
            name: title,
            model: model_id,
          },
        ];
      }),
    ));
    if (datasets[0] && datasets[0].data.length) {
      const labels = filteredData.map(obj => obj.thresh)
        .filter((item, index, arr) => arr.indexOf(item) === index);
      const chartDataset = {
        datasets: [...datasets],
        labels,
      };
      return (
        <div style={{ marginTop: 20 }}>
          <Switch
            checked={distance}
            label="Distance Type"
            innerLabelChecked="euc"
            innerLabel="cos"
            large
            onChange={this.handleDistanceSwitch}
          />
          <table className={classNames('bp3-html-table bp3-html-table-striped', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th />
                <th>Audit ID</th>
                <th>Name</th>
                <th>Model</th>
              </tr>
            </thead>
            <tbody>
              {(datasets || []).map(this.renderChartRow)}
            </tbody>
          </table>
          <div style={{ marginTop: 20 }}>
            <H3>Reid Models Comparison</H3>
          </div>
          <div style={{ height: 500 }}>
            <ROCChart
              yFmtr={fmtr}
              data={() => chartDataset}
              legend
              position="top"
              yLabel="Recall"
              xLabel="Distance Threshold"
            />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return this.renderCharts();
  }
}

VisualizeReid.propTypes = {
  compareData: PropTypes.array,
  tasks: PropTypes.array,
};

export default connect()(VisualizeReid);
