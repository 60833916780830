import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Spinner,
} from '@blueprintjs/core';
import { getServices } from 'actions/iap';
import { getReidTasks, getModels } from 'actions/reid';
import { getUsers, getUsersNonAdmin } from 'actions/user';
import reidAudit from './Audit/reidAuditList';
import List from './list';
import Create from './create';
import ReidTask from './task';
import ReidGroundTruth from './groundtruth';
import ReidMergeTask from './mergeTask';
import ManualReidTask from './manualReid';
import reidAuditSummary from './Audit/reidAuditSummary';
import ReidAuditCreate from './Audit/createReidAudit';
import compareReidAudit from './Audit/reidAuditCompare';


class Reid extends Component {
  componentDidMount() {
    const { dispatch, orgId } = this.props;
    if (orgId) {
      if (orgId === 1) {
        dispatch(getUsers(null, null, [1, 443], null, null));
        dispatch(getServices());
        dispatch(getModels());
        dispatch(getReidTasks());
      } else {
        dispatch(getUsersNonAdmin(null, null, orgId, null, null));
        dispatch(getReidTasks());
      }
    }
  }

  render() {
    const {
      services, models, tasks, users, orgId,
    } = this.props;
    if (orgId === 1) {
      if (
        (services.pending || !(services.data || []).length)
      || (models.pending || !(models.data || []).length)
      || (tasks.pending && !(tasks.data || []).length)
      || (users.pending && !(users.data || []).length)
      ) {
        return <Spinner size={100} />;
      }
    } else if (tasks.pending && !(tasks.data || []).length) {
      return <Spinner size={100} />;
    }
    return (
      <Switch>
        <Route path="/reid/create" component={Create} />
        <Route path="/reid/audit/create" component={ReidAuditCreate} />
        <Route path="/reid/audit/compare" component={compareReidAudit} />
        <Route path="/reid/:id/groundtruth/:clip_name/:track_id" component={ReidGroundTruth} />
        <Route path="/reid/merge/:id" component={ReidMergeTask} />
        <Route path="/reid/audit/:id" component={reidAuditSummary} />
        <Route path="/reid/audit" component={reidAudit} />
        <Route path="/reid/:id/manual" component={ManualReidTask} />
        <Route path="/reid/:id" component={ReidTask} />
        <Route component={List} />
      </Switch>
    );
  }
}

Reid.propTypes = {
  dispatch: PropTypes.func,
  services: PropTypes.object,
  models: PropTypes.object,
  tasks: PropTypes.object,
  users: PropTypes.object,
  orgId: PropTypes.number,
};

export default connect(state => ({
  services: state.iap.services,
  models: state.models,
  tasks: state.tasks,
  users: state.users,
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(Reid);
