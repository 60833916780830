import { requestsReducer } from 'redux-saga-requests';
import { GET_ROLES, GET_ROLE, GET_PERMISSIONS } from '../constants';

export const rolesReducer = requestsReducer({
  actionType: GET_ROLES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const roleReducer = requestsReducer({
  actionType: GET_ROLE,
  getData: (state, action) => action.payload.data.content,
});

export const permissionsReducer = requestsReducer({
  actionType: GET_PERMISSIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});
