/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  H1, Spinner, Icon, H3, FormGroup, Button, Card,
  MenuItem,
  H4,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { AppToaster } from 'components/Toaster';
import {
  getClipsPerTask, getReidTask, getReidTasks, getPatchesPerClip,
  getReidTaskGroups, postReidTaskGroup, deleteReidTaskGroup,
} from 'actions/reid';

import ReidPatchesList from './reidPatchList';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;
const ColumnSeperator = () => (<div style={{
  borderLeft: '2px solid black',
  height: '100%',
  position: 'absolute',
  left: '50%',
}}
/>
);
const defaultRenderer = o => o.clip_name;

const Tracks = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    tracksList, selectedTracks, savedGroups, handleSelect,
  } = props;
  return (
    <ul>
      {tracksList.map(track => (
        <li key={track.reference_track_id} style={{ display: 'inline-block', width: '33%', marginBottom: '10px' }}>
          <ReidPatchesList
            track={track}
            isSelected={selectedTracks.filter(x => x.clip_name === track.clip_name && x.reference_track_id === track.reference_track_id).length > 0}
            isSaved={savedGroups.filter(x => x.clip_name === track.clip_name && x.reference_track_id === track.reference_track_id).length > 0}
            handleSelect={handleSelect}
          />
        </li>
      ))}
    </ul>

  );
};

// const STATUS_MAP = {
//   0: 'Incomplete',
//   1: 'Complete',
//   4: 'Paused',
// };

class ManualReid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clipsData: [],
      saveLoading: false,
      clearLoading: false,
      selectedExitClip: '',
      selectedEntryClip: '',
      tracksloading: false,
      selectedTracks: [],
      entryTracks: [],
      exitTracks: [],
      savedGroups: [],
      isAdd: false,
      addedGroups: [],
      card_collapsed: true,
      // selectedTracks: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  @autobind
  onEntryClipSelect(item) {
    const { match } = this.props;
    this.setState({ selectedEntryClip: item.clip_name, tracksloading: true });
    this.getPatches(match.params.id, item.clip_name, 'entry');
  }

  @autobind
  onExitClipSelect(item) {
    const { match } = this.props;
    this.setState({ selectedExitClip: item.clip_name, tracksloading: true });
    this.getPatches(match.params.id, item.clip_name, 'exit');
  }

  @autobind
  async getData() {
    const {
      dispatch, match, reidTask, reidTasks,
    } = this.props;
    const { id } = match.params;
    dispatch(getClipsPerTask(id, 10)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const clipsList = payload.data.content;
        this.setState({
          clipsData: clipsList,
        });
        this.getGroups();
      }
    });
    if (!(reidTask.data || []).length) {
      dispatch(getReidTask(id));
    }

    if (!(reidTasks.data || []).length) {
      dispatch(getReidTasks());
    }
  }

  @autobind
  async getPatches(id, clip_name, type) {
    const { dispatch } = this.props;
    dispatch(getPatchesPerClip(id, clip_name)).then(({ payload }) => {
      if (payload && payload.data.content) {
        if (type === 'entry') {
          const entryTrackList = payload.data.content;
          this.setState({ entryTracks: entryTrackList, tracksloading: false });
        } else {
          const exitTrackList = payload.data.content;
          this.setState({ exitTracks: exitTrackList, tracksloading: false });
        }
      }
    });
  }

  @autobind
  async getGroups() {
    const { dispatch, match } = this.props;
    try {
      dispatch(getReidTaskGroups(match.params.id)).then(({ payload }) => {
        if (payload && payload.data.content) {
          const grouped = _.groupBy(payload.data.content, track => track.group_id);
          this.setState({ savedGroups: grouped });
        } else {
          this.setState({ savedGroups: [] });
        }
      });
    } catch (e) {
      this.setState({ savedGroups: [] });
    }
  }

  @autobind
  async clearOutput() {
    const { dispatch, match } = this.props;
    const { savedGroups } = this.state;
    // const { dispatch, match } = this.props;
    if (savedGroups.length !== 0) {
      try {
        await Promise.all(Object.keys(savedGroups).map(group_id => dispatch(deleteReidTaskGroup(
          match.params.id,
          group_id,
        ))));
        return this.getGroups();
      } catch (e) {
        return AppToaster.show({
          icon: 'cross',
          message: <span>Error Deleting Groups</span>,
          intent: 'danger',
        });
        //
      } finally {
        this.setState({ addedGroups: [] });
      }
    }
    return AppToaster.show({
      icon: 'cross',
      message: <span>Please Add Groups</span>,
      intent: 'danger',
    });
  }

  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  // @autobind
  // handleHelp() {
  //   this.setState({ showHelp: true });
  // }

  // @autobind
  // handleCloseHelp() {
  //   this.setState({ showHelp: false });
  // }
  @autobind
  async handleSubmitGroups() {
    const { dispatch, match } = this.props;
    const { addedGroups } = this.state;
    if (addedGroups.length !== 0) {
      try {
        await Promise.all(addedGroups.map(group => dispatch(postReidTaskGroup(
          match.params.id,
          JSON.parse(JSON.stringify(group)),
        ))));
        return this.getGroups();
      } catch (e) {
        return AppToaster.show({
          icon: 'cross',
          message: <span>Error Submitting Groups</span>,
          intent: 'danger',
        });
        //
      } finally {
        this.setState({ addedGroups: [] });
        AppToaster.show({
          icon: 'tick',
          message: <span>Submitted</span>,
          intent: 'success',
        });
      }
    }
    return AppToaster.show({
      icon: 'cross',
      message: <span>Please Add Groups</span>,
      intent: 'danger',
    });
  }

  @autobind
  handleSelect(track) {
    const { selectedTracks, isAdd } = this.state;
    if (!isAdd) {
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>Please Add a New Group</span>,
      });
    }
    const index = selectedTracks.findIndex(x => x.clip_name === track.clip_name && track.reference_track_id === x.reference_track_id);
    if (index > -1) {
      const tmp = [...selectedTracks];
      tmp.splice(index, 1);
      this.setState({ selectedTracks: tmp });
    } else {
      this.setState({ selectedTracks: [...selectedTracks, track] });
    }
    return null;
  }

  @autobind
  handleAddGroup() {
    const { isAdd } = this.state;
    if (!isAdd) {
      this.setState({ isAdd: true });
    }
  }

  @autobind
  handleDeleteGroup(i) {
    const { addedGroups } = this.state;
    const tmp = [...addedGroups];
    tmp.splice(i, 1);
    this.setState({ addedGroups: tmp });
  }

  @autobind
  handleDeleteSavedGroup(group_id) {
    const { dispatch, match } = this.props;
    dispatch(deleteReidTaskGroup(match.params.id, group_id)).then(() => this.getGroups());
  }

  @autobind
  handleSaveGroup() {
    const { addedGroups, selectedTracks } = this.state;
    const tmp = addedGroups.length > 0 ? addedGroups.concat([selectedTracks]) : [selectedTracks];
    const entry = selectedTracks.some(x => x.entry);
    const exit = selectedTracks.some(x => !x.entry);
    if (entry && exit) {
      this.setState({ isAdd: false, addedGroups: tmp, selectedTracks: [] });
    } else {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Please Select both entry and exit tracks</span>,
        intent: 'danger',
      });
    }
    return null;
    // const { selectedTracks } = this.state;
  }

  @autobind
  toggleGroupsCard() {
    const { card_collapsed } = this.state;
    if (!card_collapsed) {
      this.setState({ card_collapsed: true });
    } else {
      this.setState({ card_collapsed: false });
    }
  }

  @autobind
  renderItem(item, { modifiers, handleClick }) {
    const itemRenderer = defaultRenderer;
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.clip_name}
        onClick={handleClick}
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const {
      reidTasks, reidTask, match, dispatch,
    } = this.props;
    const {
      clearLoading, clipsData, saveLoading, selectedEntryClip, selectedExitClip,
      tracksloading, entryTracks, exitTracks, selectedTracks, savedGroups, isAdd,
      addedGroups, card_collapsed,
    } = this.state;
    if (
      (reidTask.pending && !reidTask.data) || tracksloading
      // || (reidTaskClips.pending && !reidTaskClips.data)
    ) {
      return <Spinner size={100} />;
    }
    const selectedReidTask = reidTask.data || (reidTasks || {}).data.find(x => x.task_id === parseInt(match.params.id, 10));
    const {
      task_id, title,
    } = selectedReidTask || {};
    const savedGroupsList = Object.values(savedGroups).flat().concat(addedGroups.flat());
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="reid-header-container">
            <H1>
              <Icon
                className="reid-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                style={{ marginRight: '1em' }}
                onClick={() => dispatch(push(`/reid/${match.params.id}`))}
              />
              {`ID: ${task_id}`}
              <Dot />
              {title}
            </H1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <H3>
              Entry
            </H3>
            <FormGroup className="inline-flex m-r-sm" label="Entry Clips">
              <div style={{ display: 'flex' }}>
                <Select
                  resetOnSelect
                  itemRenderer={this.renderItem}
                  filterable={false}
                  popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                  items={clipsData !== undefined ? clipsData.filter(x => x.entry === true) : []}
                  onItemSelect={this.onEntryClipSelect}
                  activeItem={selectedEntryClip}
                >
                  <Button text={selectedEntryClip || 'Entry Clips'} rightIcon="caret-down" />
                </Select>
              </div>
            </FormGroup>
            <H4>
              Selected Clip:
              {' '}
              {selectedEntryClip}
            </H4>
          </div>
          <ColumnSeperator />
          <div className="column">
            <H3>
              Exit
            </H3>
            <FormGroup className="inline-flex m-r-sm" label="Exit Clips">
              <div style={{ display: 'flex' }}>
                <Select
                  resetOnSelect
                  itemRenderer={this.renderItem}
                  filterable={false}
                  popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                  items={clipsData !== undefined ? clipsData.filter(x => x.entry !== true) : []}
                  onItemSelect={this.onExitClipSelect}
                  activeItem={selectedExitClip}
                >
                  <Button text={selectedExitClip || 'Exit Clips'} rightIcon="caret-down" />
                </Select>
              </div>
            </FormGroup>
            <H4>
              Selected Clip:
              {' '}
              {selectedExitClip}
            </H4>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Tracks tracksList={entryTracks} handleSelect={this.handleSelect} selectedTracks={selectedTracks} savedGroups={savedGroupsList} />
          </div>
          <ColumnSeperator />
          <div className="column">
            <Tracks tracksList={exitTracks} handleSelect={this.handleSelect} selectedTracks={selectedTracks} savedGroups={savedGroupsList} />
          </div>
        </div>
        {
          (selectedTracks.length > 0 && (
          <Card
            elevation="3"
            style={{
              display: 'inline-block',
              position: 'fixed',
              float: 'left',
              left: '0',
              top: '200px',
            }}
          >
            <div>
            Selected:
              {' '}
              { selectedTracks.length }
            </div>
            <img
              src={selectedTracks[0] && selectedTracks[0].patches ? selectedTracks[0].patches[0].url : 'https://dummyimage.com/300x200/000/fff&text=^+image+not+found'}
              height="50px"
              width="auto"
              alt=""
              id="img-feed"
            />
          </Card>
          ))
        }
        <Card
          elevation="3"
          style={{
            display: 'inline-block',
            position: 'fixed',
            float: 'right',
            right: '0',
            top: '200px',
            maxWidth: card_collapsed ? '10%' : '30%',
            maxHeight: '500px',
          }}
        >
          <H4 style={{ display: 'inline-block' }}>
            <Icon
              className="reid-back-icon"
              icon={card_collapsed ? 'caret-left' : 'caret-right'}
              iconSize={20}
              style={{ marginRight: '1em', display: 'inline-block' }}
              onClick={this.toggleGroupsCard}
            />
          </H4>
          <H4 style={{ display: 'inline-block' }}>
            Saved Groups:
            {' '}
            {savedGroups ? Object.keys(savedGroups).length : 0}
          </H4>
          <div>
            <Button onClick={this.handleAddGroup} disabled={(isAdd)} icon="plus" intent="primary" />
          </div>
          <ul style={{ overflow: 'auto', maxHeight: '350px', display: card_collapsed ? 'none' : '' }}>
            {/* <li>
              <div className="columns">
                <div className="column">Entry</div>
                <div className="column">Exit</div>
              </div>
            </li> */}
            {/* <div className="columns">
                  <div className="column">{ tracks.map(x => x.clip_name) }</div>
                  <div className="column">Exit</div>
                </div> */}
            {Object.values(savedGroups).map((tracks, i) => (
              <li key={i.toString()} style={{ marginTop: '10px' }}>
                <div style={{
                  display: 'inline-flex',
                  width: '80%',
                  borderBottom: '1px solid black',
                  paddingBottom: '10px',
                }}
                >
                  {tracks.map(x => ''.concat(x.clip_name, '_', x.reference_track_id)).join()}
                </div>
                <Button onClick={() => this.handleDeleteSavedGroup(tracks[0].group_id)} icon="cross" intent="primary" />
              </li>
            ))}
            {addedGroups.map((tracks, i) => (
              <li key={i.toString()} style={{ marginTop: '10px' }}>
                <div style={{
                  display: 'inline-flex',
                  width: '80%',
                  borderBottom: '1px solid black',
                  paddingBottom: '10px',
                }}
                >
                  {tracks.map(x => ''.concat(x.clip_name, '_', x.reference_track_id)).join()}
                </div>
                <Button onClick={() => this.handleDeleteGroup(i)} icon="cross" intent="primary" />
              </li>
            ))}
            { isAdd && (
              <li style={{ marginTop: '10px' }}>
                <div style={{
                  display: 'inline-flex',
                  width: '80%',
                  borderBottom: '1px solid black',
                  paddingBottom: '10px',
                }}
                >
                  {selectedTracks.map(x => ''.concat(x.clip_name, '_', x.reference_track_id)).join()}
                </div>
                <Button onClick={this.handleSaveGroup} icon="tick" intent="primary" />
              </li>
            )}
          </ul>
          <div style={{ marginTop: '10px', display: card_collapsed ? 'none' : '' }}>
            <Button title="Submit Groups" loading={(saveLoading || tracksloading)} onClick={this.handleSubmitGroups} disabled={(addedGroups.length === 0)} intent="primary" type="submit" icon="tick">Submit</Button>
            <Button style={{ margin: '0px 0px 0px 10px' }} disabled={(savedGroupsList.length === 0)} onClick={this.clearOutput} loading={(clearLoading)} intent="primary" icon="trash">Clear Output</Button>
            <Button title="Help" icon="help" className="m-l-sm" onClick={this.handleHelp} />
          </div>
        </Card>
      </div>
    );
  }
}

ManualReid.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  reidTask: PropTypes.object,
  reidTasks: PropTypes.object,
  // reidTaskClips: PropTypes.object,
};

export default connect(state => ({
  reidTask: state.reidTask,
  token: state.currentUser.token.access_token,
  reidTasks: state.reidTasks,
  entryTracks: state.tracks,
  exitTracks: state.exitTracks,
  selectedEntryClip: state.selectedEntryClip,
  selectedExitClip: state.selectedExitClip,
  clipsData: state.clipsData,
}))(ManualReid);
