import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation, Tag } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import numbro from 'numbro';

import Line from './line';

const fmtr = x => numbro(x.toFixed(2)).format('0,0');

const CPUChart = ({ data }) => {
  const time = data.columns.findIndex(x => x === 'time');
  const labels = data.values.map(x => DateTime.fromMillis(x[time]).toFormat('ff'));
  const cpuUtil = data.columns.findIndex(x => x === 'mean_cpu_percent');
  const cpuCount = data.columns.findIndex(x => x === 'mean_cpu_count');
  const color = '#6BE888';

  const datasets = [
    {
      label: 'CPU Utilization',
      data: data.values.map(x => x[cpuUtil]),
      fill: true,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: color,
      spanGaps: false,
      suffix: '%',
    },
  ];

  const chartDataset = { datasets, labels };

  return (
    <Fragment>
      <Tag large>{`CPU (${data.values[0][cpuCount]} cores)`}</Tag>
      <Card elevation={Elevation.THREE} style={{ marginTop: 10 }}>
        <div style={{ minHeight: 500 }}>
          <Line
            data={() => chartDataset}
            legend
            yFmtr={fmtr}
            position="bottom"
            yLabel=""
          />
        </div>
      </Card>
    </Fragment>
  );
};

CPUChart.propTypes = {
  data: PropTypes.object,
};

export default CPUChart;
