import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H2, FormGroup, InputGroup, Spinner, Button, NonIdealState, Icon,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { getAlerts, createAlert } from 'actions/alerts';

import AlertCard from './AlertCard';
import AddAlertCard, { simplifyNotifChannels } from './edit';

class AlertsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      create: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAlerts());
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleCreateAlert() {
    const { create } = this.state;
    this.setState({ create: !create });
  }

  @autobind
  saveAlert(values) {
    const { dispatch } = this.props;
    const data = {
      alert_name: values.alert_name,
      alert_description: values.alert_description,
      metric: values.metric,
      threshold: values.threshold,
      duration: values.duration,
      type: values.type,
      aggregation: values.aggregation === 'None' ? '' : values.aggregation,
      filter: values.filter,
      reminder: values.reminder || null,
      notification_channels: values.slack_channels ? simplifyNotifChannels(values.slack_channels) : '',
      no_data: values.no_data === true ? 1 : 0,
      pagerduty_notify: values.pagerduty_notify === true ? 1 : 0,
    };
    dispatch(createAlert(data)).then(
      () => dispatch(getAlerts()).then(() => this.setState({ create: false })),
    );
  }

  render() {
    const { alertsList, dispatch } = this.props;
    const { query, create } = this.state;
    if (alertsList.pending) return <Spinner size="100" />;
    if (alertsList.error) {
      return (
        <NonIdealState
          icon={<Icon iconSize={Icon.SIZE_LARGE * 3} icon="error" intent="danger" />}
          title="There was an error retrieving alerts. Ensure you are connected to the VPN"
        />
      );
    }
    let filteredAlerts = alertsList.data.sort((a, b) => (a.alert_name).localeCompare(b.alert_name));
    if (query !== '') {
      filteredAlerts = filteredAlerts.filter(
        x => x.alert_name.toLowerCase().includes(query.toLowerCase())
        || x.alert_description.toLowerCase().includes(query.toLowerCase()),
      );
    }
    return (
      <div className="container">
        <div className="flex-space-between-container">
          <H2>Alerts</H2>
          <Button
            icon={create ? 'cross' : 'add'}
            intent={create ? 'danger' : 'success'}
            onClick={this.handleCreateAlert}
            minimal
          >
            {create ? 'Cancel' : 'Create Alert'}
          </Button>
        </div>
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup
            value={query}
            onChange={this.handleQuery}
            leftIcon="search"
            large
            placeholder="Search alerts by name or description"
          />
        </FormGroup>
        {create && <AddAlertCard saveAlert={this.saveAlert} />}
        {filteredAlerts.map(x => <AlertCard alert={x} key={x.id} dispatch={dispatch} />)}
      </div>
    );
  }
}

AlertsList.propTypes = {
  dispatch: PropTypes.func,
  alertsList: PropTypes.object,
};

export default connect(state => ({
  alertsList: state.alertsList,
}))(AlertsList);
