import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
} from '@blueprintjs/core';

import { getOrganizations } from 'actions/organization';

import Info from './info';
import Assign from './assign';
import Unassign from './unassign';

class Config extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOrganizations());
  }

  renderAssign() {
    const {
      dispatch, organizations, device,
    } = this.props;
    return (
      <Assign
        dispatch={dispatch}
        id={device.id}
        organizations={organizations}
        deviceName={device.name}
      />
    );
  }

  render() {
    const {
      device, dispatch, organizations, enableNameChange,
    } = this.props;
    const infoValues = {
      name: device.name,
      monitored: device.monitored ? 'true' : 'false',
    };
    if (device.type.includes('camera')) {
      infoValues.in_maintenance = device.in_maintenance;
    }
    return (
      <div className="columns">
        <div className="column is-one-third column-eq">
          <Card>
            <Info
              dispatch={dispatch}
              initialValues={infoValues}
              device={device}
              enableNameChange={enableNameChange}
            />
          </Card>
        </div>
        <div className="column is-two-third column-eq">
          <Card>
            {!device.organization_id ? (
              <Assign
                device={device}
                dispatch={dispatch}
                deviceName={device.name}
                organizations={organizations.data || []}
              />
            ) : (
              <Unassign
                organizations={organizations.data || []}
                device={device}
              />
            )
            }
          </Card>
        </div>
      </div>
    );
  }
}

Config.propTypes = {
  dispatch: PropTypes.func,
  organizations: PropTypes.object,
  device: PropTypes.object,
  enableNameChange: PropTypes.bool,
};

export default connect(state => ({
  organizations: state.organizations,
}))(Config);
