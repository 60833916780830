import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import {
  Card, Spinner, H4, NonIdealState,
} from '@blueprintjs/core';
import { QUERY_DATE_FMT } from 'constants';
import { RangePicker } from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/css';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import {
  getInsAndOutsByLine, getCameraFps, getCameraResolution,
} from 'actions/device';
import Line from '../../Line';


const fmtr = x => numbro(x).format('0,0');

const Dot = () => <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>;

class CameraUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: DateTime.utc().minus(10800000),
      endDate: DateTime.utc(),
    };
  }

  componentDidMount() {
    this.getCameraStats();
  }

  componentDidUpdate(prevProps) {
    const { device, region } = this.props;
    if (device.id !== prevProps.device.id
      || region !== prevProps.region) {
      this.getCameraStats();
    }
  }

  @autobind
  getCameraStats(state) {
    const { dispatch, device, region } = this.props;
    const { startDate, endDate } = this.state || state;
    const start = startDate.startOf('second').toISO({ suppressMilliseconds: true });
    const end = endDate.startOf('second').toISO({ suppressMilliseconds: true });
    dispatch(getInsAndOutsByLine(
      device.id,
      start,
      end,
      region,
      'minute',
      'throughput_in,throughput_out',
      'people',
    ));
    dispatch(getCameraFps(
      `SELECT mean(fps) as fps FROM vision WHERE (camera_serial = '${device.id}') AND time >= '${start}' AND time < '${end}' GROUP BY time(1m), vision fill(null);`,
    ));
    dispatch(getCameraResolution(
      `SELECT last(width) AS width, last(height) as height FROM vision WHERE (camera_serial = '${device.id}') AND time >= now() - 30m;`,
    ));
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    }, this.getCameraStats);
  }

  @autobind
  renderCameraData(result) {
    const xFmt = x => moment(x[0]).toDate();
    const labels = result.length ? result.map(xFmt) : [];
    const datasets = [
      {
        label: 'In',
        data: result ? result.map(x => x[1]) : [],
        borderColor: '#CFF3D2',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#CFF3D2',
        pointRadius: 0,
        lineTension: 0,
      },
      {
        label: 'Out',
        data: result ? result.map(x => x[2]) : [],
        borderColor: '#DB8A3A',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#DB8A3A',
        pointRadius: 0,
        lineTension: 0,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderInsAndOuts(data) {
    if (!(data || {})) {
      return (
        <Card>
          <NonIdealState icon="warning-sign" title="No Ins and Outs" className="non-ideal-device-card" />
        </Card>
      );
    }
    const result = data ? [...data]
      .filter(x => !x.includes(null))
      .sort((a, b) => a[0].localeCompare(b[0])) : [];
    const totalIn = result ? _.sum(result.map(x => x[1])) : 0;
    const totalOut = result ? _.sum(result.map(x => x[2])) : 0;
    return (
      <React.Fragment>
        <H4 className="is-marginless column">
          <span>Ins:</span>
          <span style={{ marginLeft: 5, color: '#CFF3D2' }}>
            {numbro(totalIn).format('0,0')}
          </span>
          <Dot />
          <span>Outs:</span>
          <span style={{ marginLeft: 5, color: '#DB8A3A' }}>
            {numbro(totalOut).format('0,0')}
          </span>
        </H4>
        <div style={{ height: 300 }}>
          <Line
            yFmtr={fmtr}
            data={() => this.renderCameraData(result)}
            title="Camera Ins and Outs"
            legend
            position="top"
          />
        </div>
      </React.Fragment>
    );
  }

  @autobind
  renderFpsData(data) {
    const orderedData = data && data.length ? data.reverse() : [];
    const xFmt = x => moment(x).toDate();
    const times = orderedData && orderedData.length ? orderedData.map(x => x[0]) : [];
    const labels = times ? times.map(xFmt) : [];
    const datasets = [{
      label: 'FPS',
      data: data ? data.map(x => x[1]) : [],
      fill: true,
      borderColor: '#CFF3D2',
      pointBackgroundColor: '#CFF3D2',
      pointRadius: 0,
      lineTension: 0,
      xTimeUnit: 'minute',
    }];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderFps() {
    const { fps } = this.props;
    if (fps.pending && !fps.data.length) {
      return (
        <div className="has-text-centered" style={{ padding: '160px 0' }}>
          <Spinner />
        </div>
      );
    }
    if (!((fps.data || [])[0] || {}).series && !fps.pending) {
      return (
        <Card>
          <NonIdealState icon="warning-sign" title="No FPS" className="non-ideal-device-card" />
        </Card>
      );
    }
    const frames = (fps || {}).data;
    const result = frames.length ? frames[0] : [];
    const series = result && result.series && result.series.length ? result.series[0] : {};
    const values = series && series.values && !!series.values
      ? series.values.filter(x => x[1] !== null) : [];
    return !!values.length && (
      <React.Fragment>
        <H4 className="is-marginless column">FPS</H4>
        <div style={{ height: 300 }}>
          <Line
            yFmtr={fmtr}
            data={() => this.renderFpsData(values)}
            title="FPS"
            legend
            position="top"
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { startDate, endDate } = this.state;
    const {
      device, resolution, cameraLineStats,
    } = this.props;
    const d = device;
    const doRender = device.id === d.id;
    const res = (resolution || {}).data;
    const series = res.length ? res[0].series : [];
    const values = series && series.length ? series[0].values : [];
    const dimensions = values && values.length && values[0] ? values[0] : [];
    if (cameraLineStats.pending
      || (!cameraLineStats || !cameraLineStats.data || !cameraLineStats.data.line_rows)) {
      return (
        <div className="has-text-centered" style={{ padding: '160px 0' }}>
          <Spinner />
        </div>
      );
    }
    return doRender && (
      <React.Fragment>
        <div className="columns">
          <div className="column is-half">
            <Card>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format={QUERY_DATE_FMT}
                style={{ width: '500px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Card>
          </div>
          <div className="column is-half">
            <div style={{ fontSize: 16 }}>
              {dimensions.length
                ? `Camera Resolution: ${dimensions[1]} x ${dimensions[2]}`
                : 'Camera Resolution: Unknown'}
            </div>
          </div>
        </div>
        {Object.entries(cameraLineStats.data.line_rows || {}).map(([key, value]) => (
          <div className="columns" key={key}>
            <div className="column is-full">
              <H4 className="is-marginless column">
                <span>Line:</span>
                <span style={{ marginLeft: 5, color: 'white' }}>
                  {key.startsWith('0_') ? 'Default' : key}
                </span>
              </H4>
              {this.renderInsAndOuts(value)}
            </div>
          </div>
        ))}
        <div className="columns">
          <div className="column is-full">
            {this.renderFps()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CameraUsage.propTypes = {
  dispatch: PropTypes.func,
  region: PropTypes.string,
  device: PropTypes.object,
  fps: PropTypes.object,
  resolution: PropTypes.object,
  cameraLineStats: PropTypes.object,
};

export default connect(state => ({
  camera: state.cameraStats,
  fps: state.fps,
  resolution: state.resolution,
  cameraLineStats: state.cameraLineStats,
}))(CameraUsage);
