/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { AppToaster } from 'components/Toaster';
import { getAuditSiteDevices } from 'actions/site';
import { getClipsByCamera } from 'actions/device';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { DateInput } from '@blueprintjs/datetime';
import PropTypes from 'prop-types';
import {
  getRawAuditClip, addWaitTime,
} from 'actions/audit';
import {
  Spinner, H1, H3, H4, Callout, Button, Drawer, Classes,
  Icon, Tag,
} from '@blueprintjs/core';
import Clip from './site_audit';

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString(length) {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const zpad = (stra, num = 2, pad = '0') => {
  let str = `${stra}`;
  while (str.length < num) {
    str = pad + str;
  }
  return str;
};


const tc = (seconds) => {
  const MINUTE = 60;
  const HOUR = 60 * 60;

  // eslint-disable-next-line no-bitwise
  const ss = ~~seconds % MINUTE;
  // eslint-disable-next-line no-bitwise
  const ms = ~~((seconds - ~~seconds) * 1000);
  // eslint-disable-next-line no-bitwise
  const mm = ~~((seconds % HOUR) / MINUTE);
  // eslint-disable-next-line no-bitwise
  const hh = ~~(seconds / HOUR);

  return `${zpad(hh)}:${zpad(mm)}:${zpad(ss)}:${zpad(ms, 3)}`;
};
const tagIntent = (tag, isStart) => {
  if (tag.id) {
    return 'success';
  }
  if (isStart) {
    if (tag.start_second) {
      return 'primary';
    }
    return undefined;
  }
  if (tag.end_second) {
    return 'primary';
  }
  return undefined;
};


class ClipPage extends Component {
  constructor(props) {
    super(props);
    this.active_id = 0;
    this.dispatchingRawClips = false;
    this.state = {
      fps: 0,
      showHelp: false,
      saving: false,
      site_id: null,
      cam_id: null,
      clip_id: null,
      site_cameras: null,
      cam_clips: null,
      tags: [],
      filter_start_date: null,
      filter_end_date: null,
    };
  }

  componentDidMount() {
    const tags_string = window.localStorage.getItem('tags');

    if (tags_string) {
      try {
        const tags = JSON.parse(tags_string);
        this.setState({ tags });
      } catch (e) {
        console.log('error parsing tags', e);
      }
    }

    this.getData();
  }

  @autobind
  async getData() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const site_id = id;
    this.setState({ site_id });
    const site_devices_payload = await dispatch(getAuditSiteDevices(site_id));
    const site_devices = site_devices_payload.payload.data.content;
    const site_cameras = site_devices.filter(x => x.device.type.toLowerCase().includes('camera'));
    const cam_id = site_cameras[0].device.device_identifier;

    const cam_clips_payload = await dispatch(getClipsByCamera(cam_id));
    const cam_clips_unsorted = cam_clips_payload.payload.data.content;
    const cam_clips = [...cam_clips_unsorted].sort(
      (a, b) => DateTime.fromISO(a.start_time) - DateTime.fromISO(b.start_time),
    );

    let clip_id = null;// qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).clip_id;
    if (!clip_id) {
      clip_id = cam_clips[0].id;
    }
    this.setState({
      site_id,
      cam_id,
      clip_id,
      site_cameras,
      cam_clips,

    });

    document.addEventListener('keydown', this.handleKeyPress);
  }

  @autobind
  get_active_id() {
    return this.active_id;
  }

  @autobind
  set_zero() {
    this.active_id = 0;
    this.forceUpdate();
  }

    @autobind
  set_one() {
    this.active_id = 1;
    this.forceUpdate();
  }

  @autobind
    getTags(u_id) {
    // return all tags filtered by  u_id
      const { tags } = this.state;
      return tags.filter(x => x.u_id === u_id);
    }

    @autobind
  get_filter_end_date() {
    const { filter_end_date } = this.state;
    return filter_end_date;
  }

    @autobind
    get_filter_start_date() {
      const { filter_start_date } = this.state;
      return filter_start_date;
    }

    @autobind
    get_filter_dates() {
      const { filter_start_date, filter_end_date } = this.state;
      return [filter_start_date, filter_end_date];
    }

    @autobind
    async dispatchGetRawAuditClip(clipID) {
      const { dispatch } = this.props;
      while (this.dispatchingRawClips) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise(r => setTimeout(r, 100));
      }
      this.dispatchingRawClips = true;
      const response = await dispatch(getRawAuditClip(clipID));
      this.dispatchingRawClips = false;
      return response;
    }

  @autobind
    handleHelp() {
      this.setState({ showHelp: true });
    }

  @autobind
  handleCloseHelp() {
    this.setState({ showHelp: false });
  }

  @autobind
  handleTag(e, id) {
    const { tags } = this.state;
    const currentTag = tags.find(x => x.order === parseInt(id, 10));
    currentTag.id = e.target.value;
    this.setState({ tags });
    window.localStorage.setItem('tags', JSON.stringify(tags));
  }


  @autobind
  async handleSaveGroundTruth() {
    // this.save_wait_time("groundTruth");
    const { tags } = this.state;
    if (tags.length) {
      const valid = tags.every(x => x.clip && x.site && x.track_id && x.id && x.userId);
      if (!valid) {
        AppToaster.show({
          intent: 'danger',
          message: <span>Tag entry incomplete</span>,
          icon: 'cross',
        });
        return;
      }
    }
    if (tags.length < 2) {
      AppToaster.show({
        intent: 'danger',
        message: <span>Must have at least 2 tags</span>,
        icon: 'cross',
      });
      return;
    }

    // const { audit, file_name, camera_serial } = clip.data;
    // check if ther is a tag with id "entry" and "exit"
    // sort tags by order
    tags.sort((a, b) => a.order - b.order);
    // first tag should be entry last tag should be exit
    if (tags[0].id !== 'Entry') {
      AppToaster.show({
        intent: 'danger',
        message: <span>First Tag Must be Entry</span>,
        icon: 'cross',
      });
      return;
    }
    // there should be no duplicate tag ids
    const tagIds = tags.map(x => x.id);
    const tagIdsSet = new Set(tagIds);
    if (tagIds.length !== tagIdsSet.size) {
      AppToaster.show({
        intent: 'danger',
        message: <span>Tags must be unique</span>,
        icon: 'cross',
      });
      return;
    }
    // tag ids should start from Entry, Id Check, Redress, Exit. with possible gaps
    // check if tag ids are in order
    let valid = true;
    let tagIdsOrdered = ['Entry', 'Id Check', 'Redress', 'Exit'];
    tags.forEach((tag) => {
      // check if tag id in tagIdsOrdered
      const index = tagIdsOrdered.indexOf(tag.id);
      // if index greater than 0 then remove tagIdsOrdered[index] and tags before index
      if (index >= 0) {
        tagIdsOrdered = tagIdsOrdered.slice(index + 1);
      } else {
        valid = false;
      }
    });
    if (!valid) {
      AppToaster.show({
        intent: 'danger',
        message: <span>Tags must be in order</span>,
        icon: 'cross',
      });
      return;
    }

    this.setState({ saving: true });
    // tags.map(x => this.save_wait_time(x))
    const results = await Promise.all(tags.map(x => this.save_wait_time(x)));
    // chec kif false in results
    if (!results.includes(false)) {
      AppToaster.show({
        intent: 'success',
        message: <span>Tags Saved</span>,
        icon: 'tick',
      });
      this.setState({ saving: false, tags: [] });
      window.localStorage.setItem('tags', JSON.stringify([]));
    }
    this.setState({ saving: false });
  }

  @autobind
  async save_wait_time(tag) {
    const { dispatch } = this.props;
    const form = {
      track_id: tag.track_id,
      clip_id: tag.clip,
      epoch_time: tag.time,
      zone_id: tag.site,
      tag: tag.id,
      user_id: tag.userId,
    };
    try {
      const result = await dispatch(addWaitTime(form));

      if (result.type !== 'admin/ADD_WAIT_TIME_SUCCESS') {
        AppToaster.show({
          intent: 'danger',
          message:
  <span>
    {tag.id}
    {' '}
Tag failed to save
  </span>,
          icon: 'cross',
        });
        return false;
      }
      return true;
    } catch (error) {
      AppToaster.show({
        intent: 'danger',
        message:
  <span>
    {tag.id}
    {' '}
Tag failed to save
  </span>,
        icon: 'cross',
      });
      return false;
    }
  }

  @autobind
  handleRemoveTag(tag) {
    const { tags } = this.state;
    let new_tags;
    if (tag.start) {
      new_tags = [...tags].filter(x => x.track_id !== tag.track_id);
      this.setState({ tags: new_tags });
    } else {
      new_tags = [...tags].filter(x => x.order !== tag.order);
      this.setState({ tags: new_tags });
    }
    window.localStorage.setItem('tags', JSON.stringify(new_tags));
  }


    @autobind
  addTag(tag) {
    const { tags } = this.state;
    const { userId } = this.props;
    let start_time = DateTime.fromISO(tag.clip_start_time);
    start_time = start_time.plus({ seconds: tag.time });
    tag.full_time = start_time;
    const currentTag = _.maxBy(tags, x => x.order);
    if (tag.start === true) {
      if (currentTag) {
        AppToaster.show({
          intent: 'danger',
          message: <span>Save or Delete Current Tag</span>,
          icon: 'cross',
        });
        return;
      }
      tag.track_id = generateString(16);
    } else {
      if (!currentTag) {
        AppToaster.show({
          intent: 'danger',
          message: <span>No Current Tag</span>,
          icon: 'cross',
        });
        return;
      } if (currentTag.full_time > tag.full_time) {
        AppToaster.show({
          intent: 'danger',
          message: <span>Tag Time Must Be After Current Tag</span>,
          icon: 'cross',
        });
        return;
      }
      tag.track_id = currentTag.track_id;
    }
    tag.userId = userId;
    tag.order = currentTag ? currentTag.order + 1 : 1;
    // tag.order = currentTag.order + 1 || 1;
    this.setState({ tags: [...tags, tag] });
    window.localStorage.setItem('tags', JSON.stringify([...tags, tag]));
  }

    @autobind
    handleStartDateChange(date) {
      this.setState({ filter_start_date: date });
    }

  @autobind
    handleEndDateChange(date) {
      this.setState({ filter_end_date: date });
    }

    @autobind
  renderTag(tag) {
    return (
      <div
        style={{
          display: 'flex', alignItems: 'center', marginBottom: 5,
        }}
        key={tag.order}
      >
        <div />

        <div>
          <Tag style={{ cursor: 'pointer' }} intent={tagIntent(tag, true)} onClick={() => this.jumpToTime(tag.time)}>
            {(tag.start ? 'Start: ' : '') + tc(tag.time)}
          </Tag>
        </div>
        <div style={{ margin: '0px 10px' }}>
          <div className="bp3-select">
            <select value={tag.id} onChange={e => this.handleTag(e, tag.order)}>
              <option value="">--</option>
              <option value="Entry">Entry</option>
              <option value="Id Check">Id Check</option>
              <option value="Redress">Redress</option>
              <option value="Exit">Exit</option>
            </select>
          </div>
        </div>
        <div>
          <Button intent="danger" outlined onClick={() => this.handleRemoveTag(tag)} icon="cross" />
        </div>
      </div>
    );
  }

    render() {
      const { match } = this.props;
      const { id } = match.params;
      const {
        saving, cam_id, clip_id, site_cameras, cam_clips,
        showHelp, fps, tags, site_id, filter_end_date, filter_start_date,
      } = this.state;
      if (site_cameras && cam_clips && clip_id && cam_id) {
        const p_funcs = {
          get_active_id: this.get_active_id,
          get_tags: this.getTags,
          add_tag: this.addTag,
          get_filter_dates: this.get_filter_dates,
          dispatchGetRawAuditClip: this.dispatchGetRawAuditClip,
        };
        return (
          <div>
            <div className="column p-b-none">
              <H1>
                  Site:&nbsp;
                <Link to={`/audits/waittimes/summary/${site_id}`}>
                  {site_id}
                </Link>

                    &nbsp;
                <Button intent="success" className="m-l-sm" loading={saving} onClick={this.handleSaveGroundTruth}>Save</Button>
                <Button intent="primary" className="m-l-sm" onClick={this.handleHelp}>Help</Button>
                    &nbsp;
                <div style={{ float: 'right' }}>
                  {/* <Card> */}
                  <table className="bp3-html-table bp3-interactive bp3-html-table-condensed">
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <DateInput
                            formatDate={date => date.toLocaleString()}
                            onChange={this.handleStartDateChange}
                            parseDate={() => 'test'}
                            timePickerProps={{ precision: 'minutes' }}
                            placeholder="MM/DD/YYYY"
                            value={filter_start_date}
                          />
                        </td>
                        <td>
                          <DateInput
                            formatDate={date => date.toLocaleString()}
                            onChange={this.handleEndDateChange}
                            parseDate={str => new Date(str)}
                            timePickerProps={{ precision: 'minutes' }}
                            placeholder="MM/DD/YYYY"
                            value={filter_end_date}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* </Card>  */}
                </div>

              </H1>

              <div style={{ display: 'inline-block', float: 'right' }}>
                  &nbsp;
                <div style={{ float: 'left' }} />
              </div>
              { fps === -1 && (
              <Callout
                intent="danger"
                className="m-b-md"
                title="Media metadata failed to load; frame stepping disabled"
              />
              )}
            </div>
            <div style={{ marginBottom: 20 }}>
              <div className="flex-space-between-container" style={{ marginBottom: 5 }}>
                <div>
                  <H4>
                    <Icon icon="tag" />
                              &nbsp;
                              Tags
                  </H4>
                </div>

              </div>

              {tags.length > 0 ? !!tags.length && tags.map(this.renderTag) : <div> &nbsp;</div>}
            </div>
            <div className="columns">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div className="column is-3-eighths" onClick={this.set_zero} style={this.active_id === 0 ? { background: '#f19e38' } : { background: '#33404c' }}>
                <Clip
                  u_id={0}
                  id={id}
                  site_cameras={site_cameras}
                  cam_clips={cam_clips}
                  clip_id={clip_id}
                  cam_id={cam_id}
                  p_funcs={p_funcs}
                />
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div className="column is-3-eighths" onClick={this.set_one} style={this.active_id === 1 ? { background: '#f19e38' } : { background: '#33404c' }}>
                <Clip
                  u_id={1}
                  id={id}
                  site_cameras={site_cameras}
                  cam_clips={cam_clips}
                  clip_id={clip_id}
                  cam_id={cam_id}
                  p_funcs={p_funcs}
                />
              </div>
            </div>
            <Drawer
              icon="info-sign"
              onClose={this.handleCloseHelp}
              title="Help"
              isOpen={showHelp}
              size={Drawer.SIZE_SMALL}
            >
              <div className={Classes.DRAWER_BODY}>
                <div className={`bp3-running-text ${Classes.DIALOG_BODY}`}>
                  <H3>Shortcuts</H3>
                  <ul className="bp3-list">
                    <li>
                      <code className="bp3-code">n</code>
                  :
                  Next Clip
                    </li>
                    <li>
                      <code className="bp3-code">b</code>
                  :
                  Previous Clip
                    </li>
                    <li>
                      <code className="bp3-code">o</code>
                  :
                  Increment In
                    </li>
                    <li>
                      <code className="bp3-code">p</code>
                  :
                  Increment Out
                    </li>
                    <li>
                      <code className="bp3-code">ctrl + o</code>
                  :
                  Decrement In
                    </li>
                    <li>
                      <code className="bp3-code">ctrl + p</code>
                  :
                  Decrement Out
                    </li>
                    <li>
                      <code className="bp3-code">r</code>
                  :
                  Reset In/Out Count
                    </li>
                    <li>
                      <code className="bp3-code">[</code>
                  :
                  Decrease Playback Speed
                    </li>
                    <li>
                      <code className="bp3-code">]</code>
                  :
                  Increase Playback Speed
                    </li>
                    <li>
                      <code className="bp3-code">Backspace</code>
                  :
                  Reset Playback Speed
                    </li>
                    <li>
                      <code className="bp3-code">,</code>
                  :
                  Step Back One Frame
                    </li>
                    <li>
                      <code className="bp3-code">.</code>
                  :
                  Step Forward One Frame
                    </li>
                    <li>
                      <code className="bp3-code">s</code>
                  :
                  Download screenshot
                    </li>
                    <li>
                      <code className="bp3-code">m</code>
                  : New Tag Start Time
                    </li>
                    <li>
                      <code className="bp3-code">ctrl + m</code>
                  : Close Tag End Time
                    </li>
                  </ul>
                  <H3>Errors</H3>

                </div>
              </div>
              <div className={Classes.DRAWER_FOOTER} />
            </Drawer>
          </div>
        );
      }
      return (

        <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>

      );
    }
}

ClipPage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  userId: PropTypes.number,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
  userId: state.currentUser.profile.id,
  siteDevices: state.siteDevices,
  cameraClips: state.cameraClips,
}))(ClipPage);
