/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  H3, Button, Checkbox,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { AppToaster } from 'components/Toaster';
import {
  FormSelectInput, DateTimeInput, CheckboxInput, DayInput, TimePickerInput,
} from 'components/inputs';
import { TimePrecision } from '@blueprintjs/datetime';
import { startCameraUpload } from 'actions/device';
import ViewUploads from '../Devices/device/record/view';
import { startRecurringCameraUpload } from '../../actions/device';
import { QUERY_DATE_FMT } from '../../constants';
// import { connect } from 'react-redux';

const REQUIRED = 'This field is required';

class UploadRecording extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      serial: '',
      saved: false,
      recurring: false,
    };
  }

  @autobind
  setContent(content, serial) {
    this.setState({ content, serial }, () => window.scrollTo(0, document.body.scrollHeight));
  }

  @autobind
  setIntent(targetContent, targetSerial) {
    const { content, serial } = this.state;
    if (targetContent === content && targetSerial === serial) {
      return 'warning';
    }
    return 'primary';
  }

  @autobind
  handleSave(values) {
    const { dispatch, recordingDevices } = this.props;
    const { selected } = this.state;
    const {
      start_time, end_time, region, scheduled_start,
    } = values;
    const not_recording = [];
    selected.forEach((camera_serial) => {
      if (recordingDevices[camera_serial] && recordingDevices[camera_serial] !== '') {
        const data = {
          camera_serial,
          start_time: moment(start_time).utc().format(),
          end_time: moment(end_time).utc().format(),
          region,
          scheduled_start,
        };
        dispatch(startCameraUpload(recordingDevices[camera_serial], data))
          .then((action) => {
            if (action.payload.data && action.payload.data.content
              && action.payload.data.content.upload_count) {
              AppToaster.show({
                icon: 'tick',
                message: <span>{`Uploading ${action.payload.data.content.upload_count} Files`}</span>,
                intent: 'success',
              });
            }
          }).then(() => this.setState({ saved: true }));
      } else {
        not_recording.push(camera_serial);
      }
    });
    if (not_recording.length > 0) {
      AppToaster.show({
        icon: 'cross',
        message: <span>{`Cameras ${not_recording.join(', ')} are not recording`}</span>,
        intent: 'danger',
      });
    }
  }

  @autobind
  handleSaveRecurring(values) {
    const { dispatch, recordingDevices } = this.props;
    const { selected } = this.state;
    const {
      start_time, end_time, region, start_date, end_date,
    } = values;
    const not_recording = [];
    selected.forEach((camera_serial) => {
      if (recordingDevices[camera_serial] && recordingDevices[camera_serial] !== '') {
        const data = {
          camera_serial,
          start_date: moment(start_date, QUERY_DATE_FMT).utc().startOf('day').format(),
          end_date: moment(end_date, QUERY_DATE_FMT).utc().startOf('day').format(),
          start_time: start_time.toString().substr(16, 8),
          end_time: end_time.toString().substr(16, 8),
          region,
          scheduled_start: true,
        };
        dispatch(startRecurringCameraUpload(recordingDevices[camera_serial], data))
          .then((action) => {
            if (action.payload.data && action.payload.data.content
              && action.payload.data.content.upload_count) {
              AppToaster.show({
                icon: 'tick',
                message: <span>{`Uploading ${action.payload.data.content.upload_count} Files`}</span>,
                intent: 'success',
              });
            }
          }).then(() => this.setState({ saved: true }));
      } else {
        not_recording.push(camera_serial);
      }
    });
    if (not_recording.length > 0) {
      AppToaster.show({
        icon: 'cross',
        message: <span>{`Cameras ${not_recording.join(', ')} are not recording`}</span>,
        intent: 'danger',
      });
    }
  }

  @autobind
  selectAll(e) {
    const { serials } = this.props;
    if (e.target.checked) {
      this.setState({ selected: [...serials.map(x => x.device.device_identifier)], saved: false });
    } else {
      this.setState({ selected: [], saved: false });
    }
  }

  @autobind
  handleRecurring() {
    const { recurring } = this.state;
    this.setState({ recurring: !recurring });
  }

  @autobind
  selectCamera(e) {
    const { selected } = this.state;
    if (e.target.checked) {
      this.setState({ selected: [...selected, e.target.value], saved: false });
    } else {
      const index = selected.indexOf(e.target.value);
      let tmp = [...selected];
      tmp = tmp.splice(index, 1);
      this.setState({ selected: tmp, saved: false });
    }
  }

  @autobind
  renderDevice(d) {
    const { selected } = this.state;
    return (
      <tr key={d.id}>
        <td><Checkbox value={d.device.device_identifier} checked={selected.indexOf(d.device.device_identifier) > -1} onChange={this.selectCamera} /></td>
        <td>{d.device.device_identifier}</td>
        <td>{d.device.name}</td>
        <td>
          <Button
            outlined
            intent={this.setIntent('view', d.device.device_identifier)}
            icon="eye-open"
            small
            onClick={() => this.setContent('view', d.device.device_identifier)}
          >
            Uploads
          </Button>
        </td>
      </tr>
    );
  }

  @autobind
  renderTable(d_serials) {
    const { selected } = this.state;
    return (
      <table className="bp3-html-table bp3-html-table-striped">
        <thead>
          <tr>
            <th><Checkbox onChange={this.selectAll} checked={selected.length === d_serials.length} /></th>
            <th>Device ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {(d_serials || []).map(this.renderDevice)}
        </tbody>
      </table>
    );
  }

  render() {
    const {
      submitting, handleSubmit, serials, recordingDevices,
    } = this.props;
    const {
      content, serial, saved, recurring,
    } = this.state;
    return (
      <div style={{ marginBottom: 40 }}>
        <H3 style={{ display: 'inline-block' }}>New Upload</H3>
        <div style={{ display: 'inline-block', marginLeft: 15 }}>
          <Button icon="record" small intent={recurring ? 'success' : 'primary'} onClick={this.handleRecurring} style={{ marginBottom: 7 }}>
            Recurring
          </Button>
        </div>
        <div className="column">
          {this.renderTable(serials)}
        </div>
        { !recurring && (
          <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
            <div className="columns" style={{ marginBottom: 20 }}>
              <div className="column">
                <Field
                  component={DateTimeInput}
                  name="start_time"
                  label="Start Time"
                  fill
                />
              </div>
              <div className="column">
                <Field
                  component={DateTimeInput}
                  name="end_time"
                  label="End Time"
                  fill
                />
              </div>
              <div className="column">
                <Field
                  component={FormSelectInput}
                  name="region"
                  label="Region"
                >
                  <option value="">Select a Region</option>
                  <option value="US">US</option>
                  <option value="EU">EU</option>
                </Field>
              </div>
              <div className="column" style={{ alignSelf: 'center' }}>
                <Field
                  component={CheckboxInput}
                  name="scheduled_start"
                  label="Scheduled"
                />
              </div>
              <div className="column" style={{ alignSelf: 'center' }}>
                <Button intent="primary" style={{ float: 'right' }} loading={submitting} disabled={saved} type="submit" icon="tick">
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
        { recurring && (
          <form onSubmit={handleSubmit(this.handleSaveRecurring)} autoComplete="off">
            <H3>New Recurring Upload (In UTC!!!)</H3>
            <div className="columns" style={{ marginBottom: 20 }}>
              <div className="column">
                <Field
                  component={DayInput}
                  name="start_date"
                  label="Start Date"
                  fill
                  hideTime
                  dateFormat="LL"
                />
                <Field
                  component={TimePickerInput}
                  name="start_time"
                  label="Start Time"
                  fill
                  precision={TimePrecision.MINUTE}
                />
              </div>
              <div className="column">
                <Field
                  component={DayInput}
                  name="end_date"
                  label="End Date"
                  fill
                  hideTime
                  dateFormat="LL"
                />
                <Field
                  component={TimePickerInput}
                  name="end_time"
                  label="End Time"
                  fill
                  precision={TimePrecision.MINUTE}
                />
              </div>
              <div className="column">
                <Field
                  component={FormSelectInput}
                  name="region"
                  label="Region"
                >
                  <option value="">Select a Region</option>
                  <option value="US">US</option>
                  <option value="EU">EU</option>
                </Field>
              </div>
              <div className="column" style={{ alignSelf: 'center' }}>
                <Button intent="primary" style={{ float: 'right' }} loading={submitting} disabled={saved} type="submit" icon="tick">
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
        <div>
          {content === 'view' && <ViewUploads device={recordingDevices[serial]} serial={serial} />}
        </div>
      </div>
    );
  }
}

UploadRecording.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  serials: PropTypes.array,
  dispatch: PropTypes.func,
  recordingDevices: PropTypes.object,
};

export default reduxForm({
  form: 'camera_upload',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.start_date) {
      errors.start_date = REQUIRED;
    }
    if (!values.end_date) {
      errors.end_date = REQUIRED;
    }
    if (!values.start_time) {
      errors.start_time = REQUIRED;
    }
    if (!values.end_time) {
      errors.end_time = REQUIRED;
    }
    if (!values.region) {
      errors.region = REQUIRED;
    }
    if (values.start_time && values.end_time && !values.start_date && !values.end_date
      && moment(values.end_time).isBefore(values.start_time)) {
      errors.end_time = 'End Time must be after Start Time';
    }
    if (values.start_date && values.end_date
      && moment(values.end_date).isBefore(values.start_date)) {
      errors.end_time = 'End Date must be after Start Date';
    }
    return errors;
  },
})(UploadRecording);
