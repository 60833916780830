import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormGroup, Button, MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

const RoleSelect = ({ roles, input }) => {
  const { value, onChange } = input;

  const renderRole = (role, { handleClick, modifiers }) => {
    const selectedId = value.role_id;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={role.role_name}
        key={role.role_id}
        icon={selectedId === role.role_id ? 'tick' : 'blank'}
        onClick={handleClick}
        text={role.role_name}
      />
    );
  };

  return (
    <FormGroup
      label="Role"
      labelFor="role"
      className="inline-flex"
    >
      <Select
        resetOnSelect
        items={roles.data || []}
        itemRenderer={renderRole}
        noResults={<MenuItem disabled text="No results." />}
        activeItem={value}
        onItemSelect={onChange}
        popoverProps={{ minimal: true }}
      >
        <Button
          text={value ? value.role_name : 'Select a role'}
          fill
          rightIcon="caret-down"
        />
      </Select>
    </FormGroup>
  );
};

RoleSelect.propTypes = {
  roles: PropTypes.object,
  input: PropTypes.object,
};

export default connect(state => ({
  roles: state.roles,
}))(RoleSelect);
