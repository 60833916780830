/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm, FieldArray,
} from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import moment from 'moment';
import {
  TextInput, FormSelectInput, DateTimeInput,
} from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import { createReidTask, getReidTasks } from 'actions/reid';

class ReidTask extends Component {
  componentDidMount() {
  }

  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    const {
      title, config, model_id, feature_model_id, region, entry, exit, fast_mot_version,
    } = values;
    let entryMap;
    let exitMap;
    const data = {
      title,
      config,
      model_id,
      feature_model_id,
      region,
      fast_mot_version,
      distance_threshold: parseFloat(values.distance_threshold),
      lock_interval: parseInt(values.lock_interval, 10),
    };
    if ((entry || []).length > 0) {
      entryMap = entry.map(x => ({
        camera_serial: x.camera_serial,
        start_time: moment(x.start_time).utc().format(),
        end_time: moment(x.end_time).utc().format(),
      }));
    }

    if ((exit || []).length > 0) {
      exitMap = exit.map(x => ({
        camera_serial: x.camera_serial,
        start_time: moment(x.start_time).utc().format(),
        end_time: moment(x.end_time).utc().format(),
      }));
    }

    data.clips_input = { entry: entryMap, exit: exitMap };

    return dispatch(createReidTask(data)).then(action => dispatch(push('/reid'))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            : Reid task created
          </span>
        ),
        intent: 'success',
      }))
      .finally(() => dispatch(getReidTasks()));
  }

  @autobind
  renderEntry({ fields }) {
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Entry Clips</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((clips, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '30%' }}>
                  <Field
                    component={TextInput}
                    name={`${clips}.camera_serial`}
                    type="text"
                    label="Camera Serial"
                  />
                </div>
                <div style={{ flexGrow: 1, marginLeft: 10 }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.start_time`}
                    label="Start Time"
                    fill
                  />
                </div>
                <div style={{ flexGrow: 1, margin: '0px 10px' }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.end_time`}
                    label="End Time"
                    fill
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  @autobind
  renderExit({ fields }) {
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Exit Clips</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((clips, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '30%' }}>
                  <Field
                    component={TextInput}
                    name={`${clips}.camera_serial`}
                    type="text"
                    label="Camera Serial"
                  />
                </div>
                <div style={{ flexGrow: 1, marginLeft: 10 }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.start_time`}
                    label="Start Time"
                    fill
                  />
                </div>
                <div style={{ flexGrow: 1, margin: '0px 10px' }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.end_time`}
                    label="End Time"
                    fill
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  render() {
    const {
      submitting, handleSubmit, services, models,
    } = this.props;
    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create Reid Task
            </H1>
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="title"
                  label="Title"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  name="model_id"
                  placeholder=""
                  component={FormSelectInput}
                  label="Model"
                >
                  <option value="">Select a Model</option>
                  {models.data.map(x => (
                    <option key={x.id} value={x.id}>
                      {`${x.id} (${x.model_type})`}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="column">
                <Field
                  name="feature_model_id"
                  placeholder=""
                  component={FormSelectInput}
                  label="Feature Model"
                >
                  <option value="">Select a Feature Model</option>
                  {models.data.map(x => (
                    <option key={x.id} value={x.id}>
                      {`${x.id} (${x.model_type})`}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="column">
                <Field
                  name="fast_mot_version"
                  placeholder=""
                  component={FormSelectInput}
                  label="Fast MOT Version"
                >
                  <option value="" disabled>Select a Version</option>
                  {services.data
                    .filter(s => s.name === 'fast-mot')
                    .map(s => <option key={s.version} value={s.version}>{`v${s.version}`}</option>)
                    }
                </Field>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  name="config"
                  label="Config"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  component={TextInput}
                  name="distance_threshold"
                  label="Distance Threshold"
                  type="number"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-2">
                <Field
                  component={TextInput}
                  type="number"
                  name="lock_interval"
                  label="Timeout (min)"
                />
              </div>
              <div className="column">
                <Field
                  name="region"
                  placeholder=""
                  component={FormSelectInput}
                  label="Region"
                >
                  <option value="">Select a Region: (US/EU)</option>
                  <option value="US">US</option>
                  <option value="EU">EU</option>
                </Field>
              </div>
            </div>

            <div className="columns">
              <div className="column" style={{ borderRight: '2px solid #26343E' }}>
                <FieldArray name="entry" component={this.renderEntry} />
              </div>
              <div className="column" style={{ borderRight: '2px solid #26343E' }}>
                <FieldArray name="exit" component={this.renderExit} />
              </div>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ReidTask.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  services: PropTypes.object,
  models: PropTypes.object,
  // // eslint-disable-next-line react/no-unused-prop-types
  // initialValues: PropTypes.object,
};


const REQUIRED = 'This field is required.';

export default connect((state, props) => ({
  services: state.iap.services,
  models: state.models,
  initialValues: props.location.state && props.location.state.initialValues
    ? props.location.state.initialValues
    : {
      fast_mot_version: state.iap.services.data.filter(s => s.name === 'fast-mot')[0].version,
    },
}))(reduxForm({
  form: 'create-reid',
  validate: (values) => {
    const errors = {};
    const entryErrors = [];
    const exitErrors = [];
    if (!values.model_id) {
      errors.model_id = REQUIRED;
    }
    if (!values.feature_model_id) {
      errors.feature_model_id = REQUIRED;
    }
    if (!values.fast_mot_version) {
      errors.fast_mot_version = REQUIRED;
    }
    if (!values.region) {
      errors.region = REQUIRED;
    }
    if (!values.title) {
      errors.title = REQUIRED;
    }
    if (!values.lock_interval) {
      errors.lock_interval = REQUIRED;
    }
    if (!values.distance_threshold) {
      errors.distance_threshold = REQUIRED;
    }
    if ((values.entry || []).length > 0) {
      values.entry.forEach((t, i) => {
        const memberErrors = {};
        if (t && t.start_time && t.end_time) {
          if (!moment(t.end_time).isAfter(moment(t.start_time))) {
            memberErrors.end_time = 'Must be after start time';
            entryErrors[i] = memberErrors;
          }
        }
      });
    }
    if ((values.exit || []).length > 0) {
      values.exit.forEach((t, i) => {
        const memberErrors = {};
        if (t && t.start_time && t.end_time) {
          if (!moment(t.end_time).isAfter(moment(t.start_time))) {
            memberErrors.end_time = 'Must be after start time';
            exitErrors[i] = memberErrors;
          }
        }
      });
    }
    if (entryErrors.length > 0) {
      errors.entry = entryErrors;
    }
    if (exitErrors.length > 0) {
      errors.exit = exitErrors;
    }
    return errors;
  },
})(ReidTask));
