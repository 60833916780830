import qs from 'query-string';
import {
  GET_DEVICES, GET_DEVICE, SAVE_DEVICE, REBOOT_DEVICE, ASSIGN_DEVICE, UNASSIGN_DEVICE,
  BOSUN_METRICS_DEVICE, BEACON_METRICS_DEVICE, DEVICE_MAC, GET_IAP_VERSIONS, GET_ALL_DEVICES,
  IAP_METRICS_DEVICE, GET_VISION_PARAMETERS, PATCH_VISION_PARAMETERS, FETCH_IMAGE, REBOOT_V3,
  GET_INS_OUTS, GET_FPS, GET_CAMERA_RESOLUTION, ADD_CAMERA, PATCH_ORG_DEVICES,
  POST_IAP_CAMERA, PATCH_IAP_CAMERA, DELETE_IAP_CAMERA, GET_HOMOGRAPHY, POST_HOMOGRAPHY,
  DELETE_HOMOGRAPHY, SCAN_AVAILABLE_CAMERAS, START_CAMERA_RECORDING,
  STOP_CAMERA_RECORDING, UPLOAD_RECORDING, VIEW_EXISTING_UPLOADS, GET_CAMERA_MODELS,
  GET_PEPLINK_DEVICES, GET_SIM_POOLS, ADD_PEPLINK, REBOOT_PEPLINK, GET_PEPLINK,
  GET_PEPLINK_BANDWIDTH_USAGE, GET_PEPLINK_CLIENTS, GET_PEPLINK_LOGS, GET_CLIPS_BY_TIME,
  GET_CAMERA_COUNTING_LINES, GET_COUNTING_LINES_BY_CAMERA_LINE_ID, CREATE_COUNTING_LINE,
  UPDATE_COUNTING_LINE_BY_LINE_ID, DELETE_COUNTING_LINE_BY_LINE_ID, DELETE_EXISTING_UPLOAD,
  GET_LINES_INS_OUTS, RECURRING_UPLOAD_RECORDING, VALIDATE_HOMOGRAPHY, GET_CLIPS_BY_CAMERA,
} from '../constants';

export const getDevices = (query, assigned, monitored, organization, from, limit, version) => ({
  type: GET_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices?${qs.stringify({
        query, assigned, organization, from, limit, status: 'true', monitored, version,
      })}`,
    },
  },
});

export const getDeviceVersions = () => ({
  type: GET_IAP_VERSIONS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/devices/iap/versions',
    },
  },
});

export const getDevice = id => ({
  type: GET_DEVICE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices/${id}?status=true`,
    },
  },
});

export const saveDevice = (id, data) => ({
  type: SAVE_DEVICE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/devices/${id}`,
    },
  },
});

export const assignDevice = (id, orgId) => ({
  type: ASSIGN_DEVICE,
  payload: {
    request: {
      method: 'post',
      data: {
        device_id: id,
      },
      url: `/v1/admin/organizations/${orgId}/devices`,
    },
  },
});

export const unassignDevice = (id, orgId, force) => ({
  type: UNASSIGN_DEVICE,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/admin/organizations/${orgId}/devices/${id}?${qs.stringify({ force })}`,
    },
  },
});

export const rebootDevice = id => ({
  type: REBOOT_DEVICE,
  payload: {
    request: {
      method: 'post',
      url: `/v1/admin/devices/${id}/reboot`,
    },
  },
});

export const deviceBosunMetrics = data => ({
  type: BOSUN_METRICS_DEVICE,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/metrics/bosunMonitor',
    },
  },
});

export const deviceIapMetrics = (deviceId, start, end) => ({
  type: IAP_METRICS_DEVICE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/${deviceId}/metrics?${qs.stringify({ start, end })}`,
    },
  },
});

export const deviceBeaconMetrics = (id, start, end) => ({
  type: BEACON_METRICS_DEVICE,
  payload: {
    request: {
      method: 'post',
      data: {
        deviceIds: [id],
        startTime: start,
        endTime: end,
      },
      url: '/v1/metrics/macIdCount',
    },
  },
});

export const deviceMacAddress = id => ({
  type: DEVICE_MAC,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices/${id}/hardware/interfaces`,
    },
  },
});

export const getAllDevices = () => ({
  type: GET_ALL_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/devices',
    },
  },
});

export const getVisionParameters = deviceId => ({
  type: GET_VISION_PARAMETERS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices/${deviceId}/vision/configuration`,
    },
  },
});

export const patchVisionParameters = (deviceId, data) => ({
  type: PATCH_VISION_PARAMETERS,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/iap/${deviceId}/parameters`,
      data,
    },
  },
});

export const fetchImage = deviceId => ({
  type: FETCH_IMAGE,
  payload: {
    request: {
      method: 'post',
      url: `/v1/admin/devices/${deviceId}/camera/generate_preview`,
    },
  },
});

export const rebootV3 = deviceId => ({
  type: REBOOT_V3,
  payload: {
    request: {
      method: 'post',
      url: `/v1/iap/${deviceId}/reboot`,
    },
  },
});

export const getInsAndOuts = (
  serial, startTime, endTime, region, dimensions, metrics, source, line_id,
) => ({
  type: GET_INS_OUTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${serial}/axis/query?${qs.stringify({
        startTime,
        endTime,
        region,
        dimensions,
        metrics,
        source,
        line_id,
      })}`,
    },
  },
});

export const getInsAndOutsByLine = (
  serial, startTime, endTime, region, dimensions, metrics, source, aggregate,
) => ({
  type: GET_LINES_INS_OUTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${serial}/axis/lines/query?${qs.stringify({
        startTime,
        endTime,
        region,
        dimensions,
        metrics,
        source,
        aggregate,
      })}`,
    },
  },
});

export const getCameraFps = query => ({
  type: GET_FPS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/influxdb/query?${qs.stringify({
        db: 'lrm_iap',
        epoch: 'ms',
        q: query,
      })}`,
    },
  },
});

export const getCameraResolution = query => ({
  type: GET_CAMERA_RESOLUTION,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/influxdb/query?${qs.stringify({
        db: 'lrm_iap',
        epoch: 'ms',
        q: query,
      })}`,
    },
  },
});

export const addCamera = data => ({
  type: ADD_CAMERA,
  payload: {
    request: {
      method: 'post',
      url: '/v1/admin/devices',
      data,
    },
  },
});

export const patchOrgDevices = (orgId, deviceOrgId, data) => ({
  type: PATCH_ORG_DEVICES,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/admin/organizations/${orgId}/devices/${deviceOrgId}`,
      data,
    },
  },
});

export const postIapCamera = (deviceId, data) => ({
  type: POST_IAP_CAMERA,
  payload: {
    request: {
      method: 'post',
      url: `/v1/devices/${deviceId}/cameras`,
      data,
    },
  },
});

export const patchIapCamera = (deviceId, configId, data) => ({
  type: PATCH_IAP_CAMERA,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/devices/${deviceId}/cameras/${configId}`,
      data,
    },
  },
});

export const deleteIapCamera = (deviceId, configId) => ({
  type: DELETE_IAP_CAMERA,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/devices/${deviceId}/cameras/${configId}`,
    },
  },
});

export const getHomography = id => ({
  type: GET_HOMOGRAPHY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices/${id}/vision/homography`,
    },
  },
});

export const postHomography = (id, data) => ({
  type: POST_HOMOGRAPHY,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/admin/devices/${id}/vision/homography`,
    },
  },
});

export const validateHomography = (id, data, scale_factor, resolution, site_id) => ({
  type: VALIDATE_HOMOGRAPHY,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/admin/devices/${id}/camera/validate_homography?scale_factor=${scale_factor}&site_id=${site_id}&resolution=${resolution}`,
    },
  },
});

export const deleteHomography = id => ({
  type: DELETE_HOMOGRAPHY,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/admin/devices/${id}/vision/homography`,
    },
  },
});

export const getCameraRecordings = id => ({
  type: SCAN_AVAILABLE_CAMERAS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/${id}/scan`,
    },
  },
});

export const startCameraRecording = (id, data, override) => ({
  type: START_CAMERA_RECORDING,
  payload: {
    request: {
      method: 'post',
      url: `/v1/iap/${id}/record?override=${override}`,
      data,
    },
  },
});

export const stopCameraRecording = (id, serial) => ({
  type: STOP_CAMERA_RECORDING,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/iap/${id}/record/${serial}`,
    },
  },
});

export const viewExistingUploads = (id, serial) => ({
  type: VIEW_EXISTING_UPLOADS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/${id}/clips/${serial}/uploads`,
    },
  },
});

export const deleteExistingUpload = (id, serial, upload_id) => ({
  type: DELETE_EXISTING_UPLOAD,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/iap/${id}/clips/${serial}/uploads/${upload_id}`,
    },
  },
});

export const startCameraUpload = (id, data) => ({
  type: UPLOAD_RECORDING,
  payload: {
    request: {
      method: 'post',
      url: `/v1/iap/${id}/upload`,
      data,
    },
  },
});

export const startRecurringCameraUpload = (id, data) => ({
  type: RECURRING_UPLOAD_RECORDING,
  payload: {
    request: {
      method: 'post',
      url: `/v1/iap/${id}/upload/recurring`,
      data,
    },
  },
});

export const getCameraModels = serial => ({
  type: GET_CAMERA_MODELS,
  meta: {
    name: serial,
    actionKey: serial,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/devices/${serial}/vision/configuration`,
    },
  },
});

export const getAllPeplinkDevices = () => ({
  type: GET_PEPLINK_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/devices/peplinks',
    },
  },
});

export const getPeplinkSimPools = () => ({
  type: GET_SIM_POOLS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/devices/peplinks/pools',
    },
  },
});

export const createPeplink = data => ({
  type: ADD_PEPLINK,
  payload: {
    request: {
      method: 'post',
      url: '/v1/devices/peplinks',
      data,
    },
  },
});

export const rebootPeplink = id => ({
  type: REBOOT_PEPLINK,
  payload: {
    request: {
      method: 'post',
      url: `/v1/devices/peplinks/${id}/reboot`,
    },
  },
});

export const getPeplink = device_id => ({
  type: GET_PEPLINK,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/peplinks/${device_id}`,
    },
  },
});

export const getPeplinkBandwidthUsage = (
  peplinkId, dimension, startDate, endDate, wanId,
) => ({
  type: GET_PEPLINK_BANDWIDTH_USAGE,
  payload: {
    request: {
      method: 'get',
      url: `v1/devices/peplinks/${peplinkId}/bandwidth?type=${dimension}&start=${startDate}&end=${endDate}&wan_id=${wanId}`,
    },
  },
});

export const getPeplinkClients = peplinkId => ({
  type: GET_PEPLINK_CLIENTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/peplinks/${peplinkId}/clients`,
    },
  },
});

export const getPeplinkLogs = (
  peplinkId, startDate, endDate, dir,
) => ({
  type: GET_PEPLINK_LOGS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/peplinks/${peplinkId}/logs?start=${startDate}&end=${endDate}&dir=${dir}`,
    },
  },
});


export const getClipsByTime = (cameraSerial, startTime, endTime) => ({
  type: GET_CLIPS_BY_TIME,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/clips/${cameraSerial}/query?${qs.stringify({ startTime, endTime })}`,
    },
  },
});

export const getCountingLinesByCamera = cameraSerial => ({
  type: GET_CAMERA_COUNTING_LINES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${cameraSerial}/vision/counting_lines`,
    },
  },
});

export const getCountingLinesByCameraLineId = (cameraSerial, lineId) => ({
  type: GET_COUNTING_LINES_BY_CAMERA_LINE_ID,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${cameraSerial}/vision/counting_lines/${lineId}`,
    },
  },
});

export const createCountingLine = (cameraSerial, data) => ({
  type: CREATE_COUNTING_LINE,
  payload: {
    request: {
      method: 'post',
      url: `/v1/devices/${cameraSerial}/vision/counting_lines`,
      data,
    },
  },
});

export const updateCountingLineByLineId = (cameraSerial, lineId, data) => ({
  type: UPDATE_COUNTING_LINE_BY_LINE_ID,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/devices/${cameraSerial}/vision/counting_lines/${lineId}`,
      data,
    },
  },
});

export const deleteCountingLineByLineId = (cameraSerial, lineId) => ({
  type: DELETE_COUNTING_LINE_BY_LINE_ID,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/devices/${cameraSerial}/vision/counting_lines/${lineId}`,
    },
  },
});

export const getClipsByCamera = cameraSerial => ({
  type: GET_CLIPS_BY_CAMERA,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/_/clips/${cameraSerial}`,
    },
  },
});
