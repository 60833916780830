/* eslint-disable no-underscore-dangle, react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Field, reduxForm, FieldArray, formValueSelector,
} from 'redux-form';
import {
  H1, Button, Callout,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  TextInput, CheckboxInput, FormSelectInput, SelectInput, TextAreaInput,
} from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import semver from 'semver';
import { Link } from 'react-router-dom';

import { createRelease, editRelease, getRelease } from 'actions/iap';
import { getDevices } from 'actions/device';

class _DeviceSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleSearch = _.debounce(this.doHandleSearch, 100);
  }

  @autobind
  handleOpen() {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    dispatch(getDevices(
      undefined,
      undefined,
      undefined,
      undefined,
      0,
      10,
      undefined,
    )).then(() => this.setState({ loading: false }));
  }

  @autobind
  doHandleSearch(query) {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    dispatch(getDevices(
      query,
      undefined,
      undefined,
      undefined,
      0,
      10,
      undefined,
    )).then(() => this.setState({ loading: false }));
  }

  render() {
    const { device, devices } = this.props;
    const { loading } = this.state;
    return (
      <div className="column m-t-none p-t-none m-b-none p-none">
        <Field
          component={SelectInput}
          name={`${device}.device`}
          label="Device"
          items={devices.data || []}
          loading={loading || !!devices.pending}
          onQueryChange={this.handleSearch}
          onOpening={this.handleOpen}
          placeholder="Select a device"
        />
      </div>
    );
  }
}

_DeviceSelection.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.string,
  devices: PropTypes.object,
};

const DeviceSelection = connect(state => ({
  devices: state.devices,
}))(_DeviceSelection);

const renderDevices = ({ fields }) => (
  <React.Fragment>
    {fields.map((device, i) => (
      <div className="columns m-none service-form" key={device} style={{ margin: 0 }}>
        {!!((fields.get(i) || {}).device || {}).id && (
          <div style={{ margin: '23px 10px 0px 0px' }}>
            <Link to={`/devices/${fields.get(i).device.id}`}>
              <Button minimal outlined intent="primary" type="button" icon="arrow-top-left" />
            </Link>
          </div>
        )}
        <DeviceSelection device={device} />
        <div className="column" style={{ flexGrow: 0, flexShrink: 0 }}>
          <Button icon="delete" intent="danger" onClick={() => fields.remove(i)} style={{ marginTop: 12 }} />
        </div>
      </div>
    ))}
    <div className="has-text-right">
      <Button type="button" icon="add" onClick={() => fields.push({})}>
        Add Device
      </Button>
    </div>
  </React.Fragment>
);

renderDevices.propTypes = {
  fields: PropTypes.object,
};

const renderServices = ({ services, selectedServices, fields }) => (
  <React.Fragment>
    {fields.map((service, i) => (
      <div className="columns m-none service-form" key={service} style={{ margin: 0 }}>
        <div className="column m-t-none p-t-none m-b-none p-b-none">
          <Field
            name={`${service}.name`}
            type="text"
            component={FormSelectInput}
            label="Service Name"
            style={{ width: '100%' }}
          >
            <option value="" disabled>Select a service</option>
            {
              Array.from(new Set(services.map(s => s.name)))
                .filter(s => !(selectedServices || [])
                  .map(s2 => s2.name)
                  .includes(s) || s === (selectedServices || { [i]: {} })[i].name)
                .map(s => <option key={s} value={s}>{s}</option>)
            }
          </Field>
        </div>
        <div className="column m-t-none p-t-none m-b-none p-b-none">
          <Field
            name={`${service}.version`}
            type="text"
            component={FormSelectInput}
            label="Version"
          >
            <option value="" disabled>Select a version</option>
            {services
              .filter(s => s.name === (selectedServices || { [i]: {} })[i].name)
              .map(s => <option key={s.version} value={s.version}>{`v${s.version}`}</option>)
            }
          </Field>
        </div>
        <div className="column" style={{ flexGrow: 0, flexShrink: 0 }}>
          <Button icon="delete" intent="danger" onClick={() => fields.remove(i)} style={{ marginTop: 12 }} />
        </div>
      </div>
    ))}
    <div className="has-text-right">
      <Button type="button" icon="add" onClick={() => fields.push({})}>
        Add Service
      </Button>
    </div>
  </React.Fragment>
);

renderServices.propTypes = {
  services: PropTypes.array,
  selectedServices: PropTypes.array,
  fields: PropTypes.object,
};

class CreateRelease extends Component {
  @autobind
  handleSave(values) {
    const {
      dispatch, services, isEdit, releaseId,
    } = this.props;
    const data = {
      version: values.version,
      full_deployment: !!values.full_deployment,
      description: values.description || '',
      services: values.services
        .map(s => (services.data.find(sv => sv.name === s.name && sv.version === s.version).id)),
      devices: (values.devices || []).map(d => d.device.id),
    };

    if (isEdit) {
      return dispatch(editRelease(releaseId, data))
        .then(() => dispatch(getRelease(releaseId)))
        .then(() => AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                IAP
              </strong>
              : Release updated
            </span>
          ),
          intent: 'success',
        }));
    }
    return dispatch(createRelease(data))
      .then(action => dispatch(push(`/devices/iap/releases/${action.payload.data.content.id}`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              IAP
            </strong>
            : Release created
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      submitting, handleSubmit, services, selectedServices, error, invalid,
      title, isEdit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleSave)} className="container" autoComplete="off">
        <div className="columns">
          <div className="column">
            <H1>
              { title || 'Create IAP Release' }
            </H1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              component={TextInput}
              placeholder=""
              name="version"
              label="Version"
              type="text"
              disabled={isEdit}
            />
            <Field
              component={TextAreaInput}
              rows={10}
              name="description"
              label="Description"
              type="text"
              id="create-release-text-area"
            />
            <Field
              component={CheckboxInput}
              type="checkbox"
              id="full_deployment"
              name="full_deployment"
              label="Full Deployment"
              className="text-right m-b-lg"
            />
            <FieldArray name="devices" component={renderDevices} isEdit={isEdit} />
          </div>
          <div className="column">
            <FieldArray name="services" component={renderServices} services={services.data || []} selectedServices={selectedServices} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {error && !submitting && <Callout icon="error" intent="danger" title="Error" className="m-b-md">{error}</Callout>}
            <div className="has-text-right">
              <Link to="/devices/iap/releases" className="bp3-button m-r-md">Cancel</Link>
              <Button intent="primary" loading={submitting} disabled={submitting || !!error || invalid} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CreateRelease.propTypes = {
  isEdit: PropTypes.bool,
  title: PropTypes.string,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  services: PropTypes.object,
  error: PropTypes.string,
  selectedServices: PropTypes.array,
  invalid: PropTypes.bool,
  releaseId: PropTypes.number,
};

const selector = formValueSelector('create-release');
const requiredServices = ['daemon', 'wireless-sniffer', 'beacon-uploader', 'axis-bridge', 'interface-man', 'cms'];

export default connect((state, { initialValues }) => ({
  services: state.iap.services,
  selectedServices: selector(state, 'services'),
  initialValues: initialValues || {
    devices: [],
    services: requiredServices.map(s => ({
      name: s,
      version: state.iap.services.data.find(x => x.name === s).version,
    })),
  },
}))(reduxForm({
  form: 'create-release',
  validate: (values, { services }) => {
    const errors = {};
    if (!values.version) {
      errors.version = 'This field is required.';
    }
    if (!semver.valid(values.version)) {
      errors.version = 'Version must be a valid semver version (ex. 59.3.23)';
    }
    const missingServices = requiredServices
      .map(s => (!values.services.find(sv => sv.name === s) && s))
      .filter(x => x !== false);

    if (missingServices.length) {
      errors._error = `Required service ${missingServices[0]} is missing.`;
    }

    const sd = services.data;
    const invalidVersion = values.services
      .map(s => (!sd.find(sv => sv.name === s.name && sv.version === s.version) && s.name))
      .filter(x => x !== false);

    if (invalidVersion.length) {
      if (invalidVersion[0]) {
        errors._error = `Service ${invalidVersion[0]} has an invalid version.`;
      } else {
        errors._error = 'Invalid service selected.';
      }
    }
    return errors;
  },
})(CreateRelease));
