import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, FormSelectInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getNvr, saveNvr, patchOrgNvrs } from 'actions/nvr';

class NvrInfo extends Component {

  @autobind
  handleSave(values) {
    const { dispatch, nvr } = this.props;
    const nvrData = {
      name: values.name,
      serial: Number(values.sr_num),
      monitored: values.monitored === 'true',
    };

    return dispatch(saveNvr(nvr.id, nvrData)).then(() => dispatch(getNvr(nvr.id)))
      .then(() => AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {values.name}
              {values.sr_num}
            </strong>
            : Config saved
          </span>
        ),
        intent: 'success',
      }));
  }

  validateName(value) {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  };

  render() {
    const {
      submitting, handleSubmit, enableNameChange,
    } = this.props;

    return (
      <div className="columns">
        <form className="column" onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={TextInput}
            placeholder=""
            name="name"
            label="NVR Name"
            type="text"
            disabled={enableNameChange}
            validate={this.validateName}
          />
          <Field
            component={TextInput}
            placeholder=""
            name="sr_num"
            label="NVR SR Number"
            type="number"
            disabled={enableNameChange}
          />
          <div className="has-text-right">
            <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

NvrInfo.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  nvr: PropTypes.object,
  enableNameChange: PropTypes.bool,
};

export default reduxForm({
  form: 'nvr-info',
  enableReinitialize: true,
})(NvrInfo);
