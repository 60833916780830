import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import {
  H1, Icon, NonIdealState,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getReleases } from 'actions/nvr';

class Releases extends Component {
  constructor(props) {
    super(props);
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getReleases());
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const { query, status } = this.state;
    dispatch(getReleases(query, status));
  }

  renderRelease(release, i) {
    return (
      <tr key={i}>
        <td><Link to={`/nvrs/releases/${release.id}`}>{`v${release.version}`}</Link></td>
        <td>{release.description.split('\n')[0] || <em>n/a</em>}</td>
        <td>{moment(release.created).format('LLL')}</td>
        <td className="has-text-centered"><Icon icon="symbol-circle" intent={release.full_deployment ? 'success' : undefined} /></td>
      </tr>
    );
  }

  render() {
    const { releases } = this.props;
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  NVR Releases
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/nvrs/releases/create"
                  >
                    <Icon icon="add" />
                  </Link>
                </H1>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((releases.data || []).length) && <NonIdealState icon="search" title="No releases." />}
            {!!((releases.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>Description</th>
                    <th>Created</th>
                    <th className="has-text-centered">Full Deployment</th>
                  </tr>
                </thead>
                <tbody>
                  {(releases.data || []).map(this.renderRelease)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Releases.propTypes = {
  dispatch: PropTypes.func,
  releases: PropTypes.object,
};

export default connect(state => ({
  releases: state.nvrRelease.releases,
}))(Releases);
