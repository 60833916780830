import qs from 'query-string';
import {
  GET_ROLES, GET_ROLE, CREATE_ROLE, SAVE_ROLE, DELETE_ROLE, GET_PERMISSIONS, SAVE_PERMISSIONS,
  GET_RESOURCES, SAVE_RESOURCES,
} from '../constants';

export const getRoles = organizationId => ({
  type: GET_ROLES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/roles?${qs.stringify({ organizationId })}`,
    },
  },
});

export const createRole = data => ({
  type: CREATE_ROLE,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/roles/',
    },
  },
});

export const getRole = id => ({
  type: GET_ROLE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/roles/${id}`,
    },
  },
});

export const saveRole = (id, data) => ({
  type: SAVE_ROLE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/roles/${id}`,
    },
  },
});

export const deleteRole = id => ({
  type: DELETE_ROLE,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/roles/${id}`,
    },
  },
});

export const getPermissions = id => ({
  type: GET_PERMISSIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/roles/${id}/permissions`,
    },
  },
});

export const savePermissions = (id, data) => ({
  type: SAVE_PERMISSIONS,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/roles/${id}/permissions`,
    },
  },
});


export const getResources = id => ({
  type: GET_RESOURCES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/roles/${id}/resources`,
    },
  },
});

export const saveResources = (id, data) => ({
  type: SAVE_RESOURCES,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/roles/${id}/resources`,
    },
  },
});
