import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  H1, Tabs, Tab, Navbar, Button,
  Tooltip, Alignment,
} from '@blueprintjs/core';
import moment from 'moment';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import { RangePicker } from 'antd/lib/date-picker';
import { Route, Switch } from 'react-router-dom';
import { updateDateRange } from 'actions/daterange';

import Config from '../config';
import Metrics from './metrics';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;
const beaconStatus = status => DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;

class Meraki extends Component {
  @autobind
  handleDateChange(dates) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      DateTime.fromJSDate(dates[0].toDate()),
      DateTime.fromJSDate(dates[1].toDate()),
    ));
  }

  @autobind
  handleTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  renderConnection() {
    const { device } = this.props;
    const { status } = device;
    if (status.connection_type === 'WIFI') {
      return `WiFi ${status.wifi_source} (${status.signal_strength})`;
    }
    if (status.connection_type === 'ETH') {
      return 'Ethernet';
    }
    if (status.connection_type) {
      return status.connection_type;
    }
    return 'n/a';
  }

  render() {
    const {
      device, location, startDate, endDate,
    } = this.props;

    const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);
    const version = device.iap_configuration
      && device.iap_configuration.reported_version
      ? (device.iap_configuration || {}).reported_version
      : (device.version || {}).name;
    return (
      <div>
        <H1>
          {device.name}
          <small style={{ lineHeight: '12px', fontSize: '15px' }}>
            &nbsp;version&nbsp;
            {version}
          </small>
          <div className="p-l-md" style={{ display: 'inline' }}>
            <Tooltip content="Beacon" position="bottom">
              <Button large icon="satellite" minimal intent={beaconStatus(device.status) ? 'success' : 'danger'} />
            </Tooltip>
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
            ID:&nbsp;
            {device.id}
            <Dot />
            Created:&nbsp;
            {cd}
            <Dot />
            Connection:&nbsp;
            {this.renderConnection()}
            <Dot />
            Device Type:&nbsp;
            {(device || { type: '' }).type}
          </div>
        </H1>
        <Config device={device} />
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-device-axis" selectedTabId={location.pathname} onChange={this.handleTabChange} large>
              <Tab id={`/devices/${device.id}`} title="Metrics" />
            </Tabs>
          </Navbar.Group>
          { location.pathname === `/devices/${device.id}` && (
            <Navbar.Group align={Alignment.RIGHT}>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format="lll"
                style={{ width: '500px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Navbar.Group>
          )}
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route>
                <Metrics device={device} startDate={startDate} endDate={endDate} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Meraki.propTypes = {
  device: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
};

export default connect(state => ({
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
}))(Meraki);
