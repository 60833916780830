import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { getDash } from 'actions/dash';
import Overview from './overview';

class DashCard extends Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(getDash(match.params.id));
  }

  render() {
    const { match, dash } = this.props;

    if (!dash.data && dash.pending) {
      return <Spinner size={100} />;
    }
    if (dash.data.id !== parseInt(match.params.id, 10)) {
      return <Spinner size={100} />;
    }

    const card = (dash.data.cards || []).find(x => x.id === parseInt(match.params.card_id, 10));
    if (card) {
      return <Overview card={card} match={match} />;
    }
    return null;
  }
}

DashCard.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  dash: PropTypes.object,
};

export default connect(state => ({
  dash: state.dash,
}))(DashCard);
