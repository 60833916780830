import { requestsReducer } from 'redux-saga-requests';
import { GET_ADMIN_DASHBOARD } from '../constants';

const adminDashboardReducer = requestsReducer({
  actionType: GET_ADMIN_DASHBOARD,
  getData: (state, action) => action.payload.data.content,
});

// eslint-disable-next-line import/prefer-default-export
export { adminDashboardReducer as reducer };
