import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import {
  Button, Icon, Popover,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  TextInput, TagInput, CheckboxInput, TextAreaInput,
} from 'components/inputs';

import { getVisionParameters, patchVisionParameters } from 'actions/device';

class CameraConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopOverOpen: false,
    };
  }

  @autobind
  togglePopover() {
    this.setState(prevState => (
      {
        ...prevState,
        isPopOverOpen: !prevState.isPopOverOpen,
      }
    ));
  }

  @autobind
  handleSave(values) {
    const { dispatch, device } = this.props;
    const {
      camera_height, camera_fov, camera_user, camera_password, detect_faces, scale_counts,
      camera_ip, model_parameters, rtsp_url, max_capacity, fisheye, coalesce_entries,
      recording_flags, max_fps,
    } = values;
    return dispatch(patchVisionParameters(device.id, {
      camera_ip: camera_ip || [],
      camera_height: parseFloat(camera_height),
      camera_fov: parseFloat(camera_fov),
      camera_user,
      camera_password,
      model_parameters: JSON.parse(model_parameters),
      rtsp_url,
      detect_faces,
      scale_counts,
      max_capacity: parseFloat(max_capacity),
      fisheye,
      coalesce_entries,
      recording_flags,
      max_fps: parseInt(max_fps, 10),
    }))
      .then(() => dispatch(getVisionParameters(device.id)))
      .then(() => dispatch(reset('axis-config')));
  }

  render() {
    const {
      submitting, handleSubmit,
    } = this.props;

    const exampleModelParams = {
      anchors: [0.1, 0.2],
      confidence: 0.25,
      detect_class: [0, 1],
      detect_iou_threshold: 0.2,
      detect_frequency: 1,
      disable_positions: false,
      distance_metric: 'cosine',
      face_confidence: 0.7,
      input_size: [640, 480],
      is_face_model: false,
      mask_confidence: 0.5,
      merge_classes: false,
      min_centroid_distance: 0.0,
      model_id: 'darknet.yolov4.crowdhuman',
      models: {
        feature: 'onnx.feature.fastmot.osnet',
      },
      person_padding: 0.75,
      predict_masks: true,
      reid_cost: 0.6,
      track_iou_threshold: 0.3,
      track_max_age: 3,
      track_min_hits: 1,
      tracker: 'sort',
    };
    const modelParametersInfo = (
      <div>
        <pre>
          Example Model Parameters
          <hr />
          { JSON.stringify(exampleModelParams, null, 2) }
        </pre>
      </div>
    );

    const { isPopOverOpen } = this.state;

    return (
      <form className="columns" onSubmit={handleSubmit(this.handleSave)}>
        <div className="column">
          <Field
            name="camera_ip"
            component={TagInput}
            placeholder=""
            label="Camera IP"
            type="text"
          />
          <Field
            name="camera_user"
            component={TextInput}
            placeholder=""
            label="Username"
            type="text"
          />
          <Field
            name="camera_password"
            component={TextInput}
            placeholder=""
            label="Password"
            type="text"
          />
          <Field
            name="recording_flags"
            component={TextInput}
            placeholder=""
            label="Recording Flags"
            type="text"
          />
          <Field
            name="rtsp_url"
            component={TextInput}
            placeholder=""
            label="RTSP URL"
            type="text"
          />
          <Field
            name="max_fps"
            component={TextInput}
            placeholder=""
            label="Max FPS"
            type="number"
          />
          <Field
            name="max_capacity"
            component={TextInput}
            placeholder=""
            label="Max Capacity"
            type="number"
          />
        </div>
        <div className="column">
          <Field
            name="camera_height"
            component={TextInput}
            placeholder=""
            label="Camera Height (cm)"
            type="number"
          />
          <Field
            name="camera_fov" // 106 default for axis
            component={TextInput}
            placeholder=""
            label="Field of View"
            type="number"
          />
          <Field
            name="camera_type"
            component={TextInput}
            placeholder=""
            label="Type"
            type="text"
            disabled
          />
          <div>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              Model Parameters
              &nbsp;
              <Popover content={modelParametersInfo} isOpen={isPopOverOpen}>
                <Icon icon="info-sign" onClick={this.togglePopover} />
              </Popover>
            </div>
            <Field
              name="model_parameters"
              component={TextAreaInput}
              placeholder=""
              type="text"
            />
          </div>
          <Field
            name="fisheye"
            component={CheckboxInput}
            label="Fisheye"
          />
          <Field
            name="coalesce_entries"
            component={CheckboxInput}
            label="Coalesce Entries"
          />
          <Field
            name="detect_faces"
            component={CheckboxInput}
            label="Detect Faces"
          />
          <Field
            name="scale_counts"
            component={CheckboxInput}
            label="Scale Counts"
          />
          <div className="has-text-right">
            <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

CameraConfig.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
};

export default connect((_, { initialValues: { model_parameters, ...rest } }) => ({
  initialValues: {
    model_parameters: JSON.stringify(model_parameters, null, 2),
    ...rest,
  },
}))(reduxForm({
  form: 'axis-config',
  enableReinitialize: true,
  validate: (values) => {
    const { model_parameters } = values;
    const errors = {};
    try {
      JSON.parse(model_parameters);
    } catch (e) {
      errors.model_parameters = 'Not Valid JSON';
    }
    return errors;
  },
})(CameraConfig));
