import { requestsReducer } from 'redux-saga-requests';
import { combineReducers } from 'redux';
import { GET_IAP_RELEASES, GET_IAP_SERVICES, GET_IAP_RELEASE } from '../constants';

const iapReleasesReducer = requestsReducer({
  actionType: GET_IAP_RELEASES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

const iapServicesReducer = requestsReducer({
  actionType: GET_IAP_SERVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

const iapReleaseReducer = requestsReducer({
  actionType: GET_IAP_RELEASE,
  getData: (state, action) => action.payload.data.content,
});

const baseReducer = combineReducers({
  releases: iapReleasesReducer,
  services: iapServicesReducer,
  release: iapReleaseReducer,
});

export default baseReducer;
