import { requestsReducer } from 'redux-saga-requests';
import { GET_ALERTS, GET_ALERT, GET_ACTIVE_ALERTS } from '../constants';

export const getAlertsReducer = requestsReducer({
  actionType: GET_ALERTS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getAlertReducer = requestsReducer({
  actionType: GET_ALERT,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getActiveAlertsReducer = requestsReducer({
  actionType: GET_ACTIVE_ALERTS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});
