import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import Visualize from './AnalysisChart';

const DashAnalysis = ({ location, dispatch, match }) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  return (
    <div>
      <Visualize selectedZones={params.z} dispatch={dispatch} match={match} location={location} />
    </div>
  );
};

DashAnalysis.propTypes = {
  dispatch: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default DashAnalysis;
