import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Field, reduxForm, reset, formValueSelector,
} from 'redux-form';
import {
  Button, ButtonGroup,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { SelectInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getNvr, saveNvr } from 'actions/nvr';
import Reassign from './reassign';

class NvrAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reassign: false,
    };
  }

  @autobind
  handleReassign() {
    this.setState({ reassign: true });
  }

  @autobind
  handleCancelReassign() {
    const { dispatch } = this.props;
    this.setState({ reassign: false });
    dispatch(reset('nvr-assign'));
  }

  @autobind
  handleSave(values) {
    const { dispatch, nvr } = this.props;
    return dispatch(saveNvr(nvr.id, {
      organization_id: values.organization.id,
    })).then(() => {
      dispatch(reset('nvr-assign'));
      this.setState({ reassign: false });
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {nvr.name}
            </strong>
            : Nvr assigned
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getNvr(nvr.id));
    });
  }

  render() {
    const {
      nvr, submitting, handleSubmit, organizations, selectedOrg,
    } = this.props;
    const { reassign } = this.state;
    const orgs = organizations.map(x => ({ id: x.id, name: x.name }));

    if (nvr.organization_id && !reassign) {
      return (
        <Reassign
          organizations={organizations || []}
          nvr={nvr}
          handleReassign={this.handleReassign}
        />
      );
    }

    return (
      <div className="columns">
        <form className="column" onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={SelectInput}
            name="organization"
            label="Assign Organization"
            items={orgs}
          />
          {!reassign && (
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Assign
              </Button>
            </div>
          )}
          {reassign && (
            <ButtonGroup className="is-pulled-right">
              <Button intent="danger" icon="cross" onClick={this.handleCancelReassign}>
                Cancel
              </Button>
              <Button
                intent="warning"
                icon="tick"
                type="submit"
                disabled={submitting || !selectedOrg || selectedOrg.id === nvr.organization_id}
                loading={submitting}
              >
                Reassign
              </Button>
            </ButtonGroup>
          )}
        </form>
      </div>
    );
  }
}

NvrAssign.propTypes = {
  nvr: PropTypes.object,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organizations: PropTypes.array,
  selectedOrg: PropTypes.object,
};

const selector = formValueSelector('nvr-assign');
export default compose(
  reduxForm({
    form: 'nvr-assign',
    enableReinitialize: true,
  }),
  connect(state => ({
    selectedOrg: selector(state, 'organization'),
  })),
)(NvrAssign);
