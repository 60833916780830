import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Field, reduxForm, FieldArray, reset,
} from 'redux-form';
import {
  Button, Icon,
} from '@blueprintjs/core';
import classNames from 'classnames';
import moment from 'moment';
import { getCameraRecordings, startCameraRecording } from 'actions/device';
import { FormSelectInput, TimePickerInput } from 'components/inputs';

const DAYS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const DEFAULT_SCHEDULE = {
  day: '',
  open_time: new Date('1899-12-31T08:00:00.000Z'),
  close_time: new Date('1900-01-01T07:59:59.000Z'),
};

class CreateSchedule extends Component {
  componentDidUpdate(prevProps) {
    const { serial, dispatch } = this.props;
    if (serial !== prevProps.serial) {
      dispatch(reset('record-schedule'));
    }
  }

  @autobind
  handleSave(values) {
    const { scheduler } = values;
    const {
      device, dispatch, serial, setContent,
    } = this.props;
    if (!scheduler.filter(Boolean).length) {
      return null;
    }
    const operating_hours = (scheduler || []).map(({ day, open_time, close_time }) => ({
      day: parseInt(day, 10),
      open_time: moment(open_time).format('HH:mm:ss'),
      close_time: moment(close_time).format('HH:mm:ss'),
    }));
    if (!operating_hours.length) {
      return null;
    }
    const data = {
      camera_serial: serial,
      operating_hours,
    };
    return dispatch(startCameraRecording(device.id, data))
      .then(() => dispatch(getCameraRecordings(device.id)))
      .then(() => {
        dispatch(reset('record-schedule'));
        if (setContent) {
          setContent('', '');
        }
      });
  }

  @autobind
  renderSchedule({ fields }) {
    return (
      <table className={classNames('bp3-html-table bp3-interactive bp3-html-table-striped bp3-html-table-bordered', { 'bp3-skeleton': false })}>
        <thead>
          <tr>
            <th>
              <Icon icon="timeline-events" />
              &nbsp;
              Day
            </th>
            <th>
              <Icon icon="time" />
              &nbsp;
              Start Time (UTC)
            </th>
            <th>
              <Icon icon="time" />
              &nbsp;
              End Time (UTC)
            </th>
            <th className="has-text-right">
              <Button intent="primary" icon="plus" onClick={() => fields.push(DEFAULT_SCHEDULE)}>
                Add schedule
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((f, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <td style={{ verticalAlign: 'middle' }}>
                <Field
                  component={FormSelectInput}
                  name={`${f}.day`}
                >
                  <option value="">Select Day</option>
                  {DAYS.map(x => (
                    <option value={x.value} key={x.value}>{x.label}</option>
                  ))}
                </Field>
              </td>
              <td>
                <Field
                  component={TimePickerInput}
                  name={`${f}.open_time`}
                />
              </td>
              <td>
                <Field
                  component={TimePickerInput}
                  name={`${f}.close_time`}
                />
              </td>
              <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                <Button
                  icon="cross"
                  title="Remove Schedule"
                  intent="danger"
                  onClick={() => fields.remove(i)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className="columns" style={{ marginBottom: 20 }}>
        <div className="column">
          <form onSubmit={handleSubmit(this.handleSave)}>
            <FieldArray name="scheduler" component={this.renderSchedule} />
            <div className="columns">
              <div className="column has-text-right" style={{ margin: '20px 0px' }}>
                <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateSchedule.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
  serial: PropTypes.string,
  setContent: PropTypes.func,
};

export default connect(() => ({
  initialValues: {
    scheduler: [DEFAULT_SCHEDULE],
  },
}))(reduxForm({
  form: 'record-schedule',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    const scheduleErrors = [];
    if (values.scheduler && values.scheduler.length) {
      const dayTest = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      };
      values.scheduler.forEach((val, i) => {
        const memberErrors = {};
        if (val && val.day) {
          if (dayTest[val.day]) {
            memberErrors.day = 'Day already scheduled';
            scheduleErrors[i] = memberErrors;
          } else {
            dayTest[val.day] = true;
          }
        }
        if (val) {
          if (!val.day) {
            memberErrors.day = 'Required';
            scheduleErrors[i] = memberErrors;
          }
          if (!val.open_time) {
            memberErrors.open_time = 'Required';
            scheduleErrors[i] = memberErrors;
          }
          if (!val.close_time) {
            memberErrors.close_time = 'Required';
            scheduleErrors[i] = memberErrors;
          }
        }
      });
    }
    if (scheduleErrors.length > 0) {
      errors.scheduler = scheduleErrors;
    }
    return errors;
  },
})(CreateSchedule));
