import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  H1, Button, H3, MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { TextInput, SelectInput } from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import {
  getAllPeplinkDevices, getPeplinkSimPools, addCamera, createPeplink,
} from 'actions/device';

const defaultRenderer = o => (o ? (o.name || o.label || o) : o);
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;

class AddPeplink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_name: null,
      device: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllPeplinkDevices()).then(() => (dispatch(getPeplinkSimPools())));
  }

  @autobind
  onItemSelect(item) {
    this.setState({ group_name: item.group_name, device: item });
  }

  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    const { device } = this.state;
    return dispatch(addCamera({
      dname: device.name,
      device_id: device.lan_mac.replace(/:/g, ''),
      device_type: 'peplink',
    })).then(() => dispatch(createPeplink({
      peplink_id: device.id,
      group_id: device.group_id.toString(),
      device_id: device.lan_mac.replace(/:/g, ''),
      sim_pool_1: values.sim_pool_1.name,
      sim_pool_2: values.sim_pool_2.name,
      day_limit: parseInt(values.day_limit, 10),
    }))).then(action => dispatch(push(`/devices/${device.lan_mac.replace(/:/g, '')}`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.device_id}
            </strong>
            Peplink created
          </span>
        ),
        intent: 'success',
      }));
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.name}
        onClick={handleClick}
        text={item.name}
      />
    );
  }

  render() {
    const {
      submitting, handleSubmit, peplinks, pools,
    } = this.props;
    const { group_name, device } = this.state;
    const peps = (peplinks.data || []).map(x => ({
      key: x.name,
      id: x.id,
      name: x.name,
      lan_mac: x.lan_mac,
      group_id: x.group_id,
      group_name: x.group_name,
    }));
    const activePools = (pools.data || []).filter(x => x.active === true)
      .map(x => ({ id: x.profile_id, name: x.pool_name }));
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
              <H1>Add Peplink</H1>
              <H3>{`Peplink Group: ${group_name || 'Select a Device Name'}`}</H3>
              <div className="columns">
                <div className="column">
                  <p style={{ paddingBottom: '5px' }}>Device Name</p>
                  <Select
                    noResults={<MenuItem disabled key="nr" text="No results." />}
                    filterable
                    resetOnSelect
                    itemPredicate={defaultFilter}
                    itemRenderer={this.renderItem}
                    onItemSelect={this.onItemSelect}
                    popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                    items={peps || []}
                    label="Device Name"
                  >
                    <Button
                      text={(device || {}).name || 'Select a Device Name'}
                      fill
                      rightIcon="caret-down"
                    />
                  </Select>
                </div>
                <div className="column">
                  <Field
                    name="sim_pool_1"
                    component={SelectInput}
                    label="Sim Pool 1"
                    items={activePools}
                    placeholder="Select Sim Pool 1"
                  />
                </div>
                <div className="column">
                  <Field
                    name="sim_pool_2"
                    component={SelectInput}
                    label="Sim Pool 2"
                    items={activePools}
                    placeholder="Select Sim Pool 2"
                  />
                </div>
                <div className="column">
                  <Field
                    component={TextInput}
                    placeholder=""
                    name="day_limit"
                    type="number"
                    label="Day Limit"
                  />
                </div>
              </div>
              <div className="is-pulled-right">
                <Button
                  intent="primary"
                  loading={submitting}
                  disabled={submitting || device === null}
                  type="submit"
                  icon="tick"
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AddPeplink.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  peplinks: PropTypes.object,
  pools: PropTypes.object,
};

export default connect(state => ({
  initialvalues: {},
  peplinks: state.peplinks,
  pools: state.simpools,
}))(reduxForm({
  form: 'add_peplink',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.sim_pool_1) {
      errors.sim_pool_1 = 'This field is required.';
    }
    if (!values.sim_pool_2) {
      errors.sim_pool_2 = 'This field is required.';
    }
    return errors;
  },
})(AddPeplink));
