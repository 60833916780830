import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  H1, Tabs, Tab, Navbar, ButtonGroup, Button,
  Tooltip, Alignment, Popover, Spinner,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import moment from 'moment';
import axios from 'axios';
import { RangePicker } from 'antd/lib/date-picker';
import { autobind } from 'core-decorators';
import { getNvr, getNvrs } from 'actions/nvr';
import { updateDateRange } from 'actions/daterange';
import { AppToaster } from 'components/Toaster';
import Config from './config';
import Metrics from './metrics';
import { networkStatus } from '../list';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

class Nvr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinging: false,
      restartingNvrAi: false,
      updating: false,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getNvr(match.params.id));
    dispatch(getNvrs());
  }

  componentDidUpdate(prevProps) {
    const { match, dispatch } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(getNvr(match.params.id));
      dispatch(getNvrs());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      DateTime.utc().minus(3600000),
      DateTime.utc(),
    ));
  }

  @autobind
  handleDateChange(dates) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      DateTime.fromJSDate(dates[0].toDate()),
      DateTime.fromJSDate(dates[1].toDate()),
    ));
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch, location } = this.props;
    dispatch(push(tabId + (location.search || '')));
  }

  @autobind
  handlePing() {
    const { nvr } = this.props;
    this.setState({ pinging: true });
    axios.get(`https://${nvr.data.hostname}/nvr/v1/ping`)
      .then((response) => {
        AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                {nvr.data.name}
              </strong>
              : Pinged&nbsp;
              <em>{`ok: ${(response.data || { ok: false }).ok}`}</em>
            </span>
          ),
          intent: 'success',
        });
      })
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';
        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {nvr.data.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact nvr.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
      })
      .then(() => this.setState({ pinging: false }));
  }

  @autobind
  restartNvrAi() {
    const { nvr } = this.props;
    this.setState({ restartingNvrAi: true });
    axios.post(`https://${nvr.data.hostname}/nvr/v1/restart`, {}, {
      headers: { Authorization: `Bearer ${nvr.data.access_token}` },
    }).then(() => {
      axios.post(`/v1/nvr/${nvr.data.id}/metrics/notify-restart`);
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>{nvr.data.name}</strong>
            :&nbsp;
            restart initiated
          </span>
        ),
        intent: 'success',
      });
    }).catch((error) => {
      const isTimeout = (error.response || {}).status === 504;
      const respData = ((error.response || {}).data) || {};
      const errMessage = (respData.result || {}).errorMessage || 'unknown';

      AppToaster.show({
        icon: isTimeout ? 'cross' : 'error',
        message: (
          <span>
            <strong>
              {nvr.data.name}
            </strong>
            :&nbsp;
            {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
          </span>
        ),
        intent: 'danger',
      });
    }).finally(() => this.setState({ restartingNvrAi: false }));
  }

  @autobind
  handleUpdate() {
    const { nvr } = this.props;
    this.setState({ updating: true });
    axios.post(`https://${nvr.data.hostname}/nvr/v1/update?force=true`, {}, {
      headers: { Authorization: `Bearer ${nvr.data.access_token}` },
    }).then(
      (response) => {
        const {
          updating = false, message = 'n/a',
        } = response.data.content;
        this.setState({ updating: false });
        if (updating) {
          AppToaster.show({
            icon: 'tick',
            message: (
              <span>
                <strong>
                  {nvr.data.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'success',
          });
        } else {
          AppToaster.show({
            icon: 'error',
            message: (
              <span>
                <strong>
                  {nvr.data.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'success',
          });
        }
      },
    )
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';

        this.setState({ updating: false });
        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {nvr.data.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact NVR.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
      });
  }

  render() {
    const {
      nvr, location, match, startDate, endDate,
    } = this.props;
    const { pinging, restartingNvrAi, updating } = this.state;
    if (!nvr.data || nvr.data.id !== match.params.id) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    const cd = DateTime.fromISO(nvr.data.created).toLocaleString(DateTime.DATETIME_MED);
    const version = nvr.data.reported_version;

    return (
      <div className="container">
        <H1>
          {nvr.data.name}
          <small style={{ lineHeight: '12px', fontSize: '15px' }}>
            &nbsp;version:&nbsp;
            {version || 'n/a'}
          </small>
          <Popover
            className="is-pulled-right"
            position="bottom"
            content={(
              <ButtonGroup vertical>
                <Button
                  large
                  icon="refresh"
                  onClick={this.restartNvrAi}
                  loading={restartingNvrAi}
                  disabled={!nvr.data.hostname}
                >
                  NVR-AI
                </Button>
                <Button
                  large
                  icon="pulse"
                  onClick={this.handlePing}
                  loading={pinging}
                  disabled={!nvr.data.hostname}
                >
                  Ping
                </Button>
                <Button
                  large
                  icon="refresh"
                  onClick={this.handleUpdate}
                  loading={updating}
                  disabled={!nvr.data.hostname}
                >
                  Update
                </Button>
              </ButtonGroup>
            )}
          >
            <Button icon="desktop" rightIcon="caret-down" className="is-pulled-right">
              Nvr Utilities
            </Button>
          </Popover>
          <div className="p-l-md" style={{ display: 'inline' }}>
            <Tooltip content="Network" position="bottom">
              <Button large icon="cell-tower" minimal intent={networkStatus(nvr.data.network_status) ? 'success' : 'danger'} />
            </Tooltip>
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
            ID:&nbsp;
            {nvr.data.id}
            <Dot />
            Created:&nbsp;
            {cd}
            {nvr.data.local_ip && (
              <React.Fragment>
                <Dot />
                <span className="bp3-tag m-l-xs">
                  Local IP:&nbsp;
                  {nvr.data.local_ip}
                </span>
              </React.Fragment>
            )}
            {nvr.data.ip && (
              <React.Fragment>
                <Dot />
                <span className="bp3-tag m-l-xs">
                  IP:&nbsp;
                  {nvr.data.ip}
                </span>
              </React.Fragment>
            )}
          </div>
        </H1>
        <Config nvr={nvr.data} />
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="nvr-tabs" selectedTabId={location.pathname} onChange={this.handleTabChange} large>
              <Tab id={`/nvrs/${nvr.id}`} title="Metrics" />
            </Tabs>
          </Navbar.Group>
          {location.pathname === `/nvrs/${nvr.data.id}` && (
            <Navbar.Group align={Alignment.RIGHT}>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format="lll"
                style={{ width: '350px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Navbar.Group>
          )}
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route>
                <Metrics nvr={nvr.data} startDate={startDate} endDate={endDate} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Nvr.propTypes = {
  dispatch: PropTypes.func,
  nvr: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
};

export default connect(state => ({
  nvr: state.nvr,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
}))(Nvr);
