import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  H3, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { AppToaster } from 'components/Toaster';
import { FormSelectInput, DateTimeInput, CheckboxInput } from 'components/inputs';
import { startCameraUpload } from 'actions/device';

const REQUIRED = 'This field is required';

class UploadRecording extends Component {
  @autobind
  handleSave(values) {
    const {
      device, dispatch, setContent,
    } = this.props;
    const {
      start_time, end_time, region, camera_serial,
      scheduled_start,
    } = values;
    const data = {
      camera_serial,
      start_time: moment(start_time).utc().format(),
      end_time: moment(end_time).utc().format(),
      region,
      scheduled_start,
    };
    return dispatch(startCameraUpload(device.id, data))
      .then((action) => {
        if (action.payload.data && action.payload.data.content
          && action.payload.data.content.upload_count) {
          return AppToaster.show({
            icon: 'tick',
            message: <span>{`Uploading ${action.payload.data.content.upload_count} Files`}</span>,
            intent: 'success',
          });
        }
        return true;
      })
      .then(() => (setContent ? this.delayUpload(4000).then(() => setContent('view', camera_serial)) : true));
  }

  @autobind
  delayUpload(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  render() {
    const { submitting, handleSubmit, serial } = this.props;
    return (
      <div style={{ marginBottom: 40 }}>
        <H3>{`New Upload: ${serial}`}</H3>
        <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
          <div className="columns" style={{ marginBottom: 20 }}>
            <div className="column">
              <Field
                component={DateTimeInput}
                name="start_time"
                label="Start Time"
                fill
              />
            </div>
            <div className="column">
              <Field
                component={DateTimeInput}
                name="end_time"
                label="End Time"
                fill
              />
            </div>
            <div className="column">
              <Field
                component={FormSelectInput}
                name="region"
                label="Region"
              >
                <option value="">Select a Region</option>
                <option value="US">US</option>
                <option value="EU">EU</option>
              </Field>
            </div>
            <div className="column" style={{ alignSelf: 'center' }}>
              <Field
                component={CheckboxInput}
                name="scheduled_start"
                label="Scheduled"
              />
            </div>
            <div className="is-pulled-right column" style={{ alignSelf: 'center' }}>
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

UploadRecording.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  serial: PropTypes.string,
  device: PropTypes.object,
  dispatch: PropTypes.func,
  setContent: PropTypes.func,
};

export default reduxForm({
  form: 'camera_upload',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.start_time) {
      errors.start_time = REQUIRED;
    }
    if (!values.end_time) {
      errors.end_time = REQUIRED;
    }
    if (!values.region) {
      errors.region = REQUIRED;
    }
    if (values.start_time && values.end_time
      && moment(values.end_time).isBefore(values.start_time)) {
      errors.end_time = 'End Time must be after Start Time';
    }
    return errors;
  },
})(UploadRecording);
