import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import moment from 'moment';
import { Line as ChartJsLine } from 'react-chartjs-2';

const LineChart = ({
  data,
  labels,
  datasets,
  yFmtr,
  yLabel,
  stacked,
  legend,
  position,
  mode,
  xTimeUnit,
}) => (<ChartJsLine
  data={data || {
    labels,
    datasets,
  }}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            stepSize: 1,
            fontColor: '#fff',
          },
          gridLines: {
            borderDash: [1, 3],
            display: false,
            drawBorder: true,
          },
          type: 'time',
          time: {
            unit: xTimeUnit || undefined,
            minUnit: xTimeUnit || 'millisecond',
            parser: 'LLL',
            tooltipFormat: 'LLL',
          },
        },
      ],
      yAxes: [
        {
          stacked: !!stacked,
          ticks: {
            beginAtZero: true,
            fontColor: '#fff',
          },
          gridLines: {
            drawBorder: false,
            color: 'rgba(158,171,185,0.2)',
          },
          scaleLabel: {
            display: !!yLabel,
            labelString: yLabel || '',
          },
        },
      ],
    },
    legend: {
      display: legend || false,
      position: position || undefined,
      labels: {
        fontColor: '#fff',
        generateLabels: (chart) => {
          const x = Chart.defaults.global.legend.labels.generateLabels(chart);
          x.forEach((yy) => {
            const y = yy;
            y.fillStyle = y.strokeStyle;
          });
          return x;
        },
      },
    },
    tooltips: {
      mode: mode || 'index',
      intersect: false,
      backgroundColor: '#303F4D',
      titleFontColor: '#fff',
      bodyFontColor: '#fff',
      borderColor: '#fff',
      xPadding: 10,
      yPadding: 10,
      borderWidth: 2,
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          const x = Chart.defaults.global.tooltips
            .callbacks.labelColor(tooltipItem, chart);
          x.backgroundColor = x.borderColor;
          return x;
        },
        beforeLabel: (tooltipItem) => {
          // eslint-disable-next-line no-param-reassign
          tooltipItem.yLabel = yFmtr(tooltipItem.yLabel);
        },
        beforeTitle: (tooltipItem) => {
          // eslint-disable-next-line no-param-reassign
          tooltipItem[0].xLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].xLabel).format('LL')) : tooltipItem[0].xLabel;
        },
      },
    },
  }}
/>
);

LineChart.propTypes = {
  data: PropTypes.func,
  labels: PropTypes.array,
  datasets: PropTypes.array,
  yFmtr: PropTypes.func,
  yLabel: PropTypes.string,
  stacked: PropTypes.bool,
  position: PropTypes.string,
  legend: PropTypes.bool,
  mode: PropTypes.string,
  xTimeUnit: PropTypes.string,
};

export default LineChart;
