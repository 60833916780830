import { requestsReducer } from 'redux-saga-requests';
import {
  GET_DEVICES, GET_DEVICE, BOSUN_METRICS_DEVICE, BEACON_METRICS_DEVICE,
  DEVICE_MAC, GET_IAP_VERSIONS, GET_ALL_DEVICES, IAP_METRICS_DEVICE,
  GET_VISION_PARAMETERS, FETCH_IMAGE, GET_INS_OUTS, GET_FPS, GET_CAMERA_RESOLUTION,
  GET_HOMOGRAPHY, DELETE_HOMOGRAPHY, SCAN_AVAILABLE_CAMERAS, VIEW_EXISTING_UPLOADS,
  GET_PEPLINK_DEVICES, GET_SIM_POOLS, GET_PEPLINK, GET_PEPLINK_BANDWIDTH_USAGE,
  GET_PEPLINK_CLIENTS, GET_PEPLINK_LOGS, GET_CLIPS_BY_TIME, GET_CAMERA_COUNTING_LINES,
  GET_LINES_INS_OUTS, GET_CLIPS_BY_CAMERA,
} from '../constants';

export const versionReducer = requestsReducer({
  actionType: GET_IAP_VERSIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const devicesReducer = requestsReducer({
  actionType: GET_DEVICES,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    content.meta = action.payload.data.metadata || {};
    return content;
  },
});

export const deviceReducer = requestsReducer({
  actionType: GET_DEVICE,
  getData: (state, action) => action.payload.data.content,
});

export const metricsReducer = requestsReducer({
  actionType: BOSUN_METRICS_DEVICE,
  getData: (state, action) => action.payload.data.content,
});

export const iapMetricsReducer = requestsReducer({
  actionType: IAP_METRICS_DEVICE,
  getData: (state, action) => action.payload.data,
});

export const beaconsReducer = requestsReducer({
  actionType: BEACON_METRICS_DEVICE,
  getData: (state, action) => action.payload.data.content,
});

export const deviceInterfaceReducer = requestsReducer({
  actionType: DEVICE_MAC,
  getData: (state, action) => action.payload.data.content,
});

export const devicesAllReducer = requestsReducer({
  actionType: GET_ALL_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getVisionParametersReducer = requestsReducer({
  actionType: GET_VISION_PARAMETERS,
  getData: (state, action) => action.payload.data.content,
});

export const getFetchedImage = requestsReducer({
  actionType: FETCH_IMAGE,
  getData: (state, action) => action.payload.data.content,
});

export const getInsAndOutReducer = requestsReducer({
  actionType: GET_INS_OUTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getInsAndOutByLineReducer = requestsReducer({
  actionType: GET_LINES_INS_OUTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});


export const getFpsReducer = requestsReducer({
  actionType: GET_FPS,
  multiple: true,
  getData: (state, action) => action.payload.data.results,
});

export const getCameraResolutionReducer = requestsReducer({
  actionType: GET_CAMERA_RESOLUTION,
  multiple: true,
  getData: (state, action) => action.payload.data.results,
});

export const getHomographyReducer = requestsReducer({
  actionType: GET_HOMOGRAPHY,
  getData: (state, action) => action.payload.data.content,
});

export const deleteHomographyReducer = requestsReducer({
  actionType: DELETE_HOMOGRAPHY,
  getData: (state, action) => action.payload.data.content,
});

export const getCameraRecordingsReducer = requestsReducer({
  actionType: SCAN_AVAILABLE_CAMERAS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const viewExistingCameraUploadsReducer = requestsReducer({
  actionType: VIEW_EXISTING_UPLOADS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getAllPeplinkDevicesReducer = requestsReducer({
  actionType: GET_PEPLINK_DEVICES,
  getData: (state, action) => action.payload.data.content,
});

export const getPeplinkSimPoolsReducer = requestsReducer({
  actionType: GET_SIM_POOLS,
  getData: (state, action) => action.payload.data.content,
});

export const getPeplinkReducer = requestsReducer({
  actionType: GET_PEPLINK,
  getData: (state, action) => action.payload.data.content,
});

export const getPeplinkBandwidthUsageReducer = requestsReducer({
  actionType: GET_PEPLINK_BANDWIDTH_USAGE,
  getData: (state, action) => action.payload.data.content,
});

export const getPeplinkClientsReducer = requestsReducer({
  actionType: GET_PEPLINK_CLIENTS,
  getData: (state, action) => action.payload.data.content,
});

export const getPeplinkLogsReducer = requestsReducer({
  actionType: GET_PEPLINK_LOGS,
  getData: (state, action) => action.payload.data.content,
});

export const getClipsByTimeReducer = requestsReducer({
  actionType: GET_CLIPS_BY_TIME,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCountingLinesByCameraReducer = requestsReducer({
  actionType: GET_CAMERA_COUNTING_LINES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getClipsByCameraReducer = requestsReducer({
  actionType: GET_CLIPS_BY_CAMERA,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});
