import qs from 'query-string';
import {
  CAMERA_STATS_INS, CAMERA_STATS_FPS, CAMERA_STATS_RESOLUTION,
  CAMERA_LINE_STATS,
} from '../constants';


export const cameraStatsIns = (
  name, serial, startTime, endTime, region, dimensions, metrics, source,
) => ({
  type: CAMERA_STATS_INS,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${serial}/axis/query?${qs.stringify({
        startTime,
        endTime,
        region,
        dimensions,
        metrics,
        source,
      })}`,
    },
  },
});

export const cameraStatsByLine = (
  name, serial, startTime, endTime, region, dimensions, metrics, source,
) => ({
  type: CAMERA_LINE_STATS,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${serial}/axis/lines/query?${qs.stringify({
        startTime,
        endTime,
        region,
        dimensions,
        metrics,
        source,
      })}`,
    },
  },
});

export const cameraStatsFps = (name, query) => ({
  type: CAMERA_STATS_FPS,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/influxdb/query?${qs.stringify({
        db: 'lrm_iap',
        epoch: 'ms',
        q: query,
      })}`,
    },
  },
});

export const cameraStatsResolution = (name, query) => ({
  type: CAMERA_STATS_RESOLUTION,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/influxdb/query?${qs.stringify({
        db: 'lrm_iap',
        epoch: 'ms',
        q: query,
      })}`,
    },
  },
});
