import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H4, Spinner,
  Card, NonIdealState,
} from '@blueprintjs/core';
import moment from 'moment';
import numbro from 'numbro';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { deviceBeaconMetrics } from 'actions/device';

const MetricLineGraph = ({
  labels, datasets, yFmtr,
}) => (
  <Line
    data={{
      labels: labels[0] || [],
      datasets,
    }}
    options={{
      legend: {
        labels: {
          fontColor: '#f5f8fa',
          generateLabels: (chart) => {
            const x = Chart.defaults.global.legend.labels.generateLabels(chart);
            x.forEach((yy) => {
              const y = yy;
              y.fillStyle = y.strokeStyle;
            });
            return x;
          },
        },
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          labelColor: (tooltipItem, chart) => {
            const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
            x.backgroundColor = x.borderColor;
            return x;
          },
          beforeLabel: (tooltipItem) => {
            // eslint-disable-next-line no-param-reassign
            tooltipItem.yLabel = yFmtr(tooltipItem.yLabel);
          },
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          ticks: {
            source: 'auto',
            fontColor: '#f5f8fa',
          },
          // color: '#f5f8fa',
        }],
        yAxes: [{
          // color: '#f5f8fa',
          scaleLabel: {
            display: true,
          },
          ticks: {
            fontColor: '#f5f8fa',
            callback: yFmtr,
          },
        }],
      },
    }}
  />
);
MetricLineGraph.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  yFmtr: PropTypes.func,
};

class MerakiDevice extends Component {
  componentDidMount() {
    const {
      dispatch, device, startDate, endDate,
    } = this.props;
    dispatch(deviceBeaconMetrics(
      device.id,
      startDate.toISO(),
      endDate.toISO(),
    ));
  }

  componentDidUpdate(prevProps) {
    const {
      startDate, endDate, dispatch, device,
    } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      dispatch(deviceBeaconMetrics(
        device.id,
        startDate.toISO(),
        endDate.toISO(),
      ));
    }
  }

  renderBeaconChart() {
    const { beacon, device } = this.props;
    const status = device.status || {};
    const lastBeacon = (status.beacon || '0001').substring(0, 4) !== '0001' ? moment(status.beacon).fromNow() : 'Never';
    if (!beacon.data || beacon.pending) {
      return (
        <div className="column is-half">
          <Card>
            <div className="has-text-centered" style={{ padding: '160px 0' }}><Spinner /></div>
          </Card>
        </div>
      );
    }
    if (beacon.data.mac_count.length === 0) {
      return (
        <div className="column is-half">
          <Card>
            <NonIdealState
              icon="satellite"
              title="No beacons"
              className="non-ideal-device-card"
              description={`Last Beacon: ${lastBeacon}`}
            />
          </Card>
        </div>
      );
    }
    const fmtr = x => numbro(x).format('0,0');
    const data = beacon.data.mac_count[0].values.map(x => ({
      t: x.timeInEpoch,
      y: x.count,
    }));
    const dataset = {
      label: 'beacons',
      data,
      type: 'line',
      pointRadius: 0,
      fill: true,
      lineTension: 0,
      borderWidth: 1.3,
      borderColor: '#CFF3D2',
      // backgroundColor: 'rgb(207,243,210, .2)',
    };
    return (
      <div className="column is-half">
        <Card>
          <div className="columns is-marginless">
            <H4 className="is-marginless column">
              Beacon
              &nbsp;
              <small>
                Last Beacon:&nbsp;
                {lastBeacon}
              </small>
            </H4>
          </div>
          <Line
            data={{
              labels: _.map(data || [], 't'),
              datasets: data ? [dataset] : [],
            }}
            options={{
              legend: {
                labels: {
                  fontColor: '#f5f8fa',
                  generateLabels: (chart) => {
                    const x = Chart.defaults.global.legend.labels.generateLabels(chart);
                    x.forEach((yy) => {
                      const y = yy;
                      y.fillStyle = y.strokeStyle;
                    });
                    return x;
                  },
                },
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  labelColor: (tooltipItem, chart) => {
                    const x = Chart.defaults.global.tooltips
                      .callbacks.labelColor(tooltipItem, chart);
                    x.backgroundColor = x.borderColor;
                    return x;
                  },
                  beforeLabel: (tooltipItem) => {
                    // eslint-disable-next-line no-param-reassign
                    tooltipItem.yLabel = fmtr(tooltipItem.yLabel);
                  },
                },
              },
              scales: {
                xAxes: [{
                  type: 'time',
                  distribution: 'linear',
                  ticks: {
                    source: 'auto',
                    fontColor: '#f5f8fa',
                  },
                }],
                yAxes: [{
                  scaleLabel: {
                    display: true,
                  },
                  ticks: {
                    fontColor: '#f5f8fa',
                    callback: fmtr,
                  },
                }],
              },
            }}
          />
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div className="columns is-multiline">
        {this.renderBeaconChart()}
      </div>
    );
  }
}

MerakiDevice.propTypes = {
  device: PropTypes.object,
  dispatch: PropTypes.func,
  beacon: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default connect(state => ({
  beacon: state.beacon,
}))(MerakiDevice);
