import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { HTMLTable, Tag, Button } from '@blueprintjs/core';
import moment from 'moment';

class ActiveAlertsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows || [],
      idSort: 1,
      nameSort: 1,
      alertSort: 0,
      claimedSort: 1,
      expandedRows: [],
      dateSort: 1,
    };
  }

  @autobind
  getUserFromId(userId) {
    const { users } = this.props;
    if (users.data) {
      const user = users.data.find(x => x.id === userId);
      return user.name;
    }
    return userId;
  }

  @autobind
  handleIDSort() {
    const { idSort, rows } = this.state;

    const sort = idSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.object_id).localeCompare(b.object_id));
      this.setState({ rows: newRows, idSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.object_id).localeCompare(a.object_id));
      this.setState({ rows: newRows, idSort: sort });
    }
  }

  @autobind
  handleAlertSort() {
    const { alertSort, rows } = this.state;

    const sort = alertSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.alerting) - (b.alerting));
      this.setState({ rows: newRows, alertSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.alerting) - (a.alerting));
      this.setState({ rows: newRows, alertSort: sort });
    }
  }

  @autobind
  handleNameSort() {
    const { nameSort, rows } = this.state;

    const sort = nameSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.object_name).localeCompare(b.object_name));
      this.setState({ rows: newRows, nameSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.object_name).localeCompare(a.object_name));
      this.setState({ rows: newRows, nameSort: sort });
    }
  }

  @autobind
  handleClaimedSort() {
    const { claimedSort, rows } = this.state;

    const ascSortFunc = (a, b) => {
      if (a.user_id == null && b.user_id == null) {
        return 0;
      }
      if (!a.user_id) {
        return -1;
      }
      if (!b.user_id) {
        return 1;
      }
      return 0;
    };

    const descSortFunc = (a, b) => {
      if (a.user_id == null && b.user_id == null) {
        return 0;
      }
      if (!b.user_id) {
        return -1;
      }
      if (!a.user_id) {
        return 1;
      }
      return 0;
    };

    const sort = claimedSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => ascSortFunc(a, b));
      this.setState({ rows: newRows, claimedSort: sort });
    } else {
      const newRows = rows.sort((a, b) => descSortFunc(a, b));
      this.setState({ rows: newRows, claimedSort: sort });
    }
  }

  @autobind
  handleDateSort() {
    const { dateSort, rows } = this.state;

    const sort = dateSort === 0 ? 1 : 0;
    if (sort === 0) {
      const newRows = rows.sort((a, b) => (a.updated).localeCompare(b.updated));
      this.setState({ rows: newRows, dateSort: sort });
    } else {
      const newRows = rows.sort((a, b) => (b.updated).localeCompare(a.updated));
      this.setState({ rows: newRows, dateSort: sort });
    }
  }

  @autobind
  handleRowExpand(idx) {
    const { expandedRows } = this.state;
    if (expandedRows.includes(idx)) {
      this.setState({ expandedRows: expandedRows.filter(x => x !== idx) });
    } else {
      this.setState({ expandedRows: [...expandedRows, idx] });
    }
  }

  @autobind
  rowRenderer(row, idx) {
    const {
      onViewActions, alertID, alertType,
    } = this.props;
    const { expandedRows } = this.state;
    let intent;
    let alerting;
    if (row.alerting === 0) {
      intent = 'success';
      alerting = 'OK';
    } else if (row.alerting === 1) {
      intent = 'danger';
      alerting = 'Alerting';
    } else if (row.alerting === 2) {
      intent = 'warning';
      alerting = 'No Data';
    } else {
      intent = 'none';
      alerting = 'Paused';
    }
    const hyperlink = row.object_type === 'device_id' ? `/devices/${row.object_id}` : `/zones/${row.object_id}`;
    return (
      <Fragment>
        <tr>
          <td><a href={hyperlink}>{row.object_id}</a></td>
          <td>{row.object_name}</td>
          <td><Tag minimal intent={intent} large>{alerting}</Tag></td>
          <td>
            <Button onClick={() => onViewActions(alertID, row.object_id, alertType)}>
              {expandedRows.includes(idx) ? 'Hide' : 'View'}
            </Button>
          </td>
          <td>
            <Tag minimal intent={row.user_id ? 'success' : 'danger'} large>
              {row.user_id ? this.getUserFromId(row.user_id) : 'Unclaimed'}
            </Tag>
          </td>
          <td>
            {moment(row.updated).format('MMM D, H:mm')}
          </td>
        </tr>
      </Fragment>
    );
  }

  render() {
    const { rows } = this.state;
    const type = rows[0].object_type === 'device_id'
      ? 'Device' : 'Zone';
    return (
      <div className="container">
        <HTMLTable striped>
          <thead>
            <tr>
              <th style={{ width: '28%' }}>
                {`${type} ID`}
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                  onClick={this.handleIDSort}
                />
              </th>
              <th style={{ width: '20%' }}>
                {`${type} Name`}
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                  onClick={this.handleNameSort}
                />
              </th>
              <th style={{ width: '14%' }}>
                Alert Status
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                  onClick={this.handleAlertSort}
                />
              </th>
              <th style={{ width: '10%' }}>
                Actions
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                />
              </th>
              <th style={{ width: '14%' }}>
                Claimed
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                  onClick={this.handleClaimedSort}
                />
              </th>
              <th style={{ width: '14%' }}>
                Updated
                &nbsp;
                <Button
                  icon="double-caret-vertical"
                  minimal
                  intent="none"
                  onClick={this.handleDateSort}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.length && rows.map((x, i) => this.rowRenderer(x, i))}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}

ActiveAlertsTable.propTypes = {
  rows: PropTypes.array,
  onViewActions: PropTypes.func,
  alertID: PropTypes.number,
  alertType: PropTypes.string,
  users: PropTypes.object,
};

export default ActiveAlertsTable;
