import React, { Component } from 'react';
import PropTypes from 'prop-types'; import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon, NonIdealState } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';

import { getRoles } from 'actions/role';

class Roles extends Component {
  componentDidMount() {
    const { dispatch, organization } = this.props;
    const orgId = (organization.data || {}).id;
    if (orgId) {
      dispatch(getRoles(orgId));
    }
  }

  componentDidUpdate(prevProps) {
    const { organization, dispatch } = this.props;
    const prevOrgId = (prevProps.organization.data || {}).id;
    const orgId = (organization.data || {}).id;
    if (orgId !== prevOrgId) {
      dispatch(getRoles(orgId));
    }
  }

  @autobind
  renderRole(role, i) {
    const { organization } = this.props;
    const orgId = (organization.data || {}).id;
    return (
      <tr key={i}>
        <td><Link to={`/organizations/${orgId}/roles/${role.role_id}`}>{role.role_name}</Link></td>
        <td className="has-text-centered">{role.is_admin && <Icon icon="tick" />}</td>
      </tr>
    );
  }

  render() {
    const { roles } = this.props;

    return (
      <React.Fragment>
        {!((roles.data || []).length) && <NonIdealState icon="search" title="No results." />}
        {!!((roles.data || []).length) && (
          <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th>Name</th>
                <th className="has-text-centered">Is Admin</th>
              </tr>
            </thead>
            <tbody>
              {(roles.data || []).map(this.renderRole)}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

Roles.propTypes = {
  dispatch: PropTypes.func,
  roles: PropTypes.object,
  organization: PropTypes.object,
};

export default connect(state => ({
  roles: state.roles,
  organization: state.organization,
}))(Roles);
