import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Button, MenuItem, FormGroup, NumericInput,
} from '@blueprintjs/core';
import { DateRangeInput, TimePrecision } from '@blueprintjs/datetime';
import { Select } from '@blueprintjs/select';
import { LazyLog } from 'react-lazylog';
import axios from 'axios';
import qs from 'query-string';

const defaultRenderer = o => (o ? (o.name || o.label || o) : o);
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().add(-1, 'hours'),
      endDate: moment(),
      log: 'journald',
      unit: 'lrm-iap-daemon',
      lines: '0',
      rand: Math.floor(Math.random() * 10000),
    };
  }

  @autobind
  handleRefresh() {
    this.setState({
      rand: Math.floor(Math.random() * 10000),
      endDate: moment(),
    });
  }

  @autobind
  handleLinesChange(c) {
    this.setState({ lines: c });
  }

  @autobind
  handleUnitSelect(o) {
    this.setState({ unit: o });
  }

  @autobind
  handleRangeChange(range) {
    this.setState({
      startDate: moment(range[0]),
      endDate: moment(range[1]),
    });
  }

  @autobind
  handleLogChange(event) {
    this.setState({
      log: event.target.value,
      lines: event.target.value === 'journald' ? '0' : 500,
    });
  }

  @autobind
  handleCreateUnit(title) {
    return title;
  }

  @autobind
  handleCreateItemRender(query, active, handleClick) {
    return (
      <MenuItem
        icon="add"
        text={`Unit "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  }

  renderURL() {
    const {
      startDate, endDate, log, unit, lines, rand,
    } = this.state;
    const {
      accessToken, device,
    } = this.props;
    const vars = {
      unit: log === 'journald' && unit !== 'all' ? unit : undefined,
      lines: parseInt(lines, 10) === 0 ? undefined : lines,
      since: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
      until: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
      access_token: accessToken,
      raw: 'true',
      rand,
    };

    return `${axios.defaults.baseURL}/v1/admin/devices/${device.id}/logs/${log}?${qs.stringify(vars)}`;
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item}
        onClick={handleClick}
        text={defaultRenderer(item)}
      />
    );
  }

  render() {
    const {
      startDate, endDate, log, unit, lines,
    } = this.state;

    return (
      <div>
        <div className="columns" style={{ textAlign: 'right' }}>
          <div className="column">
            <FormGroup
              label="Logs"
              style={{ display: 'inline-flex', marginRight: 20 }}
            >
              <div className="bp3-select">
                <select value={log} onChange={this.handleLogChange}>
                  <option value="journald">journald</option>
                  <option value="syslog">syslog</option>
                  <option value="kern">kernlog</option>
                </select>
              </div>
            </FormGroup>
            {log === 'journald' && (
              <FormGroup
                label="Date Range"
                style={{ display: 'inline-flex', marginRight: 20 }}
              >
                <DateRangeInput
                  allowSingleDayRange
                  formatDate={date => moment(date).format('llll')}
                  onChange={this.handleRangeChange}
                  parseDate={str => moment(str)}
                  value={[startDate.toDate(), endDate.toDate()]}
                  timePrecision={TimePrecision.MINUTE}
                  timePickerProps={{
                    precision: TimePrecision.MINUTE,
                    useAmPm: true,
                    showArrowButtons: true,
                  }}
                />
              </FormGroup>
            )}
            {log === 'journald' && (
              <FormGroup
                label="Systemd Unit"
                style={{ display: 'inline-flex', marginRight: 20 }}
              >
                <Select
                  createNewItemFromQuery={this.handleCreateUnit}
                  createNewItemRenderer={this.handleCreateItemRender}
                  noResults={<MenuItem disabled text="No results." />}
                  filterable
                  resetOnSelect
                  itemRenderer={this.renderItem}
                  itemPredicate={defaultFilter}
                  popoverProps={{
                    minimal: true, fill: true, popoverClassName: 'popover-sz',
                  }}
                  items={['all', 'lrm-iap-daemon', 'lrm-iap-cms']}
                  onItemSelect={this.handleUnitSelect}
                >
                  <Button
                    text={defaultRenderer(unit)}
                    rightIcon="caret-down"
                  />
                </Select>
              </FormGroup>
            )}
            {log !== 'journald' && (
              <FormGroup
                label="Lines"
                style={{ display: 'inline-flex', marginRight: 20 }}
              >
                <NumericInput
                  onValueChange={this.handleLinesChange}
                  value={lines}
                  style={{ width: 75 }}
                />
              </FormGroup>
            )}
            <FormGroup
              label={<span>&nbsp;</span>}
              style={{ display: 'inline-flex' }}
            >
              <Button icon="refresh" onClick={this.handleRefresh} />
            </FormGroup>
          </div>
        </div>
        <LazyLog
          url={this.renderURL()}
          height={750}
          extraLines={1}
          follow
          enableSearch
          selectableLines
        />
      </div>
    );
  }
}

Logs.propTypes = {
  accessToken: PropTypes.string,
  device: PropTypes.object,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
}))(Logs);
