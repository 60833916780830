/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm, FieldArray, formValueSelector,
} from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import moment from 'moment';
import {
  TextInput, FormSelectInput, CheckboxInput, DateTimeInput,
} from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import { createAudit, getAudits } from 'actions/audit';
import { getCountingLinesByCamera } from 'actions/device';

class Audit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraCountingLines: [],
    };
  }

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues.clips_input) {
      const countingLines = initialValues.clips_input.map(item => item.line_id)
        .filter((value, index, self) => self.indexOf(value) === index).map((x) => {
          const rObj = {};
          rObj.line_id = x;
          rObj.name = '';
          return rObj;
        });
      this.setState({ cameraCountingLines: countingLines });
      // Promise.all(initialValues.clips_input.map(item => item.camera_serial)
      //   .filter((value, index, self) => self.indexOf(value) === index)
      //   .map(d => this.getCameraCountingLines(d)));
    }
  }

  @autobind
  async getCameraCountingLines(camera_serial) {
    const { dispatch } = this.props;
    const { cameraCountingLines } = this.state;
    let tmp = [...cameraCountingLines];
    dispatch(getCountingLinesByCamera(camera_serial)).then(({ payload }) => {
      if (payload && payload.data.content) {
        if (cameraCountingLines === undefined || cameraCountingLines === null) {
          tmp = payload.data.content;
        } else {
          payload.data.content.forEach((x) => {
            if (cameraCountingLines.filter(elem => x.line_id === elem.line_id).length === 0) {
              tmp = tmp.concat(x);
            }
          });
        }
        this.setState({ cameraCountingLines: tmp });
      }
    });
  }

  @autobind
  handleSave(values) {
    const { dispatch, task_type } = this.props;
    const {
      audit_name, s3_output_path, min_version, vision_params, model_id,
      use_learner, clips_input, include, exclude, s3_input_path, feature_extractor,
    } = values;
    let path = s3_output_path.trim();
    if (path !== '' && path.split('').pop() !== '/') {
      path = `${path}/`;
    }
    let use_feature_extractor = true;
    if (feature_extractor === 'none' || !feature_extractor) {
      use_feature_extractor = false;
    }
    const data = {
      audit_name,
      s3_output_path: path,
      min_version,
      vision_params,
      model_id,
      lock_interval: parseInt(values.lock_interval, 10),
      use_learner,
      task_type,
      use_feature_extractor,
      feature_extractor: use_feature_extractor ? feature_extractor : undefined,
    };
    if (include && include.length) {
      data.include = include.map(x => x.value);
    }
    if (exclude && exclude.length) {
      data.exclude = exclude.map(x => x.value);
    }
    if (task_type === 'inline') {
      data.frame_count = parseInt(values.frame_count, 10);
      data.iou_threshold = parseFloat(values.iou_threshold);
      data.full_model_id = values.full_model_id;
      data.full_vision_rx_params = values.full_vision_rx_params;
      data.full_vision_params = values.full_vision_params;
    }
    if ((clips_input || []).length > 0) {
      data.clips_input = clips_input.map(x => ({
        camera_serial: x.camera_serial,
        start_time: moment(x.start_time).utc().format(),
        end_time: moment(x.end_time).utc().format(),
        line_id: parseInt(x.line_id, 10) || null,
      }));
    } else {
      data.s3_input_path = s3_input_path;
    }

    return dispatch(createAudit(data)).then(action => dispatch(push('/audits'))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            : Audit task created
          </span>
        ),
        intent: 'success',
      }))
      .finally(() => dispatch(getAudits()));
  }

  @autobind
  handleCameraSerialInput(e) {
    const { dispatch } = this.props;
    const { cameraCountingLines } = this.state;
    let tmp = [...cameraCountingLines];
    dispatch(getCountingLinesByCamera(e.target.value)).then(({ payload }) => {
      if (payload && payload.data.content) {
        if (cameraCountingLines === undefined || cameraCountingLines === null) {
          tmp = payload.data.content;
        } else {
          payload.data.content.forEach((x) => {
            if (cameraCountingLines.filter(elem => x.line_id === elem.line_id).length === 0) {
              tmp = tmp.concat(x);
            }
          });
        }
        this.setState({ cameraCountingLines: tmp });
      }
    });
  }

  @autobind
  renderIncludes({ fields }) {
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Include</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((include, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '90%' }}>
                  <Field
                    component={TextInput}
                    name={`${include}.value`}
                    type="text"
                  />
                </div>
                <div>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  @autobind
  renderExcludes({ fields }) {
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Exclude</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((exclude, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '90%' }}>
                  <Field
                    component={TextInput}
                    name={`${exclude}.value`}
                    type="text"
                  />
                </div>
                <div>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  @autobind
  renderClips({ fields }) {
    const { cameraCountingLines } = this.state;
    const countingLines = cameraCountingLines === null ? [] : cameraCountingLines;
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Clips Input</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((clips, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '30%' }}>
                  <Field
                    component={TextInput}
                    name={`${clips}.camera_serial`}
                    type="text"
                    label="Camera Serial"
                    onChange={this.handleCameraSerialInput}
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <Field
                    name={`${clips}.line_id`}
                    placeholder=""
                    component={FormSelectInput}
                    label="Counting Line"
                  >
                    <option value="">Select Counting Line</option>
                    {(countingLines || []).map(x => (
                      <option key={x.line_id} value={x.line_id}>
                        {`${x.line_id} (${x.name})`}
                      </option>
                    ))}
                  </Field>
                </div>
                <div style={{ flexGrow: 1, marginLeft: 10 }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.start_time`}
                    label="Start Time"
                    fill
                  />
                </div>
                <div style={{ flexGrow: 1, margin: '0px 10px' }}>
                  <Field
                    component={DateTimeInput}
                    name={`${clips}.end_time`}
                    label="End Time"
                    fill
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  render() {
    const {
      submitting, handleSubmit, services, models, task_type,
    } = this.props;

    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create Audit Task
            </H1>
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="audit_name"
                  label="Audit Name"
                  type="text"
                />
              </div>
              <div style={{ marginTop: 40 }}>
                <Field
                  component={CheckboxInput}
                  name="use_learner"
                  label="Use Learner"
                />
              </div>
              <div className="column">
                <Field
                  name="model_id"
                  placeholder=""
                  component={FormSelectInput}
                  label="Model"
                >
                  <option value="">Select a Model</option>
                  {models.data.map(x => (
                    <option key={x.id} value={x.id}>
                      {`${x.id} (${x.model_type})`}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="column">
                <Field
                  name="min_version"
                  placeholder=""
                  component={FormSelectInput}
                  label="Version"
                >
                  <option value="" disabled>Select a Version</option>
                  {services.data
                    .filter(s => s.name === 'vision-rx')
                    .map(s => <option key={s.version} value={s.version}>{`v${s.version}`}</option>)
                    }
                </Field>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  name="vision_params"
                  label="Vision Parameters"
                  type="text"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-2">
                <Field
                  component={TextInput}
                  type="number"
                  name="lock_interval"
                  label="Timeout (min)"
                />
              </div>
              <div className="column is-10">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="s3_output_path"
                  label="S3 Output Path"
                  type="text"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ borderRight: '2px solid #26343E' }}>
                <FieldArray name="include" component={this.renderIncludes} />
              </div>
              <div className="column">
                <FieldArray name="exclude" component={this.renderExcludes} />
              </div>
            </div>
            <div className="columns">
              <div className="column is-2">
                <Field
                  name="task_type"
                  placeholder=""
                  component={FormSelectInput}
                  label="Task Type"
                >
                  <option value="" disabled>Select a Type</option>
                  <option value="overhead">Overhead</option>
                  <option value="inline">Inline</option>
                </Field>
              </div>
              <div className="column is-2">
                <Field
                  name="feature_extractor"
                  placeholder=""
                  component={FormSelectInput}
                  label="Feature Extractor"
                >
                  <option value="none">No feature extractor</option>
                  {models.data.filter(x => x.model_type === 'onnx')
                    .map(x => (
                      <option key={x.id} value={x.id}>
                        {`${x.id} (${x.model_type})`}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            {task_type === 'inline' && (
              <Fragment>
                <div className="columns">
                  <div className="column">
                    <Field component={TextInput} type="number" label="Frame Count" name="frame_count" />
                  </div>
                  <div className="column">
                    <Field component={TextInput} label="IOU Threshold" name="iou_threshold" />
                  </div>
                  <div className="column">
                    <Field
                      name="full_model_id"
                      placeholder=""
                      component={FormSelectInput}
                      label="Learner Model"
                    >
                      <option value="">Select a Model</option>
                      {models.data.map(x => (
                        <option key={x.id} value={x.id}>
                          {`${x.id} (${x.model_type})`}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="column">
                    <Field
                      name="full_vision_rx_version"
                      placeholder=""
                      component={FormSelectInput}
                      label="Learner Version"
                    >
                      <option value="" disabled>Select a Version</option>
                      {services.data
                        .filter(s => s.name === 'vision-rx')
                        .map(s => <option key={s.version} value={s.version}>{`v${s.version}`}</option>)
                        }
                    </Field>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Field
                      component={TextInput}
                      name="full_vision_params"
                      label="Learner Vision Parameters"
                      type="text"
                    />
                  </div>
                </div>
              </Fragment>
            )}
            <div className="columns">
              <div className="column" style={{ borderRight: '2px solid #26343E' }}>
                <FieldArray name="clips_input" component={this.renderClips} />
              </div>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Audit.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  services: PropTypes.object,
  models: PropTypes.object,
  task_type: PropTypes.string,
  initialValues: PropTypes.object,
};

const selector = formValueSelector('create-audit');

const REQUIRED = 'This field is required.';

export default connect((state, props) => ({
  services: state.iap.services,
  models: state.models,
  task_type: selector(state, 'task_type'),
  initialValues: props.location.state && props.location.state.initialValues
    ? props.location.state.initialValues
    : {
      min_version: state.iap.services.data.filter(s => s.name === 'vision-rx')[0].version,
    },
  cameraCountingLines: state.cameraCountingLines,
}))(reduxForm({
  form: 'create-audit',
  validate: (values, { task_type }) => {
    const errors = {};
    const clipErrors = [];
    if (!values.model_id) {
      errors.model_id = REQUIRED;
    }
    if (!values.min_version) {
      errors.min_version = REQUIRED;
    }
    if (!values.audit_name) {
      errors.audit_name = REQUIRED;
    }
    if (!values.model_id) {
      errors.model_id = REQUIRED;
    }
    if (!values.task_type) {
      errors.task_type = REQUIRED;
    }
    if (!values.lock_interval) {
      errors.lock_interval = REQUIRED;
    }
    if (task_type === 'inline') {
      if (!values.frame_count) {
        errors.frame_count = REQUIRED;
      }
      if (!values.iou_threshold) {
        errors.iou_threshold = REQUIRED;
      }
      if (values.iou_threshold && !parseFloat(values.iou_threshold)) {
        errors.iou_threshold = 'Float required';
      }
      if (!values.full_model_id) {
        errors.full_model_id = REQUIRED;
      }
      if (!values.full_vision_rx_version) {
        errors.full_vision_rx_version = REQUIRED;
      }
    }
    if ((values.clips_input || []).length > 0) {
      values.clips_input.forEach((t, i) => {
        const memberErrors = {};
        if (t && t.start_time && t.end_time) {
          if (!moment(t.end_time).isAfter(moment(t.start_time))) {
            memberErrors.end_time = 'Must be after start time';
            clipErrors[i] = memberErrors;
          }
        }
      });
    }
    if (clipErrors.length > 0) {
      errors.clips_input = clipErrors;
    }
    return errors;
  },
})(Audit));
