import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H3, HTMLTable, NonIdealState } from '@blueprintjs/core';

function floatToHour(num) {
  const sec_num = num * 3600;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = `0${hours}`; }
  if (minutes < 10) { minutes = `0${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }
  return `${hours}:${minutes}:${seconds}`;
}

const rowRenderer = row => (
  <tr>
    <td>{row.f1}</td>
    <td>{row.fn}</td>
    <td>{row.fp}</td>
    <td>{row.iter}</td>
    <td>{row.map}</td>
    <td>{row.precision}</td>
    <td>{row.recall}</td>
  </tr>
);

const reidRowRenderer = row => (
  <tr>
    <td>{row.iter}</td>
    <td>{row.batch}</td>
    <td>{floatToHour(row.time_remaining)}</td>
    <td>{row.loss}</td>
    <td>{row.accuracy}</td>
    <td>{row.learning_rate}</td>
  </tr>
);

const StatsTable = ({ stats, type }) => {
  if (!stats) {
    return <NonIdealState icon="warning-sign" title="No Data Found" className="non-ideal-device-card" />;
  }
  return (
    <Fragment>
      <H3>Training Stats</H3>
      <HTMLTable condensed bordered striped>
        <thead>
          { type !== 'reid' && (
          <tr>
            <th>f1</th>
            <th>fn</th>
            <th>fp</th>
            <th>iter</th>
            <th>map</th>
            <th>precision</th>
            <th>recall</th>
          </tr>
          )}
          { type === 'reid' && (
          <tr>
            <th>Iter</th>
            <th>Batch</th>
            <th>Time Remaining</th>
            <th>Loss</th>
            <th>Accuracy</th>
            <th>Learning Rate</th>
          </tr>
          )}
        </thead>
        <tbody>
          {type !== 'reid' && stats.map(rowRenderer)}
          {type === 'reid' && (stats.map(reidRowRenderer))}
        </tbody>
      </HTMLTable>
    </Fragment>
  );
};

StatsTable.propTypes = {
  stats: PropTypes.array,
  type: PropTypes.string,
};

export default StatsTable;
