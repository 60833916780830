/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, FormGroup, InputGroup, Icon, Spinner, Button, NonIdealState, MenuItem,
  Menu, Popover, Position,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import qs from 'query-string';
import { DateTime } from 'luxon';
import { replace, push } from 'connected-react-router';
import ReactPaginate from 'react-paginate-bp3';
import axios from 'axios';

import { getNvrs, getReleases } from 'actions/nvr';
import { getOrganizations } from 'actions/organization';

const filterOrg = (query, org) => org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
const renderTag = org => org.name;
export const networkStatus = status => DateTime.local().diff(DateTime.fromISO(status)).as('minutes') < 5;

class Nvrs extends Component {
  constructor(props) {
    super(props);
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const page = parseInt(search.p, 10) || 0;
    this.state = {
      query: search.q || '',
      assigned: search.a || '',
      monitored: search.m || '',
      page,
      orgs: [],
      version: search.v,
    };
    if (search.o) {
      this.state.orgs = (_.isArray(search.o) ? search.o : [search.o]).map(x => JSON.parse(x));
    }
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const {
      query, orgs, assigned, monitored, version,
    } = this.state;
    dispatch(getNvrs(
      query,
      assigned,
      monitored,
      (orgs ? orgs.map(o => o.id) : undefined),
      0,
      50,
      version,
    ));
    dispatch(getOrganizations());
    dispatch(getReleases());
  }

  @autobind
  onOrgSelect(org) {
    const { orgs } = this.state;
    if (_.some(orgs, x => x.id === org.id)) {
      this.setState({ orgs: orgs.filter(x => x.id !== org.id), page: 0 }, this.doRefresh);
    } else {
      this.setState({ orgs: [...orgs, org], page: 0 }, this.doRefresh);
    }
  }

  @autobind
  onOrgClear() {
    this.setState({ orgs: [], page: 0 }, this.doRefresh);
  }

  @autobind
  onOrgRemove(tag, index) {
    const { orgs } = this.state;
    this.setState({ orgs: orgs.filter((x, i) => i !== index), page: 0 }, this.doRefresh);
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const {
      query, orgs, assigned, page, monitored, version,
    } = this.state;
    // serializing json into url
    const org = (_.map(orgs, x => JSON.stringify(x)));

    dispatch(replace(`/nvrs?${qs.stringify(_.pickBy({
      q: query,
      p: page,
      a: assigned,
      m: monitored,
      o: org,
      v: version,
    }))}`));
    dispatch(getNvrs(
      query,
      assigned,
      monitored,
      (orgs ? orgs.map(o => o.id) : undefined),
      page * 50,
      50,
      version,
    ));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleAssigned(e) {
    this.setState({ assigned: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleMonitored(e) {
    this.setState({ monitored: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({ page: selected }, this.doRefresh);
  }

  @autobind
  handleVersion(e) {
    this.setState({ version: e.target.value || undefined, page: 0 }, this.doRefresh);
  }

  @autobind
  renderNvr(nvr, i) {
    if (nvr.id) {
      const { organizations } = this.props;
      const org = _.find(organizations.data || [], x => x.id === nvr.organization_id) || {};
      const isAssigned = !!nvr.organization_id;
      return (
        <tr key={i}>
          <td><Link to={`/nvrs/${nvr.id}`}>{nvr.id}</Link></td>
          <td><Link to={`/nvrs/${nvr.id}`}>{nvr.name}</Link></td>
          <td>
            {isAssigned && <Link to={`/organizations/${nvr.organization_id}`}>{org.name || nvr.organization_id}</Link>}
            {!isAssigned && 'Unassigned'}
          </td>
          <td>{DateTime.fromISO(nvr.created).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{nvr.reported_version}</td>
          <td className="has-text-centered"><Icon icon="cell-tower" intent={networkStatus(nvr.network_status) ? 'success' : 'danger'} /></td>
          <td className="has-text-centered"><Icon icon={nvr.monitored ? 'tick' : 'cross'} intent={nvr.monitored ? 'success' : ''} /></td>
        </tr>
      );
    }
    return null;
  }

  @autobind
  renderOrg(org, { handleClick, modifiers }) {
    const { orgs } = this.state;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={`${org.slug.slice(0, 5)}`}
        key={org.id}
        icon={_.some(orgs, x => x.id === org.id) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={org.name}
      />
    );
  }

  renderTable() {
    const {
      page,
      query,
      assigned,
      monitored,
      orgs,
      version,
    } = this.state;
    // eslint-disable-next-line camelcase
    const { nvrs, token: access_token } = this.props;
    const organization = orgs.map(o => JSON.stringify(o.id));
    return (
      <div>
        <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
          <ReactPaginate
            previousLabel={<Icon icon="chevron-left" />}
            nextLabel={<Icon icon="chevron-right" />}
            breakLabel="..."
            pageCount={Math.ceil(((nvrs.data.meta || {}).total || 0) / 50)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={page}
            onPageChange={this.handlePageClick}
            containerClassName="bp3-button-group"
            pageClassName="bp3-button"
            previousClassName="bp3-button"
            nextClassName="bp3-button"
            disabledClassName="bp3-disabled"
            breakClassName="bp3-button bp3-disabled"
            activeClassName="bp3-active"
          />

          <a
            role="button"
            className="bp3-button"
            style={{ verticalAlign: 'bottom', position: 'absolute', right: 0 }}
            href={`${axios.defaults.baseURL}/v1/admin/nvrs/.csv?${qs.stringify({
              query,
              version: version || undefined,
              assigned,
              monitored,
              organization,
              access_token,
            })}`}
          >
            Export
          </a>

        </div>
        <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
          <thead>
            <tr>
              <th>NVR ID</th>
              <th>Name</th>
              <th>Organization</th>
              <th>Created</th>
              <th>Version</th>
              <th className="has-text-centered">Network</th>
              <th className="has-text-centered">Monitored</th>
            </tr>
          </thead>
          <tbody>
            {(nvrs.data || []).map(this.renderNvr)}
          </tbody>
        </table>
        <div className="has-text-centered p-t-md">
          <ReactPaginate
            previousLabel={<Icon icon="chevron-left" />}
            nextLabel={<Icon icon="chevron-right" />}
            breakLabel="..."
            pageCount={Math.ceil(((nvrs.data.meta || {}).total || 0) / 50)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={page}
            onPageChange={this.handlePageClick}
            containerClassName="bp3-button-group"
            pageClassName="bp3-button"
            previousClassName="bp3-button"
            nextClassName="bp3-button"
            disabledClassName="bp3-disabled"
            breakClassName="bp3-button bp3-disabled"
            activeClassName="bp3-active"
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      query, assigned, orgs, monitored, version,
    } = this.state;
    const {
      nvrs, organizations, dispatch, releases,
    } = this.props;
    const spinner = (nvrs || {}).pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;
    const clearOrgButton = orgs.length > 0 ? <Button icon="cross" minimal onClick={this.onOrgClear} /> : null;
    const versions = ((releases || {}).data || []).map(r => r.version);

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  NVRs
                </H1>
              </div>
              <div className="column p-b-none" style={{ textAlign: 'right', alignSelf: 'center' }}>
                <Popover
                  content={
                    (
                      <Menu>
                        <Menu.Item icon="desktop" text="NVR" onClick={() => dispatch(push('/nvrs/releases'))} />
                      </Menu>
                    )
                  }
                  position={Position.BOTTOM_RIGHT}
                >
                  <Button className="is-pulled-right" rightIcon="caret-down" text="Software Release Manager" />
                </Popover>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-right" style={{ minWidth: '80%' }}>
                    <FormGroup
                      label="Organizations"
                      labelFor="organizations"
                      className="inline-flex m-r-md"
                    >
                      <MultiSelect
                        resetOnSelect
                        items={organizations.data || []}
                        itemPredicate={filterOrg}
                        itemRenderer={this.renderOrg}
                        tagRenderer={renderTag}
                        noResults={<MenuItem disabled text="No results." />}
                        selectedItems={orgs}
                        onItemSelect={this.onOrgSelect}
                        popoverProps={{ minimal: true }}
                        tagInputProps={{
                          onRemove: this.onOrgRemove,
                          rightElement: clearOrgButton,
                          placeholder: 'All Organizations',
                          className: 'min-width-tag-input',
                        }}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Assigned"
                      labelFor="user-status"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="user-status" value={assigned} onChange={this.handleAssigned}>
                          <option value="">--</option>
                          <option value="true">Assigned</option>
                          <option value="false">Unassigned</option>
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup
                      label="Monitored"
                      labelFor="user-status"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="user-status" value={monitored} onChange={this.handleMonitored}>
                          <option value="">--</option>
                          <option value="true">Monitored</option>
                          <option value="false">Unmonitored</option>
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup
                      label="Version"
                      labelFor="version-list"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="version-list" value={version} onChange={this.handleVersion}>
                          <option value="">--</option>
                          {versions.map(v => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          ))}
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((nvrs.data || []).length) && <NonIdealState icon="search" title="No results." />}
            {!!((nvrs.data || []).length) && this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Nvrs.propTypes = {
  dispatch: PropTypes.func,
  nvrs: PropTypes.object,
  organizations: PropTypes.object,
  location: PropTypes.object,
  releases: PropTypes.object,
  token: PropTypes.string,
};

export default connect(state => ({
  nvrs: state.nvrs,
  organizations: state.organizations,
  releases: state.nvrRelease.releases,
  token: state.currentUser.token.access_token,
}))(Nvrs);
