import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, reset,
} from 'redux-form';
import {
  Button, Card, Slider, Icon, Tooltip,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { FormSelectInput, CheckboxInput } from 'components/inputs';

import { getDevice, patchVisionParameters } from 'actions/device';
import DisplayHours from './displayHours';

const renderLabel = val => `${Math.round(val * 100)}%`;

class CMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dim: props.initialValues.dim,
      operating_dim: props.initialValues.operating_dim,
    };
  }

  @autobind
  handleSave(values) {
    const { dispatch, device } = this.props;
    const { dim, operating_dim } = this.state;
    return dispatch(patchVisionParameters(device.id, {
      output_mode: values.output_mode,
      output_transform: values.output_transform,
      lidl_cms: values.lidl_cms,
      cms_maintenance: values.cms_maintenance,
      cms_renderer: values.cms_renderer,
      salvation_army_cms: values.salvation_army_cms,
      dim: Math.round(dim * 100) / 100,
      operating_dim: Math.round(operating_dim * 100) / 100,
      sunrise_sunset: values.sunrise_sunset,
    }))
      .then(() => dispatch(getDevice(device.id)))
      .then(() => dispatch(reset('iap-cms-config')));
  }

  @autobind
  handleDimness(value) {
    this.setState({ dim: value });
  }

  @autobind
  handleOperatingDimness(value) {
    this.setState({ operating_dim: value });
  }

  render() {
    const {
      submitting, handleSubmit, device, cmsRenderer,
    } = this.props;
    const { dim, operating_dim } = this.state;

    const dimnessInfo = (
      <span>
        Dimness can be set only when CMS renderer is set to&nbsp;
        <strong>direct renderer</strong>
      </span>
    );
    const sunriseSunsetInfo = (
      <span>
        Overrides Display Operating Hours using sunrise/sunset for the day
      </span>
    );
    return (
      <div className="columns">
        <div className="column is-half">
          <form onSubmit={handleSubmit(this.handleSave)} style={{ height: '100%' }}>
            <Card style={{ height: '100%' }}>
              <Field
                label="Resolution"
                name="output_mode"
                component={FormSelectInput}
              >
                <option value="preferred">Preferred</option>
                <option value="1920x1080">1080p</option>
              </Field>
              <Field
                label="Rotation"
                name="output_transform"
                component={FormSelectInput}
              >
                <option value="normal">Normal</option>
                <option value="90">90° Clockwise</option>
                <option value="180">180°</option>
                <option value="270">270° Clockwise</option>
                <option value="flipped">Flipped</option>
                <option value="flipped-90">Flipped 90° Clockwise</option>
                <option value="flipped-180">Flipped 180°</option>
                <option value="flipped-270">Flipped 270° Clockwise</option>
              </Field>
              <Field
                label="Renderer"
                name="cms_renderer"
                component={FormSelectInput}
              >
                <option value="webkit">Webkit</option>
                <option value="direct">Direct</option>
              </Field>
              <Field
                component={CheckboxInput}
                type="checkbox"
                name="cms_maintenance"
                label="Maintenance Mode"
              />
              <Field
                component={CheckboxInput}
                type="checkbox"
                name="lidl_cms"
                label="Lidl CMS Override"
              />
              <Field
                component={CheckboxInput}
                type="checkbox"
                name="salvation_army_cms"
                label="Salvation Army CMS Override"
              />
              <br />
              <div>
                <p>
                  Display Dimness
                  &nbsp;
                  <Tooltip content={dimnessInfo}>
                    <Icon icon="info-sign" />
                  </Tooltip>
                </p>
                <div style={{ margin: 10 }}>
                  <p>
                    Outside Operating Hours
                  </p>
                  <div style={{ margin: '5px 20px 10px 10px' }}>
                    <Slider
                      min={0}
                      max={1}
                      labelRenderer={renderLabel}
                      value={dim || 0}
                      onChange={this.handleDimness}
                      stepSize={0.05}
                      disabled={cmsRenderer !== 'direct'}
                    />
                  </div>
                </div>
                <div style={{ margin: 10 }}>
                  <p>
                    Within Operating Hours
                  </p>
                  <div style={{ margin: '5px 20px 10px 10px' }}>
                    <Slider
                      min={0}
                      max={1}
                      labelRenderer={renderLabel}
                      value={operating_dim || 0}
                      onChange={this.handleOperatingDimness}
                      stepSize={0.05}
                      disabled={cmsRenderer !== 'direct'}
                    />
                  </div>
                </div>
                <div style={{ margin: 10 }}>
                  <span style={{ display: 'inline-block' }}>
                    <Field
                      component={CheckboxInput}
                      type="checkbox"
                      name="sunrise_sunset"
                      label="Sunrise/Sunset"
                      disabled={cmsRenderer !== 'direct'}
                    />
                  </span>
                  &nbsp;&nbsp;
                  <Tooltip content={sunriseSunsetInfo}>
                    <Icon icon="info-sign" />
                  </Tooltip>
                </div>
              </div>
              <div className="has-text-right">
                <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                  Save
                </Button>
              </div>
            </Card>
          </form>
        </div>
        <div className="column is-half">
          <DisplayHours deviceId={device.id} />
        </div>
      </div>
    );
  }
}

CMS.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
  cmsRenderer: PropTypes.string,
  initialValues: PropTypes.object,
};

export default connect(state => ({
  cmsRenderer: ((state.form['iap-cms-config'] || {}).values || {}).cms_renderer,
}))(reduxForm({
  form: 'iap-cms-config',
  enableReinitialize: true,
})(CMS));
