/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import classNames from 'classnames';
import {
  Spinner, H1, H2, H3, H4, H5, Callout, Card, FormGroup, Button, Drawer, Classes,
  Icon, Tooltip, Tag,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { replace, push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';

import {
  auditClip, updateGroundTruth, createTag, getClipTags, deleteTag, getAudit,
} from 'actions/audit';
import AuditSelect from './audit_select';
import AssignedSelect from './assign_select';

window.mediainfo = null;
window.MediaInfo({ chunkSize: 256 * 1024, coverData: false, format: 'object' }, (mediainfo) => {
  window.mediainfo = mediainfo;
}, () => { });

const baseUrl = (u) => {
  const url = new URL(u);
  url.search = '';
  return url.toString();
};

const zpad = (stra, num = 2, pad = '0') => {
  let str = `${stra}`;
  while (str.length < num) {
    str = pad + str;
  }
  return str;
};

const tagIntent = (tag, isStart) => {
  if (tag.id) {
    return 'success';
  }
  if (isStart) {
    if (tag.start_second) {
      return 'primary';
    }
    return undefined;
  }
  if (tag.end_second) {
    return 'primary';
  }
  return undefined;
};

const tc = (seconds) => {
  const MINUTE = 60;
  const HOUR = 60 * 60;

  // eslint-disable-next-line no-bitwise
  const ss = ~~seconds % MINUTE;
  // eslint-disable-next-line no-bitwise
  const ms = ~~((seconds - ~~seconds) * 1000);
  // eslint-disable-next-line no-bitwise
  const mm = ~~((seconds % HOUR) / MINUTE);
  // eslint-disable-next-line no-bitwise
  const hh = ~~(seconds / HOUR);

  return `${zpad(hh)}:${zpad(mm)}:${zpad(ss)}:${zpad(ms, 3)}`;
};

const Desc = ({
  icon, title, children, intent, noPad,
}) => (
  <H5 className={classNames({ 'bp3-intent-danger': intent === 'danger' })}>
    <Icon icon={icon} intent={intent} />
    &nbsp;
    {title}
    &nbsp;&nbsp;
    <div className="bp3-ui-text bp3-text-muted">
      { !noPad && <Icon icon="blank" /> }
      { !noPad && <span>&nbsp;</span> }
      {children}
    </div>
  </H5>
);

Desc.propTypes = {
  intent: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  noPad: PropTypes.bool,
};

const initials = (name) => {
  const xinitials = name.match(/\b\w/g) || [];
  return ((xinitials.shift() || '') + (xinitials.pop() || '')).toUpperCase();
};

const ERRORS = [
  { name: 'Back and Forth', description: 'Person walks to and from the line adding extra ins and outs.' },
  { name: 'Miss', description: 'Model does not count a person at all.' },
  { name: 'False Detection', description: 'Model counts anything that is not a person.' },
  { name: 'Shadow', description: 'Model detects the shadow of the person adding false ins/outs.' },
  { name: 'Kid', description: 'Kids cause erroneous counts or missed detections.' },
  { name: 'Partial Miss', description: 'Model detects person after they have already crossed the line.' },
  { name: 'Counting Line', description: 'If we miss someone due to the counting line being too short.' },
  { name: 'Double Count', description: 'Single person gets two tracks and gets counted twice.' },
  { name: 'Corrupt Frame', description: 'Video skips or shows corruptions in the stream.' },
  { name: 'Kalman Filter', description: 'When a person loses their track as they are crossing the counting line and then get a new track on the other side of the line.' },
  { name: 'Bug', description: 'Some vision-rx counting bug, where you either expected a count and person didnt get counted or when you didnt expect a count and person got counted.' },
  { name: 'Iou', description: 'When there are 2 people contained within a single bounding box causing a missed count.' },
  { name: 'Other', description: 'No error occurred in counts, but this section of the clip had other issues that should be further examined by the data team.' },
  { name: 'Baby', description: 'A small child in a stroller or cart, that is unlikely to be able to walk on its own and gets missed.' },
];

const ErrorTable = () => (
  <table className="bp3-html-table bp3-interactive bp3-html-table-condensed">
    <thead>
      <tr>
        <th>Error Type</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      {ERRORS.map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={i}>
          <td><Tag>{x.name}</Tag></td>
          <td>{x.description}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

class Clip extends Component {
  constructor(props) {
    super(props);
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const audit = search.audit || null;
    const lineId = parseInt(search.line_id, 10) || 0;

    this.video = React.createRef();
    this.state = {
      fps: 0,
      ins: 0,
      outs: 0,
      speed: '1.0',
      vc: null,
      metaError: null,
      saving: false,
      showHelp: false,
      audit,
      loadedUrl: null,
      ws: null,
      viewers: [],
      tags: [],
      lineId,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.reloadClip(match.params.id);
    if (this.video.current) {
      this.setState({ vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback) });
    }
    document.addEventListener('keydown', this.handleKeyPress);
    this.ismounted = true;
    this.openSocket();
  }

  componentDidUpdate(prevProps, prevState) {
    const { speed } = this.state;
    const { match } = this.props;
    if (this.video.current && prevState.speed !== speed) {
      this.video.current.playbackRate = parseFloat(speed);
    }
    if (match.params.id !== prevProps.match.params.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ins: 0, outs: 0, speed: '1.0' }, () => this.reloadClip(match.params.id));
    }
    this.ismounted = false;
  }

  componentWillUnmount() {
    const { vc } = this.state;
    if (this.video.current) {
      this.video.current.cancelVideoFrameCallback(vc);
    }
    document.addEventListener('keydown', this.handleKeyPress);
    this.closeSocket();
  }

  @autobind
  openSocket() {
    if (!this.ismounted) {
      return;
    }
    const { accessToken, match } = this.props;
    const apiHost = process.env.API_HOST ? process.env.API_HOST : 'ws://localhost:4729';
    const apiLink = process.env.NODE_ENV === 'production' ? 'wss://api.livereachmedia.com' : apiHost;
    const socket = new WebSocket(`${apiLink}/api/v1/audit/clips/${match.params.id}/view?access_token=${accessToken}`);
    socket.addEventListener('open', () => {
      this.setState({ ws: socket });
    });
    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.kind === 'viewers') {
        this.setState({ viewers: data.viewers });
      }
    });
    socket.addEventListener('close', () => {
      this.setState({ ws: null });
      setTimeout(this.openSocket, 1000);
    });
  }

  closeSocket() {
    const { ws } = this.state;
    if (ws) {
      ws.close();
      this.setState({ ws: null });
    }
  }

  isPlaying() {
    const video = this.video.current;
    const playing = video && !!(
      video.currentTime > 0
      && !video.paused
      && !video.ended
      && video.readyState > 2);
    return playing;
  }

  @autobind
  handleScreenshot() {
    const video = this.video.current;
    const canvas = document.createElement('canvas');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const link = document.createElement('a');
    link.download = 'ss.png';
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  @autobind
  handleHelp() {
    this.setState({ showHelp: true });
  }

  @autobind
  handleCloseHelp() {
    this.setState({ showHelp: false });
  }

  @autobind
  handleKeyPress(e) {
    if (e.target.tagName === 'INPUT' || document.activeElement.tagName === 'SELECT') {
      return;
    }
    const video = this.video.current;
    const {
      ins, outs, speed, tags,
    } = this.state;
    const mod = e.ctrlKey ? -1 : 1;
    if (e.key === '.') {
      this.handleForward();
    } else if (e.key === ',') {
      this.handleBackward();
    } else if (e.keyCode === 8) {
      this.handlePlayback('1.0');
    } else if (e.key === '[') {
      let s = parseFloat(speed);
      if (s > 1) {
        s = parseFloat(speed) - 1;
        this.setState({ speed: s.toFixed(1) });
      } else if (s === 1) {
        this.setState({ speed: '0.5' });
      } else if (s === 0.5) {
        this.setState({ speed: '0.25' });
      }
    } else if (e.key === ']') {
      let s = parseFloat(speed);
      if (s === 0.25) {
        this.setState({ speed: '0.5' });
      } else if (s === 0.5) {
        this.setState({ speed: '1.0' });
      } else if (s < 6) {
        s += 1;
        this.setState({ speed: s.toFixed(1) });
      }
    } else if (e.key === 'o') {
      this.setState({ ins: ins + mod });
    } else if (e.key === 'p') {
      this.setState({ outs: outs + mod });
    } else if (e.key === 'r') {
      this.setState({ ins: 0, outs: 0 });
    } else if (e.key === 's') {
      this.handleScreenshot();
    } else if (e.key === 'm' && video) {
      const currentTag = _.maxBy(tags, x => x.order) || {};
      if (e.ctrlKey) {
        if (currentTag.end_second || !currentTag) {
          AppToaster.show({
            intent: 'danger',
            message: <span>Select start time first</span>,
            icon: 'cross',
          });
        } else {
          currentTag.end_second = video.currentTime;
          this.setState({ tags });
        }
      } else if (tags.length && !currentTag.end_second) {
        AppToaster.show({
          intent: 'danger',
          message: <span>Select end time first</span>,
          icon: 'cross',
        });
      } else {
        const newEntry = {
          start_second: video.currentTime,
          end_second: null,
          value: '',
          order: currentTag.order + 1 || 1,
        };
        this.setState({ tags: [...tags, newEntry] });
      }
    }
  }

  @autobind
  handlePlayback(e) {
    const speed = _.isString(e) ? e : e.target.value;
    this.setState({ speed });
  }

  @autobind
  handleVideoCallback() {
    this.setState({
      vc: this.video.current.requestVideoFrameCallback(this.handleVideoCallback),
    });
  }

  reloadTags(auditId, clip_name) {
    const { dispatch, clip } = this.props;
    dispatch(getClipTags(auditId, clip_name))
      .then((result) => {
        if (result.payload.data.content) {
          const tags = result.payload.data.content.filter(x => x.line_id === null || x.line_id === clip.data.audit.line_id).map((x, i) => ({
            start_second: x.start_second,
            end_second: x.end_second,
            value: x.tags,
            id: x.id,
            order: i + 1,
          }));
          this.setState({ tags });
        }
      });
  }

  reloadClip(clipID) {
    const { dispatch } = this.props;
    const { audit, lineId } = this.state;
    const validLineId = lineId === 0 ? undefined : lineId;
    const validAudit = audit == null ? undefined : audit;
    dispatch(auditClip(clipID, validAudit, validLineId))
      .then((action) => {
        const { loadedUrl } = this.state;
        const clip = action.payload.data.content;
        const { ground_truth, ground_truth_ins, ground_truth_outs } = clip || {};
        if (ground_truth) {
          this.setState({ ins: ground_truth_ins, outs: ground_truth_outs });
        }
        dispatch(getAudit(clip.audit.audit_id));
        this.reloadTags(clip.audit.audit_id, clip.file_name);
        const videoPath = clip.audit.video_path || clip.video_path;
        if (loadedUrl !== videoPath) {
          return fetch(videoPath);
        }
        return false;
      })
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          const size = parseInt(response.headers.get('content-length'), 10);
          const getSize = () => size;
          const readChunk = (chunkSize, offset) => new Promise((resolve, reject) => {
            fetch(response.url, { headers: { range: `bytes=${offset}-${offset + chunkSize - 1}` } })
              .then(r => r.arrayBuffer())
              .then((buffer) => {
                const view = new Uint8Array(buffer);
                resolve(view);
              })
              .catch(err => reject(err));
          });
          window.mediainfo.analyzeData(getSize, readChunk)
            .then((result) => {
              const v = (result.media.track || []).find(x => x['@type'] === 'Video');
              if (v) {
                this.setState({ fps: parseFloat(v.FrameRate), loadedUrl: baseUrl(response.url) });
              } else {
                this.setState({ fps: -1 });
              }
            })
            .catch((err) => {
              this.setState({ fps: null, metaError: err });
            });
        }
      });
  }

  @autobind
  handleForward() {
    let { fps } = this.state;
    const video = this.video.current;
    if (this.isPlaying()) {
      video.pause();
    }
    if (fps === 0) fps = 25;
    video.currentTime += 1 / fps;
  }

  @autobind
  handleBackward() {
    let { fps } = this.state;
    const video = this.video.current;
    if (this.isPlaying()) {
      video.pause();
    }
    if (fps === 0) fps = 25;
    video.currentTime -= 1 / fps;
  }

  @autobind
  handlePlay() {
    const video = this.video.current;
    const playing = this.isPlaying();
    if (playing) {
      video.pause();
    } else {
      video.play();
    }
    this.forceUpdate();
  }

  @autobind
  async handleSaveGroundTruth() {
    const { clip, dispatch, match } = this.props;
    const { ins, outs, tags } = this.state;
    const { audit, file_name, camera_serial } = clip.data;
    const validTags = (() => {
      if (tags.length) {
        return tags.every(x => x.start_second && x.end_second && x.value);
      }
      return true;
    })();
    if (!validTags) {
      AppToaster.show({
        intent: 'danger',
        message: <span>Tag entry incomplete</span>,
        icon: 'cross',
      });
    } else {
      this.setState({ saving: true });
      try {
        if (tags.length) {
          const errorTags = tags.filter(y => !y.id).map(x => ({
            start_second: x.start_second,
            end_second: x.end_second,
            tags: x.value,
            line_id: audit.line_id,
          }));
          if (errorTags.length) {
            await Promise.all(errorTags.map(et => dispatch(createTag(audit.audit_id, file_name, et))));
          }
        }
        dispatch(updateGroundTruth(file_name, camera_serial, { ins, outs, line_id: audit.line_id }))
          .then(() => this.setState({
            saving: false, tags: [], ins: 0, outs: 0,
          }))
          .then(() => this.reloadClip(match.params.id));
      } catch (action) {
        this.setState({ saving: false });
      }
    }
  }

  @autobind
  handleAudit(e) {
    const { dispatch, match } = this.props;
    dispatch(replace(`/audits/groundtruth/${match.params.id}?${qs.stringify(_.pickBy({
      audit: e || null,
    }))}`));

    this.setState({ audit: e || null }, () => this.reloadClip(match.params.id));
  }

  @autobind
  handleTag(e, id) {
    const { tags } = this.state;
    const currentTag = tags.find(x => x.order === parseInt(id, 10));
    currentTag.value = e.target.value;
    this.setState({ tags });
  }

  @autobind
  handleRemoveTag(tag) {
    const { tags } = this.state;
    const { dispatch, clip } = this.props;
    if (tag.id) {
      dispatch(deleteTag(clip.data.audit.audit_id, tag.id))
        .then(() => this.reloadTags(clip.data.audit.audit_id, clip.data.file_name));
    }
    this.setState({ tags: [...tags].filter(x => x.order !== tag.order) });
  }

  @autobind
  jumpToTime(t) {
    const video = this.video.current;
    if (video) {
      video.currentTime = t;
    }
  }

  @autobind
  renderNoClip() {
    const { location, dispatch } = this.props;
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const audit = search.audit || null;
    return (
      <div style={{ textAlign: 'center', paddingTop: 40 }}>
        <Icon iconSize={40} icon="error" style={{ marginBottom: 10 }} />
        <H2>Error Fetching Clip</H2>
        <Button
          style={{ marginTop: 10 }}
          large
          intent="primary"
          onClick={() => dispatch(push(`/audits/groundtruth?a=${audit}`))}
          icon="properties"
        >
          Groundtruth Clips
        </Button>
      </div>
    );
  }

  @autobind
  renderTag(tag) {
    return (
      <div
        style={{
          display: 'flex', alignItems: 'center', marginBottom: 5,
        }}
        key={tag.order}
      >
        <div>
          <Tag style={{ cursor: 'pointer' }} intent={tagIntent(tag, true)} onClick={() => this.jumpToTime(tag.start_second)}>
            {tc(tag.start_second)}
          </Tag>
        </div>
        &nbsp;-&nbsp;
        <div>
          <Tag style={{ cursor: 'pointer' }} intent={tagIntent(tag)} onClick={() => this.jumpToTime(tag.end_second)}>
            {tc(tag.end_second)}
          </Tag>
        </div>
        <div style={{ margin: '0px 10px' }}>
          <div className="bp3-select">
            <select value={tag.value} onChange={e => this.handleTag(e, tag.order)}>
              <option value="">--</option>
              <option value="back and forth">Back and Forth</option>
              <option value="miss">Miss</option>
              <option value="false detection">False Detection</option>
              <option value="shadow">Shadow</option>
              <option value="partial miss">Partial Miss</option>
              <option value="counting line">Counting Line</option>
              <option value="double count">Double Count</option>
              <option value="corrupt frame">Corrupt Frame</option>
              <option value="kalman filter">Kalman Filter</option>
              <option value="bug">Bug</option>
              <option value="iou">Iou</option>
              <option value="kid-miss">Kid-Miss</option>
              <option value="kid-partial miss">Kid-Partial Miss</option>
              <option value="kid-iou">Kid-Iou</option>
              <option value="other">Other</option>
              <option value="baby">Baby</option>
            </select>
          </div>
        </div>
        <div>
          <Button intent="danger" outlined onClick={() => this.handleRemoveTag(tag)} icon="cross" />
        </div>
      </div>
    );
  }

  renderAuditInfo() {
    const { clip } = this.props;
    const { audit } = this.state;
    if (!clip.data.audit.audit_id) {
      return (
        <Card className="m-b-md">
          <AuditSelect
            value={audit ? parseInt(audit, 10) : null}
            onChange={this.handleAudit}
            clip={clip.data.file_name}
            includeReset
          />
        </Card>
      );
    }
    return (
      <Card className="m-b-md">
        <AuditSelect
          value={audit ? parseInt(audit, 10) : null}
          onChange={this.handleAudit}
          className="m-b-sm"
          clip={clip.data.file_name}
          includeReset
        />
        <Desc icon="id-number" title="Audit ID">
          <Fragment>
            <Link to={`/audits/${clip.data.audit.audit_id}`}>
              {clip.data.audit.audit_id}
            </Link>
            &nbsp;|&nbsp;
            <Link to={`/audits/groundtruth?a=${clip.data.audit.audit_id}&s=-1`}>
              GroundTruth Clips
            </Link>
          </Fragment>
        </Desc>
        <Desc icon="label" title="Audit Name">{clip.data.audit.name}</Desc>
      </Card>
    );
  }

  render() {
    const { clip, modelAudit, match } = this.props;
    const {
      fps, ins, outs, speed, vc, metaError, saving, showHelp, viewers, tags,
    } = this.state;
    const video = this.video.current;
    if (!video) {
      setTimeout(() => this.forceUpdate(), 0);
    }
    const playing = this.isPlaying();
    if (clip.error && !clip.pending) {
      return this.renderNoClip();
    }
    if (!(clip.data && clip.data.id)) {
      return <div className="has-text-centered"><Spinner size="100" className="rdtCounters" /></div>;
    }
    if (!vc && video) {
      this.setState({ vc: video.requestVideoFrameCallback(this.handleVideoCallback) });
    }
    const videoPath = clip.data.audit.video_path || clip.data.video_path;
    const timecode = video && video.currentTime;
    let modelData = (modelAudit || {})
      .data.find(x => x.clip_name === (clip.data || {}).file_name) || {};
    const auditLineId = (clip.data || {}).audit ? clip.data.audit.line_id : 0;
    // console.log(auditLineId);
    if (auditLineId !== undefined && auditLineId !== 0) {
      modelData = (modelAudit || {})
        // eslint-disable-next-line max-len
        .data.find(x => x.clip_name === (clip.data || {}).file_name && x.line_id === auditLineId) || modelData;
    }
    const noModelData = _.isEmpty(modelData);
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Audit:&nbsp;
                  {clip.data.file_name}
                </H1>
                { fps === -1 && <Callout intent="danger" className="m-b-md" title="Media metadata failed to load; frame stepping disabled" />}
                { !!metaError && <Callout intent="danger" className="m-b-md" title={`Media metadata failed to load: ${metaError}`} />}
                <div className="columns">
                  <div className="column is-one-third">
                    { this.renderAuditInfo() }
                    <Card>
                      <div style={{ float: 'right' }}>
                        <Button intent="primary" className="m-l-sm" onClick={this.handleHelp}>Help</Button>
                      </div>
                      <Desc icon="id-number" title="Clip ID">{clip.data.id}</Desc>
                      <Desc icon="person" title="Assigned">
                        {modelData.assigned ? `${modelData.assigned}` : 'unassigned'}
                      </Desc>
                      <div style={{ margin: '10px 0px' }}>
                        {modelData.assigned && (
                          <AssignedSelect
                            person={modelData.assigned}
                            audit={modelData.id}
                            match={match}
                          />
                        )}
                      </div>
                      <Desc icon="predictive-analysis" title="Model" intent={!noModelData ? undefined : 'danger'}>
                        {!noModelData && `In: ${modelData.model_ins} Out: ${modelData.model_outs}`}
                        {noModelData && 'n/a'}
                      </Desc>
                      <Desc icon="label" title="Clip Name">{clip.data.file_name}</Desc>
                      <Desc icon="bring-data" title="Ground Truth" intent={clip.data.ground_truth ? undefined : 'danger'}>
                        {!!clip.data.ground_truth && `In: ${clip.data.ground_truth_ins} Out: ${clip.data.ground_truth_outs}`}
                        {!clip.data.ground_truth && 'n/a'}
                      </Desc>
                      <Desc icon="satellite" title="Device"><Link to={`/devices/${clip.data.device.id}`}>{clip.data.device.name}</Link></Desc>
                      <Desc icon="camera" title="Camera"><Link to={`/devices/${clip.data.camera.id}`}>{clip.data.camera.name}</Link></Desc>
                      <Desc icon="calendar" title="Start Time">{clip.data.start_time}</Desc>
                      <Desc icon="calendar" title="End Time">{clip.data.end_time}</Desc>
                      <Desc icon="eye-open" title="Viewers">
                        {
                          viewers.map(viewer => (
                            <Tooltip content={viewer.name} key={viewer.id}>
                              <Button intent="warning" style={{ borderRadius: '50px' }} className="m-r-sm">{initials(viewer.name)}</Button>
                            </Tooltip>
                          ))
                        }
                      </Desc>
                    </Card>

                  </div>
                  <div className="column">
                    <Card style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left',
                    }}
                    >
                      <div>
                        <FormGroup label="Playback Speed" style={{ display: 'inline-block' }}>
                          <div className="bp3-select">
                            <select name="filter" value={speed} onChange={this.handlePlayback}>
                              <option value="0.25">0.25x</option>
                              <option value="0.5">0.5x</option>
                              <option value="1.0">1x</option>
                              <option value="1.5">1.5x</option>
                              <option value="2.0">2x</option>
                              <option value="3.0">3x</option>
                              <option value="4.0">4x</option>
                              <option value="5.0">5x</option>
                              <option value="6.0">6x</option>
                            </select>
                          </div>
                        </FormGroup>
                        <div style={{ display: 'inline-block', float: 'right' }}>
                          <H2 style={{ display: 'inline-block' }}>
                            {ins}
                            &nbsp;
                            <span className="bp3-text-muted bp3-text-small">ins</span>
                          </H2>
                          <H2 style={{ display: 'inline-block' }} className="bp3-text-muted">&nbsp;|&nbsp;</H2>
                          <H2 style={{ display: 'inline-block' }}>
                            {outs}
                            &nbsp;
                            <span className="bp3-text-muted bp3-text-small">outs</span>
                          </H2>
                          &nbsp;
                          <Button intent="success" className="m-l-sm" loading={saving} onClick={this.handleSaveGroundTruth}>Save</Button>
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }}>
                        <div className="flex-space-between-container" style={{ marginBottom: 5 }}>
                          <div>
                            <H4>
                              <Icon icon="tag" />
                              &nbsp;
                              Tags
                            </H4>
                          </div>
                          <div>
                            <Icon icon="full-circle" intent="success" />
                            &nbsp;
                            Saved
                            &nbsp;
                            <Icon icon="full-circle" intent="primary" />
                            &nbsp;
                            New
                          </div>
                        </div>
                        {!!tags.length && tags.map(this.renderTag)}
                      </div>
                      <video width="100%" controls src={videoPath} ref={this.video} crossOrigin="anonymous" />
                      <div className="has-text-centered p-t-md">
                        {tc(timecode)}
                      </div>
                      <div className="has-text-centered p-t-md">
                        <Button icon="step-backward" onClick={this.handleBackward} />
                        <Button icon={playing ? 'pause' : 'play'} onClick={this.handlePlay} />
                        <Button icon="step-forward" onClick={this.handleForward} />
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          icon="info-sign"
          onClose={this.handleCloseHelp}
          title="Help"
          isOpen={showHelp}
          size={Drawer.SIZE_SMALL}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={`bp3-running-text ${Classes.DIALOG_BODY}`}>
              <H3>Shortcuts</H3>
              <ul className="bp3-list">
                <li>
                  <code className="bp3-code">o</code>
                  :
                  Increment In
                </li>
                <li>
                  <code className="bp3-code">p</code>
                  :
                  Increment Out
                </li>
                <li>
                  <code className="bp3-code">ctrl + o</code>
                  :
                  Decrement In
                </li>
                <li>
                  <code className="bp3-code">ctrl + p</code>
                  :
                  Decrement Out
                </li>
                <li>
                  <code className="bp3-code">r</code>
                  :
                  Reset In/Out Count
                </li>
                <li>
                  <code className="bp3-code">[</code>
                  :
                  Decrease Playback Speed
                </li>
                <li>
                  <code className="bp3-code">]</code>
                  :
                  Increase Playback Speed
                </li>
                <li>
                  <code className="bp3-code">Backspace</code>
                  :
                  Reset Playback Speed
                </li>
                <li>
                  <code className="bp3-code">,</code>
                  :
                  Step Back One Frame
                </li>
                <li>
                  <code className="bp3-code">.</code>
                  :
                  Step Forward One Frame
                </li>
                <li>
                  <code className="bp3-code">s</code>
                  :
                  Download screenshot
                </li>
                <li>
                  <code className="bp3-code">m</code>
                  : New Tag Start Time
                </li>
                <li>
                  <code className="bp3-code">ctrl + m</code>
                  : Close Tag End Time
                </li>
              </ul>
              <H3>Errors</H3>
              <ErrorTable />
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER} />
        </Drawer>
      </div>
    );
  }
}

Clip.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  clip: PropTypes.object,
  location: PropTypes.object,
  accessToken: PropTypes.string,
  modelAudit: PropTypes.object,
};

export default connect(state => ({
  clip: state.auditClip,
  accessToken: state.currentUser.token.access_token,
  clipTags: state.clipTags,
  modelAudit: state.audit,
  clips: state.clips,
}))(Clip);
