import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { NavTab, PermissionedPopover } from './NavTab';

const ORG = {
  LiveReach_Media: 1,
  LRM_Vision_Audit: 443,
};

const PERMISSION_GROUPS = {
  Organizations: [ORG.LiveReach_Media],
  Users: [ORG.LiveReach_Media],
  Devices: [ORG.LiveReach_Media],
  Nvrs: [ORG.LiveReach_Media],
  Sites: [ORG.LiveReach_Media],
  Zones: [ORG.LiveReach_Media],
  Locations: [ORG.LiveReach_Media],
  Pipelines: [ORG.LiveReach_Media],
  Audits: [ORG.LiveReach_Media, ORG.LRM_Vision_Audit],
  Dashboard: [ORG.LiveReach_Media],
  ModelTraining: [ORG.LiveReach_Media],
  Alerts: [ORG.LiveReach_Media],
};

const hasPermissions = (orgId, requiredPermissions) => requiredPermissions.includes(orgId);

const PermissionsGuard = ({
  orgId,
  requiredPermissions,
  renderNoPermissions,
  children,
}) => {
  if (hasPermissions(orgId, requiredPermissions)) {
    return <React.Fragment>{ children }</React.Fragment>;
  }

  return renderNoPermissions ? renderNoPermissions() : null;
};

PermissionsGuard.propTypes = {
  orgId: PropTypes.number,
  requiredPermissions: PropTypes.array,
  renderNoPermissions: PropTypes.func,
  renderWhilePending: PropTypes.bool,
  children: PropTypes.any,
};

const RequiredPermissions = connect(state => ({
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(PermissionsGuard);

Object.entries(PERMISSION_GROUPS).forEach(([name, orgIds]) => {
  const PermissionsComponent = ({
    renderNoPermissions,
    renderWhilePending,
    children,
    ...props
  }) => (
    <RequiredPermissions
      requiredPermissions={orgIds}
      renderNoPermissions={renderNoPermissions}
      renderWhilePending={renderWhilePending}
    >
      {React.cloneElement(children, props)}
    </RequiredPermissions>
  );

  PermissionsComponent.propTypes = {
    renderNoPermissions: PropTypes.func,
    renderWhilePending: PropTypes.bool,
    children: PropTypes.any,
  };

  RequiredPermissions[name] = PermissionsComponent;
});

export {
  NavTab,
  PermissionedPopover,
  RequiredPermissions as default,
};
