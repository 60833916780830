import qs from 'query-string';
import {
  GET_SITE, GET_SITE_DEVICES, GET_SITES, GET_ZONES, GET_ZONE,
  SAVE_SITE, SAVE_ZONE, SAVE_SITE_OP_HOURS, GET_SITE_OP_HOURS, POST_GROUNDTRUTH,
  GET_GROUNDTRUTH, GET_ZONE_DEVICES, ADD_ZONE_DEVICE, RESET_OCCUPANCY,
  PATCH_ZONE_CONFIGS, UPDATE_ZONE_TAB_PERMISSIONS, GET_AUDIT_SITES,
  GET_AUDIT_SITE_DEVICES,
} from '../constants';

export const getSite = siteId => ({
  type: GET_SITE,
  payload: {
    request: {
      method: 'get',
      url: `v1/admin/sites/${siteId}?status=true`,
    },
  },
});

export const getSiteDevices = siteId => ({
  type: GET_SITE_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `v1/admin/sites/${siteId}/devices?status=true`,
    },
  },
});

export const getAuditSiteDevices = siteId => ({
  type: GET_AUDIT_SITE_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `v1/audit/sites/${siteId}/devices?status=false`,
    },
  },
});

export const getSites = (query, limit, siteId) => ({
  type: GET_SITES,
  payload: {
    request: {
      method: 'get',
      url: `v1/admin/sites?${qs.stringify({
        status: 'true', query, limit, site_id: siteId,
      })}`,
    },
  },
});

export const getAuditSites = (query, limit, siteId) => ({
  type: GET_AUDIT_SITES,
  payload: {
    request: {
      method: 'get',
      url: `v1/audit/sites?${qs.stringify({
        status: 'false', query, limit, site_id: siteId,
      })}`,
    },
  },
});

export const getZones = orgId => ({
  type: GET_ZONES,
  payload: {
    request: {
      method: 'get',
      url: orgId ? `/v1/organizations/${orgId}/zones?status=true` : '/v1/admin/zones?status=true',
    },
  },
});

export const getZone = id => ({
  type: GET_ZONE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/zones/${id}?status=true&config=true`,
    },
  },
});

export const saveSite = (id, data) => ({
  type: SAVE_SITE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/sites/${id}`,
    },
  },
});

export const saveZone = (id, data) => ({
  type: SAVE_ZONE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/zones/${id}`,
    },
  },
});

export const getSiteOperatingHours = id => ({
  type: GET_SITE_OP_HOURS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/sites/${id}/operatinghours`,
    },
  },
});

export const saveSiteOperatingHours = (id, data) => ({
  type: SAVE_SITE_OP_HOURS,
  payload: {
    request: {
      method: 'put',
      data,
      url: `/v1/admin/sites/${id}/operatinghours`,
    },
  },
});

export const postGroundTruth = (id, data) => ({
  type: POST_GROUNDTRUTH,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/admin/zones/${id}/ground_truth`,
    },
  },
});

export const queryGroundTruth = (id, startTime, endTime, scale) => ({
  type: GET_GROUNDTRUTH,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/zones/${id}/ground_truth?${qs.stringify({
        startTime, endTime, scale,
      })}`,
    },
  },
});

export const getZoneDevices = zoneId => ({
  type: GET_ZONE_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `v1/admin/zones/${zoneId}/devices?status=true`,
    },
  },
});

export const addZoneDevice = (zoneId, data) => ({
  type: ADD_ZONE_DEVICE,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/zones/${zoneId}/devices`,
    },
  },
});

export const resetOccupancy = zoneId => ({
  type: RESET_OCCUPANCY,
  payload: {
    request: {
      method: 'post',
      url: `/v1/zones/${zoneId}/metrics/occupancy/reset`,
    },
  },
});

export const patchZoneConfigs = (zoneId, data) => ({
  type: PATCH_ZONE_CONFIGS,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/zones/${zoneId}/config`,
    },
  },
});

export const updateZoneTabPermissions = (zoneId, data) => ({
  type: UPDATE_ZONE_TAB_PERMISSIONS,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/zones/${zoneId}/tab_permissions`,
    },
  },
});
