/* eslint-disable react/no-unused-state */
// The linter doesn't detect the use of state when we use
// const { var } = this.state || {}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H1, Callout, Icon, Intent, NonIdealState, H3, Button, ButtonGroup, Alert,
  NumericInput, FormGroup, Tag, Spinner, Popover, Menu, Position, InputGroup,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import prettyMs from 'pretty-ms';

import { getPipelines, editPipeline, deletePipeline } from 'actions/pipeline';

class Pipeline extends Component {
  componentDidMount() {
    const { pipeline } = this.props;
    if (pipeline.metadata && pipeline.metadata.processing_time_key) {
      const intervalID = setInterval(this.updateProcessingTime, 60000);

      this.setState({ refreshInterval: intervalID });
      this.updateProcessingTime();
    }
  }

  componentWillUnmount() {
    const { refreshInterval } = this.state || {};
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
  }

  @autobind
  handleOpenScale() {
    const { pipeline } = this.props;
    this.setState({
      isScaleOpen: true,
      instanceCount: pipeline.instances,
    });
  }

  @autobind
  handleOpenSuspend() {
    this.setState({
      isSuspendOpen: true,
    });
  }

  @autobind
  handleOpenDelete() {
    this.setState({
      isDeleteOpen: true,
    });
  }

  @autobind
  handleOpenEditImage() {
    const { pipeline } = this.props;
    this.setState({
      dockerImage: pipeline.container.image,
      isEditImageOpen: true,
    });
  }

  @autobind
  handleCancel() {
    this.setState({
      isScaleOpen: false,
      isSuspendOpen: false,
      isDeleteOpen: false,
      isEditImageOpen: false,
    });
  }

  @autobind
  handleScaleInstanceChange(value) {
    this.setState({ instanceCount: value });
  }

  @autobind
  handleEditImageChange(event) {
    this.setState({ dockerImage: event.target.value });
  }

  @autobind
  handleScale() {
    const { dispatch, pipeline } = this.props;
    const { instanceCount } = this.state;
    return dispatch(editPipeline(pipeline.id, { instances: instanceCount }))
      .then(() => dispatch(getPipelines()))
      .then(() => this.setState({ isScaleOpen: false }));
  }

  @autobind
  handleEditImage() {
    const { dispatch, pipeline } = this.props;
    const { dockerImage } = this.state;
    if (!dockerImage) {
      return this.handleCancel();
    }
    return dispatch(editPipeline(pipeline.id, { container: { image: dockerImage } }))
      .then(() => dispatch(getPipelines()))
      .then(() => this.setState({ isEditImageOpen: false }));
  }

  @autobind
  handleSuspend() {
    const { dispatch, pipeline } = this.props;
    return dispatch(editPipeline(pipeline.id, { instances: 0 }))
      .then(() => dispatch(getPipelines()))
      .then(() => this.setState({ isSuspendOpen: false }));
  }

  @autobind
  handleDelete() {
    const { dispatch, pipeline } = this.props;
    return dispatch(deletePipeline(pipeline.id))
      .then(() => dispatch(getPipelines()))
      .then(() => this.setState({ isDeleteOpen: false }));
  }

  @autobind
  updateProcessingTime() {
    const { pipeline } = this.props;
    const params = {
      q: `SELECT median("99_percentile") FROM "${pipeline.metadata.processing_time_key}" WHERE time >= now() - 10m GROUP BY time(1m) fill(null);`,
      db: 'telegraf',
      epoch: 'ms',
    };
    axios.get('v1/admin/influxdb/query', { params })
      .then((response) => {
        const ms = _(response.data.results[0].series[0].values)
          .chain()
          .map(x => x[1])
          .filter(_.identity)
          .reduce((a, e, i) => (a * i + e) / (i + 1), 0)
          .value();
        this.setState({ avgProcTime: ms });
      });
  }

  renderHeader() {
    const { pipeline } = this.props;
    const { instances } = pipeline;
    const ok = _.filter(pipeline.tasks, t => t.ok).length;
    return (
      <div>
        {pipeline.id}
        <div style={{ float: 'right' }}>{`${ok} / ${instances}`}</div>
      </div>
    );
  }

  render() {
    const { pipeline } = this.props;
    const {
      avgProcTime, isScaleOpen,
      isSuspendOpen, isDeleteOpen,
      instanceCount, isEditImageOpen,
      dockerImage,
    } = this.state || {};
    const cpu = pipeline.executor_resources.cpu
      + _.reduce(pipeline.processes, (m, n) => m + n.resources.cpu, 0);
    const mem = pipeline.executor_resources.mem
      + _.reduce(pipeline.processes, (m, n) => m + n.resources.mem, 0);
    const failures = _.reduce(pipeline.tasks, (m, n) => m + n.failures, 0);
    const intent = (() => {
      if (pipeline.instances === 0) {
        return Intent.WARNING;
      }
      const okTasks = _.filter(pipeline.tasks, t => t.ok);
      const isFailing = _.some(pipeline.tasks, t => t.failing);
      if (okTasks.length >= pipeline.instances) {
        return Intent.SUCCESS;
      }
      if (!isFailing) {
        return Intent.PRIMARY; // scaling
      }
      return Intent.DANGER;
    })();
    const title = this.renderHeader(pipeline);
    const icon = (() => {
      if (intent === Intent.WARNING) {
        return 'moon';
      }
      if (intent === Intent.PRIMARY) {
        return 'zoom-to-fit';
      }
      return undefined;
    })();
    const created = moment(pipeline.created);
    const updated = moment(pipeline.updated);
    const roles = (pipeline.roles && pipeline.roles.length) ? pipeline.roles : [<em>Any</em>];
    return (
      <Callout title={title} intent={intent} style={{ marginBottom: 20 }} icon={icon}>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Scale"
          icon="changes"
          isOpen={isScaleOpen}
          onCancel={this.handleCancel}
          onConfirm={this.handleScale}
        >
          <FormGroup
            label="Instances"
            fill
          >
            <NumericInput
              fill
              value={instanceCount}
              min={0}
              onValueChange={this.handleScaleInstanceChange}
            />
          </FormGroup>
        </Alert>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Update"
          icon="code-block"
          isOpen={isEditImageOpen}
          onCancel={this.handleCancel}
          onConfirm={this.handleEditImage}
        >
          <FormGroup
            label="Docker Image"
            fill
          >
            <InputGroup
              fill
              value={dockerImage || ''}
              onChange={this.handleEditImageChange}
            />
          </FormGroup>
        </Alert>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Suspend"
          icon="moon"
          isOpen={isSuspendOpen}
          onCancel={this.handleCancel}
          onConfirm={this.handleSuspend}
          intent="warning"
        >
          <p>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            Are you sure you want to suspend <b>{pipeline.id}</b>?
            This will scale this pipeline to 0 instances.
          </p>
        </Alert>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="delete"
          isOpen={isDeleteOpen}
          onCancel={this.handleCancel}
          onConfirm={this.handleDelete}
          intent="danger"
        >
          <p>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            Are you sure you want to delete <b>{pipeline.id}</b>?&nbsp;
            {/* eslint-disable-next-line max-len */}
            This will remove all worker processes and delete all configuration info for this pipeline.
          </p>
        </Alert>
        <div>
          <div className="pipeline-info">
            <div style={{ flexGrow: 1 }}>
              <ul className="bp3-text-small bp3-running-text">
                <li>
                  <strong>Image:&nbsp;</strong>
                  {pipeline.container.image}
                </li>
                <li>
                  <strong>Created:&nbsp;</strong>
                  {created.format('LLL')}
                  &nbsp;
                  {`(${created.fromNow()})`}
                </li>
                <li>
                  <strong>Updated:&nbsp;</strong>
                  {updated.format('LLL')}
                  &nbsp;
                  {`(${updated.fromNow()})`}
                </li>
                <li>
                  <strong>Roles:&nbsp;</strong>
                  {roles.map((r, i) => (
                    <Tag
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      minimal
                      style={{ marginRight: 4 }}
                    >
                      {r}
                    </Tag>
                  ))}
                </li>
                <li>
                  <strong>Instances:&nbsp;</strong>
                  {pipeline.instances}
                  &nbsp;✕&nbsp;
                  {_.size(pipeline.processes)}
                </li>
                <li>
                  <strong>Task Failures:&nbsp;</strong>
                  {failures}
                </li>
                <li>
                  <strong>CPU:&nbsp;</strong>
                  {cpu * pipeline.instances}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>Mem:&nbsp;</strong>
                  {mem * pipeline.instances}
                  &nbsp;MiB
                </li>
              </ul>
            </div>
            {!!avgProcTime && (
              <div>
                <H3>{prettyMs(avgProcTime)}</H3>
                <em className="bp3-text-small">Average Processing Time (last 10m)</em>
              </div>
            )}
          </div>
          <div className="columns">
            <div className="column">
              <Popover
                content={(
                  <Menu>
                    <Menu.Item icon="code-block" text="Docker Image" onClick={this.handleOpenEditImage} />
                  </Menu>
                )}
                position={Position.BOTTOM_LEFT}
              >
                <Button icon="edit" text="Configure" minimal rightIcon="caret-down" />
              </Popover>
            </div>
            <div className="column" style={{ textAlign: 'right' }}>
              <ButtonGroup minimal>
                <Button icon="changes" onClick={this.handleOpenScale}>Scale</Button>
                <Button icon="moon" intent="warning" disabled={!pipeline.instances} onClick={this.handleOpenSuspend}>Suspend</Button>
                <Button icon="delete" intent="danger" onClick={this.handleOpenDelete}>Delete</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </Callout>
    );
  }
}

Pipeline.propTypes = {
  dispatch: PropTypes.func,
  pipeline: PropTypes.object,
};

// eslint-disable-next-line react/no-multi-comp
class Pipelines extends Component {
  constructor(props) {
    super(props);
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getPipelines());
    const refreshTimeout = setInterval(dispatch, 5000, getPipelines());
    this.setState({ refreshTimeout });
  }

  componentWillUnmount() {
    const { refreshTimeout } = this.state;
    clearInterval(refreshTimeout);
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    dispatch(getPipelines());
  }

  renderPipeline(pipeline) {
    const { dispatch } = this.props;
    return <Pipeline key={pipeline.id} pipeline={pipeline} dispatch={dispatch} />;
  }

  render() {
    const { pipelines } = this.props;

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Pipelines
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/pipelines/create"
                  >
                    <Icon icon="add" />
                  </Link>
                </H1>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!!pipelines.pending
              && !((pipelines.data || []).length)
              && <Spinner size={100} />}
            {!!pipelines.error && <NonIdealState icon={<Icon iconSize={Icon.SIZE_LARGE * 3} icon="error" intent="danger" />} title="There was an error retrieving the pipelines. Ensure you are connected to the VPN" />}
            {!((pipelines.data || []).length)
              && !pipelines.error
              && !pipelines.pending
              && <NonIdealState icon="offline" title="No active pipelines." />}
            {((pipelines.data || []).map(this.renderPipeline, this))}
          </div>
        </div>
      </div>
    );
  }
}

Pipelines.propTypes = {
  dispatch: PropTypes.func,
  pipelines: PropTypes.object,
};

export default connect(state => ({
  pipelines: state.pipelines,
}))(Pipelines);
