import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import {
  H1, Tab, Tabs, Navbar, Spinner,
} from '@blueprintjs/core';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { getLocation } from 'actions/location';
import { getAllDevices } from 'actions/device';

import LocationDevices from './devices';
import LocationWhitelist from './whitelist';
import LocationInfo from './info';

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getLocation(match.params.id));
    dispatch(getAllDevices());
  }

  componentDidUpdate(prevProps) {
    const { dispatch, match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(getLocation(match.params.id));
      dispatch(getAllDevices());
    }
  }

  @autobind
  handleDisable() {
    const { disabled } = this.state;
    this.setState({ disabled: !disabled });
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch, location } = this.props;
    dispatch(push(tabId + (location.search || '')));
  }

  render() {
    const {
      locationObj, match, allDevices, location,
    } = this.props;
    const { disabled } = this.state;
    const locationId = parseInt(match.params.id, 10);
    const created = DateTime.fromISO((locationObj.data || {}).created)
      .toLocaleString(DateTime.DATETIME_MED);

    const currentLocation = (locationObj || {}).data;
    const nestDevices = ((currentLocation || {}).sites || [])
      .map(x => (x.devices ? x.devices : undefined));
    const devices = _.flattenDeep(nestDevices);

    if (!locationObj.data) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    if (currentLocation.id !== locationId) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    return (
      <div className="container">
        <div className={classNames('columns', { 'bp3-skeleton': locationObj.pending && currentLocation.id !== locationId })}>
          <H1>
            {(locationObj.data || {}).name}
            <div className="bp3-text-small bp3-text-muted">
              ID:&nbsp;
              {(locationObj.data || {}).id}
              &nbsp;
              Created:&nbsp;
              {created}
            </div>
          </H1>
        </div>
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-location" onChange={this.handleTabChange} selectedTabId={location.pathname} large>
              <Tab id={`/locations/${locationObj.data.id}`} title="Info" />
              <Tab id={`/locations/${locationObj.data.id}/devices`} title="Devices" />
              <Tab id={`/locations/${locationObj.data.id}/whitelist`} title="Whitelist" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route path="/locations/:id/devices">
                <LocationDevices
                  location={currentLocation}
                  locationDevices={devices}
                  allDevices={allDevices}
                  match={match}
                />
              </Route>
              <Route path="/locations/:id/whitelist">
                <LocationWhitelist match={match} />
              </Route>
              <Route>
                <LocationInfo
                  location={currentLocation}
                  match={match}
                  handleDisable={this.handleDisable}
                  disabled={disabled}
                  initialValues={{
                    name: currentLocation.name,
                    expiration: currentLocation.data_expiration_ttl,
                    anonymize_threshold: currentLocation.anonymize_threshold,
                  }}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Location.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  allDevices: PropTypes.object,
  locationObj: PropTypes.object,
};

export default connect(state => ({
  locationObj: state.location,
  allDevices: state.allDevices,
}))(Location);
