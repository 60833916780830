import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  TextInput, TextAreaInput, FormSelectInput, CheckboxInput,
} from 'components/inputs';
import {
  H1, H3, Button, Tag, Spinner, Navbar, Tabs, Tab,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';

import {
  getAllTrainingTasks, getTrainingDatasets, getTrainingTaskStatus,
  getTrainerResources, createTrainingTask, terminateTraining,
} from 'actions/training';
import { getModels } from 'actions/audit';
import { AppToaster } from 'components/Toaster';
import MetricsChart from './metricsChart';
import StatsTable from './stats';
import Logs from './logs';


const getInitialValues = (trainingTasks, taskId, trainingDatasets) => {
  const task = trainingTasks.data.find(x => x.training_id === parseInt(taskId, 10));
  if (task) {
    const initialValues = {};
    initialValues.augmentations = task.augmentations;
    initialValues.config = task.config;
    initialValues.training_name = task.training_name;
    initialValues.training_type = task.training_type;
    initialValues.gpu_zero = task.gpus.includes('0');
    initialValues.gpu_one = task.gpus.includes('1');
    initialValues.model_id = task.model_id;
    initialValues.framework = task.framework;

    const datasets = task.datasets.split(',');
    datasets.forEach((d) => {
      const dataset = trainingDatasets.data.find(x => x.dataset_id === d);
      if (dataset) {
        initialValues[dataset.dataset_name] = true;
      }
    });
    return initialValues;
  }
  return {};
};

const TASK_STATUS = {
  FAIL: -1,
  PROGRESS: 1,
  SUCCESS: 2,
};

const filterByType = (dataset, initalValue, stateValue) => {
  if (stateValue) {
    if (dataset.dataset_type === stateValue) {
      return true;
    }
    return false;
  }
  if (initalValue) {
    if (dataset.dataset_type === initalValue) {
      return true;
    }
    return false;
  }
  return true;
};

class TaskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetError: false,
      gpuError: false,
      currentTab: 'logs',
      startTime: null,
      endTime: null,
      trainingType: null,
      trainingframework: null,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getAllTrainingTasks()).then((response) => {
      this.setTimeRange();
      if (response.payload.data.content && match.params.task_id && match.params.task_id !== 'new') {
        const task = response.payload.data.content
          .find(x => x.training_id === parseInt(match.params.task_id, 10));
        if (task && task.config && task.config.length) {
          const config = JSON.parse(task.config) || {};
          if (config.recovery && config.recovery.training_id) {
            dispatch(getTrainingTaskStatus(config.recovery.training_id));
          } else {
            dispatch(getTrainingTaskStatus(match.params.task_id));
          }
        } else {
          dispatch(getTrainingTaskStatus(match.params.task_id));
        }
      }
    });
    dispatch(getModels());
    dispatch(getTrainingDatasets());
    if (match.params.id) dispatch(getTrainerResources(match.params.id));
  }

  @autobind
  setTimeRange() {
    const { match, trainingTasks } = this.props;
    const { task_id } = match.params;
    const task = trainingTasks.data.find(x => x.training_id === parseInt(task_id, 10));
    this.setState({ startTime: task.start_time, endTime: task.end_time });
  }

  @autobind
  handleSave(values) {
    const { dispatch, match, trainingDatasets } = this.props;
    const dArray = [];
    Object.keys(values).forEach((k) => {
      // eslint-disable-next-line no-restricted-globals
      const d = trainingDatasets.data.find(x => x.dataset_name === k);
      if (d) {
        if (values[k]) dArray.push(d.dataset_id);
      }
    });
    const gArray = [];
    if (values.gpu_zero) gArray.push('0');
    if (values.gpu_one) gArray.push('1');

    const datasets = dArray.join();
    const gpus = gArray.join();
    if (datasets === '' || gpus === '') {
      const datasetError = datasets === '';
      const gpuError = gpus === '';
      return this.setState({ datasetError, gpuError });
    }
    this.setState({ datasetError: false, gpuError: false });
    const {
      training_name, training_type, augmentations, config, model_id,
      framework,
    } = values;
    const data = {
      training_name,
      device_id: match.params.id,
      training_type,
      gpus,
      augmentations,
      config: config || '',
      datasets,
      start_time: DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true }),
      model_id,
      framework,
    };
    return dispatch(createTrainingTask(data)).then(action => dispatch(push(`/model_training/${match.params.id}/tasks`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.device_id}
            </strong>
            Training Task Created
          </span>
        ),
        intent: 'success',
      }))
      .finally(() => dispatch(getAllTrainingTasks()));
  }

  @autobind
  handleAxisTabChange(cameraTabId) {
    this.setState({ currentTab: cameraTabId });
  }

  @autobind
  handleTerminate(device_id, training_id) {
    const { dispatch } = this.props;
    return dispatch(terminateTraining(device_id, training_id)).then(() => AppToaster.show({
      icon: 'tick',
      message: (
        <span>
          Training Task Terminated
        </span>
      ),
      intent: 'success',
    })).finally(() => dispatch(getAllTrainingTasks()));
  }

  @autobind
  handleLogsRefresh() {
    const end = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
    this.setState({ endTime: end });
  }

  @autobind
  handleTrainingType(e) {
    this.setState({ trainingType: e.target.value });
  }

  @autobind
  handleFramework(e) {
    this.setState({ trainingframework: e.target.value });
  }

  render() {
    const {
      datasetError, gpuError, currentTab,
      startTime, endTime, trainingType,
      trainingframework,
    } = this.state;
    const {
      trainingTasks, match, trainingDatasets, handleSubmit, trainingTaskStatus,
      trainerResources, submitting, initialValues, models,
    } = this.props;
    if (trainingTasks.pending || trainingDatasets.pending) {
      return <Spinner size="100" />;
    }
    let gpu0Busy = false;
    let gpu1Busy = false;
    if (trainerResources[match.params.id] && trainerResources[match.params.id].response) {
      const { active_training } = trainerResources[match.params.id].response;
      if (active_training) {
        // eslint-disable-next-line prefer-destructuring
        gpu0Busy = active_training[0] !== undefined ? active_training[0] : true;
        // eslint-disable-next-line prefer-destructuring
        gpu1Busy = active_training[1] !== undefined ? active_training[1] : true;
      }
    }
    const { task_id } = match.params;
    const task = trainingTasks.data.find(x => x.training_id === parseInt(task_id, 10));
    const filteredModels = models.data.length
      ? models.data.filter(x => x.model_type.toLowerCase() === 'darknet' && x.config && x.config.length)
      : [];
    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              {(match.params.task_id && task) ? task.training_name : 'Create Training Task'}
            </H1>
            <br />
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="training_name"
                  label="Training Name"
                  type="text"
                />
                <Field
                  component={FormSelectInput}
                  name="training_type"
                  label="Training Type"
                  onChange={this.handleTrainingType}
                >
                  <option value="">Select a Type</option>
                  <option key={0} value="inline">Inline</option>
                  <option key={1} value="overhead">Overhead</option>
                  <option key={2} value="fisheye">Fisheye</option>
                  {(trainingframework === 'pytorch' || trainingframework === null) && (
                  <option key={3} value="reid">Reid</option>
                  // <option key={4} value="color_classifier">Color Classifier</option>
                  )}
                  {(trainingframework === 'pytorch' || trainingframework === null) && (
                    <option key={4} value="color_classifier">Color Classifier</option>
                  )}
                </Field>
                <Field
                  component={FormSelectInput}
                  name="framework"
                  label="Framework"
                  onChange={this.handleFramework}
                >
                  <option value="">Select a Framework</option>
                  <option key={0} value="pytorch">Pytorch</option>
                  { trainingType !== 'reid' && (<option key={1} value="darknet">Darknet</option>)}
                </Field>
                <br />
                <Field
                  component={CheckboxInput}
                  name="gpu_zero"
                  label={`GPU 0${gpu0Busy ? ' (Busy)' : ''}`}
                />
                <Field
                  component={CheckboxInput}
                  name="gpu_one"
                  label={`GPU 1${gpu1Busy ? ' (Busy)' : ''}`}
                />
                <br />
                <Field
                  component={TextAreaInput}
                  placeholder=""
                  name="config"
                  label="Config"
                  type="text"
                  rows={3}
                />
                <Field
                  component={TextAreaInput}
                  placeholder=""
                  name="augmentations"
                  label="Augmentations"
                  type="text"
                  rows={3}
                />
                <p>
                  Machine:
                  &nbsp;
                  <Tag minimal large intent="warning">{match.params.id || (task && task.device_id)}</Tag>
                </p>
              </div>
              <div className="column is-half">
                <H3>Datasets</H3>
                {trainingDatasets.data && trainingDatasets.data
                  .filter(x => !x.archived
                    && filterByType(x, initialValues.training_type, trainingType))
                  .map(x => (
                    <Field
                      component={CheckboxInput}
                      name={x.dataset_name}
                      label={x.dataset_name}
                    />
                  ))}
                {filteredModels.length > 0 ? (
                  <Fragment>
                    <br />
                    <Field
                      component={FormSelectInput}
                      name="model_id"
                      label="Training Model"
                    >
                      <option value="">Select a Type</option>
                      {filteredModels.map(x => <option key={x.id} value={x.id}>{x.id}</option>)}
                    </Field>
                  </Fragment>
                ) : null}
              </div>
            </div>
            {gpuError && <p style={{ color: '#FD7273' }}>At least one GPU must be selected to initiate a training task</p>}
            {datasetError && <p style={{ color: '#FD7273' }}>At least one dataset must be selected to initiate a training task</p>}
            {match.params.id ? (
              <Button
                style={{ float: 'right' }}
                icon={(match.params.task_id && task) ? 'redo' : 'build'}
                intent="success"
                type="submit"
                loading={submitting}
                disabled={submitting}
              >
                {(match.params.task_id && task) ? 'Refire Training' : 'Start Training'}
              </Button>
            ) : null}
          </form>
        </div>
        <br />
        {(match.params.task_id && task) && task.training_status === TASK_STATUS.PROGRESS ? (
          <div className="has-text-right">
            <Button
              icon="delete"
              intent="warning"
              onClick={() => this.handleTerminate(task.device_id, task.training_id)}
            >
              Terminate Task
            </Button>
          </div>
        ) : null}
        <br />
        {match.params.task_id !== 'new' ? (
          <Fragment>
            <Navbar style={{ margin: '16px 0' }}>
              <Navbar.Group>
                <Tabs id="tabs-device-axis" selectedTabId={currentTab} onChange={this.handleAxisTabChange} large>
                  <Tab id="metrics" title="Metrics" />
                  <Tab id="stats" title="Stats" />
                  <Tab id="logs" title="Logs" />
                </Tabs>
              </Navbar.Group>
            </Navbar>
            {(match.params.task_id && task) && task.training_status === TASK_STATUS.PROGRESS ? (
              <div className="has-text-right">
                <Button icon="refresh" onClick={this.handleLogsRefresh} />
              </div>
            ) : null}
            <br />
            {currentTab === 'metrics' && <MetricsChart chart={trainingTaskStatus.data.chart} />}
            {currentTab === 'stats' && <StatsTable stats={trainingTaskStatus.data.stats} type={task.training_type} />}
            {currentTab === 'logs' && task
              && <Logs start={startTime} end={endTime} device_id={task.device_id} />}
            <br />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

const selector = formValueSelector('create-training-task');
const REQUIRED = 'This field is required.';

TaskPage.propTypes = {
  dispatch: PropTypes.func,
  trainingTasks: PropTypes.object,
  trainingDatasets: PropTypes.object,
  trainingTaskStatus: PropTypes.object,
  trainerResources: PropTypes.object,
  match: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  models: PropTypes.object,
};

export default connect((state, props) => ({
  trainingTasks: state.trainingTasks,
  trainingDatasets: state.trainingDatasets,
  trainingTaskStatus: state.trainingTaskStatus,
  trainerResources: state.trainerResources,
  initialValues: state.trainingTasks.data.length > 0 && state.trainingDatasets.data.length > 0
    && getInitialValues(state.trainingTasks, props.match.params.task_id, state.trainingDatasets),
  training_type: selector(state, 'training_type'),
  models: state.models,
}))(reduxForm({
  form: 'create-training-task',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.training_name) {
      errors.training_name = REQUIRED;
    }
    if (!values.training_type) {
      errors.training_type = REQUIRED;
    }
    if (!values.augmentations) {
      errors.augmentations = REQUIRED;
    }
    return errors;
  },
})(TaskPage));
