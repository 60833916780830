import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation, Tag } from '@blueprintjs/core';
import { randomColor } from 'randomcolor';
import { DateTime } from 'luxon';
import numbro from 'numbro';

import Line from './line';

const fmtr = x => numbro(x.toFixed(2)).format('0,0');

const DiskChart = ({ data }) => {
  const time = data.columns.findIndex(x => x === 'time');
  const labels = data.values.map(x => DateTime.fromMillis(x[time]).toFormat('ff'));
  const diskTotal = data.columns.findIndex(x => x === 'mean_disk_/_total');
  const diskUsed = data.columns.findIndex(x => x === 'mean_disk_/_used');
  const ssd0Total = data.columns.findIndex(x => x === 'mean_disk_/mnt/ssd0_total');
  const ssd0Used = data.columns.findIndex(x => x === 'mean_disk_/mnt/ssd0_used');
  const ssd1Total = data.columns.findIndex(x => x === 'mean_disk_/mnt/ssd1_total');
  const ssd1Used = data.columns.findIndex(x => x === 'mean_disk_/mnt/ssd1_used');
  const disk = randomColor({ format: 'hsl', hue: 'random' });
  const ssd0 = randomColor({ format: 'hsl', hue: 'random' });
  const ssd1 = randomColor({ format: 'hsl', hue: 'random' });

  const datasets = [
    {
      label: 'Root',
      data: data.values.map(x => (x[diskUsed] / x[diskTotal]) * 100),
      fill: false,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: disk,
      spanGaps: false,
      suffix: '%',
    },
    {
      label: 'SSD_0',
      data: data.values.map(x => (x[ssd0Used] / x[ssd0Total]) * 100),
      fill: false,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: ssd0,
      spanGaps: false,
      suffix: '%',
    },
    {
      label: 'SSD_1',
      data: data.values.map(x => (x[ssd1Used] / x[ssd1Total]) * 100),
      fill: false,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: ssd1,
      spanGaps: false,
      suffix: '%',
    },
  ];

  const chartDataset = { datasets, labels };

  return (
    <Fragment>
      <Tag large>Disk Utilization</Tag>
      <Card elevation={Elevation.THREE} style={{ marginTop: 10 }}>
        <div style={{ minHeight: 500 }}>
          <Line
            data={() => chartDataset}
            legend
            yFmtr={fmtr}
            position="bottom"
            yLabel=""
          />
        </div>
      </Card>
    </Fragment>
  );
};

DiskChart.propTypes = {
  data: PropTypes.object,
};

export default DiskChart;
