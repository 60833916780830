import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './list';
import Create from './create';
import User from './user';

export default () => (
  <Switch>
    <Route path="/users/create" component={Create} />
    <Route path="/users/:id" component={User} />
    <Route component={List} />
  </Switch>
);
