import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  FieldArray, Field, reduxForm, reset,
} from 'redux-form';
import {
  Drawer, Button, NonIdealState, Classes, H3,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { SelectInput, FormSelectInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getPermissions, savePermissions } from 'actions/role';

const PERMISSIONS_MODULES = [
  '/apikeys',
  '/locations',
  '/sites',
  '/zones',
  '/devices',
  '/alerts',
  '/marketing',
  '/marketing/marketplaces',
  '/marketing/campaigns',
  '/marketing/displays',
  '/marketing/metrics',
  '/cms',
  '/cms/playlists',
  '/cms/creatives',
  '/cms/canvases',
  '/cms/adunits',
  '/cms/placements',
  '/billing',
  '/metrics',
  '/metrics/query',
  '/metrics/audience',
  '/metrics/vision/positions',
  '/metrics/heatmap',
  '/metrics/path_analysis',
  '/metrics/occupancy/summary',
  '/metrics/dwell_time',
  '/metrics/curb_congestion',
  '/organizations',
  '/audit',
  '/reid',
  '/users',
  '/reports',
].map(p => ({ id: p, label: p }));

const ACCESS_LEVELS = [
  { id: 0, label: 'Deny' },
  { id: 1, label: 'Read' },
  { id: 2, label: 'Write' },
];

const ACCESS_SCOPES = [
  { id: 0, label: 'User' },
  { id: 2, label: 'Organization' },
];

const validatePermissions = (values) => {
  const errors = {};
  const permissionsErrors = [];

  const allModules = values.permissions.map(p => p.module && p.module.id);
  values.permissions.forEach((permission, i) => {
    const permissionErrors = {};

    if (!permission.module) {
      permissionErrors.module = 'This field is required';
      permissionsErrors[i] = permissionErrors;
    } else if (allModules.indexOf(permission.module.id) !== i) {
      permissionErrors.module = 'Module can only be used once';
      permissionsErrors[i] = permissionErrors;
    }

    if (!permission.access) {
      permissionErrors.access = 'This field is required';
      permissionsErrors[i] = permissionErrors;
    }
  });

  if (permissionsErrors.length > 0) {
    errors.permissions = permissionsErrors;
  }

  return errors;
};

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
    };
  }

  componentDidMount() {
    const { dispatch, roleId } = this.props;
    if (roleId) {
      dispatch(getPermissions(roleId));
    }
  }

  componentDidUpdate(prevProps) {
    const { roleId, dispatch } = this.props;
    const prevRoleId = prevProps.roleId;
    if (roleId !== prevRoleId) {
      dispatch(getPermissions(roleId));
    }
  }

  @autobind
  handleHelp() {
    this.setState({ showHelp: true });
  }

  @autobind
  handleCloseHelp() {
    this.setState({ showHelp: false });
  }

  @autobind
  handleSave(values) {
    const { dispatch, roleId, orgId } = this.props;
    return dispatch(savePermissions(roleId, values.permissions.map(
      ({ module, access, scope }) => ({
        module: module.id.includes('metrics') ? `/organizations/${orgId}${module.id}` : module.id,
        access: access.id,
        scope: parseInt(scope, 10),
      }),
    ))).then(() => {
      dispatch(reset('permissions'));
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            Permissions saved
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getPermissions(roleId));
    });
  }

  @autobind
  renderPermissions({ fields }) {
    const permissions = fields.map((permission, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={i}>
        <td>
          <Field
            name={`${permission}.module`}
            component={SelectInput}
            items={PERMISSIONS_MODULES}
            placeholder="Select Module"
          />
        </td>
        <td>
          <Field
            name={`${permission}.access`}
            component={SelectInput}
            items={ACCESS_LEVELS}
            placeholder="Select ACL"
          />
        </td>
        <td>
          <Field
            name={`${permission}.scope`}
            component={FormSelectInput}
          >
            {ACCESS_SCOPES.map(x => <option value={x.id} id={x.id}>{x.label}</option>)}
          </Field>
        </td>
        <td className="has-text-right">
          <Button
            intent="danger"
            icon="trash"
            onClick={() => fields.remove(i)}
          />
        </td>
      </tr>
    ));

    return (
      <React.Fragment>
        <div className="columns">
          <div className="column has-text-right">
            <Button
              onClick={this.handleHelp}
              icon="help"
              intent="success"
              className="m-r-sm"
            >
              Help
            </Button>
            <Button icon="plus" intent="primary" onClick={() => fields.push({ module: undefined, access: ACCESS_LEVELS[1] })}>
              Add
            </Button>
          </div>
        </div>
        { permissions.length ? (
          <table className={classNames('bp3-html-table bp3-interactive', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th>Module</th>
                <th>Access</th>
                <th>Scope</th>
              </tr>
            </thead>
            <tbody>
              { permissions }
            </tbody>
          </table>
        ) : (
          <NonIdealState title="No Permissions" />
        )}
      </React.Fragment>
    );
  }

  render() {
    const { submitting, handleSubmit, isAdmin } = this.props;
    const { showHelp } = this.state;

    if (isAdmin) {
      return (
        <NonIdealState
          icon="crown"
          title="This is an admin role"
          description="Admin roles have access to all routes."
          className="non-ideal-device-card"
        />
      );
    }

    return (
      <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">

        <FieldArray name="permissions" component={this.renderPermissions} />
        <div className="has-text-right">
          <Button
            style={{ marginTop: '16px' }}
            intent="primary"
            loading={submitting}
            disabled={submitting}
            type="submit"
            icon="tick"
          >
            Save
          </Button>
        </div>
        <Drawer
          icon="info-sign"
          onClose={this.handleCloseHelp}
          title="Roles"
          isOpen={showHelp}
          size={Drawer.SIZE_SMALL}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={`bp3-running-text ${Classes.DIALOG_BODY}`}>
              <H3>Analytics</H3>
              <ul className="bp3-list">
                <li>/locations &bull; Read &bull; Organization</li>
                <li>/sites &bull; Read &bull; Organization</li>
                <li>/zones &bull; Read &bull; Organization</li>
                <li>/metrics &bull; Read &bull; Organization</li>
              </ul>
              <H3>Installer</H3>
              <ul>
                <li>/locations &bull; Write &bull; Organization</li>
                <li>/sites &bull; Write &bull; Organization</li>
                <li>/zones &bull; Write &bull; Organization</li>
                <li>/metrics &bull; Read &bull; Organization</li>
              </ul>
              <H3>CMS</H3>
              <ul>
                <li>/locations &bull; Read &bull; Organization</li>
                <li>/sites &bull; Read &bull; Organization</li>
                <li>/zones &bull; Read &bull; Organization</li>
                <li>/metrics &bull; Read &bull; Organization</li>
                <li>/cms &bull; Write &bull; Organization</li>
              </ul>
              <H3>Advertiser</H3>
              <ul>
                <li>/cms &bull; Read &bull; Organization</li>
                <li>/cms/adunits &bull; Write &bull; Organization</li>
                <li>/cms/creatives &bull; Write &bull; User</li>
                <li>/cms/placements &bull; Write &bull; User</li>
                <li>/cms/playlists &bull; Write &bull; User</li>
                <li>/marketing/displays &bull; Read &bull; Organization</li>
              </ul>
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER} />
        </Drawer>
      </form>
    );
  }
}

Permissions.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  roleId: PropTypes.number,
  isAdmin: PropTypes.bool,
  orgId: PropTypes.number,
};

export default connect(state => ({
  initialValues: state.permissions.data ? {
    permissions:
      state.permissions.data.map(
        ({ module, access, scope }) => ({
          module: PERMISSIONS_MODULES.find(p => (p.id.includes('/metrics') ? `/metrics${module.split('/metrics')[1]}` === p.id : p.id === module)),
          access: ACCESS_LEVELS[access],
          scope,
        }),
      ),
  } : undefined,
  validate: validatePermissions,
}))(reduxForm({
  form: 'permissions',
  enableReinitialize: true,
})(Permissions));
