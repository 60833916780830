import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormGroup, InputGroup, ContextMenu,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { updateGroundTruth } from 'actions/audit';
import { AppToaster } from 'components/Toaster';

class AuditTruth extends Component {
  constructor(props) {
    super(props);
    const { true_ins, true_outs } = props.selectedRow;
    this.state = {
      ins: Number.isInteger(true_ins) ? true_ins : '',
      outs: Number.isInteger(true_outs) ? true_outs : '',
      loading: false,
    };
  }

  @autobind
  handleIns(e) {
    this.setState({ ins: e.target.value });
  }

  @autobind
  handleOuts(e) {
    this.setState({ outs: e.target.value });
  }

  @autobind
  handleSave() {
    const { ins, outs } = this.state;
    const { selectedRow, getData, dispatch } = this.props;
    const { clip_name } = selectedRow || {};
    const serial = clip_name.split('_').pop().split('.')[0];
    const isDefined = ins !== undefined && outs !== undefined;
    if (!isDefined) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Ins and Outs Required</span>,
        intent: 'danger',
      });
    }
    const data = {
      ins: parseInt(ins, 10),
      outs: parseInt(outs, 10),
      line_id: selectedRow.line_id,
    };
    this.setState({ loading: true });
    return dispatch(updateGroundTruth(selectedRow.clip_name, serial, data))
      .then(() => (getData ? this.delayGet(3000).then(() => getData()) : true))
      .then(() => ContextMenu.hide())
      .finally(() => this.setState({ loading: false }));
  }

  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  render() {
    const { selectedRow } = this.props;
    const {
      ins, outs, loading,
    } = this.state;
    let link = `/audits/groundtruth/${selectedRow.clip_id}`;
    if (selectedRow.audit_id != null && selectedRow.audit_id !== '') {
      if (selectedRow.line_id != null && selectedRow.line_id !== 0) {
        link = `/audits/groundtruth/${selectedRow.clip_id}?audit=${selectedRow.audit_id}&line_id=${selectedRow.line_id}`;
      } else {
        link = `/audits/groundtruth/${selectedRow.clip_id}?audit=${selectedRow.audit_id}`;
      }
    }
    return (
      <div className="audit-context-menu">
        <div className="clip-name"><strong><a href={link}>{selectedRow.clip_name}</a></strong></div>
        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
          <div>
            <FormGroup label="True Ins">
              <InputGroup
                value={ins}
                onChange={this.handleIns}
                type="number"
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup label="True Outs">
              <InputGroup value={outs} onChange={this.handleOuts} type="number" />
            </FormGroup>
          </div>
        </div>
        <div className="has-text-right">
          <Button loading={loading} onClick={this.handleSave} intent="primary" type="submit" icon="tick">
            Save
          </Button>
        </div>
      </div>
    );
  }
}

AuditTruth.propTypes = {
  dispatch: PropTypes.func,
  selectedRow: PropTypes.object,
  getData: PropTypes.func,
};


export default AuditTruth;
