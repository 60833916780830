import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducers as sagaReducers } from '../sagas';
import userReducer from './user';
import compareAuditReducer from './compareAudit';
import compareAuditSummaryReducer from './compareAuditSummary';
import cameraModelReducer from './cameraModelReducer';
import zoneOccupancyReducer from './zoneOccupancy';
import queryReducer from './query';
import dashQueryReducer from './dashQueryReducer';
import dashDeviceReducer from './dashDeviceQuery';
import cameraStatsReducer from './cameraStatsReducer';
import trainerResourcesReducer from './trainerResourcesReducer';

const rootReducer = combineReducers({
  form: formReducer,
  currentUser: userReducer,
  compareAudit: compareAuditReducer,
  compareAuditSummary: compareAuditSummaryReducer,
  cameraModels: cameraModelReducer,
  zoneOccupancy: zoneOccupancyReducer,
  query: queryReducer,
  dashQuery: dashQueryReducer,
  dashDevices: dashDeviceReducer,
  cameraStatsSummary: cameraStatsReducer,
  trainerResources: trainerResourcesReducer,
  ...sagaReducers,
});

export default rootReducer;
