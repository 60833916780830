import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, reset, FieldArray,
} from 'redux-form';
import { Button, NonIdealState } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  TextInput, FormSelectInput,
} from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import {
  getDevice, postIapCamera, patchIapCamera, deleteIapCamera,
} from 'actions/device';

class VisionConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: [],
    };
  }

  @autobind
  addDeleteEntry(entry) {
    const { deleted } = this.state;
    this.setState({ deleted: [...deleted, entry] });
  }

  @autobind
  async handleSave(values) {
    const { dispatch, device } = this.props;
    const { deleted } = this.state;
    const { vision_iap_config: submitValues } = values;
    const data = submitValues.map(({
      connection_type: ct, id, ip, serial, vision_metric_period: vmp, vision_model: vm,
    }) => ({
      connection_type: ct,
      id,
      ip,
      serial,
      vision_model: vm,
      vision_metric_period: vmp,
    }));
    try {
      if (deleted.length) {
        await Promise.all(deleted.map(id => dispatch(deleteIapCamera(device.id, id))));
      }
      await Promise.all(data.map(d => (d.id
        ? dispatch(patchIapCamera(device.id, d.id, d))
        : dispatch(postIapCamera(device.id, d)))));
      AppToaster.show({
        message: 'Camera Configs Saved',
        intent: 'success',
        icon: 'tick',
      });
      return dispatch(getDevice(device.id))
        .then(() => this.setState({ deleted: [] }))
        .then(() => dispatch(reset('vision_iap_config')));
    } catch (action) {
      if (action.payload.response && action.payload.response.data) {
        const msg = action.payload.response.data.result.errorMessage || 'Error configuring camera';
        return AppToaster.show({
          message: msg,
          intent: 'danger',
        });
      }
      return null;
    } finally {
      dispatch(getDevice(device.id));
    }
  }

  @autobind
  renderVisionConfigs({ fields }) {
    const configs = fields.map((field, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li className="columns" key={i} style={{ borderBottom: '1px solid black' }}>
        <div className="column">
          <Field
            name={`${field}.name`}
            component={TextInput}
            label="Camera"
            disabled
            className="black-text"
          />
        </div>
        <div className="column">
          <Field
            component={FormSelectInput}
            name={`${field}.connection_type`}
            label="Camera Type"
          >
            <option value="NONE">None</option>
            <option value="IP">IP</option>
            <option value="USB">USB</option>
            <option value="AUTO">AUTO</option>
            <option value="AUTO_INTERNAL">AUTO INTERNAL</option>
          </Field>
        </div>
        <div className="column">
          <Field
            component={TextInput}
            placeholder=""
            name={`${field}.serial`}
            label="Restrict Serial #"
            disabled={!['AUTO', 'IP'].includes(fields.get(i) ? fields.get(i).connection_type : null)}
          />
        </div>
        <div className="column">
          <Field
            component={FormSelectInput}
            name={`${field}.vision_model`}
            label="Model Type"
            type="text"
          >
            <option value="bgsegm_counter">Background Segmentation Counter</option>
            <option value="coral_tracker">Coral Tracker</option>
            <option value="vision_coral_overhead">Vision Coral Overhead</option>
            <option value="vision_coral_inline">Vision Coral Inline</option>
            <option value="rx.overhead.yolov3-tiny">Yolov3 Tiny Overhead</option>
            <option value="rx.overhead.yolov4-tiny">Yolov4 Tiny Overhead</option>
            <option value="rx.inline.yolov3-tiny">Yolov3 Tiny Inline</option>
            <option value="rx.inline.yolov4-tiny">Yolov4 Tiny Inline</option>
            <option value="rx.fisheye.darknet">Yolov4 Fisheye</option>
            <option value="rx.inline.darknet">Yolov4 Darknet Inline</option>
            <option value="rx.overhead.fisheye.darknet">Yolov4 Darknet Fisheye Overhead</option>
            <option value="rx.inline.vehicle.darknet">Yolov4 Darknet Vehicle Inline</option>
          </Field>
        </div>
        <div className="column">
          <Field
            component={TextInput}
            label="Vision Metric Period"
            name={`${field}.vision_metric_period`}
            type="text"
          />
        </div>
        <div className="column">
          <Button
            icon="cross"
            title="Remove IAP-Camera Config"
            intent="danger"
            onClick={() => {
              fields.remove(i);
              if (fields.get(i).id) {
                this.addDeleteEntry(fields.get(i).id);
              }
            }}
            style={{ marginTop: 20 }}
          />
        </div>
      </li>
    ));
    return (
      <React.Fragment>
        <li className="columns">
          <div className="column">*Vision Metric Period Requires Time Unit (20s, 20ms, etc)</div>
          <div className="column has-text-right">
            <Button
              icon="plus"
              onClick={() => fields.length < 15 && fields.push({
                name: 'New',
                connection_type: 'NONE',
                ip: '',
                vision_model: 'bgsegm_counter',
                vision_metric_period: '60s',
                serial: '',
              })}
            >
              New Camera Config
            </Button>
          </div>
        </li>
        {configs.length ? configs : <NonIdealState title="" />}
      </React.Fragment>
    );
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div style={{ marginBottom: 40 }}>
        <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
          <FieldArray name="vision_iap_config" component={this.renderVisionConfigs} rerenderOnEveryChange />
          <div className="columns">
            <div className="column has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

VisionConfig.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
};


export default connect((state) => {
  const siteDevices = ((state.siteDevices || {}).data || []);
  const camerasWithNames = state.device && state.device.data && state.device.data.cameras
    ? [...state.device.data.cameras].map((y) => {
      const selectedCamera = siteDevices
        ? siteDevices.find(z => z.device.device_identifier === y.serial) : null;
      return {
        ...y,
        name: selectedCamera && selectedCamera.device && selectedCamera.device.name
          ? selectedCamera.device.name
          : y.serial,
      };
    })
    : [];
  return {
    initialValues: camerasWithNames.length
      ? { vision_iap_config: camerasWithNames }
      : undefined,
  };
})(reduxForm({
  form: 'vision_iap_config',
  enableReinitialize: true,
})(VisionConfig));
