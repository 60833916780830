import { GET_DISPLAYS, SET_DISPLAY_HOURS, GET_DISPLAY_HOURS } from '../constants';

export const getDisplays = orgId => ({
  type: GET_DISPLAYS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${orgId}/marketing/displays`,
    },
  },
});

export const setDisplayHours = (id, data) => ({
  type: SET_DISPLAY_HOURS,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/iap/${id}/cms/operating-hours`,
    },
  },
});

export const getDisplayHours = id => ({
  type: GET_DISPLAY_HOURS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/${id}/cms/operating-hours`,
    },
  },
});
