import { requestsReducer } from 'redux-saga-requests';
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_DEVICES,
  GET_AUDIT_ORGANIZATIONS,
} from '../constants';

export const organizationsReducer = requestsReducer({
  actionType: GET_ORGANIZATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const organizationReducer = requestsReducer({
  actionType: GET_ORGANIZATION,
  getData: (state, action) => action.payload.data.content,
});

export const auditOrganizationsReducer = requestsReducer({
  actionType: GET_AUDIT_ORGANIZATIONS,
  getData: (state, action) => action.payload.data.content,
});

export const organizationDevicesReducer = requestsReducer({
  actionType: GET_ORGANIZATION_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});
