import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';
import { autobind } from 'core-decorators';
import { FormGroup } from '@blueprintjs/core';

const HelperText = (touched, help, error) => (
  error || (help || undefined)
);

class TimePickerInput extends Component {
  @autobind
  handleChange(time) {
    const { input } = this.props;
    if (input.onChange) {
      input.onChange(time);
    }
  }

  render() {
    const {
      input,
      meta: { touched, error, help },
      label,
      helpIntent,
      precision,
    } = this.props;
    return (
      <FormGroup
        label={label || undefined}
        intent={(error) ? 'danger' : (helpIntent || undefined)}
        helperText={HelperText(touched, help, error)}
      >
        <TimePicker
          value={input.value || null}
          precision={precision || TimePrecision.SECOND}
          onChange={this.handleChange}
          showArrowButtons
        />
      </FormGroup>
    );
  }
}

TimePickerInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helpIntent: PropTypes.string,
  meta: PropTypes.object,
  precision: PropTypes.any,
};

export default TimePickerInput;
