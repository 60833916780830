/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm, FieldArray,
} from 'redux-form';
import {
  H1, Button, Spinner,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
// import moment from 'moment';
import {
  TextInput, FormSelectInput, CheckboxInput,
} from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import { createReidAuditTask, getReidAuditTasks, getReidTasks } from 'actions/reid';

class ReidAuditTask extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getReidTasks());
  }

  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    const {
      title, vision_params, model_id, region, vision_rx_version,
      task_ids, use_learner,
    } = values;
    const taskids = Object.values(task_ids).map(x => parseInt(x.id, 10));

    const data = {
      title,
      vision_params,
      model_id,
      region,
      vision_rx_version,
      distance_threshold: parseFloat(values.distance_threshold),
      lock_interval: parseInt(values.lock_interval, 10),
      task_ids: taskids,
      use_learner,
    };

    return dispatch(createReidAuditTask(data)).then(action => dispatch(push('/reid/audit'))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            : Reid task created
          </span>
        ),
        intent: 'success',
      }))
      .finally(() => dispatch(getReidAuditTasks()));
  }

  @autobind
  renderMergeTasks({ fields }) {
    const { tasks } = this.props;
    return (
      tasks && tasks.data && (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div className="bp3-label">Merge Tasks</div>
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((task_ids, i) => (
            <li key={i}>
              <div className="flex-space-between-container">
                <div style={{ width: '30%' }}>
                  <Field
                    name={`${task_ids}.id`}
                    placeholder=""
                    component={FormSelectInput}
                    label="Merge Task"
                  >
                    <option value="">Select a Task</option>
                    {tasks.data
                      .filter(s => s.task_type === 'merge')
                      .map(s => <option key={s.task_id} value={s.task_id}>{`(${s.task_id}):${s.title}`}</option>)
                      }
                  </Field>
                </div>
                <div style={{ marginTop: 22 }}>
                  <Button
                    icon="cross"
                    title="remove"
                    intent="danger"
                    onClick={() => fields.remove(i)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
      )
    );
  }

  render() {
    const {
      submitting, handleSubmit, services, models, tasks,
    } = this.props;
    if (!tasks || !tasks.data) {
      return <Spinner size={100} />;
    }
    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create Reid Audit Task
            </H1>
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="title"
                  label="Title"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  name="model_id"
                  placeholder=""
                  component={FormSelectInput}
                  label="Model"
                >
                  <option value="">Select a Model</option>
                  {models.data.map(x => (
                    <option key={x.id} value={x.id}>
                      {`${x.id} (${x.model_type})`}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="column">
                <Field
                  name="vision_rx_version"
                  placeholder=""
                  component={FormSelectInput}
                  label="Vision RX Version"
                >
                  <option value="" disabled>Select a Version</option>
                  {services.data
                    .filter(s => s.name === 'vision-rx')
                    .map(s => <option key={s.version} value={s.version}>{`v${s.version}`}</option>)
                    }
                </Field>
              </div>
              <div className="column">
                <Field
                  component={CheckboxInput}
                  name="use_learner"
                  label="Use Learner"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  name="vision_params"
                  label="Vision Params"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  component={TextInput}
                  name="distance_threshold"
                  label="Distance Threshold"
                  type="number"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-2">
                <Field
                  component={TextInput}
                  type="number"
                  name="lock_interval"
                  label="Timeout (min)"
                />
              </div>
              <div className="column">
                <Field
                  name="region"
                  placeholder=""
                  component={FormSelectInput}
                  label="Region"
                >
                  <option value="">Select a Region: (US/EU)</option>
                  <option value="US">US</option>
                  <option value="EU">EU</option>
                </Field>
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ borderRight: '2px solid #26343E' }}>
                <FieldArray name="task_ids" component={this.renderMergeTasks} />
              </div>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ReidAuditTask.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  services: PropTypes.object,
  models: PropTypes.object,
  tasks: PropTypes.object,
  // // eslint-disable-next-line react/no-unused-prop-types
  // initialValues: PropTypes.object,
};


const REQUIRED = 'This field is required.';

export default connect((state, props) => ({
  services: state.iap.services,
  models: state.models,
  reidAuditTasks: state.reidAuditTasks,
  tasks: state.tasks,
  initialValues: props.location.state && props.location.state.initialValues
    ? props.location.state.initialValues
    : {
      vision_rx_version: state.iap.services.data.filter(s => s.name === 'vision-rx')[0].version,
    },
}))(reduxForm({
  form: 'create-reid',
  validate: (values) => {
    const errors = {};
    if (!values.model_id) {
      errors.model_id = REQUIRED;
    }
    if (!values.vision_rx_version) {
      errors.vision_rx_version = REQUIRED;
    }
    if (!values.region) {
      errors.region = REQUIRED;
    }
    if (!values.title) {
      errors.title = REQUIRED;
    }
    if (!values.lock_interval) {
      errors.lock_interval = REQUIRED;
    }
    if (!values.distance_threshold) {
      errors.distance_threshold = REQUIRED;
    }
    return errors;
  },
})(ReidAuditTask));
