import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import {
  Icon, Card,
} from '@blueprintjs/core';

class ReidPatchesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      // selectedTracks: [],
    };
  }

  @autobind
  nextImage() {
    const { track } = this.props;
    const { selectedIndex } = this.state;
    let idx = selectedIndex;
    if (track.patches.length > 0) {
      idx = (idx + 1) % track.patches.length;
      this.setState({ selectedIndex: idx });
    }
  }

  @autobind
  prevImage() {
    const { track } = this.props;
    const { selectedIndex } = this.state;
    let idx = selectedIndex;
    if (track.patches.length > 0) {
      if (idx === 0) {
        idx = track.patches.length - 1;
      } else {
        idx = (idx - 1) % track.patches.length;
      }
      this.setState({ selectedIndex: idx });
    }
  }

  render() {
    const {
      track, isSaved, handleSelect, isSelected,
    } = this.props;
    const { selectedIndex } = this.state;
    let borderColor = '0.5em solid white';
    let blur = '';
    if (isSaved) {
      borderColor = '0.5em solid gray';
      blur = 'blur(2px)';
    } else if (isSelected) {
      borderColor = '0.5em solid green';
    }
    return (
      <div style={{ marginRight: '1em', display: 'inline-block' }}>
        <Card
          elevation="3"
          style={{
            border: borderColor,
            marginBottom: '1em',
            filter: blur,
            padding: '0',
          }}
          onClick={isSaved ? null : () => handleSelect(track)}
        >
          <img
            src={track.patches && track.patches[selectedIndex] ? track.patches[selectedIndex].url : 'https://dummyimage.com/300x200/000/fff&text=^+image+not+found'}
            height="50px"
            width="auto"
            alt=""
            id="img-feed"
          />
        </Card>
        <div className="columns">
          <div className="column" style={{ textAlign: 'left', display: 'inline-block', paddingTop: '0' }}>
            <Icon
              className="reid-back-icon"
              icon="circle-arrow-left"
              iconSize={12}
              onClick={this.prevImage}
            />
          </div>
          <div className="column" style={{ textAlign: 'center', display: 'inline-block', paddingTop: '2px' }}>{track.reference_track_id}</div>
          <div className="column" style={{ textAlign: 'right', display: 'inline-block', paddingTop: '0' }}>
            <Icon
              className="reid-back-icon"
              icon="circle-arrow-right"
              iconSize={12}
              onClick={this.nextImage}
            />
          </div>
        </div>
      </div>
    );
  }
}

ReidPatchesList.propTypes = {
  track: PropTypes.object,
  handleSelect: PropTypes.func,
  isSaved: PropTypes.bool,
  isSelected: PropTypes.bool,
};


export default ReidPatchesList;
