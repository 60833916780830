import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { H3, Button } from '@blueprintjs/core';
import { Field, reduxForm, reset } from 'redux-form';

import { CheckboxInput, SelectInput } from 'components/inputs';
import { updateZoneTabPermissions, getZone } from 'actions/site';

/* commenting these tabs out incase they are needed again */

// const AUDIENCE_TABS = [
//   { key: 'audience_visitors_in_view', title: 'Visitors In-View' },
//   { key: 'audience_engagement', title: 'Engagement' },
//   { key: 'audience_gender', title: 'Gender' },
//   { key: 'audience_age_group', title: 'Age Group' },
//   { key: 'audience_realtime', title: 'Realtime' },
// ];

const SAFE_DISTANCE_TABS = [
  { key: 'realtime_movement', title: 'Real-time Movement Map' },
  { key: 'vision_position_rectangle', title: 'Real-time Movement Rectangles' },
  { key: 'safe_distancing_visualize', title: 'Visualize Safe Distancing' },
  { key: 'safe_distancing_measure', title: 'Measure Safe Distancing' },
  { key: 'safe_distancing_mask', title: 'Mask Detection' },
  { key: 'path_analysis', title: 'Path Analysis' },
];

// const PATHING_TABS = [
//   { key: 'pathing_cross_path', title: 'Cross Path Analysis' },
//   { key: 'pathing_source_path', title: 'Source Path Analysis' },
//   { key: 'pathing_cohort', title: 'Cohort Analysis' },
// ];

const ANALYTICS_TABS = [
  { key: 'analytics_headcount', title: 'Wifi Headcount' },
  // { key: 'analytics_vision', title: 'Computer Vision' },
  // { key: 'analytics_time', title: 'Dwell-Time/Wait-Time' },
  { key: 'analytics_repeat_visitors', title: 'Repeat Visitors' },
  { key: 'analytics_realtime', title: 'Realtime' },
  { key: 'analytics_capture_rate', title: 'Capture Rate' },
  { key: 'realtime_queue_fill', title: 'Realtime Queue Fill' },
];

const OCCUPANCY_CONFIG = [
  { id: 'analytics_vision', name: 'Overhead Occupancy' },
  { id: 'vision_headcount', name: 'Fisheye/Inline Unique Visitors' },
  { id: 'disabled', name: 'Disabled' },
];

const DWELL_CONFIG = [
  { id: 'analytics_time', name: 'WiFi' },
  { id: 'vision_dwell_time', name: 'Vision' },
  { id: 'disabled', name: 'Disabled' },
];

const HEATMAP_TABS = [
  { key: 'heatmaps_location', title: 'Location Heatmap' },
  { key: 'heatmaps_time', title: 'Wait Time Heatmap' },
];

const simplifyPermissions = (rest, initialValues) => {
  const { occupancy_config, dwell_config, ...data } = rest;
  let analytics_vision;
  let analytics_time;
  let vision_dwell_time;
  let vision_headcount;
  if (!occupancy_config) {
    if (initialValues.analytics_vision) {
      analytics_vision = true;
      vision_headcount = false;
    }
    if (initialValues.vision_headcount) {
      analytics_vision = false;
      vision_headcount = true;
    }
  } else if (occupancy_config.id === 'disabled') {
    analytics_vision = false;
    vision_headcount = false;
  } else if (occupancy_config.id === 'vision_headcount') {
    analytics_vision = false;
    vision_headcount = true;
  } else if (occupancy_config.id === 'analytics_vision') {
    analytics_vision = true;
    vision_headcount = false;
  }

  if (!dwell_config) {
    if (initialValues.analytics_time) {
      analytics_time = true;
      vision_dwell_time = false;
    }
    if (initialValues.vision_dwell_time) {
      analytics_time = false;
      vision_dwell_time = true;
    }
  } else if (dwell_config.id === 'disabled') {
    analytics_time = false;
    vision_dwell_time = false;
  } else if (dwell_config.id === 'vision_dwell_time') {
    analytics_time = false;
    vision_dwell_time = true;
  } else if (dwell_config.id === 'analytics_time') {
    analytics_time = true;
    vision_dwell_time = false;
  }

  data.analytics_vision = analytics_vision;
  data.analytics_time = analytics_time;
  data.vision_dwell_time = vision_dwell_time;
  data.vision_headcount = vision_headcount;

  return data;
};

class TabPermissions extends Component {
  @autobind
  getPlaceholder(name) {
    const { initialValues } = this.props;
    if (name === 'occupancy_config') {
      if (initialValues.analytics_vision) {
        return 'Overhead Occupancy';
      }
      if (initialValues.vision_headcount) {
        return 'Fisheye/Inline Unique Visitors';
      }
      return 'Disabled';
    }

    if (name === 'dwell_config') {
      if (initialValues.analytics_time) {
        return 'WiFi';
      }
      if (initialValues.vision_dwell_time) {
        return 'Vision';
      }
      return 'Disabled';
    }

    return 'Configure a source';
  }

  @autobind
  handleSave(values) {
    const { dispatch, initialValues } = this.props;
    const { id, ...rest } = values;
    const data = simplifyPermissions(rest, initialValues);
    return dispatch(updateZoneTabPermissions(id, data))
      .then(() => dispatch(getZone(id)))
      .then(() => dispatch(reset('zone_tab_permissions')));
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="columns" style={{ margin: 0 }}>
        <div className="column is-two-third">
          <div className="columns" style={{ border: '.5px solid #394B59', marginTop: 5 }}>
            <div className="column">
              <form onSubmit={handleSubmit(this.handleSave)}>
                <div className="columns">
                  <div className="column">
                    <H3>Analytics</H3>
                    {ANALYTICS_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                    <div>
                      <Field
                        component={SelectInput}
                        name="occupancy_config"
                        label="Vision"
                        placeholder={this.getPlaceholder('occupancy_config')}
                        filterable={false}
                        items={OCCUPANCY_CONFIG}
                      />
                    </div>
                    <div>
                      <Field
                        component={SelectInput}
                        name="dwell_config"
                        label="Dwell-Time/Wait-Time"
                        placeholder={this.getPlaceholder('dwell_config')}
                        filterable={false}
                        items={DWELL_CONFIG}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <H3>Heatmap</H3>
                    {HEATMAP_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                  </div>
                  <div className="column">
                    <H3>Flow</H3>
                    {SAFE_DISTANCE_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                  </div>
                  {/* <div className="column">
                    <H3>Audience</H3>
                    {AUDIENCE_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                  </div>
                  <div className="column">
                    <H3>Pathing</H3>
                    {PATHING_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                  </div>
                  <div className="column">
                    <H3>Safe Distancing</H3>
                    {SAFE_DISTANCE_TABS.map(x => (
                      <Field
                        component={CheckboxInput}
                        name={x.key}
                        label={x.title}
                        key={x.key}
                      />
                    ))}
                  </div> */}
                </div>
                <div className="has-text-right">
                  <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TabPermissions.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: 'zone_tab_permissions',
  enableReinitialize: true,
})(TabPermissions);
