import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  H1, Button, Tooltip, Spinner,
  MenuItem, Navbar, Tabs, Tab,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { AppToaster } from 'components/Toaster';
import 'antd/lib/date-picker/style/css';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import {
  rebootPeplink, getPeplink, getDevice,
} from 'actions/device';
import Config from '../config';
import { networkStatus } from '../../../Status';
import BandwidthUsage from './bandwidthUsage';
import PeplinkClients from './clientList';
import PeplinkLogs from './logs';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

class PeplinkDevice extends Component {
  constructor(props) {
    super(props);
    const { dispatch, match } = props;
    const device_id = match.params.id;
    dispatch(getDevice(device_id));
    this.state = {
      rebooting: false,
      peplinkId: null,
    };
  }

  componentDidMount() {
    const { dispatch, device, match } = this.props;
    const device_id = match.params.id;
    dispatch(getDevice(device_id));
    if (device) {
      dispatch(getPeplink(device.id)).then((response) => {
        this.setState({ peplinkId: response.payload.data.content[0].peplink_id });
      });
    }
  }

  @autobind
  handleReboot() {
    const { dispatch, device, peplink } = this.props;
    this.setState({ rebooting: true });
    dispatch(rebootPeplink(peplink.data[0].peplink_id)).then(() => {
      this.setState({ rebooting: false });
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            : Device Rebooted
          </span>
        ),
        intent: 'success',
      });
    }, () => this.setState({ rebooting: false }));
  }

  @autobind
  handleAxisTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.name}
        onClick={handleClick}
        text={item.name}
      />
    );
  }

  render() {
    const { device, location, peplink } = this.props;
    const {
      rebooting, peplinkId,
    } = this.state;
    const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);
    return (
      <div className="column">
        <H1>
          {device.name}
          <div className="p-l-md" style={{ display: 'inline' }}>
            <Tooltip content="Network" position="bottom">
              <Button large icon="cell-tower" minimal intent={networkStatus(device.status) ? 'success' : 'danger'} />
            </Tooltip>
          </div>
          <div className="is-pulled-right" style={{ display: 'inline' }}>
            <Button
              intent="warning"
              icon="power"
              onClick={this.handleReboot}
              loading={rebooting}
            >
              Reboot Peplink
            </Button>
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
            ID:&nbsp;
            {device.id}
            <Dot />
            {peplink.data && (
              <React.Fragment>
                Peplink ID:&nbsp;
                {peplink.data[0].peplink_id}
                <Dot />
                Group ID:&nbsp;
                {peplink.data[0].group_id}
                <Dot />
              </React.Fragment>
            )}
            Created:&nbsp;
            {cd}
            <Dot />
            Device Type:&nbsp;
            {device.type}
            {networkStatus(device.status) && (
              <React.Fragment>
                <Dot />
                Connection Type:&nbsp;
                {`${device.status.connection_type} ${device.status.connected_with}`}
              </React.Fragment>
            )}
          </div>
        </H1>
        <Config device={device} enableNameChange />
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-device-axis" selectedTabId={location.pathname} onChange={this.handleAxisTabChange} large>
              <Tab id={`/devices/${device.id}`} title="Bandwidth Usage" />
              <Tab id={`/devices/${device.id}/connected_devices`} title="Connected Devices" />
              <Tab id={`/devices/${device.id}/logs`} title="Logs" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <Switch>
          <Route path="/devices/:id/connected_devices">
            {peplinkId ? <PeplinkClients peplinkId={peplinkId} /> : <Spinner />}
          </Route>
          <Route path="/devices/:id/logs">
            {peplinkId ? <PeplinkLogs peplinkId={peplinkId} /> : <Spinner />}
          </Route>
          <Route>
            {peplinkId && <BandwidthUsage peplinkId={peplinkId} />}
          </Route>
        </Switch>
      </div>
    );
  }
}

PeplinkDevice.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  peplink: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default connect(state => ({
  peplink: state.peplink,
}))(PeplinkDevice);
