import { requestsReducer } from 'redux-saga-requests';
import {
  GET_AUDITS, GET_AUDIT, GET_AUDIT_SUMMARY, GET_AUDIT_OVERVIEW, GET_SIGNED_URL,
  GET_ML_MODELS, GET_AUDIT_CLIPS, GET_AUDIT_CLIP, GET_CLIP_TAGS, GET_ML_MODEL,
  GET_ASSIGNED_CLIPS, GET_ASSIGNED_CLIPS_ORG, GET_CLIPS_SUMMARY,
  GET_RAW_AUDIT_CLIP, ADD_WAIT_TIME, GET_WAIT_TIMES,
} from '../constants';

export const auditsReducer = requestsReducer({
  actionType: GET_AUDITS,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    content.meta = action.payload.data.metadata || {};
    return content;
  },
});

export const auditReducer = requestsReducer({
  actionType: GET_AUDIT,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const auditSummaryReducer = requestsReducer({
  actionType: GET_AUDIT_SUMMARY,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const auditOverviewReducer = requestsReducer({
  actionType: GET_AUDIT_OVERVIEW,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const auditSignedUrlReducer = requestsReducer({
  actionType: GET_SIGNED_URL,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getModelsReducer = requestsReducer({
  actionType: GET_ML_MODELS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const auditClipsReducer = requestsReducer({
  actionType: GET_AUDIT_CLIPS,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    content.meta = action.payload.data.metadata || {};
    return content;
  },
});

export const auditClipReducer = requestsReducer({
  actionType: GET_AUDIT_CLIP,
  multiple: false,
  getData: (state, action) => action.payload.data.content,
});

export const auditClipTagReducer = requestsReducer({
  actionType: GET_CLIP_TAGS,
  multiple: false,
  getData: (state, action) => action.payload.data.content,
});

export const getModelReducer = requestsReducer({
  actionType: GET_ML_MODEL,
  getData: (state, action) => action.payload.data.content,
});

export const getAssignedClipsReducer = requestsReducer({
  actionType: GET_ASSIGNED_CLIPS,
  getData: (state, action) => action.payload.data.content,
});

export const getAssignedClipsByOrgReducer = requestsReducer({
  actionType: GET_ASSIGNED_CLIPS_ORG,
  getData: (state, action) => action.payload.data.content,
});

export const getClipsSummaryReducer = requestsReducer({
  actionType: GET_CLIPS_SUMMARY,
  getData: (state, action) => action.payload.data.content,
});

export const getRawAuditClipReducer = requestsReducer({
  actionType: GET_RAW_AUDIT_CLIP,
  multiple: false,
  getData: (state, action) => action.payload.data.content,
});

export const addWaitTimeReducer = requestsReducer({
  actionType: ADD_WAIT_TIME,
  multiple: false,
  getData: (state, action) => action.payload.data.content,
});

export const getWaitTimesReducer = requestsReducer({
  actionType: GET_WAIT_TIMES,
  multiple: false,
  getData: (state, action) => action.payload.data.content,
});
