import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  H4, Card, ButtonGroup, Button, Spinner,
  NonIdealState, MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { RangePicker } from 'antd/lib/date-picker';
import { DateTime } from 'luxon';
import moment from 'moment';
import _ from 'lodash';

import { getPeplinkBandwidthUsage } from 'actions/device';
import { QUERY_DATE_FMT } from 'constants';
import Line from '../../Line';

const fmtr = x => `${x.toFixed(2)} MB`;
const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

class BandwidthUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: DateTime.utc().minus({ days: 7 }),
      endDate: DateTime.utc(),
      dimension: 'daily',
      wanId: 0,
    };
  }

  componentDidMount() {
    const { peplinkId, dispatch } = this.props;
    const { startDate, endDate, wanId } = this.state;
    dispatch(getPeplinkBandwidthUsage(peplinkId, 'daily',
      `${startDate.toFormat('yyyy-MM-dd')}T${startDate.toFormat('HH:mm:ss')}`,
      `${endDate.toFormat('yyyy-MM-dd')}T${endDate.toFormat('HH:mm:ss')}`, wanId));
  }

  @autobind
  onSelectDaily() {
    this.setState({ dimension: 'daily' }, this.getBandwidthUsage);
  }

  @autobind
  onSelectMonthly() {
    this.setState({ dimension: 'monthly' }, this.getBandwidthUsage);
  }

  @autobind
  onSelectInterface(item) {
    this.setState({ wanId: item.id }, this.getBandwidthUsage);
  }

  @autobind
  getBandwidthUsage(state) {
    const { dispatch, peplinkId } = this.props;
    const {
      startDate, endDate, dimension, wanId,
    } = this.state || state;
    const start = `${startDate.toFormat('yyyy-MM-dd')}T${startDate.toFormat('HH:mm:ss')}`;
    const end = `${endDate.toFormat('yyyy-MM-dd')}T${endDate.toFormat('HH:mm:ss')}`;
    dispatch(getPeplinkBandwidthUsage(
      peplinkId, dimension, start, end, wanId,
    ));
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    }, this.getBandwidthUsage);
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.name}
        onClick={handleClick}
        text={item.name}
      />
    );
  }

  renderBandwidthData(data) {
    const xFmt = x => moment(x.ts).toDate();
    const labels = data.length ? data.map(xFmt) : [];
    const datasets = [
      {
        label: 'Down',
        data: data ? data.map(x => x.down) : [],
        borderColor: '#CFF3D2',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#CFF3D2',
        pointRadius: 0,
        lineTension: 0,
      },
      {
        label: 'Up',
        data: data ? data.map(x => x.up) : [],
        borderColor: '#DB8A3A',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#DB8A3A',
        pointRadius: 0,
        lineTension: 0,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  renderBandwidthChart() {
    const { bandWidthData } = this.props;
    if (bandWidthData.pending
      && (!bandWidthData || !bandWidthData.data || !bandWidthData.data.usages
        || !bandWidthData.data.usages.length)) {
      return (
        <div className="has-text-centered" style={{ padding: '160px 0' }}>
          <Spinner />
        </div>
      );
    }
    if (!(bandWidthData.data || {}).usages && !bandWidthData.pending) {
      return (
        <Card>
          <NonIdealState icon="warning-sign" title="No Bandwidth Data" className="non-ideal-device-card" />
        </Card>
      );
    }
    const data = bandWidthData.data && bandWidthData.data.usages ? [...bandWidthData.data.usages]
      .sort((a, b) => a.ts.localeCompare(b.ts)) : [];
    const totalUp = data ? _.sum(data.map(x => x.up)) : 0;
    const totalDown = data ? _.sum(data.map(x => x.down)) : 0;
    return (
      <React.Fragment>
        <H4 className="is-marginless column">
          <span>Total Download:</span>
          <span style={{ marginLeft: 5, color: '#CFF3D2' }}>
            {`${totalDown.toFixed(2)} MB`}
          </span>
          <Dot />
          <span>Total Upload:</span>
          <span style={{ marginLeft: 5, color: '#DB8A3A' }}>
            {`${totalUp.toFixed(2)} MB`}
          </span>
        </H4>
        <div style={{ height: 300 }}>
          <Line
            yFmtr={fmtr}
            data={() => this.renderBandwidthData(data)}
            title="Bandwidth Usage"
            legend
            position="top"
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { peplinkId } = this.props;
    if (!peplinkId) {
      return (<Spinner />);
    }
    const {
      dimension, startDate, endDate, wanId,
    } = this.state;
    const interfaceFilters = [
      { id: 0, name: 'All' },
      { id: 1, name: 'Ethernet' },
      { id: 2, name: 'Cellular' },
      { id: 3, name: 'Wi-Fi' },
    ];
    const selectedInterface = interfaceFilters.filter(x => x.id === wanId)[0];
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            <Card>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format={QUERY_DATE_FMT}
                style={{ width: '500px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Card>
          </div>
          <div className="column">
            <ButtonGroup fill style={{ marginTop: '22px' }}>
              <Button active={dimension === 'daily'} text="Daily" onClick={this.onSelectDaily} />
              <Button active={dimension === 'monthly'} text="Monthly" onClick={this.onSelectMonthly} />
            </ButtonGroup>
          </div>
          <div className="column">
            <p style={{ paddingBottom: '4px' }}>Interface Filter</p>
            <Select
              noResults={<MenuItem disabled key="nr" text="No results." />}
              filterable={false}
              resetOnSelect
              itemRenderer={this.renderItem}
              onItemSelect={this.onSelectInterface}
              popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
              items={interfaceFilters}
              label="Device Name"
            >
              <Button
                text={(selectedInterface || {}).name || 'Select an Interface'}
                fill
                rightIcon="caret-down"
              />
            </Select>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            {this.renderBandwidthChart()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BandwidthUsage.propTypes = {
  dispatch: PropTypes.func,
  peplinkId: PropTypes.number,
  bandWidthData: PropTypes.object,
};

export default connect(state => ({
  bandWidthData: state.peplinkBandwidth,
}))(BandwidthUsage);
