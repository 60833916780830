/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  H1, Tag, Spinner, Icon, H3, Tooltip, Menu, MenuItem, FormGroup, Button, InputGroup, Classes, Popover, Checkbox,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
// import { Popover2 } from '@blueprintjs/popover2';
import { DateTime } from 'luxon';
import moment from 'moment';

import { AppToaster } from 'components/Toaster';
import {
  extractPbuf, getReidTask, getReidTasks, getReidTaskOutput, patchReidTask, patchReidTrackOutput, getReidTaskWaitTimes, deleteReidTaskOutput, getPbufExtract, extractAllPatches,
} from 'actions/reid';
import ReidTruth from './gtPopup';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

const INDEX_MAP = {
  0: 'clip_name',
  1: 'reference_track_id',
  2: 'assignee',
  3: 'count',
  4: 'total_accepted',
  5: 'total_rejected',
  6: 'start_time',
  7: 'end_time',
  8: 'wait_time',
};

class TaskSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: '',
      operator: '',
      condition: '',
      groundtruth: '',
      assignee: '',
      assign: false,
      tracks: [],
      assignedPerson: '',
      loading: false,
      unassignLoading: false,
      isExtracted: false,
      extractLoading: false,
      clearLoading: false,
      threshold: 1,
      pbufExtract: [],
      entryexit: false,
      manualLoading: false,
      isManual: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    // const { reidTask } = this.props;
    // const { reidTask: oldTask } = prevProps;
    // if (reidTask.data.length && !oldTask.data.length) {
    //   // eslint-disable-next-line react/no-did-update-set-state
    //   this.setState({ data: reidTask.data });
    //   if (data.extracted) {
    //     this.setState({ isExtracted: reidTask.data[0].extracted });
    //   }
    // }
  }

  @autobind
  getTaskWaitTimes() {
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const { data } = this.state;
    let reidOutput = data;
    if (data.length === 0) {
      return;
    }
    dispatch(getReidTaskWaitTimes(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const waitTimes = payload.data.content;
        reidOutput = reidOutput.map((_elem) => {
          const gt = waitTimes.find(o => (o.clip_name === _elem.clip_name && o.track_id === _elem.reference_track_id));
          if (gt) {
            const start_epoch = moment.unix(gt.start_epoch);
            const end_epoch = moment.unix(gt.end_epoch);
            const wait_time = moment.utc(end_epoch.diff(start_epoch)).format('mm:ss');
            // eslint-disable-next-line prefer-const
            let element = Object.assign({}, _elem); // decouple instance
            element.start_time = start_epoch.format('HH:mm:ss');
            element.end_time = end_epoch.format('HH:mm:ss');
            element.wait_time = wait_time;
            return element; // replace original with new instance
          }
          return _elem;
        });
        this.setState({ data: reidOutput }, this.refreshTable);
      }
    });
  }

  @autobind
  getData() {
    const { dispatch, match, tasks } = this.props;
    const { id } = match.params;
    dispatch(getReidTaskOutput(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content });
      }
    }).then(() => this.getTaskWaitTimes());
    dispatch(getReidTask(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ isExtracted: payload.data.content.extracted, threshold: payload.data.content.distance_threshold, isManual: payload.data.content.manual_reid });
      }
    });
    if (!((tasks.data || []).length)) {
      dispatch(getReidTasks());
    }
  }

  @autobind
  async handleGetGroundTruth() {
    const { dispatch, match, reidOutput } = this.props;
    const trackData = [...reidOutput.data];
    const filteredData = trackData.filter(this.isAssignedUser).filter(this.noGroundtruth);
    const selected_clip_name = ((filteredData || []).length) ? filteredData[0].clip_name : trackData[0].clip_name;
    const selected_track_id = ((filteredData || []).length) ? filteredData[0].reference_track_id : trackData[0].reference_track_id;
    dispatch(push(`/reid/${match.params.id}/groundtruth/${selected_clip_name}/${selected_track_id}`));
  }

  @autobind
  refreshTable() {
    const {
      groundtruth, assignee: stateAssigned,
      filter, operator, condition, data,
    } = this.state;
    // const { reidOutput } = this.props;
    let taskData = [...data];
    const filteredData = (() => {
      if (groundtruth === '1') {
        taskData = taskData.filter(this.hasGroundTruth);
      }
      if (groundtruth === '-1') {
        taskData = taskData.filter(this.noGroundtruth);
      }
      if (stateAssigned === '1') {
        taskData = taskData.filter(this.isAssigned);
      }
      if (stateAssigned === '-1') {
        taskData = taskData.filter(this.isNotAssigned);
      }
      const intCondition = parseInt(condition, 10);
      if (intCondition != null && filter) {
        taskData = data.filter((x) => {
          const column = x[filter];
          switch (operator) {
            case '>':
              return column > intCondition;
            case '<':
              return column < intCondition;
            case '=':
              return column === intCondition;
            case '!=':
              return column !== intCondition;
            default:
              break;
          }
          return x;
        });
      }
      return taskData;
    })();
    return this.setState({ data: filteredData });
  }

  @autobind
  hasGroundTruth({ reid_ground_truth_aggreg }) {
    return (reid_ground_truth_aggreg.total_accepted !== 0 || reid_ground_truth_aggreg.total_rejected !== 0);
  }

  @autobind
  noGroundtruth({ reid_ground_truth_aggreg }) {
    return (reid_ground_truth_aggreg.total_accepted === 0 && reid_ground_truth_aggreg.total_rejected === 0);
  }

  @autobind
  isAssignedUser({ assignee }) {
    const { currentUser } = this.props;
    return (currentUser && assignee === currentUser.profile.name);
  }

  @autobind
  isAssigned({ assignee }) {
    return !!assignee;
  }


  @autobind
  isNotAssigned({ assignee }) {
    return !assignee;
  }

  @autobind
  toggleAssign() {
    const { assign } = this.state;
    this.setState({ assign: !assign });
  }

  @autobind
  handleFilter(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({ filter: value, operator: '', condition: '' }, this.refreshTable);
    }
    this.setState({ filter: value });
  }

  @autobind
  handleGroundTruth(e) {
    this.setState({ groundtruth: e.target.value }, this.refreshTable);
  }

  @autobind
  handleAssigned(e) {
    this.setState({ assignee: e.target.value }, this.refreshTable);
  }

  @autobind
  handleOperator(e) {
    this.setState({ operator: e.target.value });
  }

  @autobind
  handleCondition(e) {
    this.setState({ condition: e.target.value });
  }

  @autobind
  handleAssign(e) {
    this.setState({ assignedPerson: e.target.value });
  }

  @autobind
  clearTracks() {
    this.setState({ tracks: [] });
  }

  @autobind
  async isValidAssignment() {
    const { tracks } = this.state;
    const { dispatch, match } = this.props;
    let takenClips = false;
    const isValid = await dispatch(getReidTaskOutput(match.params.id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        const currentReidTrack = [...payload.data.content];
        tracks.forEach((c) => {
          const current = currentReidTrack.find(x => (x.clip_name === c.clip_name && x.reference_track_id === c.reference_track_id)) || {};
          if (current.assignee) {
            takenClips = true;
          }
        });
      }
      if (takenClips) return false;
      return true;
    });
    return isValid;
  }

  @autobind
  async handleClipAssignment() {
    const { tracks, assignedPerson } = this.state;
    const { dispatch, match } = this.props;
    if (!assignedPerson) {
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>Must select a person</span>,
      });
    }
    this.setState({ loading: true });
    const isValid = await this.isValidAssignment();
    if (!isValid) {
      this.setState({ loading: false });
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>One or more tracks you have selected are taken</span>,
      });
    }
    try {
      await Promise.all(tracks.map(x => dispatch(patchReidTrackOutput(
        match.params.id,
        x.clip_name,
        x.reference_track_id,
        { assignee: assignedPerson },
      ))));
      return this.getData();
    } catch (e) {
      //
    } finally {
      this.setState({ loading: false, assignedPerson: '', tracks: [] });
    }
    return null;
  }

  @autobind
  async handleUnassign() {
    const { dispatch, match } = this.props;
    const { tracks } = this.state;
    this.setState({ unassignLoading: true });
    try {
      await Promise.all(tracks.map(x => dispatch(patchReidTrackOutput(
        match.params.id,
        x.clip_name,
        x.reference_track_id,
        { assignee: '' },
      ))));
      return this.getData();
    } catch (e) {
      //
    } finally {
      this.setState({ unassignLoading: false, tracks: [] });
    }
    return null;
  }

  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  @autobind
  async extractProtobuf() {
    const { dispatch, match, reidTask } = this.props;
    const { threshold, entryexit } = this.state;
    this.setState({ extractLoading: true });
    if (reidTask.data.reid_matrix === 0) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Reid Job Not Complete</span>,
        intent: 'danger',
      });
    }
    return dispatch(extractPbuf(match.params.id, threshold, entryexit))
      .then(() => (this.delayGet(5000).then(() => this.getData())))
      .finally(() => this.setState({ extractLoading: false, isExtracted: true }));
  }

  @autobind
  async clearOutput() {
    const { dispatch, match, reidTask } = this.props;
    this.setState({ clearLoading: true });
    if (reidTask.data.reid_matrix === 0) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Reid Job Not Complete</span>,
        intent: 'danger',
      });
    }
    return dispatch(deleteReidTaskOutput(match.params.id))
      .then(() => this.getData())
      .finally(() => this.setState({ clearLoading: false, isExtracted: false }));
  }

  @autobind
  async handleGetExtract() {
    const { dispatch, match } = this.props;
    const { threshold } = this.state;
    this.setState({ extractLoading: true });
    return dispatch(getPbufExtract(match.params.id, threshold)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({
          clearLoading: false, isExtracted: false, extractLoading: false, pbufExtract: payload.data.content,
        });
      }
    });
  }

  @autobind
  selectTrack(row) {
    if (row && row[0].cols) {
      if (row[0].cols[0] > 0 || row[0].cols[1] > 0) {
        return null;
      }
    }
    const { data } = this.state;
    if (row && row[0] && row[0].rows) {
      const start = row[0].rows[0];
      const end = row[0].rows[1];
      const selectedTracks = [...data].slice(start, end + 1);
      return this.setState({ tracks: selectedTracks });
    }
    return null;
  }

  @autobind
  applyFilter() {
    const {
      data, filter, operator, condition,
    } = this.state;
    const intCondition = parseInt(condition, 10);
    if (intCondition != null) {
      const filteredData = data.filter((x) => {
        const column = x[filter];
        switch (operator) {
          case '>':
            return column > intCondition;
          case '<':
            return column < intCondition;
          case '=':
            return column === intCondition;
          case '!=':
            return column !== intCondition;
          default:
            break;
        }
        return x;
      });
      this.setState({ data: filteredData });
    }
  }

  @autobind
  sortAsc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 2].includes(column)) {
        return a[INDEX_MAP[column]].localeCompare(b[INDEX_MAP[column]]);
      }
      if ([4, 5].includes(column)) {
        return a.reid_ground_truth_aggreg[INDEX_MAP[column]] - b.reid_ground_truth_aggreg[INDEX_MAP[column]];
      }
      if ([6, 7].includes(column)) {
        return moment(a[INDEX_MAP[column]], 'hh-mm-ss').isBefore(moment(b[INDEX_MAP[column]]), 'hh-mm-ss') ? -1 : 1;
      }
      if ([8].includes(column)) {
        return moment(a[INDEX_MAP[column]], 'mm:ss').isBefore(moment(b[INDEX_MAP[column]]), 'mm:ss') ? -1 : 1;
      }
      return a[INDEX_MAP[column]] - b[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  handleThreshold(e) {
    this.setState({ threshold: e.target.value });
  }

  @autobind
  handleEntryExit(e) {
    this.setState({ entryexit: e.target.checked });
  }

  @autobind
  sortDesc(column) {
    const { data } = this.state;
    const sortedData = [...data].sort((a, b) => {
      if ([0, 2].includes(column)) {
        return b[INDEX_MAP[column]].localeCompare(a[INDEX_MAP[column]]);
      }
      if ([4, 5].includes(column)) {
        return b.reid_ground_truth_aggreg[INDEX_MAP[column]] - a.reid_ground_truth_aggreg[INDEX_MAP[column]];
      }
      if ([6, 7].includes(column)) {
        return moment(b[INDEX_MAP[column]], 'hh-mm-ss').isBefore(moment(a[INDEX_MAP[column]], 'hh-mm-ss')) ? -1 : 1;
      }
      if ([8].includes(column)) {
        return moment(b[INDEX_MAP[column]], 'mm:ss').isBefore(moment(a[INDEX_MAP[column]]), 'mm:ss') ? -1 : 1;
      }
      return b[INDEX_MAP[column]] - a[INDEX_MAP[column]];
    });
    this.setState({ data: sortedData });
  }

  @autobind
  pauseReidTask(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchReidTask(id, { task_status: 4 }))
      .then(() => dispatch(getReidTasks(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`${name} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeReidTask(name) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    return dispatch(patchReidTask(id, { task_status: 0 }))
      .then(() => dispatch(getReidTasks(undefined, undefined, id, undefined))
      && AppToaster.show({
        icon: 'play',
        message: <span>{`${name} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  manualReid() {
    const { dispatch, match } = this.props;
    const { isManual } = this.state;
    if (isManual) {
      return dispatch(push(`/reid/${match.params.id}/manual`));
    }
    this.setState({ manualLoading: true });
    return dispatch(extractAllPatches(match.params.id))
      .then(() => this.setState({ manualLoading: false }))
      .finally(() => dispatch(push(`/reid/${match.params.id}/manual`)));
  }

  @autobind
  cellRenderer(index, title) {
    const { data } = this.state;
    const row = data[index];
    if (row) {
      return <Cell tooltip={row[title]}>{row[title]}</Cell>;
    }
    return <Cell />;
  }

  @autobind
  gtCellRenderer(index, title) {
    const { data } = this.state;
    const row = data[index];
    if (row) {
      return <Cell tooltip={row.reid_ground_truth_aggreg[title]}>{row.reid_ground_truth_aggreg[title]}</Cell>;
    }
    return <Cell />;
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderContextMenu(a) {
    const { data } = this.state;
    const { dispatch } = this.props;
    if (a.target && a.target.rows) {
      const selectedRow = data.find((x, i) => i === a.target.rows[0]);
      if (selectedRow) {
        return <ReidTruth selectedRow={selectedRow} getData={this.getData} dispatch={dispatch} />;
      }
    }
    return undefined;
  }


  // @autobind
  // renderExtractPopup() {
  //   const { reidTask } = this.props;
  //   const { extractLoading, threshold, pbufExtract } = this.state;
  //   console.log('Render popup');
  //   if (reidTask.data.reid_matrix === 0) {
  //     return AppToaster.show({
  //       icon: 'cross',
  //       message: <span>Reid Job Not Complete</span>,
  //       intent: 'danger',
  //     });
  //   }
  //   return (

  //     <Popover style={{ height: '10em', width: '10em' }}>
  //       <div><strong>Extract Reid Task</strong></div>
  //       <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
  //         <div>
  //           <FormGroup label="Distance Threshold">
  //             <InputGroup value={threshold} onChange={this.handleThreshold} type="number" />
  //           </FormGroup>
  //         </div>
  //       </div>
  //       <div className="has-text-right">
  //         <Button loading={extractLoading} onClick={this.handleGetExtract} intent="primary" type="submit" icon="tick">
  //           View Output
  //         </Button>
  //       </div>
  //       <div className="has-text-right">
  //         <Button loading={extractLoading} onClick={this.extractProtobuf} intent="primary" type="submit" icon="tick">
  //           Extract
  //         </Button>
  //       </div>
  //       <div className="column">
  //         {!!((pbufExtract || []).length) && (
  //           <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
  //             <thead>
  //               <tr>
  //                 <th>Track ID</th>
  //                 <th>No of Patches</th>
  //                 <th />
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {pbufExtract.map(this.renderPbufExtract)}
  //             </tbody>
  //           </table>
  //         )}
  //       </div>
  //     </Popover>
  //   );
  // }


  @autobind
  renderFilters() {
    const {
      tracks, assignedPerson,
      loading, unassignLoading, groundtruth, assignee,
    } = this.state;
    const { users } = this.props;
    return (
      <div className="flex-space-between-container" style={{ alignItems: 'center' }}>
        <div className="reid-filter-container">
          <FormGroup
            label="Ground Truth"
            className="inline-flex m-r-md"
          >
            <div className="bp3-select">
              <select id="user-status" value={groundtruth || ''} onChange={this.handleGroundTruth}>
                <option value="">--</option>
                <option value="1">Completed</option>
                <option value="-1">Incomplete</option>
              </select>
            </div>
          </FormGroup>
          <FormGroup
            label="Assignee"
            className="inline-flex m-r-md"
          >
            <div className="bp3-select">
              <select id="user-status" value={assignee || ''} onChange={this.handleAssigned}>
                <option value="">--</option>
                <option value="1">Assigned</option>
                <option value="-1">Unnassigned</option>
              </select>
            </div>
          </FormGroup>
        </div>
        <div>
          <Popover
            content={tracks.length ? (
              <div style={{ padding: '10px', display: 'flex' }}>
                <div className="bp3-select">
                  <select value={assignedPerson} name="assign" className="bp3-select" onChange={this.handleAssign}>
                    <option value="">Select Person</option>
                    {([...users.data] || [])
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(x => <option value={x.name} key={x.id}>{x.name}</option>)}
                  </select>
                </div>
                <Button loading={loading} onClick={this.handleClipAssignment} style={{ marginLeft: 5 }} icon="tick" intent="primary" />
              </div>
            ) : null}
            target={(
              <Button disabled={!tracks.length} icon="person" intent="primary">
                {tracks.length ? `Assign Tracks (${tracks.length})` : 'Assign Tracks'}
              </Button>
            )}
          />
          <Button
            icon="blocked-person"
            onClick={this.handleUnassign}
            style={{ marginLeft: 10 }}
            intent="danger"
            disabled={!tracks.length}
            loading={unassignLoading}
          >
            {tracks.length ? `Unassign Tracks (${tracks.length})` : 'Unassign Tracks'}
          </Button>
          {!!tracks.length && (
            <Tooltip content="Clear selected tracls">
              <Button outlined minimal onClick={this.clearTracks} style={{ marginLeft: 10 }} icon="cross" intent="danger" />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  @autobind
  renderReidTaskTable() {
    const { data } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <div className="flex-space-between-container">
          <H3>ReidTask Report</H3>
        </div>
        {this.renderFilters()}
        <div className="columns" style={{ marginTop: 20, paddingBottom: 10, height: 400 }}>
          <Table
            columnWidths={[300, 100, 130, 100, 100, 100, 100, 100, 100]}
            numFrozenColumns={1}
            numRows={data.length}
            class="bp3-html-table bp3-table-resize-horizontal"
            getCellClipboardData={(row, col) => data[row][INDEX_MAP[col]]}
            onSelection={this.selectTrack}
            bodyContextMenuRenderer={a => this.renderContextMenu(a)}
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Clip Name" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'clip_name')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Track ID" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'reference_track_id')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Assignee" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'assignee')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="No of Patches" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'count')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Total Accepted" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.gtCellRenderer(i, 'total_accepted')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Total Rejected" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.gtCellRenderer(i, 'total_rejected')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Start Time" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'start_time')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="End Time" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'end_time')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Wait Time" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'wait_time')}
            />
          </Table>
        </div>
      </div>
    );
  }


  @autobind
  renderClipInput(clip, i) {
    return (
      <tr key={i}>
        <td>
          <Link to={`/devices/${clip.camera_serial}`}>
            {clip.camera_serial}
          </Link>
        </td>
        <td>{DateTime.fromISO(clip.start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td>{DateTime.fromISO(clip.end_time).toLocaleString(DateTime.DATETIME_MED)}</td>
      </tr>
    );
  }

  @autobind
  renderPbufExtract(track, i) {
    return (
      <tr key={i}>
        <td>
          {track.track_id}
        </td>
        <td>{track.count}</td>
      </tr>
    );
  }

  render() {
    const {
      reidOutput, reidTask, tasks, match, dispatch,
    } = this.props;
    const {
      isExtracted, extractLoading, clearLoading, threshold, pbufExtract, entryexit, manualLoading,
    } = this.state;
    if (
      (reidTask.pending || !reidTask.data)
      || (reidOutput.pending || !reidOutput.data)
      || (tasks.pending || !tasks.data.length)
    ) {
      return <Spinner size={100} />;
    }

    const selectedReidTask = (tasks || {})
      .data.find(x => x.task_id === parseInt(match.params.id, 10));
    const {
      task_id, title, EndTime, feature_model_id, model_id, s3_output_path, region, config, task_status, fast_mot_version, clips_input, task_type, task_ids,
    } = selectedReidTask || {};
    const reidStatus = task_status === 0 && EndTime !== null ? 'Failed' : STATUS_MAP[task_status];
    if (task_type === 'merge') {
      return (
        <div className="container" style={{ marginBottom: 20 }}>
          <div>
            <div className="reid-header-container">
              <H1>
                <Icon
                  className="reid-back-icon"
                  icon="circle-arrow-left"
                  iconSize={30}
                  style={{ marginRight: '1em' }}
                  onClick={() => dispatch(push('/reid'))}
                />
                {title}
              </H1>
            </div>
            <div className="bp3-text-medium bp3-text-muted reid-header" style={{ margin: '1em 0 1em 0' }}>
              {`ID: ${task_id}`}
              <Dot />
              {`Type: ${task_type}`}
              <Dot />
              {`Merge Task IDs: ${task_ids}`}
              <Dot />
              {`Status: ${reidStatus}`}
            </div>
            <div>
              {reidStatus !== 'Failed' && task_status !== 1 && (
              <Tooltip content={task_status === 4 ? 'Resume ReidTask' : 'Pause ReidTask'}>
                <div className="pause-reid-container">
                  <Icon
                    iconSize={30}
                    className="pause-reid"
                    icon={task_status === 4 ? 'play' : 'pause'}
                    onClick={() => (task_status === 4
                      ? this.resumeReidTask(title)
                      : this.pauseReidTask(title))}
                  />
                </div>
              </Tooltip>
              )}
            </div>
          </div>
        </div>
      );
    }
    // const {
    //   ,
    // } = reidTask.data || {};
    const entryClips = clips_input.entry ? clips_input.entry : null;
    const exitClips = clips_input.exit ? clips_input.exit : null;
    const refireValues = reidTask.data
      ? ([reidTask.data] || []).map(({ clips_input: ci, ...rest }) => ({
        ...rest,
        clips_input,
        entry: ci && ci.entry ? ci.entry.map(x => ({
          camera_serial: x.camera_serial,
          start_time: moment(x.start_time).toDate(),
          end_time: moment(x.end_time).toDate(),
        })) : [],
        exit: ci && ci.exit ? ci.exit.map(x => ({
          camera_serial: x.camera_serial,
          start_time: moment(x.start_time).toDate(),
          end_time: moment(x.end_time).toDate(),
        })) : [],
      })) : {};
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="reid-header-container">
            <H1>
              <Icon
                className="reid-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                style={{ marginRight: '1em' }}
                onClick={() => dispatch(push('/reid'))}
              />
              {title}
            </H1>
          </div>
          <div className="bp3-text-medium bp3-text-muted reid-header" style={{ margin: '1em 0 1em 0' }}>
            {`ID: ${task_id}`}
            <Dot />
            {`Model: ${model_id}`}
            <Dot />
            {`Feature Model: ${feature_model_id}`}
            <Dot />
            {`Version: ${fast_mot_version}`}
            <Dot />
            {`Status: ${reidStatus}`}
          </div>
          <div>
            {reidStatus !== 'Failed' && task_status !== 1 && (
            <Tooltip content={task_status === 4 ? 'Resume ReidTask' : 'Pause ReidTask'}>
              <div className="pause-reid-container">
                <Icon
                  iconSize={30}
                  className="pause-reid"
                  icon={task_status === 4 ? 'play' : 'pause'}
                  onClick={() => (task_status === 4
                    ? this.resumeReidTask(title)
                    : this.pauseReidTask(title))}
                />
              </div>
            </Tooltip>
            )}
            <Link
              to={{
                pathname: '/reid/create',
                state: { initialValues: refireValues[0] },
              }}
            >
              <Button style={{ margin: '0px 0px 10px 10px' }} icon="repeat" intent="primary">Re-Fire</Button>
            </Link>
            <Button style={{ margin: '0px 0px 10px 10px' }} disabled={!(reidStatus === 'Complete')} onClick={this.handleGetGroundTruth} icon="play" intent="primary">Start Ground Truth</Button>
            {/* <Button style={{ margin: '0px 0px 10px 10px' }} disabled={(isExtracted || !(reidStatus === 'Complete'))} onClick={this.renderExtractPopup} loading={(extractLoading)} intent="primary" icon="archive">Extract Protobuf</Button> */}
            <Button style={{ margin: '0px 0px 10px 10px' }} disabled={(!isExtracted || !(reidStatus === 'Complete'))} onClick={this.clearOutput} loading={(clearLoading)} intent="primary" icon="trash">Clear Output</Button>
            <Button style={{ margin: '0px 0px 10px 10px' }} onClick={this.manualReid} loading={(manualLoading)} intent="primary" icon="play">Manual REID</Button>
            <Popover
              interactionKind="click"
              disabled={(isExtracted || !(reidStatus === 'Complete'))}
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              placement="bottom"
              content={(
                <div style={{ height: '32em', width: '30em', margin: '1em' }}>
                  <H3>Extract Reid Task</H3>
                  <div className="columns" style={{ marginTop: 10, justifyContent: 'space-between' }}>
                    <div className="column">
                      <FormGroup label="Distance Threshold">
                        <InputGroup value={threshold} onChange={this.handleThreshold} type="number" />
                      </FormGroup>
                      <Button loading={extractLoading} onClick={this.handleGetExtract} intent="primary" type="submit" icon="search">
                        View Output
                      </Button>
                    </div>
                    <div className="column has-text-right">
                      <div>
                        <FormGroup label="Total Tracks">
                          <div>{pbufExtract.length}</div>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup label="Total Patches">
                          <div>{pbufExtract.reduce((a, b) => a + (b.count || 0), 0)}</div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="column" style={{ width: '30em' }}>
                    {!!((pbufExtract || []).length) && (
                      <table style={{ height: '15em', display: 'block', overflow: 'auto' }} className={classNames('bp3-html-table', { 'bp3-skeleton': false })}>
                        <thead>
                          <tr>
                            <th>Track ID</th>
                            <th>No of Patches</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {pbufExtract.map(this.renderPbufExtract)}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="has-text-right">
                    <Checkbox checked={entryexit} hidden={pbufExtract.length === 0} onChange={e => this.handleEntryExit(e)} label="Entry and Exit" style={{ display: 'inline', marginRight: 10 }} />
                    <Button loading={extractLoading} hidden={pbufExtract.length === 0} onClick={this.extractProtobuf} intent="primary" type="submit" icon="tick">
                      Extract
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button style={{ margin: '0px 0px 10px 10px' }} disabled={(isExtracted || !(reidStatus === 'Complete'))} loading={(extractLoading)} intent="primary" icon="archive">Extract Protobuf</Button>
            </Popover>
          </div>
          {!!entryClips && (
            <div style={{ marginTop: 10 }}>
              <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
                <thead>
                  <tr>
                    <th>Camera</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {entryClips.map(this.renderClipInput)}
                </tbody>
              </table>
            </div>
          )}
          {!!exitClips && (
            <div style={{ marginTop: 10 }}>
              <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
                <thead>
                  <tr>
                    <th>Camera</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {exitClips.map(this.renderClipInput)}
                </tbody>
              </table>
            </div>
          )}
          <H1>
            <div className="bp3-text-small">
              <div className="reid-summary">
                <div className="reid-summary-row">
                  <div className="reid-summary-row-item">
                    <div>S3 Output Path:</div>
                    <Tag multiline>{s3_output_path}</Tag>
                  </div>
                  <div className="reid-summary-row-item">
                    <div>Region:</div>
                    <Tag multiline>{region}</Tag>
                  </div>
                  <div className="reid-summary-row">
                    <div className="reid-summary-row-item">
                      <div>Config:</div>
                      <Tag multiline>{config || 'N/A'}</Tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </H1>
        </div>
        <div>
          {this.renderReidTaskTable()}
        </div>
      </div>
    );
  }
}

TaskSummary.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  reidOutput: PropTypes.object,
  reidTask: PropTypes.object,
  tasks: PropTypes.object,
  users: PropTypes.object,
  currentUser: PropTypes.object,
};

export default connect(state => ({
  reidOutput: state.reidOutput,
  reidTask: state.reidTask,
  token: state.currentUser.token.access_token,
  tasks: state.tasks,
  users: state.users,
  tracks: state.tracks,
  currentUser: state.currentUser,
}))(TaskSummary);
