import { success, error, abort } from 'redux-saga-requests';
import {
  CAMERA_STATS_INS, CAMERA_STATS_FPS, CAMERA_STATS_RESOLUTION,
  CAMERA_LINE_STATS,
} from '../constants';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case CAMERA_STATS_INS: {
      return {
        ...state,
        [action.meta.name]: {
          response: state[action.meta.name] ? state[action.meta.name].response : undefined,
          resolved: false,
          failed: false,
          error: null,
        },
      };
    }
    case CAMERA_LINE_STATS: {
      return {
        ...state,
        [action.meta.name]: {
          response: state[action.meta.name] ? state[action.meta.name].response : undefined,
          resolved: false,
          failed: false,
          error: null,
        },
      };
    }
    case CAMERA_STATS_FPS: {
      return {
        ...state,
        [action.meta.name]: {
          response: state[action.meta.name] ? state[action.meta.name].results : undefined,
          resolved: false,
          failed: false,
          error: null,
        },
      };
    }
    case CAMERA_STATS_RESOLUTION: {
      return {
        ...state,
        [action.meta.name]: {
          response: state[action.meta.name] ? state[action.meta.name].results : undefined,
          resolved: false,
          failed: false,
          error: null,
        },
      };
    }
    case success(CAMERA_STATS_INS): {
      return {
        ...state,
        [action.meta.name]: {
          response: action.payload.data.content,
          resolved: true,
          failed: false,
          error: null,
        },
      };
    }
    case success(CAMERA_LINE_STATS): {
      return {
        ...state,
        [action.meta.name]: {
          response: action.payload.data.content,
          resolved: true,
          failed: false,
          error: null,
        },
      };
    }
    case success(CAMERA_STATS_FPS): {
      return {
        ...state,
        [action.meta.name]: {
          response: action.payload.data.results,
          resolved: true,
          failed: false,
          error: null,
        },
      };
    }
    case success(CAMERA_STATS_RESOLUTION): {
      return {
        ...state,
        [action.meta.name]: {
          response: action.payload.data.results,
          resolved: true,
          failed: false,
          error: null,
        },
      };
    }
    case error(CAMERA_STATS_RESOLUTION):
    case error(CAMERA_STATS_FPS):
    case error(CAMERA_STATS_INS): {
      if (action.payload.response.status >= 400 && action.payload.response.status < 600) {
        return {
          ...state,
          [action.meta.name]: {
            resolved: true,
            failed: true,
            error: action.payload.response.data.result.errorCode,
          },
        };
      }
      return {
        ...state,
        [action.meta.name]: {
          resolved: true,
          failed: true,
          error: action.payload.toString ? action.payload.toString() : '',
        },
      };
    }
    case error(CAMERA_LINE_STATS): {
      if (action.payload.response.status >= 400 && action.payload.response.status < 600) {
        return {
          ...state,
          [action.meta.name]: {
            resolved: true,
            failed: true,
            error: action.payload.response.data.result.errorCode,
          },
        };
      }
      return {
        ...state,
        [action.meta.name]: {
          resolved: true,
          failed: true,
          error: action.payload.toString ? action.payload.toString() : '',
        },
      };
    }
    case abort(CAMERA_STATS_RESOLUTION):
    case abort(CAMERA_STATS_FPS):
    case abort(CAMERA_STATS_INS):
      return {
        ...state,
      };
    case abort(CAMERA_LINE_STATS):
      return {
        ...state,
      };
    default:
      return state;
  }
}
