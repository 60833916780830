/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';
import { getLocation, saveLocation } from 'actions/location';

import { TextInput } from 'components/inputs';

class LocationInfo extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, match, handleDisable } = this.props;
    return dispatch(saveLocation(match.params.id, {
      name: values.name,
      anonymize_threshold: values.anonymize_threshold
        ? values.anonymize_threshold.toString() : null,
      data_expiration_ttl: parseInt(values.expiration, 10),
    })).then(() => dispatch(getLocation(match.params.id)))
      .then(() => handleDisable());
  }

  render() {
    const {
      disabled, submitting, handleSubmit, handleDisable,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
        <div className="columns" style={{ border: '.5px solid #394B59', marginLeft: '0px' }}>
          <div className="column">
            <Field
              component={TextInput}
              placeholder=""
              name="name"
              label="Location Name"
              type="text"
              disabled={disabled}
            />
            <Field
              component={TextInput}
              placeholder=""
              name="expiration"
              label="Data Expiration TTL"
              type="text"
              disabled={disabled}
              // validate={[validateNum]}
            />
            <Field
              component={TextInput}
              placeholder=""
              name="anonymize_threshold"
              label="Anonymize Threshold"
              type="text"
              disabled={disabled}
              // validate={[validateNum]}
            />
          </div>
        </div>
        <div className="has-text-right">
          <Button intent="success" type="button" onClick={handleDisable}>
            Edit
          </Button>
        &nbsp;&nbsp;
          <Button intent="primary" loading={submitting} disabled={disabled} type="submit" icon="tick">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

LocationInfo.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  match: PropTypes.object,
  handleDisable: PropTypes.func,
};

export default reduxForm({
  form: 'location',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (values.expiration && isNaN(values.expiration)) {
      errors.expiration = 'Number required';
    }
    if (values.anonymize_threshold && isNaN(values.anonymize_threshold)) {
      errors.anonymize_threshold = 'Number required';
    }
    return errors;
  },
})(LocationInfo);
