import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'slugify';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, CheckboxInput } from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';

import { createOrganization } from 'actions/organization';

const formSelector = formValueSelector('create-organization');

class CreateOrganization extends Component {
  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    return dispatch(createOrganization({
      name: values.name,
      slug: values.slug || slugify(values.name, { lower: true }),
      active: values.active,
    })).then(action => dispatch(push(`/organizations/${action.payload.data.content.id}`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            : Organization created
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      slugName, submitting, handleSubmit,
    } = this.props;

    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create Organization
            </H1>
            <Field
              component={TextInput}
              placeholder=""
              name="name"
              label="Name"
              type="text"
            />
            <Field
              component={TextInput}
              placeholder={slugName}
              name="slug"
              label="Slug"
              type="text"
            />
            <Field
              component={CheckboxInput}
              type="checkbox"
              id="active"
              name="active"
              label="Active"
            />
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateOrganization.propTypes = {
  dispatch: PropTypes.func,
  slugName: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default connect(state => ({
  slugName: slugify(formSelector(state, 'name') || '', { lower: true }),
  initialValues: {
    active: true,
  },
}))(reduxForm({
  form: 'create-organization',
  validate: (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'This field is required.';
    }
    return errors;
  },
})(CreateOrganization));
