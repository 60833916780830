import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import {
  H1, Spinner, Icon, Tag,
} from '@blueprintjs/core';
import qs from 'query-string';

import VisualizeReid from './visualizeCompare';
import { compareReidAudit, getReidAuditTasks } from '../../../actions/reid';
// import Summarize from './summarize';

class ReidAuditCompare extends Component {
  async componentDidMount() {
    const { dispatch, location, reidAuditTasks } = this.props;
    const audit_ids = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (audit_ids) {
      dispatch(compareReidAudit(audit_ids.audit_ids));
    }
    if (reidAuditTasks.data.length === 0) {
      dispatch(getReidAuditTasks());
    }
  }

  @autobind
  goBack() {
    const { dispatch } = this.props;
    dispatch(push('/reid/audit'));
  }

  render() {
    const {
      reidAuditTasks, reidAuditCompareSummary, location,
    } = this.props;
    if (
      (reidAuditTasks.data.length === 0)
      || (reidAuditCompareSummary.pending && !reidAuditCompareSummary.data)
    ) {
      return <Spinner size={100} />;
    }
    return (
      <div className="container">
        <H1>
          <Icon
            className="audit-back-icon"
            icon="circle-arrow-left"
            iconSize={30}
            onClick={this.goBack}
          />
          Compare Reid Models
          &nbsp;&nbsp;&nbsp;
          <Tag>
            Audit Ids:
            &nbsp;
            {qs.parse(location.search, { ignoreQueryPrefix: true }).audit_ids}
          </Tag>
        </H1>
        <div>
          <VisualizeReid compareData={reidAuditCompareSummary.data} tasks={reidAuditTasks.data} />
        </div>
      </div>
    );
  }
}

ReidAuditCompare.propTypes = {
  reidAuditTasks: PropTypes.object,
  reidAuditCompareSummary: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
};

export default connect(state => ({
  reidAuditTasks: state.reidAuditTasks,
  reidAuditCompareSummary: state.reidAuditCompareSummary,
}))(ReidAuditCompare);
