import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './list';
import Clip from './clip';


export default () => (
  <Switch>
    <Route path="/audits/groundtruth/:id" component={Clip} />
    <Route component={List} />
  </Switch>
);
