import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Callout, Button } from '@blueprintjs/core';
import { Terminal as Xterm } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

class Terminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ws: null,
      term: null,
      ready: false,
      connecting: false,
    };
    this.term = React.createRef();
  }

  componentDidMount() {
    const { device } = this.props;
    const term = new Xterm();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    term.open(this.term.current);
    term.onData((e) => {
      const { ready, ws } = this.state;
      if (!ready || !ws) {
        return;
      }
      const data = e.split('').map(c => c.charCodeAt(0).toString(16).padStart(2, '0')).join('');
      const cmd = {
        command: 'TTY',
        args: [data],
      };
      ws.send(JSON.stringify(cmd));
    });
    fitAddon.fit();
    // term.write('Hello World');
    // term.prompt();
    this.setState({ term }, () => this.openSocket(device.id));
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props;
    if (device.id !== prevProps.device.id) {
      this.closeSocket();
      this.openSocket(device.id);
    }
  }

  componentWillUnmount() {
    const { term } = this.state;
    term.dispose();
    this.closeSocket();
  }

  @autobind
  handleConnect() {
    const { device } = this.props;
    this.openSocket(device.id);
  }

  openSocket(deviceID) {
    const { accessToken } = this.props;
    const { term } = this.state;
    const apiHost = process.env.API_HOST ? process.env.API_HOST : 'ws://localhost:4729';
    const apiLink = process.env.NODE_ENV === 'production' ? 'wss://api.livereachmedia.com' : apiHost;
    const socket = new WebSocket(`${apiLink}/api/v1/iap/${deviceID}/shell?tty=true&term=xterm-256color&cols=${term.cols}&rows=${term.rows}&access_token=${accessToken}`);
    this.setState({ connecting: true });
    socket.binaryType = 'arraybuffer';
    socket.addEventListener('open', () => {
      this.setState({ ws: socket });
    });
    socket.addEventListener('message', (event) => {
      const view = new Uint8Array(event.data);
      // eslint-disable-next-line no-shadow
      const { term } = this.state;
      if (view[0] === 3) {
        // term.prompt();
        term.writeln(`===== Connected to device ID: ${deviceID}`);
        this.setState({ ready: true, connecting: false });
      } else if (view[0] === 4 || view[0] === 5 || view[0] === 6) {
        term.write(view.subarray(1));
      }
    });
    socket.addEventListener('close', () => {
      term.writeln(`[!] Connection to device ID: ${deviceID} lost.`);
      this.setState({ ws: null, ready: false, connecting: false });
    });
  }

  closeSocket() {
    const { ws } = this.state;
    if (ws) {
      ws.close();
      this.setState({ ws: null, ready: false });
    }
  }

  render() {
    const { ws, connecting } = this.state;
    const connected = !!ws;

    return (
      <div>
        <div className="columns">
          <div className="column">
            {!connected && <Callout intent="danger">Terminal is not connected. Device may be offline.</Callout>}
          </div>
          <div className="column text-right">
            <Button
              loading={connecting}
              intent={connected ? 'success' : 'primary'}
              disabled={connected}
              large
              onClick={this.handleConnect}
            >
              { connected ? 'Connected' : 'Connect' }
            </Button>
          </div>
        </div>
        <div ref={this.term} />
      </div>
    );
  }
}

Terminal.propTypes = {
  device: PropTypes.object,
  accessToken: PropTypes.string,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
}))(Terminal);
