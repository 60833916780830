import { requestsReducer } from 'redux-saga-requests';
import { GET_DISPLAYS, GET_DISPLAY_HOURS } from '../constants';

export const getDisplaysReducer = requestsReducer({
  actionType: GET_DISPLAYS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getDisplayHoursReducer = requestsReducer({
  actionType: GET_DISPLAY_HOURS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});
