import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Navbar, Alignment, Button, Position, Popover, Menu, Icon, Classes,
} from '@blueprintjs/core';
import { Link, Route } from 'react-router-dom';
import { NavTab as PNavTab, PermissionedPopover } from './Permissions';

const MenuItemLink = ({ icon, to, text }) => {
  const handleLinkClick = e => e.target.parentElement.parentElement.click();

  return (
    <li className={Classes.POPOVER_DISMISS}>
      <Link onClick={handleLinkClick} to={to} className="bp3-menu-item">
        <Icon icon={icon} />
        <div className="bp3-text-overflow-ellipsis bp3-fill">{text}</div>
      </Link>
    </li>
  );
};

MenuItemLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
};


const NavTab = ({
  to, exact, text, icon,
}) => (
  <Route path={to}>
    {({ match }) => (
      <Link
        className={classNames('bp3-button bp3-minimal bp3-large', {
          'bp3-active': !!match && (!exact || match.isExact),
        })}
        to={to}
      >
        <Icon icon={icon} />
        <span className="bp3-button-text">{text}</span>
      </Link>
    )}
  </Route>
);

NavTab.propTypes = {
  to: PropTypes.string,
  exact: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
};

const ORG = {
  LiveReach_Media: 1,
  LRM_Vision_Audit: 443,
};

const PERMISSION_GROUPS = {
  Organizations: [ORG.LiveReach_Media],
  Users: [ORG.LiveReach_Media],
  Devices: [ORG.LiveReach_Media],
  Nvrs: [ORG.LiveReach_Media],
  Sites: [ORG.LiveReach_Media],
  Zones: [ORG.LiveReach_Media],
  Locations: [ORG.LiveReach_Media],
  Pipelines: [ORG.LiveReach_Media],
  Audits: [ORG.LiveReach_Media, ORG.LRM_Vision_Audit],
  Dashboard: [ORG.LiveReach_Media],
  ModelTraining: [ORG.LiveReach_Media],
  Inventory: [ORG.LiveReach_Media],
  Alerts: [ORG.LiveReach_Media],
  Reid: [ORG.LiveReach_Media, ORG.LRM_Vision_Audit],
};

const Nav = ({ onLogout, name }) => (
  <div>
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <img alt="logo" src="https://dr1x7e6lehslf.cloudfront.net/lrm-icon.png" style={{ height: 20, verticalAlign: 'middle', paddingRight: 8 }} />
          Admin
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Popover content={<Menu><Menu.Item icon="log-out" onClick={onLogout} text="Logout" /></Menu>} position={Position.BOTTOM}>
          <Button minimal icon="user" rightIcon="caret-down" text={name} />
        </Popover>
      </Navbar.Group>
    </Navbar>
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <PNavTab to="/dashboard" icon="dashboard" text="Dashboard" requires={PERMISSION_GROUPS.Dashboard} />
        <PNavTab to="/organizations" icon="office" text="Organizations" requires={PERMISSION_GROUPS.Organizations} />
        <PNavTab to="/users" icon="people" text="Users" requires={PERMISSION_GROUPS.Users} />
        <PNavTab to="/devices" icon="satellite" text="Devices" requires={PERMISSION_GROUPS.Devices} />
        <PNavTab to="/nvrs" icon="desktop" text="NVRs" requires={PERMISSION_GROUPS.Nvrs} />
        <PermissionedPopover
          content={
            (
              <Menu>
                <Link to="/locations" style={{ color: '#fff' }}>
                  <Menu.Item icon="locate" text="Locations" />
                </Link>
                <Menu.Divider />
                <Link to="/sites" style={{ color: '#fff' }}>
                  <Menu.Item icon="home" text="Sites" />
                </Link>
                <Menu.Divider />
                <Link to="/zones" style={{ color: '#fff' }}>
                  <Menu.Item icon="square" text="Zones" />
                </Link>
              </Menu>
            )
          }
          position={Position.BOTTOM}
          icon="control"
          text="Inventory"
          requires={PERMISSION_GROUPS.Inventory}
        />
        <PNavTab to="/pipelines" icon="th-derived" text="Pipelines" requires={PERMISSION_GROUPS.Pipelines} />
        <Popover
          content={
            (
              <Menu>
                <MenuItemLink to="/audits/summary" text="Audit Summary" icon="comparison" />
                <Menu.Divider />
                <MenuItemLink to="/audits" text="Audit Tasks" icon="lab-test" />
                <Menu.Divider />
                <MenuItemLink to="/audits/groundtruth" text="GroundTruth Clips" icon="bring-data" />
                <Menu.Divider />
                <MenuItemLink to="/audits/waittimes" text="Wait Times" icon="bring-data" />
              </Menu>
            )
            }
          position={Position.BOTTOM}
        >
          <Button large minimal icon="video" rightIcon="caret-down" text="Audits" />
        </Popover>
        <PNavTab to="/model_training" icon="predictive-analysis" text="Trainings" requires={PERMISSION_GROUPS.ModelTraining} />
        <Popover
          content={
            (
              <Menu>
                <MenuItemLink to="/alerts/active" text="Active Alerts" icon="warning-sign" />
                <Menu.Divider />
                <MenuItemLink to="/alerts" text="All Alerts" icon="add-to-artifact" />
              </Menu>
            )
            }
          position={Position.BOTTOM}
        >
          <Button large minimal icon="issue-closed" rightIcon="caret-down" text="Alerts" />
        </Popover>
        <Popover
          content={
            (
              <Menu>
                <MenuItemLink to="/reid" text="Reid Tasks" icon="lab-test" />
                <Menu.Divider />
                <MenuItemLink to="/reid/audit" text="Reid Audit Tasks" icon="bring-data" />
              </Menu>
            )
            }
          position={Position.BOTTOM}
        >
          <Button large minimal icon="video" rightIcon="caret-down" text="REID" />
        </Popover>
      </Navbar.Group>
    </Navbar>
  </div>
);

Nav.propTypes = {
  onLogout: PropTypes.func,
  name: PropTypes.string,
};

export default Nav;
