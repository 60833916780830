import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { getRelease } from 'actions/nvr';
import CreateRelease from './create_release';

class EditRelease extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getRelease(match.params.id));
  }

  render() {
    const { release } = this.props;
    if (!release.data || !!release.pending) {
      return <Spinner size={100} />;
    }

    const initialValues = {
      ...release.data,
      nvrs: release.data.nvrs.map(x => ({ nvr: x })),
    };

    return (
      <CreateRelease
        isEdit
        title={`NVR Release v${release.data.version}`}
        initialValues={initialValues}
        releaseId={release.data.id}
      />
    );
  }
}


EditRelease.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  release: PropTypes.object,
};

export default connect(state => ({
  release: state.nvrRelease.release,
}))(EditRelease);
