import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';
import { getModels } from 'actions/audit';

import ModelList from './list';
import Model from './model';
import CreateModel from './create_model';

class Models extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getModels());
  }

  render() {
    const { models } = this.props;
    if (models.pending || !models.data.length) {
      return <Spinner size={100} />;
    }

    return (
      <Switch>
        <Route path="/devices/models/create" component={CreateModel} />
        <Route path="/devices/models/_" component={Model} />
        <Route component={ModelList} />
      </Switch>
    );
  }
}

Models.propTypes = {
  dispatch: PropTypes.func,
  models: PropTypes.object,
};

export default connect(state => ({
  models: state.models,
}))(Models);
