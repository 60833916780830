import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import {
  H1, Button, Card, FormGroup, Navbar, Tabs, Tab, Alignment, Icon,
} from '@blueprintjs/core';
import { push } from 'connected-react-router';
import {
  Route, Switch, Redirect, Link,
} from 'react-router-dom';
import { autobind } from 'core-decorators';
import { TextInput, CheckboxInput, TagInput } from 'components/inputs';
import { AppToaster } from 'components/Toaster';
import _ from 'lodash';

import { getOrganization, saveOrganization } from 'actions/organization';
import LogoInput from './logoinput';
import Roles from './roles';
import Users from './users';

class Organization extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getOrganization(match.params.id));
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch } = this.props;
    dispatch(push(tabId));
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const data = (() => {
      if (_.isObject(values.logo)) {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('slug', values.slug);
        formData.append('active', values.active);
        (values.domains || []).map(x => formData.append('domains', x));
        formData.append('theme_color', values.theme_color);
        formData.append('logo', values.logo);
        formData.append('in_good_standing', values.in_good_standing);
        formData.append('has_marketing', values.has_marketing);
        return formData;
      }
      return {
        name: values.name,
        slug: values.slug,
        active: values.active,
        domains: values.domains,
        theme_color: values.theme_color,
        logo: values.logo,
        in_good_standing: values.in_good_standing,
        has_marketing: values.has_marketing,
      };
    })();
    return dispatch(saveOrganization(match.params.id, data))
      .then(() => dispatch(getOrganization(match.params.id)))
      .then(() => AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {values.name}
            </strong>
            : Organization saved
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      organization, submitting, handleSubmit, match, logo, location,
    } = this.props;
    const orgId = parseInt(match.params.id, 10);

    return (
      <div className={classNames('container', { 'bp3-skeleton': organization.pending && (organization.data || {}).id !== orgId })}>
        <div className="columns">
          <div className="column">
            <H1>
              {(organization.data || {}).name || 'abcdefghijklm'}
            </H1>
          </div>
        </div>
        <div className="columns">
          <Navbar style={{ margin: '16px 0' }}>
            <Navbar.Group>
              <Tabs id="tabs" selectedTabId={location.pathname} onChange={this.handleTabChange} large animate={false}>
                <Tab id={`/organizations/${orgId}/info`} title="Info" />
                <Tab id={`/organizations/${orgId}/roles`} title="Roles" />
                <Tab id={`/organizations/${orgId}/users`} title="Users" />
              </Tabs>
            </Navbar.Group>
            { location.pathname === `/organizations/${orgId}/roles` && (
              <Navbar.Group align={Alignment.RIGHT}>
                <Link
                  className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                  to={`/organizations/${orgId}/roles/create`}
                >
                  <Icon icon="add" />
                </Link>
              </Navbar.Group>
            )}
          </Navbar>
        </div>
        <Card className="columns">
          <Switch>
            <Route path="/organizations/:id/info">
              <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="name"
                  label="Name"
                  type="text"
                />
                <Field
                  component={TextInput}
                  placeholder=""
                  name="slug"
                  label="Slug"
                  type="text"
                />
                <Field
                  component={TagInput}
                  placeholder="no custom domains"
                  name="domains"
                  label="Domains"
                />
                <Field
                  component={TextInput}
                  placeholder=""
                  name="theme_color"
                  label="Theme Color"
                  type="text"
                />
                <div className="columns">
                  <div className="column">
                    <FormGroup
                      label="Features"
                    >
                      <Card>
                        <Field
                          component={CheckboxInput}
                          type="checkbox"
                          id="active"
                          name="active"
                          label="Active"
                        />
                        <Field
                          component={CheckboxInput}
                          type="checkbox"
                          id="in_good_standing"
                          name="in_good_standing"
                          label="In Good Standing"
                        />
                        <Field
                          component={CheckboxInput}
                          type="checkbox"
                          id="has_marketing"
                          name="has_marketing"
                          label="Marketing Suite / Ad Server"
                        />
                      </Card>
                    </FormGroup>
                  </div>
                  <div className="column">
                    <Field
                      component={LogoInput}
                      id="logo"
                      name="logo"
                      label="Logo"
                      logo={logo}
                    />
                  </div>

                </div>
                <div className="has-text-right">
                  <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                    Save
                  </Button>
                </div>
              </form>
            </Route>
            <Route path="/organizations/:id/roles">
              <Roles />
            </Route>
            <Route path="/organizations/:id/users">
              <Users />
            </Route>
            <Route path="*">
              <Redirect to={`/organizations/${orgId}/info`} />
            </Route>
          </Switch>
        </Card>
      </div>
    );
  }
}

Organization.propTypes = {
  dispatch: PropTypes.func,
  organization: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  logo: PropTypes.string,
  location: PropTypes.object,
};

export default connect((state, props) => ({
  organization: state.organization,
  logo: state.organization.data ? state.organization.data.logo : undefined,
  initialValues: state.organization.data
    && state.organization.data.id === parseInt(props.match.params.id, 10) ? {
      name: state.organization.data.name,
      slug: state.organization.data.slug,
      active: state.organization.data.active,
      domains: state.organization.data.domains || [],
      theme_color: state.organization.data.theme_color,
      logo: state.organization.data.logo,
      in_good_standing: state.organization.data.in_good_standing,
      has_marketing: state.organization.data.has_marketing,
    } : undefined,
}))(reduxForm({
  form: 'organization',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (values.theme_color && values.theme_color.length !== 6) {
      errors.theme_color = 'Color must be a 6-character hexadecimal';
    }
    return errors;
  },
})(Organization));
