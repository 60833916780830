import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Card, Elevation, Icon,
} from '@blueprintjs/core';

import { TextInput, CheckboxInput } from './inputs';

const Login = ({ handleSubmit, submitting }) => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container has-text-left">
        <div className="columns">
          <div className="column is-one-third is-offset-one-third">
            <form onSubmit={handleSubmit}>
              <Card elevation={Elevation.FOUR}>
                <h5 className="bp3-heading"><Icon icon="briefcase" /></h5>
                <Field
                  component={TextInput}
                  placeholder=""
                  name="username"
                  type="email"
                />
                <Field
                  component={TextInput}
                  placeholder=""
                  name="password"
                  type="password"
                />
                <Field
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  label="Remember Me"
                  component={CheckboxInput}
                />
                <Button intent="success" fill loading={submitting} disabled={submitting} type="submit">
                  <Icon icon="log-in" />
                </Button>
              </Card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
);

Login.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'login',
})(Login);
