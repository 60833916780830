/* eslint-disable max-len */
import qs from 'query-string';
import {
  GET_REID_TASKS, GET_REID_TASK, CREATE_REID_TASK, UPDATE_TRACK_GROUND_TRUTH, TRACK_GROUND_TRUTH, PATCH_REID_TASK,
  GET_ML_MODELS, GET_ML_MODEL, EXTRACT_TASK, GET_EXTRACT_TASK,
  TRACK_OUTPUT, REID_OUTPUT, PATCH_TRACK_OUTPUT, REID_WAIT_TIME,
  POST_TRACK_GROUND_TRUTH, DELETE_REID_OUTPUT, CREATE_REID_MERGE_TASK,
  REID_MERGE_OUTPUT, POST_REID_MERGE_GROUND_TRUTH, EXTRACT_MERGE_TASK,
  DELETE_REID_MERGE_OUTPUT, GET_REID_TASK_CLIPS, GET_REID_CLIP_PATCHES,
  GET_REID_TASK_GROUPS, POST_REID_TASK_GROUP, DELETE_REID_TASK_GROUP, EXTRACT_ALL_PATCHES,
  GET_REID_AUDIT_TASK, GET_REID_AUDIT_TASKS, CREATE_REID_AUDIT_TASK,
  PATCH_REID_AUDIT_TASK, REID_AUDIT_SUMMARY, REID_AUDIT_TRACKS, REID_AUDIT_MATRIX,
  EXTRACT_REID_AUDIT_TASK, DELETE_REID_AUDIT_OUTPUT, COMPARE_REID_AUDIT_TASKS,
} from '../constants';

export const getReidTasks = (query, from, limit) => ({
  type: GET_REID_TASKS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks?${qs.stringify({
        query, from, limit,
      })}`,
    },
  },
});

export const getReidTask = id => ({
  type: GET_REID_TASK,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${id}`,
    },
  },
});

export const getReidTaskOutput = id => ({
  type: REID_OUTPUT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${id}/output`,
    },
  },
});

export const getReidMergeTaskOutput = id => ({
  type: REID_MERGE_OUTPUT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${id}/merged/patches?limit=1000`,
    },
  },
});

export const deleteReidTaskOutput = id => ({
  type: DELETE_REID_OUTPUT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/reid/tasks/${id}/output`,
    },
  },
});

export const getReidTaskWaitTimes = id => ({
  type: REID_WAIT_TIME,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/${id}/ground_truth/wait_times`,
    },
  },
});

export const getReidTrackOutput = (id, clip_name, track_id) => ({
  type: TRACK_OUTPUT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${id}/output/clips/${clip_name}/tracks/${track_id}`,
    },
  },
});

export const getReidTrackGroundTruth = (id, clip_name, track_id) => ({
  type: TRACK_GROUND_TRUTH,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/${id}/clips/${clip_name}/tracks/${track_id}/ground_truth`,
    },
  },
});

export const updateReidTrackGroundTruth = (id, clip_name, track_id, data) => ({
  type: UPDATE_TRACK_GROUND_TRUTH,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/reid/${id}/clips/${clip_name}/tracks/${track_id}/ground_truth`,
    },
  },
});

export const postReidTrackGroundTruth = (id, clip_name, track_id, data) => ({
  type: POST_TRACK_GROUND_TRUTH,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/reid/${id}/clips/${clip_name}/tracks/${track_id}/ground_truth`,
    },
  },
});

export const postMergeReidTaskGroundTruth = (id, data) => ({
  type: POST_REID_MERGE_GROUND_TRUTH,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/reid/tasks/${id}/merged/patches/ground_truth`,
    },
  },
});

export const patchReidTrackOutput = (id, clip_name, track_id, data) => ({
  type: PATCH_TRACK_OUTPUT,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/reid/tasks/${id}/output/clips/${clip_name}/tracks/${track_id}`,
    },
  },
});

export const getModel = id => ({
  type: GET_ML_MODEL,
  payload: {
    request: {
      method: 'get',
      url: `/v1/ml/models/${id}`,
    },
  },
});

export const getModels = () => ({
  type: GET_ML_MODELS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/ml/models',
    },
  },
});

export const createReidTask = data => ({
  type: CREATE_REID_TASK,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/reid/tasks',
    },
  },
});

export const createReidMergeTask = data => ({
  type: CREATE_REID_MERGE_TASK,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/reid/merge_tasks',
    },
  },
});

export const patchReidTask = (task_id, data) => ({
  type: PATCH_REID_TASK,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/reid/tasks/${task_id}`,
      data,
    },
  },
});

export const extractPbuf = (task_id, threshold, entryexit) => ({
  type: EXTRACT_TASK,
  payload: {
    request: {
      method: 'post',
      url: `/v1/reid/tasks/${task_id}/extract?threshold=${threshold}&entryexit=${entryexit}`,
    },
  },
});

export const extractAllPatches = task_id => ({
  type: EXTRACT_ALL_PATCHES,
  payload: {
    request: {
      method: 'post',
      url: `/v1/reid/tasks/${task_id}/extractall`,
    },
  },
});

export const extractMergePbuf = (task_id, threshold) => ({
  type: EXTRACT_MERGE_TASK,
  payload: {
    request: {
      method: 'post',
      url: `/v1/reid/tasks/${task_id}/merge/extract?threshold=${threshold}`,
    },
  },
});

export const getPbufExtract = (task_id, threshold) => ({
  type: GET_EXTRACT_TASK,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${task_id}/extract?threshold=${threshold}`,
    },
  },
});

export const deleteReidMergeOutput = id => ({
  type: DELETE_REID_MERGE_OUTPUT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/reid/tasks/${id}/merged/output`,
    },
  },
});

export const getClipsPerTask = task_id => ({
  type: GET_REID_TASK_CLIPS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${task_id}/allclips`,
    },
  },
});

export const getPatchesPerClip = (task_id, clip_name) => ({
  type: GET_REID_CLIP_PATCHES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${task_id}/clips/${clip_name}/allpatches`,
    },
  },
});

export const getReidTaskGroups = task_id => ({
  type: GET_REID_TASK_GROUPS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/tasks/${task_id}/groups`,
    },
  },
});

export const postReidTaskGroup = (id, data) => ({
  type: POST_REID_TASK_GROUP,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/reid/tasks/${id}/output`,
    },
  },
});

export const deleteReidTaskGroup = (id, group_id) => ({
  type: DELETE_REID_TASK_GROUP,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/reid/tasks/${id}/groups/${group_id}`,
    },
  },
});

export const getReidAuditTasks = (query, from, limit) => ({
  type: GET_REID_AUDIT_TASKS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks?${qs.stringify({
        query, from, limit,
      })}`,
    },
  },
});

export const getReidAuditTask = id => ({
  type: GET_REID_AUDIT_TASK,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks/${id}`,
    },
  },
});

export const createReidAuditTask = data => ({
  type: CREATE_REID_AUDIT_TASK,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/reid/audit/tasks',
    },
  },
});

export const patchReidAuditTask = (task_id, data) => ({
  type: PATCH_REID_AUDIT_TASK,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/reid/audit/tasks/${task_id}`,
      data,
    },
  },
});

export const getReidAuditTaskSummary = id => ({
  type: REID_AUDIT_SUMMARY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks/${id}/summary`,
    },
  },
});

export const getReidAuditTaskTracks = (id, thresh, metric, startIndex, endIndex) => ({
  type: REID_AUDIT_TRACKS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks/${id}/aggregate?threshold=${thresh}&metric=${metric}&startIndex=${startIndex}&endIndex=${endIndex}`,
    },
  },
});

export const getReidAuditTaskMatrix = (id, trackParam, gtID, threshold, metric, region) => ({
  type: REID_AUDIT_MATRIX,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks/${id}/confusion_matrix?${trackParam}&gtID=${gtID}&threshold=${threshold}&metric=${metric}&region=${region}`,
    },
  },
});

export const extractReidAuditTask = id => ({
  type: EXTRACT_REID_AUDIT_TASK,
  payload: {
    request: {
      method: 'post',
      url: `/v1/reid/audit/tasks/${id}/extractall`,
    },
  },
});

export const deleteReidAuditTaskOutput = id => ({
  type: DELETE_REID_AUDIT_OUTPUT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/reid/audit/tasks/${id}/output`,
    },
  },
});

export const compareReidAudit = ids => ({
  type: COMPARE_REID_AUDIT_TASKS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/reid/audit/tasks/compare?audit_ids=${ids}`,
    },
  },
});
