import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  TextInput, FormSelectInput, CheckboxInput,
} from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { getDevice, saveDevice, patchOrgDevices } from 'actions/device';

class DeviceInfo extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, device } = this.props;
    const deviceData = {
      device_name: values.name,
      monitored: values.monitored === 'true',
    };
    const orgDeviceData = {
      device_name: values.name,
    };

    if (device.type.includes('camera')) {
      deviceData.in_maintenance = values.in_maintenance;
      orgDeviceData.in_maintenance = values.in_maintenance;
    }

    return dispatch(saveDevice(device.id, deviceData)).then(() => {
      dispatch(patchOrgDevices(
        device.organization_id,
        device.organization_device_id,
        orgDeviceData,
      ));
    }).then(() => dispatch(getDevice(device.id)))
      .then(() => AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {values.name}
            </strong>
            : Config saved
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      submitting, handleSubmit, device, enableNameChange,
    } = this.props;

    return (
      <div className="columns">
        <form className="column" onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={TextInput}
            placeholder=""
            name="name"
            label="Device Name"
            type="text"
            disabled={!enableNameChange}
          />
          {['livereachmedia.iap', 'Raspberry PI', 'axis.camera', 'amcrest.camera'].includes(device.type) && (
            <Field
              component={FormSelectInput}
              placeholder=""
              name="monitored"
              label="Monitored with PagerDuty"
            >
              <option value="true">Monitored</option>
              <option value="false">Unmonitored</option>
            </Field>
          )}
          {device.type.includes('camera') && (
            <Field
              component={CheckboxInput}
              name="in_maintenance"
              label="In Maintenance"
            />
          )}
          <div className="has-text-right">
            <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

DeviceInfo.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
  enableNameChange: PropTypes.bool,
};

export default reduxForm({
  form: 'device-info',
  enableReinitialize: true,
})(DeviceInfo);
