import qs from 'query-string';
import {
  GET_AUDITS, GET_AUDIT, CREATE_AUDIT, GET_AUDIT_SUMMARY,
  GET_AUDIT_OVERVIEW, DELETE_AUDIT, UPDATE_GROUND_TRUTH,
  GET_ML_MODELS, PATCH_AUDIT, COMPARE_AUDIT, COMPARE_AUDIT_SUMMARY,
  UPDATE_AUDIT_TAGS, GET_AUDIT_CLIP, GET_AUDIT_CLIPS, CREATE_TAG,
  DELETE_TAG, GET_CLIP_TAGS, UPDATE_ML_MODEL, GET_ML_MODEL, CREATE_ML_MODEL, DELETE_ML_MODEL,
  GET_ASSIGNED_CLIPS, GET_ASSIGNED_CLIPS_ORG, GET_CLIPS_SUMMARY, GET_RAW_AUDIT_CLIP,
  ADD_WAIT_TIME, GET_WAIT_TIMES,
} from '../constants';

export const getAudits = (query, from, limit, id, clip) => ({
  type: GET_AUDITS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks?${qs.stringify({
        query, from, limit, id, clip,
      })}`,
    },
  },
});

export const getAudit = id => ({
  type: GET_AUDIT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks/${id}/report`,
    },
  },
});

export const createAudit = data => ({
  type: CREATE_AUDIT,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/audit/tasks/',
    },
  },
});

export const getAuditSummary = id => ({
  type: GET_AUDIT_SUMMARY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks/${id}/summary_report`,
    },
  },
});

export const getAuditOverview = id => ({
  type: GET_AUDIT_OVERVIEW,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks/${id}`,
    },
  },
});

export const deleteAudit = audit_id => ({
  type: DELETE_AUDIT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/audit/tasks/${audit_id}`,
    },
  },
});

export const updateGroundTruth = (clipName, cameraSerial, data) => ({
  type: UPDATE_GROUND_TRUTH,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/audit/groundtruth/${cameraSerial}/${clipName}`,
    },
  },
});

export const getModels = () => ({
  type: GET_ML_MODELS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/ml/models',
    },
  },
});

export const patchAudit = (auditId, data) => ({
  type: PATCH_AUDIT,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/audit/tasks/${auditId}`,
      data,
    },
  },
});

export const compareAudit = (name, auditId) => ({
  type: COMPARE_AUDIT,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks/${auditId}/report`,
    },
  },
});

export const compareAuditSummary = (name, auditId) => ({
  type: COMPARE_AUDIT_SUMMARY,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/tasks/${auditId}/summary_report`,
    },
  },
});

export const patchAuditTags = (id, clip, data) => ({
  type: UPDATE_AUDIT_TAGS,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/audit/tasks/${id}/output/${clip}`,
      data,
    },
  },
});

export const auditClips = (uploaded, groundtruth, auditID, limit, from, assigned) => ({
  type: GET_AUDIT_CLIPS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/clips?${qs.stringify({
        uploaded,
        groundtruth,
        audit: auditID,
        limit,
        from,
        assignee: assigned,
      })}`,
    },
  },
});

export const auditClip = (clipId, auditID, lineId) => ({
  type: GET_AUDIT_CLIP,
  payload: {
    request: {
      method: 'get',
      url: (auditID || lineId) ? `/v1/audit/clips/${clipId}?${qs.stringify({
        audit: auditID,
        line_id: lineId,
      })}` : `/v1/audit/clips/${clipId}`,
    },
  },
});

export const createTag = (auditID, clip, data) => ({
  type: CREATE_TAG,
  payload: {
    request: {
      method: 'post',
      url: `/v1/audit/${auditID}/clips/tags/${clip}`,
      data,
    },
  },
});

export const deleteTag = (auditID, tagID) => ({
  type: DELETE_TAG,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/audit/${auditID}/clips/tags/${tagID}`,
    },
  },
});

export const getClipTags = (auditID, clip) => ({
  type: GET_CLIP_TAGS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/${auditID}/clips/tags/${clip}`,
    },
  },
});

export const updateModel = (id, data) => ({
  type: UPDATE_ML_MODEL,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/ml/models/${id}`,
      data,
    },
  },
});

export const getModel = id => ({
  type: GET_ML_MODEL,
  payload: {
    request: {
      method: 'get',
      url: `/v1/ml/models/${id}`,
    },
  },
});

export const createModel = data => ({
  type: CREATE_ML_MODEL,
  payload: {
    request: {
      method: 'post',
      url: '/v1/ml/models',
      data,
    },
  },
});

export const deleteModel = id => ({
  type: DELETE_ML_MODEL,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/ml/models/${id}`,
    },
  },
});

export const getAssignedClips = (s, e, g, a) => ({
  type: GET_ASSIGNED_CLIPS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/groundtruth/assigned?startTime=${s}&endTime=${e}&groundtruth=${g}&assignee=${a}`,
    },
  },
});

export const getAssignedClipsByOrg = (s, e, g, o) => ({
  type: GET_ASSIGNED_CLIPS_ORG,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/groundtruth/assigned?startTime=${s}&endTime=${e}&groundtruth=${g}&orgId=${o}`,
    },
  },
});

export const getClipsSummary = (s, e, o) => ({
  type: GET_CLIPS_SUMMARY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/groundtruth/assigned/summary?startTime=${s}&endTime=${e}&orgId=${o}`,
    },
  },
});

export const getRawAuditClip = clipId => ({
  type: GET_RAW_AUDIT_CLIP,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/clips/raw/${clipId}`,
    },
  },
});

export const addWaitTime = data => ({
  type: ADD_WAIT_TIME,
  payload: {
    request: {
      method: 'post',
      url: '/v1/audit/waittime/track',
      data,
    },
  },
});

export const getWaitTimes = zone_id => ({
  type: GET_WAIT_TIMES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/audit/waittime/track',
      zone_id,
    },
  },
});
