import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LazyLog } from 'react-lazylog';
import axios from 'axios';
import qs from 'query-string';

class Logs extends Component {
  renderURL() {
    const {
      accessToken, device_id, start, end,
    } = this.props;
    const vars = {
      access_token: accessToken,
      start,
      end,
      raw: 'true',
    };

    return `${axios.defaults.baseURL}/v1/ml/models/training/resources/${device_id}/logs?${qs.stringify(vars)}`;
  }

  render() {
    return (
      <div>
        <LazyLog
          url={this.renderURL()}
          height={750}
          extraLines={1}
          follow
          enableSearch
          selectableLines
        />
      </div>
    );
  }
}

Logs.propTypes = {
  accessToken: PropTypes.string,
  device_id: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
}))(Logs);
