import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, Icon, FormGroup, InputGroup, Spinner, Tooltip, Button, Checkbox,
  MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
  getReidAuditTasks,
  patchReidAuditTask,
} from 'actions/reid';
import { AppToaster } from 'components/Toaster';
import { replace } from 'connected-react-router';
import qs from 'query-string';
import ReactPaginate from 'react-paginate-bp3';

const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

class ReidAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      path: '',
      model: '',
      page: 0,
      checked: [],
    };
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getReidAuditTasks(null, 0, 100));
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({ page: selected }, this.doRefresh);
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const {
      page, query,
    } = this.state;
    // serializing json into url
    dispatch(replace(`/reid/audit?${qs.stringify(_.pickBy({
      p: page,
      q: query,
    }))}`));
    dispatch(getReidAuditTasks(
      query,
      page * 100,
      100,
    ));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value }, this.doRefresh);
  }

  @autobind
  handleInputPath(item) {
    this.setState({ path: item.path });
  }

  @autobind
  handleModel(item) {
    this.setState({ model: item.model });
  }

  @autobind
  handleModelPredicate(query, item) {
    const q = query.toLowerCase();
    return item.model.toLowerCase().includes(q);
  }

  @autobind
  handlePathPredicate(query, item) {
    const q = query.toLowerCase();
    return item.path.toLowerCase().includes(q);
  }

  @autobind
  clearModel() {
    this.setState({ model: '' }, this.doRefresh);
  }

  @autobind
  clearPath() {
    this.setState({ path: '' }, this.doRefresh);
  }

  @autobind
  clearCompare() {
    this.setState({ checked: [] });
  }

  @autobind
  selectReidTask(e, id) {
    const { reidAuditTasks } = this.props;
    const { checked } = this.state;
    if (!checked.length) {
      return this.setState({ checked: [id] });
    }
    if (!e.target.checked) {
      return this.setState({ checked: [...checked].filter(x => x !== id) });
    }
    // const { s3_input_path, total } = (tasks.data.find(x => x.task_id === checked[0]) || {});
    const selectedTask = reidAuditTasks.data.find(x => x.task_id === id);
    if (selectedTask.completed) {
      const newChecked = [...checked, id];
      return this.setState({ checked: newChecked });
    }
    return AppToaster.show({
      intent: 'danger',
      message: <span>Task not completed, cannot be merged</span>,
      icon: 'cross',
    });
  }

  @autobind
  pauseReidTask(id, name) {
    const { dispatch } = this.props;
    return dispatch(patchReidAuditTask(id, { task_status: 4 }))
      .then(() => dispatch(getReidAuditTasks())
      && AppToaster.show({
        icon: 'pause',
        message: <span>{`${name} Paused`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  resumeReidTask(id, name) {
    const { dispatch } = this.props;
    return dispatch(patchReidAuditTask(id, { task_status: 0 }))
      .then(() => dispatch(getReidAuditTasks())
      && AppToaster.show({
        icon: 'play',
        message: <span>{`${name} Resumed`}</span>,
        intent: 'success',
      }));
  }

  @autobind
  applyFilters(a) {
    const {
      model_id, title, task_id,
    } = a;
    const { query, path, model } = this.state;
    const modelCheck = model_id === model;
    const queryCheck = (title.toLowerCase().includes(query.toLowerCase())
      || task_id.toString().includes(query));
    if (path && model) {
      return modelCheck && queryCheck;
    }
    if (path) {
      return queryCheck;
    }
    if (model) {
      return queryCheck && modelCheck;
    }
    return queryCheck;
  }

  @autobind
  renderModelItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent={undefined}
        onClick={handleClick}
        text={item.model}
      />
    );
  }

  @autobind
  renderPathItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent={undefined}
        onClick={handleClick}
        text={item.path}
      />
    );
  }

  @autobind
  renderTask(task) {
    const {
      // eslint-disable-next-line max-len
      completed, total, task_id, title, StartTime, model_id, task_status, EndTime, task_ids,
    } = task;
    const failed = task_status === 0 && EndTime !== null;
    const { checked } = this.state;
    return (
      <tr key={task_id}>
        <td style={{ textAlign: 'center' }}>
          <Checkbox
            checked={checked.includes(task_id)}
            onChange={e => this.selectReidTask(e, task_id)}
          />
        </td>
        <td><Link to={`/reid/audit/${task_id}`}>{task_id}</Link></td>
        <td><Link to={`/reid/audit/${task_id}`}>{title}</Link></td>
        <td>{model_id}</td>
        <td>{failed ? 'Failed' : STATUS_MAP[task_status]}</td>
        <td>{task_ids}</td>
        <td>{`${completed}/${total}`}</td>
        <td>{DateTime.fromISO(StartTime).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td style={{ textAlign: 'center' }}>
          {task_status !== 1 && !failed && (
            <Tooltip content={task_status === 4 ? 'Resume Task' : 'Pause Task'}>
              <Icon
                iconSize={20}
                className="pause-audit"
                icon={task_status === 4 ? 'play' : 'pause'}
                onClick={() => (task_status === 4
                  ? this.resumeReidTask(task_id, title)
                  : this.pauseReidTask(task_id, title))}
              />
            </Tooltip>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const { reidAuditTasks } = this.props;
    const {
      query, model, checked, page,
    } = this.state;
    const spinner = reidAuditTasks.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;
    // eslint-disable-next-line max-len
    const selectedTasks = reidAuditTasks.data ? reidAuditTasks.data.filter(x1 => checked.some(x2 => (x1.task_id === x2))) : [];
    // const selectedTask = selectedTasks[0];
    const refireValues = selectedTasks
      ? (selectedTasks || []).map(({ task_ids: ti, ...rest }) => ({
        ...rest,
        task_ids: ti ? ti.split(',').map(x => ({ id: x })) : [],
      })) : {};

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Reid Audit Tasks
                  <div className="is-pulled-right">
                    { !(refireValues.length > 1) && (
                      <Link
                        className="bp3-button bp3-large bp3-intent-primary"
                        to={{
                          pathname: '/reid/audit/create',
                          state: { initialValues: refireValues[0] },
                        }}
                        disabled={(refireValues.length > 1)}
                      >
                        <Icon icon="add" />
                      </Link>
                    )}
                    { (checked.length > 1) && (
                      <Link
                        className="bp3-button bp3-intent-primary"
                        style={{ padding: '0px 5px 0px 5px', marginLeft: 10 }}
                        to={{
                          pathname: `/reid/audit/compare?audit_ids=${checked.join(',')}`,
                        }}
                      >
                        <Icon icon="comparison" />
                        <span className="bp3-button-text" style={{ fontWeight: 'lighter' }}>Compare</span>
                      </Link>
                    )}
                  </div>
                </H1>
              </div>
            </div>
          </div>
        </div>
        <FormGroup>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
        </FormGroup>
        <div className="columns">
          <div className="column">
            <FormGroup className="inline-flex m-r-sm" label="Models">
              <div style={{ display: 'flex' }}>
                <Select
                  resetOnSelect
                  filterable
                  itemPredicate={this.handleModelPredicate}
                  itemRenderer={this.renderModelItem}
                  popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                  items={_.uniqBy((reidAuditTasks.data || []), 'model_id')
                    .map((y, i) => ({ model: y.model_id, id: i + 1 })) || []}
                  onItemSelect={this.handleModel}
                  activeItem={model}
                >
                  <Button text={model || 'All Models'} rightIcon="caret-down" />
                </Select>
                {!!model && <Button intent="danger" outlined onClick={this.clearModel} style={{ marginLeft: 5 }} icon="cross" />}
              </div>
            </FormGroup>
          </div>
        </div>
        <div>
          <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
            <ReactPaginate
              previousLabel={<Icon icon="chevron-left" />}
              nextLabel={<Icon icon="chevron-right" />}
              breakLabel="..."
              pageCount={Math.ceil(((reidAuditTasks.data.meta || {}).total || 0) / 100)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              forcePage={page}
              onPageChange={this.handlePageClick}
              containerClassName="bp3-button-group"
              pageClassName="bp3-button"
              previousClassName="bp3-button"
              nextClassName="bp3-button"
              disabledClassName="bp3-disabled"
              breakClassName="bp3-button bp3-disabled"
              activeClassName="bp3-active"
            />
          </div>
          <div>
            {!!((reidAuditTasks.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>
                      {!!checked.length && (
                        <Button outlined small onClick={this.clearCompare} icon="cross" intent="danger" />
                      )}
                    </th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Model</th>
                    <th>Status</th>
                    <th>Task Ids</th>
                    <th>Done/Total</th>
                    <th>Start Time</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {([...reidAuditTasks.data] || [])
                    .filter(this.applyFilters)
                    .map(this.renderTask)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ReidAudit.propTypes = {
  dispatch: PropTypes.func,
  reidAuditTasks: PropTypes.object,
};

export default connect(state => ({
  reidAuditTasks: state.reidAuditTasks,
}))(ReidAudit);
