import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Icon, H3, Tag, H4,
} from '@blueprintjs/core';

const DAYS = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

class RecordSchedules extends Component {
  @autobind
  renderSchedule(s, i) {
    return (
      <tr key={i}>
        <td>
          <H4>{DAYS[s.day]}</H4>
        </td>
        <td>
          <Tag style={{ color: '#fff' }} minimal large intent="success">{s.open_time}</Tag>
        </td>
        <td>
          <Tag style={{ color: '#fff' }} minimal large intent="danger">{s.close_time}</Tag>
        </td>
      </tr>
    );
  }

  render() {
    const { schedules, serial } = this.props;
    return (
      <div className="columns" style={{ marginBottom: 20 }}>
        <div className="column">
          <H3>{`Active Schedules: ${serial}`}</H3>
          <table className={classNames('bp3-html-table bp3-interactive bp3-html-table-striped bp3-html-table-bordered', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th>
                  <Icon icon="timeline-events" />
                  &nbsp;
                  Day
                </th>
                <th>
                  <Icon icon="time" />
                  &nbsp;
                  Start Time (UTC)
                </th>
                <th>
                  <Icon icon="time" />
                  &nbsp;
                  End Time (UTC)
                </th>
              </tr>
            </thead>
            <tbody>
              {(schedules || []).map(this.renderSchedule)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

RecordSchedules.propTypes = {
  schedules: PropTypes.array,
  serial: PropTypes.string,
};

export default RecordSchedules;
