import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import {
  Tab, Tabs, H1, Icon, Navbar, Spinner,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { getSite, getSiteDevices, getZone } from 'actions/site';

import OperatingHours from '../EditInventory/operatinghours';
import AdvancedMetrics from '../EditInventory/advancedmetrics';
import GroundTruth from '../EditInventory/groundtruth';
import SiteDevices from './devices';
import CameraStats from './CameraStats';
import Upload from './upload';

const defaultTime = new Date();
defaultTime.setHours(0);
defaultTime.setMinutes(0);
defaultTime.setSeconds(0);
defaultTime.setMilliseconds(0);

class Site extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    dispatch(getSite(id));
    dispatch(getSiteDevices(id));
    dispatch(getZone(id));
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch, location } = this.props;
    dispatch(push(tabId + (location.search || '')));
  }

  render() {
    const {
      site, match, siteDevices, location, zone,
    } = this.props;
    const siteId = parseInt(match.params.id, 10);
    const created = DateTime.fromISO((site.data || {}).created)
      .toLocaleString(DateTime.DATETIME_MED);

    if (!site.data || !zone.data) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    if ((site.data || {}).id !== siteId) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    const currentSite = (site || {}).data;
    const defaultZone = (zone || {}).data;
    const isSiteActive = (((currentSite || {}).status || {}).devices || {}).up > 0;
    const hasCameras = (siteDevices.data || []).some(x => x.device.type.includes('camera'));

    return (
      <div className="container">
        <div className={classNames('columns', { 'bp3-skeleton': site.pending && currentSite.id !== siteId })}>
          <H1>
            {currentSite.name}
            <div className="bp3-text-small bp3-text-muted">
              ID:&nbsp;
              {currentSite.id}
              &nbsp;
              Created:&nbsp;
              {created}
            </div>
          </H1>
          <span style={{ margin: '15px 0 0 5px' }}>
            <Icon icon="symbol-circle" intent={isSiteActive ? 'success' : 'danger'} />
          </span>
        </div>
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-location" onChange={this.handleTabChange} selectedTabId={location.pathname} large>
              <Tab id={`/sites/${currentSite.id}`} title="Info" />
              <Tab id={`/sites/${currentSite.id}/op_hours`} title="Operating Hours" />
              <Tab id={`/sites/${currentSite.id}/groundtruth`} title="Groundtruth" />
              <Tab id={`/sites/${currentSite.id}/devices`} title="Devices" />
              {hasCameras && <Tab id={`/sites/${currentSite.id}/cameras`} title="Cameras Stats" />}
              {hasCameras && <Tab id={`/sites/${currentSite.id}/upload`} title="Upload Clips" />}
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route path="/sites/:id/op_hours">
                <OperatingHours match={match} />
              </Route>
              <Route path="/sites/:id/groundtruth">
                <GroundTruth match={match} />
              </Route>
              <Route path="/sites/:id/devices">
                <SiteDevices devices={siteDevices} />
              </Route>
              <Route path="/sites/:id/cameras">
                <CameraStats cameras={(siteDevices.data || []).filter(x => x.device.type.includes('camera'))} />
              </Route>
              <Route path="/sites/:id/upload">
                <Upload
                  // devices={
                  //   (siteDevices.data || []).filter(x => x.device.type.includes('iap'))
                  //     .map(x => x.device.device_identifier)
                  // }
                  serials={
                    (siteDevices.data || []).filter(x => x.device.type.includes('camera'))
                  }
                  recordingDevices={
                    // eslint-disable-next-line max-len
                    Object.assign({}, ...(siteDevices.data || []).map(s => ({ [s.device.device_identifier]: s.status.recording_device })))
                  }
                  initialValues={{
                    start_time: defaultTime,
                    end_time: defaultTime,
                  }}
                />
              </Route>
              <Route>
                <AdvancedMetrics
                  isSite
                  match={match}
                  initialValues={{
                    waittime: defaultZone.is_one_way_queuing,
                    passerby: defaultZone.passerby_type,
                  }}
                  site={currentSite}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Site.propTypes = {
  site: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  siteDevices: PropTypes.object,
  zone: PropTypes.object,
  location: PropTypes.object,
};

export default connect(state => ({
  site: state.site,
  siteDevices: state.siteDevices,
  zone: state.zone,
}))(Site);
