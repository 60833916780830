import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H1, FormGroup, InputGroup, NonIdealState, Icon, Spinner, Tag,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';

class ModelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderModel(model, i) {
    const {
      id, created, model_type, model_alias,
    } = model;
    return (
      <tr key={i}>
        <td>
          <Link to={`/devices/models/_?id=${id}`}>{model_alias}</Link>
        </td>
        <td>
          <Link to={`/devices/models/_?id=${id}`}>{id}</Link>
        </td>
        <td style={{ textAlign: 'center' }}><Tag intent="success">{model_type}</Tag></td>
        <td>{DateTime.fromISO(created).toLocaleString(DateTime.DATETIME_MED)}</td>
      </tr>
    );
  }

  render() {
    const { models } = this.props;
    const { query } = this.state;

    if (models.pending && !(models.data || []).length) {
      return <Spinner size={100} />;
    }

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Vision Models
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/devices/models/create"
                  >
                    <Icon icon="add" />
                  </Link>
                </H1>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <FormGroup>
              <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" />
            </FormGroup>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!models.data.length && <NonIdealState icon="search" title="No Models" />}
            {!!models.data.length && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>Alias</th>
                    <th>Model ID</th>
                    <th style={{ textAlign: 'center' }}>Model Type</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  {([...models.data] || [])
                    .sort((a, b) => b.created.localeCompare(a.created))
                    .filter((f) => {
                      const q = query.toLowerCase();
                      const { id = '', model_alias = '' } = f;
                      return id.includes(q) || model_alias.includes(q);
                    })
                    .map(this.renderModel)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ModelList.propTypes = {
  models: PropTypes.object,
};

export default connect(state => ({
  models: state.models,
}))(ModelList);
