import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { Spinner, NonIdealState } from '@blueprintjs/core';

import { getModel } from 'actions/audit';
import CreateModel from './create_model';

class Model extends Component {
  componentDidMount() {
    const { dispatch, location } = this.props;
    const { id } = qs.parse(location.search, { ignoreQueryPrefix: true });
    dispatch(getModel(id));
  }

  render() {
    const { model } = this.props;
    if (model.pending && !model.data) {
      return <Spinner size={100} />;
    }

    if (!model.pending && !model.data) {
      return <NonIdealState icon="search" title="No model found." />;
    }

    const { model_alias, id } = model.data || {};

    return (
      <CreateModel
        isEdit
        title={model_alias || id}
        initialValues={{ ...model.data }}
        modelId={id}
      />
    );
  }
}

Model.propTypes = {
  dispatch: PropTypes.func,
  model: PropTypes.object,
  location: PropTypes.object,
};

export default connect(state => ({
  model: state.model,
}))(Model);
