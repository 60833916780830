import React, { useEffect } from 'react';
import { getUsers } from 'actions/user';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

const Users = ({ dispatch, users, organization }) => {
  useEffect(() => {
    const orgId = (organization.data || {}).id;
    dispatch(getUsers(null, null, orgId, null, null));
  }, [organization, dispatch]);
  return (
    <React.Fragment>
      <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.data.length > 0 && users.data.map(user => (
            <tr key={user.id}>
              <td><Link to={`/users/${user.id}`}>{user.name}</Link></td>
              <td>{user.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

Users.propTypes = {
  dispatch: PropTypes.func,
  users: PropTypes.object,
  organization: PropTypes.object,
};

export default connect(state => ({
  users: state.users,
  organization: state.organization,
}))(Users);
