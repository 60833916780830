import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  Elevation, Tag, H4, Card, NonIdealState, Icon, Spinner,
} from '@blueprintjs/core';
import {
  cameraStatsFps, cameraStatsResolution,
  cameraStatsByLine,
} from 'actions/cameraStats';

import Line from '../../Devices/Line';

const fmtr = x => numbro(x).format('0,0');
const Dot = () => <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>;

class CameraStatCard extends Component {
  componentDidMount() {
    this.getCameraStats();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      this.getCameraStats();
    }
  }

  @autobind
  getCameraStats() {
    const {
      dispatch, id, region, startDate, endDate, name,
    } = this.props;
    const start = startDate.startOf('second').toISO({ suppressMilliseconds: true });
    const end = endDate.startOf('second').toISO({ suppressMilliseconds: true });
    dispatch(cameraStatsByLine(
      `ins-${name}`,
      id,
      start,
      end,
      region,
      'minute',
      'throughput_in,throughput_out',
      'people',
    ));
    dispatch(cameraStatsFps(
      `fps-${name}`,
      `SELECT mean(fps) as fps FROM vision WHERE (camera_serial = '${id}') AND time >= '${start}' AND time < '${end}' GROUP BY time(1m), vision fill(null);`,
    ));
    dispatch(cameraStatsResolution(
      `resolution-${name}`,
      `SELECT last(width) AS width, last(height) as height FROM vision WHERE (camera_serial = '${id}') AND time >= now() - 30m;`,
    ));
  }

  @autobind
  renderCameraData(result) {
    const xFmt = x => moment(x[0]).toDate();
    const labels = result.length ? result.map(xFmt) : [];
    const datasets = [
      {
        label: 'In',
        data: result ? result.map(x => x[1]) : [],
        borderColor: '#CFF3D2',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#CFF3D2',
        pointRadius: 0,
        lineTension: 0,
      },
      {
        label: 'Out',
        data: result ? result.map(x => x[2]) : [],
        borderColor: '#DB8A3A',
        borderWidth: 2,
        fill: true,
        pointBackgroundColor: '#DB8A3A',
        pointRadius: 0,
        lineTension: 0,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderInsAndOuts(data) {
    if (!(data || {})) {
      return (
        <Card>
          <NonIdealState icon="warning-sign" title="No Ins and Outs" className="non-ideal-device-card" />
        </Card>
      );
    }
    const chartData = data ? [...data]
      .sort((a, b) => a[0].localeCompare(b[0])) : [];
    const totalIn = chartData ? _.sum(chartData.map(x => x[1])) : 0;
    const totalOut = chartData ? _.sum(chartData.map(x => x[2])) : 0;
    return (
      <Fragment>
        <H4 className="is-marginless column">
          <span>Ins:</span>
          <span style={{ marginLeft: 5, color: '#CFF3D2' }}>
            {numbro(totalIn).format('0,0')}
          </span>
          <Dot />
          <span>Outs:</span>
          <span style={{ marginLeft: 5, color: '#DB8A3A' }}>
            {numbro(totalOut).format('0,0')}
          </span>
        </H4>
        <div style={{ height: 300 }}>
          <Line
            yFmtr={fmtr}
            data={() => this.renderCameraData(chartData)}
            title="Camera Ins and Outs"
            legend
            position="top"
          />
        </div>
      </Fragment>
    );
  }

  @autobind
  renderFpsData(data) {
    const orderedData = data && data.length ? data.reverse() : [];
    const xFmt = x => moment(x).toDate();
    const times = orderedData && orderedData.length ? orderedData.map(x => x[0]) : [];
    const labels = times ? times.map(xFmt) : [];
    const datasets = [{
      label: 'FPS',
      data: data ? data.map(x => x[1]) : [],
      fill: true,
      borderColor: '#CFF3D2',
      pointBackgroundColor: '#CFF3D2',
      pointRadius: 0,
      lineTension: 0,
      xTimeUnit: 'minute',
    }];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderFps() {
    const { fps } = this.props;
    if (fps.failed || fps.error) {
      return (
        <Card>
          <NonIdealState icon="warning-sign" title="Failed to retrieve Ins and Outs" className="non-ideal-device-card" />
        </Card>
      );
    }
    const chartData = fps && fps.response && fps.response[0]
      && fps.response[0].series && fps.response[0].series[0] && fps.response[0].series[0].values
      ? [...fps.response[0].series[0].values].filter(x => x[1] !== null)
      : [];

    return (
      <Fragment>
        <H4 className="is-marginless column">FPS</H4>
        <div style={{ height: 300 }}>
          <Line
            yFmtr={fmtr}
            data={() => this.renderFpsData(chartData)}
            title="FPS"
            legend
            position="top"
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      name, resolution, id, stats,
    } = this.props;
    const res = (resolution.response || [])[0] || {};
    const series = (res.series || [])[0] || {};
    const dimensions = (series.values || [])[0] || [];
    if (stats.pending
      || (!stats || !stats.response || !stats.response.line_rows)) {
      return (
        <div className="has-text-centered" style={{ padding: '160px 0' }}>
          <Spinner />
        </div>
      );
    }

    return (
      <Card elevation={Elevation.THREE}>
        <div className="columns">
          <div className="column">
            <H4>
              <Link to={`/devices/${id}`}>
                {name}
              </Link>
            </H4>
          </div>
          <div className="column has-text-right">
            <Tag large minimal>
              <Icon intent="primary" icon="camera" />
              &nbsp;&nbsp;&nbsp;
              {dimensions.length ? `${dimensions[1]} x ${dimensions[2]}` : 'Resolution Unknown'}
            </Tag>
          </div>
        </div>
        {Object.entries(stats.response.line_rows || {}).map(([key, value]) => (
          <div className="columns" key={key}>
            <div className="column is-full">
              <H4 className="is-marginless column">
                <span>Line:</span>
                <span style={{ marginLeft: 5, color: 'white' }}>
                  {key.startsWith('0_') ? 'Default' : key}
                </span>
              </H4>
              {this.renderInsAndOuts(value)}
            </div>
          </div>
        ))}
        <div className="columns">
          <div className="column is-full">
            {this.renderFps()}
          </div>
        </div>
      </Card>
    );
  }
}

CameraStatCard.propTypes = {
  dispatch: PropTypes.func,
  id: PropTypes.string,
  region: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  name: PropTypes.string,
  stats: PropTypes.object,
  fps: PropTypes.object,
  resolution: PropTypes.object,
};

export default connect((state, { name }) => ({
  stats: state.cameraStatsSummary[`ins-${name}`] || {},
  fps: state.cameraStatsSummary[`fps-${name}`] || {},
  resolution: state.cameraStatsSummary[`resolution-${name}`] || {},
}))(CameraStatCard);
