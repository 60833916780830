import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button, H5 } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';

import { FormSelectInput } from 'components/inputs';
import { getZone, patchZoneConfigs } from 'actions/site';

class ZoneOccupancyResetTime extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const { hour, minute } = values;
    const reset_time = moment().hour(hour).minute(minute).second(0)
      .format('HH:mm:ss');
    return dispatch(patchZoneConfigs(id, { reset_time }))
      .then(() => dispatch(getZone(id)));
  }

  render() {
    const { handleSubmit, submitting, timezone } = this.props;
    return (
      <div className="columns" style={{ marginLeft: '0px', marginTop: 5 }}>
        <div className="column is-half" style={{ border: '.5px solid #394B59' }}>
          <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
            <div style={{ display: 'flex' }}>
              <H5 style={{ marginRight: 10 }}>Occupancy Reset Time</H5>
              <span>{`(${timezone})`}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <Field component={FormSelectInput} name="hour">
                {[...Array(24).keys()].map(x => (
                  <option key={x} value={x}>{x < 10 ? `0${x}` : x}</option>
                ))}
              </Field>
              <div style={{ margin: 5 }}>:</div>
              <Field component={FormSelectInput} name="minute">
                {[...Array(60).keys()].map(x => (
                  <option key={x.toString()} value={x.toString()}>{x < 10 ? `0${x}` : x}</option>
                ))}
              </Field>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ZoneOccupancyResetTime.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  timezone: PropTypes.string,
};

export default reduxForm({
  form: 'zone_occupancy_reset_time',
  enableReinitialize: true,
})(ZoneOccupancyResetTime);
