import React from 'react';
import { Tag, Button } from '@blueprintjs/core';

const property = (title, value, color) => (
  <div className="card-property">
    <p className="title" style={{ color }}>{title}</p>
    <p className="value">{value}</p>
  </div>
);

const cardTitle = (data, id, intent, onDelete, deleting) => {
  const title = ((data || []).filter(x => x.id === id)[0] || {}).name;
  return (
    <div className="flex-space-between-container">
      <p className="card-header-title">
        <Tag minimal large intent={intent}>{title}</Tag>
      </p>
      <div style={{ marginTop: 5 }}>
        <Button loading={deleting} onClick={onDelete} icon="trash" minimal intent="danger" />
      </div>
    </div>
  );
};

export { cardTitle, property };
