import { requestsReducer } from 'redux-saga-requests';
import { GET_DASHES, GET_DASH } from '../constants';

export const getDashesReducer = requestsReducer({
  actionType: GET_DASHES,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getDashReducer = requestsReducer({
  actionType: GET_DASH,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});
