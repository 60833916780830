import {
  GET_LOCATIONS, GET_LOCATION, SAVE_LOCATION, GET_LOCATION_WHITELIST, POST_WHITELIST,
  DELETE_WHITELIST, GET_AUDIT_LOCATIONS,
} from '../constants';

export const getLocations = () => ({
  type: GET_LOCATIONS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/locations',
    },
  },
});

export const getAuditLocations = () => ({
  type: GET_AUDIT_LOCATIONS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/audit/locations',
    },
  },
});

export const getLocation = locationId => ({
  type: GET_LOCATION,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/locations/${locationId}?status=true`,
    },
  },
});

export const saveLocation = (locationId, data) => ({
  type: SAVE_LOCATION,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/locations/${locationId}`,
    },
  },
});

export const getLocationWhitelist = locationId => ({
  type: GET_LOCATION_WHITELIST,
  payload: {
    request: {
      method: 'get',
      url: `/v1/locations/${locationId}?whitelist=true`,
    },
  },
});

export const postWhitelist = (locationId, data) => ({
  type: POST_WHITELIST,
  payload: {
    request: {
      method: 'post',
      url: `/v1/locations/${locationId}/whitelist`,
      data,
    },
  },
});

export const deleteWhitelist = (locationId, data) => ({
  type: DELETE_WHITELIST,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/locations/${locationId}/delete_whitelist`,
      data,
    },
  },
});
