import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@blueprintjs/select';
import {
  Button, Alert, MenuItem, ButtonGroup, Tag,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';

import { getLocation } from 'actions/location';
import { getDevice, unassignDevice } from 'actions/device';
import { getSite, getSiteDevices } from 'actions/site';
import { AppToaster } from 'components/Toaster';

class DeviceUnassign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unassigning: false,
      forceAlert: false,
    };
  }

  componentDidMount() {
    const { dispatch, device } = this.props;
    if (device.site_id) {
      dispatch(getSite(device.site_id));
      dispatch(getSiteDevices(device.site_id));
      dispatch(getLocation(device.location_id));
    }
  }

  @autobind
  isDeviceUp(item) {
    const { status } = item;
    const { type } = item.device;
    const { is_cms: isCMS } = (item.iap_configuration || { is_cms: false });
    if (['axis.camera', 'amcrest.camera', 'peplink'].includes(type) || isCMS) {
      return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5;
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
    }
    return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5
    && DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
  }

  @autobind
  handlePredicate(query, neighbor) {
    const q = query.toLowerCase();
    const nameSearch = neighbor.device.name.toLowerCase().includes(q);
    const idSearch = neighbor.device.device_identifier.toLowerCase().includes(q);
    return nameSearch || idSearch;
  }

  @autobind
  handleCameraPredicate(query, camera) {
    const q = query.toLowerCase();
    const idSearch = camera.id.toLowerCase().includes(q);
    if (camera.name) {
      const nameSearch = camera.name.toLowerCase().includes(q);
      return nameSearch || idSearch;
    }
    return idSearch;
  }

  @autobind
  handleCancelForceUnassign() {
    this.setState({ forceAlert: false });
  }

  @autobind
  handleForceUnassign() {
    this.setState({ forceAlert: true });
  }

  @autobind
  handleItemSelect(item) {
    const { dispatch } = this.props;
    dispatch(push(`/devices/${item.device.device_identifier}`));
  }

  @autobind
  handleCameraItemSelect(item) {
    const { dispatch } = this.props;
    dispatch(push(`/devices/${item.id}`));
  }

  @autobind
  handleUnassign() {
    const { dispatch, device } = this.props;
    const { forceAlert } = this.state;
    this.setState({ unassigning: true });
    const cmd = unassignDevice(device.organization_device_id,
      device.organization_id,
      forceAlert);
    dispatch(cmd).then(() => {
      dispatch(getDevice(device.id));
      this.setState({ unassigning: false, forceAlert: false });
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            : Device Unassigned
          </span>
        ),
        intent: 'success',
      });
    }, () => this.setState({ unassigning: false, forceAlert: false }));
  }

  @autobind
  renderIAPItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.device.device_identifier}
        icon="symbol-circle"
        intent={this.isDeviceUp(item) ? 'success' : 'danger'}
        onClick={handleClick}
        text={item.device.name}
      />
    );
  }

  @autobind
  renderCameraItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent="none"
        onClick={handleClick}
        text={`${item.name || item.id} -- ${item.ip || 'N/A'}`}
      />
    );
  }

  render() {
    const {
      device, organizations, site, location, siteDevices,
    } = this.props;
    const { unassigning, forceAlert } = this.state;
    const org = organizations.find(o => o.id === device.organization_id) || {};
    const sDevices = (siteDevices.data || []);
    let siteName = '';
    const siteId = device.site_id || '';
    let locationName = '';
    let neighbors = [];
    const assignedSite = site.data !== null && device.site_id === site.data.id;

    if (site.data !== null && device.site_id === site.data.id) {
      siteName = site.data.name;
      if (location.data !== null && site.data.location_id === location.data.id) {
        locationName = location.data.name;
      }
    }
    if (siteDevices.data !== null && device.site_id === siteDevices.data[0].site_id) {
      neighbors = siteDevices.data;
    }
    const deviceCameras = device.cameras || [];
    const camerasWithNames = (() => {
      const cams = device.connected
        ? device.connected.map((x) => {
          const selectedCamera = sDevices.find(y => y.device.device_identifier === x);
          const cameraIp = deviceCameras
            ? (deviceCameras.find(y => y.serial === x) || { ip: '' }).ip
            : null;
          return {
            id: x,
            name: selectedCamera && selectedCamera.device && selectedCamera.device.name
              ? selectedCamera.device.name
              : x,
            ip: cameraIp,
          };
        })
        : [];
      return cams;
    })();
    return (
      <div className="columns is-multiline is-mobile">
        <div className="column is-one-third">
          <p className="p-b-md">Currently Assigned:</p>
          <Link className="p-b-md" to={`/organizations/${device.organization_id}`}>{org.name || device.organization_id}</Link>
        </div>
        <div className="column is-two-thirds">
          {assignedSite && <p className="p-b-md">{`Location: ${locationName}`}</p>}
          {assignedSite && (
            <p className="p-b-md">
              <a href={`/sites/${siteId}`}>{`Site: ${siteName}`}</a>
              &nbsp;
              <Tag minimal intent="warning">{`ID: ${siteId}`}</Tag>
            </p>
          )}
          {!assignedSite && <p className="p-b-md"><em>Not assigned to site</em></p>}
        </div>
        <div className="column is-one-third">
          <Select
            resetOnSelect
            filterable
            itemPredicate={this.handlePredicate}
            itemRenderer={this.renderIAPItem}
            popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
            items={neighbors || []}
            onItemSelect={this.handleItemSelect}
          >
            <Button
              text={`Neighboring Devices (${neighbors.length})`}
              fill
              rightIcon="caret-down"
            />
          </Select>
        </div>
        {!!camerasWithNames.length && (
        <div className="column is-one-third">
          <Select
            resetOnSelect
            filterable
            itemPredicate={this.handleCameraPredicate}
            itemRenderer={this.renderCameraItem}
            popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
            items={camerasWithNames || []}
            onItemSelect={this.handleCameraItemSelect}
          >
            <Button
              text={`Cameras -- IP's (${(camerasWithNames || []).length})`}
              fill
              rightIcon="caret-down"
              icon="camera"
            />
          </Select>
        </div>
        )}
        <div className={`column ${camerasWithNames.length ? 'is-one-third' : 'is-two-thirds'}`}>
          <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Unassign"
            icon="cross"
            intent="danger"
            isOpen={forceAlert && !unassigning}
            onCancel={this.handleCancelForceUnassign}
            onConfirm={this.handleUnassign}
          >
            <p>
              Forcing unassignment of this device will remove this device for
              this account even if the device is already assigned to any sites.
            </p>
          </Alert>
          <ButtonGroup className="is-pulled-right">
            <Button
              intent="warning"
              icon="cross"
              onClick={this.handleUnassign}
              loading={unassigning}
            >
              Unassign
            </Button>
            {assignedSite && (
              <Button
                intent="danger"
                onClick={this.handleForceUnassign}
                loading={unassigning}
              >
                Force
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

DeviceUnassign.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  organizations: PropTypes.array,
  site: PropTypes.object,
  siteDevices: PropTypes.object,
  location: PropTypes.object,
};

export default connect(state => ({
  site: state.site,
  siteDevices: state.siteDevices,
  location: state.location,
}))(DeviceUnassign);
