import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Field, reduxForm, reset } from 'redux-form';
import {
  Button, Card, H5, Spinner,
} from '@blueprintjs/core';
import { TimePickerInput } from 'components/inputs';
import { TimePrecision } from '@blueprintjs/datetime';
import { setDisplayHours, getDisplayHours } from 'actions/cms';

const isValid = (d) => {
  if (d.open_time !== null && d.open_time !== '') {
    if (d.close_time !== null && d.close_time !== '') {
      return true;
    }
  }
  return false;
};

// eslint-disable-next-line no-restricted-globals
const isValidDate = d => d instanceof Date && !isNaN(d);

class DisplayHours extends Component {
  componentDidMount() {
    const { dispatch, deviceId } = this.props;
    dispatch(getDisplayHours(deviceId));
  }

  @autobind
  handleSave(values) {
    const { dispatch, deviceId } = this.props;

    const data = { operating_hours: [] };
    if (values.monday_f) {
      const d = {
        day: 1,
        open_time: values.monday_f.toString().substr(16, 8),
        close_time: values.monday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.monday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.tuesday_f) {
      const d = {
        day: 2,
        open_time: values.tuesday_f.toString().substr(16, 8),
        close_time: values.tuesday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.tuesday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.wednesday_f) {
      const d = {
        day: 3,
        open_time: values.wednesday_f.toString().substr(16, 8),
        close_time: values.wednesday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.wednesday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.thursday_f) {
      const d = {
        day: 4,
        open_time: values.thursday_f.toString().substr(16, 8),
        close_time: values.thursday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.thursday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.friday_f) {
      const d = {
        day: 5,
        open_time: values.friday_f.toString().substr(16, 8),
        close_time: values.friday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.friday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.saturday_f) {
      const d = {
        day: 6,
        open_time: values.saturday_f.toString().substr(16, 8),
        close_time: values.saturday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.saturday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (values.sunday_f) {
      const d = {
        day: 7,
        open_time: values.sunday_f.toString().substr(16, 8),
        close_time: values.sunday_t.toString().substr(16, 8) === '23:59:00'
          ? '23:59:59' : values.sunday_t.toString().substr(16, 8),
      };
      if (isValid(d)) data.operating_hours.push(d);
    }
    if (data.operating_hours.length) {
      return dispatch(setDisplayHours(deviceId, data))
        .then(() => dispatch(getDisplayHours(deviceId)))
        .then(() => dispatch(reset('display-hours')));
    }

    return 0;
  }

  render() {
    const { submitting, handleSubmit, displayHours } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleSave)}>
        <Card>
          <H5>Display Operating Hours</H5>
          <br />
          {displayHours.pending ? <Spinner size="100" /> : (
            <div className="columns">
              <div className="column is-half">
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Mon</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="monday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="monday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Wed</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="wednesday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="wednesday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Fri</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="friday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="friday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Sun</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="sunday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="sunday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Tue</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="tuesday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="tuesday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Thu</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="thursday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="thursday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth align-center-container">
                    <p style={{ marginBottom: '15px' }}>Sat</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="saturday_f"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                  <div className="align-center-container is-centered" style={{ padding: 0 }}>
                    <p style={{ marginBottom: '15px', paddingRight: '10px' }}>to</p>
                  </div>
                  <div className="column is-one-third" style={{ padding: 0 }}>
                    <Field
                      component={TimePickerInput}
                      name="saturday_t"
                      precision={TimePrecision.MINUTE}
                    />
                  </div>
                </div>
                <div className="has-text-right" style={{ marginTop: '80px' }}>
                  <Button
                    intent="primary"
                    loading={submitting}
                    disabled={submitting}
                    type="submit"
                    icon="tick"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Card>
      </form>
    );
  }
}

DisplayHours.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  deviceId: PropTypes.string,
  displayHours: PropTypes.object,
};

/* The month date and year do not matter, but are required for Date object creation */
export default connect(state => ({
  displayHours: state.displayHours,
  initialValues: state.displayHours.data && state.displayHours.data.length ? {
    monday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 1) || {}).open_time || '').slice(0, 5)}`),
    monday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 1) || {}).close_time || '').slice(0, 5)}`),
    tuesday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 2) || {}).open_time || '').slice(0, 5)}`),
    tuesday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 2) || {}).close_time || '').slice(0, 5)}`),
    wednesday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 3) || {}).open_time || '').slice(0, 5)}`),
    wednesday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 3) || {}).close_time || '').slice(0, 5)}`),
    thursday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 4) || {}).open_time || '').slice(0, 5)}`),
    thursday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 4) || {}).close_time || '').slice(0, 5)}`),
    friday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 5) || {}).open_time || '').slice(0, 5)}`),
    friday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 5) || {}).close_time || '').slice(0, 5)}`),
    saturday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 6) || {}).open_time || '').slice(0, 5)}`),
    saturday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 6) || {}).close_time || '').slice(0, 5)}`),
    sunday_f: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 7) || {}).open_time || '').slice(0, 5)}`),
    sunday_t: new Date(`December 31, 1899 ${((state.displayHours.data.find(x => x.day === 7) || {}).close_time || '').slice(0, 5)}`),
  } : undefined,
}))(reduxForm({
  form: 'display_hours',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    const days = [
      ['monday_f', 'monday_t'],
      ['tuesday_f', 'tuesday_t'],
      ['wednesday_f', 'wednesday_t'],
      ['thursday_f', 'thursday_t'],
      ['friday_f', 'friday_t'],
      ['saturday_f', 'saturday_t'],
      ['sunday_f', 'sunday_t'],
    ];
    days.forEach((d) => {
      if (isValidDate(values[d[0]]) && !isValidDate(values[d[1]])) {
        errors[d[1]] = 'Required';
      }
      if (isValidDate(values[d[1]]) && !isValidDate(values[d[0]])) {
        errors[d[0]] = 'Required';
      }
      if (values[d[0]] && values[d[1]]) {
        if (values[d[0]] > values[d[1]]) {
          errors[d[0]] = 'Invalid';
        }
      }
    });
    return errors;
  },
})(DisplayHours));
