import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Card } from '@blueprintjs/core';
import { RangePicker } from 'antd/lib/date-picker';

import { getPeplinkLogs } from 'actions/device';
import { QUERY_DATE_FMT } from 'constants';

class PeplinkLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: DateTime.utc().minus(10800000),
      endDate: DateTime.utc(),
    };
  }

  componentDidMount() {
    const { peplinkId, dispatch } = this.props;
    const { startDate, endDate } = this.state;
    dispatch(getPeplinkLogs(peplinkId,
      `${startDate.toFormat('yyyy-MM-dd')}T${startDate.toFormat('HH:mm:ss')}`,
      `${endDate.toFormat('yyyy-MM-dd')}T${endDate.toFormat('HH:mm:ss')}`, 0));
  }

  @autobind
  getLogs(state) {
    const { dispatch, peplinkId } = this.props;
    const { startDate, endDate } = this.state || state;
    const start = `${startDate.toFormat('yyyy-MM-dd')}T${startDate.toFormat('HH:mm:ss')}`;
    const end = `${endDate.toFormat('yyyy-MM-dd')}T${endDate.toFormat('HH:mm:ss')}`;
    dispatch(getPeplinkLogs(peplinkId, start, end, 0));
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    }, this.getLogs);
  }

  render() {
    const { peplinkLogs } = this.props;
    const { startDate, endDate } = this.state;
    const logs = [];
    if (peplinkLogs.data) {
      peplinkLogs.data.forEach((log) => {
        logs.push(`
          Time: ${DateTime.fromISO(log.ts).toFormat('ff')}
          Event Type: ${log.event_type}
          Detail: ${log.detail}
        `);
      });
    }
    return (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            <Card>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format={QUERY_DATE_FMT}
                style={{ width: '500px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Card>
          </div>
        </div>
        <div>
          <pre className="bp3-code-block">
            <code>
              {logs}
            </code>
          </pre>
        </div>
      </React.Fragment>
    );
  }
}

PeplinkLogs.propTypes = {
  dispatch: PropTypes.func,
  peplinkId: PropTypes.number,
  peplinkLogs: PropTypes.object,
};

export default connect(state => ({
  peplinkLogs: state.peplinkLogs,
}))(PeplinkLogs);
