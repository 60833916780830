import { requestsReducer } from 'redux-saga-requests';
import { combineReducers } from 'redux';
import {
  GET_NVRS, GET_NVR, NVR_METRICS, GET_NVR_RELEASE, GET_NVR_RELEASES, GET_NVR_SERVICES,
} from '../constants';

export const nvrsReducer = requestsReducer({
  actionType: GET_NVRS,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    content.meta = action.payload.data.metadata || {};
    return content;
  },
});

export const nvrReducer = requestsReducer({
  actionType: GET_NVR,
  getData: (state, action) => action.payload.data.content,
});

export const nvrMetricsReducer = requestsReducer({
  actionType: NVR_METRICS,
  getData: (state, action) => action.payload.data,
});

export const nvrReleaseReducer = combineReducers({
  releases: requestsReducer({
    actionType: GET_NVR_RELEASES,
    multiple: true,
    getData: (state, action) => action.payload.data.content,
  }),
  services: requestsReducer({
    actionType: GET_NVR_SERVICES,
    multiple: true,
    getData: (state, action) => action.payload.data.content,
  }),
  release: requestsReducer({
    actionType: GET_NVR_RELEASE,
    getData: (state, action) => action.payload.data.content,
  }),
});
