import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Checkbox } from '@blueprintjs/core';


class CheckboxInput extends Component {
  @autobind
  onCheckboxChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.target.checked);
  }

  render() {
    const {
      input, label, disabled, className,
    } = this.props;
    return (
      <Checkbox
        checked={input.value}
        label={label}
        onChange={this.onCheckboxChange}
        disabled={disabled}
        className={className}
      />
    );
  }
}

CheckboxInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckboxInput;
