import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, reset, formValueSelector,
} from 'redux-form';
import {
  Button, Card,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  CheckboxInput, TagInput,
} from 'components/inputs';

import { getDevice, saveDevice } from 'actions/device';

class AxisConfig extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, device } = this.props;
    return dispatch(saveDevice(device.id, {
      axis_camera_scan: !!values.axis_camera_scan,
      axis_camera_ip: values.axis_camera_ip,
    }))
      .then(() => dispatch(getDevice(device.id)))
      .then(() => dispatch(reset('axis-iap-config')));
  }

  render() {
    const {
      submitting, handleSubmit, axisScan,
    } = this.props;

    return (
      <form className="columns" onSubmit={handleSubmit(this.handleSave)}>
        <div className="column is-half">
          <Card>
            <Field
              component={CheckboxInput}
              placeholder=""
              name="axis_camera_scan"
              label="Scan for AXIS Cameras"
            />
            {axisScan && (
              <Field
                component={TagInput}
                placeholder="Scan for any camera"
                name="axis_camera_ip"
                label="Predetermined AXIS IP List"
              />
            )}
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </Card>
        </div>
      </form>
    );
  }
}

AxisConfig.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
  axisScan: PropTypes.bool,
};

const formSelect = formValueSelector('axis-iap-config');

export default compose(
  reduxForm({
    form: 'axis-iap-config',
    enableReinitialize: true,
  }),
  connect(state => ({
    axisScan: formSelect(state, 'axis_camera_scan'),
  })),
)(AxisConfig);
