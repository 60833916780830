import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';

import { getZoneOccupancy } from 'actions/query';
import { autobind } from 'core-decorators';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { selectedZones } = this.props;

      this.getAllZonesOccupancy(selectedZones, parameters);
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const { selectedZones } = this.props;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters && !!newParameters.get('selectedZones')) {
        this.getAllZonesOccupancy(selectedZones, parameters);
        this.setState({ parameters: newParameters });
      }
    }

    @autobind
    async getAllZonesOccupancy(selectedZones, parameters) {
      const { dispatch, name } = this.props;
      const batchSize = 10;
      const batches = Math.floor(selectedZones.length / batchSize);
      const rem = selectedZones.length % batchSize;
      const asyncLoop = async (all, params) => {
        for (let i = 0; i < all.length; i += 1) {
          dispatch(getZoneOccupancy(`${all[i].id}-${name}`, params.get('endpoint'), all[i].id,
            params.get('startTime'),
            params.get('endTime'),
            params.get('dimensions').toJS()));
        }
      };

      for (let i = 0; i < batches; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(selectedZones.slice(i * batchSize, i * batchSize + batchSize),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
      if (rem > 0) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          // eslint-disable-next-line max-len
          setTimeout(() => asyncLoop(selectedZones.slice(selectedZones.length - rem, selectedZones.length),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, zoneId, endpoint,
      } = props;
      return Immutable.Map({
        startTime: fmtTimeParam(startTime),
        endTime: fmtTimeParam(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        zoneId,
        endpoint: endpoint || 'occupancy',
      });
    }

    render() {
      const {
        data,
        props,
      } = this.props;
      const propsToPass = {
        data,
      };
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    props: PropTypes.any,
    startTime: PropTypes.any,
    endTime: PropTypes.any,
    data: PropTypes.object,
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to OccupancyProvider');
    }
    const data = (() => {
      let fetched = 0;
      const rows = [];
      const { selectedZones } = props;
      if (selectedZones) {
        selectedZones.forEach((zone) => {
          if (state.zoneOccupancy[`${zone.id}-${options.name}`]) {
            if (state.zoneOccupancy[`${zone.id}-${options.name}`].response
              && state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows) {
              rows.push([zone.id, state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows]);
            }
            if (state.zoneOccupancy[`${zone.id}-${options.name}`].resolved) {
              fetched += 1;
            }
          }
        });
        const progress = Math.floor(fetched / selectedZones.length * 100);
        return { rows, progress, resolved: true } || { resolved: false };
      }
      return { rows: [], progress: 0, resolved: false };
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      props,
    };
  }, null, null, { forwardRef: true })(Model);
};

export default provider;
