import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { autobind } from 'core-decorators';
import {
  Tab, Tabs, H1, Icon, Navbar, Spinner,
} from '@blueprintjs/core';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { getZone, getZoneDevices } from 'actions/site';
import { getAllDevices } from 'actions/device';

import OperatingHours from '../EditInventory/operatinghours';
import AdvancedMetrics from '../EditInventory/advancedmetrics';
import GroundTruth from '../EditInventory/groundtruth';
import ZoneDevices from './devices';
import ZoneOccupancy from './occupancy';
import ZoneConfig from './configs';
import ZoneOccupancyReset from './resetTime';
import TabPermissions from './tabPermissions';

class Zone extends Component {
  componentDidMount() {
    this.getZoneData();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.getZoneData();
    }
  }

  @autobind
  getZoneData() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    dispatch(getZone(id));
    dispatch(getAllDevices());
    dispatch(getZoneDevices(id));
  }

  @autobind
  handleTabChange(tabId) {
    const { dispatch, location } = this.props;
    dispatch(push(tabId + (location.search || '')));
  }

  @autobind
  generateResetTime(resetTime) {
    const [hour, minute] = resetTime.split(':');
    return {
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    };
  }

  render() {
    const {
      zone, match, allDevices, location, zoneDevices,
    } = this.props;
    const zoneId = parseInt(match.params.id, 10);
    const created = DateTime.fromISO((zone.data || {}).created)
      .toLocaleString(DateTime.DATETIME_MED);

    if (!zone.data && zone.pending) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    if (!zoneDevices.data && zoneDevices.pending) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    if ((zone.data || {}).id !== zoneId) {
      return (<div className="container"><Spinner size={100} /></div>);
    }
    const currentZone = (zone || {}).data;
    // const currentZoneDevices = (zoneDevices || {}).data;
    // const cameras = (currentZoneDevices || []).filter(x => (x.device || { type: '' })
    //   .type.includes('camera'));
    const {
      zone_config = {}, tab_permissions = [], id, devices, occupancy_enabled, timezone,
    } = currentZone || {};
    const isZoneActive = (((currentZone || {}).status || {}).devices || {}).up > 0;
    return (
      <div className="container">
        <div className={classNames('columns', { 'bp3-skeleton': zone.pending && id !== zoneId })}>
          <H1>
            {currentZone.name}
            <div className="bp3-text-small bp3-text-muted">
              ID:&nbsp;
              {currentZone.id}
              &nbsp;
              Created:&nbsp;
              {created}
            </div>
          </H1>
          <span style={{ margin: '15px 0 0 20px' }}>
            <Icon icon="symbol-circle" intent={isZoneActive ? 'success' : 'danger'} />
          </span>
        </div>
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-location" onChange={this.handleTabChange} selectedTabId={location.pathname} large>
              <Tab id={`/zones/${id}`} title="Info" />
              <Tab id={`/zones/${id}/op_hours`} title="Operating Hours" />
              <Tab id={`/zones/${id}/groundtruth`} title="Groundtruth" />
              <Tab id={`/zones/${id}/devices`} title="Devices" />
              {/* {!!cameras.length
                && <Tab id={`/zones/${id}/camera_directions`} title="Camera Directions" />} */}
              <Tab id={`/zones/${id}/occupancy`} title="Occupancy" />
              <Tab id={`/zones/${id}/permissions`} title="Tab Permissions" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <Switch>
          <Route path="/zones/:id/op_hours">
            <OperatingHours isZone match={match} zone={currentZone} />
          </Route>
          <Route path="/zones/:id/groundtruth">
            <GroundTruth match={match} />
          </Route>
          <Route path="/zones/:id/devices">
            <ZoneDevices zoneDevices={devices} allDevices={allDevices} />
          </Route>
          {/* <Route path="/zones/:id/camera_directions">
            <CameraDirections cameras={cameras} match={match} />
          </Route> */}
          <Route path="/zones/:id/permissions">
            <TabPermissions initialValues={tab_permissions[0]} />
          </Route>
          <Route path="/zones/:id/occupancy">
            <Fragment>
              <ZoneOccupancy
                match={match}
                initialValues={{ occupancy_enabled }}
              />
              <ZoneOccupancyReset
                match={match}
                timezone={timezone}
                initialValues={this.generateResetTime(zone_config.reset_time)}
              />
            </Fragment>
          </Route>
          <Route>
            <Fragment>
              <AdvancedMetrics
                initialValues={{
                  waittime: currentZone.is_one_way_queuing,
                  passerby: currentZone.passerby_type,
                }}
                zone={currentZone}
                match={match}
              />
              <ZoneConfig
                initialValues={{
                  min_appearance_length: zone_config.min_appearance_length,
                  max_appearance_length: zone_config.max_appearance_length,
                  ci_type: zone_config.ci_type,
                  grid_width: zone_config.grid_width,
                  grid_height: zone_config.grid_height,
                  piecewise_model: JSON.stringify(zone_config.piecewise_model, null, 2),
                }}
                zone={currentZone}
                match={match}
              />
            </Fragment>
          </Route>
        </Switch>
      </div>
    );
  }
}

Zone.propTypes = {
  zone: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  allDevices: PropTypes.object,
  location: PropTypes.object,
  zoneDevices: PropTypes.object,
};

export default connect(state => ({
  zone: state.zone,
  allDevices: state.allDevices,
  zoneDevices: state.zoneDevices,
  zoneConfigs: state.zoneConfigs,
}))(Zone);
