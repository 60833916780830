import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { H4, FormGroup } from '@blueprintjs/core';
import numbro from 'numbro';

import { nvrMetrics } from 'actions/nvr';
import { options2, InfluxCard, InfluxGraph } from '../../Influx';

class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iface: 'eno1',
      disk: '/',
    };
  }

  componentDidMount() {
    const {
      dispatch, nvr, startDate, endDate,
    } = this.props;
    dispatch(nvrMetrics(
      nvr.id,
      startDate.startOf('second').toISO({ suppressMilliseconds: true }),
      endDate.startOf('second').toISO({ suppressMilliseconds: true }),
    ));
  }

  componentDidUpdate(prevProps) {
    const {
      startDate, endDate, dispatch, nvr,
    } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      dispatch(nvrMetrics(
        nvr.id,
        startDate.startOf('second').toISO({ suppressMilliseconds: true }),
        endDate.startOf('second').toISO({ suppressMilliseconds: true }),
      ));
    }
  }

  @autobind
  handleInterface(e) {
    this.setState({ iface: e.target.value });
  }

  @autobind
  handleDisk(e) {
    this.setState({ disk: e.target.value });
  }

  render() {
    const { metrics } = this.props;
    const {
      iface, disk,
    } = this.state;
    return (
      <div className="columns is-multiline">
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">CPU Usage</H4>
            </div>
            <InfluxGraph metric="cpu" metrics={metrics} yFmt={x => numbro(x / 100).format('0[.00]%')} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Memory Usage</H4>
            </div>
            <InfluxGraph metric="memory" metrics={metrics} yFmt={x => numbro(x).format('0[.00]b')} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Network Usage</H4>
              <div className="column has-text-right">
                <FormGroup label="Interface" inline className="inline-flex is-marginless p-l-sm">
                  <div className="bp3-select">
                    <select value={iface} onChange={this.handleInterface}>
                      {options2(metrics, 'network', 'interface').map(x => <option key={x} value={x}>{x}</option>)}
                    </select>
                  </div>
                </FormGroup>
              </div>
            </div>
            <InfluxGraph metric="network" metrics={metrics} yFmt={x => numbro(Math.abs(x)).format('0[.00]b')} filter={x => x.tags.interface === iface} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Disk Usage</H4>
              <div className="column has-text-right">
                <FormGroup label="Disk" inline className="inline-flex is-marginless">
                  <div className="bp3-select">
                    <select value={disk} onChange={this.handleDisk}>
                      {options2(metrics, 'disk', 'disk').map(x => <option key={x} value={x}>{x}</option>)}
                    </select>
                  </div>
                </FormGroup>
              </div>
            </div>
            <InfluxGraph metric="disk" metrics={metrics} yFmt={x => numbro(x).format('0[.00]b')} filter={x => x.tags.disk === disk} />
          </InfluxCard>
        </div>
      </div>
    );
  }
}

Metrics.propTypes = {
  nvr: PropTypes.object,
  dispatch: PropTypes.func,
  metrics: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default connect(state => ({
  metrics: state.nvrMetrics,
}))(Metrics);
