import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  H4, Spinner, Button,
  Card, NonIdealState, FormGroup,
} from '@blueprintjs/core';
import moment from 'moment';
import numbro from 'numbro';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import {
  deviceIapMetrics, deviceBeaconMetrics, fetchImage,
} from 'actions/device';

import { options2, InfluxCard, InfluxGraph } from '../../../Influx';

class IapDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchImageLoading: false,
      fetchImageSuccess: false,
      rand: Math.floor(Math.random() * 10000),
      refreshScreenshot: Math.floor(Math.random() * 10000),
      iface: 'wlan0',
      disk: '/',
    };
  }

  componentDidMount() {
    const {
      dispatch, device, startDate, endDate,
    } = this.props;
    dispatch(deviceIapMetrics(
      device.id,
      startDate.startOf('second').toISO({ suppressMilliseconds: true }),
      endDate.startOf('second').toISO({ suppressMilliseconds: true }),
    ));
    dispatch(deviceBeaconMetrics(
      device.id,
      startDate.toISO(),
      endDate.toISO(),
    ));
  }

  componentDidUpdate(prevProps) {
    const {
      startDate, endDate, dispatch, device,
    } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      dispatch(deviceIapMetrics(
        device.id,
        startDate.startOf('second').toISO({ suppressMilliseconds: true }),
        endDate.startOf('second').toISO({ suppressMilliseconds: true }),
      ));
      dispatch(deviceBeaconMetrics(
        device.id,
        startDate.toISO(),
        endDate.toISO(),
      ));
    }
  }

  @autobind
  handleInterface(e) {
    this.setState({ iface: e.target.value });
  }

  @autobind
  handleDisk(e) {
    this.setState({ disk: e.target.value });
  }

  @autobind
  handleFetchImage() {
    this.setState({ fetchImageLoading: true });
    const { dispatch, device } = this.props;
    dispatch(fetchImage(device.id))
      .then(() => this.setState({ fetchImageSuccess: true, fetchImageLoading: false }))
      .finally(() => this.setState({ fetchImageLoading: false }));
  }

  @autobind
  refresh() {
    this.setState({ refreshScreenshot: Math.floor(Math.random() * 10000) });
  }

  renderBeaconChart() {
    const { beacon, device } = this.props;
    const status = device.status || {};
    const lastBeacon = (status.beacon || '0001').substring(0, 4) !== '0001' ? moment(status.beacon).fromNow() : 'Never';
    if (!beacon.data || beacon.pending) {
      return (
        <div className="column is-half">
          <Card>
            <div className="has-text-centered" style={{ padding: '160px 0' }}><Spinner /></div>
          </Card>
        </div>
      );
    }
    if (beacon.data.mac_count.length === 0) {
      return (
        <div className="column is-half">
          <Card>
            <NonIdealState
              icon="satellite"
              title="No beacons"
              className="non-ideal-device-card"
              description={`Last Beacon: ${lastBeacon}`}
            />
          </Card>
        </div>
      );
    }
    const fmtr = x => numbro(x).format('0,0');
    const data = beacon.data.mac_count[0].values.map(x => ({
      t: x.timeInEpoch,
      y: x.count,
    })).sort((a, b) => a.t - b.t);
    const dataset = {
      label: 'beacons',
      data,
      type: 'line',
      pointRadius: 0,
      fill: true,
      lineTension: 0,
      borderWidth: 1.3,
      borderColor: '#CFF3D2',
      // backgroundColor: 'rgb(207,243,210, .2)',
    };
    return (
      <div className="column is-half">
        <Card>
          <div className="columns is-marginless">
            <H4 className="is-marginless column">
              Beacon
              &nbsp;
              <small>
                Last Beacon:&nbsp;
                {lastBeacon}
              </small>
            </H4>
          </div>
          <Line
            data={{
              labels: _.map(data || [], 't'),
              datasets: data ? [dataset] : [],
            }}
            options={{
              legend: {
                labels: {
                  fontColor: '#f5f8fa',
                  generateLabels: (chart) => {
                    const x = Chart.defaults.global.legend.labels.generateLabels(chart);
                    x.forEach((yy) => {
                      const y = yy;
                      y.fillStyle = y.strokeStyle;
                    });
                    return x;
                  },
                },
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  labelColor: (tooltipItem, chart) => {
                    const x = Chart.defaults.global.tooltips
                      .callbacks.labelColor(tooltipItem, chart);
                    x.backgroundColor = x.borderColor;
                    return x;
                  },
                  beforeLabel: (tooltipItem) => {
                    // eslint-disable-next-line no-param-reassign
                    tooltipItem.yLabel = fmtr(tooltipItem.yLabel);
                  },
                },
              },
              scales: {
                xAxes: [{
                  type: 'time',
                  distribution: 'linear',
                  ticks: {
                    source: 'auto',
                    fontColor: '#f5f8fa',
                  },
                }],
                yAxes: [{
                  scaleLabel: {
                    display: true,
                  },
                  ticks: {
                    fontColor: '#f5f8fa',
                    callback: fmtr,
                  },
                }],
              },
            }}
          />
        </Card>
      </div>
    );
  }

  @autobind
  renderCamera() {
    const { device } = this.props;
    const { rand, fetchImageSuccess, fetchImageLoading } = this.state;
    if (device.iap_configuration && device.iap_configuration.connected_camera) {
      if (fetchImageSuccess) {
        return (
          <div className="column is-half">
            <Card>
              <div className="columns is-marginless">
                <H4 className="is-marginless column">Vision</H4>
              </div>
              <div style={{ textAlign: 'center' }}>
                <img src={`https://s3.us-east-2.amazonaws.com/camera-data-ohio.livereachmedia.com/${device.id}.jpg?${rand}`} alt="feed" style={{ height: 312 }} />
              </div>
            </Card>
          </div>
        );
      }
      return (
        <div className="column is-half">
          <Card style={{ height: '396px', display: 'flex', flexDirection: 'column' }}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Vision</H4>
            </div>
            <div style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Button onClick={this.handleFetchImage} loading={fetchImageLoading}>
                Generate Image
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    if (device.status.past_camera) {
      return (
        <div className="column is-half">
          <Card>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Camera</H4>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img src={`https://s3.us-east-2.amazonaws.com/camera-data-ohio.livereachmedia.com/${device.id}.jpg?${rand}`} alt="feed" style={{ height: 312 }} />
            </div>
          </Card>
        </div>
      );
    }
    return null;
  }

  renderScreenshot() {
    const { device, accessToken } = this.props;
    const { refreshScreenshot } = this.state;
    if (!device.iap_configuration || !device.iap_configuration.is_cms) {
      return null;
    }

    return (
      <div className="column is-half">
        <Card style={{ height: '396px', display: 'flex', flexDirection: 'column' }}>
          <div className="columns is-marginless">
            <H4 className="is-marginless column">Display</H4>
            <div className="column has-text-right">
              <Button onClick={this.refresh}>
                Refresh
              </Button>
            </div>
          </div>
          <div style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <div style={{ textAlign: 'center' }}>
              <img src={`https://api.livereachmedia.com/api/v1/admin/devices/${device.id}/screenshot?access_token=${accessToken}&${refreshScreenshot}`} alt="feed" style={{ height: 312 }} />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    const { metrics } = this.props;
    const {
      iface, disk,
    } = this.state;
    return (
      <div className="columns is-multiline">
        {this.renderBeaconChart()}
        {this.renderCamera()}
        {this.renderScreenshot()}
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">CPU Usage</H4>
            </div>
            <InfluxGraph metric="cpu" metrics={metrics} yFmt={x => numbro(x / 100).format('0[.00]%')} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Memory Usage</H4>
            </div>
            <InfluxGraph metric="memory" metrics={metrics} yFmt={x => numbro(x).format('0[.00]b')} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Network Usage</H4>
              <div className="column has-text-right">
                <FormGroup label="Interface" inline className="inline-flex is-marginless p-l-sm">
                  <div className="bp3-select">
                    <select value={iface} onChange={this.handleInterface}>
                      {options2(metrics, 'network', 'interface').map(x => <option key={x} value={x}>{x}</option>)}
                    </select>
                  </div>
                </FormGroup>
              </div>
            </div>
            <InfluxGraph metric="network" metrics={metrics} yFmt={x => numbro(Math.abs(x)).format('0[.00]b')} filter={x => x.tags.interface === iface} />
          </InfluxCard>
        </div>
        <div className="column is-half">
          <InfluxCard metrics={metrics}>
            <div className="columns is-marginless">
              <H4 className="is-marginless column">Disk Usage</H4>
              <div className="column has-text-right">
                <FormGroup label="Disk" inline className="inline-flex is-marginless">
                  <div className="bp3-select">
                    <select value={disk} onChange={this.handleDisk}>
                      {options2(metrics, 'disk', 'disk').map(x => <option key={x} value={x}>{x}</option>)}
                    </select>
                  </div>
                </FormGroup>
              </div>
            </div>
            <InfluxGraph metric="disk" metrics={metrics} yFmt={x => numbro(x).format('0[.00]b')} filter={x => x.tags.disk === disk} />
          </InfluxCard>
        </div>
      </div>
    );
  }
}

IapDevice.propTypes = {
  accessToken: PropTypes.string,
  device: PropTypes.object,
  dispatch: PropTypes.func,
  beacon: PropTypes.object,
  metrics: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default connect(state => ({
  metrics: state.iapmetrics,
  beacon: state.beacon,
  accessToken: state.currentUser.token.access_token,
}))(IapDevice);
