import {
  GET_DASHES, ADD_CARDS, GET_DASH, PATCH_CARDS_DASH, DELETE_DASHBOARD,
} from '../constants';

export const getDashes = () => ({
  type: GET_DASHES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/dashes',
    },
  },
});

export const createDash = data => ({
  type: ADD_CARDS,
  payload: {
    request: {
      method: 'post',
      url: '/v1/admin/dashes',
      data,
    },
  },
});

export const getDash = dashId => ({
  type: GET_DASH,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/dashes/${dashId}`,
    },
  },
});

export const addCardstoDash = (data, dashId) => ({
  type: PATCH_CARDS_DASH,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/admin/dashes/${dashId}`,
      data,
    },
  },
});

export const deleteDash = id => ({
  type: DELETE_DASHBOARD,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/admin/dashes/${id}`,
    },
  },
});
