import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  Card, Elevation, ButtonGroup, Button,
} from '@blueprintjs/core';
import { push } from 'connected-react-router';

import { getAllDevices } from 'actions/device';
import TrainerResources from './Resources';
import Datasets from './datasets';
import Tasks from './Tasks';

class ModelTrainingDash extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/prop-types
    const tab = props.location.pathname.split('/')[2];
    this.state = {
      active: tab || 'machines',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllDevices());
  }

  @autobind
  onSelectMachines() {
    const { dispatch } = this.props;
    this.setState({ active: 'machines' },
      () => dispatch(push('/model_training')));
  }

  @autobind
  onSelectDatasets() {
    const { dispatch } = this.props;
    this.setState({ active: 'datasets' },
      () => dispatch(push('/model_training/datasets')));
  }

  @autobind
  onSelectTasks() {
    const { dispatch } = this.props;
    this.setState({ active: 'tasks' },
      () => dispatch(push('/model_training/tasks')));
  }

  renderCards(device) {
    return (
      <Fragment>
        <Card elevation={Elevation.FOUR}>
          {device.name}
        </Card>
        <br />
      </Fragment>
    );
  }

  render() {
    const { active } = this.state;
    const { allDevices, match } = this.props;
    const learnerDevices = allDevices.data.filter(x => x.type === 'learner');

    return (
      <Fragment>
        <div style={{ textAlign: 'center' }}>
          <ButtonGroup>
            <Button
              icon="pulse"
              style={{ width: '150px' }}
              active={active === 'machines'}
              onClick={this.onSelectMachines}
            >
              Machines
            </Button>
            <Button
              icon="database"
              style={{ width: '150px' }}
              active={active === 'datasets'}
              onClick={this.onSelectDatasets}
            >
              Datasets
            </Button>
            <Button
              icon="history"
              style={{ width: '150px' }}
              active={active === 'tasks'}
              onClick={this.onSelectTasks}
            >
              Tasks
            </Button>
          </ButtonGroup>
        </div>
        <br />
        {active === 'machines' && <TrainerResources learners={learnerDevices} />}
        {active === 'datasets' && <Datasets />}
        {active === 'tasks' && <Tasks match={match} />}
      </Fragment>
    );
  }
}

ModelTrainingDash.propTypes = {
  dispatch: PropTypes.func,
  allDevices: PropTypes.object,
  match: PropTypes.object,
};

export default connect(state => ({
  allDevices: state.allDevices,
  trainerResources: state.trainerResources,
}))(ModelTrainingDash);
