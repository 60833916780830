/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import classNames from 'classnames';
import qs from 'query-string';
import {
  H1, Icon, FormGroup, Spinner, Tooltip, Button, MenuItem, H5,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { auditClips } from 'actions/audit';
import ReactPaginate from 'react-paginate-bp3';
import { getUsersNonAdmin } from 'actions/user';

import AuditSelect from './audit_select';

const initials = (name) => {
  const xinitials = name.match(/\b\w/g) || [];
  return ((xinitials.shift() || '') + (xinitials.pop() || '')).toUpperCase();
};

class Videos extends Component {
  constructor(props) {
    super(props);
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const page = parseInt(search.p, 10) || 0;
    const groundtruth = search.gt || null;
    const audit = search.a || null;
    const assigned = search.s || '-1';

    this.state = {
      groundtruth,
      audit,
      page,
      viewers: '-1',
      assigned,
      viewButton: false,
      staleData: false,
    };

    this.refresh = _.debounce(this.handleRefresh, 100);
  }

  componentDidMount() {
    this.loadClips();
    const { users, orgId, dispatch } = this.props;
    if (!users.data || !users.data.length) {
      if (orgId) dispatch(getUsersNonAdmin(null, null, orgId, null, null));
    }
  }

  loadClips() {
    const { dispatch } = this.props;
    const {
      groundtruth, audit, page, assigned,
    } = this.state;
    const assignee = assigned === '-1' ? undefined : assigned;
    if (audit || assigned !== '-1') {
      dispatch(auditClips(true, groundtruth, audit, 100, page * 100, assignee))
        .then(this.setState({ staleData: false }));
    }
  }

  @autobind
  handleRefresh() {
    const { dispatch } = this.props;
    const {
      groundtruth, page, audit, assigned,
    } = this.state;

    dispatch(replace(`/audits/groundtruth?${qs.stringify(_.pickBy({
      gt: groundtruth,
      p: page,
      a: audit,
      s: assigned,
    }))}`));
    this.loadClips();
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({ page: selected }, this.refresh);
  }

  @autobind
  handleGroundTruth(e) {
    this.setState({ groundtruth: e.target.value || null, staleData: true }, this.showViewButton);
  }

  @autobind
  handleAssigned(item) {
    this.setState({ assigned: item.value, staleData: true }, this.showViewButton);
  }

  @autobind
  handleAudit(e) {
    if (e === undefined) {
      this.setState({ audit: null, staleData: true }, this.showViewButton);
    } else {
      this.setState({ audit: e || null, staleData: true }, this.showViewButton);
    }
  }

  @autobind
  handleViewers(e) {
    this.setState({ viewers: e.target.value });
  }

  @autobind
  handleAssigneePredicate(query, item) {
    const q = query.toLowerCase();
    return item.name.toLowerCase().includes(q);
  }

  @autobind
  handleViewClips() {
    this.refresh();
  }

  @autobind
  showViewButton() {
    const { audit, assigned } = this.state;
    if (audit || assigned !== '-1') {
      this.setState({ viewButton: true });
    } else {
      this.setState({ viewButton: false });
    }
  }

  @autobind
  renderAssigneeItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item.id}
        icon="symbol-circle"
        intent={undefined}
        onClick={handleClick}
        text={item.name}
      />
    );
  }

  @autobind
  renderClip(clip) {
    const { audit, viewers } = this.state;
    const ic = <Icon icon={clip.ground_truth ? 'tick-circle' : 'ban-circle'} iconSize={Icon.SIZE_LARGE} intent={clip.ground_truth ? 'success' : 'danger'} />;
    let link = audit ? `/audits/groundtruth/${clip.id}?audit=${audit}` : `/audits/groundtruth/${clip.id}`;
    if ((clip.audit || {}).line_id) {
      link = `/audits/groundtruth/${clip.id}?audit=${audit}&line_id=${clip.audit.line_id}`;
    }
    let showClip = true;
    if (viewers === '0') showClip = !!clip.viewers.length;
    if (viewers === '1') showClip = !clip.viewers.length;
    return showClip && (
      <tr key={`${clip.id}_${clip.audit.line_id}`}>
        <td><Link to={link}>{clip.id}</Link></td>
        <td>{(clip.audit || {}).line_id}</td>
        <td><Link to={link}>{clip.audit.clip_name}</Link></td>
        <td><Link to={`/devices/${clip.device.id}`}>{clip.device.name}</Link></td>
        <td><Link to={`/devices/${clip.camera.id}`}>{clip.camera.name}</Link></td>
        <td>{DateTime.fromISO(clip.start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td className="has-text-centered">{ic}</td>
        <td className="has-text-centered">
          {
            clip.viewers.map(viewer => (
              <Tooltip content={viewer.name} key={viewer.id}>
                <Button intent="warning" style={{ borderRadius: '50px' }} className="m-r-sm">{initials(viewer.name)}</Button>
              </Tooltip>
            ))
          }
        </td>
      </tr>
    );
  }

  render() {
    const { clips, users } = this.props;
    const {
      groundtruth, audit, page, viewers, assigned, viewButton, staleData,
    } = this.state;
    const spinner = clips.pending ? <Spinner size="30" className="rdtCounters" /> : undefined;
    const userItems = [
      { value: '-1', name: 'Any', id: -2 },
      { value: '', name: 'Unassigned', id: -3 },
      ...users.data.map(x => ({ value: x.name, id: x.id, name: x.name })),
    ];
    const clipCheck = (((clips.data || [])[0] || {}).audit || {});
    const validClips = clips.data.filter(x => x.ground_truth_line_id === x.audit.line_id || x.ground_truth_line_id === 0);
    const totalValid = validClips.length;
    let doRender = true;
    if (audit) {
      doRender = clipCheck.audit_id === parseInt(audit, 10);
    }
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none flex-space-between-container">
                <H1>
                  Audit Ground Truth
                  &nbsp;
                  {spinner}
                </H1>
                {doRender && (
                  <div className="bp3-text-large bp3-text-muted" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    <H5>Summary</H5>
                    <Link to={`/audits/${audit}`}>
                      {clipCheck.name }
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <FormGroup
              label="Ground Truth"
              className="inline-flex m-r-md"
            >
              <div className="bp3-select">
                <select id="user-status" value={groundtruth || ''} onChange={this.handleGroundTruth}>
                  <option value="">--</option>
                  <option value="true">Completed</option>
                  <option value="false">Incomplete</option>
                </select>
              </div>
            </FormGroup>
            <FormGroup
              label="Audit"
              className="inline-flex m-r-md"
            >
              <AuditSelect
                value={audit ? parseInt(audit, 10) : null}
                onChange={this.handleAudit}
                includeReset
              />
            </FormGroup>
            <FormGroup label="Assigned" className="inline-flex m-r-md">
              <Select
                resetOnSelect
                filterable
                itemPredicate={this.handleAssigneePredicate}
                itemRenderer={this.renderAssigneeItem}
                popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
                items={userItems}
                onItemSelect={this.handleAssigned}
                activeItem={assigned}
              >
                <Button
                  text={(userItems.find(x => x.value === assigned) || { name: '' }).name}
                  rightIcon="caret-down"
                />
              </Select>
            </FormGroup>
            <FormGroup
              label="Viewers"
              className="inline-flex m-r-md"
            >
              <div className="bp3-select">
                <select id="user-status" value={viewers} onChange={this.handleViewers}>
                  <option value="-1">--</option>
                  <option value="0">True</option>
                  <option value="1">False</option>
                </select>
              </div>
            </FormGroup>
            {viewButton ? (
              <FormGroup label="">
                <Button text="View Clips" intent="success" onClick={this.handleViewClips} />
              </FormGroup>
            ) : null}
          </div>
        </div>
        {doRender && !staleData && (
          <Fragment>
            <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
              <ReactPaginate
                previousLabel={<Icon icon="chevron-left" />}
                nextLabel={<Icon icon="chevron-right" />}
                breakLabel="..."
                pageCount={Math.ceil((totalValid || 0) / 100)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={page}
                onPageChange={this.handlePageClick}
                containerClassName="bp3-button-group"
                pageClassName="bp3-button"
                previousClassName="bp3-button"
                nextClassName="bp3-button"
                disabledClassName="bp3-disabled"
                breakClassName="bp3-button bp3-disabled"
                activeClassName="bp3-active"
              />
            </div>
            <div className="columns">
              <div className="column">
                {!!((validClips || []).length) && (
                  <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Line ID</th>
                        <th>Name</th>
                        <th>Device</th>
                        <th>Camera</th>
                        <th>Start Time</th>
                        <th>Ground Truth</th>
                        <th>Viewers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...validClips]
                        .sort((a, b) => a.audit.clip_name.localeCompare(b.audit.clip_name))
                        .map(this.renderClip)}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

Videos.propTypes = {
  dispatch: PropTypes.func,
  clips: PropTypes.object,
  location: PropTypes.object,
  users: PropTypes.object,
  orgId: PropTypes.number,
};

export default connect(state => ({
  clips: state.auditClips,
  selectedAudit: state.audit,
  users: state.users,
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(Videos);
