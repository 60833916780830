import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H1, FormGroup, InputGroup, NonIdealState, Icon, MenuItem, Button, Spinner,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { getAuditSites } from 'actions/site';
import { getAuditLocations } from 'actions/location';
import { getAuditOrganizations } from 'actions/organization';

const filterOrg = (query, org) => org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
const renderTag = org => org.name;

class Sites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      orgs: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAuditSites());
    dispatch(getAuditLocations());
    dispatch(getAuditOrganizations());
  }

  @autobind
  onOrgSelect(org) {
    const { orgs } = this.state;
    if (_.some(orgs, x => x.id === org.id)) {
      this.setState({ orgs: orgs.filter(x => x.id !== org.id) });
    } else {
      this.setState({ orgs: [...orgs, org] });
    }
  }

  @autobind
  onOrgClear() {
    this.setState({ orgs: [] });
  }

  @autobind
  onOrgRemove(tag, index) {
    const { orgs } = this.state;
    this.setState({ orgs: orgs.filter((x, i) => i !== index) });
  }

  @autobind
  handleQuery(e) {
    this.setState({
      query: e.target.value,
    });
  }

  @autobind
  renderOrg(org, { handleClick, modifiers }) {
    const { orgs } = this.state;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={`${org.slug.slice(0, 5)}`}
        key={org.id}
        icon={_.some(orgs, x => x.id === org.id) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={org.name}
      />
    );
  }

  @autobind
  renderSite(site, i) {
    const {
      auditLocations,
      auditOrganizations,
    } = this.props;

    const location = _.find(auditLocations.data || [], x => x.id === site.location_id) || {};
    // eslint-disable-next-line max-len
    const organization = _.find(auditOrganizations.data || [], x => x.id === site.organization_id) || {};
    const isSiteActive = (((site || {}).status || {}).devices || {}).up > 0;
    return (
      <React.Fragment key={site.id}>
        <tr key={i}>
          <td>
            <Link to={`/audits/waittimes/summary/${site.id}`}>
              {site.id}
            </Link>
          </td>
          <td>
            <Link to={`/audits/waittimes/summary/${site.id}`}>
              {site.name}
            </Link>
          </td>
          <td>{location.name && location.name.length > 30 ? `${location.name.substring(0, 30)} ...` : location.name }</td>
          <td>
            <Link to={`/organizations/${organization.id}`}>
              {organization.name}
            </Link>
          </td>
          <td>{DateTime.fromISO(site.created).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td className="has-text-centered"><Icon icon="symbol-circle" intent={isSiteActive ? 'success' : 'danger'} /></td>
        </tr>
      </React.Fragment>
    );
  }

  render() {
    const { query, orgs } = this.state;
    const { auditSites, auditOrganizations } = this.props;
    const spinner = auditSites.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;

    const clearOrgButton = orgs.length > 0 ? <Button icon="cross" minimal onClick={this.onOrgClear} /> : null;
    const orgIds = orgs.map(x => x.id);
    const filteredSites = _.chain((auditSites.data || []))
      .filter(q => q.name.toLowerCase()
        .includes(query.toLowerCase()) || q.id.toString().includes(query))
      .filter(r => (
        orgIds && orgIds.length ? orgIds.includes(r.organization_id)
          : r))
      .value();
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>Sites</H1>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Sites or ID's" rightElement={spinner} />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-right">
                    <FormGroup
                      label="Organizations"
                      labelFor="organizations"
                      className="inline-flex m-r-md"
                    >
                      <MultiSelect
                        resetOnSelect
                        items={auditOrganizations.data || []}
                        itemPredicate={filterOrg}
                        itemRenderer={this.renderOrg}
                        tagRenderer={renderTag}
                        noResults={<MenuItem disabled text="No results." />}
                        selectedItems={orgs}
                        onItemSelect={this.onOrgSelect}
                        popoverProps={{ minimal: true }}
                        tagInputProps={{
                          onRemove: this.onOrgRemove,
                          rightElement: clearOrgButton,
                          placeholder: 'All Organizations',
                          className: 'min-width-tag-input',
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((filteredSites || []).length) && !auditSites.pending && <NonIdealState icon="search" title="No Results" />}
            { !!((filteredSites || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Site</th>
                    <th>Location</th>
                    <th>Organization</th>
                    <th>Created</th>
                    <td className="has-text-centered">Status</td>
                  </tr>
                </thead>
                <tbody>
                  {(filteredSites || []).map(this.renderSite)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Sites.propTypes = {
  dispatch: PropTypes.func,
  auditSites: PropTypes.object,
  auditLocations: PropTypes.object,
  auditOrganizations: PropTypes.object,
};

export default connect(state => ({
  auditSites: state.auditSites,
  auditLocations: state.auditLocations,
  auditOrganizations: state.auditOrganizations,
}))(Sites);
