/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button } from '@blueprintjs/core';
import _ from 'lodash';
import { patchVisionParameters, getVisionParameters } from 'actions/device';
import { AppToaster } from 'components/Toaster';

const isAboveLine = (x, y, m, b, endX) => (m === Infinity ? x > endX : y < m * x + b);

const lineFromPoints = (p, q) => {
  if (p && q) {
    const dy = q.y - p.y;
    const dx = q.x - p.x;
    if (dx === 0) return { m: Infinity, b: null };
    const m = dy / dx;
    const b = p.y + (-m) * p.x;
    return { m, b };
  }
  return null;
};

class VisionConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      points: [],
      directionPoint: {},
      configureNewDirection: false,
      previousDirectionPoint: {},
      previousPoints: [],
      submitting: false,
      image: null,
      counting_line: true,
      polygon: [],
      edgeCounter: 0,
      reid_region: false,
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props;
    if (device.id !== prevProps.device.id) {
      this.handleDirectionReset();
      this.handleResetPolygon();
    }
  }

  componentWillUnmount() {
    this.handleDirectionReset();
    this.handleResetPolygon();
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  @autobind
  onMouseClick(e) {
    const { polygon, edgeCounter } = this.state;
    const { x, y } = e.nativeEvent;
    if (this.imageRef.current) {
      const c = this.imageRef.current.getBoundingClientRect();
      const newPoint = {
        x: x - c.x, y: y - c.y, id: edgeCounter,
      };
      const newPolys = [...polygon, newPoint];
      return this.setState({ polygon: newPolys, edgeCounter: edgeCounter + 1 });
    }
    return null;
  }

  @autobind
  setDirectionPoint(e) {
    e.preventDefault();
    const { points } = this.state;
    const { m = null, b = null } = lineFromPoints(points[0], points[1]);
    const answer = isAboveLine(
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY, m, b, points[0].x,
    );
    const directionPoint = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
    this.setState({ direction: answer, directionPoint, draw: true });
  }

  @autobind
  getImageDimensions() {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    const naturalHeight = this.imageRef.current ? this.imageRef.current.naturalHeight : 0;
    return {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    };
  }

  @autobind
  getOrthagonalDirectionEndPoints(xMid, yMid, m, direction) {
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    let x;
    let y;
    let xHalf;
    let yHalf;
    if (m === Infinity) {
      y = clientHeight / 2;
      x = direction ? clientWidth : 0;
      xHalf = Math.min(x, xMid) + Math.abs(x - xMid) / 2;
      yHalf = y;
    } else if (m === 0) {
      y = direction ? 0 : clientHeight;
      x = clientWidth / 2;
      xHalf = x;
      yHalf = Math.min(y, yMid) + Math.abs(y - yMid) / 2;
    } else {
      const inverseSlope = -1 / m;
      const inverseYInt = yMid + (-inverseSlope * xMid);
      if (inverseYInt < 0) {
        y = direction ? 0 : clientHeight;
        x = (y - inverseYInt) / inverseSlope;
      } else if (inverseYInt > clientHeight) {
        y = direction ? 0 : clientHeight;
        x = (y - inverseYInt) / inverseSlope;
      } else if (inverseSlope >= 0 && inverseSlope < 1) {
        x = direction ? 0 : clientWidth;
        y = inverseSlope * x + inverseYInt;
      } else {
        x = direction ? clientWidth : 0;
        y = inverseSlope * x + inverseYInt;
      }
      xHalf = Math.min(x, xMid) + Math.abs(x - xMid) / 2;
      yHalf = (inverseSlope * xHalf) + inverseYInt;
    }
    return { xHalf, yHalf };
  }

  @autobind
  undo() {
    const { polygon } = this.state;
    const newPoly = polygon.slice(0, -1);
    this.setState({ polygon: newPoly, drawEdges: false });
  }

  @autobind
  handleResetPolygon() {
    this.setState({
      polygon: [], edgeCounter: 0, drawEdges: false, viewMask: false, reid_region: false,
    });
  }

  @autobind
  finishPolygon() {
    this.setState({ drawEdges: true });
  }

  @autobind
  handleChangeConfig(counting_line) {
    this.setState({ counting_line });
  }

  @autobind
  handleViewMask() {
    this.setState({ viewMask: true, reid_region: false });
  }

  @autobind
  handleViewReidRegion() {
    this.setState({ reid_region: true, viewMask: false });
  }

  @autobind
  fetchImage() {
    const { rand } = this.state;
    const { device } = this.props;
    const imgObject = new Image();
    imgObject.src = `https://admin.livereachmedia.com/api/v1/devices/${device.id}/camera/preview?r=${rand}`;
    imgObject.onload = this.checkImageLoaded(imgObject);
    imgObject.onerror = () => this.setState({ loading: false });
    this.setState({ loading: true });
  }

  @autobind
  checkImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ image: imgObject, loading: false });
      } else {
        setTimeout(() => {
          this.checkImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  handleSave() {
    const { direction, points, directionPoint } = this.state;
    const {
      dispatch, vision, device,
    } = this.props;
    if (!device.organization_id) {
      return AppToaster.show({
        message: 'Device must be assigned to an organization first.',
        intent: 'danger',
      });
    }
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    const hasPointsDefined = points.length === 2;
    let countRegion;
    if (naturalWidth && naturalHeight && hasPointsDefined) {
      const x0 = hasPointsDefined
        ? (points[0].x / clientWidth) * naturalWidth : 0;
      const y0 = hasPointsDefined
        ? (points[0].y / clientHeight) * naturalHeight : naturalHeight / 2;
      const x1 = hasPointsDefined
        ? (points[1].x / clientWidth) * naturalWidth : naturalWidth;
      const y1 = hasPointsDefined
        ? (points[1].y / clientHeight) * naturalHeight : naturalHeight / 2;
      countRegion = [[[x0, y0], [x1, y1], [x1, y1], [x0, y0]]];
    } else {
      countRegion = ((vision || {}).data || {}).count_region || null;
    }
    const submitDirection = directionPoint.x && directionPoint.y ? direction : true;

    const data = {
      count_region: countRegion,
      direction: submitDirection,
    };
    this.setState({ submitting: true });
    return dispatch(patchVisionParameters(device.id, data))
      .then(() => dispatch(getVisionParameters(device.id)))
      .then(() => this.handleDirectionReset())
      .then(() => {
        this.setState({ configureNewDirection: false });
        AppToaster.show({
          icon: 'tick',
          message: <span>Camera configuration saved</span>,
          intent: 'success',
        });
      })
      .finally(() => this.setState({ submitting: false }));
  }

  @autobind
  handlePolygonSave() {
    this.setState({ submitting: true });
    const { polygon, reid_region } = this.state;
    const {
      dispatch, vision, device,
    } = this.props;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    if (!device.organization_id) {
      return AppToaster.show({
        message: 'Device must be assigned to an organization first.',
        intent: 'danger',
      });
    }
    const polygonPoints = polygon
      .map(pp => [(pp.x / clientWidth) * naturalWidth, (pp.y / clientHeight) * naturalHeight]);
    const realPoints = [...polygonPoints, polygonPoints[0]];
    let data = {};
    if (reid_region) {
      data = {
        reid_region: [realPoints] || (vision || {}).data.reid_region,
      };
    } else {
      data = {
        polygon_mask: [realPoints] || (vision || {}).data.polygon_mask,
      };
    }

    return dispatch(patchVisionParameters(device.id, data))
      .then(() => dispatch(getVisionParameters(device.id)))
      .then(() => {
        this.handleResetPolygon();
        this.handleCancelNewPolygon();
      })
      .then(() => {
        AppToaster.show({
          icon: 'tick',
          message: <span>Region saved</span>,
          intent: 'success',
        });
      })
      .finally(() => this.setState({ submitting: false }));
  }

  @autobind
  handleDirectionReset() {
    return this.setState({
      points: [],
      direction: null,
      draw: false,
      directionPoint: {},
      previousDirectionPoint: {},
      previousPoints: [],
    });
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  handleKeyPress(e) {
    const deviceId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const { points } = this.state;
    const {
      clientWidth, clientHeight, naturalWidth,
    } = this.getImageDimensions();
    if (clientHeight === 0 || clientWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentPoint = points.find(x => x.id === deviceId);
    const filteredPoints = points.filter(y => y.id !== deviceId);
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newMappedPoints = [...filteredPoints, newPoint];
    return this.setState({ points: newMappedPoints });
  }

  @autobind
  handlePolygonKeyPress(e) {
    const pId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const { polygon } = this.state;
    const {
      clientWidth, clientHeight, naturalWidth,
    } = this.getImageDimensions();
    if (clientHeight === 0 || clientWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentPoint = polygon.find(x => x.id === pId);
    const filteredPoints = polygon.filter(y => y.id !== pId);
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newMappedPoints = [...filteredPoints, newPoint];
    return this.setState({ polygon: newMappedPoints.sort((a, b) => a.id - b.id) });
  }

  handleKeyDown(e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  @autobind
  placeStartingPoints() {
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    if (clientHeight && clientWidth) {
      const startingPoints = [
        {
          x: clientWidth,
          y: clientHeight / 2,
          color: '#2887C2',
          invertY: clientHeight / 2,
          movable: true,
          id: 1,
        },
        {
          x: 0,
          y: clientHeight / 2,
          color: '#2887C2',
          invertY: clientHeight / 2,
          movable: true,
          id: 2,
        },
      ];
      return this.setState({ points: startingPoints });
    }
    return null;
  }

  @autobind
  drawPoints(points) {
    return points.map(p => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          height: 6,
          width: 6,
          backgroundColor: p.color,
          top: p.y - 3,
          left: p.x - 3,
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 21,
          cursor: 'move',
        }}
        draggable={p.movable}
        key={p.id}
        id={p.id}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        onDragStart={e => (p.movable ? this.handleDragStart(e, p.id) : null)}
        onKeyDown={e => (p.movable ? this.handleKeyPress(e) : null)}
      />
    ));
  }

  @autobind
  handleDrop(e) {
    if (e.target.id !== 'svg') {
      return null;
    }
    e.preventDefault();
    e.stopPropagation();
    const { points } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (clientHeight === 0 || clientWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const newPoint = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      invertY: Math.abs(clientHeight - e.nativeEvent.offsetY),
      id: parseInt(id, 10),
      movable: true,
      color: '#2887C2',
    };
    const newPoints = [...points.filter(x => x.id !== parseInt(id, 10)), newPoint];
    return this.setState({ points: newPoints });
  }

  @autobind
  drawEdge(p) {
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const firstPoint = p.find(x => x.id === 1);
    const secondPoint = p.find(x => x.id === 2);
    return clientHeight && clientWidth && (
      <svg
        id="svg"
        key={p.id}
        width={clientWidth}
        height={clientHeight}
        style={{
          position: 'absolute', top: 0, left: 0, zIndex: 20,
        }}
        draggable={false}
      >
        <line
          x1={`${firstPoint.x}`}
          y1={`${firstPoint.y}`}
          x2={`${secondPoint.x}`}
          y2={`${secondPoint.y}`}
          stroke="yellow"
          strokeWidth="3px"
        />
      </svg>
    );
  }

  @autobind
  viewCurrentDirection() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    const currentDirection = v.direction;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const count = v.count_region[0];
    const firstPoint = count[0];
    const secondPoint = count[1];
    const x1 = (firstPoint[0] / naturalWidth) * clientWidth;
    const y1 = (firstPoint[1] / naturalHeight) * clientHeight;
    const x2 = (secondPoint[0] / naturalWidth) * clientWidth;
    const y2 = (secondPoint[1] / naturalHeight) * clientHeight;
    const points = [
      {
        x: x1, y: y1, color: '#28A26D', id: 1, movable: false, invertY: Math.abs(clientHeight - y1),
      },
      {
        x: x2, y: y2, color: '#28A26D', id: 2, movable: false, invertY: Math.abs(clientHeight - y2),
      },
    ];
    const { m = null } = lineFromPoints(points[0], points[1]);
    const xMid = (points[0].x + points[1].x) / 2;
    const yMid = (points[0].y + points[1].y) / 2;
    const { xHalf, yHalf } = this.getOrthagonalDirectionEndPoints(xMid, yMid, m, currentDirection);
    this.setState({
      previousPoints: points,
      previousDirectionPoint: { x: xHalf, y: yHalf },
      points: [],
      draw: false,
    });
  }

  @autobind
  viewCurrentMask() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const mask = v.polygon_mask[0];
    const path = mask
      .map(b => [(b[0] / naturalWidth) * 100, (b[1] / naturalHeight) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#28A26D',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.8,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
          pointerEvents: 'none',
          mouseEvents: 'none',
        }}
      />
    );
  }

  @autobind
  viewCurrentReidRegion() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const mask = v.reid_region[0];
    const path = mask
      .map(b => [(b[0] / naturalWidth) * 100, (b[1] / naturalHeight) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#28A26D',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.8,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
          pointerEvents: 'none',
          mouseEvents: 'none',
        }}
      />
    );
  }

  @autobind
  handleConfigureNewDirection() {
    this.handleDirectionReset();
    this.setState({ configureNewDirection: true });
  }

  @autobind
  handleConfigureNewPolygon() {
    this.handleResetPolygon();
    this.setState({ configureNewPolygon: true, viewMask: false, reid_region: false });
  }

  @autobind
  handleConfigureNewReidPolygon() {
    this.handleResetPolygon();
    this.setState({ configureNewPolygon: true, reid_region: true, viewMask: false });
  }

  @autobind
  handleCancelNewPolygon() {
    this.handleResetPolygon();
    this.setState({ configureNewPolygon: false });
  }

  @autobind
  handleCancelNewDirection() {
    this.handleDirectionReset();
    this.setState({ configureNewDirection: false });
  }

  @autobind
  handlePolygonDrop(e) {
    if (!['svg', 'IMG'].includes(e.target.nodeName)) {
      return null;
    }
    e.stopPropagation();
    e.preventDefault();
    const { polygon } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    if (imgHeight === 0 || imgWidth === 0) { _.defer(() => this.forceUpdate()); }
    const newPolyPoint = {
      x: e.nativeEvent.offsetX - 5,
      y: e.nativeEvent.offsetY - 5,
      id: parseInt(id, 10),
    };
    const filteredPolygon = polygon.filter(x => x.id !== parseInt(id, 10));
    const newPoly = _.sortBy([...filteredPolygon, newPolyPoint], x => x.id);
    return this.setState({ polygon: newPoly });
  }

  @autobind
  drawDirection() {
    const {
      draw, points, direction,
    } = this.state;
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const xMid = (points[0].x + points[1].x) / 2;
    const yMid = (points[0].y + points[1].y) / 2;
    const { m = null } = lineFromPoints(points[0], points[1]);
    const { xHalf, yHalf } = this.getOrthagonalDirectionEndPoints(xMid, yMid, m, direction);
    return draw && (
      <svg
        width={clientWidth}
        height={clientHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <marker
          id="arrow2"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
          fill="#fff"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
        <line
          x1={`${xMid + 5}`}
          y1={`${yMid + 5}`}
          x2={`${xHalf}`}
          y2={`${yHalf}`}
          stroke="white"
          strokeWidth="3px"
          markerEnd="url(#arrow2)"
        />
      </svg>
    );
  }

  @autobind
  drawPolygonPoint(polygon) {
    return polygon.map(p => (
      <div
        style={{
          height: 10,
          width: 10,
          backgroundColor: 'yellow',
          top: p.y,
          left: p.x,
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 20,
          cursor: 'move',
        }}
        draggable
        key={p.id}
        id={p.id}
        role="presentation"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        onDragStart={e => this.handleDragStart(e, p.id)}
        onKeyDown={e => this.handlePolygonKeyPress(e)}
      />
    ));
  }

  @autobind
  fillPolygonBackground(polygon) {
    const { clientWidth, clientHeight } = this.getImageDimensions();
    const path = polygon
      .map(p => [((p.x + 5) / clientWidth) * 100, ((p.y + 5) / clientHeight) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#28A26D',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.8,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
        }}
        id="polygon-background"
      />
    );
  }

  @autobind
  drawCurrentDirection() {
    const { previousDirectionPoint, previousPoints } = this.state;
    const { clientWidth, clientHeight } = this.getImageDimensions();
    if (previousPoints && previousPoints.length) {
      const xMid = (previousPoints[0].x + previousPoints[1].x) / 2;
      const yMid = (previousPoints[0].y + previousPoints[1].y) / 2;
      if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
      return clientHeight && clientWidth && (
        <svg
          width={clientWidth}
          height={clientHeight}
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
            fill="#fff"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
          <line
            x1={`${xMid + 5}`}
            y1={`${yMid + 5}`}
            x2={`${previousDirectionPoint.x}`}
            y2={`${previousDirectionPoint.y}`}
            stroke="white"
            strokeWidth="3px"
            markerEnd="url(#arrow)"
          />
        </svg>
      );
    }
    return null;
  }

  @autobind
  directionActions() {
    const {
      points, draw, previousDirectionPoint, previousPoints,
    } = this.state;
    return (
      <Fragment>
        {points.length === 2 && this.drawPoints(points)}
        {points.length === 2 && this.drawEdge(points)}
        {draw && this.drawDirection()}
        {!!previousDirectionPoint && this.drawCurrentDirection()}
        {previousPoints.length === 2 && this.drawPoints(previousPoints)}
        {previousPoints.length === 2 && this.drawEdge(previousPoints)}
      </Fragment>
    );
  }

  @autobind
  polygonActions() {
    const {
      polygon, drawEdges, viewMask, configureNewPolygon, reid_region,
    } = this.state;
    return (
      <Fragment>
        {!!polygon.length && this.drawPolygonPoint(polygon)}
        {drawEdges && this.drawPolygonEdges(polygon)}
        {drawEdges && this.fillPolygonBackground(polygon)}
        {viewMask && !configureNewPolygon && this.viewCurrentMask()}
        {reid_region && !configureNewPolygon && this.viewCurrentReidRegion()}
      </Fragment>
    );
  }

  @autobind
  drawPolygonEdges(polygon) {
    const { clientWidth, clientHeight } = this.getImageDimensions();
    return polygon.map((p, i) => {
      if (i === polygon.length - 1) {
        return (
          <svg key={p.id} width={`${clientWidth}`} height={`${clientHeight}`} style={{ position: 'absolute', top: 0, left: 0 }}>
            <line x1={p.x + 5} y1={p.y + 5} x2={`${polygon[0].x + 5}`} y2={`${polygon[0].y + 5}`} stroke="yellow" strokeWidth="2px" />
          </svg>
        );
      }
      return (
        <svg key={p.id} width={`${clientWidth}`} height={`${clientHeight}`} style={{ position: 'absolute', top: 0, left: 0 }}>
          <line x1={p.x + 5} y1={p.y + 5} x2={`${polygon[i + 1].x + 5}`} y2={`${polygon[i + 1].y + 5}`} stroke="yellow" strokeWidth="2px" />
        </svg>
      );
    });
  }

  @autobind
  renderCamera() {
    const {
      points, image, counting_line,
    } = this.state;
    const dummyImage = 'https://dummyimage.com/300x200/000/fff&text=^+generate+image+above';
    return (
      <div
        id="render-camera-img"
        style={{
          position: 'relative',
          maxWidth: '50%',
          width: '50%',
        }}
        onDoubleClick={e => (points && points.length === 2 ? this.setDirectionPoint(e) : null)}
        role="presentation"
        onDrop={e => (counting_line ? this.handleDrop(e) : this.handlePolygonDrop(e))}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <img
          src={image && image.complete ? image.src : dummyImage}
          alt="no feed"
          className="camera-vision-image"
          ref={this.imageRef}
          draggable={false}
          id="img-feed"
          role="presentation"
          onClick={!counting_line ? this.onMouseClick : null}
        />
        {counting_line ? this.directionActions() : this.polygonActions()}
      </div>
    );
  }


  @autobind
  renderNewDirectionConfiguration() {
    const { vision } = this.props;
    const { submitting } = this.state;
    const v = (vision || {}).data;
    return (
      <div className="camera-instructions">
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {v.count_region && (
            <Button className="camera-config-margin" intent="success" icon="arrow-left" onClick={this.handleCancelNewDirection}>
              Options
            </Button>
          )}
          <Button className="camera-config-margin" icon="reset" intent="danger" onClick={this.handleDirectionReset}>
            Reset
          </Button>
        </div>
        <ol>
          <li>
            Generate default bisecting line.
            &nbsp;&nbsp;
            <Button intent="primary" onClick={this.placeStartingPoints}>
              Bisect
            </Button>
          </li>
          <li>Drag and drop each point to their desired positions.</li>
          <li>
            Double click a half of the frame that represents
            &nbsp;
            <b>IN.</b>
          </li>
          <li>A white direction arrow signifies the direction has been configured.</li>
        </ol>
        <div className="text-right">
          <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick" onClick={this.handleSave}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderDirectionIntructions() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    return (
      <div className="direction-configuration">
        {!!v.count_region && (
          <Button
            onClick={this.viewCurrentDirection}
            className="camera-config-margin"
            icon="eye-open"
            intent="success"
          >
          View Current Direction
          </Button>
        )}
        <Button
          onClick={this.handleConfigureNewDirection}
          icon="plus"
          intent="primary"
        >
          Configure New Direction
        </Button>
      </div>
    );
  }

  @autobind
  renderPolygonInstructions() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    return (
      <div className="direction-configuration">
        {!!v.polygon_mask && (
          <Button
            onClick={this.handleViewMask}
            className="camera-config-margin"
            icon="eye-open"
            intent="success"
          >
          View Current Region of Interest
          </Button>
        )}
        <Button
          onClick={this.handleConfigureNewPolygon}
          icon="plus"
          intent="primary"
        >
          Configure New Region of Interest
        </Button>
      </div>
    );
  }

  @autobind
  renderReidPolygonInstructions() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    return (
      <div className="direction-configuration">
        {!!v.reid_region && (
          <Button
            onClick={this.handleViewReidRegion}
            className="camera-config-margin"
            icon="eye-open"
            intent="success"
          >
          View Current REID Region
          </Button>
        )}
        <Button
          onClick={this.handleConfigureNewReidPolygon}
          icon="plus"
          intent="primary"
        >
          Configure New REID Region
        </Button>
      </div>
    );
  }

  @autobind
  renderFetchImageCamera() {
    const { loading } = this.state;
    return (
      <div id="render-camera-img">
        <div style={{ fontSize: 16 }}>
          <Button onClick={this.fetchImage} loading={loading} icon="media">
            Generate Image
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderCountingLine() {
    const { vision } = this.props;
    const { configureNewDirection } = this.state;

    const v = (vision || {}).data;
    return (
      <Fragment>
        {v.count_region && !configureNewDirection && this.renderDirectionIntructions()}
        {configureNewDirection || !v.count_region ? this.renderNewDirectionConfiguration() : null}
      </Fragment>
    );
  }

  @autobind
  renderPolygon() {
    const { vision } = this.props;
    const { configureNewPolygon } = this.state;

    const v = (vision || {}).data;
    return (
      <Fragment>
        {v.polygon_mask && !configureNewPolygon && this.renderPolygonInstructions()}
        {!configureNewPolygon && this.renderReidPolygonInstructions()}
        {configureNewPolygon || (!v.polygon_mask || !v.reid_region) ? this.renderNewPolygonConfiguration() : null}
        {/* {configureNewPolygon || (!v.polygon_mask && !v.reid_region) ? this.renderNewPolygonConfiguration() : null} */}
      </Fragment>
    );
  }

  @autobind
  renderNewPolygonConfiguration() {
    const {
      counting_line, drawEdges, submitting, polygon, reid_region,
    } = this.state;
    const { vision } = this.props;
    const v = (vision || {}).data;
    return (
      <div className="camera-instructions">
        <div className="flex-space-between-container">
          <div>
            {(v.polygon_mask || v.reid_region) && (
              <Button
                icon="arrow-left"
                onClick={this.handleCancelNewPolygon}
              >
                Options
              </Button>
            )}
          </div>
          <div>
            <Button
              intent="danger"
              onClick={!counting_line && this.handleResetPolygon}
              icon="reset"
            >
              Reset
            </Button>
          </div>
        </div>
        <div style={{ margin: '20px 0px' }}>Click points on the image to create a region of interest</div>
        <div style={{ marginTop: 10, display: 'flex' }}>
          <Button
            intent="warning"
            className="camera-config-margin"
            icon="undo"
            onClick={!counting_line && this.undo}
          >
            Undo
          </Button>
          <Button
            intent="success"
            icon="draw"
            disabled={counting_line || polygon.length < 3}
            onClick={this.finishPolygon}
          >
            Fill Region
          </Button>
        </div>
        {drawEdges && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button
              loading={submitting}
              icon="tick"
              intent="primary"
              onClick={this.handlePolygonSave}
            >
              {reid_region ? 'Submit Reid Region' : 'Submit'}
            </Button>
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderToggle() {
    const { counting_line } = this.state;
    return (
      <div>
        <Button
          intent={counting_line ? 'success' : 'none'}
          onClick={() => this.handleChangeConfig(true)}
          className="camera-config-margin"
          rightIcon="arrows-horizontal"
        >
          Counting Line
        </Button>
        <Button
          intent={!counting_line ? 'success' : 'none'}
          className="camera-config-margin"
          onClick={() => this.handleChangeConfig(false)}
          rightIcon="polygon-filter"
        >
          Polygon Regions
        </Button>
      </div>
    );
  }

  @autobind
  renderConfig() {
    const {
      device, vision,
    } = this.props;
    const { counting_line } = this.state;

    const { image } = this.state;
    const v = (vision || {}).data;
    const doRender = v.device_id === device.id;

    return doRender && (
      <Fragment>
        <div className="direction-configured-container">
          <div>{this.renderFetchImageCamera()}</div>
          <div>{this.renderToggle()}</div>
        </div>
        <div
          className="flex-start-container"
          style={{
            marginTop: 20,
            opacity: image && image.complete ? 1 : 0.5,
            pointerEvents: image && image.complete ? 'auto' : 'none',
          }}
        >
          {this.renderCamera()}
          {counting_line ? this.renderCountingLine() : this.renderPolygon()}
        </div>
      </Fragment>
    );
  }

  @autobind
  renderNoConfig() {
    return (
      <div style={{ fontSize: 16 }}>
        Vision Configuration Unavailable
      </div>
    );
  }

  render() {
    const { device } = this.props;
    const hasConnectedIap = !!device.via;
    return hasConnectedIap && device.in_maintenance ? this.renderConfig() : this.renderNoConfig();
  }
}

VisionConfig.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  vision: PropTypes.object,
};

export default connect(state => ({
  vision: state.vision,
}))(VisionConfig);
