/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import {
  Button, HTMLTable, Icon, Checkbox, Tag, FormGroup, InputGroup,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';

const INTENT = {
  site: 'warning',
  organization: 'primary',
  location: 'success',
};

const centerStyle = { textAlign: 'center' };

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      query: '',
      status: '',
    };
  }

  @autobind
  onRowSelect(event, site) {
    const { checked } = this.state;
    if (event.target.checked) {
      checked.push(site.id);
    }
    if (!event.target.checked) {
      return this.setState({ checked: [...checked].filter(x => x !== site.id) });
    }
    return this.setState({ checked });
  }

  @autobind
  onSelectAll(e, data) {
    if (!e.target.checked) return this.setState({ checked: [] });
    return this.setState({ checked: data.map(x => x.id) });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleStatus(e) {
    this.setState({ status: e.target.value });
  }

  @autobind
  statusFilter(z) {
    const { status } = this.state;
    const { up } = (z.status || {}).devices || {};
    if (status === 'active') return up > 0;
    if (status === 'inactive') return up === 0;
    return true;
  }

  @autobind
  rowRenderer(zone, i) {
    const { locations } = this.props;
    const { checked } = this.state;
    return (
      <tr key={i}>
        <td>
          <Checkbox
            checked={checked.includes(zone.id)}
            large
            onChange={event => this.onRowSelect(event, zone)}
          />
        </td>
        <td><Link to={`/zones/${zone.id}`}>{zone.id}</Link></td>
        <td><Link to={`/zones/${zone.id}`}>{zone.name}</Link></td>
        <td style={{ maxWidth: 200 }}>
          <Link to={`/locations/${zone.location_id}`}>
            {((locations.data || []).find(x => x.id === zone.location_id) || {}).name}
          </Link>
        </td>
        <td>{DateTime.fromISO(zone.created).toFormat('f')}</td>
        <td style={centerStyle}>
          <Icon icon="symbol-circle" intent={((zone.status || {}).devices || {}).up > 0 ? 'success' : 'danger'} />
        </td>
      </tr>
    );
  }

  @autobind
  analyze() {
    const { dispatch, card } = this.props;
    const { checked } = this.state;
    dispatch(push(`/dashboard/${card.dash_id}/card/${card.id}/analyze?${qs.stringify({ z: checked })}`));
  }

  render() {
    const {
      organizations, locations, dispatch, match, card, zones,
    } = this.props;
    const { checked, query, status } = this.state;

    const isOrgCard = card.kind === 'organization';
    const isLocationCard = card.kind === 'location';

    const data = (zones.data || []).filter((x) => {
      if (isOrgCard) return x.organization_id === card.kind_id;
      if (isLocationCard) return x.location_id === card.kind_id;
      return x.site_id === card.kind_id;
    })
      .filter(y => y.name.toLowerCase().includes(query.toLowerCase()))
      .filter(this.statusFilter);

    const title = (() => {
      if (isOrgCard) return ((organizations.data || []).find(x => x.id === card.kind_id) || {}).name;
      if (isLocationCard) return ((locations.data || []).find(x => x.id === card.kind_id) || {}).name;
      return ((zones.data || []).find(x => x.id === card.kind_id) || {}).name;
    })();
    return (
      <Fragment>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Icon
            className="audit-back-icon"
            icon="circle-arrow-left"
            iconSize={30}
            onClick={() => dispatch(push(`/dashboard/${match.params.id}`))}
          />
          <div>
            <Tag style={{ fontSize: 18 }} minimal large>
              {_.upperFirst(`${card.kind}:`)}
            </Tag>
            &nbsp;
            <Tag style={{ fontSize: 18 }} intent={INTENT[card.kind]} large minimal>
              {title}
            </Tag>
          </div>
          <div>
            <Button onClick={this.analyze} icon="chart" text={`Analyze (${checked.length})`} intent="warning" disabled={!checked.length} />
          </div>
        </div>
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Zones" />
        </FormGroup>
        <FormGroup className="has-text-right">
          <div className="bp3-select">
            <select value={status} onChange={this.handleStatus}>
              <option value="">Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </FormGroup>
        <HTMLTable interactive striped style={{ marginTop: 10 }}>
          <thead>
            <tr>
              <th>
                <Checkbox large checked={data.length === checked.length} onChange={e => this.onSelectAll(e, data)} />
              </th>
              <th>Zone ID</th>
              <th>Zone</th>
              <th>Location</th>
              <th>Created</th>
              <th style={centerStyle}>Status</th>
            </tr>
          </thead>
          <tbody>
            {!!data.length && data.filter(this.statusFilter).map(this.rowRenderer)}
          </tbody>
        </HTMLTable>
        <br />
      </Fragment>
    );
  }
}

Overview.propTypes = {
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  locations: PropTypes.object,
  organizations: PropTypes.object,
  match: PropTypes.object,
  card: PropTypes.object,
};

export default connect(state => ({
  locations: state.locations,
  organizations: state.organizations,
  zones: state.zones,
}))(Overview);
