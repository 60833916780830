/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  H3, Spinner, NonIdealState, Button, Card, Elevation, Icon,
} from '@blueprintjs/core';

import { viewExistingUploads, deleteExistingUpload } from 'actions/device';

import UploadedClips from './clips';

class ViewUploads extends Component {
  constructor(props) {
    super(props);
    this.state = { uploadId: null };
  }

  componentDidMount() {
    const { dispatch, device, serial } = this.props;
    dispatch(viewExistingUploads(device.id, serial));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, device, serial } = this.props;
    if (device.id !== prevProps.device.id || serial !== prevProps.serial) {
      dispatch(viewExistingUploads(device.id, serial));
    }
  }

  @autobind
  setUploadId(uploadId) {
    this.setState({ uploadId }, () => window.scrollTo(0, document.body.scrollHeight));
  }

  @autobind
  deleteUpload(uploadId) {
    const { dispatch, device, serial } = this.props;
    // eslint-disable-next-line max-len
    dispatch(deleteExistingUpload(device, serial, uploadId)).then(dispatch(viewExistingUploads(device, serial)));
  }

  @autobind
  renderUpload(upload, i) {
    const {
      upload_id, start_time, end_time, upload_count, current_count, creation_date,
    } = upload;
    const { uploadId } = this.state;
    return (
      <Fragment key={i}>
        <tr key={i}>
          <td>{upload_id}</td>
          <td>{DateTime.fromISO(start_time).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{DateTime.fromISO(end_time).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{DateTime.fromISO(creation_date).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{upload_count}</td>
          <td>{current_count}</td>
          <td>
            {current_count > 0 && (
              <Button
                intent={uploadId === upload_id ? 'warning' : 'primary'}
                minimal
                outlined
                onClick={() => this.setUploadId(upload_id)}
                small
                icon="mobile-video"
              >
                Clips
              </Button>
            )}
          </td>
          <td>
            <Icon
              minimal
              outlined
              onClick={() => this.deleteUpload(upload_id)}
              small
              icon="cross"
            />
          </td>
        </tr>
      </Fragment>
    );
  }

  render() {
    const { uploads, serial } = this.props;
    const { uploadId } = this.state;
    if (uploads.pending && !uploads.data) {
      return <Spinner size={80} />;
    }
    const selectedUpload = (uploads.data || []).find(x => x.upload_id === uploadId);
    return (
      <React.Fragment>
        <Card elevation={Elevation.TWO} style={{ marginBottom: 20 }}>
          <div className="columns">
            <div className="column">
              <H3>
                {`View Uploads: ${serial}`}
                &nbsp;&nbsp;
                {!!uploads.pending && <Spinner size={30} className="rdtCounters" />}
              </H3>
              <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                {!!(uploads.data || []).length && (
                  <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
                    <thead>
                      <tr>
                        <td>Upload ID</td>
                        <td>Start Time</td>
                        <td>End Time</td>
                        <td>Created</td>
                        <td>Total Files</td>
                        <td>Uploaded Files</td>
                        <td />
                      </tr>
                    </thead>
                    <tbody>
                      {((uploads.data || [])
                        .sort((a, b) => (b.start_time).localeCompare(a.start_time)))
                        .map(this.renderUpload)}
                    </tbody>
                  </table>
                )}
              </div>
              {!(uploads.data || []).length && !uploads.pending && <NonIdealState icon="search" title="No Uploads" />}
            </div>
          </div>
        </Card>
        {!!selectedUpload && <UploadedClips upload={selectedUpload} />}
      </React.Fragment>
    );
  }
}

ViewUploads.propTypes = {
  dispatch: PropTypes.func,
  serial: PropTypes.string,
  device: PropTypes.object,
  uploads: PropTypes.object,
};

export default connect(state => ({
  uploads: state.cameraUploads,
  clips: state.clipsByTime,
}))(ViewUploads);
