import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PermissionedRoute = ({
  path,
  component: Component,
  permissions: Permissions,
  renderNoPermissions,
  receivedProps,
}) => (
  <Route
    path={path}
    render={props => (
      <Permissions renderNoPermissions={renderNoPermissions} renderWhilePending>
        <Component {...props} receivedProps={receivedProps} />
      </Permissions>
    )}
  />
);

PermissionedRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.any,
  permissions: PropTypes.any,
  renderNoPermissions: PropTypes.func,
};

export default PermissionedRoute;
