import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  Card, Elevation, Button, Spinner,
} from '@blueprintjs/core';
import { RangePicker } from 'antd/lib/date-picker';
import { DateTime } from 'luxon';
import moment from 'moment';

import { getTrainerResources } from 'actions/training';
import GPUMemChart from './gpuMemChart';
import GPUTempChart from './gpuTempChart';
import CPUChart from './cpuChart';
import RamChart from './ramChart';
import DiskChart from './diskChart';
import GPUProcesses from './gpuProcesses';

class TrainerResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: DateTime.utc().minus({ hours: 12 }),
      endDate: DateTime.utc(),
    };
  }

  componentDidMount() {
    const { learners } = this.props;
    if (learners.length) {
      this.getResources(learners);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { learners } = this.props;
    if (learners.length !== nextProps.learners.length) {
      this.getResources(nextProps.learners);
    }
  }

  @autobind
  getResources(learners) {
    const { dispatch } = this.props;
    const { startDate, endDate } = this.state;
    const start = startDate.startOf('second').toISO({ suppressMilliseconds: true });
    const end = endDate.startOf('second').toISO({ suppressMilliseconds: true });
    learners.forEach((l) => {
      dispatch(getTrainerResources(l.id, start, end));
    });
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    });
  }

  @autobind
  handleDateOk() {
    const { learners } = this.props;
    this.getResources(learners);
  }

  @autobind
  renderCharts(name, resource) {
    if (resource.resolved && !resource.failed) {
      const { dispatch } = this.props;
      let canTrain = false;
      const gpus = resource.response.active_training;
      if (gpus) {
        Object.keys(gpus).forEach((g) => {
          if (!gpus[g]) {
            canTrain = true;
          }
        });
      }
      return (
        <Fragment>
          <div className="row">
            {resource && resource.response && (
              <p style={{ fontSize: 24, display: 'inline' }}><b>{name}</b></p>
            )}
            <Button
              icon="applications"
              intent={canTrain ? 'success' : 'warning'}
              style={{ float: 'right' }}
              disabled={!canTrain}
              onClick={() => dispatch(push(`/model_training/${name}/tasks`))}
            >
              Begin Training
            </Button>
          </div>
          <div className="columns is-multiline" style={{ marginTop: 10 }}>
            {resource && resource.response && (
              <div className="column is-half">
                <GPUMemChart data={resource.response.status[0]} />
              </div>
            )}
            {resource && resource.response && (
              <div className="column is-half">
                <GPUTempChart data={resource.response.status[0]} />
              </div>
            )}
            {resource && resource.response && (
              <div className="column is-half">
                <CPUChart data={resource.response.status[0]} />
              </div>
            )}
            <br />
            {resource && resource.response && (
              <div className="column is-half">
                <RamChart data={resource.response.status[0]} />
              </div>
            )}
            {resource && resource.response && (
              <div className="column is-half">
                <DiskChart data={resource.response.status[0]} />
              </div>
            )}
            {resource && resource.response && (
              <div className="column is-half">
                <GPUProcesses data={resource.response.gpu} />
              </div>
            )}
          </div>
          <br />
        </Fragment>
      );
    }
    if (!resource.resolved) return <Spinner size="50" />;
    return null;
  }

  render() {
    const { startDate, endDate } = this.state;
    const { trainerResources } = this.props;
    const names = Object.keys(trainerResources);
    let resolved = false;
    names.forEach((n) => {
      if (trainerResources[n].resolved) {
        resolved = true;
      }
    });
    if (trainerResources && !resolved) {
      return <Spinner size="100" />;
    }
    return (
      <div className="container">
        <Card elevation={Elevation.FOUR}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RangePicker
              value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
              format="lll"
              style={{ width: '500px' }}
              showTime
              onChange={this.handleDateChange}
              onOk={this.handleDateOk}
              allowClear={false}
            />
          </div>
        </Card>
        <br />
        {names.length ? names.map(x => this.renderCharts(x, trainerResources[x])) : null}
      </div>
    );
  }
}

TrainerResources.propTypes = {
  dispatch: PropTypes.func,
  learners: PropTypes.array,
  trainerResources: PropTypes.object,
};

export default connect(state => ({
  trainerResources: state.trainerResources,
  trainingDatasets: state.trainingDatasets,
}))(TrainerResources);
