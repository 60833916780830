import qs from 'query-string';
import {
  GET_NVRS, GET_NVR, SAVE_NVR, NVR_METRICS, GET_NVR_RELEASES,
  GET_NVR_RELEASE, GET_NVR_SERVICES, CREATE_NVR_RELEASE, EDIT_NVR_RELEASE,
} from '../constants';

export const getNvrs = (query, assigned, monitored, organization, from, limit, version) => ({
  type: GET_NVRS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/nvrs?${qs.stringify({
        query, assigned, organization, from, limit, monitored, version,
      })}`,
    },
  },
});

export const getNvr = id => ({
  type: GET_NVR,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/nvrs/${id}?cameras=true`,
    },
  },
});

export const saveNvr = (id, data) => ({
  type: SAVE_NVR,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/nvrs/${id}`,
    },
  },
});

export const nvrMetrics = (nvrId, start, end) => ({
  type: NVR_METRICS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/nvrs/${nvrId}/metrics?${qs.stringify({ start, end })}`,
    },
  },
});

export const getReleases = () => ({
  type: GET_NVR_RELEASES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/nvrs/releases',
    },
  },
});

export const getRelease = releaseId => ({
  type: GET_NVR_RELEASE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/nvrs/releases/${releaseId}`,
    },
  },
});

export const getServices = () => ({
  type: GET_NVR_SERVICES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/nvrs/services',
    },
  },
});

export const createRelease = data => ({
  type: CREATE_NVR_RELEASE,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/admin/nvrs/releases',
    },
  },
});

export const editRelease = (releaseId, data) => ({
  type: EDIT_NVR_RELEASE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/nvrs/releases/${releaseId}`,
    },
  },
});
