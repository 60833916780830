import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './site_list';
import WaitTimeSummary from './summary';
import ClipPage from './Clip_Page';


export default () => (
  <Switch>
    {/* <Route path="/:id" render={(props) => <SiteAudit {...props} />}/> */}
    <Route path="/audits/waittimes/summary/:id" component={WaitTimeSummary} />
    <Route path="/audits/waittimes/site_audit/:id" component={ClipPage} />
    <Route component={List} />
  </Switch>
);
