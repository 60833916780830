/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  H1, Spinner, Icon, H3, FormGroup, Button, InputGroup, Classes, Popover, Drawer, Card,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { AppToaster } from 'components/Toaster';
import {
  extractMergePbuf, getReidTask, getReidTasks, getReidMergeTaskOutput, deleteReidMergeOutput, postMergeReidTaskGroundTruth,
} from 'actions/reid';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

const PatchItem = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    isSelected, patch, handleSelect,
  } = props;
  const colorborder = () => {
    if (isSelected) {
      return '0.5em solid green';
    }
    return '0.5em solid white';
  };

  return (
    <div style={{ marginRight: '1em' }}>
      <Card elevation="3" style={{ border: colorborder(), marginBottom: '1em' }} onClick={() => handleSelect(patch)}>
        <img
          src={patch.url ? patch.url : 'https://dummyimage.com/300x200/000/fff&text=^+image+not+found'}
          height="50px"
          width="auto"
          alt=""
          id="img-feed"
        />
      </Card>
    </div>
  );
};

const STATUS_MAP = {
  0: 'Incomplete',
  1: 'Complete',
  4: 'Paused',
};

class MergeTaskSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isExtracted: false,
      threshold: 1,
      extractLoading: false,
      saveLoading: false,
      clearLoading: false,
      showHelp: false,
      selected: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  @autobind
  getData() {
    const { dispatch, match, reidTasks } = this.props;
    const { id } = match.params;
    // const { selected } = this.state;
    dispatch(getReidMergeTaskOutput(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ data: payload.data.content, selected: [payload.data.content[0]], source_patch: payload.data.content[0] });
      }
    });
    dispatch(getReidTask(id)).then(({ payload }) => {
      if (payload && payload.data.content) {
        this.setState({ isExtracted: payload.data.content.extracted, threshold: payload.data.content.distance_threshold });
      }
    });

    if (!(reidTasks.data || []).length) {
      dispatch(getReidTasks());
    }
  }

  @autobind
  async clearOutput() {
    const { dispatch, match } = this.props;
    this.setState({ clearLoading: true });

    return dispatch(deleteReidMergeOutput(match.params.id))
      .then(() => this.getData())
      .finally(() => this.setState({ clearLoading: false, isExtracted: false }));
  }

  @autobind
  delayGet(time, f) {
    return new Promise(resolve => setTimeout(resolve.bind(null, f), time));
  }

  @autobind
  handleThreshold(e) {
    this.setState({ threshold: e.target.value });
  }

  @autobind
  handleHelp() {
    this.setState({ showHelp: true });
  }

  @autobind
  handleCloseHelp() {
    this.setState({ showHelp: false });
  }

  @autobind
  handleSelect(patch) {
    const { selected, source_patch } = this.state;
    if (patch.source_track === source_patch.source_track && patch.source_task === source_patch.source_task) {
      return;
    }
    const index = selected.findIndex(x => x.source_track === patch.source_track && x.source_task === patch.source_task);

    if (index > -1) {
      const tmp = [...selected];
      tmp.splice(index, 1);
      this.setState({ selected: tmp });
    } else {
      this.setState({ selected: [...selected, patch] });
    }
  }

  @autobind
  async handleSave() {
    const {
      selected,
    } = this.state;
    const {
      dispatch, match,
    } = this.props;
    if (selected.length === 0) {
      return AppToaster.show({
        icon: 'cross',
        message: <span>Select atleast 1 patch</span>,
        intent: 'danger',
      });
    }
    this.setState({ saveLoading: true });

    const groundtruths = selected
      ? (selected || []).map(({ source_task: st, source_track: str, ...rest }) => ({
        ...rest,
        task_id: st,
        track_id: str,
      })) : [];
    const payload = {
      ground_truths: groundtruths,
    };
    return dispatch(postMergeReidTaskGroundTruth(match.params.id, payload))
      .then(() => this.getData())
      .then(() => this.setState({ saveLoading: false, selected: [] }));
  }

  @autobind
  async extractProtobuf() {
    const { dispatch, match } = this.props;
    const { threshold } = this.state;
    this.setState({ extractLoading: true });

    return dispatch(extractMergePbuf(match.params.id, threshold))
      .then(() => (this.delayGet(5000).then(() => this.getData())))
      .finally(() => this.setState({ extractLoading: false, isExtracted: true }));
  }

  render() {
    const {
      reidMergeOutput, reidTask, reidTasks, match, dispatch,
    } = this.props;
    const {
      isExtracted, extractLoading, threshold, clearLoading, showHelp, selected, data, saveLoading, source_patch,
    } = this.state;
    if (
      (reidTask.pending && !reidTask.data)
      || (reidMergeOutput.pending && !reidMergeOutput.data)
    ) {
      return <Spinner size={100} />;
    }
    const selectedReidTask = reidTask.data || (reidTasks || {}).data.find(x => x.task_id === parseInt(match.params.id, 10));
    const {
      task_id, title, EndTime, task_status, task_type, task_ids,
    } = selectedReidTask || {};
    const reidStatus = task_status === 0 && EndTime !== null ? 'Failed' : STATUS_MAP[task_status];
    return (
      <div className="container" style={{ marginBottom: 20 }}>
        <div>
          <div className="reid-header-container">
            <H1>
              <Icon
                className="reid-back-icon"
                icon="circle-arrow-left"
                iconSize={30}
                style={{ marginRight: '1em' }}
                onClick={() => dispatch(push('/reid'))}
              />
              {title}
            </H1>
          </div>
          <div className="bp3-text-medium bp3-text-muted reid-header" style={{ margin: '1em 0 1em 0' }}>
            {`ID: ${task_id}`}
            <Dot />
            {`Type: ${task_type}`}
            <Dot />
            {`Merge Task IDs: ${task_ids}`}
            <Dot />
            {`Status: ${reidStatus}`}
          </div>
          <div>
            {reidStatus !== 'Failed' && task_status !== 1 && (
            <Button
              title={task_status === 4 ? 'Resume ReidTask' : 'Pause ReidTask'}
              style={{ marginRight: '10px' }}
              icon={task_status === 4 ? 'play' : 'pause'}
              onClick={() => (task_status === 4
                ? this.resumeReidTask(title)
                : this.pauseReidTask(title))}
            />
            )}
            <Button title="Save Ground Truth" loading={(saveLoading)} onClick={this.handleSave} disabled={(selected.length === 0)} intent="primary" type="submit" icon="tick">Save</Button>
            <Button style={{ margin: '0px 0px 0px 10px' }} disabled={(!isExtracted || !(reidStatus === 'Complete'))} onClick={this.clearOutput} loading={(clearLoading)} intent="primary" icon="trash">Clear Output</Button>
            <Popover
              interactionKind="click"
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              placement="bottom"
              content={(
                <div style={{ height: '10em', width: '10em', margin: '1em' }}>
                  <H3>Extract Reid Task</H3>
                  <div>
                    <FormGroup label="Distance Threshold">
                      <InputGroup value={threshold} onChange={this.handleThreshold} type="number" />
                    </FormGroup>
                    <Button loading={extractLoading} onClick={this.extractProtobuf} intent="primary" type="submit" icon="tick">
                    Extract
                    </Button>
                  </div>
                </div>
              )}
            >
              <Button style={{ margin: '0px 0px 0px 10px' }} disabled={(isExtracted || !(reidStatus === 'Complete'))} loading={(extractLoading)} intent="primary" icon="archive">Extract Protobuf</Button>
            </Popover>
            <Button title="Help" icon="help" className="m-l-sm" onClick={this.handleHelp} />
          </div>
        </div>
        <Drawer
          icon="info-sign"
          onClose={this.handleCloseHelp}
          title="Help"
          isOpen={showHelp}
          size={Drawer.SIZE_SMALL}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={`bp3-running-text ${Classes.DIALOG_BODY}`}>
              <H3>Instructions</H3>
              <div>
                <div>
                  Overview:
                  <ul>
                    <li>
                          Left-hand column: patches captured from the entry clip(s).
                    </li>
                    <li>
                          Right-hand column: patches captured from the exit clip(s).
                    </li>
                  </ul>
                </div>
                <div>
                  Procedure:
                  <ol>
                    <li>
                    Accept all patches that are of the same person.
                    </li>
                    <li>
                    Reject all patches that are not the same person as in the accepted patches.
                    </li>
                    <li>
                    Click save when finished.
                    </li>
                  </ol>
                </div>
                <div>
                  Considerations:
                  <ol>
                    <li>
                    Each patch should have only
                      <strong> one subject.</strong>
                      <ol type="a">
                        <li>
                        If there is more than one person in the patch, it must be clear which person is the subject; if it is not clear, reject that patch.
                        </li>
                        <li>
                        If there is a clear subject in the patch but another person or object in the foreground is blocking that person, then accept the patch only if at least approximately 50% of the subject person is visible.
                        </li>
                        <li>
                        If the patch seems to be centered more around the floor / background than the person, then that person cannot be considered the subject and the patch should be rejected
                        </li>
                      </ol>
                    </li>
                    <li>
                    Prefer accepting people who appear both in the entry and exit clips
                      <ol type="a">
                        <li>
                        If there is no such person, then choose the person who has the highest number of qualified patches.
                        </li>
                        <li>
                        If there are no patches either on in the entry clip(s) or the exit clip(s), again, accept the person who has the highest number of qualified patches.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER} />
        </Drawer>
        <div>
          {
            (source_patch && (
            <Card
              elevation="4"
              style={{
                display: 'inline-block', position: 'fixed', float: 'left', left: '0',
              }}
            >
              <div>
              Matches:
                {' '}
                { selected.length - 1}
              </div>
              <img
                src={source_patch.url ? source_patch.url : 'https://dummyimage.com/300x200/000/fff&text=^+image+not+found'}
                height="50px"
                width="auto"
                alt=""
                id="img-feed"
              />
            </Card>
            ))
          }
        </div>
        <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
          {data.map(patch => (
            <li key={patch.patch_id}>
              <PatchItem patch={patch} isSelected={(selected.filter(x => x.source_task === patch.source_task && x.source_track === patch.source_track).length > 0)} handleSelect={this.handleSelect} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

MergeTaskSummary.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  reidMergeOutput: PropTypes.object,
  reidTask: PropTypes.object,
  reidTasks: PropTypes.object,
};

export default connect(state => ({
  reidMergeOutput: state.reidMergeOutput,
  reidTask: state.reidTask,
  token: state.currentUser.token.access_token,
  reidTasks: state.reidTasks,
  tracks: state.tracks,
}))(MergeTaskSummary);
