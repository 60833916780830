import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Tag, Spinner, H5, H3,
} from '@blueprintjs/core';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import { randomColor } from 'randomcolor';

import { compareAudit } from 'actions/audit';
import Line from '../LineChart';

const fmtr = x => numbro(x).format('0,0');

class Visualize extends Component {
  async componentDidMount() {
    const { dispatch, compareIds } = this.props;
    if (compareIds.length) {
      return Promise.all(compareIds.map(x => dispatch(compareAudit(`v-${x}`, x))));
    }
    return null;
  }

  @autobind
  renderChartRow(item) {
    const {
      label, name, model, borderColor,
    } = item;
    return (
      <tr key={label}>
        <td><Tag style={{ backgroundColor: borderColor }} /></td>
        <td>{label}</td>
        <td>{name}</td>
        <td>{model}</td>
      </tr>
    );
  }

  @autobind
  renderCharts() {
    const { chartData, audits } = this.props;
    const datasets = _.flatten(Object.values(
      _.mapValues(chartData, (a, b) => {
        const {
          audit_name = '',
          model_id = '',
        } = (audits || []).find(o => o.audit_id === parseInt(b.split('-')[1], 10));
        return [
          {
            label: b.split('-')[1],
            data: [...a.response].sort((c, d) => c.clip_name.localeCompare(d.clip_name))
              .filter(f => Number.isInteger(f.true_occupancy))
              .map(m => m.model_occupancy),
            fill: false,
            pointRadius: 0.5,
            lineTension: 0,
            borderWidth: 1,
            borderColor: randomColor({ format: 'hsl', hue: 'random' }),
            name: audit_name,
            model: model_id,
          },
        ];
      }),
    ));
    if (datasets[0] && datasets[0].data.length) {
      const labels = Array(datasets[0].data.length)
        .fill().map((x, i) => i + 1);
      const truth = (Object.values(chartData)[0] || {}).response;
      const trueDataset = {
        label: 'True',
        data: [...truth].sort((t, f) => t.clip_name.localeCompare(f.clip_name))
          .filter(f => Number.isInteger(f.true_occupancy))
          .map(x => x.true_occupancy),
        fill: false,
        pointRadius: 0.5,
        lineTension: 0,
        borderWidth: 1,
        borderColor: '#fff',
      };
      const chartDataset = {
        datasets: [trueDataset, ...datasets],
        labels,
      };
      return (
        <div style={{ marginTop: 20 }}>
          <table className={classNames('bp3-html-table bp3-html-table-striped', { 'bp3-skeleton': false })}>
            <thead>
              <tr>
                <th />
                <th>Audit ID</th>
                <th>Name</th>
                <th>Model</th>
              </tr>
            </thead>
            <tbody>
              {(datasets || []).map(this.renderChartRow)}
            </tbody>
          </table>
          <div style={{ marginTop: 20 }}>
            <H3>Models vs True Occupancy</H3>
          </div>
          <div style={{ height: 500 }}>
            <Line
              yFmtr={fmtr}
              data={() => chartDataset}
              legend
              position="top"
            />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { chartData, audits } = this.props;
    const loaded = _.every(chartData, { resolved: true });
    if (!loaded || !audits.length) {
      return (
        <div className="center-text">
          <H5>Loading Audit Visualization...</H5>
          <Spinner size={100} />
        </div>
      );
    }
    return this.renderCharts();
  }
}

Visualize.propTypes = {
  dispatch: PropTypes.func,
  chartData: PropTypes.object,
  audits: PropTypes.array,
};

export default connect((state, { compareIds }) => ({
  chartData: _.pickBy(state.compareAudit, (v, k) => compareIds.includes(k.split('-')[1])),
}))(Visualize);
