import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { autobind } from 'core-decorators';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const asTrue = () => true;
const defaultRenderer = o => (o ? (o.name || o.label || '') : '');
const defaultFilter = (query, item) => defaultRenderer(item)
  .toLowerCase()
  .indexOf(query.toLowerCase()) >= 0;

class SelectInput extends Component {
  @autobind
  onItemSelect(o) {
    const { input } = this.props;
    input.onChange(o);
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    const { input, selectProps } = this.props;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const itemRenderer = (selectProps || {}).itemRenderer || defaultRenderer;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        icon={item.id === (input.value || {}).id ? 'tick' : 'blank'}
        onClick={handleClick}
        text={itemRenderer(item)}
      />
    );
  }

  render() {
    const {
      input,
      label,
      help,
      helpIntent,
      selectProps,
      items,
      placeholder,
      loading,
      onQueryChange,
      onOpening,
      meta: { touched, error },
      filterable,
      disabled,
    } = this.props;
    const itemRenderer = (selectProps || {}).itemRenderer || defaultRenderer;
    return (
      <FormGroup
        intent={(touched && error) ? 'danger' : (helpIntent || undefined)}
        helperText={HelperText(touched, help, error)}
        label={label || undefined}
        labelFor="text-input"
      >
        <Select
          noResults={<MenuItem disabled text="No results." />}
          filterable={typeof filterable === 'undefined' ? true : filterable}
          resetOnSelect
          itemRenderer={this.renderItem}
          itemPredicate={loading ? asTrue : defaultFilter}
          popoverProps={{
            minimal: true, fill: true, popoverClassName: 'popover-sz', onOpening,
          }}
          {...(selectProps || {})}
          itemDisabled={loading ? asTrue : undefined}
          items={loading ? [{ id: null, name: 'Loading...' }] : items}
          onItemSelect={this.onItemSelect}
          onQueryChange={onQueryChange}
          disabled={disabled}
        >
          <Button
            text={itemRenderer(input.value) || placeholder || 'Select an organization'}
            fill
            rightIcon="caret-down"
            intent={(touched && error) ? 'danger' : (helpIntent || undefined)}
          />
        </Select>
      </FormGroup>
    );
  }
}

SelectInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onOpening: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default SelectInput;
