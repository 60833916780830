import React, { Component } from 'react';
import {
  Button, H2, H4, Spinner,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Field, reduxForm } from 'redux-form';
import { TextInput, CheckboxInput } from 'components/inputs';
import { getSiteOperatingHours, saveSiteOperatingHours } from 'actions/site';

const Dash = () => <div style={{ margin: '12px' }}>-</div>;
const required = value => (value ? undefined : 'Required');

class OperatingHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {
    const {
      dispatch, match, zone, isZone,
    } = this.props;
    const id = isZone && zone && zone.site_id ? zone.site_id : match.params.id;
    dispatch(getSiteOperatingHours(id));
  }

  @autobind
  handleOperatingHours(values) {
    const { dispatch, match } = this.props;
    const data = [
      {
        open_time: values.mondaystart, close_time: values.mondayend, day: 'Monday', closed: values.mondayclosed,
      },
      {
        open_time: values.tuesdaystart, close_time: values.tuesdayend, day: 'Tuesday', closed: values.tuesdayclosed,
      },
      {
        open_time: values.wednesdaystart, close_time: values.wednesdayend, day: 'Wednesday', closed: values.wednesdayclosed,
      },
      {
        open_time: values.thursdaystart, close_time: values.thursdayend, day: 'Thursday', closed: values.thursdayclosed,
      },
      {
        open_time: values.fridaystart, close_time: values.fridayend, day: 'Friday', closed: values.fridayclosed,
      },
      {
        open_time: values.saturdaystart, close_time: values.saturdayend, day: 'Saturday', closed: values.saturdayclosed,
      },
      {
        open_time: values.sundaystart, close_time: values.sundayend, day: 'Sunday', closed: values.sundayclosed,
      },
    ];
    return dispatch(saveSiteOperatingHours(match.params.id, data))
      .then(() => dispatch(getSiteOperatingHours(match.params.id)))
      .then(() => this.setState({ disabled: true }));
  }

  @autobind
  handleDisabled() {
    const { disabled } = this.state;
    this.setState({ disabled: !disabled });
  }

  render() {
    const {
      handleSubmit, submitting, isZone, hours,
    } = this.props;
    const { disabled } = this.state;

    if (!hours.data && hours.pending) {
      return <div><Spinner /></div>;
    }

    return (
      <div style={{ border: '.5px solid #394B59', padding: '10px' }}>
        <H2>Operating Hours</H2>
        <br />
        <form onSubmit={handleSubmit(this.handleOperatingHours)} autoComplete="off">
          <div className="columns">
            <div className="column">
              <H4>Monday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="mondaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="mondayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column">
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="mondayclosed"
                name="mondayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Tuesday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="tuesdaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="tuesdayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="tuesdayclosed"
                name="tuesdayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Wednesday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="wednesdaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="wednesdayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="wednesdayclosed"
                name="wednesdayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Thursday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="thursdaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="thursdayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="thursdayclosed"
                name="thursdayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Friday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="fridaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="fridayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="fridayclosed"
                name="fridayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Saturday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="saturdaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="saturdayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="saturdayclosed"
                name="saturdayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <H4>Sunday</H4>
            </div>
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="sundaystart"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <Dash />
            <div className="operating-day">
              <Field
                component={TextInput}
                placeholder=""
                name="sundayend"
                label=""
                type="text"
                disabled={disabled}
                validate={[required]}
              />
            </div>
            <div className="column" style={{ paddingBottom: '20px' }}>
              <Field
                component={CheckboxInput}
                type="checkbox"
                id="sundayclosed"
                name="sundayclosed"
                label="Closed"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="has-text-right">
            {
              !isZone ? (
                <React.Fragment>
                  <Button intent="success" type="button" onClick={this.handleDisabled}>
                    {disabled ? 'Edit' : 'Disable'}
                  </Button>
                  &nbsp;&nbsp;
                  <Button intent="primary" loading={submitting} disabled={disabled} type="submit" icon="tick">
                    Save
                  </Button>
                </React.Fragment>
              )
                : <div style={{ textAlign: 'center' }}>Operating hours can only be modified at the site level</div>
          }
          </div>
        </form>
      </div>
    );
  }
}

OperatingHours.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  isZone: PropTypes.bool,
  zone: PropTypes.object,
  hours: PropTypes.object,
};

export default connect(state => ({
  hours: state.siteOp,
  initialValues: state.siteOp.data && state.siteOp.data.length ? {
    mondaystart: state.siteOp.data[0].open_time,
    mondayend: state.siteOp.data[0].close_time,
    mondayclosed: state.siteOp.data[0].closed,
    tuesdaystart: state.siteOp.data[1].open_time,
    tuesdayend: state.siteOp.data[1].close_time,
    tuesdayclosed: state.siteOp.data[1].closed,
    wednesdaystart: state.siteOp.data[2].open_time,
    wednesdayend: state.siteOp.data[2].close_time,
    wednesdayclosed: state.siteOp.data[2].closed,
    thursdaystart: state.siteOp.data[3].open_time,
    thursdayend: state.siteOp.data[3].close_time,
    thursdayclosed: state.siteOp.data[3].closed,
    fridaystart: state.siteOp.data[4].open_time,
    fridayend: state.siteOp.data[4].close_time,
    fridayclosed: state.siteOp.data[4].closed,
    saturdaystart: state.siteOp.data[5].open_time,
    saturdayend: state.siteOp.data[5].close_time,
    saturdayclosed: state.siteOp.data[5].closed,
    sundaystart: state.siteOp.data[6].open_time,
    sundayend: state.siteOp.data[6].close_time,
    sundayclosed: state.siteOp.data[6].closed,
  } : undefined,
}))(reduxForm({
  form: 'operatinghours',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!(values.mondaystart && values.mondayend
      && values.tuesdaystart && values.tuesdayend
      && values.wednesdaystart && values.wednesdayend
      && values.thursdaystart && values.thursdayend
      && values.fridaystart && values.fridayend
      && values.saturdaystart && values.saturdayend
      && values.sundaystart && values.sundayend)) {
      errors.sundaystart = 'Start and End time must be entered for every day';
    }
    const days = [values.mondaystart, values.mondayend, values.tuesdaystart, values.tuesdayend,
      values.wednesdaystart, values.wednesdayend, values.thursdaystart, values.thursdayend,
      values.fridaystart, values.fridayend, values.saturdaystart, values.saturdayend,
      values.sundaystart, values.sundayend];
    const e = days.filter(x => moment(x, 'HH:mm:ss').isValid());
    if (days.length !== e.length) {
      errors.sundayend = 'Please enter all times in HH:mm:ss format';
    }

    return errors;
  },
})(OperatingHours));
