import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Popover, Button } from '@blueprintjs/core';
import { Link, Route } from 'react-router-dom';
import classNames from 'classnames';

const hasPermissions = (orgId, requiredPermissions) => requiredPermissions.includes(orgId);

const NavTabGuard = (props) => {
  const {
    requires, orgId, to, text, icon, exact,
  } = props;
  const allowed = hasPermissions(orgId, requires);
  if (!allowed) {
    return null;
  }
  return (
    <Route path={to}>
      {({ match }) => (
        <Link
          className={classNames('bp3-button bp3-minimal bp3-large', {
            'bp3-active': !!match && (!exact || match.isExact),
          })}
          to={to}
        >
          <Icon icon={icon} />
          <span className="bp3-button-text">{text}</span>
        </Link>
      )}
    </Route>
  );
};

NavTabGuard.propTypes = {
  orgId: PropTypes.number,
  requires: PropTypes.array,
  to: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  exact: PropTypes.bool,
};

const NavTab = connect(state => ({
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(NavTabGuard);

const PopoverGuard = (props) => {
  const {
    content, position, requires, orgId, text, icon,
  } = props;
  const allowed = hasPermissions(orgId, requires);
  if (!allowed) {
    return null;
  }
  return (
    <Popover
      content={content}
      position={position}
    >
      <Button large minimal icon={icon} rightIcon="caret-down" text={text} />
    </Popover>
  );
};

PopoverGuard.propTypes = {
  orgId: PropTypes.number,
  requires: PropTypes.array,
  icon: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.any,
  position: PropTypes.any,
};

const PermissionedPopover = connect(state => ({
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(PopoverGuard);

export {
  NavTab,
  PermissionedPopover,
};
