import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, Elevation, Tag, H5, Spinner,
  FormGroup, InputGroup, Checkbox,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';

import { getTrainingDatasets } from 'actions/training';

class Datasets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      showArchived: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getTrainingDatasets());
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleArchived(e) {
    this.setState({ showArchived: e.target.checked });
  }

  rowRenderer(d) {
    return (
      <Card elevation={Elevation.THREE} style={{ margin: '10px 0 10px 0' }}>
        <Tag minimal large intent="success" style={{ marginBottom: '10px' }}>{`Dataset ID: ${d.dataset_id}`}</Tag>
        <H5>
          Name:
          &nbsp;
          <Tag>{d.dataset_name}</Tag>
        </H5>
        <H5>
          Description:
          &nbsp;
          <Tag>{d.dataset_description}</Tag>
        </H5>
        <H5>
          Type:
          &nbsp;
          <Tag>{d.dataset_type}</Tag>
        </H5>
        <H5>
          Platform:
          &nbsp;
          <Tag>{d.dataset_platform}</Tag>
        </H5>
        <H5>
          Location:
          &nbsp;
          <Tag>{d.dataset_location}</Tag>
        </H5>
      </Card>
    );
  }

  render() {
    const { trainingDatasets } = this.props;
    const { query, showArchived } = this.state;
    if (trainingDatasets.pending) {
      return <Spinner size="100" />;
    }
    let filteredData = trainingDatasets.data;
    if (!showArchived) {
      filteredData = trainingDatasets.data.filter(x => !x.archived);
    }
    if (query.length) {
      filteredData = trainingDatasets.data.filter(
        x => x.dataset_name.toLowerCase().includes(query.toLowerCase())
        || x.dataset_description.toLowerCase().includes(query.toLowerCase())
        || x.dataset_type.toLowerCase().includes(query.toLowerCase()),
      );
    }
    return (
      <div className="container">
        <Checkbox
          checked={showArchived}
          onChange={this.handleArchived}
        >
          Show Archived
        </Checkbox>
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup
            value={query}
            onChange={this.handleQuery}
            leftIcon="search"
            large
            placeholder="Search datasets by name, type or description"
          />
        </FormGroup>
        {filteredData && filteredData.map(this.rowRenderer)}
      </div>
    );
  }
}

Datasets.propTypes = {
  dispatch: PropTypes.func,
  trainingDatasets: PropTypes.object,
};

export default connect(state => ({
  trainingDatasets: state.trainingDatasets,
}))(Datasets);
