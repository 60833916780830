import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, FormSelectInput } from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';
import { addCamera } from 'actions/device';

class AddCamera extends Component {
  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    let dname;
    switch (values.camera_type) {
      case 'axis.camera':
        dname = `Axis-${values.id.trim().slice(-6)}`.toUpperCase();
        break;
      case 'amcrest.camera':
        dname = `Amcrest-${values.id.trim().slice(-6)}`.toUpperCase();
        break;
      default:
        dname = values.id.trim();
    }
    return dispatch(addCamera({
      dname,
      device_id: values.id.trim(),
      device_type: values.camera_type,
    })).then(action => dispatch(push(`/devices/${action.payload.data.content.id}`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            Camera created
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
              <H1>Add Camera</H1>
              <div className="columns">
                <div className="column">
                  <Field
                    component={TextInput}
                    placeholder=""
                    name="id"
                    type="text"
                    label="Camera Serial"
                  />
                </div>
                <div className="column">
                  <Field
                    name="camera_type"
                    component={FormSelectInput}
                    placeholder=""
                    label="Type"
                    type="text"
                  >
                    <option value="axis.camera">Axis</option>
                    <option value="amcrest.camera">Amcrest</option>
                    <option value="usb.cam">USB</option>
                  </Field>
                </div>
              </div>
              <div className="is-pulled-right">
                <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AddCamera.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default connect(() => ({
  initialvalues: {},
}))(reduxForm({
  form: 'add_camera',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.id) {
      errors.id = 'This field is required.';
    }
    if (values.id && values.id.length < 6) {
      errors.id = 'Minimum 6 characters required.';
    }
    if (!values.camera_type) {
      errors.camera_type = 'This field is required.';
    }
    return errors;
  },
})(AddCamera));
