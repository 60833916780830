import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  H1, Tabs, Tab, Navbar, ButtonGroup, Button,
  Tooltip, Alignment, Popover,
} from '@blueprintjs/core';
import moment from 'moment';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import { RangePicker } from 'antd/lib/date-picker';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import numbro from 'numbro';
import semver from 'semver';

import {
  deviceMacAddress, rebootV3, rebootDevice,
} from 'actions/device';
import { getModels } from 'actions/audit';
import { AppToaster } from 'components/Toaster';
import { updateDateRange } from 'actions/daterange';
import { getDisplays } from 'actions/cms';

import Config from '../config';
import Metrics from './metrics';
import Axis from './axis';
import Vision from './vision';
import Logs from './logs';
import Mosaic from './mosaic';
import Terminal from './terminal';
import Record from '../record';
import CMS from './cms';
import VisionModels from './visionModels';

import { networkStatus, beaconStatus, cameraStatus } from '../../../Status';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

class IapDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speedtest_running: false,
      updating: false,
      speedtest_int_id: -1,
      pinging: false,
      rebooting: false,
      restarting_cms: false,
      restarting_weston: false,
      restarting_visionrx: false,
      clearingCache: false,
    };
  }

  componentDidMount() {
    const { dispatch, device } = this.props;
    dispatch(deviceMacAddress(device.id));
    dispatch(getModels());
    dispatch(getDisplays(device.organization_id));
  }

  @autobind
  handleDateChange(dates) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      DateTime.fromJSDate(dates[0].toDate()),
      DateTime.fromJSDate(dates[1].toDate()),
    ));
  }

  @autobind
  handleTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  @autobind checkSpeedtest() {
    const { device } = this.props;
    const { speedtest_int_id } = this.state;
    axios.get(`/v1/admin/devices/${device.id}/speedtest`).then(
      (response) => {
        const {
          status, message, bytes, millis,
        } = response.data;
        if (status === 'success') {
          AppToaster.show({
            icon: 'tick',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
              : download&nbsp;
                <em>{`${numbro(8000 * Math.abs(bytes) / millis).format('0a')}bits/sec`}</em>
              </span>
            ),
            intent: 'success',
          });
          clearInterval(speedtest_int_id);
          this.setState({ speedtest_int_id: -1, speedtest_running: false });
        } else if (status === 'failure') {
          AppToaster.show({
            icon: 'error',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
              : error:&nbsp;
                <em>
                  {message}
                </em>
              </span>
            ),
            intent: 'success',
          });
          clearInterval(speedtest_int_id);
          this.setState({ speedtest_int_id: -1, speedtest_running: false });
        }
      },
    )
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';

        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
      });
  }

  @autobind
  handleSpeedtest() {
    const { device } = this.props;
    const { speedtest_int_id } = this.state;
    this.setState({ speedtest_running: true });
    axios.post(`/v1/admin/devices/${device.id}/speedtest`).then(() => {
      const id = setInterval(this.checkSpeedtest, 1000);
      this.setState({ speedtest_int_id: id });
    })
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';

        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
        clearInterval(speedtest_int_id);
        this.setState({ speedtest_int_id: -1, speedtest_running: false });
      });
  }

  @autobind
  handleUpdate() {
    const { device } = this.props;
    this.setState({ updating: true });
    axios.post(`/v1/admin/devices/${device.id}/update?force=true`).then(
      (response) => {
        const {
          updating, message,
        } = response.data;
        this.setState({ updating: false });
        if (updating) {
          AppToaster.show({
            icon: 'tick',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'success',
          });
        } else {
          AppToaster.show({
            icon: 'error',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'success',
          });
        }
      },
    )
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';

        this.setState({ updating: false });
        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
      });
  }

  @autobind
  handlePing() {
    const { device } = this.props;
    this.setState({ pinging: true });
    axios.post(`/v1/admin/devices/${device.id}/ping`)
      .then((response) => {
        const ms = response.data.content.latency_ms;
        AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              : Pinged&nbsp;
              <em>{`${numbro(Math.abs(ms)).format('0,0[.00]')}ms`}</em>
            </span>
          ),
          intent: 'success',
        });
      })
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';
        // if (isTime)

        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
            </span>
          ),
          intent: 'danger',
        });
      })
      .then(() => this.setState({ pinging: false }));
  }

  @autobind
  handleReboot() {
    const { dispatch, device } = this.props;
    const v3reboot = !!device.iap_configuration;
    this.setState({ rebooting: true });
    dispatch(v3reboot ? rebootV3(device.id) : rebootDevice(device.id)).then(() => {
      this.setState({ rebooting: false });
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            : Device Rebooted
          </span>
        ),
        intent: 'success',
      });
    }, () => this.setState({ rebooting: false }));
  }

  @autobind
  handleClearCache() {
    const { device } = this.props;
    this.setState({ clearingCache: true });
    axios.delete(`/v1/iap/${device.id}/cms/cache`).then(
      (response) => {
        const { message } = response.data.content;
        const { success } = response.data.result;
        this.setState({ clearingCache: false });
        if (success) {
          AppToaster.show({
            icon: 'tick',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'success',
          });
        } else {
          AppToaster.show({
            icon: 'error',
            message: (
              <span>
                <strong>
                  {device.name}
                </strong>
                :&nbsp;
                {message}
              </span>
            ),
            intent: 'warning',
          });
        }
      },
    )
      .catch((error) => {
        const isTimeout = (error.response || {}).status === 504;
        const respData = ((error.response || {}).data) || {};
        const errMessage = (respData.result || {}).errorMessage || 'unknown';
        const errCode = (respData.result || {}).errorCode || 'unknown';

        this.setState({ clearingCache: false });
        AppToaster.show({
          icon: isTimeout ? 'cross' : 'error',
          message: (
            <span>
              <strong>
                {device.name}
              </strong>
              :&nbsp;
              {isTimeout ? 'Failed to contact device.' : `${errMessage} ${errCode}`}
            </span>
          ),
          intent: 'danger',
        });
      });
  }

  @autobind
  restartCms() {
    const { device } = this.props;
    this.setState({ restarting_cms: true });
    axios.get(`/v1/admin/devices/${device.id}/restart_cms`).then((response) => {
      const { message } = response.data || {};
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>{device.name}</strong>
            :&nbsp;
            {message}
          </span>
        ),
        intent: 'success',
      });
    }).catch((error) => {
      const isTimeout = (error.response || {}).status === 504;
      const respData = ((error.response || {}).data) || {};
      const errMessage = (respData.result || {}).errorMessage || 'unknown';

      AppToaster.show({
        icon: isTimeout ? 'cross' : 'error',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            :&nbsp;
            {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
          </span>
        ),
        intent: 'danger',
      });
    }).finally(() => this.setState({ restarting_cms: false }));
  }

  @autobind
  restartWeston() {
    const { device } = this.props;
    this.setState({ restarting_weston: true });
    axios.get(`/v1/admin/devices/${device.id}/restart_weston`).then((response) => {
      const { message } = response.data || {};
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>{device.name}</strong>
            :&nbsp;
            {message}
          </span>
        ),
        intent: 'success',
      });
    }).catch((error) => {
      const isTimeout = (error.response || {}).status === 504;
      const respData = ((error.response || {}).data) || {};
      const errMessage = (respData.result || {}).errorMessage || 'unknown';

      AppToaster.show({
        icon: isTimeout ? 'cross' : 'error',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            :&nbsp;
            {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
          </span>
        ),
        intent: 'danger',
      });
    }).finally(() => this.setState({ restarting_weston: false }));
  }

  @autobind
  restartVisionrx() {
    const { device } = this.props;
    this.setState({ restarting_visionrx: true });
    axios.get(`/v1/admin/devices/${device.id}/restart/vision-rx?service_port=3242`).then((response) => {
      const { message } = response.data || {};
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>{device.name}</strong>
            :&nbsp;
            {message}
          </span>
        ),
        intent: 'success',
      });
    }).catch((error) => {
      const isTimeout = (error.response || {}).status === 504;
      const respData = ((error.response || {}).data) || {};
      const errMessage = (respData.result || {}).errorMessage || 'unknown';

      AppToaster.show({
        icon: isTimeout ? 'cross' : 'error',
        message: (
          <span>
            <strong>
              {device.name}
            </strong>
            :&nbsp;
            {isTimeout ? 'Failed to contact device.' : `An error occured: ${errMessage}`}
          </span>
        ),
        intent: 'danger',
      });
    }).finally(() => this.setState({ restarting_visionrx: false }));
  }

  renderConnection() {
    const { device } = this.props;
    const { status } = device;
    if (status.connection_type === 'WIFI') {
      return `WiFi ${status.wifi_source} (${status.signal_strength})`;
    }
    if (status.connection_type === 'ETH') {
      return 'Ethernet';
    }
    if (status.connection_type) {
      return status.connection_type;
    }
    return 'n/a';
  }

  render() {
    const {
      device, location, interfaces, userID, startDate, endDate, models, displays,
    } = this.props;
    const {
      speedtest_running, updating, pinging, rebooting, restarting_cms,
      restarting_weston, restarting_visionrx, clearingCache,
    } = this.state;

    const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);
    const version = device.iap_configuration
      && device.iap_configuration.reported_version
      ? (device.iap_configuration || {}).reported_version
      : (device.version || {}).name;
    const type = device.iap_configuration && device.iap_configuration.is_cms ? 'CMS' : 'IAP';
    const code = device.iap_configuration && device.iap_configuration.cms_id;
    const display = displays.data && displays.data.find(x => x.iap_cms_id === code);

    let displayCode = null;
    if (type === 'CMS' && code) {
      displayCode = (
        <React.Fragment>
          Display Code:&nbsp;
          {code}
          <Dot />
        </React.Fragment>
      );
    }
    let displayId = null;
    if (type === 'CMS' && display) {
      displayId = (
        <React.Fragment>
          Display ID:&nbsp;
          {display.id}
          <Dot />
        </React.Fragment>
      );
    }
    const is3016 = semver.valid(version) && semver.gte(version, '3.0.16');
    return (
      <div>
        <H1>
          {device.name}
          <small style={{ lineHeight: '12px', fontSize: '15px' }}>
            &nbsp;version&nbsp;
            {version}
          </small>
          <Popover
            className="is-pulled-right"
            content={(
              <ButtonGroup vertical>
                {type === 'CMS' && (
                  <React.Fragment>
                    <Button
                      large
                      icon="refresh"
                      onClick={this.restartWeston}
                      loading={restarting_weston}
                    >
                      Weston
                    </Button>
                    <Button
                      large
                      icon="refresh"
                      onClick={this.restartCms}
                      loading={restarting_cms}
                    >
                      CMS
                    </Button>
                    <Button
                      large
                      icon="refresh"
                      onClick={this.restartVisionrx}
                      loading={restarting_visionrx}
                    >
                      Vision-RX
                    </Button>
                  </React.Fragment>
                )}
                <Button
                  large
                  icon="swap-vertical"
                  onClick={this.handleSpeedtest}
                  loading={speedtest_running}
                >
                  Speedtest
                </Button>
                <Button
                  large
                  icon="pulse"
                  onClick={this.handlePing}
                  loading={pinging}
                >
                  Ping
                </Button>
                <Button
                  large
                  icon="refresh"
                  onClick={this.handleUpdate}
                  loading={updating}
                >
                  Update
                </Button>
                <Button
                  large
                  icon="trash"
                  onClick={this.handleClearCache}
                  loading={clearingCache}
                >
                  Clear Cache
                </Button>
                <Button
                  large
                  intent="warning"
                  icon="power"
                  onClick={this.handleReboot}
                  loading={rebooting}
                >
                  Reboot
                </Button>
              </ButtonGroup>
            )}
          >
            <Button icon="satellite" rightIcon="caret-down" className="is-pulled-right">
              Device Utilities
            </Button>
          </Popover>
          <div className="p-l-md" style={{ display: 'inline' }}>
            <Tooltip content="Network" position="bottom">
              <Button large icon="cell-tower" minimal intent={networkStatus(device.status) ? 'success' : 'danger'} />
            </Tooltip>
            <Tooltip content="Beacon" position="bottom">
              <Button large icon="satellite" minimal intent={beaconStatus(device.status) ? 'success' : 'danger'} />
            </Tooltip>
            {device.status.past_camera && (
              <Tooltip content="Camera" position="bottom">
                <Button large icon="camera" minimal intent={cameraStatus(device.status) ? 'success' : 'danger'} />
              </Tooltip>)
            }
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
            ID:&nbsp;
            {device.id}
            <Dot />
            Created:&nbsp;
            {cd}
            <Dot />
            {displayCode}
            {displayId}
            Connection:&nbsp;
            {this.renderConnection()}
            <Dot />
            <span className="bp3-tag m-l-xs">
              Local IP:&nbsp;
              {device.status ? device.status.local_ip : '' }
            </span>
            <Dot />
            <span className="bp3-tag m-l-xs">{`eth0: ${device.eth}`}</span>
            {(interfaces.data || [])
              .map(x => <span className="bp3-tag m-l-xs">{`${x.interface}: ${x.address.match(/.{1,2}/g).join(':')}`}</span>)}
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 5 }}>
            Device Type:&nbsp;
            {type}
          </div>
        </H1>
        <Config device={device} />
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-device-axis" selectedTabId={location.pathname} onChange={this.handleTabChange} large>
              <Tab id={`/devices/${device.id}`} title="Metrics" />
              <Tab id={`/devices/${device.id}/axis`} title="Axis Config" />
              {!!device.iap_configuration && <Tab id={`/devices/${device.id}/vision`} title="Vision Config" />}
              {!!(device.connected || []).length && <Tab id={`/devices/${device.id}/camera_models`} title="Vision Models" />}
              {is3016 && <Tab id={`/devices/${device.id}/logs`} title="Logs" />}
              {type === 'CMS' && <Tab id={`/devices/${device.id}/cms`} title="CMS" />}
              <Tab id={`/devices/${device.id}/mosaic`} title="Mosaic" />
              {type === 'CMS' && <Tab id={`/devices/${device.id}/record`} title="Record" />}
              {[362, 405].includes(userID) && <Tab id={`/devices/${device.id}/portal`} title="Terminal" />}
            </Tabs>
          </Navbar.Group>
          { location.pathname === `/devices/${device.id}` && (
            <Navbar.Group align={Alignment.RIGHT}>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format="lll"
                style={{ width: '350px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Navbar.Group>
          )}
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route path="/devices/:id/vision">
                <Vision
                  device={device}
                  initialValues={device.iap_configuration || {}}
                  models={(models || {}).data}
                />
              </Route>
              <Route path="/devices/:id/axis">
                <Axis device={device} initialValues={device} />
              </Route>
              <Route path="/devices/:id/logs">
                <Logs device={device} initialValues={device} />
              </Route>
              <Route path="/devices/:id/cms">
                <CMS device={device} initialValues={device.iap_configuration || {}} />
              </Route>
              <Route path="/devices/:id/mosaic">
                <Mosaic device={device} initialValues={device} />
              </Route>
              <Route path="/devices/:id/portal">
                <Terminal device={device} initialValues={device} />
              </Route>
              {type === 'CMS' && (
                <Route path="/devices/:id/record">
                  <Record device={device} />
                </Route>
              )}
              <Route path="/devices/:id/camera_models">
                <VisionModels cameras={device.connected} deviceCameras={device.cameras} />
              </Route>
              <Route>
                <Metrics device={device} startDate={startDate} endDate={endDate} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

IapDevice.propTypes = {
  device: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  interfaces: PropTypes.object,
  userID: PropTypes.number,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  models: PropTypes.object,
  displays: PropTypes.object,
};

export default connect(state => ({
  vision: state.vision,
  interfaces: state.interfaces,
  userID: (state.currentUser.profile || {}).id,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  models: state.models,
  displays: state.displays,
}))(IapDevice);
