import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@blueprintjs/select';
import {
  Button, MenuItem, ButtonGroup,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import { getLocation } from 'actions/location';

class NvrReassign extends Component {
  componentDidMount() {
    const { dispatch, nvr } = this.props;
    if (nvr.location_id) {
      dispatch(getLocation(nvr.location_id));
    }
  }

  @autobind
  isNvrUp(nvr) {
    const { network_status } = nvr;
    return DateTime.local().diff(DateTime.fromISO(network_status)).as('minutes') < 5;
  }

  @autobind
  handlePredicate(query, neighbor) {
    const q = query.toLowerCase();
    const nameSearch = neighbor.name.toLowerCase().includes(q);
    const idSearch = neighbor.id.toLowerCase().includes(q);
    return nameSearch || idSearch;
  }

  @autobind
  handleCameraPredicate(query, camera) {
    const q = query.toLowerCase();
    const idSearch = camera.id.toLowerCase().includes(q);
    if (camera.name) {
      const nameSearch = camera.name.toLowerCase().includes(q);
      return nameSearch || idSearch;
    }
    return idSearch;
  }

  @autobind
  handleNvrSelect(nvr) {
    const { dispatch } = this.props;
    dispatch(push(`/nvrs/${nvr.id}`));
  }

  @autobind
  handleCameraSelect() {
    // const { dispatch } = this.props;
    // dispatch(push(`/devices/${camera.id}`));
  }

  @autobind
  renderNvrItem(nvr, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={nvr.id}
        icon="symbol-circle"
        intent={this.isNvrUp(nvr) ? 'success' : 'danger'}
        onClick={handleClick}
        text={nvr.name}
      />
    );
  }

  @autobind
  renderCameraItem(camera, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={camera.id}
        icon="symbol-circle"
        intent="none"
        onClick={handleClick}
        text={`${camera.name || camera.id}`}
      />
    );
  }

  render() {
    const {
      nvr, organizations, location, handleReassign,
    } = this.props;
    const org = organizations.find(o => o.id === nvr.organization_id) || {};
    let locationName = '';
    const neighbors = [];

    if (location.data !== null && nvr.location_id === location.data.id) {
      locationName = location.data.name;
    }
    const nvrCameras = nvr.cameras || [];
    return (
      <div className="columns is-multiline is-mobile">
        <div className="column is-one-third">
          <p className="p-b-md">Currently Assigned:</p>
          <Link className="p-b-md" to={`/organizations/${nvr.organization_id}`}>{org.name || nvr.organization_id}</Link>
        </div>
        <div className="column is-two-thirds">
          {locationName && <p className="p-b-md">{`Location: ${locationName}`}</p>}
          {!locationName && <p className="p-b-md"><em>Not assigned to location</em></p>}
        </div>
        <div className="column is-one-third">
          <Select
            resetOnSelect
            filterable
            itemPredicate={this.handlePredicate}
            itemRenderer={this.renderNvrItem}
            popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
            items={neighbors || []}
            onItemSelect={this.handleNvrSelect}
          >
            <Button
              text={`Neighboring Nvrs (${neighbors.length})`}
              fill
              rightIcon="caret-down"
            />
          </Select>
        </div>
        {!!nvrCameras.length && (
        <div className="column is-one-third">
          <Select
            resetOnSelect
            filterable
            itemPredicate={this.handleCameraPredicate}
            itemRenderer={this.renderCameraItem}
            popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
            items={nvrCameras}
            onItemSelect={this.handleCameraSelect}
          >
            <Button
              text={`Cameras (${nvrCameras.length})`}
              fill
              rightIcon="caret-down"
              icon="camera"
            />
          </Select>
        </div>
        )}
        <div className={`column ${nvrCameras.length ? 'is-one-third' : 'is-two-thirds'}`}>
          <ButtonGroup className="is-pulled-right">
            <Button
              intent="warning"
              onClick={handleReassign}
            >
              Reassign
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

NvrReassign.propTypes = {
  dispatch: PropTypes.func,
  nvr: PropTypes.object,
  organizations: PropTypes.array,
  location: PropTypes.object,
  handleReassign: PropTypes.func,
};

export default connect(state => ({
  location: state.location,
}))(NvrReassign);
