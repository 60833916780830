import qs from 'query-string';
import {
  GET_ORGANIZATIONS, GET_ORGANIZATION, CREATE_ORGANIZATION, SAVE_ORGANIZATION,
  GET_ORGANIZATION_DEVICES, GET_AUDIT_ORGANIZATIONS,
} from '../constants';

export const getOrganizations = (query, status, from, limit) => ({
  type: GET_ORGANIZATIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/organizations?${qs.stringify({
        query, status, from, limit,
      })}`,
    },
  },
});

export const getAuditOrganizations = (query, status, from, limit) => ({
  type: GET_AUDIT_ORGANIZATIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/audit/organizations?${qs.stringify({
        query, status, from, limit,
      })}`,
    },
  },
});

export const getOrganizationDevices = (id, query, status, from, limit) => ({
  type: GET_ORGANIZATION_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/organizations/${id}/devices?${qs.stringify({
        query, status, from, limit,
      })}`,
    },
  },
});

export const createOrganization = data => ({
  type: CREATE_ORGANIZATION,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/admin/organizations',
    },
  },
});

export const getOrganization = id => ({
  type: GET_ORGANIZATION,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/organizations/${id}`,
    },
  },
});

export const saveOrganization = (id, data) => ({
  type: SAVE_ORGANIZATION,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/organizations/${id}`,
    },
  },
});
