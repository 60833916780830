import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Tabs, Tab, Navbar, Alignment,
  Button, Overlay, Classes, NonIdealState,
} from '@blueprintjs/core';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { getDashes, getDash } from 'actions/dash';
import { autobind } from 'core-decorators';
import { getSites, getZones } from 'actions/site';
import { getLocations } from 'actions/location';
import { getOrganizations } from 'actions/organization';

import AddDash from './addDash';
import Dash from './Dash';
import DashCard from './DashCard';
import DashAnalysis from './DashCardAnalysis';
import DashExplore from './DashExplore';

const classes = classNames(
  Classes.CARD,
  Classes.ELEVATION_4,
  'dash-overlay',
);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashOverlay: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOrganizations());
    dispatch(getLocations());
    dispatch(getSites());
    dispatch(getZones());
    dispatch(getDashes());
  }

  componentDidUpdate() {
    const { dashes, dispatch, location } = this.props;
    if (location.pathname.split('/').pop() === 'dashboard' && !!dashes.length) {
      dispatch(push(`/dashboard/${dashes[0].id}`));
    }
  }

  @autobind
  getDash(dashId) {
    const { dispatch } = this.props;
    dispatch(getDash(dashId));
  }

  @autobind
  toggleCardOverlay() {
    const { cardOverlay } = this.state;
    this.setState({ cardOverlay: !cardOverlay });
  }

  @autobind
  toggleDashOverlay() {
    const { dashOverlay } = this.state;
    this.setState({ dashOverlay: !dashOverlay });
  }

  @autobind
  handleTabChange(id) {
    const { dispatch } = this.props;
    dispatch(push(id));
  }

  render() {
    const { dashes, location } = this.props;
    const { dashOverlay } = this.state;
    return (
      <div className="container">
        <Navbar>
          <Navbar.Group>
            <Tabs selectedTabId={location.pathname} onChange={this.handleTabChange} large>
              {dashes.map(x => <Tab key={`/dashboard/${x.id}`} id={`/dashboard/${x.id}`} title={x.name} />)}
            </Tabs>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button intent="primary" icon="add" text="New Dashboard" onClick={this.toggleDashOverlay} />
          </Navbar.Group>
        </Navbar>
        <br />
        {!dashes.length && !dashes.pending && location.pathname === '/dashboard' && (
          <NonIdealState icon="add" title="Create Dashboards Above" className="non-ideal-device-card" />
        )}
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          hasBackdrop
          usePortal
          isOpen={dashOverlay}
          onClose={this.toggleDashOverlay}
          transitionDuration={0}
        >
          <div className={classes}>
            <AddDash toggleDashOverlay={this.toggleDashOverlay} />
          </div>
        </Overlay>
        <Switch>
          <Route path="/dashboard/:id/card/:card_id/analyze" component={DashAnalysis} />
          <Route path="/dashboard/:id/card/:card_id" component={DashCard} />
          <Route path="/dashboard/:id/explore/analyze" component={DashAnalysis} />
          <Route path="/dashboard/:id/explore" component={DashExplore} />
          <Route path="/dashboard/:id" component={Dash} />
        </Switch>
      </div>
    );
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func,
  dashes: PropTypes.array,
  location: PropTypes.object,
};

export default connect(state => ({
  dash: state.dash,
  dashes: ((state.dashes || {}).data || [])
    .filter(x => x.creator_id === state.currentUser.profile.id) || [],
  organizations: state.organizations,
  locations: state.locations,
  sites: state.sites,
}))(Dashboard);
