import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  H3, H5, NonIdealState, Overlay, Spinner, Icon,
  Tag, Classes, Callout, TextArea, Button, MenuItem,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { getActiveAlerts, patchAlertStatus } from 'actions/alerts';
import { getUsers } from 'actions/user';
import AlertsTable from './AlertsTable';

const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);

const classes = classNames(
  Classes.CARD,
  Classes.ELEVATION_4,
  'dash-overlay',
);

const AlertsTableByType = ({
  type, alertsList, onViewActions, users,
}) => (
  <Fragment key={type}>
    <H3 style={{ margin: '20px 0' }}>{capitalizeFirstLetter(type)}</H3>
    {alertsList.map(x => (
      <Fragment key={x.alert_id}>
        <Tag intent="default" large>{x.alert_name}</Tag>
        <AlertsTable
          rows={x.status}
          alertID={x.alert_id}
          onViewActions={onViewActions}
          alertType={type}
          users={users}
        />
        <br />
      </Fragment>
    ))}
  </Fragment>
);

AlertsTableByType.propTypes = {
  type: PropTypes.string,
  alertsList: PropTypes.array,
  onViewActions: PropTypes.func,
  users: PropTypes.object,
};

class ActiveAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsOverlay: false,
      currentAlert: null,
      currentObject: null,
      currentType: null,
      comments: '',
      editing: false,
      selectedType: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getActiveAlerts());
    dispatch(getUsers());
  }

  @autobind
  onCommentsChange(e) {
    return this.setState({ comments: e.target.value });
  }

  @autobind
  onEditComment() {
    return this.setState({ editing: true });
  }

  @autobind
  onSelectAlertType(item) {
    if (item === 'All Types') return this.setState({ selectedType: null });
    return this.setState({ selectedType: item });
  }

  @autobind
  getUserFromId(userId) {
    const { users } = this.props;
    if (users.data) {
      const user = users.data.find(x => x.id === userId);
      return user.name;
    }
    return userId;
  }

  @autobind
  closeActionsOverlay() {
    return this.setState({ actionsOverlay: false, editing: false });
  }

  @autobind
  handleViewActions(alertID, objectID, alertType) {
    this.setState({
      currentAlert: alertID,
      currentObject: objectID,
      currentType: alertType,
      actionsOverlay: true,
    });
  }

  @autobind
  handleClaimAlert() {
    const { dispatch, currentUser } = this.props;
    const { currentAlert, currentObject } = this.state;
    const data = {
      user_id: currentUser.profile.id,
    };
    return dispatch(patchAlertStatus(currentAlert, currentObject, data)).then(() => {
      dispatch(getActiveAlerts());
    });
  }

  @autobind
  handleComment() {
    const { dispatch } = this.props;
    const { currentAlert, currentObject, comments } = this.state;
    const data = {
      comments,
    };
    return dispatch(patchAlertStatus(currentAlert, currentObject, data)).then(() => {
      dispatch(getActiveAlerts());
      this.setState({ editing: false });
    });
  }

  @autobind
  handleUnclaimAlert() {
    const { dispatch } = this.props;
    const { currentAlert, currentObject } = this.state;
    const data = {
      user_id: null,
    };
    return dispatch(patchAlertStatus(currentAlert, currentObject, data)).then(() => {
      dispatch(getActiveAlerts());
    });
  }

  @autobind
  handlePauseAlert() {
    const { dispatch } = this.props;
    const { currentAlert, currentObject } = this.state;
    const data = {
      alerting: 3,
    };
    return dispatch(patchAlertStatus(currentAlert, currentObject, data)).then(() => {
      dispatch(getActiveAlerts());
    });
  }

  @autobind
  renderAlertActions() {
    const { activeAlerts } = this.props;
    const {
      currentAlert, currentObject, currentType, comments, editing,
    } = this.state;
    if (!currentAlert || !currentObject || !activeAlerts.data) return <p>Hello</p>;
    const alert = activeAlerts.data[currentType].find(x => x.alert_id === currentAlert);
    const status = alert.status.find(x => x.object_id === currentObject);
    return (
      <div className={classes}>
        <H5>Comments</H5>
        {status.comments && !editing ? (
          <Fragment>
            <Callout style={{ margin: '20px 0' }} icon="comment">
              {status.comments}
            </Callout>
            <Button intent="success" onClick={this.onEditComment}>Edit</Button>
          </Fragment>
        ) : (
          <Fragment>
            <TextArea
              style={{ width: '100%', margin: '20px 0' }}
              onChange={this.onCommentsChange}
              growVertically
            >
              {status.comments ? status.comments : ''}
            </TextArea>
            <Button
              intent="success"
              disabled={!comments.length}
              onClick={this.handleComment}
            >
              Save
            </Button>
          </Fragment>
        )}
        {status.user_id ? (
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <H5 style={{ margin: 0 }}>Claimed by:</H5>
            &nbsp;
            <Tag intent="primary" large>{this.getUserFromId(status.user_id)}</Tag>
            &nbsp;
            <Button intent="warning" icon="cross" onClick={this.handleUnclaimAlert}>Unclaim</Button>
          </div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <Button intent="primary" onClick={this.handleClaimAlert}>Claim Alert</Button>
          </div>
        )}
        <div style={{ marginTop: '20px' }}>
          <Button icon="pause" onClick={this.handlePauseAlert}>Pause Alert</Button>
        </div>
      </div>
    );
  }

  @autobind
  renderItem(item, { handleClick, modifiers }) {
    return (
      <MenuItem
        disabled={modifiers.disabled}
        key={item}
        onClick={handleClick}
        text={capitalizeFirstLetter(item)}
      />
    );
  }

  render() {
    const { activeAlerts, users } = this.props;
    const { actionsOverlay, selectedType } = this.state;
    if (activeAlerts.error) {
      return (
        <NonIdealState
          icon={<Icon iconSize={Icon.SIZE_LARGE * 3} icon="error" intent="danger" />}
          title="There was an error retrieving alerts. Ensure you are connected to the VPN"
        />
      );
    }
    if (!activeAlerts.data) return <NonIdealState icon="clean" title="No active alerts" />;
    if (activeAlerts.pending) {
      return <Spinner size="100" />;
    }
    let filteredAlerts = {};
    if (selectedType) {
      filteredAlerts[selectedType] = activeAlerts.data[selectedType];
    } else {
      filteredAlerts = activeAlerts.data;
    }
    return (
      <Fragment>
        <div className="container">
          <div className="is-pulled-right">
            <Select
              noResults={<MenuItem disabled key="nr" text="No results." />}
              filterable={false}
              resetOnSelect
              itemRenderer={this.renderItem}
              onItemSelect={this.onSelectAlertType}
              popoverProps={{ minimal: true, fill: true, popoverClassName: 'popover-sz' }}
              items={['All Types', ...Object.keys(activeAlerts.data)]}
              label="Alert type"
            >
              <Button
                text={selectedType ? capitalizeFirstLetter(selectedType) : 'Filter by Type'}
                fill
                rightIcon="caret-down"
              />
            </Select>
          </div>
          {Object.entries(filteredAlerts).map((e) => {
            const [k, v] = e;
            return (
              <AlertsTableByType
                type={k}
                alertsList={v}
                onViewActions={this.handleViewActions}
                users={users}
              />
            );
          })}
        </div>
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          hasBackdrop
          usePortal
          isOpen={actionsOverlay}
          onClose={this.closeActionsOverlay}
          transitionDuration={0}
          canOutsideClickClose
        >
          {this.renderAlertActions()}
        </Overlay>
      </Fragment>
    );
  }
}

ActiveAlerts.propTypes = {
  dispatch: PropTypes.func,
  activeAlerts: PropTypes.object,
  currentUser: PropTypes.object,
  users: PropTypes.object,
};

export default connect(state => ({
  activeAlerts: state.activeAlerts,
  currentUser: state.currentUser,
  users: state.users,
}))(ActiveAlerts);
