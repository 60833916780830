import { requestsReducer } from 'redux-saga-requests';
import {
  GET_SITE,
  GET_SITE_DEVICES,
  GET_SITES,
  GET_AUDIT_SITES,
  GET_AUDIT_SITE_DEVICES,
  GET_ZONES,
  GET_ZONE,
  GET_SITE_OP_HOURS,
  GET_GROUNDTRUTH,
  GET_ZONE_DEVICES,
} from '../constants';

const getSiteReducer = requestsReducer({
  actionType: GET_SITE,
  getData: (state, action) => action.payload.data.content,
});

const getAuditSitesReducer = requestsReducer({
  actionType: GET_AUDIT_SITES,
  getData: (state, action) => action.payload.data.content,
});

const getSiteDevicesReducer = requestsReducer({
  actionType: GET_SITE_DEVICES,
  getData: (state, action) => action.payload.data.content,
});

const getAuditSiteDevicesReducer = requestsReducer({
  actionType: GET_AUDIT_SITE_DEVICES,
  getData: (state, action) => action.payload.data.content,
});

const getSitesReducer = requestsReducer({
  actionType: GET_SITES,
  getData: (state, action) => action.payload.data.content,
});

const getZonesReducer = requestsReducer({
  actionType: GET_ZONES,
  getData: (state, action) => action.payload.data.content,
});

const getZoneReducer = requestsReducer({
  actionType: GET_ZONE,
  getData: (state, action) => action.payload.data.content,
});

const getSiteOpReducer = requestsReducer({
  actionType: GET_SITE_OP_HOURS,
  getData: (state, action) => action.payload.data.content,
});

const getGroundTruthReducer = requestsReducer({
  actionType: GET_GROUNDTRUTH,
  getData: (state, action) => action.payload.data.content,
});

const getZoneDevicesReducer = requestsReducer({
  actionType: GET_ZONE_DEVICES,
  getData: (state, action) => action.payload.data.content,
});

// eslint-disable-next-line import/prefer-default-export
export {
  getSiteReducer,
  getSiteDevicesReducer,
  getSitesReducer,
  getZonesReducer,
  getZoneReducer,
  getSiteOpReducer,
  getGroundTruthReducer,
  getZoneDevicesReducer,
  getAuditSitesReducer,
  getAuditSiteDevicesReducer,
};
