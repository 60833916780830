import React, { Fragment } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Card, Elevation } from '@blueprintjs/core';
import { push } from 'connected-react-router';
import numbro from 'numbro';
import moment from 'moment';

import { property, cardTitle } from './cardItems';

const fmtTime = (x) => {
  const t = numbro(x * 60).format('00:00:00').split(':');
  let hour = parseInt(t[0], 10);
  let minute = parseInt(t[1], 10);
  let second = parseInt(t[2], 10);
  if (second === 60) {
    minute += 1;
    second = 0;
  }
  if (minute === 24) {
    hour += 1;
    minute = 0;
  }
  const time = moment({ hour, minute, second }).format('HH:mm:ss').split(':');
  if (time[0] === '00') {
    return `${time[1]}m ${time[2]}s`;
  }
  return `${time[0]}h ${time[1]}m ${time[2]}s`;
};

const SiteCard = ({
  permissions, zones, card, dispatch, onDelete, deleting, orgId,
}) => (
  <Fragment>
    {cardTitle(zones.data, card.kind_id, 'warning', onDelete, deleting)}
    <Card
      interactive
      elevation={Elevation.FOUR}
      onClick={() => dispatch(push(`/dashboard/${card.dash_id}/card/${card.id}/analyze?${qs.stringify({ z: card.kind_id })}&orgId=${orgId}`))}
    >
      <div className="columns">
        {permissions && permissions.analytics_vision && (
        <div className="column">
          {property('Occupancy', numbro(card.data.occupancy).format('0,0'))}
        </div>
        )}
        {permissions && permissions.analytics_time && (
        <div className="column">
          {property('Wait Time', fmtTime(card.data.wait_time))}
        </div>
        )}
        <div className="column">
          {property('Devices (Active/Total)', `${card.data.active_devices}/${card.data.total_devices}`)}
        </div>
      </div>
    </Card>
    <br />
  </Fragment>
);

SiteCard.propTypes = {
  zones: PropTypes.object,
  card: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func,
  deleting: PropTypes.bool,
  permissions: PropTypes.any,
  orgId: PropTypes.number,
};

export default SiteCard;
