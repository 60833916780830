import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  HTMLTable, Tag, H2, Button, Spinner, FormGroup, InputGroup,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { getAllTrainingTasks } from 'actions/training';

const TASK_STATUS = {
  FAIL: -1,
  PROGRESS: 1,
  SUCCESS: 2,
};

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllTrainingTasks());
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  rowRenderer(task) {
    const { dispatch, match } = this.props;
    const start = DateTime.fromISO(task.start_time).toFormat('f');
    const end = task.end_time ? DateTime.fromISO(task.end_time).toFormat('f') : '-';
    let intent = 'none';
    if (task.training_type === 'inline') {
      intent = 'success';
    } else if (task.training_type === 'overhead') {
      intent = 'warning';
    } else if (task.training_type === 'fisheye') {
      intent = 'primary';
    }
    let status = task.training_status;
    if (status === TASK_STATUS.FAIL) {
      status = 'Failed';
    } else if (status === TASK_STATUS.PROGRESS) {
      status = 'In progress';
    } else if (status === TASK_STATUS.SUCCESS) {
      status = 'Success';
    }
    let clickHandler;
    if (match.params.id) {
      clickHandler = () => dispatch(push(`/model_training/${match.params.id}/tasks/${task.training_id}`));
    } else {
      clickHandler = () => dispatch(push(`/model_training/tasks/${task.training_id}`));
    }
    return (
      <tr
        onClick={clickHandler}
      >
        <td>{task.training_id}</td>
        <td>{task.training_name}</td>
        <td>{task.device_id}</td>
        <td>{status}</td>
        <td>{task.gpus}</td>
        <td>{start}</td>
        <td>{end}</td>
        <td><Tag minimal intent={intent}>{task.training_type}</Tag></td>
      </tr>
    );
  }

  render() {
    const { trainingTasks, dispatch, match } = this.props;
    const { query } = this.state;
    if (trainingTasks.pending) {
      return <Spinner size="100" />;
    }
    let filteredData = trainingTasks.data;
    if (query.length) {
      filteredData = trainingTasks.data.filter(
        x => x.training_name.toLowerCase().includes(query.toLowerCase()),
      );
    }
    filteredData.sort((a, b) => (b.start_time).localeCompare(a.start_time));
    return (
      <div className="container">
        <H2>Training Tasks</H2>
        {match.params.id ? (
          <Button
            icon="add"
            intent="success"
            style={{ margin: '10px 0px 10px 0px' }}
            onClick={() => dispatch(push(`/model_training/${match.params.id}/tasks/new`))}
          >
            Create New Training
          </Button>
        ) : null}
        <br />
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup
            value={query}
            onChange={this.handleQuery}
            leftIcon="search"
            large
            placeholder="Search training tasks by name"
          />
        </FormGroup>
        <br />
        <HTMLTable striped interactive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Device ID</th>
              <th>Training Status</th>
              <th>GPUs</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {filteredData && filteredData.map(this.rowRenderer)}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}

Tasks.propTypes = {
  dispatch: PropTypes.func,
  trainingTasks: PropTypes.object,
  match: PropTypes.object,
};

export default connect(state => ({
  trainingTasks: state.trainingTasks,
}))(Tasks);
