import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { H3, Button } from '@blueprintjs/core';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import { CheckboxInput } from 'components/inputs';
import { getZone, saveZone } from 'actions/site';

import SiteName from '../Sites/sitename';
import ZoneName from '../Zones/zonename';

class AdvancedMetrics extends Component {
  @autobind
  handleAdvancedMetrics(values) {
    const { dispatch, match } = this.props;
    const data = {
      is_one_way_queuing: values.waittime,
      passerby_type: values.passerby,
    };
    return dispatch(saveZone(match.params.id, data))
      .then(() => dispatch(getZone(match.params.id)));
  }

  render() {
    const {
      submitting, handleSubmit, isSite, match, zone, site,
    } = this.props;
    return (
      <div className="columns">
        <div className="column is-one-third" style={{ marginRight: '10px' }}>
          {isSite && (
            <SiteName
              match={match}
              initialValues={{ name: site.name }}
            />
          )}
          {!isSite && (
            <ZoneName
              match={match}
              initialValues={{
                name: zone.name,
                valid_from: moment(zone.valid_from).toDate(),
              }}
            />
          )}
        </div>
        <div className="column is-two-thirds">
          <form onSubmit={handleSubmit(this.handleAdvancedMetrics)}>
            <div className="columns" style={{ border: '.5px solid #394B59' }}>
              <div className="column">
                <H3>Advanced Metrics</H3>
                <div className="columns">
                  <div className="column">
                    <Field
                      component={CheckboxInput}
                      type="checkbox"
                      id="waittime"
                      name="waittime"
                      label="Wait Time"
                    />
                  </div>
                  <div className="column">
                    <span style={{ marginBottom: '10px' }}>Passerby Metrics</span>
                    &nbsp;&nbsp;
                    <div className="bp3-select">
                      <Field name="passerby" component="select" className="passerby-select">
                        <option value="none">None</option>
                        <option value="outside">Calculate Passerby Metrics</option>
                        {!isSite && <option value="site">Use Site Headcount as the Outside Headcount</option>}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="has-text-right" style={{ marginTop: '10px' }}>
                  <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AdvancedMetrics.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isSite: PropTypes.bool,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zone: PropTypes.object,
  site: PropTypes.object,
};

export default reduxForm({
  form: 'advancedmetrics',
  enableReinitialize: true,
})(AdvancedMetrics);
