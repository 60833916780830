import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { hot } from 'react-hot-loader';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { AppToaster } from 'components/Toaster';

import {
  login, logout, getCurrentUser, resendTwoFactor, twoFactorLogin,
} from 'actions/user';

import Login from './Login';
import Nav from './Nav';
import Dashboard from './Dashboardv2';
import Organizations from './Organizations';
import Users from './Users';
import Devices from './Devices';
import Nvrs from './Nvrs';
import Sites from './Sites';
import Zones from './Zones';
import Locations from './Locations';
import Pipelines from './Pipelines';
import MFALogin from './MFALogin';
import Audits from './Audits';
import ModelTraining from './ModelTraining';
import Permissions from './Permissions';
import PermissionedRoute from './Permissions/Route';
import PermissionedRedirect from './Permissions/Redirect';
import Alerts from './Alerts';
import Reid from './Reid';

const redirect = () => <Redirect to="/audits" />;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resendCodeLoading: false,
    };
  }

  componentDidMount() {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn) {
      dispatch(getCurrentUser());
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn !== prevProps.loggedIn && loggedIn) {
      dispatch(getCurrentUser());
    }
  }

  @autobind
  onLogin(values) {
    const { dispatch } = this.props;
    const { username, password, rememberMe } = values;
    return dispatch(login(username, password, rememberMe));
  }

  @autobind
  onMFALogin(values) {
    const { dispatch, user } = this.props;
    return dispatch(twoFactorLogin(values.code, user.token.access_token));
  }

  @autobind
  onLogout() {
    const { dispatch } = this.props;
    return dispatch(logout());
  }

  @autobind
  resendCode() {
    this.setState({ resendCodeLoading: true });
    const { dispatch, user } = this.props;
    return dispatch(resendTwoFactor(user.token.access_token))
      .then(() => this.setState({ resendCodeLoading: false }))
      .then(() => AppToaster.show({
        icon: 'tick',
        message: 'Code Resent',
        intent: 'primary',
      }));
  }

  @autobind
  renderLogin() {
    const { user } = this.props;
    const { resendCodeLoading } = this.state;
    if (user.token && user.token.expires_in === 600) {
      const isTotp = user.profile && user.profile['2fa'] && user.profile['2fa'].factor === 'totp';
      return (
        <MFALogin
          onSubmit={this.onMFALogin}
          resend={this.resendCode}
          resendCodeLoading={resendCodeLoading}
          isTotp={isTotp}
        />
      );
    }
    return <Login onSubmit={this.onLogin} />;
  }

  renderApp() {
    const { user } = this.props;
    if (!(user.profile || {}).id) {
      return (
        <div style={{ paddingTop: 50 }}>
          <Spinner size={100} />
        </div>
      );
    }
    return (
      <div style={{ paddingTop: 16 }}>
        <Switch>
          <PermissionedRoute
            path="/organizations"
            component={Organizations}
            permissions={Permissions.Organizations}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/users"
            component={Users}
            permissions={Permissions.Users}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/devices"
            component={Devices}
            permissions={Permissions.Devices}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/nvrs"
            component={Nvrs}
            permissions={Permissions.Nvrs}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/sites"
            component={Sites}
            permissions={Permissions.Sites}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/zones"
            component={Zones}
            permissions={Permissions.Zones}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/locations"
            component={Locations}
            permissions={Permissions.Locations}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/pipelines"
            component={Pipelines}
            permissions={Permissions.Pipelines}
            renderNoPermissions={redirect}
          />
          <Route
            path="/audits"
            component={Audits}
            // permissions={Permissions.Audits}
            // renderNoPermissions={redirect}
          />
          <Route
            path="/reid"
            component={Reid}
          />
          <PermissionedRoute
            path="/dashboard"
            component={Dashboard}
            permissions={Permissions.Dashboard}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/model_training"
            component={ModelTraining}
            permissions={Permissions.ModelTraining}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/alerts"
            component={Alerts}
            permissions={Permissions.Alerts}
            renderNoPermissions={redirect}
          />
          <PermissionedRedirect
            to="/dashboard"
            permissions={Permissions.Dashboard}
            renderNoPermissions={redirect}
          />
        </Switch>
      </div>
    );
  }

  render() {
    const { loggedIn, userName } = this.props;
    const children = (loggedIn) ? this.renderApp() : this.renderLogin();

    return (
      <div className="bp3-dark app">
        { loggedIn && <Nav onLogout={this.onLogout} name={userName} /> }
        {children}
      </div>
    );
  }
}

App.propTypes = {
  loggedIn: PropTypes.bool,
  userName: PropTypes.string,
  dispatch: PropTypes.func,
  user: PropTypes.object,
};

export default compose(
  hot(module),
  connect(state => ({
    loggedIn: !!state.currentUser.token && state.currentUser.token.expires_in > 600,
    userName: state.currentUser.profile ? (state.currentUser.profile.name || state.currentUser.profile.email) : '',
    r: state.router.location,
    user: state.currentUser,
  })),
)(App);
