import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Spinner,
} from '@blueprintjs/core';
import { getServices } from 'actions/iap';
import { getModels, getAudits } from 'actions/audit';
import { getUsers, getUsersNonAdmin } from 'actions/user';

import List from './list';
import Create from './create';
import Audit from './auditSummary';
import CompareAudit from './Compare';
import GroundTruth from './GroundTruth';
import Summary from './Summary';
import WaitTimes from './WaitTimes';

class Audits extends Component {
  componentDidMount() {
    const { dispatch, orgId } = this.props;
    if (orgId) {
      if (orgId === 1) {
        dispatch(getUsers(null, null, [1, 443], null, null));
        dispatch(getServices());
        dispatch(getModels());
        dispatch(getAudits(null, 0, 9999, null, null));
      } else {
        dispatch(getUsersNonAdmin(null, null, orgId, null, null));
        dispatch(getAudits(null, 0, 9999, null, null));
      }
    }
  }

  render() {
    const {
      services, models, audits, users, orgId,
    } = this.props;

    if (orgId === 1) {
      if (
        (services.pending || !(services.data || []).length)
      || (models.pending)
      || (audits.pending && !(audits.data || []).length)
      || (users.pending && !(users.data || []).length)
      ) {
        return <Spinner size={100} />;
      }
    } else if (audits.pending && !(audits.data || []).length) {
      return <Spinner size={100} />;
    }
    return (
      <Switch>
        <Route path="/audits/summary" component={Summary} />
        <Route path="/audits/groundtruth" component={GroundTruth} />
        <Route path="/audits/compare" component={CompareAudit} />
        <Route path="/audits/create" component={Create} />
        <Route path="/audits/waittimes" component={WaitTimes} />
        <Route path="/audits/:id" component={Audit} />
        <Route component={List} />
      </Switch>
    );
  }
}

Audits.propTypes = {
  dispatch: PropTypes.func,
  services: PropTypes.object,
  models: PropTypes.object,
  audits: PropTypes.object,
  users: PropTypes.object,
  orgId: PropTypes.number,
};

export default connect(state => ({
  services: state.iap.services,
  models: state.models,
  audits: state.audits,
  users: state.users,
  orgId: state.currentUser.profile && state.currentUser.profile.organization_id,
}))(Audits);
