import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TagInput as BpTagInput } from '@blueprintjs/core';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const TagInput = ({
  input,
  label,
  help,
  placeholder,
  disabled,
  emptyDisabled,
  fill,
  meta: { touched, error },
}) => (
  <FormGroup
    intent={(touched && error) ? 'danger' : undefined}
    helperText={HelperText(touched, help, error)}
    label={label || undefined}
    labelFor="text-input"
  >
    <BpTagInput
      {...input}
      disabled={disabled}
      intent={(touched && error) ? 'danger' : undefined}
      placeholder={placeholder || input.placeholder}
      values={emptyDisabled && disabled ? [] : (input.value || [])}
      addOnBlur
      fill={fill}
    />
  </FormGroup>
);

TagInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  fill: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default TagInput;
