import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, CheckboxInput } from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';

import { createRole } from 'actions/role';

class Role extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, organization } = this.props;
    return dispatch(createRole({
      role_name: values.role_name,
      organization_id: organization.data.id,
      is_admin: values.is_admin,
    })).then(action => dispatch(push(`/organizations/${organization.data.id}/roles`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.role_name}
            </strong>
            : Role created
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      submitting, handleSubmit,
    } = this.props;

    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create Role
            </H1>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="role_name"
                  label="Name"
                  type="text"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={CheckboxInput}
                  type="checkbox"
                  id="is_admin"
                  name="is_admin"
                  label="Admin Role"
                />
              </div>
            </div>

            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Role.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organization: PropTypes.object,
};

export default connect(state => ({
  organization: state.organization,
}))(reduxForm({
  form: 'create-role',
  validate: (values) => {
    const errors = {};
    if (!values.role_name) {
      errors.role_name = 'This field is required.';
    }
    return errors;
  },
})(Role));
