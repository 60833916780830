import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { DateTime } from 'luxon';
import { RangePicker } from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/css';
import {
  Card, Spinner,
} from '@blueprintjs/core';
import moment from 'moment';

import { getLocation } from 'actions/location';
import { QUERY_DATE_FMT } from 'constants';

import CameraStatsCard from './card';

class SiteCameraStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: DateTime.utc().minus(10800000),
      endDate: DateTime.utc(),
    };
  }

  componentDidMount() {
    const { cameras, dispatch } = this.props;
    const locationId = (cameras[0] || {}).location_id;
    if (locationId) {
      dispatch(getLocation(locationId));
    }
  }

  @autobind
  handleDateChange(dates) {
    this.setState({
      startDate: DateTime.fromJSDate(dates[0].toDate()),
      endDate: DateTime.fromJSDate(dates[1].toDate()),
    });
  }

  render() {
    const { location, cameras } = this.props;
    const { startDate, endDate } = this.state;
    if (!location.data && location.pending) {
      return (<div className="container"><Spinner size={100} /></div>);
    }
    const region = (location.data || {}).region || 'EU';
    const cardProps = {
      startDate, endDate, region,
    };
    return (
      <Fragment>
        <div className="columns is-centered">
          <div className="column is-half">
            <Card>
              <RangePicker
                value={[moment(startDate.toJSDate()), moment(endDate.toJSDate())]}
                format={QUERY_DATE_FMT}
                style={{ width: '500px' }}
                showTime
                onChange={this.handleDateChange}
                allowClear={false}
              />
            </Card>
          </div>
        </div>
        {cameras.map(x => (
          <div key={x.device.device_identifier} style={{ marginBottom: 20 }}>
            <CameraStatsCard name={x.device.name} id={x.device.device_identifier} {...cardProps} />
          </div>
        ))}
      </Fragment>
    );
  }
}

SiteCameraStats.propTypes = {
  cameras: PropTypes.array,
  dispatch: PropTypes.func,
  location: PropTypes.object,
};

export default connect(state => ({
  location: state.location,
}))(SiteCameraStats);
