import React, { Component } from 'react';
import { DateInput } from '@blueprintjs/datetime';
import { autobind } from 'core-decorators';
import { Position, FormGroup } from '@blueprintjs/core';
import moment from 'moment';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

class DayInput extends Component {
  @autobind
  momentFormatter(format) {
    return {
      formatDate: (date, locale) => moment(date).locale(locale).format(format),
      parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
      placeholder: format,
    };
  }

  @autobind
  handleDateChange(d) {
    const { input } = this.props;
    if (input.onChange) {
      input.onChange(d);
    }
  }

  render() {
    const {
      input,
      help,
      label,
      meta: { touched, error },
      hideTime,
    } = this.props;
    return (
      <FormGroup
        intent={(touched && error) ? 'danger' : undefined}
        helperText={HelperText(touched, help, error)}
        label={label || undefined}
      >
        <DateInput
          {...this.momentFormatter(hideTime ? 'LL' : 'lll')}
          locale="en"
          onChange={this.handleDateChange}
          placeholder=""
          value={input.value || null}
          popoverProps={{ position: Position.BOTTOM }}
          timePrecision={undefined}
          closeOnSelection
        />
      </FormGroup>
    );
  }
}

export default DayInput;
