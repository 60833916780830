/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, reduxForm, FieldArray,
} from 'redux-form';
import _ from 'lodash';
import { H4, Button, NonIdealState } from '@blueprintjs/core';
import { autobind } from 'core-decorators';

import { SelectInput, RadioInput } from 'components/inputs';
import { addCardstoDash, getDash } from 'actions/dash';

const CARD_KINDS = [
  { value: 'organization', label: 'Organization' },
  { value: 'location', label: 'Location' },
  { value: 'site', label: 'Zone' },
];

class AddCard extends Component {
  @autobind
  toggleCardOverlay() {
    const { toggleCardOverlay } = this.props;
    if (toggleCardOverlay) {
      toggleCardOverlay();
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, dash } = this.props;
    const { card } = values;
    const data = card.map(x => ({
      kind: x.kind,
      kind_id: x.kind_id.id,
    }));
    const cards = [
      ...(dash.cards || []),
      ...data,
    ];
    return dispatch(addCardstoDash({ cards }, dash.id))
      .then(() => dispatch(getDash(dash.id)))
      .then(() => this.toggleCardOverlay());
  }

  @autobind
  renderFields({ fields }) {
    const { locations, zones, organizations } = this.props;
    const configs = fields.map((field, i) => (
      <div key={i}>
        <div className="columns">
          <div className="column">
            <Field
              component={RadioInput}
              items={CARD_KINDS}
              name={`${field}.kind`}
              inline
            />
          </div>
          <div className="column">
            <Button
              icon="cross"
              title="Remove Card"
              intent="danger"
              minimal
              outlined
              style={{ marginTop: -6 }}
              onClick={() => fields.remove(i)}
            />
          </div>
        </div>
        <div style={{ marginTop: -20 }}>
          {(fields.get(i) || {}).kind === 'organization' && (
            <Field
              component={SelectInput}
              name={`${field}.kind_id`}
              label="Organization"
              placeholder="Select a Organization"
              items={organizations.data}
            />
          )}
          {(fields.get(i) || {}).kind === 'location' && (
            <Field
              component={SelectInput}
              name={`${field}.kind_id`}
              label="Location"
              placeholder="Select a Location"
              items={locations.data}
            />
          )}
          {(fields.get(i) || {}).kind === 'site' && (
            <Field
              component={SelectInput}
              name={`${field}.kind_id`}
              label="Zone"
              placeholder="Select a Zone"
              items={zones.data}
            />
          )}
        </div>
      </div>
    ));
    return (
      <Fragment>
        <div className="flex-space-between-container" style={{ marginBottom: 10 }}>
          <div>
            <H4>
              Add Cards
              &nbsp;
              <Button intent="primary" className="has-text-right" icon="add" onClick={() => fields.push()} />
            </H4>
          </div>
          <div>
            <Button onClick={this.toggleCardOverlay} intent="danger" icon="cross" />
          </div>
        </div>
        <div>
          {configs.length ? configs : <NonIdealState title="" />}
        </div>
      </Fragment>
    );
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="columns">
        <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
          <FieldArray name="card" component={this.renderFields} rerenderOnEveryChange />
          <div className="is-pulled-right" style={{ marginTop: 20 }}>
            <Button
              intent="primary"
              loading={submitting}
              disabled={submitting}
              type="submit"
              icon="tick"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddCard.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organizations: PropTypes.object,
  locations: PropTypes.object,
  zones: PropTypes.object,
  toggleCardOverlay: PropTypes.func,
  dash: PropTypes.object,
};


export default connect(state => ({
  initialValues: {
    card: [
      { kind: '', kind_id: '' },
    ],
  },
  organizations: state.organizations,
  locations: state.locations,
  zones: state.zones,
}))(reduxForm({
  form: 'add_card',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    const cardErrors = [];
    values.card.forEach((val, i) => {
      const memberErrors = {};
      if (val) {
        if (!val.kind) {
          memberErrors.kind = 'Required';
          cardErrors[i] = memberErrors;
        }
        if (val.kind && _.isEmpty(val.kind_id)) {
          memberErrors.kind_id = 'Required';
          cardErrors[i] = memberErrors;
        }
      }
    });
    if (cardErrors.length) {
      errors.card = cardErrors;
    }
    return errors;
  },
})(AddCard));
