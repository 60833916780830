import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import {
  Icon, H3, Card, Elevation, FormGroup, InputGroup, Tooltip, Button,
  Menu, MenuItem,
} from '@blueprintjs/core';
import {
  Cell, Column, Table, ColumnHeaderCell,
} from '@blueprintjs/table';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { getDashZoneDevices } from 'actions/query';

const centerStyle = { textAlign: 'center' };
const INDEX_MAP = {
  0: 'name',
  1: 'zone_name',
  2: 'reboots',
  3: 'blips',
  4: 'status',
  5: 'in_maintenance',
};

const isDeviceUp = (d) => {
  const { status } = d;
  // org devices don't have extra device key
  const type = d.device ? d.device.type : d.type;
  const { is_cms: isCMS } = (d.iap_configuration || { is_cms: false });
  if (type.includes('camera') || isCMS || type.includes('peplink')) {
    return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5;
  }
  if (['cisco.meraki', 'aruba.iap'].includes(type)) {
    return DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
  }
  return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5
  && DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
};

class AnalyzeDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      type: '',
      data: [],
      originalData: [],
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  @autobind
  getDeviceType(d) {
    const { type = '' } = d.device || {};
    if (d.iap_configuration && d.iap_configuration.is_cms) {
      return 'cms';
    }
    if (type.includes('camera')) {
      return 'vision';
    }
    if (type.includes('peplink')) {
      return 'peplink';
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return 'external';
    }
    return 'iap';
  }

  @autobind
  handleQuery(e) {
    const { originalData, type } = this.state;
    const q = e.target.value.toLowerCase();
    const filteredData = originalData
      .filter(x => (x.name.toLowerCase().includes(q) || x.zone_name.toLowerCase().includes(q))
      && x.filter_type.includes(type));
    this.setState({ query: e.target.value, data: filteredData });
  }

  @autobind
  handleType(e) {
    const { originalData, query } = this.state;
    const q = query.toLowerCase();
    const filteredData = originalData
      .filter(x => x.filter_type.includes(e.target.value)
      && (x.name.toLowerCase().includes(q) || x.zone_name.toLowerCase().includes(q)));
    this.setState({ type: e.target.value, data: filteredData });
  }

  @autobind
  fetchData() {
    const { selectedZones, dispatch, zones } = this.props;
    const data = [];
    return Promise.all(selectedZones.map(({ id, default_zone }) => dispatch(
      getDashZoneDevices(id, default_zone),
    ))).then((response) => {
      if (response.length) {
        response.forEach(x => data.push(...x.payload.data.content));
        if (data.length) {
          const zoneDevices = (data || []).filter(x => !!x.zone_id);
          const zoneDeviceIds = (zoneDevices || []).map(({ id }) => id);
          const siteDevices = _.uniqBy(data.filter(y => !zoneDeviceIds.includes(y.id)),
            x => ((x || {}).device || {}).device_identifier);
          const tableDevices = [...zoneDevices, ...siteDevices]
            .map(x => ({
              filter_type: this.getDeviceType(x),
              name: x.device.name,
              in_maintenance: x.device.in_maintenance ? 'Yes' : 'No',
              zone_name: ((zones.data || []).find(y => y.id === (x.zone_id ? x.zone_id : x.site_id)) || { name: '' }).name,
              reboots: (x.status.reboots || 0).toString(),
              blips: (x.status.blips || 0).toString(),
              status: isDeviceUp(x) ? 'Up' : 'Down',
            }));
          this.setState({ data: tableDevices, originalData: tableDevices });
        }
      }
    });
  }

  @autobind
  cellRenderer(index, title) {
    const { data } = this.state;
    const row = data[index];
    if (row) {
      if (title === 'status') {
        return (
          <Cell intent={row[title] === 'Up' ? 'success' : 'danger'} style={centerStyle}>
            {row[title]}
          </Cell>
        );
      }
      if (title === 'in_maintenance') {
        return (
          <Cell intent={row[title] === 'Yes' ? 'success' : undefined} style={centerStyle}>
            {row[title]}
          </Cell>
        );
      }
      return <Cell wrapText>{row[title]}</Cell>;
    }
    return <Cell />;
  }

  @autobind
  sortAsc(column) {
    const { data } = this.state;
    const sortedData = [...data]
      .sort((a, b) => a[INDEX_MAP[column]].localeCompare(b[INDEX_MAP[column]]));
    this.setState({ data: sortedData });
  }

  @autobind
  sortDesc(column) {
    const { data } = this.state;
    const sortedData = [...data]
      .sort((a, b) => b[INDEX_MAP[column]].localeCompare(a[INDEX_MAP[column]]));
    this.setState({ data: sortedData });
  }

  @autobind
  renderMenu(column) {
    return (
      <Menu>
        <MenuItem icon="sort-asc" text="Sort Asc" onClick={() => this.sortAsc(column)} />
        <MenuItem icon="sort-desc" text="Sort Desc" onClick={() => this.sortDesc(column)} />
      </Menu>
    );
  }

  @autobind
  renderDevice(d) {
    if (d) {
      const {
        name, device_identifier, in_maintenance, zone_name, reboots, fps, status,
      } = d;
      return (
        <tr key={device_identifier}>
          <td><Link to={`/devices/${device_identifier}`}>{name}</Link></td>
          <td><Link to={`/devices/${device_identifier}`}>{device_identifier}</Link></td>
          <td>
            {zone_name}
          </td>
          <td style={centerStyle}>{reboots}</td>
          <td style={centerStyle}>{numbro(fps).format('0,0[.00]')}</td>
          <td style={centerStyle}>
            <Icon
              icon="symbol-circle"
              intent={status ? 'success' : 'danger'}
            />
          </td>
          <td style={centerStyle}>
            <Icon
              icon={in_maintenance ? 'tick' : 'cross'}
              intent={in_maintenance ? 'success' : undefined}
            />
          </td>
        </tr>
      );
    }
    return null;
  }

  render() {
    const { dashDevices } = this.props;
    const { query, type, data } = this.state;

    const loading = _.some(Object.values(dashDevices), x => !x.resolved);
    const columnWidth = this.tableRef.current ? this.tableRef.current.clientWidth / 6.165 : 0;
    return (
      <Card elevation={Elevation.FOUR} style={{ marginBottom: 20 }}>
        <div className="flex-space-between-container" ref={this.tableRef}>
          <div>
            <H3>
              Devices
              &nbsp;&nbsp;
              <FormGroup className="inline-flex m-r-md">
                <div className="bp3-select">
                  <select value={type} onChange={this.handleType}>
                    <option value="">All</option>
                    <option value="iap">IAP</option>
                    <option value="vision">Vision</option>
                    <option value="cms">CMS</option>
                    <option value="external">External AP</option>
                    <option value="peplink">Peplink</option>
                  </select>
                </div>
              </FormGroup>
            </H3>
          </div>
          <div className="flex-space-between-container">
            <div style={{ fontWeight: 'bold', marginRight: 20 }}>* Past 24hrs</div>
            <Tooltip content="Refresh Devices">
              <Button loading={loading} intent="success" icon="refresh" onClick={this.fetchData} />
            </Tooltip>
          </div>
        </div>
        <FormGroup style={{ margin: '10px 0px' }}>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Devices or Zones" />
        </FormGroup>
        {/** blueprintjs table needs fixed height container or only renders 200 rows */}
        <div style={{ height: 600 }}>
          <Table
            columnWidths={Array(6).fill(columnWidth)}
            numFrozenColumns={1}
            numRows={data.length}
            class="bp3-html-table bp3-table-resize-horizontal"
            enableFocusedCell={false}
          >
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Name" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'name')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Zone" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'zone_name')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="Reboots*" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'reboots')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell name="FPS Blips*" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'blips')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell style={centerStyle} name="Device Status" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'status')}
            />
            <Column
              columnHeaderCellRenderer={() => <ColumnHeaderCell style={centerStyle} name="Maintenance Mode" menuRenderer={this.renderMenu} />}
              cellRenderer={i => this.cellRenderer(i, 'in_maintenance')}
            />
          </Table>
        </div>
      </Card>
    );
  }
}

AnalyzeDevices.propTypes = {
  selectedZones: PropTypes.any,
  dispatch: PropTypes.func,
  dashDevices: PropTypes.object,
  zones: PropTypes.object,
};

export default connect((state, { selectedZones }) => ({
  dashDevices: _.pickBy(state.dashDevices, (v, k) => selectedZones.map(({ id }) => id)
    .includes(parseInt(k, 10))),
  zones: state.zones,
}))(AnalyzeDevices);
