import React from 'react';
import PropTypes from 'prop-types';
import {
  H1,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';

import Config from './config';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

const GenericDevice = ({ device }) => {
  const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);

  return (
    <div className="column">
      <H1>
        {device.name}
        <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
          ID:&nbsp;
          {device.id}
          <Dot />
          Created:&nbsp;
          {cd}
          <Dot />
          Device Type:&nbsp;
          {device.type}
        </div>
      </H1>
      <Config device={device} />
    </div>
  );
};

GenericDevice.propTypes = {
  device: PropTypes.object,
};

export default GenericDevice;
