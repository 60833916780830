import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  H1, Tabs, Tab, Navbar, Spinner, Tooltip, Button,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import { Route, Switch, Link } from 'react-router-dom';

import { getVisionParameters } from 'actions/device';

import Config from '../config';
import VisionConfig from './VisionConfig';
import CameraDirectionConfig from './CameraConfig';
import CameraUsage from './CameraUsage';
import Homography from './homography';
import PolygonLine from './PolygonLine';

import { networkStatus } from '../../../Status';
import CountingLines from './CountingLines';

const Dot = () => <span>&nbsp;&bull;&nbsp;</span>;

class VisionDevice extends Component {
  componentDidMount() {
    const { dispatch, device } = this.props;
    dispatch(getVisionParameters(device.id));
  }

  @autobind
  handleAxisTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  render() {
    const {
      device, location, region, vision, allDevices, dispatch,
    } = this.props;

    if (!vision.data) {
      return (<div className="container"><Spinner size={100} /></div>);
    }

    const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);

    const connectedIap = (() => {
      if (device.via) {
        return ((allDevices.data || []).find(x => x.id === device.via) || {});
      }
      return {};
    })();
    const recordingIap = (() => {
      if (device.status.recording_device) {
        return ((allDevices.data || []).find(x => x.id === device.status.recording_device) || {});
      }
      return {};
    })();

    const model = ((device || {}).status || {}).model_id;
    return (
      <div>
        <H1>
          {device.name}
          <div className="p-l-md" style={{ display: 'inline' }}>
            <Tooltip content="Network" position="bottom">
              <Button large icon="cell-tower" minimal intent={networkStatus(device.status) ? 'success' : 'danger'} />
            </Tooltip>
            {!!connectedIap.id && (
              <span className="bp3-text-small bp3-text-muted">
                Connected IAP
                <Dot />
                <Link to={`/devices/${connectedIap.id}`}>
                  {connectedIap.name}
                </Link>
              </span>
            )}
            &nbsp;&nbsp;
            {!!recordingIap.id && (
              <span className="bp3-text-small bp3-text-muted">
                Recording IAP
                <Dot />
                <Link to={`/devices/${recordingIap.id}`}>
                  {recordingIap.name}
                </Link>
              </span>
            )}
            &nbsp;&nbsp;
            {!!model && (
              <span className="bp3-text-small bp3-text-muted">
                Model ID
                <Dot />
                {model}
              </span>
            )}
          </div>
          <div className="bp3-text-small bp3-text-muted" style={{ lineHeight: 1.28581, marginTop: 10 }}>
            ID:&nbsp;
            {device.id}
            <Dot />
            Created:&nbsp;
            {cd}
            <Dot />
            Device Type:&nbsp;
            {device.type}
          </div>
        </H1>
        <Config device={device} enableNameChange />
        <Navbar style={{ margin: '16px 0' }}>
          <Navbar.Group>
            <Tabs id="tabs-device-axis" selectedTabId={location.pathname} onChange={this.handleAxisTabChange} large>
              <Tab id={`/devices/${device.id}`} title="Vision Configuration" />
              <Tab id={`/devices/${device.id}/camera`} title="Camera Configuration" />
              <Tab id={`/devices/${device.id}/metrics`} title="Stats" />
              <Tab id={`/devices/${device.id}/homography`} title="Homography" />
              <Tab id={`/devices/${device.id}/polgyon_line`} title="Polygon Line" />
              <Tab id={`/devices/${device.id}/counting_lines`} title="Counting Lines" />
            </Tabs>
          </Navbar.Group>
        </Navbar>
        <div className="columns">
          <div className="column">
            <Switch>
              <Route path="/devices/:id/homography">
                <Homography device={device} />
              </Route>
              <Route path="/devices/:id/metrics">
                <CameraUsage device={device} region={region} />
              </Route>
              <Route path="/devices/:id/camera">
                <CameraDirectionConfig device={device} initialValues={vision.data} />
              </Route>
              <Route path="/devices/:id/polgyon_line">
                <PolygonLine device={device} vision={vision} dispatch={dispatch} />
              </Route>
              <Route path="/devices/:id/counting_lines">
                <CountingLines device={device} dispatch={dispatch} />
              </Route>
              <Route>
                <VisionConfig device={device} vision={vision} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

VisionDevice.propTypes = {
  device: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  region: PropTypes.string,
  vision: PropTypes.object,
  allDevices: PropTypes.object,
};

export default connect(state => ({
  vision: state.vision,
  allDevices: state.allDevices,
}))(VisionDevice);
