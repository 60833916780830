import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H1, FormGroup, InputGroup, NonIdealState, MenuItem, Button,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { getLocations } from 'actions/location';
import { getOrganizations } from 'actions/organization';

const filterOrg = (query, org) => org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
const renderTag = org => org.name;

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      orgs: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getLocations());
    dispatch(getOrganizations());
  }

  @autobind
  onOrgSelect(org) {
    const { orgs } = this.state;
    if (_.some(orgs, x => x.id === org.id)) {
      this.setState({ orgs: orgs.filter(x => x.id !== org.id) });
    } else {
      this.setState({ orgs: [...orgs, org] });
    }
  }

  @autobind
  onOrgClear() {
    this.setState({ orgs: [] });
  }

  @autobind
  onOrgRemove(tag, index) {
    const { orgs } = this.state;
    this.setState({ orgs: orgs.filter((x, i) => i !== index) });
  }

  @autobind
  handleQuery(e) {
    this.setState({
      query: e.target.value,
    });
  }

  @autobind
  renderOrg(org, { handleClick, modifiers }) {
    const { orgs } = this.state;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={`${org.slug.slice(0, 5)}`}
        key={org.id}
        icon={_.some(orgs, x => x.id === org.id) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={org.name}
      />
    );
  }

  renderLocation = (location, i) => {
    const { organizations } = this.props;
    const organization = _.find(organizations.data || [], x => x.id === location.organization_id)
      || {};
    return (
      <tr key={i}>
        <td><Link to={`/locations/${location.id}`}>{location.id}</Link></td>
        <td><Link to={`/locations/${location.id}`}>{location.name}</Link></td>
        <td><Link to={`/organizations/${organization.id}`}>{organization.name}</Link></td>
        <td>{location.region}</td>
        <td>{location.timezone}</td>
      </tr>
    );
  }

  render() {
    const { query, orgs } = this.state;
    const { locations, organizations } = this.props;
    const clearOrgButton = orgs.length > 0 ? <Button icon="cross" minimal onClick={this.onOrgClear} /> : null;
    const orgIds = orgs.map(x => x.id);
    const filteredLocations = _.chain((locations.data || []))
      .filter(q => q.name.toLowerCase()
        .includes(query.toLowerCase()) || q.id.toString().includes(query))
      .filter(r => (
        orgIds && orgIds.length ? orgIds.includes(r.organization_id)
          : r))
      .value();
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>Locations</H1>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Locations or ID's" />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-right">
                    <FormGroup
                      label="Organizations"
                      labelFor="organizations"
                      className="inline-flex m-r-md"
                    >
                      <MultiSelect
                        resetOnSelect
                        items={organizations.data || []}
                        itemPredicate={filterOrg}
                        itemRenderer={this.renderOrg}
                        tagRenderer={renderTag}
                        noResults={<MenuItem disabled text="No results." />}
                        selectedItems={orgs}
                        onItemSelect={this.onOrgSelect}
                        popoverProps={{ minimal: true }}
                        tagInputProps={{
                          onRemove: this.onOrgRemove,
                          rightElement: clearOrgButton,
                          placeholder: 'All Organizations',
                          className: 'min-width-tag-input',
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((filteredLocations || []).length && !locations.pending) && <NonIdealState icon="search" title="No Results" />}
            { !!((filteredLocations || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Location</th>
                    <th>Organization</th>
                    <th>Region</th>
                    <th>Timezone</th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredLocations || []).map(this.renderLocation)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Locations.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  organizations: PropTypes.object,
};

export default connect(state => ({
  locations: state.locations,
  organizations: state.organizations,
}))(Locations);
