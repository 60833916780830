import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation, Tag } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import numbro from 'numbro';

import Line from './line';

const fmtr = x => numbro(x.toFixed(2)).format('0,0');

const GPUMemChart = ({ data }) => {
  const time = data.columns.findIndex(x => x === 'time');
  const util0 = data.columns.findIndex(x => x === 'mean_gpu_0_utilization');
  const util1 = data.columns.findIndex(x => x === 'mean_gpu_1_utilization');
  const labels = data.values.map(x => DateTime.fromMillis(x[time]).toFormat('ff'));
  const gpu0Color = '#E6B518';
  const gpu1Color = '#59DEB6';

  const datasets = [
    {
      label: 'GPU 0 Memory Utilization',
      data: data.values.map(x => x[util0]),
      fill: false,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: gpu0Color,
      spanGaps: false,
      suffix: '%',
    },
    {
      label: 'GPU 1 Memory Utilization',
      data: data.values.map(x => x[util1]),
      fill: false,
      pointRadius: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: gpu1Color,
      spanGaps: false,
      suffix: '%',
    },
  ];

  const chartDataset = { datasets, labels };

  return (
    <Fragment>
      <Tag large>GPU Memory Utilization</Tag>
      <Card elevation={Elevation.THREE} style={{ marginTop: 10 }}>
        <div style={{ minHeight: 500 }}>
          <Line
            data={() => chartDataset}
            legend
            yFmtr={fmtr}
            position="bottom"
            yLabel=""
          />
        </div>
      </Card>
    </Fragment>
  );
};

GPUMemChart.propTypes = {
  data: PropTypes.object,
};

export default GPUMemChart;
