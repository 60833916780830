import React, { Component } from 'react';
import { DateInput, TimePicker, TimePrecision } from '@blueprintjs/datetime';
import { autobind } from 'core-decorators';
import { FormGroup, Position } from '@blueprintjs/core';
import moment from 'moment';

const HelperText = (touched, help, error) => (
  error || (help || undefined)
);

class DateTimeInput extends Component {
  @autobind
  momentFormatter(format) {
    return {
      formatDate: (date, locale) => moment(date).locale(locale).format(format),
      parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
      placeholder: format,
    };
  }

  @autobind
  handleDateChange(d) {
    const { input } = this.props;
    if (input.onChange) {
      input.onChange(d);
    }
  }

  render() {
    const {
      input,
      meta: { touched, error, help },
      label,
      fill,
      helpIntent,
      secondsPrecision,
    } = this.props;
    return (
      <FormGroup
        label={label || undefined}
        intent={(error) ? 'danger' : (helpIntent || undefined)}
        helperText={HelperText(touched, help, error)}
      >
        <DateInput
          {...input}
          {...this.momentFormatter(secondsPrecision ? 'MMM D YYYY, HH:mm:ss' : 'LLL')}
          locale="en"
          onChange={this.handleDateChange}
          placeholder="Choose Date"
          value={input.value || null}
          popoverProps={{ position: Position.TOP }}
          timePrecision={secondsPrecision ? TimePrecision.SECOND : TimePrecision.MINUTE}
          closeOnSelection={false}
          timePickerProps={<TimePicker />}
          fill={fill || undefined}
        />
      </FormGroup>
    );
  }
}

export default DateTimeInput;
