import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button, H5 } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';

import { AppToaster } from 'components/Toaster';
import { CheckboxInput } from 'components/inputs';
import { getZone, saveZone, resetOccupancy } from 'actions/site';

class ZoneOccupancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetting: false,
    };
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const zoneId = parseInt(match.params.id, 10);
    return dispatch(saveZone(zoneId, {
      occupancy_enabled: values.occupancy_enabled,
    })).then(() => dispatch(getZone(zoneId)));
  }

  @autobind
  handleResetOccupancy() {
    this.setState({ resetting: true });
    const { dispatch, match } = this.props;
    const zoneId = parseInt(match.params.id, 10);
    dispatch(resetOccupancy(zoneId))
      .then(() => this.setState({ resetting: false })
      || AppToaster.show({
        icon: 'tick',
        message: 'Occupancy Reset',
        intent: 'success',
      }));
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    const { resetting } = this.state;
    return (
      <div className="columns" style={{ marginLeft: '0px' }}>
        <div className="column" style={{ border: '.5px solid #394B59' }}>
          <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
            <div style={{ display: 'flex' }}>
              <H5 style={{ marginRight: 10 }}>Occupancy Enabled</H5>
              <span>
                <Field
                  component={CheckboxInput}
                  name="occupancy_enabled"
                />
              </span>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
        <div className="column has-text-right">
          <Button loading={resetting} icon="reset" large intent="warning" onClick={this.handleResetOccupancy}>
            Reset Occupancy
          </Button>
        </div>
      </div>
    );
  }
}

ZoneOccupancy.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'zone_occupancy',
  enableReinitialize: true,
})(ZoneOccupancy);
