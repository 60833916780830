import { requestsReducer } from 'redux-saga-requests';
import { GET_USERS, GET_USER } from '../constants';

export const usersReducer = requestsReducer({
  actionType: GET_USERS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const userReducer = requestsReducer({
  actionType: GET_USER,
  getData: (state, action) => action.payload.data.content,
});
