/* eslint-disable import/default */

import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import axios from 'axios';

import configureStore, { history } from './store/configureStore';
import appSaga from './sagas';
import Root from './components/Root';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.

require('./favicon.ico'); // Tell webpack to load favicon.ico

const apiHost = process.env.API_HOST ? process.env.API_HOST : 'http://api.livereachmedia.com/api';
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : apiHost;
const store = configureStore();
store.runSaga(appSaga);

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app'),
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    // eslint-disable-next-line global-require
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app'),
    );
  });
}
