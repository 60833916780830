import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from '@blueprintjs/core';
import { Field, reduxForm } from 'redux-form';
import { getSite, saveSite } from 'actions/site';
import { TextInput, CheckboxInput } from 'components/inputs';

class SiteName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  @autobind
  handleDisabled() {
    const { disabled } = this.state;
    this.setState({ disabled: !disabled });
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    return dispatch(saveSite(match.params.id, {
      name: values.name,
      enable_live: values.live,
    })).then(() => dispatch(getSite(match.params.id)))
      .then(() => this.setState({ disabled: true }));
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    const { disabled } = this.state;
    return (
      <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
        <div className="columns" style={{ border: '.5px solid #394B59', marginLeft: '0px' }}>
          <div className="column">
            <Field
              component={TextInput}
              placeholder=""
              name="name"
              label="Site Name"
              type="text"
              disabled={disabled}
            />
            <Field
              component={CheckboxInput}
              placeholder=""
              name="live"
              label="Enable Live"
              type="checkbox"
              disabled={disabled}
            />

            <div className="has-text-right">
              <Button intent="success" type="button" onClick={this.handleDisabled}>
                Edit
              </Button>
            &nbsp;&nbsp;
              <Button intent="primary" loading={submitting} disabled={disabled} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

SiteName.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  match: PropTypes.object,
  dispatch: PropTypes.func,
};

export default reduxForm({
  form: 'sitename',
  enableReinitialize: true,
})(SiteName);
