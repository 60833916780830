import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroup } from '@blueprintjs/core';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const TextInput = ({
  input,
  label,
  help,
  type,
  placeholder,
  disabled,
  emptyDisabled,
  rightElement,
  meta: { touched, error },
}) => (
  <FormGroup
    intent={(touched && error) ? 'danger' : undefined}
    helperText={HelperText(touched, help, error)}
    label={label || undefined}
    labelFor="text-input"
  >
    <InputGroup
      {...input}
      disabled={disabled}
      intent={(touched && error) ? 'danger' : undefined}
      type={type || input.type}
      placeholder={placeholder || input.placeholder}
      value={emptyDisabled && disabled ? '' : input.value}
      rightElement={rightElement}
    />
  </FormGroup>
);

TextInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  rightElement: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

const TextAreaInput = ({
  input,
  label,
  help,
  disabled,
  rows,
  meta: { touched, error },
  id,
  name,
  style,
}) => (
  <FormGroup
    intent={(touched && error) ? 'danger' : undefined}
    helperText={HelperText(touched, help, error)}
    label={label || undefined}
    labelFor="text-input"
  >
    {/**
     * Cursor bug with redux form + blueprintjs text area. Reverting to html text area
     * */}
    <textarea
      onChange={input.onChange}
      id={id}
      className="custom-text-area"
      rows={rows || 10}
      name={name}
      value={input.value}
      disabled={disabled}
      style={style}
    />
  </FormGroup>
);

TextAreaInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
};

const SelectInput = ({
  input,
  label,
  help,
  children,
  meta: { touched, error },
}) => (
  <FormGroup
    intent={(touched && error) ? 'danger' : undefined}
    helperText={HelperText(touched, help, error)}
    label={label || undefined}
    labelFor="text-input"
  >
    <div className="bp3-select">
      <select {...input}>
        {children}
      </select>
    </div>
  </FormGroup>
);

SelectInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export { TextInput, SelectInput, TextAreaInput };
