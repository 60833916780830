import qs from 'query-string';
import _ from 'lodash';
import { DASH_QUERY, GET_DASH_ZONE_DEVICES, GET_ZONE_OCCUPANCY } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const dashQuery = (
  zoneId, endPoint, startTime, endTime, dimension, metrics,
) => ({
  type: DASH_QUERY,
  meta: {
    name: `${endPoint}-${zoneId}`,
    actionKey: `${endPoint}-${zoneId}`,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/${endPoint}?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions: dimension,
        metrics,
      }))}`,
    },
  },
});

export const getDashZoneDevices = (zoneId, isDefault) => ({
  type: GET_DASH_ZONE_DEVICES,
  meta: {
    name: zoneId,
    actionKey: zoneId,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/${isDefault ? 'sites' : 'zones'}/${zoneId}/devices?status=true&reboots=true`,
    },
  },
});

export const getZoneOccupancy = (name, endpoint, zoneId, startTime, endTime, dimensions) => ({
  type: GET_ZONE_OCCUPANCY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/${endpoint}?${qs.stringify({
        startTime,
        endTime,
        dimensions,
      })}`,
    },
  },
});
