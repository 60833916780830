import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Radio, RadioGroup } from '@blueprintjs/core';

class RadioInput extends Component {
  @autobind
  onRadioChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.currentTarget.value);
  }

  render() {
    const {
      input, label, disabled, items, inline,
    } = this.props;

    return (
      <RadioGroup
        label={label}
        onChange={this.onRadioChange}
        selectedValue={input.value}
        disabled={disabled}
        inline={inline || false}
      >
        {!!items && items.map(x => <Radio key={x.value} label={x.label} value={x.value} />)}
      </RadioGroup>
    );
  }
}

RadioInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

export default RadioInput;
