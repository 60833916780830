import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '@blueprintjs/datetime';
import { autobind } from 'core-decorators';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Position,
  Button,
  H3,
  NonIdealState,
} from '@blueprintjs/core';
import { AppToaster } from 'components/Toaster';
import moment from 'moment';
import classNames from 'classnames';
import { queryGroundTruth } from 'actions/site';

class QueryGroundtruth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment(),
      endTime: moment(),
    };
  }

  @autobind
  momentFormatter(format) {
    return {
      formatDate: (date, locale) => moment(date).locale(locale).format(format),
      parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
    };
  }

  @autobind
  handleStartTime(startTime) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ startTime });
  }

  @autobind
  handleEndTime(endTime) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ endTime });
  }

  @autobind
  handleSubmit(values) {
    const { startTime, endTime } = this.state;
    const { dispatch, match } = this.props;
    return startTime && endTime && values.scale
      ? dispatch(queryGroundTruth(match.params.id,
        moment(startTime).startOf('day').format(),
        moment(endTime).endOf('day').format(),
        values.scale))
      : AppToaster.show({
        icon: 'cross',
        message: (
          <span>All Fields Required, Remember To Select A Time Scale</span>
        ),
        intent: 'danger',
      });
  }

  renderStartTime = ({ startTime }) => (
    <DateInput
      {...this.momentFormatter('ll')}
      locale="en"
      onChange={this.handleStartTime}
      placeholder="Start Date"
      value={startTime}
      popoverProps={{ position: Position.TOP }}
    />
  );

  renderEndTime = ({ endTime }) => (
    <DateInput
      {...this.momentFormatter('ll')}
      locale="en"
      onChange={this.handleEndTime}
      placeholder="End Date"
      value={endTime}
      popoverProps={{ position: Position.TOP }}
    />
  );

  renderGroundtruth(gt, i) {
    if (!gt.Headcount && !gt.AvgDwellTime && !gt.AvgWaitTime) {
      return '';
    }
    return (
      <tr key={i}>
        <td>{gt.EpochTime ? moment(gt.EpochTime).format('MMM D YYYY, HH:mm:ss') : ''}</td>
        <td>{gt.AvgDwellTime || ''}</td>
        <td>{gt.AvgWaitTime || ''}</td>
        <td>{gt.Headcount || ''}</td>
      </tr>
    );
  }

  render() {
    const {
      submitting,
      handleSubmit,
      groundtruth,
    } = this.props;
    const { startTime, endTime } = this.state;
    return (
      <React.Fragment>
        <H3>View Groundtruth</H3>
        <br />
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="columns">
            <div className="column">
              <div className="bp3-select">
                <Field
                  component="select"
                  name="scale"
                  className="passerby-select"
                >
                  <option value="" defaultValue disabled>Time Scale</option>
                  <option value="hour">Hourly</option>
                  <option value="minute">Minutely</option>
                </Field>
              </div>
            </div>
            <div className="column">
              <Field
                component={this.renderStartTime}
                name="starttime"
                props={startTime}
              />
            </div>
            <div className="column">
              <Field
                component={this.renderEndTime}
                name="endtime"
                props={endTime}
              />
            </div>
            <div className="column">
              <Button intent="success" loading={submitting} disabled={submitting} type="submit" icon="search">
                View
              </Button>
            </div>
          </div>
        </form>
        <div className="columns">
          <div className="column">
            {!((groundtruth.data || []).length) && <NonIdealState icon="search" title="No Results" />}
            <br />
            {!!((groundtruth.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>Datetime</th>
                    <th>Avg Dwell Time</th>
                    <th>Avg Wait Time</th>
                    <th>Headcount</th>
                  </tr>
                </thead>
                <tbody>
                  {(groundtruth.data || []).map(this.renderGroundtruth)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QueryGroundtruth.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default connect(state => ({
  groundtruth: state.groundtruth,
}))(reduxForm({
  form: 'querygroundtruth',
  enableReinitialize: true,
})(QueryGroundtruth));
