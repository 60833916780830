import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { H4, Button } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput } from 'components/inputs';
import { createDash, getDashes } from 'actions/dash';

class AddDash extends Component {
  toggleDashOverlay() {
    const { toggleDashOverlay } = this.props;
    toggleDashOverlay();
  }

  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    const data = {
      name: values.dash_name,
    };
    return dispatch(createDash(data))
      .then(() => dispatch(getDashes()))
      .then(() => this.toggleDashOverlay());
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="column">
        <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
          <H4>New Dashboard</H4>
          <div className="column">
            <Field
              component={TextInput}
              name="dash_name"
              type="text"
              placeholder="Name"
            />
          </div>
          <div className="is-pulled-right">
            <Button
              intent="primary"
              loading={submitting}
              disabled={submitting}
              type="submit"
              icon="tick"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddDash.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  toggleDashOverlay: PropTypes.func,
};

export default reduxForm({
  form: 'add_dash',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.dash_name) {
      errors.dash_name = 'This field is required.';
    }
    return errors;
  },
})(AddDash);
