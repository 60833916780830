import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Card, Radio, RadioGroup, Elevation, H3,
} from '@blueprintjs/core';

class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = { dimension: props.initialDimension || 'hour' };
    this.chartRef = React.createRef();
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimension: e.target.value });
  }

  renderTitle() {
    const {
      title, hideHour, hideDay, hideWeek, showMin,
    } = this.props;
    const { dimension } = this.state;
    return (
      <div className="flex-space-between-container">
        <H3>{title}</H3>
        <div>
          <RadioGroup inline size="small" selectedValue={dimension} onChange={this.handleDimensionChange}>
            {showMin && <Radio value="minute">Minute</Radio>}
            {!hideHour && <Radio value="hour">Hour</Radio>}
            {!hideDay && <Radio value="day">Day</Radio>}
            {!hideWeek && <Radio value="week">Week</Radio>}
          </RadioGroup>
        </div>
      </div>
    );
  }

  render() {
    const { loading, children } = this.props;
    const { dimension } = this.state;
    return (
      <Card loading={loading} elevation={Elevation.FOUR} style={{ margin: '20px 0px', backgroundColor: '#212C35' }}>
        {this.renderTitle()}
        { React.cloneElement(children, { dimension, ref: this.chartRef })}
      </Card>
    );
  }
}

ChartCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  hideHour: PropTypes.bool,
  hideDay: PropTypes.bool,
  hideWeek: PropTypes.bool,
  showMin: PropTypes.bool,
  initialDimension: PropTypes.string,
};

export default ChartCard;
