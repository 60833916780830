import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation } from '@blueprintjs/core';
import { push } from 'connected-react-router';

import { property, cardTitle } from './cardItems';

const OrganizationCard = ({
  card, organizations, dispatch, onDelete, deleting,
}) => (
  <Fragment>
    {cardTitle(organizations.data, card.kind_id, 'primary', onDelete, deleting)}
    <Card
      interactive
      elevation={Elevation.FOUR}
      onClick={() => dispatch(push(`/dashboard/${card.dash_id}/card/${card.id}`))}
    >
      <div className="columns">
        <div className="column">
          {property('Total Sites', card.sites.total)}
        </div>
        <div className="column">
          {property('Active Sites', card.sites.active, '#399F79')}
        </div>
        <div className="column">
          {property('Inactive Sites', card.sites.total - card.sites.active, '#df4848')}
        </div>
        <div className="column">
          {property('Devices (Active/Total)', `${card.data.active_devices}/${card.data.total_devices}`)}
        </div>
      </div>
    </Card>
    <br />
  </Fragment>
);

OrganizationCard.propTypes = {
  organizations: PropTypes.object,
  card: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func,
  deleting: PropTypes.bool,
};

export default OrganizationCard;
