import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import {
  Button, HTMLTable, Icon, Checkbox, Tag, FormGroup, InputGroup, Spinner,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';

import { getDash } from 'actions/dash';

const centerStyle = { textAlign: 'center' };

class DashExploreOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      query: '',
      org: '',
      status: '',
    };
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(getDash(match.params.id));
  }

  componentDidUpdate(prevProps) {
    const { match, dispatch } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(getDash(match.params.id));
    }
  }

  @autobind
  onRowSelect(event, site) {
    const { checked } = this.state;
    if (event.target.checked) {
      checked.push(site.id);
    }
    if (!event.target.checked) {
      return this.setState({ checked: [...checked].filter(x => x !== site.id) });
    }
    return this.setState({ checked });
  }

  @autobind
  onSelectAll(e, data) {
    if (!e.target.checked) return this.setState({ checked: [] });
    return this.setState({ checked: data.map(x => x.id) });
  }

  @autobind
  handleStatus(e) {
    this.setState({ status: e.target.value });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleOrg(e) {
    this.setState({ org: e.target.value });
  }

  @autobind
  statusFilter(z) {
    const { status } = this.state;
    const { up } = (z.status || {}).devices || {};
    if (status === 'active') return up > 0;
    if (status === 'inactive') return up === 0;
    return true;
  }

  @autobind
  rowRenderer(zone, i) {
    const { locations, organizations } = this.props;
    const { checked } = this.state;
    return (
      <tr key={i}>
        <td>
          <Checkbox
            checked={checked.includes(zone.id)}
            large
            onChange={event => this.onRowSelect(event, zone)}
          />
        </td>
        <td><Link to={`/zones/${zone.id}`}>{zone.id}</Link></td>
        <td><Link to={`/zones/${zone.id}`}>{zone.name}</Link></td>
        <td style={{ maxWidth: 200 }}>
          <Link to={`/locations/${zone.location_id}`}>
            {((locations.data || []).find(x => x.id === zone.location_id) || {}).name}
          </Link>
        </td>
        <td>
          <Link to={`/organizations/${zone.organization_id}`}>
            {((organizations.data || []).find(x => x.id === zone.organization_id) || {}).name}
          </Link>
        </td>
        <td style={centerStyle}>
          <Icon icon="symbol-circle" intent={((zone.status || {}).devices || {}).up > 0 ? 'success' : 'danger'} />
        </td>
      </tr>
    );
  }

  @autobind
  analyze() {
    const { dispatch, match } = this.props;
    const { checked } = this.state;
    dispatch(push(`/dashboard/${match.params.id}/explore/analyze?${qs.stringify({ z: checked })}`));
  }

  render() {
    const {
      dash, match, zones, dispatch, organizations,
    } = this.props;
    const {
      query, checked, org, status,
    } = this.state;

    if (!dash.data && dash.pending) {
      return <Spinner size={100} />;
    }
    if (dash.data.id !== parseInt(match.params.id, 10)) {
      return <Spinner size={100} />;
    }
    const dashZones = [];
    dash.data.cards.forEach((card) => {
      if (card.kind === 'organization') {
        const orgZones = (zones.data || []).filter(x => x.organization_id === card.kind_id);
        dashZones.push(orgZones);
      }
      if (card.kind === 'location') {
        const locationZones = (zones.data || []).filter(x => x.location_id === card.kind_id);
        dashZones.push(locationZones);
      }
      if (card.kind === 'site') {
        const z = (zones.data || []).find(x => x.id === card.kind_id);
        dashZones.push(z);
      }
    });
    const dashOrgs = _.uniqBy(_.flatten(dashZones), x => x.organization_id);
    const tableZones = _.uniqBy(_.flatten(dashZones), x => x.id)
      .filter(y => y.name.toLowerCase().includes(query.toLowerCase())
        && y.organization_id.toString().includes(org))
      .filter(this.statusFilter);

    return (
      <Fragment>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Icon
            className="audit-back-icon"
            icon="circle-arrow-left"
            iconSize={30}
            onClick={() => dispatch(push(`/dashboard/${match.params.id}`))}
          />
          <div>
            <Tag style={{ fontSize: 18 }} intent="primary" minimal large>
              Dashboard:
            </Tag>
            &nbsp;
            <Tag style={{ fontSize: 18 }} intent="warning" large minimal>
              {dash.data.name}
            </Tag>
          </div>
          <div>
            <Button onClick={this.analyze} icon="chart" text={`Analyze (${checked.length})`} intent="warning" disabled={!checked.length} />
          </div>
        </div>
        <FormGroup style={{ margin: '20px 0px' }}>
          <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="Search Zones" />
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormGroup className="has-text-right">
            <div className="bp3-select">
              <select value={org} onChange={this.handleOrg}>
                <option value="">Organization</option>
                {(dashOrgs || [])
                  .map(y => (
                    <option key={y.organization_id} value={y.organization_id}>
                      {((organizations.data || [])
                        .find(z => z.id === y.organization_id) || {}).name}
                    </option>
                  ))}
              </select>
            </div>
          </FormGroup>
          &nbsp;&nbsp;
          <FormGroup>
            <div className="bp3-select">
              <select value={status} onChange={this.handleStatus}>
                <option value="">Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </FormGroup>
        </div>
        <HTMLTable interactive striped style={{ marginTop: 10 }}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  large
                  checked={tableZones.length === checked.length}
                  onChange={e => this.onSelectAll(e, tableZones)}
                />
              </th>
              <th>Zone ID</th>
              <th>Zone</th>
              <th>Location</th>
              <th>Organization</th>
              <th style={centerStyle}>Status</th>
            </tr>
          </thead>
          <tbody>
            {!!tableZones.length && [...tableZones]
              .sort((a, b) => b.default_zone - a.default_zone)
              .map(this.rowRenderer)}
          </tbody>
        </HTMLTable>
        <br />
      </Fragment>
    );
  }
}

DashExploreOverview.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  dash: PropTypes.object,
  zones: PropTypes.object,
  locations: PropTypes.object,
  organizations: PropTypes.object,
};

export default connect(state => ({
  sites: state.sites,
  locations: state.locations,
  organizations: state.organizations,
  zones: state.zones,
  dash: state.dash,
}))(DashExploreOverview);
