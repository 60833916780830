import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  Spinner,
} from '@blueprintjs/core';

// import List from './list';
import { getServices } from 'actions/iap';

import Releases from './releases';
import CreateRelease from './create_release';
import EditRelease from './edit_release';

class IAP extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getServices());
  }

  render() {
    const { services } = this.props;
    if (services.pending || !services.data.length) {
      return <Spinner size={100} />;
    }
    return (
      <Switch>
        <Route path="/devices/iap/releases/create" component={CreateRelease} />
        <Route path="/devices/iap/releases/:id" component={EditRelease} />
        <Route component={Releases} />
      </Switch>
    );
  }
}

IAP.propTypes = {
  dispatch: PropTypes.func,
  services: PropTypes.object,
};

export default connect(state => ({
  services: state.iap.services,
}))(IAP);
