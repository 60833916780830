import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button, ButtonGroup } from '@blueprintjs/core';
import _ from 'lodash';
import {
  deleteHomography, getHomography, postHomography,
  patchVisionParameters, validateHomography,
} from 'actions/device';
import { AppToaster } from 'components/Toaster';

const NONE = 'none';
const GENERATE = 'generate';
const VIEW = 'view';
const SUBMIT = 'submit';
const DELETE = 'delete';
const VALIDATE = 'validate';

class Homography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      view: false,
      validate: false,
      edit: false,
      cameraPoints: [],
      sitemapPoints: [],
      cameraImg: null,
      sitemapImg: null,
      loading: NONE,
      cameraImgHeight: null,
      cameraImgWidth: null,
      sitemapImgHeight: null,
      sitemapImgWidth: null,
      dewarp_scale: null,
    };
    this.cameraRef = React.createRef();
    this.siteRef = React.createRef();
  }

  @autobind
  setCameraImgRes({ target: img }) {
    this.setState({ cameraImgWidth: img.offsetWidth, cameraImgHeight: img.offsetHeight });
  }

  @autobind
  setSitemapImgRes({ target: img }) {
    this.setState({ sitemapImgWidth: img.offsetWidth, sitemapImgHeight: img.offsetHeight });
  }

  @autobind
  handleView() {
    const { device, dispatch } = this.props;
    const { view } = this.state;
    if (view) {
      return this.setState({ view: false });
    }
    this.setState({ loading: VIEW });
    return dispatch(getHomography(device.id))
      .then(() => this.setState({
        view: true,
      }))
      .finally(() => this.setState({ loading: NONE }));
  }

  @autobind
  handleDelete() {
    const { device, dispatch } = this.props;
    this.setState({ loading: DELETE });
    return dispatch(deleteHomography(device.id))
      .then(() => this.setState({
        view: false,
      }))
      .finally(() => this.setState({ loading: NONE }));
  }

  @autobind
  handleEdit() {
    const { edit } = this.state;
    if (edit) {
      return this.setState({ edit: false, cameraPoints: [], sitemapPoints: [] });
    }
    return this.setState({ edit: true });
  }

  @autobind
  handleValidate() {
    const { validate } = this.state;
    if (validate) {
      return this.setState({ validate: false, cameraPoints: [], sitemapPoints: [] });
    }
    return this.setState({ validate: true });
  }

  @autobind
  checkValidation() {
    const { device, dispatch, site } = this.props;
    // device, dispatch,
    const { dewarp_scale, cameraImg, cameraPoints } = this.state;
    this.setState({ loading: VALIDATE });
    if (cameraImg) {
      const resolution = `${cameraImg.naturalWidth}x${cameraImg.naturalHeight}`;
      // eslint-disable-next-line max-len
      return dispatch(validateHomography(device.id, { points: cameraPoints.map(this.scaleFromCameraPoint).map(p => [p.x, p.y]) }, (dewarp_scale && (dewarp_scale > 0 && dewarp_scale < 1)) ? dewarp_scale : 0, resolution, site.data.id))
        .catch(() => {
          this.setState({ loading: NONE });
        })
        .then((action) => {
          if (action.payload.data && action.payload.data.points) {
            this.setState({ sitemapPoints: action.payload.data.points });
          }
        })
        .then(() => this.setState({ loading: NONE }));
    }
    return null;
  }

  @autobind
  addPoint(ev) {
    const {
      edit, cameraPoints, sitemapPoints, validate,
    } = this.state;
    const { x, y } = ev.nativeEvent;

    if (!edit && !validate) {
      return;
    }

    if (this.cameraRef.current) {
      const c = this.cameraRef.current.getBoundingClientRect();
      // eslint-disable-next-line max-len
      if (x >= c.x && x < c.x + c.width && y >= c.y && y < c.y + c.height) {
        if (edit && (cameraPoints.length > sitemapPoints.length)) {
          AppToaster.show({
            icon: 'error',
            message: 'Finish mapping on the sitemap first',
            intent: 'error',
          });
          return;
        }
        cameraPoints.push({ x: (x - c.x), y: (y - c.y) });
        this.setState({ cameraPoints });
        return;
      }
    }

    if (edit && this.siteRef.current) {
      const c = this.siteRef.current.getBoundingClientRect();
      // eslint-disable-next-line max-len
      if (x >= c.x && x < c.x + c.width && y >= c.y && y < c.y + c.height) {
        if (edit && (sitemapPoints.length > cameraPoints.length)) {
          AppToaster.show({
            icon: 'error',
            message: 'Finish mapping on the camera first',
            intent: 'r',
          });
          return;
        }
        sitemapPoints.push({ x: (x - c.x), y: (y - c.y) });
        this.setState({ sitemapPoints });
        return;
      }
    }

    _.defer(() => this.forceUpdate());
  }

  @autobind
  scaleToCameraPoint(p) {
    const { cameraImg, cameraImgHeight, cameraImgWidth } = this.state;
    return {
      x: Math.round(p.x * cameraImgWidth / cameraImg.naturalWidth),
      y: Math.round(p.y * cameraImgHeight / cameraImg.naturalHeight),
    };
  }

  @autobind
  scaleToSitemapPoint(p) {
    const { sitemapImg, sitemapImgHeight, sitemapImgWidth } = this.state;
    return {
      x: Math.round(p.x * sitemapImgWidth / sitemapImg.naturalWidth),
      y: Math.round(p.y * sitemapImgHeight / sitemapImg.naturalHeight),
    };
  }

  @autobind
  scaleFromCameraPoint(p) {
    const { cameraImg, cameraImgHeight, cameraImgWidth } = this.state;
    return {
      x: Math.round(p.x * cameraImg.naturalWidth / cameraImgWidth),
      y: Math.round(p.y * cameraImg.naturalHeight / cameraImgHeight),
    };
  }

  @autobind
  scaleFromSitemapPoint(p) {
    const { sitemapImg, sitemapImgHeight, sitemapImgWidth } = this.state;
    return {
      x: Math.round(p.x * sitemapImg.naturalWidth / sitemapImgWidth),
      y: Math.round(p.y * sitemapImg.naturalHeight / sitemapImgHeight),
    };
  }

  @autobind
  handleSubmit() {
    const { device, dispatch } = this.props;
    const { cameraPoints, sitemapPoints, dewarp_scale } = this.state;
    if (cameraPoints.length < 4 || sitemapPoints < 4) {
      return AppToaster.show({
        icon: 'error',
        message:
          'At least 4 mappings are required',
        intent: 'error',
      });
    }
    this.setState({ loading: SUBMIT });
    const data = {
      camera_points: cameraPoints.map(this.scaleFromCameraPoint),
      site_points: sitemapPoints.map(this.scaleFromSitemapPoint),
    };
    return dispatch(postHomography(device.id, data))
      .then(() => {
        this.setState({ cameraPoints: [], sitemapPoints: [], edit: false });
        AppToaster.show({
          icon: 'tick',
          message: (
            <span>
              <strong>
                    Configured homography
              </strong>
            </span>
          ),
          intent: 'success',
        });
      })
      // eslint-disable-next-line max-len
      .then(() => dispatch(patchVisionParameters(device.id, { fisheye_scaling_factor: parseFloat(dewarp_scale) })))
      .then(() => dispatch(getHomography(device.id))
        .catch((error) => {
          AppToaster.show({
            icon: 'error',
            message: (
              <span>{error.message || 'unknown'}</span>
            ),
            intent: 'error',
          });
        }))
      .catch((error) => {
        AppToaster.show({
          icon: 'error',
          message: (
            <span>{error.message || 'unknown'}</span>
          ),
          intent: 'error',
        });
      })
      .finally(() => { this.setState({ loading: NONE }); });
  }

  @autobind
  checkCameraImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ cameraImg: imgObject, loading: NONE });
      } else {
        setTimeout(() => {
          this.checkCameraImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  fetchCameraImage() {
    const { rand, sitemapImg, dewarp_scale } = this.state;
    const { device } = this.props;
    const imgObject = new Image();
    imgObject.src = (dewarp_scale != null && dewarp_scale > 0 && dewarp_scale < 1) ? `https://admin.livereachmedia.com/api/v1/devices/${device.id}/camera/preview?r=${rand}&scale_factor=${dewarp_scale}` : `https://admin.livereachmedia.com/api/v1/devices/${device.id}/camera/preview?r=${rand}`;
    imgObject.onload = this.checkCameraImageLoaded(imgObject);
    imgObject.onerror = () => this.setState({ loading: NONE });
    this.setState({ loading: GENERATE, rand: Math.floor(Math.random() * 10000) });

    if (!sitemapImg) {
      this.fetchSiteImage();
    }
  }

  @autobind
  updateDewarpScale(e) {
    this.setState({ dewarp_scale: e.target.value });
  }

  @autobind
  checkSiteImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ sitemapImg: imgObject });
      } else {
        setTimeout(() => {
          this.checkSiteImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  fetchSiteImage() {
    const { site } = this.props;
    const imgObject = new Image();
    imgObject.src = site.data.floorplan;
    imgObject.onload = this.checkSiteImageLoaded(imgObject);
  }

  @autobind
  renderConfigureButtons(enabled) {
    const { edit, loading, validate } = this.state;
    if (!edit) {
      return (
        <Button
          onClick={this.handleEdit}
          disabled={!enabled || loading !== NONE || validate}
          icon="pin"
          intent="primary"
          style={{ marginLeft: 5 }}
        >
          Configure Homography
        </Button>
      );
    }
    return (
      <div>
        <Button
          onClick={this.handleSubmit}
          disabled={!enabled || (loading !== NONE && loading !== SUBMIT)}
          icon="circle-arrow-up"
          intent="primary"
          loading={enabled && loading === SUBMIT}
          style={{ marginLeft: 5 }}
        >
      Submit
        </Button>
        <Button
          onClick={this.handleEdit}
          disabled={!enabled || loading !== NONE}
          icon="cross"
          intent="none"
          style={{ marginLeft: 5 }}
        >
      Cancel
        </Button>
      </div>
    );
  }

  @autobind
  renderValidateButtons(enabled) {
    const { loading, validate } = this.state;
    if (validate) {
      return (
        <div>
          <Button
            onClick={this.checkValidation}
            disabled={!enabled || (loading !== NONE && loading !== VALIDATE)}
            icon="circle-arrow-up"
            intent="primary"
            loading={enabled && loading === VALIDATE}
            style={{ marginLeft: 5 }}
          >
        Submit Check
          </Button>
          <Button
            onClick={this.handleValidate}
            disabled={!enabled || loading !== NONE}
            icon="cross"
            intent="none"
            style={{ marginLeft: 5 }}
          >
        Cancel Check
          </Button>
        </div>
      );
    }
    return null;
  }

  @autobind
  renderPoints(points, color, z) {
    const ret = [];
    const { edit } = this.state;
    points.forEach((p, i) => {
      const { x, y } = p;
      const j = i + 1;
      const key = `point-${j}`;
      ret.push((
        <div
          key={key}
          onClick={edit ? this.addPoint : null}
          role="presentation"
          style={{
            position: 'absolute', zIndex: z, left: x - 2, top: y - 2, height: 4, width: 4, backgroundColor: color, borderRadius: '50%', textAlign: 'center',
          }}
        >
          {j}
        </div>
      ));
    });
    return ret;
  }


  @autobind
  renderImages(enabled) {
    const {
      homography,
    } = this.props;
    const {
      cameraImg, cameraPoints, edit, sitemapImg, sitemapPoints, view,
      validate,
    } = this.state;
    if (!enabled) {
      return null;
    }
    let obj = {};
    if (homography.data) {
      obj = JSON.parse(homography.data);
    }
    let site_points = [];
    if (validate) {
      site_points = sitemapPoints.map(p => ({ x: p[0], y: p[1] }));
    }

    return (
      <div>
        <div
          style={{
            position: 'relative',
            maxWidth: '50vw',
            marginBottom: 10,
          }}
        >
          <img
            src={cameraImg.src}
            onLoad={this.setCameraImgRes}
            alt="no feed"
            ref={this.cameraRef}
            draggable={false}
            role="presentation"
            onClick={(edit || validate) ? this.addPoint : null}
          />
          {(edit || validate) && this.renderPoints(cameraPoints, '#f75', 10)}
          {view && homography.data && this.renderPoints(obj.camera_points.map(this.scaleToCameraPoint), '#00f', 9)}
        </div>
        <div
          style={{
            position: 'relative',
            maxWidth: '50vw',
            background: 'white',
          }}
        >
          <img
            src={sitemapImg.src}
            onLoad={this.setSitemapImgRes}
            alt="no feed"
            ref={this.siteRef}
            draggable={false}
            role="presentation"
            onClick={edit ? this.addPoint : null}
          />
          {(edit) && this.renderPoints(sitemapPoints, '#f75', 10)}
          {(validate) && this.renderPoints(site_points.map(this.scaleToSitemapPoint), '#f75', 10)}
          {view && homography.data && this.renderPoints(obj.site_points.map(this.scaleToSitemapPoint), '#00f', 9)}
        </div>
      </div>
    );
  }

  @autobind
  render() {
    const { site } = this.props;
    const {
      cameraImg, loading, sitemapImg, view, dewarp_scale,
      validate,
    } = this.state;
    const enabled = site && site.data && cameraImg && cameraImg.complete && sitemapImg
      && sitemapImg.complete;
    return (
      <div>
        <input value={dewarp_scale} onChange={this.updateDewarpScale} placeholder="Fisheye Dewarp Scale" type="number" className="bp3-input" style={{ marginRight: 5, marginBottom: 10 }} />
        <ButtonGroup style={{ marginBottom: 20 }}>
          <Button onClick={this.fetchCameraImage} disabled={loading !== NONE && loading !== GENERATE} loading={loading === GENERATE} icon="media" intent="success" style={{ marginRight: 5 }}>
            Generate Image
          </Button>
          <Button
            onClick={this.handleView}
            disabled={!enabled || validate || (loading !== NONE && loading !== VIEW)}
            icon={view ? 'eye-off' : 'eye-open'}
            intent={view ? 'none' : 'primary'}
            loading={enabled && loading === VIEW}
            style={{ marginLeft: 5, marginRight: 5 }}
          >
            {view ? 'Hide Current' : 'View Current'}
          </Button>
          <Button
            onClick={this.handleValidate}
            disabled={!enabled || view || (loading !== NONE && loading !== VALIDATE)}
            icon={validate ? 'eye-off' : 'eye-open'}
            intent={validate ? 'none' : 'primary'}
            loading={enabled && loading === VALIDATE}
            style={{ marginLeft: 5, marginRight: 5 }}
          >
            {validate ? 'Hide Check' : 'Check Homography'}
          </Button>
          {this.renderValidateButtons(enabled)}
          {this.renderConfigureButtons(enabled)}
          <Button onClick={this.handleDelete} disabled={!enabled || (loading !== NONE && loading !== DELETE)} loading={enabled && loading === DELETE} icon="trash" intent="none" style={{ marginLeft: 10 }}>
      Delete
          </Button>
        </ButtonGroup>
        {this.renderImages(enabled)}
      </div>
    );
  }
}

Homography.propTypes = {
  site: PropTypes.object,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  homography: PropTypes.object,
};

export default connect(state => ({
  site: state.site,
  homography: state.homography,
}))(Homography);
