/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, reset, FieldArray,
} from 'redux-form';
import { Button, NonIdealState } from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import {
  TextInput, FormSelectInput,
} from 'components/inputs';
import { AppToaster } from 'components/Toaster';

import { patchVisionParameters, getDevice } from 'actions/device';

class ModelForm extends Component {
  @autobind
  async handleSave(values) {
    const { dispatch, device, refreshModels } = this.props;
    const { camera_model_ids } = values;
    if (camera_model_ids.length) {
      try {
        await Promise.all(camera_model_ids.map(
          ({ serial, model_id }) => dispatch(patchVisionParameters(
            serial, { model_parameters: { model_id } },
          )),
        ));
        AppToaster.show({
          message: 'Camera Models Saved',
          intent: 'success',
          icon: 'tick',
        });
        return dispatch(getDevice(device.id))
          .then(() => {
            if (refreshModels) {
              refreshModels();
            }
            dispatch(reset('camera_model_ids'));
          });
      } catch (action) {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage || 'Error configuring camera models';
          return AppToaster.show({
            message: msg,
            intent: 'danger',
          });
        }
      }
    }
    return null;
  }

  @autobind
  filterModels(model, row) {
    const { vision_model = '' } = row || {};
    const { id } = (model || { id: '' });
    switch (vision_model) {
      case 'vision_coral_overhead':
      case 'rx.overhead.yolov4-tiny':
        return id.includes('vision-rx.tinyyolov4.overhead');
      case 'vision_coral_inline':
      case 'rx.inline.yolov4-tiny':
        return id.includes('vision-rx.tinyyolov4.inline');
      case 'rx.inline.yolov3-tiny':
        return id.includes('vision-rx.tinyyolov3.inline');
      case 'rx.overhead.yolov3-tiny':
        return id.includes('vision-rx.tinyyolov3.overhead');
      default:
        return true;
    }
  }

  @autobind
  renderModelFields({ fields }) {
    const { models } = this.props;
    const configs = fields.map((field, i) => (
      <li className="columns" key={i}>
        <div className="column">
          <Field
            component={TextInput}
            placeholder=""
            name={`${field}.serial`}
            label="Camera Serial #"
            disabled
          />
        </div>
        <div className="column">
          <Field
            component={TextInput}
            name={`${field}.vision_model`}
            label="Model Type"
            type="text"
            disabled
          />
        </div>
        {!['bgsegm_counter', 'coral_tracker'].includes((fields.get(i) || {}).vision_model) && (
          <div className="column">
            <Field
              name={`${field}.model_id`}
              placeholder=""
              component={FormSelectInput}
              label="Model ID"
            >
              <option value="">Default</option>
              {(models || [])
                .filter(m => this.filterModels(m, fields.get(i)))
                .map(x => (
                  <option key={x.id} value={x.id}>
                    {`${x.id} (${x.model_type})`}
                  </option>
                ))
              }
            </Field>
          </div>
        )}
      </li>
    ));
    return configs.length ? configs : <NonIdealState title="" />;
  }

  render() {
    const { submitting, handleSubmit } = this.props;
    return (
      <div style={{ marginBottom: 40 }}>
        <form onSubmit={handleSubmit(this.handleSave)} autoComplete="off">
          <FieldArray name="camera_model_ids" component={this.renderModelFields} />
          <div className="columns">
            <div className="column has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ModelForm.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  models: PropTypes.array,
};

export default reduxForm({
  form: 'camera_model_ids',
  enableReinitialize: true,
})(ModelForm);
