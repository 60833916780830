/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, FormGroup, InputGroup, Icon, Spinner, Button, NonIdealState, MenuItem,
  Menu, Popover, Position,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import qs from 'query-string';
import { DateTime } from 'luxon';
import { replace, push } from 'connected-react-router';
import ReactPaginate from 'react-paginate-bp3';
import axios from 'axios';

import { getDevices, getDeviceVersions } from 'actions/device';
import { getOrganizations } from 'actions/organization';
import { networkStatus, beaconStatus } from '../Status';

const filterOrg = (query, org) => org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
const renderTag = org => org.name;

const filterDevices = (devicesData, type) => {
  let filteredDevices = [];
  let iapDevices = [];
  switch (type) {
    case '':
      filteredDevices = devicesData;
      break;
    case 'iap':
      iapDevices = devicesData.filter(val => ['livereachmedia.iap', 'Raspberry PI'].includes(val.type));
      for (let i = 0; i < iapDevices.length;) {
        const isCMS = ((iapDevices[i] || {}).iap_configuration || { is_cms: false }).is_cms;
        if (!isCMS) {
          filteredDevices.push(iapDevices[i]);
        }
        i += 1;
      }
      break;
    case 'meraki':
      filteredDevices = devicesData.filter(val => ['cisco.meraki'].includes(val.type));
      break;
    case 'vision':
      filteredDevices = devicesData.filter(val => val.type.includes('camera'));
      break;
    case 'aruba':
      filteredDevices = devicesData.filter(val => ['aruba.iap'].includes(val.type));
      break;
    case 'peplink':
      filteredDevices = devicesData.filter(val => ['peplink'].includes(val.type));
      break;
    case 'cms':
      for (let i = 0; i < devicesData.length;) {
        const isCMS = ((devicesData[i] || {}).iap_configuration || { is_cms: false }).is_cms;
        if (isCMS) {
          filteredDevices.push(devicesData[i]);
        }
        i += 1;
      }
      break;
    default:
      filteredDevices = devicesData;
  }
  return filteredDevices;
};

const skipBeaconCheck = ['axis.camera', 'amcrest.camera', 'peplink'];
const skipNetworkCheck = ['cisco.meraki', 'aruba.iap'];

class Devices extends Component {
  constructor(props) {
    super(props);
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const page = parseInt(search.p, 10) || 0;
    this.state = {
      query: search.q || '',
      assigned: search.a || '',
      monitored: search.m || '',
      page,
      orgs: [],
      version: search.v || '',
    };
    if (search.o) {
      this.state.orgs = (_.isArray(search.o) ? search.o : [search.o]).map(x => JSON.parse(x));
    }
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const {
      query, orgs, assigned, monitored, version,
    } = this.state;
    dispatch(getDevices(
      query,
      assigned,
      monitored,
      (orgs ? orgs.map(o => o.id) : undefined),
      0,
      50,
      // eslint-disable-next-line no-bitwise
      (version ? ~~version : undefined),
    ));
    dispatch(getOrganizations());
    dispatch(getDeviceVersions());
  }


  @autobind
  onOrgSelect(org) {
    const { orgs } = this.state;
    if (_.some(orgs, x => x.id === org.id)) {
      this.setState({ orgs: orgs.filter(x => x.id !== org.id), page: 0 }, this.doRefresh);
    } else {
      this.setState({ orgs: [...orgs, org], page: 0 }, this.doRefresh);
    }
  }

  @autobind
  onOrgClear() {
    this.setState({ orgs: [], page: 0 }, this.doRefresh);
  }

  @autobind
  onOrgRemove(tag, index) {
    const { orgs } = this.state;
    this.setState({ orgs: orgs.filter((x, i) => i !== index), page: 0 }, this.doRefresh);
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const {
      query, orgs, assigned, page, monitored, version,
    } = this.state;
    // serializing json into url
    const org = (_.map(orgs, x => JSON.stringify(x)));

    dispatch(replace(`/devices?${qs.stringify(_.pickBy({
      q: query,
      p: page,
      a: assigned,
      m: monitored,
      o: org,
      // eslint-disable-next-line no-bitwise
      v: (version ? ~~version : undefined),
    }))}`));
    dispatch(getDevices(
      query,
      assigned,
      monitored,
      (orgs ? orgs.map(o => o.id) : undefined),
      page * 50,
      50,
      // eslint-disable-next-line no-bitwise
      (version ? ~~version : undefined),
    ));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleAssigned(e) {
    this.setState({ assigned: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleMonitored(e) {
    this.setState({ monitored: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handlePageClick({ selected }) {
    this.setState({ page: selected }, this.doRefresh);
  }

  @autobind
  handleVersion(e) {
    this.setState({ version: e.target.value, page: 0 }, this.doRefresh);
  }

  @autobind
  handleDeviceType(e) {
    this.setState({ type: e.target.value }, this.doRefresh);
  }

  @autobind
  renderDevice(device, i) {
    if (device.id) {
      const { organizations } = this.props;
      const org = _.find(organizations.data || [], x => x.id === device.organization_id) || {};
      const isAssigned = !!device.organization_id;
      const skipBeacon = skipBeaconCheck.includes(device.type);
      const skipNetwork = skipNetworkCheck.includes(device.type);
      const version = (device || {}).iap_configuration && device.iap_configuration.reported_version
        ? device.iap_configuration.reported_version
        : device.version.name;
      const iapCamera = !!device.connected;
      const isCMS = ((device || {}).iap_configuration || { is_cms: false }).is_cms;
      return (
        <tr key={i}>
          <td><Link to={`/devices/${device.id}`}>{device.id}</Link></td>
          <td><Link to={`/devices/${device.id}`}>{device.name}</Link></td>
          <td>
            {isAssigned && <Link to={`/organizations/${device.organization_id}`}>{org.name || device.organization_id}</Link>}
            {!isAssigned && 'Unassigned'}
          </td>
          <td>{DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED)}</td>
          <td>{version}</td>
          <td className="has-text-centered"><Icon icon="cell-tower" intent={skipNetwork ? undefined : networkStatus(device.status) ? 'success' : 'danger'} /></td>
          <td className="has-text-centered"><Icon icon="satellite" intent={skipBeacon || isCMS ? undefined : beaconStatus(device.status) ? 'success' : 'danger'} /></td>
          <td className="has-text-centered"><Icon icon={device.monitored ? 'tick' : 'cross'} intent={device.monitored ? 'success' : ''} /></td>
          <td className="has-text-centered"><Icon icon={iapCamera ? 'tick-circle' : ''} intent={iapCamera ? 'success' : ''} /></td>
        </tr>
      );
    }
    return null;
  }

  @autobind
  renderOrg(org, { handleClick, modifiers }) {
    const { orgs } = this.state;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={`${org.slug.slice(0, 5)}`}
        key={org.id}
        icon={_.some(orgs, x => x.id === org.id) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={org.name}
      />
    );
  }

  renderTable() {
    const {
      page,
      query,
      assigned,
      monitored,
      orgs,
      version,
      type,
    } = this.state;
    // eslint-disable-next-line camelcase
    const { devices, token: access_token } = this.props;
    const organization = orgs.map(o => JSON.stringify(o.id));
    const filteredDevicesData = filterDevices(devices.data || [], type);
    return (
      <div>
        <div className="has-text-centered p-b-mb" style={{ position: 'relative' }}>
          <ReactPaginate
            previousLabel={<Icon icon="chevron-left" />}
            nextLabel={<Icon icon="chevron-right" />}
            breakLabel="..."
            pageCount={Math.ceil(((devices.data.meta || {}).total || 0) / 50)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={page}
            onPageChange={this.handlePageClick}
            containerClassName="bp3-button-group"
            pageClassName="bp3-button"
            previousClassName="bp3-button"
            nextClassName="bp3-button"
            disabledClassName="bp3-disabled"
            breakClassName="bp3-button bp3-disabled"
            activeClassName="bp3-active"
          />

          <a
            role="button"
            className="bp3-button"
            style={{ verticalAlign: 'bottom', position: 'absolute', right: 0 }}
            href={`${axios.defaults.baseURL}/v1/admin/devices/.csv?${qs.stringify({
              query,
              version: version || undefined,
              assigned,
              monitored,
              organization,
              access_token,
            })}`}
          >
            Export
          </a>

        </div>
        <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Name</th>
              <th>Organization</th>
              <th>Created</th>
              <th>Version</th>
              <th className="has-text-centered">Network</th>
              <th className="has-text-centered">Beacon</th>
              <th className="has-text-centered">Monitored</th>
              <th className="has-text-centered">IAP Camera</th>
            </tr>
          </thead>
          <tbody>
            {(filteredDevicesData || []).map(this.renderDevice)}
          </tbody>
        </table>
        <div className="has-text-centered p-t-md">
          <ReactPaginate
            previousLabel={<Icon icon="chevron-left" />}
            nextLabel={<Icon icon="chevron-right" />}
            breakLabel="..."
            pageCount={Math.ceil(((devices.data.meta || {}).total || 0) / 50)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={page}
            onPageChange={this.handlePageClick}
            containerClassName="bp3-button-group"
            pageClassName="bp3-button"
            previousClassName="bp3-button"
            nextClassName="bp3-button"
            disabledClassName="bp3-disabled"
            breakClassName="bp3-button bp3-disabled"
            activeClassName="bp3-active"
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      query, assigned, orgs, monitored, version, type,
    } = this.state;
    const {
      devices, organizations, dispatch, versions = [],
    } = this.props;
    const spinner = devices.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;
    const clearOrgButton = orgs.length > 0 ? <Button icon="cross" minimal onClick={this.onOrgClear} /> : null;

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Devices
                </H1>
              </div>
              <div className="column p-b-none" style={{ textAlign: 'right', alignSelf: 'center' }}>
                <Button
                  className="is-pulled-right"
                  style={{ marginLeft: 10 }}
                  icon="add"
                  onClick={() => dispatch(push('/devices/add_camera'))}
                >
                  Add Camera
                </Button>
                <Button
                  className="is-pulled-right"
                  style={{ marginLeft: 10 }}
                  icon="add"
                  onClick={() => dispatch(push('/devices/add_peplink'))}
                >
                  Add Peplink Device
                </Button>
                <Popover
                  content={
                    (
                      <Menu>
                        <Menu.Item icon="satellite" text="IAP" onClick={() => dispatch(push('/devices/iap'))} />
                        <Menu.Item icon="predictive-analysis" text="Vision Models" onClick={() => dispatch(push('/devices/models'))} />
                      </Menu>
                    )
                    }
                  position={Position.BOTTOM_RIGHT}
                >
                  <Button className="is-pulled-right" rightIcon="caret-down" text="Software Release Manager" />
                </Popover>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-left" style={{ marginLeft: '1%' }}>
                    <FormGroup
                      label="Single Page Device Filter"
                      labelFor="device-type"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="device-type" value={type} onChange={this.handleDeviceType}>
                          <option value="">--</option>
                          <option value="vision">Vision</option>
                          <option value="iap">IAP</option>
                          <option value="cms">CMS</option>
                          <option value="meraki">Meraki</option>
                          <option value="aruba">Aruba</option>
                          <option value="peplink">Peplink</option>
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="column has-text-right" style={{ minWidth: '80%' }}>
                    <FormGroup
                      label="Organizations"
                      labelFor="organizations"
                      className="inline-flex m-r-md"
                    >
                      <MultiSelect
                        resetOnSelect
                        items={organizations.data || []}
                        itemPredicate={filterOrg}
                        itemRenderer={this.renderOrg}
                        tagRenderer={renderTag}
                        noResults={<MenuItem disabled text="No results." />}
                        selectedItems={orgs}
                        onItemSelect={this.onOrgSelect}
                        popoverProps={{ minimal: true }}
                        tagInputProps={{
                          onRemove: this.onOrgRemove,
                          rightElement: clearOrgButton,
                          placeholder: 'All Organizations',
                          className: 'min-width-tag-input',
                        }}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Assigned"
                      labelFor="user-status"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="user-status" value={assigned} onChange={this.handleAssigned}>
                          <option value="">--</option>
                          <option value="true">Assigned</option>
                          <option value="false">Unassigned</option>
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup
                      label="Monitored"
                      labelFor="user-status"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="user-status" value={monitored} onChange={this.handleMonitored}>
                          <option value="">--</option>
                          <option value="true">Monitored</option>
                          <option value="false">Unmonitored</option>
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup
                      label="Version"
                      labelFor="version-list"
                      className="inline-flex m-r-md"
                    >
                      <div className="bp3-select">
                        <select id="version-list" value={version} onChange={this.handleVersion}>
                          <option value="">--</option>
                          {
                            versions.data.map(v => (
                              <option key={v.no} value={v.no}>
                                {v.name}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((devices.data || []).length) && <NonIdealState icon="search" title="No results." />}
            {!!((devices.data || []).length) && this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

Devices.propTypes = {
  dispatch: PropTypes.func,
  devices: PropTypes.object,
  organizations: PropTypes.object,
  location: PropTypes.object,
  versions: PropTypes.object,
  token: PropTypes.string,
};

export default connect(state => ({
  devices: state.devices,
  organizations: state.organizations,
  versions: state.versions,
  token: state.currentUser.token.access_token,
}))(Devices);
