/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Callout, H4, Checkbox, InputGroup, Label,
} from '@blueprintjs/core';
import {
  getCountingLinesByCamera, createCountingLine, updateCountingLineByLineId,
  deleteCountingLineByLineId,
} from 'actions/device';
import _ from 'lodash';

const CountingLines = ({ dispatch, device, cameraCountingLines }) => {
  const imageRef = useRef();
  const [rand, setRand] = useState(Math.floor(Math.random() * 10000));
  const [points, setPoints] = useState([]);
  const [anchorPoint, setAnchorPoint] = useState({});
  const [anchorBool, setAnchorBool] = useState(false);
  const [configureNewBool, setConfigureNewBool] = useState(false);
  const [viewCurrentBool, setViewCurrentBool] = useState(false);
  const [image, setImage] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [saveLineName, setSaveLineName] = useState(false);
  const [names, setNames] = useState([]);
  // const [max_age_list, setMaxAge] = useState([]);
  const { via, in_maintenance } = device || {};
  const [lines, setLines] = useState([]);
  const lineColors = ['#2965CC', '#29A634', '#D99E0B', '#D13913', '#8F398F', '#00B3A4', '#DB2C6F', '#9BBF30', '#96622D', '#7157D9'];
  const handleKeyDown = (e) => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(getCountingLinesByCamera(device.id)).then((response) => {
      const namesData = [];
      // const max_age_data = [];
      if (!!response.payload.data.content.length > 0 && response.payload.data.content.length > 0) {
        response.payload.data.content.forEach(({ name, max_age }) => {
          namesData.push({ name, max_age });
          // max_age_data.push(max_age);
        });
        setLines(namesData);
        // setMaxAge(max_age_data);
      }
    });
    window.addEventListener('keydown', handleKeyDown, false);

    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setNames([...lines]);
  }, [lines]);

  const getImageDimensions = () => {
    const clientWidth = imageRef.current ? imageRef.current.clientWidth : 0;
    const clientHeight = imageRef.current ? imageRef.current.clientHeight : 0;
    const naturalWidth = imageRef.current ? imageRef.current.naturalWidth : 0;
    const naturalHeight = imageRef.current ? imageRef.current.naturalHeight : 0;
    return {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    };
  };

  const getCentroid = (currentPoints) => {
    const renderPoints = currentPoints || points;
    const midpoints = [];
    renderPoints.forEach((p, i) => {
      if (i !== renderPoints.length - 1) {
        const seg = [renderPoints[i], renderPoints[i + 1]];
        if (seg.length === 2) {
          const midpoint = { x: (seg[1].x + seg[0].x) / 2, y: (seg[1].y + seg[0].y) / 2 };
          midpoints.push(midpoint);
        }
      }
    });
    const x = _.mean(midpoints.map(xx => xx.x));
    const y = _.mean(midpoints.map(yy => yy.y));
    return { x, y };
  };

  const handleKeyPress = (e) => {
    const pointId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const { clientHeight, clientWidth } = getImageDimensions();
    // if (clientHeight === 0 || clientWidth === 0 || naturalWidth === 0) {
    //   _.defer(() => this.forceUpdate());
    // }
    const currentPoint = points.find(x => x.id === pointId);
    const filteredPoints = points.filter(y => y.id !== pointId);
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newMappedPoints = [...filteredPoints, newPoint].sort((a, b) => a.id - b.id);
    return setPoints(newMappedPoints);
  };

  const handleDrop = (e) => {
    if (!['polygon_image', 'polygon_svg', 'polgyon_anchor'].includes(e.target.id)) {
      return null;
    }
    e.preventDefault();
    e.stopPropagation();
    const id = e.dataTransfer.getData('text/plain');
    const { x, y } = e.nativeEvent;
    // const { clientHeight, clientWidth } = getImageDimensions();
    // if (clientHeight === 0 || clientWidth === 0) {
    //   _.defer(() => this.forceUpdate());
    // }
    if (imageRef.current) {
      const c = imageRef.current.getBoundingClientRect();
      const newPoint = {
        x: x - c.x,
        y: y - c.y,
        id: parseInt(id, 10),
      };
      const newPoints = [...points.filter(f => f.id !== parseInt(id, 10)), newPoint]
        .sort((a, b) => a.id - b.id);
      setPoints(newPoints);
    }
    return null;
  };


  const preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragStart = (e, id) => {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  };


  const checkImageLoaded = (imgObject) => {
    if (imgObject.complete) {
      setImage(imgObject);
      setLoading(false);
    } else {
      setTimeout(() => {
        checkImageLoaded(imgObject);
      }, 1);
    }
  };

  const fetchImage = () => {
    const imgObject = new Image();
    imgObject.src = `https://admin.livereachmedia.com/api/v1/devices/${device.id}/camera/preview?r=${rand}`;
    imgObject.onload = checkImageLoaded(imgObject);
    imgObject.onerror = () => setLoading(false);
    setLoading(true);
    setRand(Math.floor(Math.random() * 10000));
  };

  const undo = () => {
    if (points.length > 0) {
      setPoints(p => p.slice(0, -1));
    }
  };

  const reset = () => {
    setPoints([]);
    setAnchorPoint({});
    setAnchorBool(false);
  };

  const addPoint = (e) => {
    if (!['polygon_image', 'polygon_svg', 'polgyon_anchor'].includes(e.target.id)) {
      return null;
    }
    const { x, y } = e.nativeEvent;
    if (imageRef.current) {
      const c = imageRef.current.getBoundingClientRect();
      const nextId = points.length
        ? Math.max(...points.map(p => p.id)) + 1
        : 1;
      setPoints([...points, { x: (x - (c.x)), y: (y - (c.y)), id: nextId }]);
    }
    return null;
  };

  const addAnchor = (ev) => {
    if (imageRef.current) {
      const { x, y } = ev.nativeEvent;
      const c = imageRef.current.getBoundingClientRect();
      setAnchorPoint({ x: (x - (c.x)), y: (y - (c.y)) });
    }
  };

  const configureRemove = () => {
    setConfigureNewBool(false);
    setPoints([]);
    setAnchorPoint({});
    setAnchorBool(false);
  };

  const hideAnchor = () => {
    setAnchorBool(false);
    setAnchorPoint({});
  };

  const handleSave = () => {
    const {
      clientWidth, clientHeight, naturalWidth, naturalHeight,
    } = getImageDimensions();
    const counting_line = points.map(({ x, y }) => [
      (x / clientWidth) * naturalWidth,
      (y / clientHeight) * naturalHeight,
    ]);
    const counting_anchor = [
      (anchorPoint.x / clientWidth) * naturalWidth,
      (anchorPoint.y / clientHeight) * naturalHeight,
    ];

    setSubmitting(true);
    dispatch(createCountingLine(device.id, { counting_line, counting_anchor, name: 'line' }))
      .then(() => dispatch(getCountingLinesByCamera(device.id)))
      .then(() => setNames([...names, { name: 'line' }]))
      .then(() => configureRemove())
      .then(() => setViewCurrentBool(true))
      .finally(() => setSubmitting(false));
  };

  const renderInstructions = () => (
    <Callout intent="primary">
      <H4>
        <div className="flex-space-between-container">
          <div>New Line</div>
          <Button intent="danger" onClick={configureRemove}>Exit</Button>
        </div>
      </H4>
      <div
        style={{
          margin: '20px 0px 10px 0px', opacity: anchorBool && 0.5, display: 'flex', flexDirection: 'column',
        }}
      >
        <div>
          Click continuous points on the image to create your counting line.
        </div>
        <div className="flex-space-between-container" style={{ margin: '10px 0px' }}>
          <div>
            <Button style={{ marginRight: 10 }} disabled={anchorBool} onClick={reset} intent="warning" icon="reset">Reset</Button>
            <Button disabled={anchorBool} onClick={undo} intent="success" icon="undo">Undo Point</Button>
          </div>
          <Button disabled={points.length < 2 || anchorBool} onClick={() => setAnchorBool(true)} rightIcon="arrow-right" intent="primary">Next</Button>
        </div>
      </div>
      {anchorBool && (
        <React.Fragment>
          <div style={{ margin: '10px 0px', display: 'flex', flexDirection: 'column' }}>
            <div>
              Click a single point that represents the IN direction.
            </div>
            <div className="flex-space-between-container" style={{ margin: '10px 0px' }}>
              <Button style={{ marginRight: 10 }} icon="arrow-left" onClick={hideAnchor} intent="warning">Back</Button>
              <Button loading={submitting} onClick={handleSave} disabled={_.isEmpty(anchorPoint)} icon="tick" intent="primary">Done</Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Callout>
  );

  const drawAnchor = (currentAnchor, currentPoints, color, line_id) => {
    const renderAnchor = currentAnchor || anchorPoint;
    const renderPoints = currentPoints || points;
    const { clientHeight, clientWidth } = getImageDimensions();
    const { x, y } = getCentroid(renderPoints);
    if (!x && !y) {
      return null;
    }
    return (
      <svg
        width={clientWidth}
        height={clientHeight}
        style={{
          position: 'absolute', top: 0, left: 0,
        }}
        id="polgyon_anchor"
      >
        <marker
          id={currentAnchor ? `${line_id}` : 'anchor2'}
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
          fill={currentAnchor ? `${color}` : '#fff'}
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
        <line
          x1={x}
          y1={y}
          x2={renderAnchor.x}
          y2={renderAnchor.y}
          stroke={currentAnchor ? `${color}` : '#fff'}
          strokeWidth="2.5px"
          markerEnd={currentAnchor ? `url(#${line_id})` : 'url(#anchor2)'}
        />
      </svg>
    );
  };

  const drawEdges = (currentPoints, color) => {
    const { clientHeight, clientWidth } = getImageDimensions();
    const renderPoints = currentPoints || points;
    if (!!renderPoints && renderPoints.length > 1) {
      return renderPoints.map((p, i) => !(i === renderPoints.length - 1) && (
        <svg
          draggable={false}
          key={p.id}
          width={`${clientWidth}`}
          height={`${clientHeight}`}
          style={{
            position: 'absolute', top: 0, left: 0,
          }}
          id="polygon_svg"
        >
          <line
            x1={p.x - 2}
            y1={p.y - 2}
            x2={`${renderPoints[i + 1].x - 2}`}
            y2={`${renderPoints[i + 1].y - 2}`}
            stroke={currentPoints ? `${color}` : '#ffff00'}
            strokeWidth="4px"
          />
        </svg>
      ));
    }
    return null;
  };

  const drawPoints = (currentPoints, color) => {
    const renderPoints = currentPoints || points;
    const draggable = (() => {
      if (currentPoints || anchorBool || !configureNewBool) {
        return false;
      }
      return true;
    })();
    return renderPoints.map(({ x, y, id }) => (
      <div
        key={id}
        id={id}
        role="presentation"
        style={{
          position: 'absolute',
          zIndex: 20,
          left: x - 4,
          top: y - 5,
          height: 6,
          width: 6,
          backgroundColor: currentPoints ? `${color}` : '#ffff00',
          borderRadius: '50%',
          textAlign: 'center',
          fontSize: 12,
          cursor: 'pointer',
        }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        draggable={draggable}
        onDragStart={e => handleDragStart(e, id)}
        onKeyDown={!anchorBool && !currentPoints ? handleKeyPress : null}
      />
    ));
  };

  const viewCurrent = () => {
    const { data } = (cameraCountingLines || {});
    const pointsArray = data.map((p) => {
      const {
        clientWidth, clientHeight, naturalWidth, naturalHeight,
      } = getImageDimensions();
      const currPoints = p.counting_line.map((c, i) => ({
        x: (c[0] / naturalWidth) * clientWidth,
        y: (c[1] / naturalHeight) * clientHeight,
        id: i + 1,
      }));
      const currAnchorPoint = {
        x: (p.counting_anchor[0] / naturalWidth) * clientWidth,
        y: (p.counting_anchor[1] / naturalHeight) * clientHeight,
      };
      return [currPoints, currAnchorPoint];
    });

    return (
      <React.Fragment>
        {pointsArray.map((p, i) => (
          <React.Fragment key={data[i].line_id}>
            {drawEdges(p[0], lineColors[i])}
            {drawPoints(p[0], lineColors[i])}
            {drawAnchor(p[1], p[0], lineColors[i], data[i].line_id)}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  const removeCurrentPoint = (i, line_id) => {
    setRemoving(true);
    dispatch(deleteCountingLineByLineId(device.id, line_id))
      .then(() => dispatch(getCountingLinesByCamera(device.id))
        .then(() => {
          const values = names;
          values.splice(i, 1);
          setNames([...values]);
        })
        .finally(() => setRemoving(false)));
  };

  const saveCurrentLineName = (i, line_id) => {
    setSaveLineName(true);
    dispatch(updateCountingLineByLineId(device.id, line_id, { name: names[i].name, max_age: parseInt(names[i].max_age, 10) }))
      .then(() => dispatch(getCountingLinesByCamera(device.id))
        .finally(() => setSaveLineName(false)));
  };

  const saveCoalesce = (e, line_id) => {
    setSaveLineName(true);
    dispatch(updateCountingLineByLineId(device.id, line_id, { coalesce_entries: e.target.checked }))
      .then(() => dispatch(getCountingLinesByCamera(device.id))
        .finally(() => setSaveLineName(false)));
  };

  const inputChangeText = (i, e) => {
    const values = names;
    values[i].name = e.target.value;
    setNames([...values]);
  };

  const maxAgeChangeText = (i, e) => {
    const values = names;
    values[i].max_age = e.target.value;
    setNames([...values]);
  };

  const viewCurrentPoints = () => {
    const { data } = cameraCountingLines;
    return (
      <React.Fragment>
        { data.length <= names.length && data.map(({ name, line_id, counting_line, coalesce_entries, max_age }, i) => (
          <div key={counting_line[0]} className="flex-space-between-container" style={{ marginTop: 20 }}>
            <div style={{
              marginTop: 5, marginRight: 5, height: '1.2rem', width: '1.2rem', backgroundColor: `${lineColors[i]}`,
            }}
            />
            <InputGroup value={names[i].name} onChange={e => inputChangeText(i, e)} />
            <Checkbox disabled={saveLineName} style={{ marginTop: 5, marginLeft: 20 }} label="Coalesce" checked={coalesce_entries} onChange={e => saveCoalesce(e, line_id)} />
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Label htmlFor="max_age" style={{ marginBottom: 'unset', marginTop: -18 }}>Max Age</Label>
              <InputGroup value={names[i].max_age} onChange={e => maxAgeChangeText(i, e)} label="max_age" />
            </div>
            <Button intent="primary" disabled={name === names[i].name && max_age === names[i].max_age} loading={saveLineName} onClick={() => saveCurrentLineName(i, line_id)} icon="tick" />
            <Button intent="danger" loading={removing} onClick={() => removeCurrentPoint(i, line_id)} icon="cross" />
          </div>
        ))}
      </React.Fragment>
    );
  };

  const renderConfig = () => {
    const dummyImage = 'https://dummyimage.com/300x200/000/fff&text=^+generate+image+above';
    const { data } = (cameraCountingLines || {});
    const onClick = (() => {
      if (configureNewBool) {
        if (anchorBool) return addAnchor;
        if (!anchorBool && configureNewBool) return addPoint;
        return null;
      }
      return null;
    })();

    return (
      <React.Fragment>
        <div style={{ marginBottom: 40 }}>
          <div style={{ marginBottom: 20 }} id="render-camera-img-polygon">
            <div style={{ fontSize: 16 }}>
              <Button onClick={fetchImage} loading={loading} icon="media">
                Generate Image
              </Button>
            </div>
          </div>
          <div className="flex-space-between-container">
            <div
              id="render-camera-img-polygon2"
              onDragOver={preventDefault}
              onDragEnter={preventDefault}
              onDrop={!anchorBool ? handleDrop : undefined}
              onClick={onClick}
              role="presentation"
            >
              <img
                src={image && image.complete ? image.src : dummyImage}
                alt="no feed"
                className="camera-vision-image"
                ref={imageRef}
                draggable={false}
                role="presentation"
                id="polygon_image"
              />
              {drawEdges()}
              {!!points.length && drawPoints()}
              {!!anchorPoint.x && !!anchorPoint.y && anchorBool && drawAnchor()}
              {viewCurrentBool && !!data.length && viewCurrent()}
            </div>
            <div style={{ width: '50%', paddingLeft: 20 }}>
              <div style={{ display: 'flex', marginBottom: 20 }}>
                {!!data && (
                  <Checkbox disabled={!image || !image.complete} label="View Current Direction" checked={viewCurrentBool} onChange={() => setViewCurrentBool(!viewCurrentBool)} />
                )}
                &nbsp;&nbsp;&nbsp;
                <Button disabled={!image || !image.complete} onClick={() => setConfigureNewBool(!configureNewBool)} icon="plus" intent="primary">
                  Configure New Line
                </Button>
              </div>
              {configureNewBool && renderInstructions()}
              {viewCurrentBool && viewCurrentPoints()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderNoConfig = () => (
    <div style={{ fontSize: 16 }}>
      Counting Line Configuration Unavailable
    </div>
  );

  return (
    <React.Fragment>
      { !!via && in_maintenance ? renderConfig() : renderNoConfig()}
    </React.Fragment>
  );
};

CountingLines.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  cameraCountingLines: PropTypes.object,
};

export default connect(state => ({
  cameraCountingLines: state.cameraCountingLines,
}))(CountingLines);
