import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Elevation, Tag, HTMLTable,
} from '@blueprintjs/core';

const rowRenderer = data => (
  <Fragment>
    <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ fontSize: 16, fontWeight: 600, display: 'inline' }}>
        {`${data.index}: ${data.name}`}
      </p>
      <Tag style={{ marginLeft: 10 }}>{`Utilization ${data['utilization.gpu']}%`}</Tag>
    </div>
    <HTMLTable striped>
      <thead>
        <tr>
          <th>Command</th>
          <th>Memory Usage</th>
        </tr>
      </thead>
      <tbody>
        {data.processes.map(x => (
          <tr>
            <td>{x.command}</td>
            <td>{`${x.gpu_memory_usage} %`}</td>
          </tr>
        ))}
      </tbody>
    </HTMLTable>
    <br />
  </Fragment>
);

const GPUProcessList = ({ data }) => (
  <Fragment>
    <Tag large>GPU Processes</Tag>
    <Card elevation={Elevation.THREE} style={{ marginTop: 10, minHeight: 540 }}>
      {data.map(x => rowRenderer(x))}
    </Card>
  </Fragment>
);

GPUProcessList.propTypes = {
  data: PropTypes.object,
};

export default GPUProcessList;
