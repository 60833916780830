import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import {
  Spinner, Button, Tag, Icon, NonIdealState, Popover, Position, Menu, H3,
  H5, Classes, Overlay,
} from '@blueprintjs/core';
import { AppToaster } from 'components/Toaster';
import {
  getCameraRecordings, startCameraRecording, stopCameraRecording,
} from 'actions/device';

import ViewUploads from './view';
import Upload from './upload';
import ViewSchedules from './schedules';
import CreateSchedule from './createSchedule';

const classes = classNames(
  Classes.CARD,
  Classes.ELEVATION_4,
  'dash-overlay',
);

const defaultTime = new Date();
defaultTime.setHours(0);
defaultTime.setMinutes(0);
defaultTime.setSeconds(0);
defaultTime.setMilliseconds(0);

class Record extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      serial: '',
      loading: '',
      new_records: [],
      new_serial: '',
      alreadyRecording: '',
    };
  }

  componentDidMount() {
    this.getRecordings();
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props;
    if (device.id !== prevProps.device.id) {
      this.getRecordings();
    }
  }

  @autobind
  getRecordings() {
    const { dispatch, device } = this.props;
    dispatch(getCameraRecordings(device.id));
  }

  @autobind
  setContent(content, serial) {
    this.setState({ content, serial }, () => window.scrollTo(0, document.body.scrollHeight));
  }

  @autobind
  setIntent(targetContent, targetSerial) {
    const { content, serial } = this.state;
    if (targetContent === content && targetSerial === serial) {
      return 'warning';
    }
    return 'primary';
  }

  @autobind
  addRecordings() {
    const { cameraRecordings } = this.props;
    const { new_records, new_serial } = this.state;
    // eslint-disable-next-line max-len
    if ([...cameraRecordings.data].concat(new_records).filter(x => x.camera_serial === new_serial).length > 0) {
      return AppToaster.show({
        icon: 'cross',
        intent: 'danger',
        message: <span>No Duplicate Records</span>,
      });
    }
    const new_record = {
      camera_serial: new_serial,
      ip: [],
      recording: false,
    };
    this.setState({ new_records: [...new_records, new_record] });
    return null;
  }

  @autobind
  handleOverride(serial) {
    this.setState({ loading: serial });
    const { dispatch, device } = this.props;
    dispatch(startCameraRecording(device.id, { camera_serial: serial }, true))
      .then(() => this.getRecordings())
      .finally(() => this.setState({ loading: '', alreadyRecording: '' }));
  }

  @autobind
  startRecording(serial) {
    this.setState({ loading: serial });
    const { dispatch, device } = this.props;
    dispatch(startCameraRecording(device.id, { camera_serial: serial }, false))
      .catch((error) => {
        if (error && error.payload && error.payload.response.data.result.errorMessage.includes('already being recorded')) {
          this.setState({ alreadyRecording: serial });
        }
        return undefined;
      })
      .then(() => this.getRecordings())
      .finally(() => this.setState({ loading: '' }));
  }

  @autobind
  stopRecording(serial) {
    this.setState({ loading: serial });
    const { dispatch, device } = this.props;
    dispatch(stopCameraRecording(device.id, serial))
      .finally(() => this.getRecordings())
      .then(() => this.setState({ loading: '', content: '' }));
  }

  @autobind
  handleInputChange(e) {
    this.setState({ new_serial: e.target.value });
  }

  @autobind
  closeOverlay() {
    this.setState({ alreadyRecording: '', loading: '' });
  }

  @autobind
  renderOverrideActions(camera_serial) {
    return (
      <div className={classes}>
        <H5>Override Recording</H5>
        <p>Are you sure you want to start this recording?</p>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
              Cancel
          </Button>
          <Button onClick={() => this.handleOverride(camera_serial)} intent="success" className={Classes.POPOVER_DISMISS}>
              Confirm
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderRecording(record) {
    const { cameraRecordings } = this.props;
    const {
      camera_serial, recording, ip, operating_hours,
    } = record;
    const { loading, alreadyRecording } = this.state;
    const disabled = cameraRecordings.pending > 0;
    const hasOperatingHours = operating_hours && !!operating_hours.length;
    return (
      <tr key={camera_serial}>
        <td>{camera_serial}</td>
        <td style={{ textAlign: 'center' }}>
          {(recording || hasOperatingHours) && <Icon intent="success" icon="tick" />}
          {!recording && !hasOperatingHours && <Icon intent="danger" icon="cross" />}
        </td>
        <td>
          {!!ip.length && ip.map((x, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={j} style={{ margin: '0px 5px 5px 0px' }} multiline intent="primary">{x}</Tag>
          ))}
        </td>
        <td>
          {(recording || hasOperatingHours) && (
            <Button
              onClick={() => this.stopRecording(camera_serial)}
              small
              icon="stop"
              intent="danger"
              loading={loading === camera_serial}
              disabled={disabled}
            >
              Stop
            </Button>
          )}
          {(!recording && !hasOperatingHours) && (
            <Popover
              content={(
                <Menu>
                  <Menu.Item intent="success" onClick={() => this.startRecording(camera_serial)} icon="record" text="Start Now" />
                  <Menu.Item intent="warning" onClick={() => this.setContent('create', camera_serial)} icon="timeline-events" text="Add Schedule" />
                </Menu>
              )}
              position={Position.TOP}
            >
              <Button loading={loading === camera_serial} disabled={disabled} icon="record" small intent="success">
                Start
              </Button>
            </Popover>
          )}
        </td>
        <td>
          {hasOperatingHours && (
            <Button
              outlined
              disabled={disabled}
              intent={this.setIntent('schedules', camera_serial)}
              small
              icon="timeline-events"
              onClick={() => this.setContent('schedules', camera_serial)}
            >
              Schedules
            </Button>
          )}
        </td>
        <td>
          <Button
            outlined
            disabled={disabled}
            intent={this.setIntent('view', camera_serial)}
            icon="eye-open"
            small
            onClick={() => this.setContent('view', camera_serial)}
          >
            Uploads
          </Button>
        </td>
        <td>
          <Button
            outlined
            disabled={disabled}
            intent={this.setIntent('upload', camera_serial)}
            icon="upload"
            small
            onClick={() => this.setContent('upload', camera_serial)}
          >
            New Upload
          </Button>
        </td>
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          hasBackdrop
          // usePortal
          isOpen={alreadyRecording === camera_serial}
          transitionDuration={0}
          canOutsideClickClose
          onClose={this.closeOverlay}
        >
          {this.renderOverrideActions(camera_serial)}
        </Overlay>
      </tr>
    );
  }

  render() {
    const { cameraRecordings, device } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { content, serial, new_records, new_serial } = this.state;
    if (cameraRecordings.pending && !(cameraRecordings.data || []).length) {
      return <Spinner size={80} />;
    }
    // eslint-disable-next-line max-len
    const records = cameraRecordings.data ? [...cameraRecordings.data].concat(new_records) : [];
    return (
      <div className="container">
        <div className="flex-space-between-container" style={{ margin: '10px 0px' }}>
          <div>
            <H3>Record Service</H3>
          </div>
          <div className="has-text-right">
            <Button loading={cameraRecordings.pending > 0} onClick={this.getRecordings} icon="refresh" intent="primary" style={{ marginRight: '10px' }} />
            <Popover
              content={(
                <div style={{ padding: '10px', display: 'flex' }}>
                  <div className="bp3-text">
                    <input value={new_serial} placeholder="Camera Serial" name="camera serial" className="bp3-input" onChange={this.handleInputChange} />
                  </div>
                  <Button onClick={this.addRecordings} style={{ marginLeft: 5 }} icon="tick" intent="primary" />
                </div>
              )}
              target={(
                <div className="has-text-right">
                  <Button disabled={cameraRecordings.pending > 0} icon="plus" intent="primary" />
                </div>
              )}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!!(records || []).length && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <td>Camera Serial</td>
                    <td style={{ textAlign: 'center' }}>Recording</td>
                    <td>IPs</td>
                  </tr>
                </thead>
                <tbody>
                  {// eslint-disable-next-line max-len
                  }
                  {records.sort((a, b) => a.camera_serial.localeCompare(b.camera_serial))
                    .map(this.renderRecording)}
                </tbody>
              </table>
            )}
            {!(records || []).length && <NonIdealState icon="search" title="No Recordings" />}
          </div>
        </div>
        {content === 'view' && <ViewUploads device={device.id} serial={serial} />}
        {content === 'create' && (
          <CreateSchedule
            device={device}
            serial={serial}
            setContent={this.setContent}
          />
        )}
        {content === 'schedules' && (
          <ViewSchedules
            schedules={(records.find(x => x.camera_serial === serial) || {}).operating_hours}
            serial={serial}
            device={device}
          />
        )}
        {content === 'upload' && (
          <Upload
            device={device}
            serial={serial}
            setContent={this.setContent}
            initialValues={{
              camera_serial: serial,
              start_time: defaultTime,
              end_time: defaultTime,
            }}
          />
        )}
      </div>
    );
  }
}

Record.propTypes = {
  dispatch: PropTypes.func,
  device: PropTypes.object,
  cameraRecordings: PropTypes.object,
};

export default connect(state => ({
  cameraRecordings: state.cameraRecordings,
}))(Record);
