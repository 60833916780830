import {
  GET_PIPELINES, EDIT_PIPELINE, DELETE_PIPELINE,
} from '../constants';

export const getPipelines = () => ({
  type: GET_PIPELINES,
  payload: {
    request: {
      method: 'get',
      // custom domain here instead of .edge.infra
      // so that we can load over https
      url: 'https://faustian-infra.livereachmedia.com/pipelines',
    },
  },
});


export const editPipeline = (pipelineID, data) => ({
  type: EDIT_PIPELINE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `https://faustian-infra.livereachmedia.com/pipelines/${pipelineID}`,
    },
  },
});

export const deletePipeline = pipelineID => ({
  type: DELETE_PIPELINE,
  payload: {
    request: {
      method: 'delete',
      url: `https://faustian-infra.livereachmedia.com/pipelines/${pipelineID}`,
    },
  },
});
