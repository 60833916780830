import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { autobind } from 'core-decorators';
import {
  TextInput, TextAreaInput, FormSelectInput, CheckboxInput,
} from 'components/inputs';
import {
  Card, Elevation, Button, Icon, Classes, Popover, H5, Tag,
} from '@blueprintjs/core';
import { connect } from 'react-redux';
import { patchAlert, deleteAlert, getAlerts } from 'actions/alerts';

const SlackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" viewBox="0 0 60 60" width="25" height="25" aria-label="Slack">
    <path d="m21.902.148c-3.299 0-5.973 2.68-5.973 5.985a5.979 5.979 0 0 0 5.973 5.985h5.974v-5.985a5.98 5.98 0 0 0 -5.974-5.985m0 15.96h-15.929c-3.299 0-5.973 2.68-5.973 5.986 0 3.305 2.674 5.985 5.973 5.985h15.93c3.298 0 5.973-2.68 5.973-5.985 0-3.306-2.675-5.986-5.974-5.986" fill="#36c5f0" />
    <path d="m59.734 22.094c0-3.306-2.675-5.986-5.974-5.986s-5.973 2.68-5.973 5.986v5.985h5.973a5.98 5.98 0 0 0 5.974-5.985m-15.929 0v-15.961a5.98 5.98 0 0 0 -5.974-5.985c-3.299 0-5.973 2.68-5.973 5.985v15.96c0 3.307 2.674 5.987 5.973 5.987a5.98 5.98 0 0 0 5.974-5.985" fill="#2eb67d" />
    <path d="m37.831 60a5.98 5.98 0 0 0 5.974-5.985 5.98 5.98 0 0 0 -5.974-5.985h-5.973v5.985c0 3.305 2.674 5.985 5.973 5.985m0-15.96h15.93c3.298 0 5.973-2.68 5.973-5.986a5.98 5.98 0 0 0 -5.974-5.985h-15.929c-3.299 0-5.973 2.68-5.973 5.985a5.979 5.979 0 0 0 5.973 5.985" fill="#ecb22e" />
    <path d="m0 38.054a5.979 5.979 0 0 0 5.973 5.985 5.98 5.98 0 0 0 5.974-5.985v-5.985h-5.974c-3.299 0-5.973 2.68-5.973 5.985m15.929 0v15.96c0 3.306 2.674 5.986 5.973 5.986a5.98 5.98 0 0 0 5.974-5.985v-15.961a5.979 5.979 0 0 0 -5.974-5.985c-3.299 0-5.973 2.68-5.973 5.985" fill="#e01e5a" />
  </svg>
);

export const simplifyNotifChannels = (channels) => {
  const channelList = channels.map(x => x.value);
  return channelList.join(',');
};

const formatInitialValues = (alert) => {
  const channelList = alert.notification_channels.split(',');
  const slack_channels = channelList.map(x => ({ value: x }));
  return { ...alert, slack_channels };
};

// eslint-disable-next-line react/prefer-stateless-function
class EditAlert extends Component {
  @autobind
  editAlert(values) {
    const { dispatch, editToggle, alert } = this.props;
    const data = {
      alert_name: values.alert_name,
      alert_description: values.alert_description,
      metric: values.metric,
      threshold: values.threshold,
      duration: values.duration,
      type: values.type,
      aggregation: values.aggregation || '',
      filter: values.filter,
      reminder: values.reminder || null,
      notification_channels: values.slack_channels ? simplifyNotifChannels(values.slack_channels) : '',
      no_data: values.no_data === true ? 1 : 0,
    };
    dispatch(patchAlert(alert.alert_id, data)).then(
      () => dispatch(getAlerts()).then(() => editToggle()),
    );
  }

  @autobind
  handleDelete() {
    const { dispatch, alert } = this.props;
    dispatch(deleteAlert(alert.alert_id)).then(() => dispatch(getAlerts()));
  }

  @autobind
  deleteContent() {
    return (
      <div style={{ padding: 10 }}>
        <H5>Confirm Deletion</H5>
        <p>Are you sure you want to delete this alert?</p>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
              Cancel
          </Button>
          <Button onClick={this.handleDelete} intent="danger" className={Classes.POPOVER_DISMISS}>
              Delete
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderChannels({ fields }) {
    return (
      <Fragment>
        <div className="row-align-center">
          <Icon icon={<SlackIcon />} />
          &nbsp;&nbsp;
          <Tag
            intent="success"
            minimal
            large
          >
            Add Slack Notification Channels
          </Tag>
          &nbsp;&nbsp;
          <Button icon="plus" onClick={() => fields.push()} />
        </div>
        <ul>
          {fields.map((channel, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Field
                  component={TextInput}
                  placeholder="Notification Channel"
                  name={`${channel}.value`}
                  type="text"
                />
                &nbsp;&nbsp;
                <Button
                  icon="cross"
                  title="remove"
                  intent="danger"
                  onClick={() => fields.remove(i)}
                />
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  render() {
    const { editToggle, handleSubmit, saveAlert } = this.props;
    return (
      <form onSubmit={saveAlert ? handleSubmit(saveAlert) : handleSubmit(this.editAlert)} autoComplete="off">
        <div className="flex-row-reverse" style={{ paddingBottom: 10 }}>
          {!saveAlert && (
            <Popover
              content={this.deleteContent()}
              target={(
                <Button icon="trash" minimal intent="danger" />
              )}
            />
          )}
          <Button icon="tick" minimal intent="success" type="submit" />
          {!saveAlert && <Button onClick={editToggle} icon="cross" minimal intent="warning" />}
        </div>
        <Card elevation={Elevation.THREE} style={{ marginBottom: 20 }}>
          <Field
            component={TextInput}
            name="alert_name"
            type="text"
            label="Name"
          />
          <Field
            component={TextInput}
            name="alert_description"
            type="text"
            label="Description"
          />
          <div style={{ paddingBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
              <Field
                component={FormSelectInput}
                name="metric"
              >
                <option value="">Select a Metric</option>
                <option key={9} value="nvr_camera_fps">NVR Camera Recording FPS</option>
                <option key={10} value="nvr_camera_ai_fps">NVR Camera AI FPS</option>
                <option key={0} value="fps">FPS</option>
                <option key={1} value="temperature">Temperature</option>
                <option key={2} value="disk">Disk</option>
                <option key={3} value="cpu">CPU</option>
                <option key={4} value="memory">Memory</option>
                <option key={5} value="occupancy">Occupancy</option>
                <option key={6} value="total_ins">Total INs</option>
                <option key={7} value="total_outs">Total Outs</option>
                <option key={8} value="peplink">Peplink</option>
              </Field>
              &nbsp;&nbsp;
              <Field
                component={FormSelectInput}
                name="type"
              >
                <option value="">Select an Operator</option>
                <option key={0} value="eq">=</option>
                <option key={1} value="ne">≠</option>
                <option key={2} value="gt">&gt;</option>
                <option key={3} value="lt">&lt;</option>
              </Field>
              &nbsp;&nbsp;
              <Field
                component={TextInput}
                placeholder="Enter a Threshold"
                name="threshold"
                type="number"
              />
            </div>
            <div className="row-align-center">
              <Field
                component={FormSelectInput}
                name="aggregation"
              >
                <option value="">Select aggregation</option>
                <option key={0} value="mean">Mean</option>
                <option key={1} value="median">Median</option>
                <option key={2} value="max">Max</option>
                <option key={3} value="min">Min</option>
                <option key={4} value="None">None</option>
              </Field>
              &nbsp;&nbsp;
              <Field
                component={TextInput}
                placeholder="Reminder"
                name="reminder"
                type="number"
              />
              &nbsp;&nbsp;
              <Field
                component={TextInput}
                placeholder="Duration"
                name="duration"
                type="number"
              />
              &nbsp;&nbsp;
              <Field
                component={CheckboxInput}
                name="no_data"
                label="Send Notification on No Data"
              />
              &nbsp;&nbsp;
              <Field
                component={CheckboxInput}
                name="pagerduty_notify"
                label="Send Pager Duty Notifications"
              />
            </div>
          </div>
          Filter:
          <Field
            component={TextAreaInput}
            placeholder=""
            name="filter"
            type="text"
            rows={3}
            style={{
              fontFamily: 'monospace',
              fontSize: '13px',
              padding: '13px 15px 12px',
            }}
          />
          <div className="columns">
            <div className="column">
              <FieldArray name="slack_channels" component={this.renderChannels} />
            </div>
          </div>
        </Card>
      </form>
    );
  }
}

const REQUIRED = 'This field is required.';

EditAlert.propTypes = {
  editToggle: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveAlert: PropTypes.func,
  dispatch: PropTypes.func,
  alert: PropTypes.object,
};

export default connect((state, props) => ({
  initialValues: props.alert && formatInitialValues(props.alert),
}))(reduxForm({
  form: 'alert_config',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.alert_name) {
      errors.alert_name = REQUIRED;
    }
    if (!values.alert_description) {
      errors.alert_description = REQUIRED;
    }
    if (!values.metric) {
      errors.metric = REQUIRED;
    }
    if (!values.type) {
      errors.type = REQUIRED;
    }
    if (!values.aggregation) {
      errors.aggregation = REQUIRED;
    }
    if (!values.threshold) {
      errors.threshold = REQUIRED;
    }
    if (!values.duration) {
      errors.duration = REQUIRED;
    }
    if (!values.filter) {
      errors.filter = REQUIRED;
    }
    return errors;
  },
})(EditAlert));
